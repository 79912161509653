import React from "react";
import { Header } from "../../Header";
import { ConfigForm } from "../ConfigForm/ConfigForm";

export const ConfigCreateContainer = (props: any) => {
  return (
    <>
      <Header {...props} />
      <ConfigForm />
    </>
  );
};
