import React, { useState } from "react";
import { SelectBox } from "app/shared";
import { Button, Text, Icon, Box, Flex } from "primitives";
import { SelectLabelSimple as SelectLabel } from "components";
import styled from "styled-components";
import { CommonProps } from "styles/constants";

export interface PaginationParams {
  page: number;
  perPage: number;
  to?: string | undefined;
}

interface PaginationSimpleProps {
  nextPageUrl: string | number;
  previousPageUrl: string | number;
  pageSize: number;
  onChange: (url: string | number) => any;
  onPageSizeChange: (pageSize: number) => any;
  classes?: any;
  disabled?: boolean;
  small?: boolean;
  total?: number;
  page?: number;
}

const options = {
  enumOptions: [
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 200, label: "200" },
    { value: 500, label: "500" }
  ]
};

const PaginationButton = styled(Button)`
  background: transparent;
  border: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;

  &:hover {
    background-color: #ffffff1a !important;
  }
`;

export const PaginationSimple = (
  props: PaginationSimpleProps & CommonProps
) => {
  const {
    nextPageUrl,
    previousPageUrl,
    disabled,
    small,
    total,
    page,
    pageSize: pSize,
    onChange,
    onPageSizeChange: onPgSizeChange,
    ...commonProps
  } = props;

  const [pageSize, setPageSize] = useState(pSize);

  const onClick = (url: string | number) => () => {
    onChange(url);
  };

  const onPageSizeChange = (size: number) => {
    onPgSizeChange(size);
    setPageSize(size);
  };

  return (
    <Flex
      justifyContent="flex-end"
      alignItems="center"
      style={{ position: "relative", fontSize: small ? 12 : "auto" }}
      my={1}
      {...commonProps}
    >
      <SelectLabel style={{ fontSize: small ? 12 : "auto" }}>
        Rows per page:
      </SelectLabel>
      <Box width={50} ml="10px" mr="12px">
        <SelectBox
          id="page-size-select"
          options={options}
          onChange={onPageSizeChange}
          value={pageSize}
          fontSize="12px"
          height={26}
          width="50px"
          iconPt="0"
          noBorderBottom={true}
        />
      </Box>
      {(total || total === 0) && page ? (
        <Text color="text.default" ml="20px" mr={3} fontSize={3}>{`${
          total === 0 ? 0 : (page - 1) * pageSize + 1
        }-${
          page * pageSize < total ? page * pageSize : total
        } of ${total}`}</Text>
      ) : (
        ""
      )}
      <PaginationButton
        size={small ? "smaller" : "small"}
        onClick={onClick(previousPageUrl)}
        disabled={
          previousPageUrl === null ||
          previousPageUrl === -1 ||
          disabled ||
          (previousPageUrl !== 0 && !previousPageUrl)
        }
      >
        <Icon name="ArrowLeftPagination" size={24}></Icon>
      </PaginationButton>
      <PaginationButton
        size={small ? "smaller" : "small"}
        onClick={onClick(nextPageUrl)}
        disabled={!nextPageUrl || disabled}
        mr={1}
      >
        <Icon name="ArrowRightPagination" size={24}></Icon>
      </PaginationButton>
    </Flex>
  );
};
