import { InputBase, SelectBox } from "app/shared";
import { DataSource } from "app/dataSource/models";
import { DashboardComponentsConfiguration } from "app/dashboard/components/DashboardComponentsConfiguration";
import { componentSchemaGenerator } from "app/dashboard/models/component";
import { SatelliteDefinition } from "app/satelliteDefinition/models";
import { SatelliteInstance } from "app/satellite/models";
import { CheckBox } from "components";
import { FileWidget } from "app/shared/form";
import { ExtendedDashboard } from "./index";

export const schemaGenerator = (
  dataSources: DataSource[],
  satelliteDefinitions: any[],
  defaultSatelliteDefinition: number,
  selectedSatelliteDefinition: SatelliteDefinition,
  formData: any,
  satelliteInstances: SatelliteInstance[],
  addChange?: () => void
) => {
  return {
    type: "object",
    title: "Dashboard",
    required: [
      "name",
      "satDefinitionId",
      "size",
      // "type",
      // "permissions",
      "components"
    ],
    properties: {
      satDefinitionId: {
        type: "number",
        title: "Satellite definition",
        enum: satelliteDefinitions.map((satelliteDefinition) =>
          satelliteDefinition.id
            ? satelliteDefinition.id
            : satelliteDefinition.satelliteDefinitionId
        ),
        enumNames: satelliteDefinitions.map(
          (satelliteDefinition) =>
            `${satelliteDefinition.name} #${
              satelliteDefinition.id
                ? satelliteDefinition.id
                : satelliteDefinition.satelliteDefinitionId
            }`
        ),
        default: defaultSatelliteDefinition
      },
      name: {
        type: "string"
      },
      description: {
        type: ["null", "string"]
      },
      size: {
        type: "object",
        properties: {
          row: {
            type: "number",
            default: 1
          },
          col: {
            type: "number",
            default: 1
          }
        }
      },
      dimensions: {
        tile: "Dimensions",
        description:
          "Dimensions of the dashboard page in pixels. If left empty the dashboard page will fill the screen.",
        type: "object",
        properties: {
          width: {
            title: "Width (Px)",
            type: ["null", "number"]
          },
          height: {
            title: "Height (Px)",
            type: ["null", "number"]
          }
        }
      },
      type: {
        type: "string",
        title: "Dashboard type",
        enum: ["PerSatellite"],
        default: "PerSatellite"
      },
      permissions: {
        type: "string",
        title: "Dashboard permission",
        enum: ["Private", "Public"],
        default: "Public"
      },
      components: {
        type: "array",
        formData: formData,
        selectedSatelliteDefinition,
        dataSources,
        satelliteInstances,
        default: [],
        items: componentSchemaGenerator(
          dataSources,
          selectedSatelliteDefinition
        ),
        addChange: addChange && addChange
      }
    }
  };
};

export const uiSchema = {
  name: { "ui:widget": InputBase },
  satDefinitionId: { "ui:widget": SelectBox },
  description: { "ui:widget": InputBase },
  size: {
    row: { "ui:widget": InputBase },
    col: { "ui:widget": InputBase }
  },
  dimensions: {
    width: { "ui:widget": InputBase },
    height: { "ui:widget": InputBase }
  },
  type: { "ui:widget": "hidden" },
  permissions: { "ui:widget": "hidden" },
  components: { "ui:field": DashboardComponentsConfiguration }
};


export const uiSchemaImport = {
  file: {
    "ui:widget": FileWidget
  }
};

export const schemaImport = () => ({
  type: "object",
  properties: {
    file: {
      title: " ",
      type: "string"
    }
  }
});


export const schemaExport = (dashboards: ExtendedDashboard[] = []) => {
  return {
    type: "object",
    properties: {
      dashboardId: {
        type: "string",
        title: "Dashboards",
        enum: ["ALL", ...dashboards.map((d: ExtendedDashboard) => d.id)],
        enumNames: ["ALL", ...dashboards.map((d: ExtendedDashboard) => d.name)]
      },
      exportRawObject: {
        type: "boolean",
        title: "Export raw object without remaping keys",
        default: false
      }
    }
  };
};

export const uiSchemaExport = {
  dashboardId: { "ui:widget": SelectBox },
  exportRawObject: { "ui:widget": CheckBox }
};

export enum ActionType {
  EXPORT = "Export",
  IMPORT = "Import",
}

type SchemaMap = {
  [key: string]: {
    schema: Object;
    uiSchema: Object;
    type: ActionType;
  };
};

export const getSchemas = (op: any, dashboards: any) => {
  const schemaMap: SchemaMap = {
    [ActionType.EXPORT]: {
      schema: schemaExport(dashboards),
      uiSchema: uiSchemaExport,
      type: ActionType.EXPORT,
    },
    [ActionType.IMPORT]: {
      schema: schemaImport(),
      uiSchema: uiSchemaImport,
      type: ActionType.IMPORT,
    },
  };

  return schemaMap[(op as any)?.type] || { schema: null, uiSchema: null, type: "" };
};
