import { Resource } from "../models";
import { ResourceActions, ResourceActionType } from "../actions";

interface ResourceStoreState {
  resources: Resource[];
}

const initialState: ResourceStoreState = {
  resources: []
};

export const resourceReducer = (
  state: ResourceStoreState = initialState,
  action: ResourceActions
): ResourceStoreState => {
  switch (action.type) {
    case ResourceActionType.FetchResources:
      return { resources: action.payload };
    default:
      return state;
  }
};
