import React, { useEffect, useRef } from "react";
import { Box, Flex, Label, Button, Grid } from "primitives";
import { Switch } from "components";
import { Form, SelectBox } from "app/shared";
import { schemaGenerator, uiSchemaGenerator } from "../schemaGenerator";
import { TelecommandSpec, TelecommandFormData } from "../models";
import { TelecommandSendConfirmation } from "./TelecommandExecutionHelpers";
import debounce from "lodash/debounce";
import { TelecommandStack as ITelecommandStack } from "app/telecommandStack/models";
import { getWritableArmsPaths } from "app/resources_v2/services";
import {
  formValidator,
  automaticallyClickAddButtons,
  addDefaultArmsPath,
  getConsumedFormData
} from "../utils/index";
import { useFormState } from "../reducers/telecommandExecutionFormReducer";
import { FeedbackStatus } from "app/feedback/models";
import { filterTCStacksBySatID } from "app/telecommandStack/helpers";
import { sortObjectsByLabel } from "utils/arrays";

interface TcCreationFormProps {
  initialFormData: TelecommandFormData;
  onSubmitTelecommandHandler: (data: any) => Promise<any>;
  satelliteId: number;
  satelliteLabel: string;
  defaultFieldsVisualization: string;
  automaticFill: boolean;
  sendConfirmation: boolean;
  preventMultiSend: boolean;
  addToStack: (telecommand: any, telecommandStack: ITelecommandStack) => void;
  telecommandStacks: any;
  selectedPassage?: any;
  setFeedback: (title: string, status: string) => void;
  selectedTelecommandId: string;
  telecommandSpecs: TelecommandSpec[];
}

export const TelecommandCreationForm = ({
  initialFormData,
  onSubmitTelecommandHandler,
  satelliteId,
  satelliteLabel,
  defaultFieldsVisualization,
  automaticFill,
  sendConfirmation,
  preventMultiSend,
  selectedPassage,
  telecommandStacks,
  addToStack,
  setFeedback,
  selectedTelecommandId,
  telecommandSpecs
}: TcCreationFormProps) => {
  const currentTc = useRef<any>(null);
  const organization = useRef<string>("");

  const { state, actions } = useFormState();

  const {
    formData,
    loading,
    hideDefaultFields,
    showDefaultFieldsVisualizationSwitch,
    telecommandSpecState,
    modalOpen,
    selectedTelecommandStackId
  } = state;

  // when component mounts
  useEffect(() => {
    actions.setDefaultView(defaultFieldsVisualization);
    const addButtonWorkAroundInterval = setInterval(
      automaticallyClickAddButtons,
      50
    );
    getWritableArmsPaths().then((res: any) => {
      organization.current = res.data.organization;
    });

    return () => {
      clearInterval(addButtonWorkAroundInterval);
      actions.setInitialState();
    };
  }, [defaultFieldsVisualization, automaticFill]);

  // when Re-use in tc sent or edit in tc stack are clicked
  useEffect(() => {
    const selectedTc = telecommandSpecs.filter(
      (t: any) => t.id === selectedTelecommandId
    )[0];
    if (selectedTc && initialFormData) {
      actions.setFormData(selectedTc, initialFormData);
    }
    currentTc.current = selectedTc;
  }, [selectedTelecommandId, initialFormData, telecommandSpecs]);

  // when new tc is selected
  useEffect(() => {
    if (
      !initialFormData &&
      (!telecommandSpecState ||
        telecommandSpecState.id !== selectedTelecommandId)
    ) {
      actions.setInitialState();
      actions.setTelecommandSpecState(currentTc.current, null, automaticFill);
    }
  }, [
    selectedTelecommandId,
    initialFormData,
    telecommandSpecState,
    automaticFill
  ]);

  useEffect(() => {
    actions.setTelecommandSpecState(currentTc.current, null, automaticFill);
  }, [formData, automaticFill]);

  return (
    <Grid data-testid="TelecommandCreationForm">
      {showDefaultFieldsVisualizationSwitch ? (
        <Flex alignItems="center" m={2}>
          <Flex mr={2}>
            <Label>Hide default fields:</Label>
          </Flex>
          <Switch
            checked={hideDefaultFields}
            onChange={(event) =>
              actions.toggleHideConstants(
                event.currentTarget.checked,
                showDefaultFieldsVisualizationSwitch
              )
            }
          />
        </Flex>
      ) : null}
      {renderForm()}
    </Grid>
  );

  function renderForm() {
    if (
      !telecommandSpecState ||
      telecommandSpecState.id !== selectedTelecommandId
    ) {
      return null;
    }
    const schema = schemaGenerator.generate(telecommandSpecState);

    const extraProps = { satelliteId };
    const uiSchema = uiSchemaGenerator.generate(
      telecommandSpecState,
      hideDefaultFields,
      extraProps
    );

    const options = filterTCStacksBySatID(
      satelliteId,
      telecommandStacks.telecommandStacks
    );

    const selectOptions = {
      enumOptions: sortObjectsByLabel(options, "label")
    };

    const consumedFormFata = getConsumedFormData(formData);

    return (
      <Box position="relative">
        <Form
          id="telecommands"
          formData={consumedFormFata}
          schema={schema}
          uiSchema={uiSchema}
          validate={(data: any, e: any) =>
            formValidator(data, e, currentTc.current.args)
          }
          onSubmit={(data: any, e: any) => {
            selectedTelecommandStackId
              ? actions
                  .onSendToStack(
                    data,
                    e,
                    currentTc.current,
                    selectedPassage,
                    telecommandStacks,
                    selectedTelecommandStackId,
                    addToStack
                  )
                  .then(() => {
                    actions.setInitialState();
                    setFeedback(
                      "Telecommand successfully added to stack",
                      FeedbackStatus.SUCCESS
                    );
                  })
                  .catch(() => {
                    setFeedback(
                      "Error adding telecommand to stack",
                      FeedbackStatus.ERROR
                    );
                  })
              : sendConfirmation
              ? actions.onFormSubmitWithConfirmation(e)
              : actions.onFormSubmit(
                  data,
                  e,
                  currentTc.current,
                  onSubmitTelecommandHandler,
                  preventMultiSend
                );
          }}
          onChange={debounce(({ formData: data }: any) => {
            addDefaultArmsPath(
              data,
              organization.current,
              satelliteLabel,
              currentTc.current
            );
            actions.setFormData(null, data);
          }, 500)}
        >
          <Flex flexDirection="row" height="100%">
            <Button
              type="submit"
              form="telecommands"
              disabled={loading || selectedTelecommandStackId}
              mt={2}
            >
              Send
            </Button>
            <Button
              type="submit"
              disabled={loading || !selectedTelecommandStackId}
              mt={2}
              ml={2}
              mr={2}
            >
              Add to Stack
            </Button>
            <SelectBox
              height={40}
              id="telecommandstack-select"
              options={selectOptions}
              onChange={(tcStackId: any) =>
                actions.setSelectedTelecommandStackId(tcStackId)
              }
              value={selectedTelecommandStackId}
            />
          </Flex>
        </Form>
        <TelecommandSendConfirmation
          cancel={() => actions.setModalOpen(false)}
          sendTelecommand={() =>
            actions.onFormSubmit(
              formData,
              null,
              currentTc.current,
              onSubmitTelecommandHandler,
              preventMultiSend
            )
          }
          modalOpen={modalOpen}
        />
      </Box>
    );
  }
};
