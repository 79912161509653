import React from "react";
import { Switch, Route } from "react-router-dom";
import {
  AlertDefinitions,
  Alerts,
  AlertDefinitionCreate,
  AlertDefinitionEdit,
  AlertDefinitionImport,
  AlertDefinitionExport
} from ".";
import { AlertDefinitionDeleteAll } from "./components/AlertDefinitionDeleteAll";

export const AlertRouters = () => (
  <Switch>
    <Route exact path="/alerts" component={Alerts} />
    <Route exact path="/alert-definitions" component={AlertDefinitions} />
    <Route
      exact
      path="/alert-definitions/create"
      component={AlertDefinitionCreate}
    />
    <Route
      exact
      path="/alert-definitions/import"
      component={AlertDefinitionImport}
    />
    <Route
      exact
      path="/alert-definitions/export"
      component={AlertDefinitionExport}
    />
    <Route
      exact
      path="/alert-definitions/delete-all"
      component={AlertDefinitionDeleteAll}
    />
    <Route path="/alert-definitions/:id" component={AlertDefinitionEdit} />
  </Switch>
);
