import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  fetchGroundStationConfig,
  createGroundStationConfig
} from "../services";
import { fetchGroundStationsAction } from "../actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import GroundStationConfigEdit from "app/groundStation/components/GroundStationConfigEdit";

const mapStateToProps = (state: any) => ({
  groundStations: state.groundStations.groundStations,
  satellites: state.constellations.dashboard
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  fetchGroundStations: () => dispatch(fetchGroundStationsAction()),
  createGroundStationConfig,
  fetchGroundStationConfig: (satelliteID: number, groundStationID: number) =>
    fetchGroundStationConfig(satelliteID, groundStationID)
});

export const GroundStationEditContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GroundStationConfigEdit)
);
