import {
  DataSourceReadingResponse,
  DataSourceReadingDTO,
  ReadingDTO,
  ValueDTO,
  ReadingResponse,
  ValueResponse
} from "app/telemetry/models";
import { AlertValueStatus } from "app/alert/models";

/*
 * "Data Transfer Object" Function
 */

export const convertToLocalTelemetryModel = (
  telemetry: DataSourceReadingResponse
): DataSourceReadingDTO => {
  const dto: DataSourceReadingDTO = {
    dataSource: telemetry.dataSource,
    readings: []
  };
  telemetry.readings.forEach((reading: ReadingResponse) => {
    const readingDTO: ReadingDTO = {
      bootCount: reading.bootCount,
      onboardTimeMillis: reading.onboardTimeMillis,
      sendTimestamp: reading.sendTimestamp,
      receiveTimestamp: reading.receiveTimestamp,
      valueIsArray: reading.valueIsArray,
      valueHumanReadable: reading.valueHumanReadable,
      value: []
    };
    reading.value.forEach((value: ValueResponse | any, valueIndex: number) => {
      //alert property should be object instead array of objects
      let alert = null;
      const isDynamic = value.alert[0]?.type === "DynamicAlertValue";
      if (value.alert[0]) {
        alert = {
          index: value.index,
          type: value.alert[0].type
        };
        if (value.alert[0].type === AlertValueStatus.AlertValue) {
          alert = { ...alert, ...value.alert[0].alertLevel };
        } else if (value.alert[0].type === AlertValueStatus.OutOfBoundsValue) {
          alert = { ...alert, ...value.alert[0].valueBoundary };
        }
      }
      const valueDTO: ValueDTO = {
        index: value.index,
        value: value.value,
        alert: isDynamic ? value.alert : alert
      };
      readingDTO.value.push(valueDTO);
    });
    dto.readings.push(readingDTO);
  });
  return dto;
};
