import { TelemetryFrame } from "app/telemetry/models";
import React from "react";

export const LITERAL_ARG_TYPE = "Literal";
export const ENUM_ARG_TYPE = "Enum";
export const ARRAY_ARG_TYPE = "Array";
export const GROUP_ARG_TYPE = "Group";
export const FILE_ARG_TYPE = "File";

export const MANUAL_KIND = "manual";
export const AUTO_KIND = "auto";
export const CONST_KIND = "constant";

export enum TelecommandExecutionActionTypes {
  SelectTelecommand = "SELECT_TC_TO_EXECUTE",
  SelectExecutionMode = "SELECT_TC_EXECUTION_MODE",
  ClearTelecommand = "CLEAR_TC_EXECUTION"
}

export enum TelecommandListActionTypes {
  ClearResponse = "CLEAR_TC_RESPONSE",
  TelecommandResponse = "TC_RESPONSE"
}

export enum TelecommandExecutionMode {
  Form = "Form",
  Terminal = "Terminal"
  //   JsonUpload = "JsonUpload"
}

export enum TelecommandType {
  Sync = "Sync",
  Async = "Async"
}

export enum TelecommandExecutionStatus {
  Sending = "Sending",
  Sent = "Sent",
  NotSent = "NotSent"
}

export enum TelecommandResponseLevel {
  ERROR = "ERROR",
  WARNING = "WARNING",
  INFO = "INFO",
  SUCCESS = "SUCCESS",
  INVALID = "INVALID"
}

export enum TelecommandResponseStatus {
  TcAck = "TcAck",
  TcNack = "TcNack",
  TcNone = "TcNone"
}

/**
 * Redux store state
 */
export interface TelecommandExecutionStoreState {
  telecommandId?: string;
  telecommandExecutionMode?: TelecommandExecutionMode;
  telecommandFormData?: any;
  telecommandPayload?: TelecommandPayload;
  // The telecommand  database id
  telecommandRecordId?: number;
}

export interface TelecommandListStoreState {
  responses: {
    [executedTelecommandId: number]: TelemetryFrame;
  };
}

/**
 * Historical executed telecommands
 */

export interface TelecommandResponse {
  statusName: string;
  level: TelecommandResponseLevel;
  reason?: string | null;
}

export interface ExecutedTelecommand {
  satId: number;
  id: number;
  sentAt: string;
  groundStation: null;
  groundStationName: string;
  username: string;
  tcType: TelecommandType;
  tcLabel: string;
  status: TelecommandExecutionStatus;
  response: TelecommandResponse;
  telecommandExecutionPayload: { telecommand: TelecommandPayload };
  telecommandProcessedPayload: { telecommand: TelecommandPayload };
}

export interface IFiltersTCList {
  satId: string | null;
  groundStationName: string | null;
  username: string | null;
  tcLabel: string | null;
  timestamp: string | null;
  status: string | null;
  response: string | null;
}

/**
 * Definition of the specification of the fields for a telecommand. It differs from the
 * TelecommandPayload because the args are JSON schema elements
 */
export interface TelecommandSpec {
  id: string;
  label: string;
  args: FormInputArg[];
  type?: string;
  version?: string;
  satId?: number;
  satDefId?: number | null;
  current: number;
  total: number;
}

export interface TelecommandPayload {
  telecommandUri: string;
  params: object;
}

export type TelecommandFormData = any;

export class AuroraTelecommandPayload implements TelecommandPayload {
  readonly telecommandUri: string;
  readonly params: any;
  private passageId?: number;

  private constructor(id: string, params: object) {
    this.telecommandUri = id;
    this.params = params;
  }

  toFormData(): TelecommandFormData {
    return this.telecommandParamTransform(this.params);
  }

  private telecommandParamTransform(param: any): any {
    Object.keys(param).forEach((key) => {
      if (param && typeof param[key] === "object" && param[key] !== null) {
        param[key] = [this.telecommandParamTransform(param[key])];
      }
    });
    return param;
  }

  setPassageId(id: number) {
    this.passageId = id;
  }

  toOutputModel(): {
    telecommand: TelecommandPayload;
    passageId?: number;
  } {
    return {
      telecommand: {
        telecommandUri: this.telecommandUri,
        params: this.params
      },
      passageId: this.passageId
    };
  }

  static build(rawPayload: TelecommandPayload): AuroraTelecommandPayload {
    return new AuroraTelecommandPayload(
      rawPayload.telecommandUri,
      rawPayload.params
    );
  }

  static from(
    spec: TelecommandSpec,
    formData: TelecommandFormData
  ): AuroraTelecommandPayload {
    //Current format is something like a { csp: [{a: "b", c: "d"}]}
    //We want csp: {a: "b", c: "d"}
    const transformArraysIntoObjects = (data: TelecommandFormData) => {
      Object.keys(data).forEach((key, index) => {
        if (
          Array.isArray(data[key]) &&
          data[key].length === 1 &&
          typeof data[key][0] === "object"
        ) {
          data[key] = transformArraysIntoObjects(data[key][0]);
        }
      });
      return data;
    };
    return new AuroraTelecommandPayload(
      spec.id,
      transformArraysIntoObjects(formData)
    );
  }
}

export enum Commands {
  DtpDownloadFile = "DTP DOWNLOAD FILE",
  DtpDownloadMem = "DTP DOWNLOAD MEM"
}

export enum TelecommandFormActionTypes {
  setFormData = "SET_FORM_DATA",
  setLoading = "SET_LOADING",
  setDefaultFields = "SET_DEFAULT_FIELDS",
  setTelecommandSpecState = "SET_TELECOMMAND_SPEC_STATE",
  setModalOpen = "SET_MODAL_OPEN",
  setSelectedTelecommandStackId = "SET_SELECTED_TELECOMMAND_STACK_ID",
  setOrganization = "SET_ORGANIZATION",
  setInitialState = "SET_INITIAL_STATE"
}

export interface FormInputArg {
  argType: string | object;
  dataType?: string;
  default?: number | string;
  id: string;
  kind?: string;
  name: string;
  size?: string | number;
  range?: { min: Number; max: Number };
  values?: { key: string; value: string }[];
  groupSpec?: Array<FormInputArg>;
  filter?: any;
  itemSpec?: { dataType: string };
}

export interface BuilInputArgs {
  arg: FormInputArg;
  tc?: React.RefObject<{}>;
  curNode?: React.RefObject<{}>;
  telecommandSpec: TelecommandSpec;
  path?: Array<string>;
}

export interface TelecommandFormValidationArgs {
  spec: any;
  formData: object;
  errors: object;
  key: string;
  errorsKey: string;
}

export interface TcFormDataCheckerPayload {
  item: { id: string; value: string | Array<any> };
  telecommandSpec: TelecommandSpec;
  groupSpec?: any;
}

export interface FormData {
  csp: Array<{ armsPath: string }>;
}

export interface ParsedSSE {
  payload: string;
  msgType: MessageType;
  satId: number;
  timeStamp: string;
}

export enum MessageType {
  TELECOMMAND_SUBMIT = "TelecommandSubmit",
  TELECOMMAND_RESPONSE = "TelecommandResponse"
}
