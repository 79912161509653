import { COLORS } from "../../utils";

export const legendSettings = (showLegend: any, showLegendAsToolTip: any) => {
  const filterLegend = () => {
    if (showLegend) {
      return showLegend;
    }
    return showLegendAsToolTip;
  };

  return {
    show: filterLegend(),
    live: true,
    markers: {
      // first item is timestamps, data starts at index 1
      fill: (_: any, i: number) => {
        return COLORS[i - 1];
      }
    }
  };
};
