import React, { Component } from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import { Flex, Icon, Box, Text, Button } from "primitives";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "components/table/Table";
import { AlertDTO, SelectedAlert, AlertState, Colors } from "../models";
import { getActiveSatellite } from "app/shared/utils";
import {
  getAlertColor,
  getAlertDetails,
  getAlertStatus,
  getAlertColorForArrayValue
} from "app/alert/helpers";
import { DateTimeLabel, DeleteButton, CheckBox, Badge } from "components";
import { DataSourceFilterTree } from "./DataSourceFilterTree";
import { PaginationSimple } from "app/shared";
import { DataSource } from "app/dataSource/models";
import { RouteComponentProps } from "react-router-dom";
import { AlertDetails, AlertIndexDetails } from "./AlertDetails";

interface AlertsProps extends RouteComponentProps {
  getAlerts: (
    satelliteId: number,
    pageSize: number,
    paginationUrl?: string,
    dismissed?: string | undefined,
    datasources?: number[] | null | undefined
  ) => Promise<any>;
  getAlertsDataSourceList: (satelliteId: number) => Promise<any>;
  dismissAlerts: (
    satelliteId: number,
    alertIds: SelectedAlert[],
    pageSize: number,
    datasources?: number[] | null | undefined
  ) => void;
  dismissAllAlerts: (
    satelliteId: number,
    datasourceId?: number | null
  ) => Promise<any>;
  setAlerts: (alerts: AlertDTO[]) => void;
  satellites: [
    {
      id: number;
      label: string;
    }
  ];
  alerts: AlertState;
  alertCounter: {
    numCritical: number;
    numWarnings: number;
    numOthers: number;
  };
  dismissAlertsState: {
    loading: boolean;
    error: string;
  };
  location: any;
}

interface AlertsState {
  selectedAlerts: SelectedAlert[];
  toggleAllSelected: boolean;
  timer?: number | undefined;
  paginationUrl: any;
  pageSize: number;
  nextPageUrl: string;
  previousPageUrl: string;
  initialAlertsNumber: number | string;
  alertsDataSourceList: DataSource[];
  selectedDataSources: number[] | null;
  groupedByDatasource: boolean;
  alertsByDataSource: AlertDTO[];
  previousContext: {
    selectedDataSources: number[] | null;
    groupedByDatasource: boolean;
  } | null;
}

export class Alerts extends Component<AlertsProps, AlertsState> {
  private lastFetch: Date = new Date();

  private initialState: AlertsState = {
    selectedAlerts: [],
    toggleAllSelected: false,
    timer: undefined,
    paginationUrl: null,
    pageSize: 25,
    nextPageUrl: "",
    previousPageUrl: "",
    initialAlertsNumber: 0,
    alertsDataSourceList: [],
    selectedDataSources: null,
    groupedByDatasource: true,
    alertsByDataSource: [],
    previousContext: null
  };

  constructor(props: AlertsProps) {
    super(props);
    this.state = this.initialState;
  }

  componentDidMount() {
    this.startAlerts();
  }

  componentDidUpdate(prevProps: AlertsProps, prevState: AlertsState) {
    if (
      this.props.location?.state?.datasourceId &&
      !prevState.selectedDataSources?.includes(
        this.props.location?.state?.datasourceId
      )
    ) {
      this.setState({
        groupedByDatasource: false,
        selectedDataSources: [this.props.location.state.datasourceId]
      });
    }
    const oldActive = getActiveSatellite(prevProps.satellites);
    const newActive = getActiveSatellite(this.props.satellites);
    const satelliteIdIsSet = !oldActive && newActive;
    const satelliteIdChanges =
      oldActive && newActive !== null && oldActive.id !== newActive.id;
    if (satelliteIdIsSet || satelliteIdChanges) {
      this.setState(this.initialState);
      this.startAlerts();
    }

    if (prevState.groupedByDatasource !== this.state.groupedByDatasource) {
      this.startAlerts();
    }

    if (
      prevState &&
      this.state &&
      prevState.selectedDataSources &&
      this.state.selectedDataSources &&
      prevState.selectedDataSources.length !==
        this.state.selectedDataSources.length
    ) {
      this.subscribeAlerts();
    }

    // To avoid updating the state endlessly, only update if the new alerts counter number is greater than zero
    const alertsCounter = this.getTotalAlertsNumber(this.props.alertCounter);
    if (!this.state.initialAlertsNumber && alertsCounter > 0) {
      this.setState({
        initialAlertsNumber: alertsCounter
      });
    }

    // If there alerts are grouped by data source, there are new alerts and last fetch was more than one second ago, the alerts should refresh automatically
    const { alertCounter } = this.props;
    const { groupedByDatasource } = this.state;
    const newAlertsNumber = this.getNewAlertsNumber(alertCounter);
    const millisSinceLastFetch =
      new Date().getTime() - this.lastFetch.getTime();
    if (
      groupedByDatasource &&
      newAlertsNumber > 0 &&
      millisSinceLastFetch > 1000
    ) {
      this.startAlerts();
    }
  }

  render() {
    const {
      alerts: { error, alerts },
      alertCounter,
      dismissAlertsState
    } = this.props;

    const {
      pageSize,
      nextPageUrl,
      previousPageUrl,
      selectedAlerts,
      alertsDataSourceList,
      selectedDataSources,
      groupedByDatasource,
      alertsByDataSource,
      previousContext
    } = this.state;

    const newAlerts = this.getNewAlertsNumber(alertCounter);
    return (
      <Flex overflow="visible">
        <Flex
          flexDirection="column"
          width="30%"
          minHeight="100vh"
          p={2}
          ml={2}
          mr={2}
          bg="fill.0"
          bgOpacity={6}
        >
          <Flex mb={3} pb={2} borderBottom={2}>
            <Button
              type="button"
              disabled={!previousContext}
              onClick={() => this.goToPreviousContext()}
              mx={1}
            >
              <Icon name="ArrowLeft" size={18} />
            </Button>
            <Button type="button" onClick={() => this.clearContext()} mx={1}>
              <Icon name="Reset" size={20} />
            </Button>
          </Flex>
          <Flex overflow="visible">
            <CheckBox
              checked={groupedByDatasource}
              label={"Group By Data Source"}
              onChange={() =>
                this.onChangeGroupedByDataSource(!groupedByDatasource)
              }
            />
          </Flex>
          <Flex overflow="visible" flex={1} flexDirection="column">
            <Flex p={2} mb={1} alignItems="center" overflow="visible">
              <Flex flex={1}>
                <Text mr={1} color="text.white">{`${
                  selectedDataSources ? selectedDataSources.length : 0
                }`}</Text>
                <Text color="text.label">{`Selected Data Sources`}</Text>
              </Flex>
              <Link onClick={() => this.selectAllDataSources()}>
                Select All
              </Link>
              <Link onClick={() => this.deselectAllDataSources()}>
                Deselect All
              </Link>
            </Flex>
            <DataSourceFilterTree
              alertsDataSourceList={alertsDataSourceList}
              selectedDataSources={selectedDataSources}
              alertsByDataSource={alertsByDataSource}
              expandedDataSource={
                selectedDataSources && selectedDataSources.length === 1
                  ? selectedDataSources[0]
                  : null
              }
              satellite={getActiveSatellite(this.props.satellites)}
              toggleDataSource={(dataSourceId: number) =>
                this.toggleDataSource(dataSourceId)
              }
            />
          </Flex>
        </Flex>
        <Flex overflow="visible" flexDirection="column" width="70%">
          <Table data-testid="Alerts">
            <TableHead>
              <TableRow bg="fill.0">
                <TableCell width={200}>Date</TableCell>
                <TableCell width={200}>Data source</TableCell>
                <TableCell width={200}>Alert Type</TableCell>
                <TableCell width={200}>Severity</TableCell>
                <TableCell width={200}>
                  <span>Details</span>
                </TableCell>
                <TableCell width={50}>
                  {!groupedByDatasource ? (
                    <CheckBox
                      disabled={alerts.length === 0}
                      checked={this.state.toggleAllSelected}
                      onChange={() => this.toggleSelectAll()}
                    />
                  ) : null}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newAlerts !== 0 && (
                <TableRow>
                  <TableCell colSpan={4} border="0!important">
                    {`${
                      newAlerts > 0
                        ? `There are new alerts`
                        : `There are changes on alerts`
                    }, please, press the refresh button`}
                  </TableCell>
                  <TableCell>
                    <Box
                      overflow="visible"
                      onClick={() => this.resetAlertCounter()}
                    >
                      <Badge
                        count={newAlerts > 0 ? newAlerts.toString() : "..."}
                        data-testid="AlertCounter"
                      >
                        <Icon name="Replay" color={"text.white"} size={20} />
                      </Badge>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {alerts.length > 0 &&
                alerts.map(
                  (alert: AlertDTO, index) =>
                    selectedDataSources &&
                    selectedDataSources.indexOf(alert.dataSourceId) !== -1 &&
                    this.renderRow(
                      alert,
                      selectedAlerts.find((it) => it.id === alert.id) != null,
                      index
                    )
                )}
              {!error.status && alerts.length === 0 && newAlerts === 0 && (
                <TableRow>
                  <TableCell colSpan={5}>No pending alerts</TableCell>
                </TableRow>
              )}

              {error.status && (
                <TableRow>
                  <TableCell color="#f00" colSpan={5}>
                    {error.msg}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              {!groupedByDatasource ? (
                <TableRow bg="fill.0">
                  <TableCell colSpan={5}>
                    <Flex
                      flexDirection="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <DeleteButton
                        disabled={
                          alerts.length === 0 || selectedAlerts.length === 0
                        }
                        onClick={() => this.dismissSelected()}
                        ml={2}
                      >
                        {dismissAlertsState.loading && "Working..."}

                        {!dismissAlertsState.loading && "Dismiss selected"}
                      </DeleteButton>
                    </Flex>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableFooter>
          </Table>
          {!groupedByDatasource ? (
            <PaginationSimple
              pageSize={pageSize}
              nextPageUrl={nextPageUrl}
              previousPageUrl={previousPageUrl}
              onChange={(paginationUrl: string | number) =>
                this.setState({ paginationUrl }, () => this.subscribeAlerts())
              }
              onPageSizeChange={(size: number) =>
                this.setState({ pageSize: size, paginationUrl: null }, () =>
                  this.subscribeAlerts()
                )
              }
            />
          ) : null}
        </Flex>
      </Flex>
    );
  }

  private renderRow(alert: AlertDTO, checked: boolean, id: number) {
    const color = getAlertColor(alert);
    const details = getAlertDetails(alert);
    const status = getAlertStatus(alert);
    const type = alert?.type;
    const colorsByValue = getAlertColorForArrayValue(alert, true) || [];
    const { groupedByDatasource } = this.state;

    return (
      <TableRow key={id} color={color} height={40}>
        <TableCell>
          <DateTimeLabel
            value={new Date(alert.receiveTimestamp)}
            format="long"
            color={color}
          />
        </TableCell>
        <TableCell>
          <Text
            cursor="pointer"
            textDecoration="underline"
            onClick={() => {
              this.selectSingleDataSource(alert.dataSourceId);
            }}
          >
            {alert.dataSourceName}
          </Text>
        </TableCell>
        <TableCell>
          <Text>{alert?.type}</Text>
        </TableCell>
        <TableCell>
          {status &&
            status.split(",").map((stat: string, index: number) => {
              if (!stat) return null;
              return (
                <AlertIndexDetails>
                  <Box key={index}>
                    <Text
                      color={
                        stat === "Out Of Bounds"
                          ? Colors.OutOfBounds
                          : (Colors as any)[stat.trim()]
                      }
                    >
                      {stat}
                    </Text>
                    {index - 1 < status.length ? <br /> : null}
                  </Box>
                </AlertIndexDetails>
              );
            })}
        </TableCell>
        <TableCell>
          <AlertDetails
            type={type}
            colorsByValue={colorsByValue}
            details={details}
          />
        </TableCell>
        <TableCell>
          {!groupedByDatasource ? (
            <CheckBox
              checked={checked}
              onChange={() => this.toggleRow(alert, checked)}
            />
          ) : (
            <DeleteButton
              onClick={() => this.dismissSelected(alert.dataSourceId)}
            >
              Dismiss
            </DeleteButton>
          )}
        </TableCell>
      </TableRow>
    );
  }

  /** Context Change Functions */
  private goToPreviousContext() {
    const { previousContext } = this.state;
    this.setState({
      ...this.state,
      ...previousContext,
      paginationUrl: null,
      nextPageUrl: "",
      previousPageUrl: "",
      previousContext: null
    });
  }

  private clearContext() {
    this.setState(
      {
        selectedDataSources: null,
        groupedByDatasource: true,
        previousContext: null
      },
      () => this.subscribeAlerts()
    );
  }

  /** Group By DataSource Functions */
  private onChangeGroupedByDataSource(groupedByDatasource: boolean) {
    const { selectedDataSources } = this.state;
    const previousContext = {
      selectedDataSources,
      groupedByDatasource: !groupedByDatasource
    };
    this.setState({ groupedByDatasource, previousContext });
  }

  private selectSingleDataSource(dataSourceId: number) {
    const { selectedDataSources, groupedByDatasource } = this.state;
    if (selectedDataSources && selectedDataSources.length > 0) {
      const previousContext = { selectedDataSources, groupedByDatasource };
      this.setState({
        selectedDataSources: [dataSourceId],
        paginationUrl: null,
        groupedByDatasource: false,
        previousContext
      });
    }
  }

  /** Data Source Filter Functions */
  private toggleDataSource(dataSourceId: number) {
    let { selectedDataSources, groupedByDatasource } = this.state;
    const previousContext = { selectedDataSources, groupedByDatasource };

    if (selectedDataSources && selectedDataSources.includes(dataSourceId)) {
      selectedDataSources = selectedDataSources.filter(
        (dataSource: number) => dataSource !== dataSourceId
      );
    } else if (selectedDataSources) {
      selectedDataSources = [...selectedDataSources, dataSourceId];
    }
    this.setState({
      selectedDataSources,
      paginationUrl: null,
      previousContext
    });
  }

  private selectAllDataSources() {
    const { selectedDataSources, groupedByDatasource } = this.state;
    const previousContext = { selectedDataSources, groupedByDatasource };

    const newSelectedDataSources =
      this.state.alertsDataSourceList !== null
        ? this.state.alertsDataSourceList.map(
            (dataSource: DataSource) => dataSource.id
          )
        : [];
    this.setState({
      selectedDataSources: newSelectedDataSources,
      paginationUrl: null,
      previousContext
    });
  }

  private deselectAllDataSources() {
    const { selectedDataSources, groupedByDatasource } = this.state;
    const previousContext = { selectedDataSources, groupedByDatasource };
    this.setState({
      selectedDataSources: [],
      paginationUrl: null,
      previousContext
    });
  }

  /***/
  /** Select/Deselect alerts for dismissal list */

  private toggleSelectAll() {
    if (this.state.toggleAllSelected) {
      this.setState({
        toggleAllSelected: false,
        selectedAlerts: []
      });
    } else {
      const allAlerts: SelectedAlert[] = this.props.alerts.alerts.map(
        (alert: AlertDTO) => {
          return {
            id: alert.id,
            dataSourceId: alert.dataSourceId,
            receiveTimestamp: alert.receiveTimestamp
          };
        }
      );
      this.setState({
        toggleAllSelected: true,
        selectedAlerts: allAlerts
      });
    }
  }

  private toggleRow(alert: AlertDTO, currentCheckValue: boolean) {
    const desiredCheckValue = !currentCheckValue;
    const existingAlert = this.state.selectedAlerts.find(
      (it) => it.id === alert.id
    );
    if (desiredCheckValue && existingAlert == null) {
      // Add to selectedAlerts if the item is checked but the alert is not already selected
      this.setState({
        toggleAllSelected: false,
        selectedAlerts: this.state.selectedAlerts.concat([
          {
            id: alert.id,
            dataSourceId: alert.dataSourceId,
            receiveTimestamp: alert.receiveTimestamp
          }
        ])
      });
    } else if (!desiredCheckValue && existingAlert !== null) {
      // Remove from selectedAlerts if the item is unchecked but the alert is still selected
      this.setState({
        toggleAllSelected: false,
        selectedAlerts: this.state.selectedAlerts.filter(
          (it) => it.id !== alert.id
        )
      });
    }
  }
  /***/

  private dismissSelected(datasourceId: number | null = null) {
    const activeSatellite = getActiveSatellite(this.props.satellites);
    if (datasourceId !== null) {
      this.props.dismissAllAlerts(activeSatellite.id, datasourceId).then(() => {
        this.subscribeAlerts();
        this.setState({ initialAlertsNumber: "0" });
      });
    } else if (activeSatellite && this.state.selectedAlerts.length > 0) {
      this.props.dismissAlerts(
        activeSatellite.id,
        this.state.selectedAlerts,
        this.state.pageSize,
        this.state.selectedDataSources
      );

      this.setState({
        selectedAlerts: [],
        toggleAllSelected: false
      });
    } else if (activeSatellite && this.state.selectedAlerts.length === 0) {
      this.props
        .dismissAllAlerts(activeSatellite.id)
        // To bypass the verification in componentDidUpdate, used a string in initialAlertsNumber,
        // So that we could reset the alerts counter after dismiss all alerts
        .then(() => this.setState({ initialAlertsNumber: "0" }));
    }
  }

  private startAlerts() {
    this.subscribeAlerts();
    this.setState({
      initialAlertsNumber: this.getTotalAlertsNumber(this.props.alertCounter)
    });
  }

  private subscribeAlerts() {
    const activeSatellite = getActiveSatellite(this.props.satellites);
    const { groupedByDatasource } = this.state;
    if (activeSatellite) {
      this.lastFetch = new Date();
      //Get Alerts
      if (!groupedByDatasource) {
        this.props
          .getAlerts(
            activeSatellite.id,
            this.state.pageSize,
            this.state.paginationUrl,
            undefined,
            this.state.selectedDataSources
          )
          .then((response) => {
            this.setState({
              nextPageUrl: response.nextPageUrl,
              previousPageUrl: response.previousPageUrl
            });
          })
          .catch((error: Error) => {
            console.log("Error: ", error.message);
          });
      }
      //Get Alerts Data Source List
      this.props
        .getAlertsDataSourceList(activeSatellite.id)
        .then((alertsDataSourceListResponse) => {
          const newAlertsDataSourceList: DataSource[] =
            alertsDataSourceListResponse && alertsDataSourceListResponse.data
              ? alertsDataSourceListResponse.data.map(
                  (alertsDataSourceList: any) => alertsDataSourceList.datasource
                )
              : [];
          let { selectedDataSources, alertsDataSourceList } = this.state;
          //if first time rendering or if new datasources have alerts
          if (
            selectedDataSources === null ||
            newAlertsDataSourceList.length > alertsDataSourceList.length
          ) {
            selectedDataSources =
              this.state.selectedDataSources === null
                ? newAlertsDataSourceList.map(
                    (dataSource: DataSource) => dataSource.id
                  )
                : this.state.selectedDataSources;
          }

          const alerts: AlertDTO[] =
            alertsDataSourceListResponse && alertsDataSourceListResponse.data
              ? alertsDataSourceListResponse.data.map(
                  (alertsDataSource: any) => {
                    alertsDataSource.alert.dataSourceName =
                      alertsDataSource.datasource.fullyQualifiedName;
                    alertsDataSource.alert.dataSourceId =
                      alertsDataSource.datasource.id;
                    const alert = {
                      ...alertsDataSource.alert,
                      ...alertsDataSource.datasource
                    };
                    return alert;
                  }
                )
              : [];

          if (groupedByDatasource) {
            this.props.setAlerts(alerts);
          }

          this.setState({
            alertsDataSourceList: newAlertsDataSourceList,
            selectedDataSources,
            alertsByDataSource: alerts
          });
        })
        .catch((error: Error) => {
          console.log("Error: ", error.message);
        });
    }
  }

  private getNewAlertsNumber(totalAlerts: object): number {
    return (
      this.getTotalAlertsNumber(totalAlerts) -
      parseInt(this.state.initialAlertsNumber.toString(), 10)
    );
  }

  private getTotalAlertsNumber(totalAlerts: object): number {
    return Object.values(totalAlerts).reduce(
      (acc: number, current: number) => acc + current,
      0
    );
  }

  private resetAlertCounter() {
    this.startAlerts();
  }
}

const Link = styled(Text)`
  color: ${themeGet("colors.palette.brand.0")};
  font-size: 14px;
  margin: 5px;
  text-decoration: underline;
  cursor: pointer;
`;

const TableFooter = styled(Box)`
  display: table-footer-group;
  border-spacing: 0 1px;
  white-space: nowrap;
`;
