import { connect } from "react-redux";
import { SatelliteDefinitionEdit } from "../components/SatelliteDefinitionEdit";
import { getDataSourceUnits } from "app/dataSource";
import { withRouter } from "react-router-dom";
import { setFeedback } from "app/feedback/actions";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    getDataSourceUnits: () => getDataSourceUnits(),
    setFeedback: (title, status, details = "") =>
      dispatch(setFeedback(title, status, details))
  };
};

export const SatelliteDefinitionEditContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SatelliteDefinitionEdit)
);
