import React from "react";
import { Box } from "primitives";
import { Candidate } from "app/passageManager/models";
import styled from "styled-components";

const StyledBox = styled(Box)<{ status: string; bookable: boolean }>`
  text-align: center;
  padding: 6px 2px;
  background-color: #212232;
  border-radius: 2px;
  color: ${({ status, bookable }: any) =>
    status === "BOOKED" || bookable !== true ? "#7ab9dc" : "#88bd40"};
`;

export const Status = ({ status, bookable }: any) => {
  return (
    <StyledBox status={status} bookable={bookable}>
      {status}
    </StyledBox>
  );
};

export function getCandidateStatus(candidate: Candidate) {
  const status = candidate?.status
    ? String(candidate?.status).toUpperCase()
    : "UNKNOWN";

  const statusMapping: Record<string, { status: string; bookable: boolean }> = {
    PASSAGEUNAVAILABLE: { status: "BOOKED", bookable: false },
    BOOKED: { status: "BOOKED", bookable: false },
    TRUE: { status: "BOOKED", bookable: false },
    AVAILABLE: { status: "AVAILABLE", bookable: true },
    SATACTIVEONDIFFERENTGS: { status: "UNAVAILABLE", bookable: false },
    PASSAGEOVERLAP: { status: "OVERLAP", bookable: false }
  };

  return statusMapping[status] || { status, bookable: false };
}
