import {
  planningSystemActions,
  PlanningSyatemStoreState,
  PlanningSystemActionType
} from "../models";

const initialState: PlanningSyatemStoreState = {
  oaPagination: null,
  oa: [],
  timelines: null,
  calendarTimeline: []
};

export const planningSystemReducer = (
  state: PlanningSyatemStoreState = initialState,
  action: planningSystemActions
): PlanningSyatemStoreState => {
  switch (action.type) {
    case PlanningSystemActionType.GetOperationActivities:
      return (
        (action.payload && {
          ...state,
          oa: action.payload.oas,
          oaPagination: action.payload.pagination
        }) ||
        state
      );
    case PlanningSystemActionType.GetTimeline:
      return (
        (action.payload && { ...state, timelines: action.payload }) || state
      );
    case PlanningSystemActionType.GetCalendarTimeline:
      return (
        (action.payload && { ...state, calendarTimeline: action.payload }) ||
        state
      );

    default:
      return state;
  }
};
