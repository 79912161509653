import React from "react";
import Portal from "layout/Portal";
import { Manager, Reference, Popper } from "react-popper";
import { Absolute } from "primitives";
import { Toggler } from "components";
import {
  TestableProps,
  DATA_TEST_ID_ATTR_NAME
} from "components/testable/models";

interface PopoverProps extends TestableProps {
  children: any;
  overlay: React.ReactNode;
  opened?: boolean;
  zIndex?: number;
  over?: boolean;
  placement?:
    | "auto-start"
    | "auto"
    | "auto-end"
    | "top-start"
    | "top"
    | "top-end"
    | "right-start"
    | "right"
    | "right-end"
    | "bottom-end"
    | "bottom"
    | "bottom-start"
    | "left-end"
    | "left"
    | "left-start";
}

const Popover = ({
  children,
  overlay,
  opened,
  placement,
  zIndex,
  over
}: PopoverProps) => {
  return (
    <Manager>
      <Toggler defaultToggled={opened}>
        {([toggled, onToggle]: any) => (
          <>
            <Reference>
              {({ ref }) =>
                React.cloneElement(children, {
                  ref,
                  onClick: () => onToggle(),
                  onMouseEnter: () => (over ? onToggle(true) : null),
                  onMouseLeave: () => (over ? onToggle(false) : null)
                })
              }
            </Reference>

            {toggled && (
              <Portal>
                <Popper
                  placement={placement}
                  modifiers={{ preventOverflow: { enabled: false } }}
                >
                  {({ ref, style }) => (
                    <Absolute ref={ref} style={style} zIndex={zIndex}>
                      {overlay}
                    </Absolute>
                  )}
                </Popper>
              </Portal>
            )}
          </>
        )}
      </Toggler>
    </Manager>
  );
};

Popover.displayName = "Popover";

Popover.defaultProps = {
  visible: false,
  placement: "auto",
  zIndex: 1,
  [DATA_TEST_ID_ATTR_NAME]: Popover.displayName
};

export default Popover;
