import React, { useState } from "react";
import { Form } from "app/shared";
import { Box } from "primitives";
import { SuspenseMutation } from "app/network";
import { uiSchemaExport, schemaExport } from "../models/schemas";
import { Heading, Flex, Text } from "primitives";
import { CancelButton, SaveButton } from "components";
import { useSelector } from "react-redux";
import { exportToJson } from "utils/exportAsJson";
import { injectDatasourceNames, transformAlertDefPayload } from "../helpers";
import { getAlertsDefinitionsList } from "../services";
import { HTTPResponse } from "app/network/dataProvider/model";

export const AlertDefinitionExport = (props: any) => {
  const [formData, setFormData] = useState({});
  const alertDefinitions = useSelector(
    (s: any) => s.alert.alerts.alertDefinitions
  );
  const satDef = useSelector(
    (s: any) => s.satellite.dashboard.satelliteDefinition
  );
  const alertDefs = injectDatasourceNames(alertDefinitions, satDef);
  const submit = async (
    e: React.FormEvent<HTMLInputElement>,
    formData: any
  ) => {
    if (!formData?.exportAll || !formData?.datasourceName) return;
    e.preventDefault();
    if (formData?.exportAll) {
      const { data } = (await getAlertsDefinitionsList(satDef.id, {
        page: 0,
        pageSize: 9999
      })) as HTTPResponse;
      const allAlertDefs = injectDatasourceNames(data, satDef);
      const exportablePayload = transformAlertDefPayload(
        allAlertDefs,
        formData
      );
      exportToJson(exportablePayload, formData.alertDefinitionId);
      return props.history.push("/alert-definitions");
    } else {
      const exportablePayload = transformAlertDefPayload(alertDefs, formData);
      exportToJson(exportablePayload, formData.alertDefinitionId);
      return props.history.push("/alert-definitions");
    }
  };

  return (
    <Box color="text.default" data-testid="alert-definition-export" mx={3}>
      <Flex mb={2}>
        <Heading display={1}>Alert Definition Export</Heading>
      </Flex>
      <Flex flexDirection="column" bg="fill.0" p={3}>
        <SuspenseMutation>
          {({ loading, error, action }) => {
            return (
              <Form
                formData={formData}
                schema={schemaExport(alertDefs)}
                uiSchema={uiSchemaExport}
                disabled={loading}
                onSubmit={(
                  form: { formData: any },
                  e: React.FormEvent<HTMLInputElement>
                ) => action(() => submit(e, form.formData))}
                onChange={(form: any) => () =>
                  setFormData({ formData: form.formData })
                }
              >
                <CancelButton
                  onClick={() => props.history.push("/alert-definitions")}
                  mr={2}
                  mt={4}
                >
                  Cancel
                </CancelButton>

                <SaveButton ml={2} mt={4} type="submit" disabled={loading}>
                  Export
                </SaveButton>

                {error ? (
                  <Box my={2}>
                    <Text color="text.danger">
                      Error...{console.log(error)}
                    </Text>
                  </Box>
                ) : null}
              </Form>
            );
          }}
        </SuspenseMutation>
      </Flex>
    </Box>
  );
};
