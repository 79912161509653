import React from "react";
import { Text } from "primitives";
import { GraphBaseComponent } from "./GraphBaseComponent";
import { GraphAutoSizer } from "./GraphAutoSizer";
import { DataSourceReading } from "app/telemetry/models/datasources";
import { useSelector } from "react-redux";

export function GraphRenderer(props: any) {
  const timeController = useSelector((s: any) => s.datastore.timeController);
  const widgetName = props.type || props.options.widgetName;
  // inject timeController for RealTimeGraph to enable zoom feature and to allow timeController to control time on x-axis
  const configOptions = {
    ...props.options,
    id: props.id,
    widgetName,
    timeController: widgetName === "RealTimeGraph" ? timeController : null
  };

  return (
    <GraphAutoSizer {...props} idAttribute={`${configOptions.widgetName}`}>
      {({ w, h }: any) => {
        if (!props.options) {
          return <>Widget configuration is missing</>;
        }
        return (
          <>
            {props.options.label && (
              <Text fontSize={14}>{props.options.label}</Text>
            )}
            <GraphBaseComponent
              width={w}
              height={h}
              configOptions={configOptions}
              data={props.datasourceReading as DataSourceReading[]}
              modalOpen={props.modalOpen}
            />
          </>
        );
      }}
    </GraphAutoSizer>
  );
}
