import React, { useContext } from "react";
import { VisibiltyWindowContext } from "app/visibilityWindow/context";
import { TableHead, TableRow, TableSort } from "components";
import {
  TableHeader,
  TableHeaderStyled,
  enablePassageDetails
} from "app/visibilityWindow/components/styledComponents";

const PassageTableHeader = () => {
  const {
    state: { sortBy, isSortAscending },
    dispatch
  } = useContext(VisibiltyWindowContext);

  const setSort = (newSortBy: string) => {
    dispatch({
      type: "default",
      payload: {
        sortBy: newSortBy,
        isSortAscending: newSortBy === sortBy ? !isSortAscending : true
      }
    });
  };

  return (
    <TableHead>
      <TableRow color="text.white">
        <TableHeaderStyled>Satellite</TableHeaderStyled>
        <TableHeaderStyled>
          <TableSort
            id="groundStationName"
            name="Ground Station"
            isSortAscending={isSortAscending}
            sortBy={sortBy}
            setSort={(id: string) => setSort(id)}
          />
        </TableHeaderStyled>
        <TableHeaderStyled>Remaining Time</TableHeaderStyled>
        <TableHeaderStyled>
          <TableSort
            id="aos"
            name="AOS"
            isSortAscending={isSortAscending}
            sortBy={sortBy}
            setSort={(id: string) => setSort(id)}
          />
        </TableHeaderStyled>
        <TableHeaderStyled>
          <TableSort
            id="tca"
            name="TCA"
            isSortAscending={isSortAscending}
            sortBy={sortBy}
            setSort={(id: string) => setSort(id)}
          />
        </TableHeaderStyled>
        <TableHeaderStyled>
          <TableSort
            id="los"
            name="LOS"
            isSortAscending={isSortAscending}
            sortBy={sortBy}
            setSort={(id: string) => setSort(id)}
          />
        </TableHeaderStyled>
        <TableHeaderStyled>Duration</TableHeaderStyled>
        <TableHeader minWidth={198}>
          <TableSort
            id="maxElevation"
            name="Maximum Elevation"
            isSortAscending={isSortAscending}
            sortBy={sortBy}
            setSort={(id: string) => setSort(id)}
          />
        </TableHeader>
        {enablePassageDetails && <TableHeader maxWidth={48}></TableHeader>}
      </TableRow>
    </TableHead>
  );
};

export { PassageTableHeader };
