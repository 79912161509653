export const getDateFromTimeController = (timeController: any) => {
  const { quickRange, from, to } = timeController;

  if (!quickRange) {
    return { from, to };
  }
  // if quickRange is set, calculate from,to from quickRange value
  const fromValue = (quickRange as string).slice(
    (quickRange as string).indexOf("-") + 1,
    (quickRange as string).length - 1
  );
  const unit = (quickRange as string).slice(-1);
  const _from = subtractTime(
    new Date(Date.now()),
    Number(fromValue),
    unit
  ).toISOString();

  const _to = new Date(Date.now()).toISOString();
  return { from: _from, to: _to, quickRange };
};

export const subtractTime = (date: Date, amount: number, unit: string) => {
  const dateCopy = new Date(date);

  if (unit === "m") {
    dateCopy.setMinutes(date.getMinutes() - amount);
  } else if (unit === "h") {
    dateCopy.setHours(date.getHours() - amount);
  } else if (unit === "d") {
    dateCopy.setDate(date.getDate() - amount);
  }
  return dateCopy;
};

export const getDateRangeDiff = (state: any) => {
  // TODO: add additional checks to prevent unintentional large diffs
  const diff = new Date(state.to).getTime() - new Date(state.from).getTime();
  // return difference between two dates in ms
  return diff;
};

export const incrementDate = (date: any, diff: number) => {
  const newDate = new Date(new Date(date).setMilliseconds(diff)).toISOString();
  return newDate;
};

export const decrementDate = (date: any, diff: number) => {
  const newDate = new Date(new Date(date).setMilliseconds(-diff)).toISOString();
  return newDate;
};

export const getTimeControllerSetting = () => {
  const fromLS = localStorage.getItem("tsSetting");
  if (fromLS) {
    const timeControllerSavedSettings = JSON.parse(fromLS);
    return timeControllerSavedSettings;
  }
  return null;
};

export const saveTimeControllerSetting = (setting: any) => {
  localStorage.setItem("tsSetting", JSON.stringify(setting));
};
