import React from "react";
import styled from "styled-components";
import {
  TYPOGRAPHY,
  BORDER,
  TypographyProps,
  CommonProps,
  BorderProps
} from "styles/constants";
import {
  TestableProps,
  DATA_TEST_ID_ATTR_NAME
} from "components/testable/models";
import {
  SelectLabelSimple as Label,
  SelectContainerSimple as InputContainer
} from "components";
import { themeGet } from "styled-system";

type HTMLInput = React.InputHTMLAttributes<HTMLInputElement>;

interface InputProps
  extends TypographyProps,
    BorderProps,
    CommonProps,
    TestableProps {
  label?: string;
  ref?: any;
  p?: string;
}

const StyledInput = styled<any>("input")`
  ${TYPOGRAPHY}
  ${BORDER}
  border-bottom: 1px ${(props) =>
    props.disabled ? "dotted" : "solid"} ${themeGet("colors.palette.grey.8")};
  color: ${themeGet("colors.text.default")};
  border-top: none;
  border-left: none;
  border-right: none;
  margin: 0;
  padding:${(props) => (props.p ? props.p : "9px 0 7px")};
  display: block;
  flex-grow: 1;
  background: none;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  font-size: 6;
  width: ${(props) => (props.width ? props.width : "100%")}

  &:hover {
    border-bottom: ${(props) =>
      props.disabled ? "none" : "2px solid"} ${themeGet(
  "colors.palette.white"
)};
  };

  &:focus {
    outline: none;
    box-shadow: none;
    border-bottom: 2px solid ${themeGet("colors.palette.purple.2")};
    border-top: none;
    border-left: none;
    border-right: none;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`;

const InputSimple = (props: HTMLInput & InputProps) => {
  const { label, width, id } = props;
  return (
    <InputContainer my={props.my} mx={props.mx} width={width ? width : "100%"}>
      {label && <Label>{label}</Label>}
      <StyledInput data-testid={id ? id : "InputField"} {...props} />
    </InputContainer>
  );
};

InputSimple.displayName = "Input";

InputSimple.defaultProps = {
  fontSize: "16px",
  [DATA_TEST_ID_ATTR_NAME]: InputSimple.displayName
};

export default InputSimple;
