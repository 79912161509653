import React, { useContext } from "react";
import styled from "styled-components";
import { Icon } from "primitives";
import {
  TimelineItemContextMenuProps,
  CalendarTimelineItem,
  ContextActionsNames,
  TimelineEntryType,
  TimelineTypes
} from "app/planningSystem/models";
import { PlanningSystemContext } from "app/planningSystem/context";

const Container = styled("div")`
  & ul {
    list-style-type: none;
    padding: 2px 0;
    margin: 0;
  }
  & li {
    font-size: 12px;
    padding: 8px;
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
      background-color: #3d5076;
    }
    & span {
      margin-left: 6px;
      line-height: 1;
    }
  }
`;

export const TimelineItemContextMenu = ({
  onClose,
  data: oa,
  handleItemDelete
}: TimelineItemContextMenuProps) => {
  const {
    dispatch,
    state: { selectedTimeline }
  } = useContext(PlanningSystemContext);

  const handleTimelineItemInfo = (
    e: React.MouseEvent,
    item: CalendarTimelineItem
  ) => {
    e.stopPropagation();
    dispatch({
      type: ContextActionsNames.timelineItemDialog,
      payload: { item: oa, isOpen: true, setOpen: dispatch }
    });
    console.log("Show info for this item: ", item);
  };

  return (
    <Container data-testid={"timeline-item-context-menu"}>
      <ul>
        <li
          data-testid={"ctx-info"}
          onClick={(e) => {
            handleTimelineItemInfo(e, oa);
            onClose();
          }}
        >
          <Icon name="Info" size={14} />
          <span>Show Info</span>
        </li>
        {selectedTimeline?.timelineType !== TimelineTypes.MASTER && (
          <li
            style={
              (String(oa.id).includes(TimelineEntryType.TRACK) && {
                pointerEvents: "none",
                opacity: "0.6"
              }) ||
              {}
            }
            data-testid={"ctx-delete"}
            onClick={async (e) => {
              e.stopPropagation();
              await onClose();
              handleItemDelete(oa.id);
            }}
          >
            <Icon name="Delete" size={14} />
            <span>Delete</span>
          </li>
        )}
      </ul>
    </Container>
  );
};
