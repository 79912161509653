import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgDashboardMenu = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 18 18"
    color={color}
  >
    <g id="surface1">
      <path d="M 17.113281 16.554688 L 17.113281 5.949219 L 13.9375 5.949219 L 13.9375 16.554688 L 11.746094 16.554688 L 11.746094 0.226562 L 8.570312 0.226562 L 8.570312 16.554688 L 6.496094 16.554688 L 6.496094 5.949219 L 3.316406 5.949219 L 3.316406 16.554688 L 1.527344 16.554688 L 1.527344 0 L 0 0 L 0 18.082031 L 18.082031 18.082031 L 18.082031 16.554688 Z M 17.113281 16.554688 " />
    </g>
  </Svg>
);

SvgDashboardMenu.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgDashboardMenu;
