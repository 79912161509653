import { displayAlertInfo } from "./alertInfo";
import uPlot from "uplot";

export const STYLES = {
  textAlign: "left",
  color: "white",
  "background-color": "#353447",
  opacity: 1,
  pointerEvents: "none",
  display: "inline-block",
  padding: "0px 8px",
  "font-size": "12px",
  position: "absolute",
  left: 0,
  top: 0,
  zIndex: 9999
};

export function createToolTip(u: uPlot) {
  const tooltip = document.createElement("div");
  tooltip.className = "uplot-tooltip";
  tooltip.style.position = "absolute";
  tooltip.style.padding = "15px";
  tooltip.style.pointerEvents = "none";
  tooltip.style.display = "block";
  u.root.appendChild(tooltip);
}

export function showTooltip(u: uPlot, seriesIdx: number, rect: any, line: any) {
  const { scale } = u.series[seriesIdx];
  const yPos = Math.round(u.valToPos(line.value, scale as string, true));
  const x = rect.left + line.x;
  const y = rect.top + yPos;
  const text = line.value;
  const value = u.data[seriesIdx];
  const coordinates = getCursorCoordinate(u);
  const tooltip = document.querySelector(".uplot-tooltip");
  const alertInfo = getAlertDetailsForValue(u, seriesIdx, line);
  if (tooltip) {
    // @ts-ignore
    Object.assign(tooltip.style, STYLES);
    // @ts-ignore
    tooltip.style.left = `${x}px`;
    // @ts-ignore
    tooltip.style.top = `${10}px`;
    tooltip.innerHTML = displayAlertInfo(alertInfo);
  }
}

function getAlertDetailsForValue(u: uPlot, seriesIdx: any, line: any) {
  // last array item is aggregated alerts array
  const alertSeries = u.data[u.data.length - 1][0] as any;
  // first series is timestamps
  const alertSeriesForCurrentSeries = alertSeries[seriesIdx - 1];
  const alertInfo = line;
  if (alertInfo) {
    return alertInfo;
  } else {
    console.warn("alert info is missing");
    return "...";
  }
}

function getCursorCoordinate(u: uPlot) {
  return {
    x: u.cursor?.x,
    y: u.cursor?.y
  };
}
