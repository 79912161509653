import React, { useState, useEffect } from "react";
import { CancelButton, SaveButton } from "components";
import { DialogContent, Flex } from "primitives";
import { Form } from "app/shared";
import {
  generateOaHandleSchema,
  operationActivityHandleUISchema
} from "app/planningSystem/schemas";
import { SuspenseMutation } from "app/network";
import {
  DialogContentHandleProps,
  OaHandleState,
  OperationDialogActionName,
  CreateOaParams,
  PatchOaParams
} from "app/planningSystem/models";
import { GroundStation } from "app/groundStation";
import { fetchGroundStationBySatellite } from "app/groundStation/services";
import {
  handleRequestBody,
  operationToFormState,
  handleFormValidator,
  handleYamlTemplate
} from "app/planningSystem/utils/helpers";
import { Procedure } from "app/procedure/models";
import { refreshTimelines } from "app/planningSystem/actions";

export const DialogContentHandle = ({
  handleClose,
  satelliteInstances,
  getProcedures,
  getResources,
  createOperationActivity,
  editOperationActivity,
  getAllOperationActivities,
  operation,
  handleType
}: DialogContentHandleProps) => {
  const [formDataState, setFormDataState] = useState<OaHandleState | null>(
    operation ? operationToFormState(operation, handleType) : null
  );
  const [groundStations, setGroundStations] = useState<GroundStation[]>([]);
  const [procedures, setProcedures] = useState<Procedure[]>([]);

  useEffect(() => {
    getProcedureAsync();
    if (formDataState?.satelliteID) {
      updateGs(formDataState.satelliteID);
    }
  }, []);

  const submit = async (
    e: React.FormEvent<HTMLInputElement>,
    { formData }: any
  ) => {
    e.preventDefault();
    let res: any = null;

    const handleParams = handleRequestBody(formData, handleType);
    if (!handleParams) return;
    if (handleType === OperationDialogActionName.create) {
      res = await createOperationActivity(handleParams as CreateOaParams);
    } else if (handleType === OperationDialogActionName.edit && operation) {
      res = await editOperationActivity(
        operation?.uuid,
        handleParams as PatchOaParams
      );
    }
    if (!res || res?.detail || res.details) return; //something went wrong
    // Close the dialog
    handleClose();
    // Update the OA table
    await getAllOperationActivities();
    // Update the timelines
    await refreshTimelines();
  };

  const getProcedureAsync = async () => {
    const p = await getProcedures();
    setProcedures(p);
  };

  const updateGs = async (satID: string) => {
    try {
      const gs = await fetchGroundStationBySatellite(Number(satID));
      await setGroundStations(gs);
    } catch (error) {
      console.log(error);
    }
  };

  const startTimeToString = (formData: OaHandleState): OaHandleState => {
    if (formData.taskSetting)
      formData.taskSetting.startTime =
        formData.taskSetting.startTime instanceof Date
          ? formData.taskSetting.startTime.toISOString()
          : formData.taskSetting.startTime;

    if (formData.procedureExecutionSetting?.startTime)
      formData.procedureExecutionSetting.startTime =
        formData.procedureExecutionSetting.startTime instanceof Date
          ? formData.procedureExecutionSetting.startTime.toISOString()
          : formData.procedureExecutionSetting.startTime;
    return formData;
  };

  const handleLogFileName = (formData: OaHandleState): OaHandleState => {
    const logFileName = procedures.find(
      (p) => p.id === formData.fop.templateProcedureID
    )?.name;

    return {
      ...formData,
      fop: {
        ...formData.fop,
        ...(logFileName ? { logFileName: `${logFileName}.log` } : {})
      }
    };
  };

  const handleOnChange = async ({ formData }: { formData: OaHandleState }) => {
    let updatedFormData: OaHandleState = formData;
    if (
      formData.satelliteID &&
      formData.satelliteID !== formDataState?.satelliteID
    ) {
      await updateGs(formData.satelliteID);
    }

    /**
     * Transform the dates to isostring
     */
    updatedFormData = startTimeToString(formData);

    /**
     * - Assign a value to the logFileName hidden field based on `formDataState.fop.templateProcedureID`
     * - Get the procedure -> script[0] -> resources[0]
     *   This should be a YAML template to be shown in the EmbeddedEditor
     */
    if (
      formData.fop.templateProcedureID !==
      formDataState?.fop.templateProcedureID
    ) {
      updatedFormData = handleLogFileName(updatedFormData || formData);
      updatedFormData = await handleYamlTemplate(updatedFormData);
    }

    setFormDataState(updatedFormData);
  };

  return (
    <DialogContent>
      <SuspenseMutation>
        {({ loading, action }) => {
          return (
            <Form
              formData={formDataState || {}}
              id={"create-operation-activity"}
              schema={generateOaHandleSchema(
                satelliteInstances,
                groundStations,
                procedures,
                handleType
              )}
              uiSchema={operationActivityHandleUISchema}
              onSubmit={(form: any, e: React.FormEvent<HTMLInputElement>) => {
                return submit(e, form);
              }}
              disabled={loading}
              onChange={handleOnChange}
              validate={handleFormValidator}
            >
              <Flex justifyContent={"flex-end"}>
                <CancelButton
                  onClick={(e: React.MouseEvent) => handleClose()}
                  mr={2}
                >
                  Cancel
                </CancelButton>
                <SaveButton type="submit" disabled={loading}>
                  Save
                </SaveButton>
              </Flex>
            </Form>
          );
        }}
      </SuspenseMutation>
    </DialogContent>
  );
};
