import { InputBase, SelectBox, DataSourceArrayField } from "app/shared";
import { DataSource, DataTypeAll } from "app/dataSource/models";
import {
  DashboardComponentKeys,
  DashboardComponentsAll,
  DashboardComponentsNames
} from "app/dashboard/models";
import { SatelliteDefinition } from "app/satelliteDefinition/models";
import { SatelliteInstance } from "app/satellite/models";
import { GroundStation } from "app/groundStation";
import { DashboardComponents } from "app/dashboard/models";
import {
  createMappingSchemaForDatasources,
  createUnitsEnumForDatasources
} from "app/telemetry/visualizations";
import { configOptionsSchemaBuilder } from "app/dashboard/services/configOptionsSchemaBuilder";

export const componentSchemaGenerator = (
  dataSources: DataSource[],
  selectedSatelliteDefinition: SatelliteDefinition,
  formData: any = null
) => {
  const dataSouceComponents = DashboardComponents.getDataSourceComponents();
  const otherComponents = DashboardComponentsAll.filter(
    (type: string) => !dataSouceComponents.includes(type)
  );
  return {
    type: "object",
    required: ["type", "size", "position"],
    properties: {
      label: {
        type: "string",
        default: ""
      },
      type: {
        type: "string",
        title: "Component type",
        enum: DashboardComponentsAll,
        enumNames: DashboardComponentsNames
      }
    },
    dependencies: {
      type: {
        oneOf: [
          {
            properties: {
              type: {
                enum: dataSouceComponents
              },
              dataSources: getDataSourcesSchema(
                dataSources,
                selectedSatelliteDefinition,
                formData && DashboardComponents[formData.type]
                  ? DashboardComponents[formData.type].maxItems
                  : null,
                formData && DashboardComponents[formData.type]
                  ? DashboardComponents[formData.type].allowedDataTypes
                  : DataTypeAll
              )
            }
          },
          {
            properties: {
              type: {
                enum: otherComponents
              }
            }
          }
        ]
      }
    }
  };
};

const getDataSourcesSchema = (
  dataSources: DataSource[],
  selectedSatelliteDefinition: SatelliteDefinition,
  maxItems: number | null = null,
  allowedDataTypes: string[] | null = null
) => {
  const schema: any = {
    title: "Data Sources",
    description: "List of data sources that are assigned to the widget",
    type: "array",
    hasRemove: true,
    minItems: 0,
    default: dataSources,
    items: {
      type: "object",
      selectedSatelliteDefinition,
      dataSources: [],
      properties: {
        id: {
          type: "number"
        },
        label: {
          type: "string"
        }
      }
    }
  };
  if (maxItems) schema.maxItems = maxItems;
  if (allowedDataTypes) schema.allowedDataTypes = allowedDataTypes;

  return schema;
};

export const configOptionsSchemaGenerator = (
  formData: any,
  satelliteInstances: SatelliteInstance[],
  groundStations: GroundStation[]
) => {
  // Insert dynamic data into json schema spec (Bad solution)
  if (formData && formData.type) {
    const configOptions = configOptionsSchemaBuilder(formData.type);
    if (!configOptions) return {};
    const schema: any = configOptions.schema;
    const sortedSatInstances = satelliteInstances.sort((a, b) =>
      a.label.localeCompare(b.label)
    );

    const groundStationIDs = groundStations.map(
      (groundstation: GroundStation) => groundstation.groundStationID
    );
    const groundStationNames = groundStations.map(
      (groundstation: GroundStation) => groundstation.groundStationName
    );
    const satelliteIDs = sortedSatInstances.map(
      (satellite: SatelliteInstance) => satellite.id
    );
    const satelliteNames = sortedSatInstances.map(
      (satellite: SatelliteInstance) => satellite.label
    );

    switch (formData.type) {
      case DashboardComponentKeys.Globe:
        schema.definitions.satellite.properties.id.enum = satelliteIDs;
        schema.definitions.satellite.properties.id.enumNames = satelliteNames;
        schema.definitions.groundstation.properties.id.enum = groundStationIDs;
        schema.definitions.groundstation.properties.id.enumNames =
          groundStationNames;
        break;
      case DashboardComponentKeys.PassageCountdown:
        schema.definitions.groundstations.items.enum = groundStationIDs;
        schema.definitions.groundstations.items.enumNames = groundStationNames;
        schema.definitions.satellites.items.enum = satelliteIDs;
        schema.definitions.satellites.items.enumNames = satelliteNames;
        break;
      case DashboardComponentKeys.VisibilityWindows:
        schema.definitions.satellites.items.enum = satelliteIDs;
        schema.definitions.satellites.items.enumNames = satelliteNames;
        break;
      case DashboardComponentKeys.Grafana:
        // Add additional settings for Grafana widget from state
        break;
      case DashboardComponentKeys.RealTimeGraph:
        if (formData.dataSources) {
          const ids = formData.dataSources.map((dS: any) => dS.id);
          const labels = formData.dataSources.map((dS: any) => dS.label);
          schema.definitions.limits.dependencies.type.oneOf[1].properties.dataSource.enum =
            ids;
          schema.definitions.limits.dependencies.type.oneOf[1].properties.dataSource.enumNames =
            labels;
        }
        break;
      case DashboardComponentKeys.Histogram:
        if (formData.dataSources) {
          schema.properties.valueMapping.items =
            createMappingSchemaForDatasources(formData);
          schema.properties.scale.properties.enableScaling.dependencies.enableScaling.oneOf[1].properties.scaledUnit.enum =
            createUnitsEnumForDatasources(formData);
        }
        break;
      default:
        break;
    }
    return schema;
  } else {
    return {};
  }
};

export const configOptionsUISchemaGenerator = (formData: any) => {
  if (formData && formData.type && configOptionsSchemaBuilder(formData.type)) {
    return configOptionsSchemaBuilder(formData.type).uiSchema;
  } else {
    return {};
  }
};

export const uiSchema = {
  label: { "ui:widget": InputBase },
  type: { "ui:widget": SelectBox },
  dataSources: { "ui:field": DataSourceArrayField },
  text: { "ui:widget": InputBase }
};
