import { dataProvider, CREATE, UPDATE, GET_LIST, DELETE } from "app/network";
import { passageManagerDataProvider } from "app/network/dataProvider";

export const bookPassage = async (data: any) => {
  const result = await passageManagerDataProvider(CREATE, `candidates`, {
    data
  });
  return result;
};

export const cancelPassage = async (passageIds: any) => {
  const result = await passageManagerDataProvider(
    DELETE,
    `passages?passageIds=${passageIds}`
  );
  return result;
};

export const bookingSinglePassage = async (data: any) => {
  await dataProvider(CREATE, `candidates`, data);
  return data;
};

export const cancelSinglepassageManager = async (data: any) => {
  await dataProvider(CREATE, `candidates`, data);
  return data;
};

export const cancelMultiplepassageManager = async (data: any) => {
  await dataProvider(CREATE, `candidates`, data);
  return data;
};

export const bookingMultiplePassages = async (data: any) => {
  await dataProvider(UPDATE, `candidates`, data);
  return data;
};
