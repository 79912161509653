import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Flex } from "primitives";
import { tcStack } from "app/telecommandStack/actions";
import { isAllowedDrag, getItemStyle, getListStyle } from "./helpers";
import { RenderTelecommand } from "./RenderTelecommand";

export function TelecommandsList(props: any) {
  const { selectedStack } = props;
  const { telecommandList } = selectedStack;

  const onDragEnd = async (result: any) => {
    const { destination } = result;

    if (!destination) {
      return;
    }
    if (!isAllowedDrag(selectedStack, result)) {
      tcStack.toast("Reset TC Stack before moving", "ERROR");
      return;
    }

    const newList = [...selectedStack.telecommandList];
    // eslint-disable-next-line no-case-declarations
    const movedTC = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, ...movedTC);
    const updatedStack = {
      ...selectedStack,
      telecommandList: newList
    };
    tcStack.reorder(selectedStack.id, updatedStack);
  };

  function renderDraggableTC(
    draggableProvided: any,
    renderTelecommand: any,
    telecommand: any,
    index: any
  ) {
    return (
      <Flex
        {...draggableProvided.draggableProps}
        {...draggableProvided.dragHandleProps}
        ref={draggableProvided.innerRef}
        style={getItemStyle(
          draggableProvided.isDragging,
          draggableProvided.draggableProps.style,
          telecommand
        )}
      >
        {RenderTelecommand(telecommand, index, props)}
      </Flex>
    );
  }

  return (
    <DragDropContext onDragEnd={(result: any) => onDragEnd(result)}>
      <Droppable
        droppableId="telecommandstack"
        renderClone={(
          draggableProvided: any,
          draggableSnapshot: any,
          draggable: any
        ) =>
          renderDraggableTC(
            draggableProvided,
            RenderTelecommand,
            telecommandList[draggable.source.index],
            draggable.source.index
          )
        }
      >
        {(provided: any, snapshot: any) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            {...provided.droppableProps}
          >
            {Array.isArray(telecommandList) &&
              telecommandList.map((telecommand: any, index: number) => (
                <Draggable
                  draggableId={String(telecommand.id)}
                  key={String(telecommand.id)}
                  index={index}
                >
                  {(draggableProvided: any, draggableSnapshot: any) =>
                    renderDraggableTC(
                      draggableProvided,
                      RenderTelecommand,
                      telecommand,
                      index
                    )
                  }
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
