import { getReadingsByDataSources } from "app/dataSource";
import { store } from "app/store";
import { AnyAction, Dispatch, Store } from "redux";
import {
  IDatasourcesForCurrentDashboard,
  TMIntervals,
  TelemetryDataReducerActions
} from "../models/telemetry";
import { Component, DashboardDataSourceDefinition } from "../models";
import { setFeedback } from "app/feedback/actions";
import { FeedbackStatus } from "app/feedback/models";
import { isNotEmpty } from "app/shared/utils";
import { fetchReadingEnd } from "app/dataSource/actions";

function setUpInterval(
  intervalName: TMIntervals,
  cb: any,
  refreshInterval: number,
  ...args: any
) {
  cleanUpIntervals(TMIntervals[intervalName]);
  window[intervalName] = setInterval(cb, refreshInterval, ...args);
  return window[intervalName];
}

function getDatasourcesForCurrentDashboard(
  reduxStore: Store<any, AnyAction>
): IDatasourcesForCurrentDashboard | null {
  if (!reduxStore) return null;
  cleanUpIntervals();
  const currentDashboard = reduxStore.getState().dashboard.selected;
  const allDatasources: number[] =
    currentDashboard &&
    currentDashboard.components
      //@ts-ignore
      .filter((i: Component) => i.configOptions?.isGloballyControlled)
      .map((i: Component) => i.dataSources)
      .flat()
      .map((i: DashboardDataSourceDefinition) => i.datasourceId);

  const datasourcesWithAlertsEnabled: number[] =
    currentDashboard &&
    currentDashboard.components
      .filter((i: Component) => i.configOptions?.isGloballyControlled)
      .filter((i: Component) => i.configOptions.alerts?.showAlerts)
      .map((i: Component) => i.dataSources.map((ds) => ds.datasourceId))
      .flat();

  const datasourcesWithMultipleWindows = currentDashboard.components
    //@ts-ignore
    .filter(
      (i: Component) =>
        i.type === "RealTimeGraph" && i.configOptions?.isGloballyControlled
    )
    .map((i: Component) => i.dataSources)
    .flat()
    .map((i: DashboardDataSourceDefinition) => i.datasourceId);

  const idsWithHistorical = allDatasources.filter((x) =>
    datasourcesWithMultipleWindows.includes(x)
  );

  // TODO: dont delete datasources in both realtime graph and other widget
  // const datasourcesWithSingleWindow = allDatasources.filter(
  //   (x) => !idsWithHistorical.includes(x)
  // );

  const datasourcesWithSingleWindow = allDatasources;

  const refreshIntervalsOnWidgets = currentDashboard.components
    .map((i: Component) => i.configOptions)
    .map((i: any) => {
      return i && i.updatePeriod;
    });

  const lowestRefreshValueForCurrentDashboard = Math.min(
    ...refreshIntervalsOnWidgets
  );

  if (
    refreshIntervalsOnWidgets &&
    datasourcesWithSingleWindow &&
    idsWithHistorical
  ) {
    return {
      datasourcesOnCurrentDashboard: [...new Set(datasourcesWithSingleWindow)],
      realTimeGraphDatasources: [...new Set(idsWithHistorical)],
      refreshInterval: lowestRefreshValueForCurrentDashboard,
      dsWithAlerts: [...new Set(datasourcesWithAlertsEnabled)]
    } as IDatasourcesForCurrentDashboard;
  }
  return null;
}

function transformTimeReference(timeReference: string) {
  return timeReference == "groundUtc" ? "ReceiveUtcTime" : "SendUtcTime";
}

function quickRangeIsSet(quickRange: string) {
  return quickRange.length !== 0;
}

function buildParamsFromTimeController() {
  const refreshInterval =
    store.getState().datastore.timeController.refreshInterval;
  const _params = store.getState().datastore.timeController;
  const quickRangeIsSelected = quickRangeIsSet(_params.quickRange);
  const from = _params.from
    ? _params.from
    : new Date(Date.now() - 120 * 60000).toISOString();
  const to = quickRangeIsSet(_params.quickRange)
    ? null
    : _params.to || new Date(Date.now()).toISOString();

  const params = {
    from,
    windowSize: 1,
    to,
    timeUnit: _params.timeUnit ? _params.timeUnit : "Seconds",
    timeReference: _params.timeReference
      ? transformTimeReference(_params.timeReference)
      : "SendUtcTime"
  };
  // fetch params when component is mounted
  const firstHistoricalFetchParams = {
    windowSize: 5000,
    // NOTE: if from is not set, fetch TM from last two hours
    from,
    // NOTE: verify in tm service if setting "to" to null returns latest data
    to,
    timeUnit: params.timeUnit,
    timeReference: params.timeReference
  };

  const intervalHistoricalFetchParams = {
    ...params,
    refreshInterval,
    // NOTE: max datapoints to fetch during interval, if interval is less than 10s, available datapoints are normally less than 10
    windowSize: 5000
  };

  const singleWindowOptions = {
    timeReference: params.timeReference,
    timeUnit: params.timeUnit,
    from,
    to
  };

  return {
    firstHistoricalFetchParams,
    intervalHistoricalFetchParams,
    refreshInterval,
    quickRangeIsSelected,
    singleWindowOptions
  };
}

/**
 * @description it handle two different types of requests:
 * those with a single 'windowSize' (ex. for SingleValue widget)
 * and the other with larger 'windowSize' (Ex. Graphs).
 * Creates a loop of requests using as interval 'refreshInterval' of the timeController.
 * @param satelliteId
 * @returns {void}
 */
export const fetchTelemetryAtInterval = (satelliteId: string) => {
  if (!satelliteId) {
    return store.dispatch(
      setFeedback("Satellite Id is null", FeedbackStatus.ERROR)
    );
  }
  return async (dispatch: Dispatch<TelemetryDataReducerActions>) => {
    try {
      const {
        firstHistoricalFetchParams,
        intervalHistoricalFetchParams,
        refreshInterval,
        quickRangeIsSelected,
        singleWindowOptions
      } = buildParamsFromTimeController();
      const datasources = getDatasourcesForCurrentDashboard(store);
      if (datasources) {
        const { datasourcesOnCurrentDashboard, realTimeGraphDatasources } =
          datasources;

        // Case of widget showing a single value (windowSize: 1)
        if (isNotEmpty(datasourcesOnCurrentDashboard)) {
          await getReadingsByDataSources(
            satelliteId,
            datasourcesOnCurrentDashboard,
            singleWindowOptions,
            {}
          );
          // NOTE: set up interval only when quickRange(Last 5 min) is selected, this prevents background fetching when user only needs to show datapoints for a specific date range
          quickRangeIsSelected &&
            setUpInterval(
              TMIntervals.TM2,
              getReadingsByDataSources,
              refreshInterval,
              satelliteId,
              datasourcesOnCurrentDashboard,
              singleWindowOptions,
              {}
            );
        }

        // Case of widget showing multiple value for a datasource (windowSize: <n>)
        if (isNotEmpty(realTimeGraphDatasources)) {
          await getReadingsByDataSources(
            satelliteId,
            realTimeGraphDatasources,
            firstHistoricalFetchParams,
            {}
          );

          // NOTE: set up interval only when quickRange(Last 5 min) is selected
          quickRangeIsSelected &&
            setUpInterval(
              TMIntervals.TM1,
              getReadingsByDataSources,
              refreshInterval,
              satelliteId,
              realTimeGraphDatasources,
              intervalHistoricalFetchParams,
              {}
            );
        }
      }
      store.dispatch(fetchReadingEnd());
    } catch (e) {
      console.error(`TURBO fetchTelemetryAtInterval: ${e}`);
    }
  };
};

export function cleanUpIntervals(interval?: TMIntervals | null) {
  if (!interval) {
    for (const key of Object.keys(TMIntervals)) {
      const intervalId = (window as { [key: string]: any })[key] as number;
      window.clearInterval(intervalId);
    }
  } else window.clearInterval(window[interval]);
}
