import React, { useCallback } from "react";
import { SuspenseMutation } from "app/network";
import { Toggler, Spinner } from "components";
import { CancelButton, DeleteButton } from "components";
import {
  Flex,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Text
} from "primitives";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "app/shared";
import { cancelOnePassage } from "app/passageManager/actions";

interface CancelPassageProps {
  closeModal?: any;
  candidate?: any;
  config?: any;
}

export const CancelPassage = ({ candidate, config }: CancelPassageProps) => {
  const dispatch = useDispatch();
  const { status } = useSelector((s: any) => s.passageManager);
  const loading = status?.book === "loading";
  const submit = async (
    e: React.FormEvent<HTMLInputElement>,
    formData: any,
    onToggle: any
  ) => {
    e.preventDefault();
    const result = await dispatch(
      cancelOnePassage(candidate.passageID, config)
    );
    if (result?.length || !loading) {
      onToggle();
    }
  };

  const onChange = useCallback((form) => form.formData, []);

  return (
    <Toggler>
      {([toggled, onToggle]) => (
        <>
          <DeleteButton onClick={onToggle} data-testid="passage-cancel-btn">
            CANCEL
          </DeleteButton>
          <Dialog
            open={toggled}
            maxWidth="lg"
            data-testid="passage-manager-edit"
          >
            <SuspenseMutation>
              {({ loading, action }) => {
                return (
                  <>
                    <DialogTitle>
                      Cancel candidate with id: {candidate?.passageID}?
                    </DialogTitle>
                    <DialogContent>
                      <Flex alignItems="center">
                        <Form
                          id="passage-manager"
                          formData={{}}
                          schema={{}}
                          uiSchema={{}}
                          disabled={loading}
                          onSubmit={(
                            form: { formData: any },
                            e: React.FormEvent<HTMLInputElement>
                          ) => action(() => submit(e, form.formData, onToggle))}
                          onChange={(form: any) => onChange(form)}
                        >
                          <></>
                        </Form>
                      </Flex>
                    </DialogContent>
                    <DialogActions>
                      <CancelButton onClick={onToggle}>CANCEL</CancelButton>
                      <Button
                        form="passage-manager"
                        type="submit"
                        ml={2}
                        data-testid="book-btn"
                      >
                        {loading ? (
                          <Flex flexDirection="row" mx={2}>
                            <Spinner size={14} />
                            <Text color="text.default" mx={2}>
                              CANCELING....
                            </Text>
                          </Flex>
                        ) : (
                          <Text color="text.default"> CANCEL PASSAGE </Text>
                        )}
                      </Button>
                    </DialogActions>
                  </>
                );
              }}
            </SuspenseMutation>
          </Dialog>
        </>
      )}
    </Toggler>
  );
};
