import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgArrowRightPagination = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}
  >
    <g>
      <g>
        <path
          d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"
          fill="#7AB9DB"
        ></path>
      </g>
    </g>
  </Svg>
);

SvgArrowRightPagination.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgArrowRightPagination;
