import { connect } from "react-redux";
import { fetchSessionKeyAction } from "app/keyManager/actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { InjectSessionKeyDialog } from "../components/InjectSessionKeyDialog";
const mapStateToProps = (state: any) => ({
  selectedSatellite: state.constellations.dashboard.find(
    (satellite: any) => satellite.visible
  )
});
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchSessionKey: (
      satelliteID: number,
      masterKeyId: string,
      sessionKeyID: string
    ) => dispatch(fetchSessionKeyAction(satelliteID, masterKeyId, sessionKeyID))
  };
};
export const InjectSessionKeyDialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InjectSessionKeyDialog);
