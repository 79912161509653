import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Text, Flex, Icon } from "primitives";
import {
  QuickTimeRanges,
  GrafanaVariables,
  Variable,
  variableInitialState
} from "app/telemetry/models";
import { DragHandle } from "components";
import {
  SelectContainerSimple as SelectContainer,
  SelectLabelSimple as SelectLabel,
  SelectSimple as Select
} from "components";
import { DateTimeFilter } from "..";

export const GrafanaIFrame = (props: { options: any }) => {
  const { timeFrame, quickRange, variables } = useSelector(
    (state: any) => state.grafana
  );

  const dispatch = useDispatch();
  const frame = useRef(null);
  const [, setReset] = useState(false);
  const [toggle, setToggle] = useState(false);
  const { options } = props;
  const [grafanaUrl, setGrafanaUrl] = useState(options.url);

  useEffect(() => {
    const timer = setTimeout(() => {
      setReset(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => setGrafanaUrl(options.url), [options.url]);

  useEffect(() => {
    setGrafanaUrl(
      rebuildGrafanaUrl(options.url, timeFrame, quickRange, variables)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFrame, quickRange, variables]);

  return (
    <Box
      data-testid="grafana"
      minWidth={260}
      height={`calc(100% - ${options.label ? 21 : 0}px)`}
      overflow="visible"
    >
      {options.label && (
        <Text fontSize={18} m="10px 0">
          {options.label}
        </Text>
      )}
      <Flex overflow="visible" bg="fill.0" p={1} alignItems="flex-start">
        <Flex
          overflow="visible"
          alignItems="center"
          width="100%"
          justifyContent="space-between"
        >
          <DragHandle width={18} />
          <Flex
            alignItems="flex-start"
            justifyContent="space-between"
            width="100%"
            overflow="visible"
          >
            {toggle && (
              <>
                <Flex
                  alignContent="center"
                  alignItems="center"
                  justifyContent="flex-start"
                  maxWidth="40%"
                  flexWrap="wrap"
                  ml={2}
                >
                  {variables.map((v: Variable, i: number) => {
                    return (
                      <SelectContainer
                        key={i}
                        style={{ margin: "2px 0" }}
                        width="66px"
                      >
                        <SelectLabel>{v.label}</SelectLabel>
                        <Select
                          width="50px"
                          fontSize="14px"
                          iconPt="6px"
                          pb="1px"
                          borderColor="#fff"
                          className="grafanaSelect"
                          value={v.value}
                          onChange={(e) => {
                            dispatch({
                              type: "SET_VARIABLE",
                              payload: { value: e.target.value, name: v.name }
                            });
                          }}
                        >
                          <option value=" "></option>
                          {Object.values(GrafanaVariables).map((val, ind) => (
                            <option key={ind} value={val}>
                              {val}
                            </option>
                          ))}
                        </Select>
                      </SelectContainer>
                    );
                  })}
                </Flex>
                <Flex
                  alignContent="center"
                  alignItems="center"
                  justifyContent="flex-end"
                  maxWidth="60%"
                  flexWrap="wrap"
                  overflow="visible"
                >
                  <DateTimeFilter
                    margin="2px 8px"
                    paddingTop={"5px"}
                    timeFilterChanged={(from, to) => {
                      dispatch({
                        type: "SET_TIMEFRAME",
                        payload: {
                          from: new Date(from).valueOf(),
                          to: new Date(to).valueOf()
                        }
                      });
                    }}
                  />
                  <SelectContainer
                    width="94px"
                    style={{ margin: "2px 0 2px 8px" }}
                  >
                    <SelectLabel>Quick range</SelectLabel>
                    <Select
                      width="86px"
                      fontSize="14px"
                      iconPt="6px"
                      pb="1px"
                      borderColor="#fff"
                      className="grafanaSelect"
                      value={quickRange}
                      onChange={(e) => {
                        dispatch({
                          type: "SET_QUICKRANGE",
                          payload: e.target.value
                        });
                      }}
                    >
                      <option value=" "></option>
                      {Object.entries(QuickTimeRanges).map((key, ind) => (
                        <option key={ind} value={key[0]}>
                          {key[1]}
                        </option>
                      ))}
                    </Select>
                  </SelectContainer>
                </Flex>
              </>
            )}
          </Flex>
          <Box
            cursor="pointer"
            padding={2}
            // eslint-disable-next-line no-shadow
            onClick={() => setToggle((toggle) => !toggle)}
            overflow="visible"
          >
            <Icon name={toggle ? "ArrowUp" : "ArrowDown"} size={12} />
          </Box>
        </Flex>
      </Flex>
      <Box
        color="text.default"
        bg="fill.0"
        data-tesid="GrafanaDashboard"
        height={`calc(100% - ${toggle ? 62 : 58}px)`}
        overflow="visible"
      >
        <iframe
          key={grafanaUrl}
          title="grafana embdeded panel"
          className="grafana"
          ref={frame}
          src={grafanaUrl}
          width="100%"
          height="100%"
          frameBorder="0"
        ></iframe>
      </Box>
    </Box>
  );
};

const rebuildGrafanaUrl = (
  url: string,
  timeFrame: TimeFrame,
  quickRange: string,
  variables: Variable[]
) => {
  let newUrl: string;
  try {
    const u = new URL(url);
    const host = u.host;
    const path = u.pathname;
    const protocol = u.protocol;
    const uri = `${protocol}//${host}${path}`;
    const params = u.searchParams;
    const orgId = params.get("orgId");

    const variableValues = variables.filter(
      (v) => typeof v.value !== "string" || v.value.trim()
    );

    if (timeFrame.from && timeFrame.to) {
      newUrl = `${uri}?orgId=${orgId}&refresh=5s&panelId=${params.get(
        "panelId"
      )}&from=${timeFrame.from}&to=${timeFrame.to}`;

      if (variableValues.length > 0) {
        variableValues.forEach(
          (val) => (newUrl = `${newUrl}&${val.name}=${val.value}`)
        );
      }
      return newUrl;
    } else if (quickRange) {
      newUrl = `${uri}?orgId=${orgId}&refresh=5s&panelId=${params.get(
        "panelId"
      )}&from=${quickRange.trim()}&to=now`;

      if (variableValues.length > 0) {
        variableValues.forEach(
          (val) => (newUrl = `${newUrl}&${val.name}=${val.value}`)
        );
      }
      return newUrl;
    } else return url;
  } catch (error) {
    console.log("Err:parsing grafana url", error);
  }
};

interface TimeFrame {
  from: number | null;
  to: number | null;
}

interface GrafanaState {
  url: string;
  timeFrame: TimeFrame;
  quickRange: string | null;
  variables: Variable[];
}

const initialState: GrafanaState = {
  url: "",
  timeFrame: { from: null, to: null },
  quickRange: " ",
  variables: variableInitialState
};

export const grafanaReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_URL":
      return { ...state, url: action.payload };
    case "SET_TIMEFRAME":
      return { ...state, timeFrame: action.payload, quickRange: " " };
    case "SET_QUICKRANGE":
      return {
        ...state,
        timeFrame: { from: null, to: null },
        quickRange: action.payload
      };
    case "SET_VARIABLE":
      // eslint-disable-next-line no-case-declarations
      const newVariables = [...(state.variables as Variable[])];

      // eslint-disable-next-line no-case-declarations
      const variableIndex = state.variables.findIndex(
        (v) => v.name === action.payload.name
      );
      newVariables[variableIndex].value = action.payload.value;
      return { ...state, variables: newVariables };
    default:
      return state;
  }
};
