import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE,
  userDataProvider
} from "app/network";
import { store } from "app/store";

export const createKeycloakGroup = (groupName: string) => {
  // TODO: change aurora to d-orbit as clientID
  const orgName =
    store.getState().keycloak.tokenParsed.webgsClientId === "aurora"
      ? "d-orbit"
      : store.getState().keycloak.tokenParsed.webgsClientId;
  const fleetName = store.getState().constellations.selected.name;
  const fullPath = `organization/${orgName}/fleets/${fleetName}/${groupName.trim()}`;
  return userDataProvider(CREATE, `groups`, {
    data: {
      path: fullPath
    }
  });
};

export const addUserToKeycloakGroup = (userId: string, groupId: string) => {
  return userDataProvider(UPDATE, `groups/${groupId}/${userId}`);
};

export const removeUserToKeycloakGroup = (userId: string, groupId: string) => {
  return userDataProvider(DELETE, `groups/${groupId}/${userId}`);
};

export const getKeycloakGroups = () => {
  return userDataProvider(GET_LIST, `groups`);
};

export const getKeycloakGroupsById = (groupId: string) => {
  return userDataProvider(GET_ONE, `groups/${groupId}`);
};
