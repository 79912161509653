import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Box, Grid, Text, Flex } from "primitives";
import {
  TelecommandStackState,
  TelecommandStack as ITelecommandStack,
  TelecommandStackStatus
} from "app/telecommandStack/models";
import { tcStack, selectStack } from "app/telecommandStack/actions";
import { StackSelector } from "../TelecommandStackSelector";
import { Spinner } from "components";
import { Header } from "app/dashboard/components/Header";
import { AddCommentToStack } from "./AddComment";
import { getStackStatus } from "./helpers";
import { TelecommandsList } from "./TelecommandsList";

export const TelecommandStack = (props: any) => {
  const dispatch = useDispatch();

  let { telecommandStacks, selectedStack } = useSelector<
    any,
    TelecommandStackState
  >((state: any) => state.telecommandStack);

  const selectedPassage = useSelector(
    (state: any) => state.visibilityWindow.selectedPassage
  );
  const satellite = useSelector((state: any) =>
    state.constellations.dashboard.find((i: any) => i.visible)
  );

  const {
    stackIsRunning,
    stackIsResumable,
    canReset,
    canSave
  } = getStackStatus(selectedStack);

  useEffect(() => {
    tcStack.list();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(selectStack(""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    selectedStack = null;
  }, []);

  useEffect(() => {
    if (selectedStack) {
      const updated = telecommandStacks.find(
        (tc: ITelecommandStack) =>
          tc.id === (selectedStack as ITelecommandStack).id
      );
      updated && tcStack.set(updated.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [telecommandStacks, selectedStack]);

  return (
    <Box minWidth={450}>
      <Text fontSize={18} m="10px 0">
        {props.options.label}
      </Text>
      <Grid
        data-testid="telecommand-stack"
        gridTemplateRows="0.5fr 5fr 0.5fr"
        height={props.options.label ? `calc(100% - 21px)` : "100%"}
        bg="fill.0"
      >
        <Box overflow="visible" color="text.default">
          <Header my={20} mr={0} ml={2}>
            <Flex
              flexDirection="row"
              alignItems="center"
              width="100%"
              p={2}
              overflow="visible"
            >
              <Box overflow="visible" width="80%">
                <StackSelector />
              </Box>
              <AddCommentToStack disabled={!canSave} />
              <Button
                ml={2}
                mr={1}
                size="medium"
                onClick={() => tcStack.save()}
                disabled={!canSave}
              >
                <Text>Save</Text>
              </Button>
              <Button
                mx={1}
                size="medium"
                onClick={() => tcStack.reset()}
                disabled={!canReset}
              >
                <Text>Reset</Text>
              </Button>

              <Box overflow="visible">
                <Flex>
                  {stackIsRunning ? (
                    <>
                      <Button
                        mx={1}
                        size="medium"
                        onClick={() =>
                          selectedStack &&
                          tcStack.setStackStatus(
                            TelecommandStackStatus.PAUSED,
                            selectedStack.id
                          )
                        }
                      >
                        <Text>Pause</Text>
                      </Button>
                      <Box m="auto" mx={1} overflow="hidden">
                        <Spinner />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Button
                        mx={1}
                        size="medium"
                        disabled={!stackIsResumable}
                        onClick={() => {
                          if (!selectedPassage) {
                            tcStack.toast("No passage selected", "ERROR");
                          }
                          tcStack.run(selectedPassage, satellite, true);
                        }}
                      >
                        <Text>Resume</Text>
                      </Button>

                      <Button
                        mx={1}
                        size="medium"
                        disabled={
                          !selectedStack && !stackIsRunning && !stackIsResumable
                        }
                        onClick={() => {
                          if (stackIsResumable) {
                            tcStack.toast(
                              "Reset Stack before running again or Resume from last TC",
                              "ERROR"
                            );
                          } else if (!selectedPassage) {
                            tcStack.toast("No passage selected", "ERROR");
                          } else tcStack.run(selectedPassage, satellite);
                        }}
                      >
                        <Text>Run</Text>
                      </Button>
                    </>
                  )}
                </Flex>
              </Box>
            </Flex>
          </Header>
        </Box>

        <Box mt={1}>
          {selectedStack && (
            <TelecommandsList
              selectedStack={selectedStack}
              selectedPassage={selectedPassage}
              satellite={satellite}
            />
          )}
        </Box>
      </Grid>
    </Box>
  );
};
