import { fetchUtils } from "app/network";
import { constants } from "config";
import { store } from "app/store";

export const httpClient = (url, options = {}) => {
  const kc = store.getState().keycloak;

  if (!options.headers) {
    options.headers = new Headers({
      "content-type": constants.ContentType,
      "cache-control": constants.cache,
      pragma: constants.cache
    });
  } else if (!(options.headers instanceof Headers)) {
    throw Error("Invalid options.headers");
  }

  // Additional safeguard to make sure we provide a content-type
  const hasContentType = options.headers.has("Content-Type");
  const hasBody = !(
    options &&
    options.body &&
    options.body instanceof FormData
  );
  if (!hasContentType && hasBody) {
    options.headers.set("Content-Type", "application/json");
  }

  if (process.env.NODE_ENV === "test") {
    return fetchUtils.fetchJson(url, options);
  }
  return kc
    .updateToken(constants.TokenMinValidity)
    .then(() => {
      options.headers.set("Authorization", `Bearer ${kc.token}`);
      return fetchUtils.fetchJson(url, options);
    })
    .catch((error) => (error.status === 401 ? kc.login : error));
};
