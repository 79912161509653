import React from "react";
import { MenuItem } from "navigation/MainMenu";

const getActiveSatellite = (satellites) => {
  return satellites.filter((satellite) => {
    return satellite.visible;
  })[0];
};

const HistoricalLink = (props) => {
  return (
    <>
      {props && props.constellation && getActiveSatellite(props.satellites) && (
        <MenuItem
          to={`/constellation/${props.constellation.id}/satellite/${
            getActiveSatellite(props.satellites)["id"]
          }/datasources-filters`}
          data-testid="HistoricalLink"
        >
          Historical Data
        </MenuItem>
      )}
    </>
  );
};

export default HistoricalLink;
