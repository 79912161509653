import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Table, TableHead, TableRow, TableCell, TableBody } from "components";

const TableHeader = styled(TableCell)``;
const Input = styled.input`
  width: 100%;
  font-size: 12px;
  background-color: transparent;
  outline: none;
  border-width: 0px 0px 0px 0px;
  color: white;
  padding: 2px 0px;
`;

TableHeader.defaultProps = {
  position: "sticky",
  top: "2px",
  border: 1,
  borderColor: "fill.3",
  bg: "palette.blue.3"
};

export const DatasourceValueMapper = (props: any) => {
  const [formData, setFormData] = useState({ ...props.formData });
  const onChange = (index: any) => (event: any) => {
    const { id, name, value } = event.target;
    setFormData((prevFormData: any) => {
      return {
        ...prevFormData,
        [index]: {
          ...props.formData[index],
          id,
          datasource: props.schema.items[index].title,
          [name]: value
        }
      };
    });
  };
  useEffect(() => {
    props.onChange(formData);
  }, [formData, props.onChange]);

  const { items } = props.schema;
  return (
    <Table>
      <TableHead>
        <TableRow color="text.white">
          <TableHeader>NAME</TableHeader>
          <TableHeader>LABEL</TableHeader>
          <TableHeader>COLOR</TableHeader>
          <TableHeader>INDEX</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((ds: any, i: any) => {
          return (
            <TableRow id={ds.id} key={ds.id} backgroundColor="palette.white.1">
              <TableCell>
                <Input
                  name="name"
                  id={ds.id}
                  type={ds.type}
                  disabled
                  value={ds.title}
                  onChange={onChange(i)}
                />
              </TableCell>
              <TableCell>
                <Input
                  name="label"
                  id={ds.id}
                  type={ds.type}
                  value={formData[i].label}
                  onChange={onChange(i)}
                />
              </TableCell>
              <TableCell>
                <Input
                  name="color"
                  id={ds.id}
                  type="color"
                  value={formData[i].color}
                  onChange={onChange(i)}
                />
              </TableCell>
              <TableCell>
                <Input
                  name="index"
                  id={ds.id}
                  type={ds.type}
                  value={formData[i].index}
                  onChange={onChange(i)}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
