import React from "react";
import { Flex } from "primitives";
import { DragHandle } from "components";

export const Header = ({ children, center = false, ...props }) => (
  <Flex width="100%" overflow="visible" alignItems={center ? "center" : "auto"}>
    <DragHandle {...props} />
    {children}
  </Flex>
);
