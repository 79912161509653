import {
  SET_REFRESH_DATA_TRUE,
  SET_REFRESH_DATA_FALSE,
  SET_FEEDBACK,
  CLEAR_FEEDBACK
} from "../models/constants";
import { FeedbackStatus } from "../models";
import { CONNECTED } from "../reducers/NotificationsStatusReducer";
import { v4 as uuidv4 } from "uuid";

/*
 * Refresh Data
 */

export const setRefreshData = () => ({
  type: SET_REFRESH_DATA_TRUE
});

export const unsetRefreshData = () => ({
  type: SET_REFRESH_DATA_FALSE
});

/*
 * Feedback
 */

const CLEAR_FEEDBACK_TIMEOUT = 5000;

export const setFeedback =
  (
    title: string,
    status: FeedbackStatus,
    details = "",
    showInFooter: "showInFooter" | null = null
  ) =>
  (dispatch: any) => {
    const id = uuidv4();

    setTimeout(() => {
      dispatch(clearFeedback(id));
    }, CLEAR_FEEDBACK_TIMEOUT);

    const setFeedbackAction = {
      type: SET_FEEDBACK,
      payload: {
        id,
        title,
        status,
        details,
        showInFooter
      }
    };
    return dispatch(setFeedbackAction);
  };

export const clearFeedback = (id: string) => (dispatch: any) => {
  const clearFeedbackAction = {
    type: CLEAR_FEEDBACK,
    payload: {
      id
    }
  };

  return dispatch(clearFeedbackAction);
};

export const setNotificationStatus = (status: boolean) => ({
  type: CONNECTED,
  payload: status
});
