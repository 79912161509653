import React, { useEffect } from "react";
import { Box, Heading, Flex } from "primitives";
import { Link } from "react-router-dom";
import { AddButton, DownloadButton, DeleteButton } from "components";
import { useSelector } from "react-redux";

export const AlertDefinitionHeader = (props: any) => {
  const { selectSatellite } = props;
  const satellites = useSelector<any, any>(
    (state: any) => state.constellations.dashboard
  );
  const selected = satellites.find((s: any) => s.visible);
  const id = selected && selected.id;

  useEffect(() => {
    id && selectSatellite(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Flex alignItems="center" justifyContent="space-between" mb={4} mx={3}>
      <Heading display={1}>Alert Definitions</Heading>
      <Flex>
        <Link to="/alert-definitions/create">
          <AddButton>Create</AddButton>
        </Link>
        <Link to="/alert-definitions/import">
          <AddButton ml={2} mr={2}>
            Import
          </AddButton>
        </Link>
        <Link to="/alert-definitions/export">
          <DownloadButton mr={2}>Export</DownloadButton>
        </Link>
        <Link to="/alert-definitions/delete-all">
          <DeleteButton mr={2}> Delete All</DeleteButton>
        </Link>
      </Flex>
    </Flex>
  );
};
