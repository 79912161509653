import React, { Component } from "react";
import { Form } from "app/shared";
import { Box, Heading, Flex, Text } from "primitives";
import { SuspenseQuery, SuspenseMutation, Suspense } from "app/network";
import { CancelButton, SaveButton } from "components";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { fetchScript, editScript } from "../services";
import { schemaEdit, uiSchemaEdit } from "../models/schemas";
import { Script, EditScriptCommand } from "../models";
import { Resource } from "app/resources/models";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { fetchResourcesAction } from "app/resources/actions";

type RouteParams = {
  id: string;
};

interface ScriptEditProps extends RouteComponentProps<RouteParams> {
  resources: Resource[];
  fetchScript: (id: string) => Promise<Script>;
  editScript: (id: string, data: EditScriptCommand) => Promise<Script>;
  fetchResources: () => Promise<Resource[]>;
}

interface ScriptEditState {
  formData: EditScriptCommand | null;
  formChange: boolean;
}
export class ScriptEdit extends Component<ScriptEditProps, ScriptEditState> {
  state = {
    formData: null,
    formChange: false
  };

  componentDidMount() {
    this.props.fetchResources();
  }

  render() {
    const { formData, formChange } = this.state;
    const { resources } = this.props;

    return (
      <Suspense>
        <SuspenseQuery query={this.query}>
          {({ response }) => {
            return (
              response && (
                <Box color="text.default" data-testid="ScriptEdit" mx={3}>
                  <Flex mb={2}>
                    <Heading display={1}>Scripts</Heading>
                  </Flex>
                  <Flex flexDirection="column" bg="fill.0" p={3}>
                    <SuspenseMutation>
                      {({ loading, error, action }) => (
                        <Form
                          formData={formData || response}
                          schema={schemaEdit(resources || [])}
                          uiSchema={uiSchemaEdit}
                          disabled={loading}
                          onSubmit={(
                            form: { formData: EditScriptCommand },
                            e: React.FormEvent<HTMLInputElement>
                          ) => action(() => this.submit(e, form.formData))}
                          onChange={(form: ScriptEditState) =>
                            this.onChange(form)
                          }
                        >
                          <CancelButton
                            onClick={() => this.props.history.push("/scripts")}
                            mr={2}
                          >
                            Cancel
                          </CancelButton>
                          <SaveButton
                            type="submit"
                            disabled={loading || !formChange}
                          >
                            Save
                          </SaveButton>

                          {error ? (
                            <Box my={2}>
                              <Text color="text.danger">Error...</Text>
                            </Box>
                          ) : null}
                        </Form>
                      )}
                    </SuspenseMutation>
                  </Flex>
                </Box>
              )
            );
          }}
        </SuspenseQuery>
      </Suspense>
    );
  }

  private async submit(
    e: React.FormEvent<HTMLInputElement>,
    formData: EditScriptCommand
  ) {
    e.preventDefault();
    const { id } = this.props.match.params;
    await this.props.editScript(id, formData);
    this.props.history.push("/scripts");
  }

  private onChange(form: ScriptEditState) {
    this.setState({ formData: form.formData, formChange: true });
  }

  query = () => {
    const { id } = this.props.match.params;
    return this.props.fetchScript(id);
  };
}

const mapStateToProps = (state: any) => ({
  resources: state.resources.resources
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  fetchScript,
  editScript,
  fetchResources: () => dispatch(fetchResourcesAction())
});

export const ScriptEditContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ScriptEdit)
);
