import React from "react";
import { GraphDataModel } from "../shared";
import { ChartEngines } from "../shared/models";
import { Threshold } from "./models";

export class Gauge {
  model: GraphDataModel;
  engine: any;
  constructor(model: GraphDataModel, engine: any) {
    this.model = model;
    this.engine = engine;
  }

  draw = () => {
    const ref = this.model.axesRef;
    const svg = this.engine.select(ref.current);
    svg.selectAll("*").remove();
    const { radius, width: w, height: h, id } = this.model.getGaugeOptions();
    svg
      .append("circle")
      .attr("cx", w / 2)
      .attr("cy", h / 2)
      .attr("r", radius / 2)
      .style("fill", "#1f1e2c");

    this.drawThresholds(svg);
    this.drawValuePath(svg);
    this.drawValues(svg);

    return <svg ref={ref} width={w} height={h} data-testid={`gauge-${id}`} />;
  };

  drawArc = (dimensions: any[]) => {
    const [outer, inner, startAngle, endAngle] = dimensions;
    return this.engine
      .arc()
      .outerRadius(outer)
      .innerRadius(inner)
      .startAngle(startAngle)
      .endAngle(endAngle);
  };

  appendToSvg = (
    svg: SVGElement,
    type: string,
    arc: any,
    color: string,
    id: string
  ) => {
    const { transform } = this.model.getGaugeOptions();
    return (
      svg
        .append(type)
        // @ts-ignore
        .attr("data-testid", id)
        .attr("d", arc)
        .attr("transform", transform)
        .style("fill", color)
    );
  };

  drawValuePath = (svg: SVGElement) => {
    const { startAngle, endAngle, outer, inner, valuePathColor } =
      this.model.getGaugeOptions();
    const arc = this.drawArc([outer, inner, startAngle, endAngle]);
    this.appendToSvg(svg, "path", arc, valuePathColor, "value-path");
  };

  drawThresholds = (svg: SVGElement) => {
    const { radius, minAngle, maxAngle, innerArcCircle, thresholds, toRadian } =
      this.model.getGaugeOptions();
    const total = thresholds.reduce(
      (v: number, t: Threshold) => v + (t.max - t.min),
      0
    );
    let angle = minAngle * toRadian;

    thresholds.forEach((t: Threshold) => {
      const endAngle =
        angle + ((t.max - t.min) / total) * (maxAngle * toRadian * 2);
      const arc = this.drawArc([radius, innerArcCircle, angle, endAngle]);
      this.appendToSvg(svg, "path", arc, t.color, "arc-segment");
      angle = endAngle;
    });
  };

  drawValues = (svg: SVGElement) => {
    const {
      startAngle,
      endAngle,
      height,
      value,
      displayName,
      outer,
      inner,
      valueToColor,
      valueToText,
      valueColor,
      valueFontSize,
      labelColor,
      labelTopMargin,
      labelFontSize,
      extent
    } = this.model.getGaugeOptions();

    const scale = this.engine
      .scaleLinear()
      .domain(extent)
      .range([startAngle, endAngle]);

    const arc = this.drawArc([outer, inner, startAngle, scale(value)]);
    this.appendToSvg(svg, "path", arc, valueToColor(value), "arc-value");

    // DRAW DATASOURCE VALUE
    this.appendToSvg(svg, "text", arc, valueColor, "value")
      .attr("text-anchor", "middle")
      .attr("x", arc.centroid[0])
      .attr("y", arc.centroid[1])
      .text(valueToText(value))
      .style("font-size", `${height * 0.025 + valueFontSize}px`);

    // DRAW DATASOURCE LABEL
    this.appendToSvg(svg, "text", arc, labelColor, "label")
      .attr("text-anchor", "middle")
      .attr("x", arc.centroid[0])
      .attr("y", height * 0.35 + labelTopMargin)
      .text(displayName)
      .style("font-size", `${height * 0.035 + labelFontSize}px`);
  };
}
