import React, { useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { Flex } from "primitives";
import { AddButton, SaveButton, CancelButton } from "components";
import { Dialog, DialogTitle, DialogActions, Box } from "primitives";
import useToggle from "components/toggler/hooks/useToggle";
import { ConfigForm } from "../Config/ConfigForm/ConfigForm";
import { BookerModal } from "../Booker/BookerForm/Modal";

export const HeaderActions = (props: any) => {
  const isCandidates = props?.location?.pathname?.includes("candidates");
  const isConfig = props?.location?.pathname?.includes("passage-manager");
  return (
    <Flex ml={2}>
      {!isCandidates && <BookerConfig />}
      {!isConfig ||
        (isCandidates && (
          <Link to="/passage-manager">
            <AddButton>{"BOOKING CONFIGS"}</AddButton>
          </Link>
        ))}
      {isCandidates && <BookerModal {...props} />}
    </Flex>
  );
};

export const BookerConfig = () => {
  const formRef = useRef(null);
  const defaultToggled = false;
  const [toggled, onToggle] = useToggle({
    defaultToggled: defaultToggled ? defaultToggled : false
  });

  const cancel = useCallback(() => {
    onToggle();
  }, []);

  return (
    <Flex ml={2} data-testid="create-booker-config">
      <AddButton size={"small"} mr={2} onClick={onToggle}>
        {"CREATE CONFIG"}
      </AddButton>
      <Dialog open={toggled} maxWidth="md" height="100%">
        <DialogTitle>Create a passage manager configuration</DialogTitle>
        <Flex height="100%">
          <Box overflow="scroll" width="100%">
            <ConfigForm formRef={formRef} closeModal={cancel} type="config" />
          </Box>
        </Flex>
        <DialogActions>
          <Flex justifyItems="flex-end" alignItems="center">
            <SaveButton
              mr={1}
              type="submit"
              id="passage-manager"
              form="passage-manager"
            >
              Save
            </SaveButton>
            <CancelButton onClick={cancel} mx={1}>
              Cancel
            </CancelButton>
          </Flex>
        </DialogActions>
      </Dialog>
    </Flex>
  );
};

export const UploadTLE = () => {
  const defaultToggled = false;
  const [toggled, onToggle] = useToggle({
    defaultToggled: defaultToggled ? defaultToggled : false
  });

  const submit = useCallback(() => {
    console.log("submit");
  }, []);
  const cancel = useCallback(() => {
    onToggle();
  }, []);

  return (
    <>
      <AddButton size={"small"} onClick={onToggle}>
        {"Upload TLE"}
      </AddButton>
      <Dialog open={toggled} maxWidth="md" height="100%">
        <DialogTitle>Upload TLE from file</DialogTitle>
        <Flex height="100%"></Flex>
        <DialogActions>
          <Flex justifyItems="flex-end" alignItems="center">
            <SaveButton mr={1} onClick={submit}>
              Submit
            </SaveButton>
            <CancelButton onClick={cancel} mx={1}>
              Cancel
            </CancelButton>
          </Flex>
        </DialogActions>
      </Dialog>
    </>
  );
};
