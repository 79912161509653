import React, {
  useEffect,
  useState,
  useMemo,
  ChangeEvent,
  useRef
} from "react";
import { connect } from "react-redux";
import { Passage, SelectedSatPassages } from "../../models";
import { getActiveSatellite } from "app/shared/utils";
import { SatellitePassages } from "../../reducers";
import { SatelliteInstance } from "app/satellite/models";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { selectPassageAction } from "../../actions";
import { Text } from "primitives";
import {
  getCurrNext,
  currNextChanged,
  getSelectOptions,
  updatePassages,
  clearExpiredPass,
  RETRY_ON_FAIL
} from "../../helpers";
import { SelectContainer, SelectLabel, Select } from "components";
import moment from "moment";

interface PassagesSelectorProps {
  satellites: SatellitePassages;
  selectedPassage: Passage;
  selectedSatellite: SatelliteInstance;
  satelliteInstances: SatelliteInstance[];
  selectPassage: (passage: Passage | null) => void;
  serverTime: Date;
}

const initialPassages: SelectedSatPassages = {
  current: [],
  next: []
};

export const PassagesSelector = ({
  selectPassage,
  satellites,
  selectedPassage,
  selectedSatellite,
  satelliteInstances,
  serverTime
}: PassagesSelectorProps) => {
  const [selectedSatPassages, setSelectedSatPassages] =
    useState<SelectedSatPassages>(initialPassages);
  const fetching = useRef(false);
  // In some case the next passage request can respond with an empty array. Set default to false here.
  const failedNextPassage = useRef(false);

  useEffect(() => {
    if (
      satelliteInstances.length > 0 &&
      !fetching.current &&
      selectedSatellite
    ) {
      /**
       * 1. Fetching current and next passage for each sat instance
       * will populate the prop 'satellites' in the store.
       * TODO: fetching current/next passage should be done with one single service in stream mode.
       */
      fetching.current = true;
      if (selectedSatellite) {
        updatePassages({
          satelliteId: selectedSatellite.id
        }).then((res) => {
          // If the next passage request respond with an empty array set this to true and block the request loop for 10s.
          failedNextPassage.current = res[1].length === 0;
          setTimeout(() => {
            failedNextPassage.current = false;
          }, RETRY_ON_FAIL);
          fetching.current = false;
        });
      }
    }
  }, [satelliteInstances, selectedSatellite]);

  useEffect(() => {
    /**
     * 2. Once all satellite instance have been fetched and the prop 'satellites'
     * is updated i set once the curr/next passages to be rendered.
     */
    const fetchedSelectedSatellites = Object.keys(satellites).includes(
      selectedSatellite && selectedSatellite.id.toString()
    );
    if (selectedSatellite && fetchedSelectedSatellites) {
      const { current, next } = getCurrNext(satellites, selectedSatellite.id);

      const changed = currNextChanged({
        current: { prevState: selectedSatPassages.current, nextState: current },
        next: { prevState: selectedSatPassages.next, nextState: next }
      });

      if (changed) {
        setSelectedSatPassages({ current, next });
      }
    }
  }, [satellites, selectedSatellite]);

  useEffect(() => {
    /**
     * 3. Once got the selected satellite passages setting a selected passage
     */
    const selected = selectedSatPassages.current[0] || null;
    selectPassage(selected);
  }, [selectedSatPassages]);

  useEffect(() => {
    if (fetching.current || !selectedSatellite) return;

    const currentFinished =
      selectedSatPassages.current[0] &&
      moment(moment(serverTime)).isAfter(selectedSatPassages.current[0].los);

    const nextStarted =
      selectedSatPassages.next[0] &&
      moment(moment(serverTime)).isSameOrAfter(selectedSatPassages.next[0].aos);

    // console.log(currentFinished, nextStarted);
    if (currentFinished && selectedSatPassages.current.length > 0) {
      const clearedCurr = clearExpiredPass("current", selectedSatPassages);
      return setSelectedSatPassages(clearedCurr);
    }

    if (nextStarted && selectedSatPassages.next.length > 0) {
      // When a nextPassage starts it is removed from the list of nextPassages and moved into the currentPassages array
      const clearedNext = clearExpiredPass("next", selectedSatPassages);
      const newSelectedSatPassages = {
        next: clearedNext.next,
        current: [...clearedNext.current, selectedSatPassages.next[0]].sort(
          (a, b) => moment(a.los).valueOf() - moment(b.los).valueOf()
        )
      };
      return setSelectedSatPassages(newSelectedSatPassages);
    }

    const shouldUpdate =
      selectedSatPassages.next.length === 0 &&
      satelliteInstances.length > 0 &&
      !failedNextPassage.current;

    if (shouldUpdate) {
      fetching.current = true;
      updatePassages({ satelliteId: selectedSatellite.id }).then((res) => {
        failedNextPassage.current = res[1].length === 0;
        setTimeout(() => {
          failedNextPassage.current = false;
        }, 10000);
        fetching.current = false;
      });
    }
  }, [serverTime, selectedSatellite]);

  const onHandleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (selectedSatPassages.current.length > 0) {
      const currentPassage = selectedSatPassages.current.filter(
        (passage: Passage) => {
          return passage.passageID === parseInt(e.target.value, 10);
        }
      )[0];

      selectPassage(currentPassage);
    }
  };

  const { current, next } = selectedSatPassages;
  return useMemo(() => {
    const havePassages = current.length > 0 || next.length > 0;
    return (
      (havePassages && (
        <SelectContainer data-testid={"passage-selector"} bg="transparent">
          <SelectLabel htmlFor="satellite">Current passages:</SelectLabel>
          <Select
            id="passages"
            value={
              current.length > 0
                ? selectedPassage && selectedPassage.passageID
                : "none"
            }
            onChange={onHandleChange}
            color="text.success"
            readOnly={current.length === 0 && next.length > 0}
          >
            {...getSelectOptions(selectedSatPassages)}
          </Select>
        </SelectContainer>
      )) || <Text fontSize={4}>Without next passages</Text>
    );
  }, [current, next, selectedPassage]);
};

const mapStateToProps = (state: any) => ({
  serverTime: state.visibilityWindow.serverTime,
  satellites: state.visibilityWindow.satellites,
  selectedPassage: state.visibilityWindow.selectedPassage,
  selectedSatellite: getActiveSatellite(state.constellations.dashboard),
  satelliteInstances: state.constellations.selected
    ? state.constellations.selected.satelliteInstances
    : []
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  selectPassage: (passage: Passage | null) =>
    dispatch(selectPassageAction(passage))
});

export const PassagesSelectorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PassagesSelector);
