import { combineReducers } from "redux";
import RefreshDataReducer from "./RefreshDataReducer";
import FeedbackDataReducer from "./FeedbackDataReducer";
import NotificationsStatusReducer from "./NotificationsStatusReducer";

export default combineReducers({
  refreshData: RefreshDataReducer,
  feedback: FeedbackDataReducer,
  notificationsStatus: NotificationsStatusReducer
});
