import { DefaultTheme } from "styled-components";
import colors from "./palette";
import fonts from "./fonts";
import buttons from "./buttons";

export const borders = [
  0,
  "0.5px solid",
  "0.5px dashed",
  "1px solid",
  "1.5px solid",
  "2px solid"
];
export const breakpoints = {
  sm: "544px",
  md: "768px",
  lg: "1024px"
};
//                    0  1  2  3  4  5
export const radii = [0, 1, 3, 5, 7, 10];
//                    0  1  2  3   4   5   6   7   8   9   10
export const space = [0, 4, 8, 16, 24, 32, 40, 48, 56, 64, 72];
export const zIndices = [1, 2, 3];
export const opacities = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9];
export const sizes = {
  maxWidth: breakpoints.lg,
  sidebarWidth: {
    sm: 1,
    md: "200px",
    lg: null
  },
  navbarHeight: {
    xs: "35px",
    md: null,
    lg: null
  },
  footerHeight: {
    xs: "40px",
    md: "27px",
    lg: null
  }
};
export const mediaQueries = {
  sm: `@media screen and (min-width: ${breakpoints.sm})`,
  md: `@media screen and (min-width: ${breakpoints.md})`,
  lg: `@media screen and (min-width: ${breakpoints.lg})`,
  smMinHeight: `@media screen and (min-height: 620px) and (min-width: ${
    breakpoints.md
  })`
};

export const theme: DefaultTheme = {
  borders,
  buttons,
  breakpoints,
  colors,
  opacities,
  radii,
  space,
  sizes,
  mediaQueries,
  zIndices,
  ...fonts
};
