import { FileInput } from "components";
import { Label } from "primitives";
import React from "react";

function processJsonFile(files: any) {
  const file = files[0];
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = (err: any) => {
      return reject(err);
    };
    reader.onload = (event: any) => {
      if (!event) return;
      return resolve(event.target && event.target.result);
    };
    reader.readAsText(file, "UTF-8");
  });
}

export const FileWidget = (props: any) => {
  return (
    <>
      <Label htmlFor="json-import">
        Choose a file in valid JSON format:
      </Label>
      <br />
      <br />
      <FileInput
        type="file"
        id="json-import"
        width="100%"
        accept=".json"
        required={props.required}
        onChange={(event) =>
          processJsonFile(event.target.files).then(props.onChange)
        }
      />
    </>
  );
};
