import { literalParser } from "../parsers/literal";

/*
id -> id
name -> name

size | constantSize
minimum -> minItems

size | dynamicSize
@pattern

itemSpec -> items
*/
class ArrayParser {
  /**
   *
   * @param {object} size
   * @returns {object}
   */
  getLimits(arg) {
    const { size } = arg;

    if (`${size}`.startsWith("@")) {
      return {
        pattern: size
      };
    }

    return {
      minItems: size,
      maxItems: size
    };
  }

  /**
   *
   * @param {object} itemSpec
   * @returns {object}
   */
  getItems(itemSpec) {
    return { items: literalParser.parse(itemSpec) };
  }

  /**
   *
   * @param {object} arg
   * @returns {object} schema array
   */
  parse(arg) {
    const { itemSpec, id, name, argType } = arg;
    return {
      type: "array",
      argType,
      id,
      name: id,
      title: name,
      canAdd: false,
      hasRemove: false,
      ...this.getItems(itemSpec),
      ...this.getLimits(arg)
    };
  }

  /**
   *
   * @param {object} arg
   * @returns {object} schema
   *
   */
  parseUI(arg, hideDefaults) {
    const { itemSpec } = arg;
    const schema = {
      items: literalParser.parseUI(itemSpec, hideDefaults),
      classNames: !arg.name ? "hidden-title" : ""
    };
    return schema;
  }
}

export const arrayParser = new ArrayParser();
