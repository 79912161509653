import { GET_LIST, CREATE, DELETE, UPDATE_ONE } from "app/network";
import { dataProvider } from "app/network";

/**
 *
 * @param {object} record = satellite definition
 */
export const getFrameDefinition = (record) => {
  return dataProvider(
    GET_LIST,
    `satellite-definition/${record.id}/frame-definition`
  );
};

/**
 * @param {object} record = satellite definition
 * @param {object} data = frame definition
 */
export const createFrameDefinition = (record, data) => {
  return dataProvider(
    CREATE,
    `satellite-definition/${record.id}/frame-definition`,
    {
      data
    }
  );
};

/**
 * @param {object} record = satellite definition
 * @param {object} data = frame definition
 */
export const editFrameDefinition = (record, data) => {
  return dataProvider(
    UPDATE_ONE,
    `satellite-definition/${record.id}/frame-definition`,
    {
      data
    }
  );
};

/**
 * @param {object} record = satellite definition
 */
export const deleteFrameDefinition = (record) => {
  return dataProvider(
    DELETE,
    `satellite-definition/${record.id}/frame-definition`
  );
};

export const updateFrameDefinition = (data, satelliteDefinition) => {
  const { frameDefinition, id } = data;

  try {
    if (id) {
      return editFrameDefinition(satelliteDefinition, { frameDefinition });
    } else if (frameDefinition) {
      return createFrameDefinition(satelliteDefinition, { frameDefinition });
    }
  } catch (error) {
    // showNotification(error.message, "error");
  }
};
