import styled from "styled-components";
import { GRID, GridProps as GridBaseProps } from "styles/constants";
import Flex, { FlexLayoutProps } from "./Flex";
import { DATA_TEST_ID_ATTR_NAME } from "components/testable/models";

export interface GridProps extends GridBaseProps, FlexLayoutProps {}

const Grid = styled(Flex)<GridProps>`
  ${GRID}
`;

Grid.displayName = "Box";

Grid.defaultProps = {
  display: "grid",
  [DATA_TEST_ID_ATTR_NAME]: Grid.displayName
};

export default Grid;
