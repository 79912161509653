import { ITimeController } from "app/shared/timeController/models";
import moment from "moment";
import { subtractTime } from "app/shared/timeController/utils";

const getXDomain = (options: any, timeController: ITimeController) => {
  const domain: [number, number] = [0, 0];
  if (options.isGloballyControlled) {
    const { quickRange } = timeController;
    if (quickRange) {
      const fromValue = (quickRange as string).slice(
        (quickRange as string).indexOf("-") + 1,
        (quickRange as string).length - 1
      );
      const unit = (quickRange as string).slice(-1);

      const from = subtractTime(
        new Date(Date.now()),
        Number(fromValue),
        unit
      ).getTime();
      domain[0] = from;
      domain[1] = new Date().getTime();
    } else {
      const duration = Date.now() - new Date(timeController.from).getTime();
      const mins = Math.floor((duration / 1000 / 60) << 0);
      domain[0] = new Date(Date.now() - mins * 60 * 1000).getTime();
      domain[1] = new Date(timeController.to).getTime();
    }
    return domain;
  }

  if (options.timeReference.from) {
    domain[0] = new Date(options.timeReference.from).getTime();
  } else if (options.liveStream && options.liveStream.from) {
    domain[0] = new Date(
      Date.now() - options.liveStream.from * 60 * 1000
    ).getTime();
  } else return null;

  if (options.timeReference.to) {
    domain[1] = new Date(options.timeReference.to).getTime();
  } else {
    domain[1] = new Date().getTime();
  }

  return domain;
};

const getYDomain = (options: any) => {
  const domain: any[] = [0, 0];
  if (!isNaN(options.scale.min)) domain[0] = options.scale.min;
  else domain[0] = null;

  if (!isNaN(options.scale.max)) domain[1] = options.scale.max;
  else domain[1] = null;

  return domain;
};

const getGridThickness = (options: any) => {
  if (options.grid.thickness) return options.grid.thickness;
  return 2;
};

const getXTicks = (options: any) => {
  if (options.grid.size.x) return options.grid.size.x;
  return 5;
};

const getYTicks = (options: any) => {
  if (options.grid.size.y) return options.grid.size.y;
  return 5;
};

const getPlotThickness = (options: any) => {
  if (!isNaN(options.plot.thickness)) return options.plot.thickness;
  return 2;
};

const getWarningLimits = (options: any, dataSourceAlertData: any) => {
  if (!options.limits) return [];
  const warningLimits =
    options.limits.type === "manual"
      ? options.limits.warning
      : dataSourceAlertData.warning;
  if (!warningLimits) return [];
  else return warningLimits;
};

const getCriticalLimits = (options: any, dataSourceAlertData: any) => {
  if (!options.limits) return [];
  const criticalLimits =
    options.limits.type === "manual"
      ? options.limits.critical
      : dataSourceAlertData.critical;
  if (!criticalLimits) return [];
  else return criticalLimits;
};

const getBoundAxisX = (options: any) => {
  if (!options.axis || !options.axis.boundAxis || !options.axis.boundAxis.x)
    return null;
  if (options.axis.boundAxis.x.type === "datasource")
    return options.axis.boundAxis.x;
  return null;
};

const getBoundAxisY = (options: any) => {
  if (!options.axis || !options.axis.boundAxis || !options.axis.boundAxis.y)
    return null;
  if (options.axis.boundAxis.y.type === "datasource")
    return options.axis.boundAxis.y;
  return null;
};

const getSegmentTimeRange = (options: any) => {
  if (
    !options.axis ||
    !options.axis.boundAxis ||
    !options.axis.boundAxis.segmentTimeRange
  )
    return 0;
  else return options.axis.boundAxis.segmentTimeRange;
};

const convertSelectionToTime = (
  selection: [[number, number], [number, number]],
  xDomain: [number, number] | null,
  dimensions: { width: number; margin: { left: number } }
) => {
  if (xDomain) {
    const { width, margin } = dimensions;
    const initialTimeRange = moment(xDomain[1]).diff(moment(xDomain[0]));
    const newXDomain = [0, 0];
    newXDomain[0] =
      Math.floor((initialTimeRange * selection[0][0]) / (width + margin.left)) +
      xDomain[0];
    newXDomain[1] =
      Math.floor((initialTimeRange * selection[1][0]) / width) + xDomain[0];
    return newXDomain;
  }
};

export {
  getXDomain,
  getYDomain,
  getGridThickness,
  getXTicks,
  getYTicks,
  getPlotThickness,
  getWarningLimits,
  getCriticalLimits,
  getBoundAxisX,
  getBoundAxisY,
  getSegmentTimeRange,
  convertSelectionToTime
};
