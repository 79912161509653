import React, { Component, MouseEvent } from "react";
import { Box, Heading, Flex, Text, Grid, Button } from "primitives";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter, Link } from "react-router-dom";
import { fetchResource, editResource } from "../services";
import { ResourceEdit, ResourceDetail } from "../models";
import Dropzone from "react-dropzone";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  EditButton
} from "components";

type RouteParams = {
  id: string;
};

interface ResourceEditProps extends RouteComponentProps<RouteParams> {
  getResource: (id: string) => Promise<ResourceDetail>;
  updateResource: (id: string, data: FormData) => Promise<any>;
}

interface ResourcesEditState<T extends File> {
  formData: ResourceEdit | null;
  resource: ResourceDetail | null;
  resourceLoaded: boolean;
  file: T | null;
  loading: boolean;
  uploadError: string;
}

export class ResourceEditView extends Component<
  ResourceEditProps,
  ResourcesEditState<any>
> {
  constructor(props: ResourceEditProps) {
    super(props);
    this.state = {
      formData: null,
      resource: null,
      resourceLoaded: false,
      file: null,
      loading: false,
      uploadError: ""
    };
  }

  componentWillMount() {
    const { match, getResource } = this.props;
    getResource(match.params.id)
      .then((resource) => this.setState({ resource, resourceLoaded: true }))
      .catch((err) => {
        // eslint-disable-next-line no-console
        this.setState({ resourceLoaded: true });
        console.error(err);
      });
  }

  private onChange(form: ResourcesEditState<any>) {
    this.setState({ formData: form.formData });
  }

  private clearFile() {
    this.setState({ file: null });
  }

  private onDropAccepted<T extends File>(files: T[]) {
    this.setState({ file: files[0], uploadError: "" });
  }

  private onUploadHander(e: MouseEvent<HTMLButtonElement>) {
    const { file } = this.state;
    if (file) {
      try {
        this.setState({ loading: true }, () => {
          this.sendResource(file);
          this.clearFile();
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
      this.setState({ loading: false });
      e.preventDefault();
    }
  }

  private sendResource(file: File) {
    const { match, updateResource } = this.props;
    const data = new FormData();
    data.append("name", file.name);
    data.append("filePayload", file);
    updateResource(match.params.id, data)
      .then(() => {
        this.props.history.push("/scripts");
      })
      .catch((err: Error) => {
        this.setState({ uploadError: err.message });
      });
  }

  render() {
    if (this.state.resourceLoaded) {
      if (this.state.resource) {
        return this.renderResourceUploadForm();
      } else {
        return this.renderResourceNotFound();
      }
    } else {
      return <>Loading...</>;
    }
  }

  private renderResourceUploadForm() {
    const { file, loading, resource, uploadError } = this.state;
    if (!resource) {
      throw new Error(
        "Cannot render resource detail when the resource is not defined"
      );
    }

    const numScriptReferences = resource.referencedBy
      ? resource.referencedBy.length
      : 0;

    return (
      <Box color="text.default" data-testid="ResourceCreate" mx={3}>
        <Flex mb={2}>
          <Heading display={1}>Edit resource: {resource.name}</Heading>
        </Flex>

        {numScriptReferences > 0 && (
          <>
            <Flex mb={2}>
              <Heading display={3}>Script references</Heading>
            </Flex>
            <Table mb={2}>
              <TableHead>
                <TableRow bg="fill.0">
                  <TableCell width="50">ID</TableCell>
                  <TableCell width="auto">Name</TableCell>
                  <TableCell width="50" />
                </TableRow>
              </TableHead>
              <TableBody>
                {resource.referencedBy.map((ref) => (
                  <TableRow>
                    <TableCell>{ref.id}</TableCell>
                    <TableCell>{ref.name}</TableCell>
                    <TableCell>
                      <Link to={`/scripts/${ref.id}`}>
                        <EditButton>Edit</EditButton>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}

        {uploadError && (
          <Flex mb={2}>
            <Heading display={3} color="text.danger">
              {this.state.uploadError}
            </Heading>
          </Flex>
        )}
        <Grid data-tesid="Resource Upload">
          <Dropzone
            onDrop={(files) => this.onDropAccepted(files)}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <>
                <div {...getRootProps()}>
                  <Box mb={2} p={3} bg="fill.0" border={1}>
                    <input {...getInputProps()} />
                    <p>
                      Drop a file to replace the resource, or click to select
                      it.
                    </p>
                  </Box>
                </div>
                {file && (
                  <Flex
                    p={2}
                    my={2}
                    bg="fill.0"
                    key={file.path}
                    alignItems="center"
                  >
                    <Text pr={2}>
                      {file.path} - {file.size} bytes
                    </Text>
                  </Flex>
                )}
              </>
            )}
          </Dropzone>

          <Button
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              this.onUploadHander(e);
            }}
            disabled={loading || !file}
          >
            Upload
          </Button>
        </Grid>
      </Box>
    );
  }

  private renderResourceNotFound() {
    return (
      <Flex mb={2}>
        <Heading display={3} color="text.danger">
          Sorry the provided resource is not available.
        </Heading>
      </Flex>
    );
  }
}

const mapDispatchToProps = () => ({
  getResource: fetchResource,
  updateResource: editResource
});

export const ResourceEditContainer = withRouter(
  connect(
    null,
    mapDispatchToProps
  )(ResourceEditView)
);
