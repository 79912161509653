import React from "react";
import { Absolute, Flex, Text, Box } from "primitives";
import { FormatedData } from "app/telemetry/models";

interface GraphLegendProps {
  keys: number[];
  showLegend: boolean;
  legendRef: { current: HTMLDivElement };
  color: (key: number) => string;
  selectedIndex: number | null;
  data: FormatedData[];
  onChange: (key: number) => void;
}

export const GraphLegend = ({
  keys,
  showLegend,
  legendRef,
  color,
  selectedIndex,
  data,
  onChange
}: GraphLegendProps) => {
  return keys && showLegend ? (
    <Absolute bottom={4} left={4}>
      <Flex flexWrap="wrap" ref={legendRef}>
        {keys.map((key: number) => {
          const { name, units } = data[key].dataSource;
          return (
            <Flex
              key={key}
              m={1}
              alignItems="center"
              cursor="pointer"
              opacity={
                selectedIndex === null || key === selectedIndex ? 1 : 0.2
              }
              onClick={() => onChange(key)}
            >
              <Box
                borderRadius="50%"
                height={14}
                width={14}
                bg={color(key)}
                cursor="pointer"
              ></Box>
              <Text mx={2} color={color(key)}>{`${name} ${
                units && units.unit !== "-" ? units.unit : ""
              }`}</Text>
            </Flex>
          );
        })}
      </Flex>
    </Absolute>
  ) : null;
};
