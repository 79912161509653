import {
  resourcesDataProvider,
  ftmsDataProvider,
  GET_LIST,
  CREATE,
  UPLOAD,
  UPDATE_ONE,
  GET_ONE,
  DELETE
} from "app/network";
import { store } from "app/store";
import Axios from "axios";
import fileDownload from "js-file-download";
import { OperationStatus, OperationType } from "../models";
import { createResourceDTO, listOperationsDTO } from "../models/dto";

/*
 * Resources
 */

export const getResourcesByPath = (
  repositoryId: number,
  path: string,
  name: string,
  isSortAscending: boolean
) => {
  return resourcesDataProvider(
    GET_LIST,
    `repositories/${repositoryId}/locations/${path}?metadata=${name}${
      !isSortAscending ? "&sort=desc" : ""
    }`
  );
};

export const createResource = (repositoryId: number, data: any) => {
  return resourcesDataProvider(
    CREATE,
    `repositories/${repositoryId}/resources/`,
    { data: createResourceDTO(data) }
  );
};

export const deleteResource = (
  repositoryId: number,
  resourceId: string,
  isPermanentDelete: boolean
) => {
  return resourcesDataProvider(
    DELETE,
    `repositories/${repositoryId}/resources/${resourceId}?permanent=${isPermanentDelete}`
  );
};

export const moveResource = (
  repositoryId: number,
  resourceId: string,
  destination: string,
  copy: boolean
) => {
  return resourcesDataProvider(
    CREATE,
    `repositories/${repositoryId}/resources/${resourceId}/copy/`,
    { data: { destination, copy } }
  );
};

export const uploadResource = (
  repositoryId: number,
  uploadId: number,
  data: any
) => {
  const headers = {
    "Content-Type": "application/octet-stream"
  };
  return resourcesDataProvider(
    UPLOAD,
    `repositories/${repositoryId}/uploads/${uploadId}`,
    { data },
    headers
  );
};

export const updateResource = (
  repositoryId: number,
  resourceId: number,
  data: any
) => {
  return resourcesDataProvider(
    UPDATE_ONE,
    `repositories/${repositoryId}/resources/${resourceId}`,
    { data: createResourceDTO(data) }
  );
};

export const updateResourceMetadata = (
  repositoryId: number,
  resourceId: number,
  data: any
) => {
  return resourcesDataProvider(
    UPDATE_ONE,
    `repositories/${repositoryId}/resources/${resourceId}/metadata`,
    { data: createResourceDTO(data) }
  );
};

export const downloadResource = (
  repositoryId: number,
  resourceId: string,
  versionId: number
) => {
  return resourcesDataProvider(
    GET_ONE,
    `repositories/${repositoryId}/resources/${resourceId}/versions/${versionId}`
  );
};

export const downloadResourceLastVersion = (
  repositoryId: number,
  resourceId: string,
  resourceName: string
) => {
  //Custom data provider can't be used in this request
  const kc = store.getState().keycloak;
  return Axios.get(
    `${process.env.REACT_APP_API_URL}/arms/api/v1/repositories/${repositoryId}/resources/${resourceId}/versions/`,
    {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${kc.token}`
      }
    }
  ).then((res) => {
    fileDownload(res.data, resourceName);
  });
};

/*
 * OPERATIONS
 */

export const getOperationsList = (
  operationType: OperationType,
  satelliteId: number
) => {
  const data = {
    operationType,
    satelliteId
  };
  return ftmsDataProvider(GET_LIST, `operations`, {
    filter: listOperationsDTO(data)
  });
};

export const deleteOperation = (operationId: number, satelliteId: number) => {
  return ftmsDataProvider(
    UPDATE_ONE,
    `sat/${satelliteId}/operations/${operationId}`
  );
};

export const updateDownload = (
  operationId: number,
  satelliteId: number,
  operationStatus: OperationStatus
) => {
  return ftmsDataProvider(
    UPDATE_ONE,
    `sat/${satelliteId}/downloads/${operationId}`,
    { opStatus: operationStatus }
  );
};

export const updateUpload = (
  operationId: number,
  satelliteId: number,
  operationStatus: OperationStatus,
  operationDismissed = "false"
) => {
  const data = { opStatus: operationStatus, opDismissed: operationDismissed };
  return ftmsDataProvider(
    UPDATE_ONE,
    `sat/${satelliteId}/uploads/${operationId}`,
    { data }
  );
};

export const getWritableArmsPaths = () => {
  return resourcesDataProvider(GET_LIST, "repositories/1/write-paths/");
};
