import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

export const AlertDefinitionEditor = (props: any) => {
  const [condition, setCondition] = useState<any>(props.value);
  const highlightedTextRef = useRef(null);

  // TODO:Improve syntax highlighting part when indexes parsing is decided
  // useEffect(() => {
  //   // apply syntax highlighter after a delay
  //   const id = setTimeout(() => {
  //     if (highlightedTextRef && highlightedTextRef.current) {
  //       // @ts-ignore
  //       highlightedTextRef.current.innerHTML = highlightKeywords(condition);
  //       const range = document.createRange();
  //       const selection = window.getSelection();
  //       range.selectNodeContents(highlightedTextRef.current);
  //       range.collapse(false);
  //       // @ts-ignore
  //       selection.removeAllRanges();
  //       // @ts-ignore
  //       selection.addRange(range);
  //     }
  //   }, 500);
  //   return () => clearTimeout(id);
  // }, [condition]);

  return (
    <div>
      <StyledInput
        ref={highlightedTextRef}
        contentEditable
        placeholder="main_ds > another_ds AND main_ds < another_ds OR main_ds = 1"
        className="alert-definition-editor"
        defaultValue={props.value}
        onInput={(e) => {
          setCondition(e.currentTarget.value);
          return props.onChange(processCondition(e.currentTarget.value));
        }}
      />
    </div>
  );
};

const hasKeywords = (input: string) => {
  const keywords = ["and", "or"];
  return keywords.some((kw) => kw.includes(input));
};

export const highlightKeywords = (input: string) => {
  const keywords = [
    "and",
    "or",
    "AND",
    "OR",
    ">",
    "<",
    "===",
    "!==",
    "=",
    "<=",
    ">="
  ];
  const pattern = new RegExp(`(${keywords.join("|")})`, "gi");
  const highlightedKeywords = input.replace(
    pattern,
    (m) => `<span class="keyword">${m}</span>`
  );

  const dsNamePattern = /\b\w*_\w*\b/g;
  const dsPattern = new RegExp(dsNamePattern);
  const highlightedDSNames = highlightedKeywords.replace(
    dsPattern,
    (m) => `<span class="datasource">${m}</span>`
  );
  return highlightedDSNames;
};

const processCondition = (conditionString: any) => {
  let condition = "";
  if (hasKeywords(conditionString)) {
    // TODO: do validation, post-processing etc
    condition = conditionString;
  }
  return conditionString || "";
};

const StyledInput = styled.input`
  height: 80px;
  width: 100%;
  font-size: 20px;
  color: white;
  background-color: #272822;
  border-width: 0px 0px 1px 0px;
  padding: 15px 10px;
  &:focus {
    border-bottom-width: 1px;
  }
  &:hover {
    border-bottom-width: 1px;
  }
  &::placeholder {
    font-size: 16px;
    color: grey;
  }
`;
