import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const ZoomIn = ({
  width,
  height,
  size,
  color,
  dataTestId
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 30 30"
    color={color}
    data-testid={dataTestId}
  >
    <path
      d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Zm4-8H12V7a1,1,0,0,0-2,0v3H7a1,1,0,0,0,0,2h3v3a1,1,0,0,0,2,0V13h3a1,1,0,0,0,0-2Z"
      fill={color}
    />
  </Svg>
);

ZoomIn.defaultProps = {
  width: "24",
  height: "30"
};
export default ZoomIn;
