import { store } from "app/store";
import { TimeControllerActionTypes } from "../models/index";

const {
  SET_FROM_TO,
  SET_TIME_RANGE,
  RESET_TIME_CONTROLLER,
  SET_WINDOW_SIZE,
  ZOOM_OUT,
  ZOOM_IN,
  NEXT_RANGE,
  PREV_RANGE
} = TimeControllerActionTypes;

export const setFromTo = (payload) => ({
  payload,
  type: SET_FROM_TO
});

export const updateDateRange = (range) => {
  return store.dispatch(setFromTo(range));
};

export const setQuickRange = (payload) => ({
  payload,
  type: SET_TIME_RANGE
});

export const resetTimeController = () => ({
  type: RESET_TIME_CONTROLLER
});

export const setWindowSize = (payload) => ({ payload, type: SET_WINDOW_SIZE });

export const zoomOut = () => ({ type: ZOOM_OUT });
export const zoomIn = () => ({ type: ZOOM_IN });

export const setNextRange = () => ({ type: NEXT_RANGE });

export const setPreviousRange = () => ({ type: PREV_RANGE });
