import React from "react";
import { Switch, Route } from "react-router-dom";
import { DashboardContainer as Dashboard } from "./containers/DashboardContainer";
import { DashboardListContainer as DashboardList } from "./containers/DashboardListContainer";
import { DashboardEditContainer as DashboardEdit } from "./containers/DashboardEditContainer";
import { DashboardCreateContainer as DashboardCreate } from "./containers/DashboardCreateContainer";
import { DashboardImportExport } from "./components/DashboardImportExport";

export const DashboardRouters = () => (
  <>
    <Switch>
      <Route path={["/", "/dashboard/view/:id"]} exact component={Dashboard} />
      <Route path="/dashboard/create" exact component={DashboardCreate} />
      <Route path="/dashboard/edit/:id" exact component={DashboardEdit} />
      <Route path="/dashboard" exact component={DashboardList} />
      <Route
        path="/dashboards/importexport"
        exact
        component={DashboardImportExport}
      /></Switch>
  </>
);
