import { FileInput } from "components";
import { Flex, Text } from "primitives";

import React from "react";
export const FileWidget = (props: any) => {
  const sslEnabled =
    props.formContext.configuration &&
    props.formContext.configuration.sslEnabled
      ? props.formContext.configuration.sslEnabled
      : null;
  const fieldName = props.id && props.id.replace("root_configuration_", "");
  const sslFields =
    sslEnabled &&
    props.formContext.configuration &&
    props.formContext.configuration.ssl &&
    props.formContext.configuration.ssl;
  return (
    <Flex flexDirection="column" mt={1}>
      <FileInput
        type="file"
        id="cert"
        width="100%"
        required={props.required}
        onChange={(event) =>
          processJsonFile(event.target.files).then(props.onChange)
        }
      />
      {!props.value && sslEnabled && sslFields && sslFields[fieldName] === "" && (
        <Text mt={1} fontSize={5}>
          {`${props.label} already exists (Choose a new file to override)`}
        </Text>
      )}
    </Flex>
  );
};

function processJsonFile(files: any) {
  const file = files[0];
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = (err: any) => {
      return reject(err);
    };
    reader.onload = (event: any) => {
      if (!event) return;
      return resolve(event.target && event.target.result);
    };
    file && reader.readAsText(file, "UTF-8");
  });
}

export function formatConfigurationObject(configuration: any) {
  const commonFields = {
    IP: configuration.IP,
    port: configuration.port,
    uplinkTopic: configuration.uplinkTopic,
    downlinkTopic: configuration.downlinkTopic,
    username: configuration.username,
    password: configuration.password,
    topic: configuration.topic,
    sslEnabled: configuration.sslEnabled
  };
  return {
    ...commonFields,
    ssl: {
      certAuthority: configuration.certAuthority
        ? configuration.certAuthority
        : configuration.ssl && configuration.ssl.certAuthority === ""
        ? ""
        : null,
      clientCert: configuration.clientCert
        ? configuration.clientCert
        : configuration.ssl && configuration.ssl.clientCert === ""
        ? ""
        : null,
      clientKey: configuration.clientKey
        ? configuration.clientKey
        : configuration.ssl && configuration.ssl.clientKey === ""
        ? ""
        : null
    }
  };
}
