import * as schemaModels from "app/telecommand/models";
import { AutoCompleteSelect } from "app/shared/";
/*

id -> id
name -> name
argType -> argType

values
key -> enum
value -> enumNames

*/

class EnumParser {
  /**
   *
   * @param {array} values
   * @returns {object}
   */
  getEnum(values) {
    const enumKeys = [];
    const enumNames = [];

    values.forEach((i) => {
      enumKeys.push(i.key);
      enumNames.push(`${i.value} (${i.key})`);
    });

    return { enum: enumKeys, enumNames };
  }

  /**
   *
   * @param {object} arg
   * @returns {object} schema enum
   */
  parse(arg) {
    const { values, id, name, default: defaultValue, ...rest } = arg;

    return {
      ...rest,
      id,
      name: id,
      title: name,
      default: `${defaultValue}`,
      ...this.getEnum(values)
    };
  }

  /**
   *
   * @param {object} arg
   * @returns {object} schema
   *
   */
  parseUI(arg, hideDefaults) {
    const hiddenTypes = [schemaModels.AUTO_KIND, schemaModels.CONST_KIND];
    if (hiddenTypes.includes(arg.kind)) {
      return { "ui:widget": "hidden" };
    }

    if (arg.default && hideDefaults) {
      return { "ui:widget": "hidden" };
    }

    return {
      "ui:widget": AutoCompleteSelect
    };
  }
}

export const enumParser = new EnumParser();
