import React, { Component } from "react";
import ReactJson from "react-json-view";
import { Flex, Box, Button, Icon, Text } from "primitives";
import { Toggler, ExportButton, Filter } from "components";
import { AuroraTelemetryFrame, TelemetryFrame } from "app/telemetry/models";
import { TabInput, SelectBox } from "app/shared";
import { exportToJson } from "utils/exportAsJson";
import { Header } from "app/dashboard/components/Header";
import { FramePayloadBodyGrid } from "app/telemetry/components/FramePayloadBodyGrid";
import { FramePayloadAuxiliariesGrid } from "app/telemetry/components/FramePayloadAuxiliariesGrid";

interface FramesListProps {
  list: TelemetryFrame[];
  onChange: Function;
  onTimeSelectorChange: Function;
  from: number;
}
interface SatFramesListTableHeaderProps {
  onChange: Function;
  list: any;
}

interface TimeSelectorProps {
  from: number;
  onChange: Function;
}

const timeFilterOptions = {
  enumOptions: [
    { value: 1, label: "1 Hour" },
    { value: 24, label: "24 Hours" },
    { value: 168, label: "7 Days" },
    { value: 336, label: "14 Days" }
  ]
};

const SatFramesListTableHeader = (props: SatFramesListTableHeaderProps) => {
  const exportedSelectedFields = (tmList: TelemetryFrame[]) => {
    return tmList.map((tm: any) => {
      return {
        timestamp: tm.timestamp,
        satelliteId: tm.satelliteId,
        frameName: tm.frameName,
        payload: tm.payload
      };
    });
  };
  return (
    <Box
      p={2}
      bg="fill.0"
      fontSize={2}
      color="text.default"
      data-testid="SatelliteFramesListTable"
      width="85%"
    >
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <Flex minWidth="155px" flexDirection="column">
          Receive Timestamp{" "}
          <Filter field="timestamp" onChange={props.onChange}></Filter>
        </Flex>
        <Flex minWidth="55px" width="55px" flexDirection="column">
          Satellite Id{" "}
          <Filter field="satelliteId" onChange={props.onChange}></Filter>
        </Flex>
        <Flex width="200px" flexDirection="column">
          Frame name{" "}
          <Filter field="frameName" onChange={props.onChange}></Filter>
        </Flex>
        <Flex width="55px" alignSelf="flex-start">
          <ExportButton
            onClick={() =>
              exportToJson(exportedSelectedFields(props.list), "telemetry_list")
            }
          />
        </Flex>
      </Flex>
    </Box>
  );
};

const TimeSelector = (props: TimeSelectorProps) => {
  const { from, onChange } = props;
  return (
    <Flex justifyContent="flex-end" fontSize={2} ml={1} mt="2px">
      <Text mt="6px" mr={1}>
        Last
      </Text>
      <Box width={81}>
        <SelectBox
          id="time-filter-select"
          options={timeFilterOptions}
          onChange={(newFrom: number) => onChange(newFrom)}
          value={from}
          fontSize="11px"
          height={26}
          width="73px"
          iconPt="1px"
        />
      </Box>
    </Flex>
  );
};

export class SatelliteFramesListTable extends Component<FramesListProps> {
  render() {
    const { list, onChange, onTimeSelectorChange, from } = this.props;
    return (
      <>
        <Header my={20} ml={2} width={18}>
          <Flex
            width="100%"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <SatFramesListTableHeader list={list} onChange={onChange} />
            <TimeSelector from={from} onChange={onTimeSelectorChange} />
          </Flex>
        </Header>
        {list && list.map((item, index) => this.renderToggler(item, index))}
      </>
    );
  }

  private renderRawData(frame: TelemetryFrame) {
    return (
      <Box py={2}>
        <ReactJson
          src={
            frame.payload && (frame.payload as any).length === 1
              ? (frame as any).payload[0]
              : frame.payload
          }
          collapsed={1}
          theme="monokai"
          name="payload"
          style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 5 }}
        />
      </Box>
    );
  }

  private renderToggler(item: TelemetryFrame, index: number) {
    const frame = AuroraTelemetryFrame.of(item);
    const showPrettifiedData =
      frame && frame.header && Object.keys(frame.header).length !== 0;
    const tabs = ["RAW DATA"];
    showPrettifiedData && tabs.unshift("PAYLOAD DATA");
    return (
      <Toggler key={index}>
        {([toggled, onToggle]) => (
          <Box
            py={2}
            bg="fill.1"
            fontSize={2}
            data-testid="SatelliteFramesRowContent"
            width="100%"
          >
            <Flex mb={1} justifyContent="space-between">
              <Flex width="31px" minWidth="31px"></Flex>
              <Flex justifyContent="space-between" width="100%">
                <Box
                  p={2}
                  fontSize={2}
                  color="text.default"
                  data-testid="SatelliteFramesListTable"
                  width="85%"
                >
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Flex minWidth="155px">{item.timestamp}</Flex>
                    <Flex minWidth="55px" width="55px">
                      {item.satelliteId}
                    </Flex>
                    <Flex width="200px">{item.frameName}</Flex>
                    <Flex width="55px" justifyContent="center">
                      <Button size="small" onClick={onToggle}>
                        {toggled ? (
                          <Icon name="ArrowUp" size="14" />
                        ) : (
                          <Icon name="ArrowDown" size="14" />
                        )}
                      </Button>
                    </Flex>
                  </Flex>
                </Box>
                <Flex width="104px" minWidth="104px"></Flex>
              </Flex>
            </Flex>

            {toggled && (
              <Box px={2} py={3} bg="fill.2">
                <TabInput
                  tabs={tabs}
                  containers={(value: number) => (
                    <>
                      {value === tabs.indexOf("PAYLOAD DATA") && (
                        <>
                          <FramePayloadAuxiliariesGrid tmFrame={frame} />
                          <FramePayloadBodyGrid tmFrame={frame} />
                        </>
                      )}
                      {value === tabs.indexOf("RAW DATA") &&
                        this.renderRawData(frame)}
                    </>
                  )}
                />
              </Box>
            )}
          </Box>
        )}
      </Toggler>
    );
  }
}
