import {
  FETCH_CONSTELLATIONS_START,
  FETCH_CONSTELLATIONS_SUCCESS,
  FETCH_CONSTELLATIONS_ERROR
} from "../models/constants";

const initialState = [];

/**
[
  {
    "id": int,
    "name": string,
    "satelliteInstances": [
      {
        "id": int,
        "label": string,
        "satelliteDefinitionSummary": {
          "satelliteDefinitionId": int,
          "name": string,
          "classes": Array<string>
        }
      }
    ]
  },
  {...}
]
*/

export const constellationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONSTELLATIONS_START:
    case FETCH_CONSTELLATIONS_ERROR:
      return initialState;
    case FETCH_CONSTELLATIONS_SUCCESS:
      return Array.isArray(action.payload) ? [...action.payload] : [];
    default:
      return state;
  }
};
