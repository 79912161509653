import React, { Component, ChangeEvent } from "react";
import { Text, Icon, Box, Flex } from "primitives";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Popover,
  InputField,
  TableSort,
  DragHandle
} from "components";
import { DashboardComponents } from "app/dashboard/models";
import { getAlertColor, getAlertColorForArrayValue } from "app/alert/helpers";
import { DataSourceReading } from "app/telemetry/models/datasources";
import { sort } from "utils/arrays";
import { AlertDTO, Colors } from "app/alert/models";
import {
  configOptionsGetTimeReferenceLabel,
  configOptionsGetTimeReferenceValue
} from "app/telemetry/utils";
import styled from "styled-components";
import { TelemetryBaseComponent } from "../TelemetryBaseComponent/TelemetryBaseComponent";

interface DataTableSimpleProps {
  options: any;
  id: string;
  type: string;
  label: string;
  alerts?: AlertDTO[] | null;
  updateConfigOptions: (configOptions: any) => void;
  showModal: (componentType: String, id: String) => void;
}

interface DataTableSimpleState {
  isHovering: boolean;
  searchText: string;
  sortBy: string;
  isSortAscending: boolean;
}

const TableHeader = styled(TableCell)``;
TableHeader.defaultProps = {
  position: "sticky",
  top: "2px",
  border: 4,
  borderColor: "fill.3",
  bg: "palette.blue.3"
};

export class DataTableSimple extends Component<
  DataTableSimpleProps,
  DataTableSimpleState
> {
  public static defaultProps: Partial<DataTableSimpleProps>;
  state = {
    isHovering: false,
    searchText: "",
    sortBy: "",
    isSortAscending: true
  };

  buildHumanReadableValue(readings: any, dataSourceId: number) {
    if (readings && readings.length) {
      if (readings[0].valueHumanReadable) {
        return <Text>{readings[0].valueHumanReadable}</Text>;
      } else if (readings[0].repr) {
        return <Text>{readings[0].repr}</Text>;
      } else {
        if (Array.isArray(readings[0].value)) {
          const { options } = this.props;
          const colors = getAlertColorForArrayValue(readings[0]);

          //Filter out non selected indexes and reorder the selected ones
          let inputValue: any[] = [];
          let reorderedColors: any = [];
          if (options && options.dataSources) {
            const dataSourceOptions = options.dataSources.find(
              (ds: any) => ds.id === dataSourceId
            );
            if (dataSourceOptions && dataSourceOptions.indexes) {
              dataSourceOptions.indexes.forEach((index: any) => {
                const value = readings[0].value[index.index];
                inputValue.push(value);
                const color = colors ? colors[index.index] : null;
                reorderedColors.push(color);
              });
            } else {
              inputValue = readings[0].value;
              reorderedColors = colors;
            }
          } else {
            inputValue = readings[0].value;
            reorderedColors = colors;
          }

          return inputValue.map((value: any, index: number, array: any[]) => {
            return (
              <>
                <Text
                  color={
                    reorderedColors &&
                    (!options || (options && options.showAlerts !== false))
                      ? reorderedColors[index]
                      : Colors.Default
                  }
                >{`${value.value}`}</Text>
                {index !== inputValue.length - 1 ? (
                  <Text color={Colors.Default}>, </Text>
                ) : null}
              </>
            );
          });
        } else {
          return <Text>{readings[0].value}</Text>;
        }
      }
    }
    return "";
  }

  onChangeSearch(
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) {
    const { value } = event.currentTarget;
    this.setState({ searchText: value });
  }

  datasourceHasActiveAlerts(dataSourceId: number) {
    const { alerts } = this.props;
    return (
      alerts &&
      alerts.find((alert: AlertDTO) => alert.dataSourceId === dataSourceId)
    );
  }

  setSort(newSortBy: string) {
    let { sortBy, isSortAscending } = this.state;

    if (!isSortAscending && newSortBy === sortBy) {
      sortBy = "";
      isSortAscending = true;
    } else if (isSortAscending && newSortBy !== sortBy) {
      sortBy = newSortBy;
    } else {
      sortBy = newSortBy;
      isSortAscending = false;
    }

    this.setState({ sortBy, isSortAscending });
  }

  showHeader = () => {
    const { options } = this.props;
    const { isHovering } = this.state;
    return (
      !options ||
      !options.header ||
      !options.header.visibilityMode ||
      options.header.visibilityMode === "alwaysVisible" ||
      (options.header.visibilityMode === "onHoverVisible" && isHovering)
    );
  };

  renderTable(data: DataSourceReading[]) {
    const { sortBy, isSortAscending } = this.state;

    if (!data) return;
    const { alerts, options } = this.props;
    const { isHovering, searchText } = this.state;
    const datasourceLabels = options && options.datasourceLabels;
    const showHeader = this.showHeader();
    const timeReference =
      options && options.timeReference && options.timeReference.reference
        ? options.timeReference.reference
        : null;

    return (
      <Table
        onMouseOver={() => !isHovering && this.setState({ isHovering: true })}
        onMouseOut={() => isHovering && this.setState({ isHovering: false })}
      >
        <TableHead hideHeader={!showHeader}>
          <TableRow color="text.white">
            {alerts &&
              alerts.length > 0 &&
              (!options || options.showAlerts !== false) && <TableHeader />}
            {!options ||
            !options.header ||
            options.header.show.indexOf("name") !== -1 ? (
              <TableHeader>
                <TableSort
                  id="dataSource.name"
                  name="Name"
                  isSortAscending={isSortAscending}
                  sortBy={sortBy}
                  setSort={(id: string) => this.setSort(id)}
                />
              </TableHeader>
            ) : null}
            {!options ||
            !options.header ||
            options.header.show.indexOf("value") !== -1 ? (
              <TableHeader>Value</TableHeader>
            ) : null}
            {!options ||
            !options.header ||
            options.header.show.indexOf("unit") !== -1 ? (
              <TableHeader>
                <TableSort
                  id="dataSource.units.unit"
                  name="Unit"
                  isSortAscending={isSortAscending}
                  sortBy={sortBy}
                  setSort={(id: string) => this.setSort(id)}
                />
              </TableHeader>
            ) : null}
            {!options ||
            !options.header ||
            options.header.show.indexOf("timeReference") !== -1 ? (
              <TableHeader>
                <TableSort
                  id={`${timeReference}`}
                  name={configOptionsGetTimeReferenceLabel(options)}
                  isSortAscending={isSortAscending}
                  sortBy={sortBy}
                  setSort={(id: string) => this.setSort(id)}
                />
              </TableHeader>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {sort(data, sortBy, isSortAscending).map(
            ({ dataSource, readings }: DataSourceReading, index: number) => {
              if (
                searchText &&
                !(dataSource.name as string)
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
              )
                return null;
              const alert = this.datasourceHasActiveAlerts(dataSource.id);
              return (
                <TableRow
                  key={index}
                  color={
                    !options || options.showAlerts !== false
                      ? getAlertColor(readings[0])
                      : Colors.Default
                  }
                >
                  {alerts &&
                    alerts.length > 0 &&
                    (!options || options.showAlerts !== false) && (
                      <TableCell>
                        {alert && (
                          <Popover
                            placement="right"
                            over
                            overlay={
                              <Box ml={2} p={2} bg="fill.2" color="text.white">
                                DataSource has active alerts.
                              </Box>
                            }
                          >
                            <Box>
                              <Icon
                                name="Alert"
                                color={getAlertColor(alert)}
                                size={15}
                              />
                            </Box>
                          </Popover>
                        )}
                      </TableCell>
                    )}
                  {!options ||
                  !options.header ||
                  options.header.show.indexOf("name") !== -1 ? (
                    <TableCell>
                      {datasourceLabels && datasourceLabels[dataSource.id]
                        ? datasourceLabels[dataSource.id]
                        : dataSource.name}
                    </TableCell>
                  ) : null}
                  {!options ||
                  !options.header ||
                  options.header.show.indexOf("value") !== -1 ? (
                    <TableCell>
                      {this.buildHumanReadableValue(readings, dataSource.id)}
                    </TableCell>
                  ) : null}
                  {!options ||
                  !options.header ||
                  options.header.show.indexOf("unit") !== -1 ? (
                    <TableCell>
                      {dataSource.units && dataSource.units.unit}
                    </TableCell>
                  ) : null}
                  {!options ||
                  !options.header ||
                  options.header.show.indexOf("timeReference") !== -1 ? (
                    <TableCell>
                      {readings.length > 0 &&
                        configOptionsGetTimeReferenceValue(
                          options,
                          readings[0]
                        )}
                    </TableCell>
                  ) : null}
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    );
  }

  render() {
    const { options, type, showModal, id } = this.props;
    const { searchText } = this.state;

    return (
      <>
        {options && (
          <Flex
            data-testid="real-time-table"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            overflow="visible"
          >
            {options.label && (
              <Text bold fontSize={18}>
                {options.label}
              </Text>
            )}
            <Flex alignItems="flex-end" overflow="visible" ml="auto">
              {options.showSearch !== false && (
                <Box>
                  <InputField
                    id="search-input"
                    label="search"
                    autoFocus={true}
                    value={searchText}
                    onChange={(
                      e:
                        | ChangeEvent<HTMLInputElement>
                        | ChangeEvent<HTMLTextAreaElement>
                    ) => this.onChangeSearch(e)}
                  />
                </Box>
              )}
              <Flex flexDirection="column" alignItems="center" width={45}>
                <DragHandle ml={0} mr={0} my={1} />
                <Box
                  cursor="pointer"
                  py={2}
                  onClick={() => showModal(type, id)}
                >
                  <Icon name={"SettingsMenu"} size={20} id="settings-gear" />
                </Box>
              </Flex>
            </Flex>
          </Flex>
        )}
        <TelemetryBaseComponent {...this.props}>
          {({ data }: { data: DataSourceReading[] }) => this.renderTable(data)}
        </TelemetryBaseComponent>
      </>
    );
  }
}

DataTableSimple.defaultProps = {
  ...DashboardComponents.DataTable
};
