import React from "react";
import { Box } from "primitives";

export const ObjectFieldTemplate = (props) => {
  return (
    <Box overflow="visible">
      {props.properties.map((element) => (
        <Box px={3} key={element.name}>
          {element.content}
        </Box>
      ))}
    </Box>
  );
};
