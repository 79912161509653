import { SHOW_DIALOG, HIDE_DIALOG } from "../models/constants";

const initialState = {
  show: false,
  reference: ""
};

export const dialogReducer = (state = initialState, action) => {
  const { type, reference } = action;
  switch (type) {
    case SHOW_DIALOG: {
      return {
        reference,
        show: true
      };
    }
    case HIDE_DIALOG: {
      return {
        reference: "",
        show: false
      };
    }
    default: {
      return state;
    }
  }
};
