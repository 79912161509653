import { TelecommandStack as ITelecommandStack } from "app/telecommandStack/models";

export function setNonDefaultParams(store: any, telecommand: any) {
  const spec = store
    .getState()
    .constellations.dashboard.find((i: any) => i.visible)
    .satelliteDefinition.availableTelecommands.find(
      (i: any) => i.id === telecommand.telecommand.telecommandUri
    );
  if (spec) {
    return getNonDefaultParams(spec);
  }
  return null;
}

export function getNonDefaultParams(tcSpec: any) {
  const nonDefaults: any = [];
  function findNonDefaults(arr: any) {
    arr.forEach((arg: any) => {
      if (arg["default"] === undefined && !Array.isArray(arg["groupSpec"])) {
        nonDefaults.push(arg.id);
      } else if (Array.isArray(arg["groupSpec"])) {
        findNonDefaults(arg["groupSpec"]);
      }
    });
  }
  findNonDefaults([...tcSpec.args]);
  return nonDefaults;
}

export function isComment(telecommand: any) {
  if (telecommand && telecommand.comment) {
    return true;
  }
  return false;
}

export function isTelecommand(telecommand: any) {
  if (telecommand && telecommand.telecommand) {
    return true;
  }
  return false;
}

export function filterTCStacksBySatID(
  satID: number,
  telecommandStacks: ITelecommandStack[]
) {
  if (!satID || !Array.isArray(telecommandStacks)) return [];
  return telecommandStacks
    .filter((telecommandStack: any) => telecommandStack.satelliteId === satID)
    .map((telecommandStack: ITelecommandStack) => {
      return {
        label: telecommandStack.name,
        value: telecommandStack.id
      };
    });
}
