/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { PaginationSimple } from "app/shared";
import { OaPaginationProps, FetchAllOperationActivityParams } from "../models";

enum ChangeType {
  next = "next",
  prev = "prev"
}

const CURRENT_PAGE = 1;
const PAGE_SIZE = 10;

export const OperationActivityPagination = ({
  pagination,
  getAllOperationActivities
}: OaPaginationProps) => {
  const [params, setParams] = useState<FetchAllOperationActivityParams>();
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (params) paginate(params);
  }, [params]);

  const handleOnChange = (newPage: string | number) => {
    const nextPagesFinished =
      newPage === ChangeType.next &&
      pagination.currentPage === pagination.totalPages;
    const prevPagesFinished =
      newPage === ChangeType.prev && pagination.currentPage === 1;

    // Prevent pagination
    if (nextPagesFinished || prevPagesFinished) return;

    const currentPage = pagination?.currentPage || CURRENT_PAGE;
    const page =
      newPage === ChangeType.next ? currentPage + 1 : currentPage - 1;
    setParams({ ...params, page });
  };

  const handlePageSizeChange = (page_size: number) =>
    setParams({ ...params, page_size });

  const paginate = async (params: FetchAllOperationActivityParams) => {
    await setDisabled(true);
    await getAllOperationActivities(params);
    await setDisabled(false);
  };

  return (
    <PaginationSimple
      data-testid={"operation-activity-pagination"}
      disabled={disabled}
      total={pagination?.total || 0}
      page={pagination?.currentPage || CURRENT_PAGE}
      pageSize={pagination?.pageSize || PAGE_SIZE}
      nextPageUrl={ChangeType.next}
      previousPageUrl={ChangeType.prev}
      onChange={handleOnChange}
      onPageSizeChange={handlePageSizeChange}
    />
  );
};
