import React, { useContext } from "react";
import { Flex, Dialog, DialogTitle, DialogActions } from "primitives";
import { CancelButton, DeleteButton } from "components";
import { PlanningSystemContext } from "app/planningSystem/context";
import {
  ContextActionsNames,
  OperationDialogActionName,
  Oa,
  OperationActivityDialogProps
} from "app/planningSystem/models";
import { DialogContentDelete } from "./DialogContentDelete";
import { DialogContentInfo } from "./DialogContentInfo";
import { DialogContentHandle } from "app/planningSystem/containers";

export const OperationActivityDialog = ({
  deleteOperationActivity
}: OperationActivityDialogProps) => {
  const {
    state: { operationActivityDialog },
    dispatch
  } = useContext(PlanningSystemContext);
  const { isOpen, actionName, oa } = operationActivityDialog || {
    isOpen: false
  };

  const handleClose = () =>
    dispatch({
      type: ContextActionsNames.operationActivityDialog,
      payload: null
    });

  const handleOaDelete = async (operation: Oa | undefined) => {
    if (operation) await deleteOperationActivity(operation.uuid);

    // show notification in the action delete
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      data-testid={`operation-activity-dialog`}
    >
      <DialogTitle>
        {actionName &&
          `${
            actionName?.charAt(0).toUpperCase() + actionName?.slice(1)
          } Operation Activity`}
      </DialogTitle>

      {((actionName === OperationDialogActionName.create ||
        (oa && actionName === OperationDialogActionName.edit)) && (
        <DialogContentHandle
          handleClose={handleClose}
          operation={oa}
          handleType={actionName}
        />
      )) ||
        (oa && actionName === OperationDialogActionName.delete && (
          <DialogContentDelete operation={oa} />
        )) ||
        (oa && actionName === OperationDialogActionName.info && (
          <DialogContentInfo operation={oa} />
        ))}
      {actionName === OperationDialogActionName.info ||
        (actionName === OperationDialogActionName.delete && (
          <DialogActions>
            <Flex alignItems="center" mx={1}>
              <CancelButton onClick={handleClose}>Cancel</CancelButton>
              {actionName === OperationDialogActionName.delete && (
                <DeleteButton ml={1} onClick={() => handleOaDelete(oa)}>
                  Delete
                </DeleteButton>
              )}
            </Flex>
          </DialogActions>
        ))}
    </Dialog>
  );
};
