import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgArrowDown = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 8.956 4.872"
    color={color}
  >
    <g>
      <g>
        <path d="M4.868 4.872h-.816L0 0h.815L4.46 3.984 8.14 0h.816z" />
      </g>
    </g>
  </Svg>
);

SvgArrowDown.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgArrowDown;
