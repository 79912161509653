import React, { useCallback, useState } from "react";
import { Box } from "primitives";
import { Table, TableHead, TableBody, TableRow, TableCell } from "components";
import { Link } from "react-router-dom";
import { PaginationSimple } from "app/shared";
import { EditButton } from "components";
import { SuspenseQuery, Suspense, LoadingError } from "app/network";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import orderBy from "lodash/orderBy";
import { AlertDefinitionHeader } from "./AlertDefinitionHeader";
import { AlertDefinitionDeleteContainer } from "../containers/AlertDefinitionDeleteContainer";
import { AlertConditions, AlertDetails, AlertType } from "./AlertCondition";
import { NotificationPolicies } from "./AlertNotification";
import { AlertStatus } from "./AlertStatus";
import {
  AlertDefinitionDTO,
  Options,
  AlertResponseWithPagination
} from "../models";
import { getDatasourcesSelector } from "../helpers";
import { getAlertDefinitions } from "../services";

interface AlertDefinitionListProps {
  fetchAlertLists: (
    satDefId: number,
    { page, pageSize }: Options
  ) => Promise<AlertDefinitionDTO[]>;
  satDefId?: any;
  datasources?: any;
}

export const AlertDefinitionList = (props: AlertDefinitionListProps) => {
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedSatelliteId, setSelectedSatelliteId] = useState<number | null>(
    null
  );
  const [total, setTotal] = useState(0);

  const filterAlertLists = (AlertLists: AlertResponseWithPagination) => {
    const result =
      AlertLists?.data && AlertLists?.total ? AlertLists?.data : [];

    if (AlertLists?.total) {
      setTotal(AlertLists.total);
    } else if (result.length !== total) {
      setTotal(result.length);
    }

    return result || [];
  };

  const selectSatellite = (value: number) => {
    setSelectedSatelliteId(value);
    setTotal(0);
    setPage(0);
  };

  const query = useCallback(
    () => props.fetchAlertLists(props.satDefId, { page, pageSize }),
    [page, pageSize, props.satDefId]
  );

  interface ErrorProps {
    reload: () => void;
    error: { status: number };
  }
  const Error = ({ reload, error }: ErrorProps) => {
    reload();
    return <LoadingError httpErrorStatus={error.status} />;
  };

  return (
    <>
      <AlertDefinitionHeader
        selectSatellite={selectSatellite}
        selectedSatelliteId={selectedSatelliteId}
        alertDefinitions={filterAlertLists}
      />
      <Suspense>
        <SuspenseQuery query={query} errorFallback={true}>
          {({ response, reload, error }) => {
            const AlertLists = filterAlertLists(response ? response : []);
            return error ? (
              <Error reload={reload} error={error} />
            ) : (
              <Box
                data-testid="alert-definition-list"
                className="alert-definition-list"
                mx={3}
              >
                <Table>
                  <TableHead>
                    <TableRow bg="fill.0">
                      <TableCell width="10">Datasource</TableCell>
                      <TableCell width="80">Type</TableCell>
                      <TableCell width="auto">Alert Conditions</TableCell>
                      <TableCell width="80">Notification Policy</TableCell>
                      <TableCell width="30">Updated On</TableCell>
                      <TableCell width="20">Active</TableCell>
                      <TableCell width="50" />
                      <TableCell width="50" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderBy(
                      AlertLists,
                      [
                        (alertDef: AlertDefinitionDTO) =>
                          alertDef.alert?.metadata?.updatedOn || "",
                        (alertDef: AlertDefinitionDTO) =>
                          alertDef.alert?.metadata?.createdOn || ""
                      ],
                      ["desc", "desc"]
                    ).map((alertDef: AlertDefinitionDTO, idx: number) => {
                      return (
                        <TableRow key={alertDef.id}>
                          <TableCell width="2%">
                            <Link
                              to={{
                                pathname: `/alerts`,
                                state: { datasourceId: alertDef.dataSourceId }
                              }}
                            >
                              {props.datasources?.get(alertDef.dataSourceId) ??
                                alertDef.dataSourceId}
                            </Link>
                          </TableCell>
                          <TableCell width="1%">
                            <AlertType type={alertDef.alert?.alertType} />
                          </TableCell>
                          <TableCell width="auto">
                            {alertDef.alert.alertType === "DynamicAlert" ? (
                              <AlertConditions
                                alertConfigs={alertDef.alert?.alertConfigs}
                              />
                            ) : (
                              <AlertDetails
                                alertDetails={alertDef.alert as any}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <NotificationPolicies
                              alertConfigs={alertDef.alert?.alertConfigs}
                            />
                          </TableCell>
                          <TableCell>
                            {alertDef.alert?.metadata?.createdOn &&
                              new Date(
                                alertDef.alert.metadata.createdOn
                              ).toLocaleString()}
                          </TableCell>
                          <TableCell align="center">
                            <AlertStatus status={alertDef.active} />
                          </TableCell>
                          {/* <TableCell>
                            <Button size="small">
                              {alertDef.active ? "Disable" : "Enable"}
                            </Button>
                          </TableCell> */}
                          <TableCell>
                            <Link
                              to={`/alert-definitions/${alertDef.dataSourceId}`}
                            >
                              <EditButton
                                disabled={
                                  alertDef.alert?.alertType !== "DynamicAlert"
                                }
                              >
                                Edit
                              </EditButton>
                            </Link>
                          </TableCell>
                          <TableCell>
                            <AlertDefinitionDeleteContainer
                              dataSourceId={alertDef.dataSourceId}
                              onChange={() => reload()}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <PaginationSimple
                  total={total}
                  page={page + 1}
                  pageSize={pageSize}
                  nextPageUrl={(page + 1) * pageSize < total ? page + 1 : 0}
                  previousPageUrl={page - 1}
                  onChange={(newPage: string | number) => {
                    setPage(Number(newPage));
                    setPageSize(pageSize);
                  }}
                  onPageSizeChange={(newPageSize: number) => {
                    setPage(0);
                    setPageSize(Number(newPageSize));
                  }}
                />
              </Box>
            );
          }}
        </SuspenseQuery>
      </Suspense>
    </>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  fetchAlertLists: (satDefId: number, { page, pageSize }: Options) =>
    dispatch(getAlertDefinitions(satDefId, { page, pageSize }))
});

const mapStateToProps = (state: any) => ({
  satDefId: state.satellite.dashboard.satelliteDefinition.id,
  datasources: getDatasourcesSelector(state)
});

export const AlertListListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertDefinitionList);
