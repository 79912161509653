import React, { Component } from "react";
import { DropdownMenuLink } from "navigation/DropdownMenu";

/**
 * Dashboard component
 * Used in the sidebar to change
 * between dashboards.
 */
export class DashboardSelector extends Component {
  render() {
    const { dashboards } = this.props;

    return dashboards.map((dashboard) => {
      return (
        <DropdownMenuLink
          key={`${dashboard.id}`}
          to={`/dashboard/view/${dashboard.id}`}
          exact
          data-testid="DashboardSelector"
        >
          {dashboard.name}
        </DropdownMenuLink>
      );
    });
  }
}
