import React from "react";
import { Switch, Route } from "react-router-dom";
import { ScriptListContainer } from "./components/ScriptList";
import { ScriptCreateContainer } from "./components/ScriptCreate";
import { ScriptEditContainer } from "./components/ScriptEdit";

export const ScriptRouters = () => (
  <>
    <Switch>
        <Route path="/scripts" exact component={ScriptListContainer} />
        <Route path="/scripts/create" exact component={ScriptCreateContainer} />
        <Route path="/scripts/:id" component={ScriptEditContainer} />
    </Switch>
  </>
);
