import React, { useState } from "react";
import { Toggler } from "components";
import { Flex, Icon, Box } from "primitives";
import { FlexLayoutProps } from "primitives/Flex";
import { ItemMenu, IconMenu, TitleMenu } from "./ItemMenu";
import { Link } from "primitives";
import { LinkProps } from "primitives/Link";

interface DropDownMenuProps {
  title: string;
  icon?: string;
  children: React.ReactNode | React.ReactNode[];
}

interface DropdownMenuItemProps extends FlexLayoutProps {
  children?: React.ReactNode;
  active?: boolean;
}

export const DropdownMenuItem = ({
  children,
  active,
  ...props
}: DropdownMenuItemProps) => {
  return (
    <Flex alignItems="center" pb={3} fontSize={2} {...props}>
      <Box
        bg={active ? "text.default" : "text.label"}
        size={6}
        minWidth={6}
        border={3}
        borderColor="border.1"
        borderRadius="50%"
      />
      <Flex
        pl={2}
        flex="1 0 auto"
        justifyContent="space-between"
        alignItems="center"
      >
        {children}
      </Flex>
    </Flex>
  );
};

export const DropdownMenuLink = ({ children, ...props }: LinkProps) => {
  const [active, setActive] = useState(false);

  const isActive = (match: any, location: any) => {
    if (!match) {
      setActive(false);
      return false;
    }

    if (props.isActive) {
      const parentIsActive = props.isActive(match, location);
      setActive(parentIsActive);
      return parentIsActive;
    }

    setActive(true);
    return true;
  };

  return (
    <DropdownMenuItem active={active}>
      <Link {...props} isActive={isActive}>
        {children}
      </Link>
    </DropdownMenuItem>
  );
};

const DropDownMenu = ({ title, icon, children }: DropDownMenuProps) => (
  <Toggler>
    {([toggled, onToggle]: any) => (
      <>
        <ItemMenu onClick={onToggle} active={toggled} mb={toggled ? 0 : 2}>
          <IconMenu active={toggled}>
            <Icon name={icon ? icon : "SettingsMenu"} size={18} />
          </IconMenu>
          <TitleMenu>{title}</TitleMenu>
          <Flex>
            <Icon
              name={toggled ? "ArrowUp" : "ArrowDown"}
              width={9}
              height={5}
            />
          </Flex>
        </ItemMenu>
        {toggled && children}
      </>
    )}
  </Toggler>
);

export default DropDownMenu;
