import React, { useState, useEffect } from "react";
import { CheckBox } from "components";
import { Heading } from "primitives";
import styled from "styled-components";
import {
  DataListItem,
  SelectableListProps,
  InternalDataListItem
} from "./models";
import palette from "styles/palette";

const StyleProvider = styled("div")<{ isSelected: boolean }>`
  background-color: ${(props) => (props.isSelected ? "#1F1E2C" : "#3d5076")};
  margin-bottom: 10px;
  cursor: pointer;
  outline: ${(props) =>
    props.isSelected ? `1px solid ${palette.palette.blue[1]}` : "none"}
  &:hover {
    outline: 1px solid ${palette.palette.blue[1]};
  }
`;

export const SelectableList = ({
  /** A heading to be shown in the component */
  title,
  /** A list of data to render */
  dataList,
  /** A component used to render the list item */
  listItemTemplate: Template,
  /** The callback to call each time the selection changes */
  onChange = () => null,
  /** A string or an hash used to generate an internal id */
  scope,
  /** Whether should enable multi-selection or not */
  multiSelect = false
}: SelectableListProps) => {
  // Map the state of the checkbox and the full list selection
  const [selectAll, setSelectAll] = useState<"yes" | "no" | "indeterminate">(
    "no"
  );
  // The list of selected items
  const [selectedItems, setSelectedItems] = useState<InternalDataListItem[]>(
    []
  );
  // Used when click+shift to determinate a selection range
  const [lastSelected, setLastSelected] = useState<string | null>();
  // Remapped dataList to add an id used for the selection operation
  const [internalDataList, setInternalDatalist] = useState<
    InternalDataListItem[]
  >([]);

  useEffect(() => {
    /**
     * Reset selection if the selected items are not included in the internalDataList.
     */
    if (
      !selectedItems
        .map((itm) => itm.id)
        .every((item) => internalDataList.map((itm) => itm.id).includes(item))
    ) {
      setSelectedItems([]);
    }
  }, [internalDataList]);

  useEffect(() => {
    /**
     * Remapping the received dataList to add an id that will be used to identify
     * the items in the selection operations.
     */
    if (dataList) {
      setInternalDatalist(addInternalID());
    }
  }, [dataList]);

  useEffect(() => {
    /**
     * Select/deselect all the items
     */
    if (selectAll !== "indeterminate") {
      const selected = selectAll === "yes" ? internalDataList : [];
      if (selectedItems.length !== selected.length) {
        return setSelectedItems(selected);
      }
    }
  }, [selectAll]);

  useEffect(() => {
    handleIndeterminate();
    if (selectedItems.length === 0) {
      /**
       * When the selection is controlled from the `select all` checkbox.
       * the lastSelected state must be reset.
       */
      setLastSelected(null);
    }
    onChange(removeInternalID(selectedItems));
  }, [selectedItems]);

  /**
   * @description Return a remapped DataListItem[] with an id
   * used for the selection operations
   */
  const addInternalID = (): InternalDataListItem[] => {
    return dataList.map((item: DataListItem, i: number) => {
      return { ...item, id: `${scope}--${i}` };
    });
  };

  /**
   * @description return a list of DataListItem at the original state (without the id)
   */
  const removeInternalID = (
    subSet?: InternalDataListItem[]
  ): DataListItem[] => {
    const internalData = subSet || internalDataList;
    return internalData.map((item: InternalDataListItem, i: number) => {
      const { id, ...initialItem } = item;
      return initialItem;
    });
  };

  /**
   * Handle the user interaction on the selectAll checkbox.
   */
  const handleBulkSelectionChange = () => {
    const selection =
      selectAll === "indeterminate" || selectAll === "no" ? "yes" : "no";
    setSelectAll(selection);
  };

  /**
   * When the selected items change it handle the checkbox state
   */
  const handleIndeterminate = () => {
    if (selectedItems.length === 0) {
      setSelectAll("no");
    } else if (selectedItems.length === internalDataList.length) {
      setSelectAll("yes");
    } else {
      setSelectAll("indeterminate");
    }
  };

  /**
   * @description Handle the single selection adding/removing the item
   * from the `selectedItem` state.
   * @param itemId {string}
   */
  const handleSingleSelection = (itemId: string) => {
    const isSelected = selectedItems.map((itm) => itm.id).includes(itemId);

    const selected: InternalDataListItem[] = (isSelected &&
      selectedItems.filter(
        (itm: InternalDataListItem) => itm.id !== itemId
      )) || [
      ...internalDataList.filter((internalItem) => internalItem.id === itemId)
    ];

    setLastSelected(itemId);
    setSelectedItems(selected);
  };

  /**
   * @description Handle the multi selection (shift+click) creating a selection range
   * from the last item clicked and the current item clicked.
   * @param itemId {string}
   */
  const handleMultiSelection = (itemId: string) => {
    let inBetween = false;
    let selected: InternalDataListItem[] = [];

    if (selectedItems.map((itm) => itm.id).includes(itemId)) {
      // Click+shift on a selected item
      selected = selectedItems.filter(
        (itm: InternalDataListItem) => itm.id !== itemId
      );
    } else {
      // Click+shift on a item with a previous selection determinate a range of selected items
      internalDataList.forEach((item: InternalDataListItem) => {
        const isTheClickedItem = item.id === itemId;
        const isThePreviousClicked = item.id === lastSelected;
        if (isTheClickedItem || isThePreviousClicked) {
          inBetween = !inBetween;
          return (
            !selectedItems.map((itm) => itm.id).includes(item.id) &&
            selected.push(item)
          );
        }
        if (inBetween) {
          return (
            !selectedItems.map((itm) => itm.id).includes(item.id) &&
            selected.push(item)
          );
        }
      });
      selected = [...selectedItems, ...selected];
    }

    setLastSelected(itemId);
    setSelectedItems(selected);
  };

  return (
    <div data-testid={"selectable-list"}>
      {title && <Heading>{title}</Heading>}
      {internalDataList.length > 1 && multiSelect && (
        // The checkbox is shown if the list length is > 1
        <div style={{ margin: "20px 0 5px -5px" }} data-testid={"select-all"}>
          <CheckBox
            checked={selectedItems.length > 0}
            onChange={handleBulkSelectionChange}
            indeterminate={selectAll === "indeterminate"}
          />
        </div>
      )}

      <div>
        {internalDataList.map((item: InternalDataListItem, i: number) => {
          const { id, ...originalItem } = item;
          return (
            <StyleProvider
              data-testid={"selectable-list-item"}
              key={i}
              isSelected={selectedItems.map((itm) => itm.id).includes(item.id)}
              onClick={(e) => {
                if (multiSelect && e.shiftKey && lastSelected) {
                  return handleMultiSelection(item.id);
                }
                return handleSingleSelection(item.id);
              }}
            >
              <Template data={originalItem} />
            </StyleProvider>
          );
        })}
      </div>
    </div>
  );
};
