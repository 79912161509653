import React from "react";
import { DialogContent, Text as T } from "primitives";
import { DialogContentDeleteProps } from "app/planningSystem/models";
import styled from "styled-components";

const Text = styled(T)`
  position: relative;
  color: white;
`;

export const DialogContentDelete = ({ operation }: DialogContentDeleteProps) =>
  (operation && (
    <DialogContent>
      <Text>{`Delete ${operation.name}?`}</Text>
    </DialogContent>
  )) || <>The operation that you selected does not exists</>;
