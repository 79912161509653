import TelecommandFileInput from "app/telecommand/components/TelecommandFileInput";

class FileParser {
  /**
   *
   * @param {object} arg
   * @returns {object} schema literal
   */
  parse(arg) {
    const { range, dataType, default: defaultValue, ...rest } = arg;
    let defaultValueFormated = defaultValue;

    return {
      ...rest,
      name: rest.id || rest.name,
      title: rest.name,
      default: defaultValueFormated,
      type: "string"
    };
  }

  /**
   *
   * @param {object} arg
   * @returns {object} schema
   *
   */
  parseUI(arg, hideDefaults, satelliteId) {
    return {
      "ui:widget": TelecommandFileInput,
      "ui:options": {
        satelliteId: satelliteId
      }
    };
  }
}

export const fileParser = new FileParser();
