import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgAdminMenu = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 18 18"
    color={color}
  >
    <g id="surface1">
      <path d="M 9.679688 3.878906 C 9.65625 3.449219 9.21875 3.523438 8.789062 3.523438 C 9.105469 3.609375 9.40625 3.726562 9.679688 3.878906 Z M 9.679688 3.878906 " />
      <path d="M 5.101562 2.804688 C 3.441406 2.804688 2.675781 4.90625 4.003906 5.964844 C 4.457031 4.757812 5.359375 3.886719 6.539062 3.546875 C 6.21875 3.097656 5.695312 2.804688 5.101562 2.804688 Z M 5.101562 2.804688 " />
      <path d="M 1.667969 5.636719 C 1.734375 5.855469 1.824219 6.0625 1.929688 6.261719 L 1.382812 6.808594 C 1.234375 6.953125 1.234375 7.191406 1.382812 7.339844 L 2.34375 8.300781 C 2.414062 8.371094 2.511719 8.410156 2.609375 8.410156 C 2.710938 8.410156 2.804688 8.371094 2.875 8.300781 L 3.421875 7.753906 C 3.503906 7.796875 3.59375 7.824219 3.679688 7.863281 C 3.679688 7.855469 3.679688 7.847656 3.679688 7.84375 C 3.679688 7.398438 3.730469 6.976562 3.820312 6.578125 C 3.164062 6.15625 2.722656 5.421875 2.722656 4.582031 C 2.722656 3.269531 3.789062 2.199219 5.101562 2.199219 C 5.992188 2.199219 6.757812 2.695312 7.167969 3.417969 C 7.605469 3.363281 8.015625 3.363281 8.507812 3.457031 C 8.445312 3.265625 8.371094 3.078125 8.277344 2.898438 L 8.824219 2.351562 C 8.894531 2.28125 8.933594 2.1875 8.933594 2.085938 C 8.933594 1.988281 8.894531 1.890625 8.824219 1.820312 L 7.859375 0.859375 C 7.789062 0.785156 7.691406 0.75 7.59375 0.75 C 7.5 0.75 7.402344 0.785156 7.328125 0.859375 L 6.78125 1.40625 C 6.585938 1.300781 6.375 1.214844 6.160156 1.148438 L 6.160156 0.375 C 6.160156 0.167969 5.992188 0 5.785156 0 L 4.421875 0 C 4.214844 0 4.046875 0.167969 4.046875 0.375 L 4.046875 1.148438 C 3.828125 1.214844 3.621094 1.300781 3.421875 1.40625 L 2.875 0.859375 C 2.804688 0.785156 2.707031 0.75 2.609375 0.75 C 2.515625 0.75 2.417969 0.785156 2.34375 0.859375 L 1.382812 1.824219 C 1.3125 1.894531 1.273438 1.988281 1.273438 2.089844 C 1.273438 2.1875 1.3125 2.285156 1.382812 2.355469 L 1.929688 2.898438 C 1.824219 3.097656 1.734375 3.304688 1.667969 3.523438 L 0.898438 3.523438 C 0.691406 3.523438 0.523438 3.691406 0.523438 3.898438 L 0.523438 5.261719 C 0.523438 5.46875 0.691406 5.636719 0.898438 5.636719 Z M 1.667969 5.636719 " />
      <path d="M 13.460938 12.921875 C 14.0625 12.589844 14.476562 11.957031 14.476562 11.226562 C 14.476562 10.15625 13.605469 9.285156 12.535156 9.285156 C 12.15625 9.285156 11.808594 9.398438 11.511719 9.582031 C 11.332031 10.292969 11.050781 10.972656 10.683594 11.5625 C 11.726562 11.757812 12.683594 12.234375 13.460938 12.921875 Z M 13.460938 12.921875 " />
      <path d="M 17.476562 10.492188 C 17.476562 10.265625 17.296875 10.085938 17.070312 10.085938 L 16.238281 10.085938 C 16.167969 9.851562 16.070312 9.625 15.957031 9.410156 L 16.546875 8.824219 C 16.625 8.746094 16.667969 8.644531 16.667969 8.535156 C 16.667969 8.429688 16.625 8.324219 16.546875 8.25 L 15.511719 7.210938 C 15.429688 7.132812 15.328125 7.09375 15.222656 7.09375 C 15.121094 7.09375 15.015625 7.132812 14.9375 7.210938 L 14.347656 7.800781 C 14.132812 7.6875 13.910156 7.59375 13.671875 7.519531 L 13.671875 6.6875 C 13.671875 6.464844 13.492188 6.28125 13.269531 6.28125 L 11.800781 6.28125 C 11.703125 6.28125 11.617188 6.324219 11.542969 6.382812 C 11.667969 6.832031 11.734375 7.320312 11.734375 7.84375 C 11.734375 8.175781 11.710938 8.511719 11.660156 8.84375 C 11.933594 8.746094 12.226562 8.679688 12.535156 8.679688 C 13.9375 8.679688 15.078125 9.824219 15.078125 11.226562 C 15.078125 12.121094 14.609375 12.90625 13.90625 13.359375 C 14.417969 13.921875 14.824219 14.585938 15.089844 15.332031 C 15.132812 15.347656 15.179688 15.359375 15.222656 15.359375 C 15.328125 15.359375 15.429688 15.320312 15.511719 15.238281 L 16.550781 14.203125 C 16.625 14.125 16.667969 14.023438 16.667969 13.914062 C 16.667969 13.808594 16.625 13.703125 16.550781 13.628906 L 15.957031 13.039062 C 16.070312 12.824219 16.167969 12.601562 16.238281 12.363281 L 17.070312 12.363281 C 17.179688 12.363281 17.285156 12.324219 17.359375 12.246094 C 17.433594 12.171875 17.476562 12.066406 17.476562 11.960938 Z M 17.476562 10.492188 " />
      <path d="M 14.261719 15.699219 C 13.632812 13.847656 11.96875 12.570312 10.046875 12.398438 C 9.40625 13.078125 8.609375 13.511719 7.707031 13.511719 C 6.804688 13.511719 6.007812 13.078125 5.367188 12.398438 C 3.445312 12.570312 1.78125 13.847656 1.152344 15.699219 L 0.800781 16.722656 C 0.703125 17.019531 0.75 17.34375 0.933594 17.597656 C 1.113281 17.851562 1.40625 18 1.714844 18 L 13.695312 18 C 14.007812 18 14.300781 17.851562 14.480469 17.597656 C 14.664062 17.34375 14.710938 17.019531 14.609375 16.722656 Z M 14.261719 15.699219 " />
      <path d="M 4.582031 7.84375 C 4.582031 10.140625 5.984375 12.605469 7.707031 12.605469 C 9.433594 12.605469 10.832031 10.140625 10.832031 7.84375 C 10.832031 3.078125 4.582031 3.070312 4.582031 7.84375 Z M 4.582031 7.84375 " />
    </g>
  </Svg>
);

SvgAdminMenu.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgAdminMenu;
