import { useState, useEffect } from "react";

const useNode = (type: string) => {
  const [node, setNode] = useState(null);
  useEffect(() => {
    const element: any = document.createElement(type);
    document.body.appendChild(element);
    setNode(element);
    return () => document.body.removeChild(element);
  }, [type]);
  return node;
};

export default useNode;
