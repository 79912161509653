import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import {
  TYPOGRAPHY,
  TypographyProps,
  CommonProps,
  BorderProps
} from "styles/constants";
import { Box } from "primitives";
import {
  TestableProps,
  DATA_TEST_ID_ATTR_NAME
} from "components/testable/models";

interface HTMLInput extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  checked?: boolean | undefined;
}
interface InputProps
  extends TypographyProps,
    CommonProps,
    BorderProps,
    TestableProps {}

const Root = styled(Box)`
  ${TYPOGRAPHY}
  margin: 5px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: relative;
  overflow: visible !important;
  label {
    padding-left: 25px;
    width: max-content;
    display: block;
    cursor: pointer;
  }
  &::after {
    content: "";
    border-radius: 100%;
    border: ${themeGet("borders.3")} ${themeGet("colors.palette.brand.0")};
    background: transparent;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 0;
  }
`;

const Fill = styled(Box)`
  width: 0;
  height: 0;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition:
    width 0.2s ease-in,
    height 0.2s ease-in;
  pointer-events: none;
  z-index: 0;

  &::before {
    content: "";
    opacity: 0;
    width: calc(20px - 4px);
    position: absolute;
    height: calc(20px - 4px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: ${themeGet("borders.3")} ${themeGet("colors.palette.brand.0")};
    border-radius: 100%;
  }
`;

const Input = styled("input")`
  opacity: 0;
  z-index: 1;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:checked {
    & ~ ${Fill} {
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      transition:
        width 0.2s ease-out,
        height 0.2s ease-out;

      &::before {
        opacity: 1;
        transition: opacity 1s ease;
      }
    }
  }
`;

const Radio = (props: HTMLInput & InputProps) => {
  const { onChange, value, label, checked, name, ...styles } = props;
  return (
    <Root {...styles}>
      <label>
        {label}
        <Input
          type="radio"
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            onChange && onChange(event);
          }}
          name={name}
          value={value}
          checked={checked}
          aria-checked={checked}
        />
        <Fill bg="palette.brand.0" />
      </label>
    </Root>
  );
};

Radio.displayName = "Radio";

Radio.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: Radio.displayName
};

export default Radio;
