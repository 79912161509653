import { SelectBox, InputBase } from "app/shared";
import fromPairs from "lodash/fromPairs";

const pathSelectorConfigOptionsSchema = (permissions: any, formData: any) => {
  const { organization, fleets, satellites, subpaths } = getWritablePaths(
    permissions,
    formData
  );

  return {
    $schema: "http://json-schema.org/draft-07/schema#",
    $id: "table.schema.json",
    type: "object",
    definitions: {
      organization: {
        $id: "#/definitions/armsPathSelector",
        type: "string",
        // enum: [organization],
        default: organization
      },
      fleet: {
        $id: "#/definitions/armsPathSelector",
        type: "string",
        enum: fleets,
        default: fleets[0]
      },
      satellite: {
        $id: "#/definitions/armsPathSelector",
        type: "string",
        enum: satellites,
        default: satellites[0]
      },
      subpath: {
        $id: "#/definitions/armsPathSelector",
        type: "string",
        enum: subpaths
      },
      filename: {
        $id: "#/definitions/armsPathSelector",
        type: "string",
        default: ""
      }
    },
    properties: {
      organization: {
        $ref: "#/definitions/organization"
      },
      fleet: {
        $ref: "#/definitions/fleet"
      },
      satellite: {
        $ref: "#/definitions/satellite"
      },
      subpath: {
        $ref: "#/definitions/subpath"
      },
      filename: {
        $ref: "#/definitions/filename"
      }
    },
    required: ["organization", "fleet", "satellite"]
  };
};

const pathSelectorConfigOptionsDefault = {
  organization: "D-Orbit"
};

const pathSelectorConfigOptionsUISchema = {
  classNames: "arms-form",
  organization: { "ui:widget": InputBase, classNames: "arms-organization" },
  fleet: { "ui:widget": SelectBox },
  satellite: { "ui:widget": SelectBox },
  subpath: { "ui:widget": SelectBox },
  filename: { "ui:widget": InputBase }
};

function getWritablePaths(permissions: any, formData: any) {
  const organization = permissions.organization;
  const fleets = Object.keys(permissions.fleets);
  const satellites =
    formData && formData.fleet
      ? Object.keys(permissions.fleets[formData.fleet])
      : [];
  const subpaths =
    formData && formData.fleet && formData.satellite
      ? permissions.fleets[formData.fleet][formData.satellite]
      : [];
  return {
    organization,
    fleets,
    satellites,
    subpaths
  };
}

export function isAdmin(permissions: any) {
  return permissions && permissions.organization === "__su__";
}

export function objContainsEmptyProps(object: any, ignoreProp: string) {
  const filterdObj =
    object &&
    fromPairs(
      Object.entries(object).filter(([key]) => !key.includes(ignoreProp))
    );
  return (
    object &&
    Object.values(filterdObj).some(
      (i) => i === null || i === "" || i === undefined
    )
  );
}

export function isValidArmsPath(formData: any, path: string, ignore: string) {
  const validFormValues = !objContainsEmptyProps(formData, ignore);
  // const unFilledValue = path && path.includes("____");
  // eslint-disable-next-line no-useless-escape
  const regexPattern = /^\/[A-Za-z0-9\s\-_\/\.]*\.[a-zA-Z0-9]+$/;
  return regexPattern.test(path) && validFormValues;
}

export {
  pathSelectorConfigOptionsSchema,
  pathSelectorConfigOptionsDefault,
  pathSelectorConfigOptionsUISchema
};
