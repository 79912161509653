import React, { useContext, useCallback, useState } from "react";
import {
  Flex,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from "primitives";
import { SuspenseMutation } from "app/network";
import { CancelButton, UploadButton } from "components";
import { KeyManagerContext } from "../context";
import {
  InjectSessionKeyDialogProps,
  InjectionSettings,
  ContextActionsNames,
  SuccessResponse
} from "../models";
import { dialogTestIdPrefix } from "../utils/constants";
import { injectSessionKey } from "app/keyManager/services/sessionKey";
import { Form } from "app/shared";
import {
  keyManagerConfigOptionsSchema,
  keyManagerConfigOptionsUISchema
} from "../schemas";

const initialInjectionSettings: InjectionSettings = {
  channels: ""
};

export const InjectSessionKeyDialog = ({
  selectedSatellite,
  fetchSessionKey
}: InjectSessionKeyDialogProps) => {
  const [{ channels }, setInjectionSettings] = useState<InjectionSettings>(
    initialInjectionSettings
  );
  const {
    state: { injectSessionKeyDialog, selectedMasterKey }
  } = useContext(KeyManagerContext);
  const { key, isOpen, setOpen } = injectSessionKeyDialog || {
    isOpen: false
  };

  const submit = useCallback(
    async (e, form) => {
      e.preventDefault();
      injectAction(form.formData);
    },
    [key]
  );

  const injectAction = async (formData: InjectionSettings) => {
    try {
      if (key?.key_id) {
        const response = await injectSessionKey(selectedSatellite.id, {
          session_key_id: key?.key_id,
          settings: formData
        });
        if ((response as SuccessResponse).status === 204) {
          fetchSessionKey(
            selectedSatellite.id,
            selectedMasterKey?.key_id as string,
            key.key_id
          );
        }
      }
      return onClose();
    } catch (e) {
      console.warn(e);
    }
  };

  const handleOnChange = ({ formData }: any) => {
    setInjectionSettings(formData);
  };

  const onClose = () => {
    setInjectionSettings(initialInjectionSettings);
    setOpen &&
      setOpen({
        type: ContextActionsNames.injectSessionKeyDialog,
        payload: null
      });
  };

  const cantSubmit = !channels;
  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      data-testid={`${dialogTestIdPrefix}inject`}
    >
      <SuspenseMutation>
        {({ loading, action }) => {
          return (
            <>
              <DialogTitle>{`Use this key for encryption`}</DialogTitle>

              <DialogContent>
                <Form
                  formData={{ channels }}
                  id={"injection-configuration"}
                  schema={keyManagerConfigOptionsSchema}
                  uiSchema={keyManagerConfigOptionsUISchema}
                  onSubmit={(
                    form: any,
                    e: React.FormEvent<HTMLInputElement>
                  ) => {
                    return submit(e, form);
                  }}
                  disabled={loading}
                  onChange={handleOnChange}
                >
                  <></>
                </Form>
              </DialogContent>

              <DialogActions>
                <Flex alignItems="center" mx={1}>
                  <UploadButton
                    data-testid={"inject-button"}
                    disabled={loading || cantSubmit}
                    form={"injection-configuration"}
                    type={"submit"}
                  >
                    Inject
                  </UploadButton>
                  <CancelButton ml={1} onClick={() => onClose()}>
                    Cancel
                  </CancelButton>
                </Flex>
              </DialogActions>
            </>
          );
        }}
      </SuspenseMutation>
    </Dialog>
  );
};
