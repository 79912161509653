import { scriptDataProvider } from "app/network/dataProvider";
import { OperationType } from "app/network/models/constants";
import {
  Procedure,
  EditProcedureCommand,
  CreateProcedureCommand
} from "../models";

export const fetchProcedures = async (): Promise<Procedure[]> => {
  const response = (await scriptDataProvider(
    OperationType.GET_LIST,
    `procedure`
  )) as any;
  return response.data;
};

export const fetchProcedure = async (id: number): Promise<Procedure> => {
  const response = (await scriptDataProvider(
    OperationType.GET_ONE,
    `procedure`,
    {
      id
    }
  )) as any;
  return response.data;
};

export const createProcedure = async (
  data: CreateProcedureCommand
): Promise<Procedure> => {
  const response = (await scriptDataProvider(
    OperationType.CREATE,
    `procedure`,
    {
      data: {
        name: data.name,
        steps: data.steps
      }
    }
  )) as any;
  return response.data;
};

export const editProcedure = async (
  id: number,
  data: EditProcedureCommand
): Promise<Procedure> => {
  const response = (await scriptDataProvider(
    OperationType.UPDATE,
    `procedure`,
    {
      id,
      data: {
        name: data.name,
        steps: data.steps
      }
    }
  )) as any;
  return response.data;
};

export const deleteProcedure = async (id: number): Promise<any> => {
  await scriptDataProvider(OperationType.DELETE, `procedure`, {
    id
  });
};
