import React from "react";
import { SuspenseMutation } from "app/network";
import { Toggler } from "components";
import { DeleteButton, CancelButton } from "components";
import { connect } from "react-redux";
import { deleteTelecommandStacks } from "app/telecommandStack/services";
import { Flex, Dialog, DialogTitle, DialogActions } from "primitives";

interface TelecommandStackDeleteProps {
  //add types
  telecommandStack: any;
  onChange: () => void;
  deleteTelecommandStack: (id: string) => Promise<any>;
}

export const TelecommandStackDelete = ({
  telecommandStack,
  onChange,
  ...props
}: TelecommandStackDeleteProps) => {
  const submit = async (onToggle: () => void) => {
    await deleteTelecommandStacks(telecommandStack.id);
    if (onChange) {
      onChange();
    }
    onToggle();
  };

  return (
    <Toggler>
      {([toggled, onToggle]) => (
        <>
          <DeleteButton onClick={onToggle}>Delete</DeleteButton>

          <Dialog
            open={toggled}
            maxWidth="sm"
            data-testid="TelecommandStackDelete"
          >
            <SuspenseMutation>
              {({ loading, action }) => {
                return (
                  <>
                    <DialogTitle>Delete {telecommandStack.name}?</DialogTitle>

                    <DialogActions>
                      <Flex alignItems="center">
                        <DeleteButton
                          onClick={() => action(() => submit(onToggle))}
                          mr={1}
                          disabled={loading}
                        >
                          Delete
                        </DeleteButton>
                        <CancelButton onClick={onToggle} mr={1}>
                          Cancel
                        </CancelButton>
                      </Flex>
                    </DialogActions>
                  </>
                );
              }}
            </SuspenseMutation>
          </Dialog>
        </>
      )}
    </Toggler>
  );
};

const mapDispatchToProps = () => ({});

export const TelecommandStackDeleteContainer = connect(
  null,
  mapDispatchToProps
)(TelecommandStackDelete);
