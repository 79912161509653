import React, { useContext } from "react";
import { channelMaps, functionalCategoriesMaps } from "../utils/constants";
import { CheckBox } from "components";
import { Flex, Heading } from "primitives";
import {
  FunctionalCategoriesMaps,
  ChannelMaps,
  ChannelsName,
  FunctionalCategoriesName,
  ContextActionsNames
} from "../models";
import { KeyManagerContext } from "../context";

type FilterType = FunctionalCategoriesMaps | ChannelMaps;
type FilterName = "channels" | "functionalCategory";
type Filters = ChannelsName | FunctionalCategoriesName;

export const SessionKeyFilter = () => {
  const {
    state: { applyedFilters },
    dispatch
  } = useContext(KeyManagerContext);

  const toggleFilter = (filterName: FilterName, filter: Filters) => {
    const newFilters = [...applyedFilters.sessionKey[filterName]];

    /**
     * Check if the filter has been already applyed then
     * push|remove the filter from the applyed filters
     */
    const index = applyedFilters.sessionKey[filterName].indexOf(
      filter as never
    );
    if (index === -1) {
      newFilters.push(filter);
    } else {
      newFilters.splice(index, 1);
    }

    dispatch({
      type: ContextActionsNames.applyedFilters,
      payload: {
        ...applyedFilters,
        sessionKey: { ...applyedFilters.sessionKey, [filterName]: newFilters }
      }
    });
  };

  const renderFilters = (filter: FilterType, filterName: FilterName) => {
    return Object.values(filter).map((channel, idx) => {
      const id = `session-key-filter-${Object.keys(filter)[idx]}`;
      const label = Object.keys(filter)[idx] as Filters;
      return (
        <Flex alignItems={"center"} key={idx}>
          <CheckBox
            id={id}
            checked={applyedFilters.sessionKey[
              filterName as keyof typeof applyedFilters.sessionKey
            ].includes(label as never)}
            onChange={() => toggleFilter(filterName, label)}
          />
          <label htmlFor={id}>{label}</label>
        </Flex>
      );
    });
  };

  return (
    <div>
      <Flex flexDirection={"column"} mt={5}>
        <Heading display={4}>Filter by channel</Heading>
        <Flex justifyContent={"flex-start"}>
          {renderFilters(channelMaps, "channels")}
        </Flex>
      </Flex>
    </div>
  );
};
