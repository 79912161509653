import { combineReducers } from "redux";
import { constellationsReducer } from "./constellationsReducer";
import { constellationReducer } from "./constellationReducer";
import { constellationDashboardReducer } from "./constellationDashboardReducer";

export default combineReducers({
  selected: constellationReducer,
  list: constellationsReducer,
  dashboard: constellationDashboardReducer
});
