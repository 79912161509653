import React from "react";
import styled, { css } from "styled-components";
import { Flex, Absolute } from "primitives";
import SwitchState, { SwitchStateProps } from "./SwitchState";
import {
  TestableProps,
  DATA_TEST_ID_ATTR_NAME
} from "components/testable/models";

interface SwitchProps extends SwitchStateProps, TestableProps {
  size?: string;
  checked?: boolean;
}

interface CircleSizes {
  [name: string]: string;
  default: string;
  small: string;
}

const circleSizes: CircleSizes = {
  default: "12px",
  small: "6px"
};

const size = (props: SwitchProps) => {
  switch (props.size) {
    case "small":
      return css`
        width: 18px;
        height: ${circleSizes.small};
        ${Circle} {
          width: ${circleSizes.small};
          height: ${circleSizes.small};
        }
      `;

    default:
      return css`
        width: 30px;
        height: ${circleSizes.default};
        ${Circle} {
          width: ${circleSizes.default};
          height: ${circleSizes.default};
        }
      `;
  }
};

const Content = styled(Flex)`
  border-radius: 100px;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

const Circle = styled(Absolute)`
  border-radius: 50%;
  transition: all 0.1s linear;
  left: 0;
`;

const circleSize = (props: SwitchProps) =>
  props.size ? circleSizes[props.size] : circleSizes.default;

const SwitchStyled = styled("div")<SwitchProps>`
  position: relative;

  input:checked + ${Content} ${Circle} {
    left: calc(100% - ${circleSize});
  }
  ${size}
`;

const Switch = ({ size: selectedSize, ...props }: SwitchProps) => (
  <SwitchState {...props}>
    {({ input, checked }: any) => (
      <SwitchStyled size={selectedSize}>
        <input type="checkbox" {...input} />
        <Content bg="palette.grey.3">
          <Circle bg={checked ? "palette.brand.0" : "palette.grey.1"} />
        </Content>
      </SwitchStyled>
    )}
  </SwitchState>
);

Switch.displayName = "Switch";

Switch.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: Switch.displayName
};

export default Switch;
