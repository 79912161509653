import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import {
  COMMON,
  TYPOGRAPHY,
  BORDER,
  TypographyProps,
  CommonProps,
  BorderProps
} from "styles/constants";
import { Flex, Icon, Text } from "primitives";
import {
  TestableProps,
  DATA_TEST_ID_ATTR_NAME
} from "components/testable/models";

type HTMLSelect = React.SelectHTMLAttributes<HTMLSelectElement>;
interface SelectProps
  extends TypographyProps,
    CommonProps,
    BorderProps,
    TestableProps {
  readOnly?: boolean;
  borderL?: number;
}

const SelectStyled = styled.select<HTMLSelect & SelectProps>`
  ${COMMON}
  ${TYPOGRAPHY}
  ${BORDER}
  font-family: ${themeGet("fonts.0")};
  text-transform: uppercase;
  text-align: center;
  display: block;
  width: 100%;
  appearance: none;
  background-color: transparent;
  border-radius: 0;

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const IconContainer = styled(Flex)`
  pointer-events: none;
`;

IconContainer.defaultProps = {
  alignItems: "center",
  color: "palette.brand.0",
  borderLeft: 2,
  borderColor: "palette.brand.0",
  px: 2,
  ml: -4
};

const Select = (props: HTMLSelect & SelectProps) => {
  return (
    <Flex alignItems="stretch" flex="1 1 auto">
      <SelectStyled {...props} />
      <IconContainer borderLeft={props.borderL}>
        <Icon name="ArrowDown" width="8" height="4" />
      </IconContainer>
    </Flex>
  );
};

Select.displayName = "Select";

Select.defaultProps = {
  fontWeight: "bold",
  color: "inherit",
  fontSize: 2,
  py: 2,
  pl: 2,
  pr: 5,
  border: "none",
  [DATA_TEST_ID_ATTR_NAME]: Select.displayName,
  list: "options-list"
};

export default Select;

const SelectSimpleStyled = styled.select<
  HTMLSelect &
    SelectProps & {
      width?: string;
      borderColor?: string;
      noBorderBottom?: boolean;
    }
>`
  ${COMMON}
  ${TYPOGRAPHY}
  ${BORDER}
  text-align: start;
  display: block;
  min-width: ${(props) => (props.width ? props.width : "auto")} ;
  width: ${(props) => (props.width ? "auto" : "100%")} 
  appearance: none;
  background-color: transparent;
  border-radius: 0;
  border-bottom: 1px solid ${(props) =>
    props.noBorderBottom
      ? "none"
      : props.borderColor
      ? props.borderColor
      : themeGet("colors.palette.grey.8")};
  cursor: pointer;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: ${(props) => (props.fontSize ? props.fontSize : 6)}

  &:focus {
    outline: none;
    box-shadow: none;
    border-bottom: ${(props) =>
      props.noBorderBottom ? "none" : "2px solid"} ${themeGet(
      "colors.palette.purple.2"
    )};
    background-color: ${themeGet("colors.palette.white.1")};
  }
`;

const IconContainerSimple = styled(Flex)`
  pointer-events: none;
`;

IconContainerSimple.defaultProps = {
  color: "palette.brand.0",
  px: 2,
  ml: "-33px",
  pt: 1
};

interface SelectSimpleProps {
  width?: string;
  height?: number | string;
  borderColor?: string;
  iconPt?: string | number;
  noBorderBottom?: boolean;
}

export const SelectSimple = (
  props: HTMLSelect & SelectProps & SelectSimpleProps
) => {
  return (
    <Flex height={props.height ? props.height : 32}>
      <SelectSimpleStyled {...props} />
      <IconContainerSimple pt={props.iconPt && props.iconPt}>
        <Icon name="ArrowSelect" size={42} />
      </IconContainerSimple>
    </Flex>
  );
};

SelectSimple.displayName = "Select";

SelectSimple.defaultProps = {
  color: "palette.brand.0",
  fontSize: 6,
  pt: "6px",
  pb: "7px",
  pr: "28px",
  border: "none",
  [DATA_TEST_ID_ATTR_NAME]: Select.displayName,
  list: "options-list"
};

export const SelectContainerSimple = styled(Flex)`
  flex-direction: column;
  display: inline-flex;
`;

export const SelectLabelSimple = styled(Text)`
  &:focus {
    color: ${themeGet("colors.palette.white.1")};
  }
`;

SelectLabelSimple.defaultProps = {
  color: "palette.brand.0",
  fontSize: 3
};
