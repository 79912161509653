import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { OperationActivityDialog } from "app/planningSystem/components/OperationActivityDialog";
import { deleteOperationActivityAction } from "app/planningSystem/actions";

const mapStateToProps = (state: any) => ({
  oa: state.planningSystem.oa
});
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  deleteOperationActivity: (uuid: string) =>
    dispatch(deleteOperationActivityAction(uuid))
});

export const OperationActivityDialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OperationActivityDialog);
