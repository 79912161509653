export enum FeedbackStatus {
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
  INFO = "info"
}

export interface Feedback {
  id: string;
  title: string;
  details?: string;
  status: FeedbackStatus;
  showInFooter: "showInFooter" | null;
}

export function getFeedbackStatus(status: string) {
  if (status === "OK" || status === "SUCCESS") {
    return FeedbackStatus.SUCCESS;
  } else if (status === "ERROR") {
    return FeedbackStatus.ERROR;
  }
  return FeedbackStatus.INFO;
}
