import React, { Component } from "react";
import { Form } from "app/shared";
import { Box, Heading, Flex, Text } from "primitives";
import { SuspenseMutation } from "app/network";
import { CancelButton, SaveButton } from "components";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { CreateTelecommandStackCommand } from "../models";
import { schemaImport, uiSchemaImport } from "../models/schemas";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { TelecommandStack } from "app/telecommandStack/models";
import { createTelecommandStack } from "app/telecommandStack/services";
import { tcStack } from "../actions";

interface TelecommandStackImportProps extends RouteComponentProps {
  createTelecommandStack: (
    data: CreateTelecommandStackCommand
  ) => Promise<TelecommandStack>;
}

interface TelecommandStackImportState {
  formData: CreateTelecommandStackCommand | null;
}

export class TelecommandStackImport extends Component<
  TelecommandStackImportProps,
  TelecommandStackImportState
> {
  state = {
    formData: null
  };

  onChange(form: TelecommandStackImportState) {
    this.setState({ formData: form.formData });
  }

  async submit(e: React.FormEvent<HTMLInputElement>, formData: any) {
    e.preventDefault();
    const importedFormData = JSON.parse(formData.file);
    const {
      data: { name }
    } = await createTelecommandStack(importedFormData);
    if (name) {
      tcStack.toast(`${name} imported successfully`, "SUCCESS");
      this.props.history.push("/telecommandstacks");
    } else {
      tcStack.toast("Telecommand Stack import failed", "ERROR");
      this.props.history.push("/telecommandstacks");
    }
  }

  render() {
    const { formData } = this.state;

    return (
      <Box color="text.default" data-testid="TelecommandStackCreate" mx={3}>
        <Flex mb={2}>
          <Heading display={1}>Telecommand Stack</Heading>
        </Flex>
        <Flex flexDirection="column" bg="fill.0" p={3}>
          <SuspenseMutation>
            {({ loading, error, action }) => {
              return (
                <Form
                  formData={formData}
                  schema={schemaImport()}
                  uiSchema={uiSchemaImport}
                  disabled={loading}
                  onSubmit={(
                    form: { formData: any },
                    e: React.FormEvent<HTMLInputElement>
                  ) => action(() => this.submit(e, form.formData))}
                  onChange={(form: TelecommandStackImportState) =>
                    this.onChange(form)
                  }
                >
                  <CancelButton
                    onClick={() =>
                      this.props.history.push("/telecommandstacks")
                    }
                    mr={2}
                    mt={4}
                  >
                    Cancel
                  </CancelButton>
                  <SaveButton mt={4} type="submit" disabled={loading}>
                    Import
                  </SaveButton>

                  {error ? (
                    <Box my={2}>
                      <Text color="text.danger">Error...</Text>
                    </Box>
                  ) : null}
                </Form>
              );
            }}
          </SuspenseMutation>
        </Flex>
      </Box>
    );
  }
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
) => ({});

export const TelecommandStackImportContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TelecommandStackImport)
);
