import React, { useContext, useState } from "react";
import { Flex, Dialog, DialogTitle, DialogContent, Button } from "primitives";
import { CancelButton } from "components";
import { PlanningSystemContext } from "app/planningSystem/context";
import { ContextActionsNames } from "app/planningSystem/models";
import { Form } from "app/shared";
import { SuspenseMutation } from "app/network";
import {
  timelineDisapproveUISchema,
  timelineDisapproveSchema
} from "app/planningSystem/schemas";
import { store } from "app/store";
import { disapproveTimelineAction } from "app/planningSystem/actions";

interface FormData {
  startTime: string;
}

export const TimelineDisapprove = () => {
  const [formData, setFormData] = useState<FormData | null>();
  const {
    state: { timelineDialog },
    dispatch
  } = useContext(PlanningSystemContext);
  const { isOpen } = timelineDialog || {};

  const handleCancel = () => {
    dispatch({
      type: ContextActionsNames.timelineDialog,
      payload: { isOpen: false }
    });
  };

  const handleOnChange = async ({
    formData: data
  }: {
    formData: { startTime: Date };
  }) => {
    if (data?.startTime) {
      setFormData({ startTime: data.startTime.toISOString() });
    }
  };

  const submit = async (
    e: React.FormEvent<HTMLInputElement>,
    { formData: data }: any
  ) => {
    try {
      e.preventDefault();
      const res = await store.dispatch(
        disapproveTimelineAction({ startTs: data.startTime })
      );
      if (res.detail || res.details) return;
      dispatch({
        type: ContextActionsNames.timelineDialog,
        payload: { isOpen: false }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog
      open={isOpen || false}
      maxWidth="sm"
      data-testid={`timeline-dialog`}
    >
      <DialogTitle>Disapprove timeline</DialogTitle>

      <DialogContent>
        <SuspenseMutation>
          {({ loading, action }) => {
            return (
              <Flex
                flexDirection={"column"}
                justifyContent={"space-between"}
                style={{ minHeight: 400 }}
              >
                <Form
                  formData={formData}
                  id={"timeline-disapprove"}
                  schema={timelineDisapproveSchema}
                  uiSchema={timelineDisapproveUISchema}
                  onSubmit={(
                    data: any,
                    e: React.FormEvent<HTMLInputElement>
                  ) => {
                    return submit(e, data);
                  }}
                  disabled={loading}
                  onChange={handleOnChange}
                >
                  <></>
                </Form>
                <Flex justifyContent={"flex-end"}>
                  <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                  <Button
                    ml={1}
                    size={"small"}
                    form={"timeline-disapprove"}
                    type={"submit"}
                    disabled={loading}
                  >
                    Continue
                  </Button>
                </Flex>
              </Flex>
            );
          }}
        </SuspenseMutation>
      </DialogContent>
    </Dialog>
  );
};
