import React, { createContext } from "react";
import { IContext, IContextActions } from "../models";

export const initialState: IContext = {
  contextMenu: null,
  selectedMasterKey: null,
  selectedSessionKey: [],
  changeStateDialog: null,
  applyedFilters: {
    sessionKey: {
      channels: [],
      functionalCategory: []
    }
  },
  injectSessionKeyDialog: null
};

export const reducer = (state: IContext, action: IContextActions): IContext => {
  switch (action.type) {
    case "selectedSessionKey":
      return {
        ...state,
        selectedSessionKey: action.payload as IContext["selectedSessionKey"]
      };
    case "selectedMasterKey":
      return {
        ...state,
        selectedMasterKey: action.payload as IContext["selectedMasterKey"]
      };
    case "contextMenu":
      return {
        ...state,
        contextMenu: action.payload as IContext["contextMenu"]
      };
    case "changeStateDialog":
      return {
        ...state,
        changeStateDialog: action.payload as IContext["changeStateDialog"]
      };
    case "applyedFilters":
      return {
        ...state,
        applyedFilters: action.payload as IContext["applyedFilters"]
      };
    case "injectSessionKeyDialog":
      return {
        ...state,
        injectSessionKeyDialog:
          action.payload as IContext["injectSessionKeyDialog"]
      };
    default:
      return { ...state, ...(action.payload as any) };
  }
};

export const KeyManagerContext = createContext<{
  state: IContext;
  dispatch: React.Dispatch<IContextActions>;
}>({ state: initialState, dispatch: () => null });
