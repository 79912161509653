import React from "react";
import styled from "styled-components";
import {
  TYPOGRAPHY,
  BORDER,
  TypographyProps,
  CommonProps,
  BorderProps
} from "styles/constants";
import { Box } from "primitives";
import {
  TestableProps,
  DATA_TEST_ID_ATTR_NAME
} from "components/testable/models";

type HTMLInput = React.InputHTMLAttributes<HTMLInputElement>;

interface InputProps
  extends TypographyProps,
    BorderProps,
    CommonProps,
    TestableProps {}

const StyledInput = styled("input")`
  ${TYPOGRAPHY}
  ${BORDER}
  width: ${(props) => (props.width ? props.width : "100%")};
  outline: none;
  &::-webkit-file-upload-button {
    visibility: hidden;
  }
  &::before {
    content: "Choose a file";
    display: inline-block;
    border: 1px solid #fff;
    border-radius: 3px;
    padding: 5px 12px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    outline: none;
  }
  &::before:hover {
    outline: none;
    background-color: rgba(255, 255, 255, 0.1);
  }
  &::before:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const Input = (props: HTMLInput & InputProps) => {
  return (
    <Box overflow="visible">
      <StyledInput type="file" {...props} />
    </Box>
  );
};

Input.displayName = "Input";

Input.defaultProps = {
  fontSize: "16px",
  [DATA_TEST_ID_ATTR_NAME]: Input.displayName
};

export default Input;
