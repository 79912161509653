import styled from "styled-components";
import { Label } from "primitives";

const SelectLabel = styled(Label)`
  width: auto;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
`;

SelectLabel.defaultProps = {
  fontSize: 2,
  fontWeight: "normal",
  color: "text.default",
  margin: 0,
  p: 2,
  borderColor: "palette.brand.0",
  borderRight: 2
};

export default SelectLabel;
