import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgReset = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 40 40"
    color={color}
  >
    <path d="M 18.386719 0.488281 C 16.394531 0.675781 14.554688 1.109375 12.832031 1.792969 C 9.242188 3.214844 6.160156 5.660156 4.003906 8.789062 L 3.714844 9.214844 L 3.714844 4.765625 L 0.449219 4.765625 L 0.449219 15.675781 L 11.347656 15.675781 L 11.347656 12.378906 L 8.511719 12.371094 L 5.667969 12.359375 L 6.023438 11.765625 C 8.902344 6.957031 13.964844 3.996094 19.550781 3.839844 C 23.558594 3.734375 27.339844 5.050781 30.46875 7.652344 C 31.039062 8.121094 32.140625 9.246094 32.628906 9.851562 C 34.636719 12.359375 35.8125 15.210938 36.171875 18.425781 C 36.253906 19.15625 36.242188 21.082031 36.148438 21.824219 C 35.679688 25.644531 34.074219 28.933594 31.382812 31.582031 C 28.789062 34.140625 25.445312 35.734375 21.777344 36.160156 C 20.753906 36.277344 19.058594 36.265625 18.019531 36.136719 C 13.714844 35.59375 9.835938 33.398438 7.164062 29.988281 C 6.777344 29.496094 6.097656 28.472656 5.777344 27.894531 C 4.539062 25.679688 3.757812 22.828125 3.753906 20.507812 L 3.753906 19.992188 L 0.4375 19.992188 L 0.460938 20.8125 C 0.472656 21.269531 0.515625 21.890625 0.550781 22.191406 C 1.078125 26.582031 2.941406 30.507812 5.980469 33.628906 C 9.164062 36.894531 13.25 38.886719 17.898438 39.441406 C 18.777344 39.542969 21.304688 39.542969 22.183594 39.441406 C 26.648438 38.910156 30.574219 37.058594 33.710938 34.019531 C 36.992188 30.832031 38.949219 26.839844 39.539062 22.148438 C 39.644531 21.292969 39.644531 18.683594 39.539062 17.832031 C 38.957031 13.171875 36.996094 9.164062 33.75 6 C 30.601562 2.9375 26.730469 1.101562 22.222656 0.546875 C 21.617188 0.46875 19 0.429688 18.386719 0.488281 Z M 18.386719 0.488281 " />
  </Svg>
);

SvgReset.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgReset;
