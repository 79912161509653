import { connect } from "react-redux";
import { getConstellation, clearConstellation } from "app/constellation";
import { getSatelliteDefinition } from "app/satelliteDefinition";
import { getSatellite } from "../services";
import { getActiveSatellite } from "app/shared/utils";
import { DatasourceHistoryTable } from "app/telemetry/visualizations/DatasourceHistoryTable";

const mapStateToProps = (state: any) => ({
  dashboard: state.satellite.dashboard,
  satellite: getActiveSatellite(state.constellations.dashboard)
});

const mapDispatchToProps = () => {
  return {
    getConstellation: (id: number) => getConstellation(id),
    clearConstellation: () => clearConstellation(),
    getSatellite: (id: number) => getSatellite(id),
    getSatelliteDefinition: (id: number) => getSatelliteDefinition(id)
  };
};

export const DatasourceHistoryTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DatasourceHistoryTable);
