import React, { Component, ChangeEvent } from "react";
import {
  Text,
  Flex,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "primitives";
import {
  deleteDataSourceUnit,
  createDataSourceUnit,
  getDataSourceUnits
} from "app/dataSource/services";
import {
  AddButton,
  EditButton,
  CancelButton,
  DeleteButton,
  InputField
} from "components";
import { FeedbackStatus } from "app/feedback/models";

interface UnitManagementState {
  modalOpen: boolean;
  unit: string;
  unitsList: Unit[];
}

interface UnitManagementProps {
  unitsList: Unit[];
  setFeedback: (title: string, status: FeedbackStatus) => void;
}

interface Unit {
  unit: string;
}

export class UnitManagement extends Component<
  UnitManagementProps,
  UnitManagementState
> {
  state: UnitManagementState = {
    modalOpen: false,
    unit: "",
    unitsList: []
  };

  componentWillMount() {
    const { unitsList } = this.props;
    this.setState({ unitsList });
  }

  componentWillUpdate(nextProps: UnitManagementProps) {
    const { unitsList } = this.state;
    if (
      unitsList.length !== nextProps.unitsList.length &&
      nextProps.unitsList.length > 0
    ) {
      this.setState({ unitsList: nextProps.unitsList });
    }
  }

  hideDialog = () => {
    this.setState({ modalOpen: false, unit: "" });
  };

  openDialog = () => {
    this.setState({ modalOpen: true });
  };

  deleteDataSourceUnit = (unit: Unit) => {
    deleteDataSourceUnit(unit.unit)
      .then((response: any) => {
        getDataSourceUnits();
      })
      .catch(() => {
        this.props.setFeedback(
          "Can't delete a data source unit that is being used.",
          FeedbackStatus.ERROR
        );
      });
  };

  addUnit = () => {
    const { unit } = this.state;
    unit.trim();
    if (unit.length > 0) {
      createDataSourceUnit(unit)
        .then((response: any) => {
          getDataSourceUnits();
        })
        .then(() =>
          this.props.setFeedback("Unit added", FeedbackStatus.SUCCESS)
        )
        .catch(() => {
          this.props.setFeedback(
            "Error while creating data source unit.",
            FeedbackStatus.ERROR
          );
        });
    }
  };

  render() {
    const { modalOpen, unit, unitsList } = this.state;
    return (
      <>
        <Flex overflow="visible" justifyContent="flex-end">
          <EditButton
            maxWidth={200}
            marginRight={5}
            onClick={() => this.openDialog()}
            mr={1}
          >
            Manage Units
          </EditButton>
        </Flex>
        <Dialog
          open={modalOpen}
          maxWidth="md"
          data-testid="SystemDefinitionEdit"
        >
          <DialogTitle>Manage Units</DialogTitle>
          <Flex
            maxWidth={200}
            ml={"auto"}
            mr={2}
            mb={2}
            overflow="visible"
            alignItems="flex-end"
            minHeight={48}
          >
            <InputField
              id="unitName"
              value={unit}
              label="Unit"
              onChange={(
                event:
                  | ChangeEvent<HTMLTextAreaElement>
                  | ChangeEvent<HTMLInputElement>
              ) => this.setState({ unit: event.target.value })}
            />
            <AddButton mr={1} ml={3} onClick={() => this.addUnit()}>
              Add
            </AddButton>
          </Flex>
          <DialogContent>
            <Flex flexWrap="wrap">
              {unitsList.map((unitItem: Unit) => {
                return (
                  <Flex
                    key={unitItem.unit}
                    m={2}
                    p={2}
                    border={1}
                    borderRadius={2}
                    bg="palette.blue.5"
                  >
                    <Text fontSize={18} color="text.white">
                      {unitItem.unit}
                    </Text>
                    <DeleteButton
                      onClick={() => this.deleteDataSourceUnit(unitItem)}
                      ml={3}
                    />
                  </Flex>
                );
              })}
            </Flex>
          </DialogContent>
          <DialogActions>
            <Flex alignItems="center">
              <CancelButton onClick={() => this.hideDialog()} mx={1}>
                Close
              </CancelButton>
            </Flex>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
