import { DataSourceReading } from "app/telemetry/models/datasources";

export interface DataSourceOption {
  id: number;
  name: string;
  label: string;
  indexes: DataSourceOptionIndex[];
}

interface DataSourceOptionIndex {
  index: number;
}

export enum SortingOptions {
  ASC = "ASC",
  DESC = "DESC"
}

export enum ChartType {
  Histogram = "Histogram",
  Gauge = "Gauge",
  LineChart = "RealTimeGraph"
}

export type GraphProps = {
  configOptions?: any;
  width: number;
  height: number;
  data: DataSourceReading[];
  ref?: any;
  modalOpen?: any;
};

export enum ChartEngines {
  d3 = "d3"
  // convasBase = "canvasGraph",
}

export const isTEST = process.env.NODE_ENV === "test";

export const isGauge = (widget: string) => widget === ChartType.Gauge;

export const isLineChart = (widget: string) => widget === ChartType.LineChart;

export const isHistogram = (widget: string) => widget === ChartType.Histogram;
