import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgCancel = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    viewBox="0 0 25 25"
    width={size || width}
    height={size || height}
    color={color}
  >
    <path
      d="M16.7193 12.4084L24.0491 19.7386C24.5253 20.2148 24.5253 20.988 24.0491 21.4642L21.4628 24.0507C20.9866 24.5269 20.2147 24.5269 19.7373 24.0507L12.4062 16.7205L5.07519 24.0507C4.59904 24.5269 3.82709 24.5269 3.35094 24.0507L0.763364 21.4642C0.287212 20.988 0.287212 20.216 0.763364 19.7386L8.09443 12.4084L0.763364 5.07699C0.287212 4.60081 0.287212 3.82762 0.763364 3.35145L3.35214 0.764946C3.82829 0.28877 4.60024 0.28877 5.07639 0.764946L12.4062 8.09637L19.7373 0.764946C20.2135 0.28877 20.9854 0.28877 21.4628 0.764946L24.0491 3.35265C24.5253 3.82883 24.5253 4.60081 24.0491 5.07819L16.7193 12.4084Z"
      fill={color}
    />
  </Svg>
);

SvgCancel.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgCancel;
