import * as d3 from "d3";
import { ChartType } from "./models";
import { UnitConverter } from "./unitConverter";
import { Histogram } from "../Histogram";
import { GraphDataModel } from "./GraphDataModel";
import { createToolTip } from "./ToolTip";
import { Gauge } from "../Gauge/Gauge";
import { DataSourceReading } from "app/telemetry/models/datasources";
import { LineChartNew } from "../LineChart/LineChartNew";

export class GraphBuilder {
  public static build(
    data: DataSourceReading[],
    configOptions: any,
    height: number,
    width: number,
    axesRef: React.RefObject<SVGElement>
  ) {
    const graphEngine = d3;
    const tooltip = createToolTip(graphEngine, configOptions.id);
    const unitConverter = new UnitConverter();
    const model = new GraphDataModel(
      data,
      configOptions,
      height,
      width,
      axesRef,
      unitConverter
    );

    switch (configOptions.widgetName) {
      case ChartType.Histogram:
        return new Histogram(model, graphEngine, tooltip);
        break;
      case ChartType.Gauge:
        return new Gauge(model, graphEngine);
        break;
      case ChartType.LineChart:
        return new LineChartNew(model, document.querySelector("#chart"));
        break;
      default:
        throw new Error(`${configOptions.widgetName} is not supported`);
        break;
    }
  }
}
