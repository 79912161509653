import React from "react";
import { SelectBox, InputBase } from "app/shared";
import { CheckBox, DateTimePicker } from "components";
import { scalingConfig } from "app/telemetry/visualizations/shared/configs";
const labelConfigOptionsSchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "label.schema.json",
  title: "Aurora Label",
  description: "The configuration options for the Aurora's Label component",
  type: "object",
  definitions: {
    textValue: {
      $id: "#/definitions/textValue",
      description: "Text value to be displayed",
      type: "string"
    },
    time: {
      $id: "#/definitions/time",
      title: "Time",
      description: "Defines the characteristics of the time",
      type: "string",
      //format: "date-time",
      examples: ["2011-08-12T20:17:46.384Z"]
    },
    timeUnixTimestamp: {
      $id: "#/definitions/timeUnixTimestamp",
      title: "Time",
      description: "Defines the characteristics of the time",
      type: "integer",
      minimum: 0,
      examples: [1]
    },
    bootcount: {
      $id: "#/definitions/bootcount",
      title: "Bootcount",
      description: "Defines the characteristics bootcount",
      type: "integer",
      minimum: 1
    },
    timeReference: {
      collapsible: "enabled",
      $id: "#/definitions/timeReference",
      title: "Timereference",
      description:
        "Defines the characteristics of the timeReference to be used to retrive the data to be presented",
      type: "object",
      properties: {
        reference: {
          $id: "#/properties/timeReference/properties/reference",
          title: "Reference",
          description:
            'An enumeration for the selection of the time reference to be used for the data querying.\nIf the reference "satelliteBootcountUtc" is selected, the "fromBootcount" and "toBootcount" can be used.\nIf "from" and "to" (and "fromBootcount" and "toBootount" for the case of "satelliteUtcBootcount" is selected), the mos recent value is queried.\n',
          type: "string",
          enum: ["groundUtc", "satelliteUtc", "satelliteBootcountObt"],
          default: "groundUtc"
        }
      },
      dependencies: {
        reference: {
          oneOf: [
            {
              properties: {
                reference: {
                  enum: ["groundUtc", "satelliteUtc"]
                }
                // from: {
                //   $id: "#/properties/timeReference/properties/from",
                //   title: "From",
                //   description:
                //     "The date-time used to present the data only after this date-time.\nIf not used, this filter is not applied.",
                //   $ref: "#/definitions/time"
                // },
                // to: {
                //   $id: "#/properties/timeReference/properties/to",
                //   title: "To",
                //   description:
                //     "The date-time used to present the data only before this date-time.\nIf not used, this filter is not applied.",
                //   $ref: "#/definitions/time"
                // }
              }
            },
            {
              properties: {
                reference: {
                  enum: ["satelliteBootcountObt"]
                },
                fromBootcount: {
                  $id: "#/properties/timeReference/properties/fromBootcount",
                  title: "From Bootcount",
                  description:
                    'The bootcount used to present the data only after this bootcount. If null, this filter is not applied.\nThis filter only exists if the reference "satelliteBootcountUtc" is selected.',
                  $ref: "#/definitions/bootcount"
                },
                fromBootcountTimestamp: {
                  $id: "#/properties/timeReference/properties/from",
                  title: "From Timestamp (Milis)",
                  description:
                    "The timestamp used to present the data only after this timestamp.\n",
                  $ref: "#/definitions/timeUnixTimestamp",
                  default: 0
                },
                toBootcount: {
                  $id: "#/properties/timeReference/properties/toBootcount",
                  title: "To Bootcount",
                  description:
                    'The bootcount used to present the data only before this bootcount. If null, this filter is not applied.\nThis filter only exists if the reference "satelliteBootcountUtc" is selected.',
                  $ref: "#/definitions/bootcount"
                },
                toBootcountTimestamp: {
                  $id: "#/properties/timeReference/properties/to",
                  title: "To Timestamp (Milis)",
                  description:
                    "The timestamp used to present the data only before this timestamp.\n",
                  $ref: "#/definitions/timeUnixTimestamp",
                  default: 0
                }
              }
            }
          ]
        }
      },
      required: ["reference"]
    },
    updatePeriod: {
      $id: "#/definitions/updatePeriod",
      title: "Update Period",
      description:
        "The period between queries to retrieve the values (milliseconds)",
      type: "integer",
      minimum: 1000,
      default: 2000
    },
    dataSource: {
      $id: "#/definitions/dataSource",
      type: "object",
      title: "DataSource",
      description: "The data-source item to be added to the component",
      properties: {
        name: {
          $id: "#/definitions/dataSource/properties/name",
          title: "Name",
          description: "The original data-source name",
          type: "string"
        },
        label: {
          $id: "#/definitions/dataSource/properties/label",
          title: "Label",
          description:
            "The label to be given to the data-source. When used, it overrides the original data-source name",
          type: "string"
        },
        id: {
          $id: "#/definitions/dataSource/properties/id",
          title: "Id",
          description: "The id of data-source",
          type: "integer",
          minimum: 1
        },
        indexes: {
          $id: "#/definitions/dataSource/properties/indexes",
          title: "Indexes",
          description:
            "In case of a vector data-source, represents the indexes to be presented. When is a scalar data-source, this field is not used. If it is an array data-source, and this field is not present or is empty, all the indexes should be considered",
          oneOf: [
            {
              type: "array",
              items: {
                title: "Items",
                description:
                  "An explanation about the purpose of this instance.",
                type: "object",
                properties: {
                  index: {
                    $id: "#/definitions/dataSource/properties/indexes/properties/index",
                    title: "Index",
                    description:
                      "The index where this properties will be reflected",
                    type: "integer",
                    minimum: 0
                  }
                },
                required: ["index"]
              }
            },
            {
              type: "null"
            }
          ]
        }
      },
      required: ["name", "id"]
    },
    visibilityMode: {
      collapsible: "enabled",
      $id: "#/definitions/visibilityMode",
      title: "Visibility Mode",
      description: "The visibility mode of the component",
      type: "string",
      enum: ["alwaysVisible", "alwaysHidden", "onHoverVisible"],
      default: "onHoverVisible"
    },
    floatDigits: {
      collapsible: "enabled",
      $id: "#/definitions/floatDigits",
      type: "number",
      title: "Float Digits",
      description: "Number of digits to display for floating point values"
    },
    scientificNotation: {
      collapsible: "enabled",
      $id: "#/definitions/scientificNotation",
      title: "Scientific Notation",
      type: "object",
      properties: {
        enabled: {
          type: "string",
          enum: ["notEnabled", "always", "ifGreaterThan"],
          enumNames: ["Not Enabled", "Always", "If greater than"],
          default: "notEnabled"
        }
      },
      dependencies: {
        enabled: {
          oneOf: [
            {
              properties: {
                enabled: {
                  enum: ["notEnabled"]
                }
              }
            },
            {
              properties: {
                enabled: {
                  enum: ["always"]
                },
                value: {
                  type: "number",
                  readOnly: true,
                  default: 0,
                  fixedValue: 0
                }
              },
              required: ["value"]
            },
            {
              properties: {
                enabled: {
                  enum: ["ifGreaterThan"]
                },
                value: {
                  type: "number",
                  default: 0
                }
              },
              required: ["value"]
            }
          ]
        }
      }
    },
    valueConversion: {
      collapsible: "enabled",
      $id: "#/definitions/valueConversion",
      type: "string",
      title: "Data Conversion",
      enum: ["Decimal", "Hexadecimal", "Octal", "Binary"],
      default: "Decimal"
    },
    color: {
      collapsible: "enabled",
      $id: "#/definitions/color",
      type: "string"
    },
    size: {
      collapsible: "enabled",
      $id: "#/definitions/size",
      type: "number"
    },
    valueMapping: {
      collapsible: "enabled",
      type: "array",
      hasRemove: true,
      items: {
        type: "object",
        properties: {
          enumeration: {
            type: "string"
          },
          backgroundColor: {
            $ref: "#/definitions/color"
          },
          fontColor: {
            title: "Font Color",
            $ref: "#/definitions/color"
          },
          fontSize: {
            title: "Font Size (px)",
            $ref: "#/definitions/size"
          },
          isRange: {
            title: "Is Range?",
            type: "boolean",
            default: true
          }
        },
        dependencies: {
          isRange: {
            oneOf: [
              {
                properties: {
                  isRange: {
                    enum: [true]
                  },
                  rangeMin: {
                    title: "Range - Min Value",
                    type: "number"
                  },
                  rangeMax: {
                    title: "Range - Max Value",
                    type: "number"
                  }
                },
                required: ["rangeMin", "rangeMax"]
              },
              {
                properties: {
                  isRange: {
                    enum: [false]
                  },
                  value: {
                    type: "number"
                  }
                },
                required: ["value"]
              }
            ]
          }
        }
      }
    }
  },
  properties: {
    textValue: {
      $ref: "#/definitions/textValue"
    },
    scale: scalingConfig("SimpleValue").configSchema,
    isGloballyControlled: {
      $id: "#/properties/isGloballyControlled",
      title: "Enable Time Controller",
      description:
        "Weather this widget should be controlled by global time controller. Enabling it will overide widget updatePeriod interval with global interval",
      type: "boolean",
      default: true
    },
    timeReference: {
      $ref: "#/definitions/timeReference"
    },
    showAlerts: {
      collapsible: "enabled",
      $id: "#/properties/showAlerts",
      title: "Show Alerts",
      description: "Weather the alerts should be shown",
      type: "boolean",
      default: true
    },
    visibilityMode: {
      collapsible: "enabled",
      description: "Visibility mode for the date of presented data",
      $ref: "#/definitions/visibilityMode"
    },
    // updatePeriod: {
    //   collapsible: "enabled",
    //   $ref: "#/definitions/updatePeriod"
    // },
    dataSources: {
      $id: "#/properties/data-sources",
      title: "DataSources",
      description: "List of data-sources to be added to the component",
      type: "array",
      minItems: 1,
      maxItems: 1,
      items: {
        $ref: "#/definitions/dataSource"
      }
    },
    showUnit: {
      collapsible: "enabled",
      $id: "#/properties/showUnit",
      title: "Show Unit",
      description: "Weather the data-source unit should be shown",
      type: "boolean",
      default: true
    },
    floatDigits: {
      title: "Float Digits",
      $ref: "#/definitions/floatDigits"
    },
    scientificNotation: {
      title: "Scientific Notation",
      $ref: "#/definitions/scientificNotation"
    },
    valueConversion: {
      title: "Value Conversion",
      $ref: "#/definitions/valueConversion"
    },
    styles: {
      collapsible: "enabled",
      $id: "#/definitions/styles",
      title: "Label font size & colors",
      type: "object",
      properties: {
        backgroundColor: {
          title: "Background Color",
          $ref: "#/definitions/color"
        },
        fontColor: {
          title: "Font Color",
          $ref: "#/definitions/color"
        },
        fontSize: {
          title: "Font Size (px)",
          $ref: "#/definitions/size"
        }
      }
    },
    valueMapping: {
      title: "Value Mapping",
      $ref: "#/definitions/valueMapping"
    },
    widgetName: {
      type: "string",
      title: "Label",
      default: "Label"
    }
  },
  required: ["timeReference", "showAlerts", "dataSources"]
};

const labelConfigOptionsDefault = {
  textValue: "",
  scale: {},
  isGloballyControlled: true,
  timeReference: {
    reference: "groundUtc"
  },
  showAlerts: true,
  visibilityMode: "onHoverVisible",
  // updatePeriod: 2000,
  dataSources: [],
  showUnit: true,
  floatDigits: 1,
  styles: {
    backgroundColor: "",
    fontColor: "",
    fontSize: 18
  },
  valueConversion: "Decimal",
  valueMapping: []
};

const labelConfigOptionsUISchema = {
  textValue: { "ui:widget": InputBase },
  scale: scalingConfig("SimpleValue").uiSchema,
  isGloballyControlled: { "ui:widget": "hidden" },
  timeReference: {
    reference: { "ui:widget": SelectBox },
    from: { "ui:widget": DateTimePicker },
    to: { "ui:widget": DateTimePicker },
    fromBootcountTimestamp: {
      "ui:widget": (props: any) => <InputBase maxLength={12} {...props} />
    },
    toBootcountTimestamp: {
      "ui:widget": (props: any) => <InputBase maxLength={12} {...props} />
    },
    fromBootcount: { "ui:widget": InputBase },
    toBootcount: { "ui:widget": InputBase }
  },
  showAlerts: { "ui:widget": CheckBox },
  visibilityMode: { "ui:widget": SelectBox },
  // updatePeriod: { "ui:widget": InputBase },
  dataSources: { "ui:widget": "hidden" },
  showUnit: { "ui:widget": CheckBox },
  floatDigits: { "ui:widget": InputBase },
  scientificNotation: {
    enabled: { "ui:widget": SelectBox },
    value: { "ui:widget": InputBase }
  },
  valueConversion: { "ui:widget": SelectBox },
  styles: {
    backgroundColor: { "ui:widget": "color" },
    fontColor: { "ui:widget": "color" },
    fontSize: { "ui:widget": InputBase }
  },
  valueMapping: {
    items: {
      isRange: { "ui:widget": CheckBox },
      value: { "ui:widget": InputBase },
      rangeMin: { "ui:widget": InputBase },
      rangeMax: { "ui:widget": InputBase },
      enumeration: { "ui:widget": InputBase },
      backgroundColor: { "ui:widget": "color" },
      fontColor: { "ui:widget": "color" },
      fontSize: { "ui:widget": InputBase }
    }
  },
  widgetName: { "ui:widget": "hidden" }
};

export {
  labelConfigOptionsSchema,
  labelConfigOptionsDefault,
  labelConfigOptionsUISchema
};
