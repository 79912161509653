import React from "react";
import useToggle, { UseToggleProps, UseToggleReturn } from "./hooks/useToggle";

export interface TogglerProps extends UseToggleProps {
  children: (props: UseToggleReturn) => React.ReactElement;
}

const Toggler = ({ children, defaultToggled, onToggle }: TogglerProps) => {
  const toggle = useToggle({ defaultToggled, onToggle });
  return children(toggle);
};

export default Toggler;
