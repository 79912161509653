import * as schemaModels from "../models";
import { literalParser, enumParser, groupParser, fileParser } from "./parsers";

/**
 * UI SchemaGenerator
 * The JSON ui schema generator will define which widget must be used for each input;
 * Usage:
 * import {uiSchemaGenerator} from "schemaGenerator";
 * const uiSchema = uiSchemaGenerator.generate(data);
 */
class UISchemaGenerator {
  /**
   *
   * @param {object} data
   * @returns {object} schema
   *
   */
  generate(data, hideDefaults = false, extraProps) {
    if (!data) return {};
    const { args } = data;
    const schema = {};

    args.forEach((arg) => {
      switch (arg.argType) {
        case schemaModels.LITERAL_ARG_TYPE:
          schema[arg.id] = literalParser.parseUI(arg, hideDefaults);
          break;
        case schemaModels.ENUM_ARG_TYPE:
          schema[arg.id] = enumParser.parseUI(arg, hideDefaults);
          break;
        case schemaModels.ARRAY_ARG_TYPE:
          schema[arg.id] = literalParser.parseUI(arg, hideDefaults);
          break;
        case schemaModels.GROUP_ARG_TYPE:
          schema[arg.id] = groupParser.parseUI(arg, hideDefaults);
          break;
        case schemaModels.FILE_ARG_TYPE:
          schema[arg.id] = fileParser.parseUI(
            arg,
            hideDefaults,
            extraProps.satelliteId
          );
          break;
        default:
          break;
      }
    });

    if (hideDefaults) {
      Object.keys(schema).forEach((k) => {
        if (this.shouldHide(schema[k])) {
          schema[k] = { ...schema[k], "ui:widget": "hidden" };
        }
      });
    }

    //Auto focus first input
    Object.keys(schema).some((fieldKey) => {
      if (schema[fieldKey]["ui:widget"] !== "hidden") {
        if (schema[fieldKey].items) {
          Object.keys(schema[fieldKey].items).some((itemKey) => {
            schema[fieldKey].items[itemKey]["ui:autofocus"] = true;
            return true;
          });
        } else {
          schema[fieldKey]["ui:autofocus"] = true;
        }
        return true;
      }
      return false;
    });

    return schema;
  }

  shouldHide(item) {
    // The parseUI family of methods either returns arrays or
    // items that don't have the type key. And although the type
    // is "array" it is in fact an object with an "items" key that
    // then has the fields mapped as entries of the "items"
    if (item.type && item.type === "array") {
      return Object.keys(item.items).reduce(
        (acc, k) => this.shouldHide(item.items[k]) && acc,
        true
      );
    } else {
      return item["ui:widget"] === "hidden";
    }
  }
}

export const uiSchemaGenerator = new UISchemaGenerator();
