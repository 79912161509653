import React, { useCallback, useState } from "react";
import { SelectBox, InputBase } from "app/shared";
import { Box, Heading, Flex, Text } from "primitives";
import styled from "styled-components";
import ReactJson from "react-json-view";
import { DateTimePicker, EmbeddedEditorEditor } from "components";
import { QuickRange } from "app/passageManager/utils";
type Satellite = any;
type GroundStation = any;

export const bookerSchemaEdit = (
  satellites: Satellite[],
  groundStations: GroundStation[],
  type: string,
  bookerConfigs?: any[],
  selectedConfigId?: any
) => {
  return {
    type: "object",
    properties: {
      id: {
        type: "number",
        title: "Id",
        kind: "constant"
      },
      configuration: {
        type: "number",
        title: "Select a passage manager configuration",
        enum: bookerConfigs?.map((p) => p.id),
        enumNames: bookerConfigs?.map((p) => p.name),
        default: selectedConfigId?.id
      },
      name: {
        type: "string",
        title: "",
        default: ""
      },
      groundStationsBySatellite: {
        type: "array",
        title: "Selected Satellites with Groundstations",
        items: {
          type: "object",
          properties: {
            satellite: {
              title: "Satellite",
              type: "string",
              enum: satellites?.map((satellite: Satellite) => satellite.label),
              default: ""
            },
            groundStations: {
              type: "array",
              title: "Ground Stations",
              items: {
                type: "string",
                enum: groundStations?.map(
                  (gs: GroundStation) => gs.groundStationName
                ),
                uniqueItems: true
              },
              uniqueItems: true
            }
          },
          required: ["satellite"]
        }
      },
      quickrange: {
        type: "string",
        title: "Select a quick date range",
        enum: Object.values(QuickRange)
      },
      daterange: {
        title: "Select a date range for fetching passages",
        type: "object",
        properties: {
          from: {
            type: "string",
            format: "date-time",
            source: "passage-manager"
          },
          to: {
            type: "string",
            format: "date-time",
            source: "passage-manager"
          }
        }
      },
      additionalData: {
        title: "Add additional config options",
        type: "object",
        properties: {
          config: {
            type: "string",
            title: " "
          }
        }
      }
    }
  };
};

export const createbookerUISchema = (readonly = false) => {
  return {
    id: { "ui:widget": "hidden" },
    configuration: { "ui:widget": SelectBox },
    name: {
      "ui:field": ConfigName
    },
    description: {
      "ui:widget": "hidden"
    },
    quickrange: { "ui:widget": SelectBox },
    daterange: {
      classNames: "daterange-passage-manager-fetch",
      from: {
        "ui:widget": DateTimePicker,
        classNames: "daterange-passage-manager-fetch-from"
      },
      to: {
        "ui:widget": DateTimePicker,
        classNames: "daterange-passage-manager-fetch-to"
      }
    },
    groundStationsBySatellite: {
      "ui:field": GroundStationsBySatelliteComponent,
      items: {
        satellite: {
          classNames: "multi-select-sats"
        },
        groundStations: {
          classNames: "multi-select-gs"
        }
      }
    },
    additionalData: {
      config: {
        "ui:widget": (props: any) => (
          <ReactJson
            src={{}}
            enableClipboard
            onEdit={() => {
              console.log("options edited");
            }}
            onAdd={() => {
              console.log("options edited");
            }}
            theme="monokai"
            name="options"
            style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 5 }}
            {...props}
          />
        )
      }
    }
  };
};

const GroundStationsBySatelliteComponent = (props: any) => {
  const list = props.formData || [];
  return (
    <>
      {list?.map((item: any, index: number) => (
        <Flex key={index} flexDirection="row">
          <Box>
            {item.satellite && <StyledSat>{item?.satellite}</StyledSat>}
          </Box>
          <StyledWrapper>
            [{" "}
            {item.groundStations &&
              item.groundStations?.map((gs: any, idx: number) => (
                <StyledGS key={idx}>{gs}</StyledGS>
              ))}{" "}
            ]
          </StyledWrapper>
        </Flex>
      ))}
    </>
  );
};

const ConfigName = (props: any) => {
  const name = props.formData || "";
  return <StyledLabel> {name} </StyledLabel>;
};

const StyledLabel = styled.div`
  padding: 2px 6px;
  margin: 4px 2px;
  font-size: 16px;
  color: #7ab9dc;
`;

const StyledSat = styled.div`
  color: #7ab9dc;
  padding: 2px 6px;
  margin: 4px 2px;
  background-color: #212232;
`;

const StyledGS = styled.span`
  padding: 2px 6px;
  margin: 4px;
  color: #7ab9dc;
  background-color: #21223240;
`;

const StyledWrapper = styled.span`
  padding: 2px;
  margin: 4px;
  color: #7ab9dc;
`;
