import { Procedure } from "../models";
import { Dispatch } from "react";
import { ThunkAction } from "redux-thunk";
import { fetchProcedures, fetchProcedure } from "../services";

export enum ProcedureActionType {
  FetchProcedure = "FETCH_PROCEDURE",
  FetchProcedures = "FETCH_PROCEDURES"
}

export interface FetchProceduresAction {
  type: ProcedureActionType.FetchProcedures;
  payload: Procedure[];
}

export interface FetchProcedureAction {
  type: ProcedureActionType.FetchProcedure;
  payload: Procedure | null;
}

export type ProcedureActions = FetchProceduresAction | FetchProcedureAction;

export type FetchProcedureActionResult = ThunkAction<
  Promise<Procedure | null>,
  {},
  {},
  FetchProcedureAction
>;
export const fetchProcedureAction = (
  id: number
): FetchProcedureActionResult => {
  return async (dispatch: Dispatch<FetchProcedureAction>) => {
    try {
      const payload = await fetchProcedure(id);
      dispatch({
        type: ProcedureActionType.FetchProcedure,
        payload
      });
      return Promise.resolve(payload);
    } catch (e) {
      dispatch({
        type: ProcedureActionType.FetchProcedure,
        payload: null
      });
      return Promise.resolve(null);
    }
  };
};

export type FetchProceduresActionResult = ThunkAction<
  Promise<Procedure[]>,
  {},
  {},
  FetchProceduresAction
>;
export const fetchProceduresAction = (): FetchProceduresActionResult => {
  return async (dispatch: Dispatch<FetchProceduresAction>) => {
    try {
      const payload = await fetchProcedures();
      dispatch({
        type: ProcedureActionType.FetchProcedures,
        payload
      });
      return Promise.resolve(payload);
    } catch (e) {
      return Promise.resolve([]);
    }
  };
};
