import React from "react";
import { SuspenseMutation } from "app/network";
import { Toggler } from "components";
import { DeleteButton, CancelButton } from "components";
import { connect } from "react-redux";
import { deleteScript } from "../services";
import { Script } from "../models";
import { Flex, Dialog, DialogTitle, DialogActions } from "primitives";

interface ScriptDeleteProps {
  script: { id: number; name: string };
  onChange: () => void;
  deleteScript: (id: number) => Promise<any>;
}

export const ScriptDelete = ({
  script,
  onChange,
  ...props
}: ScriptDeleteProps) => {
  const submit = async (onToggle: () => void) => {
    await props.deleteScript(script.id);
    if (onChange) {
      onChange();
    }
    onToggle();
  };

  return (
    <Toggler>
      {([toggled, onToggle]) => (
        <>
          <DeleteButton onClick={onToggle}>Delete</DeleteButton>

          <Dialog open={toggled} maxWidth="sm" data-testid="ScriptDelete">
            <SuspenseMutation>
              {({ loading, action }) => {
                return (
                  <>
                    <DialogTitle>Delete {script.name}?</DialogTitle>

                    <DialogActions>
                      <Flex alignItems="center">
                        <DeleteButton
                          onClick={() => action(() => submit(onToggle))}
                          mr={1}
                          disabled={loading}
                        >
                          Delete
                        </DeleteButton>
                        <CancelButton onClick={onToggle} mr={1}>
                          Cancel
                        </CancelButton>
                      </Flex>
                    </DialogActions>
                  </>
                );
              }}
            </SuspenseMutation>
          </Dialog>
        </>
      )}
    </Toggler>
  );
};

const mapDispatchToProps = () => ({
  deleteScript
});

export const ScriptDeleteContainer = connect(
  null,
  mapDispatchToProps
)(ScriptDelete);
