import { Fonts } from "styled-components";

const fontSizes = [8, 10, 11, 12, 13, 15, 16, 19, 33];

const fonts: Fonts = {
  fonts: ["'Anonymous+Pro', sans-serif, monospace"],
  fontSizes,
  fontWeights: {
    light: 300,
    regular: 400,
    bold: 600
  },
  heading: [
    fontSizes[8],
    fontSizes[7],
    fontSizes[6],
    fontSizes[5],
    fontSizes[4],
    fontSizes[3]
  ]
};

export default fonts;
