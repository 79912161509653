import { combineReducers } from "redux";
import { alertCounterReducer } from "./AlertCounterReducer";
import { alertsReducer } from "./AlertsReducer";
import { alertsDismissReducer } from "./AlertsDismissReducer";

export default combineReducers({
  alerts: alertsReducer,
  alertCounter: alertCounterReducer,
  alertDismiss: alertsDismissReducer
});
