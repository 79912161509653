import React, { useState, useEffect } from "react";
import { Box, Heading, Flex, Text, Label } from "primitives";
import { store } from "app/store";
import { isArray } from "lodash";
import { CheckBox } from "components";

interface FieldProps {
  label: string;
  children: string;
}

const Field = ({ label, children }: FieldProps) => {
  return (
    <Flex flexDirection="column" width="100%" my={3}>
      {label ? <Label>{label}</Label> : null}
      {isArray(children) ? (
        <Flex mt={1} flexDirection="column">
          {children.map((group: string) => (
            <CheckBox
              checked={true}
              label={group}
              onChange={null}
              disabled={true}
              style={{ cursor: "default" }}
            />
          ))}
        </Flex>
      ) : (
        <Text color="text.white" py={2}>
          {children}
        </Text>
      )}
    </Flex>
  );
};

export const Profile = () => {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const userData = store.getState().keycloak.idTokenParsed;
    setData(userData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box color="text.default" data-testid="DashboardCreate" mx={3}>
      <Flex mb={2}>
        <Heading display={1}>User account</Heading>
      </Flex>
      <Flex flexDirection="column" bg={"fill.1"} p={3} pt={1}>
        <Field label="Name">{data && data.name}</Field>
        <Field label="Username">{data && data.preferred_username}</Field>
        <Field label="Role">{data && data.family_name}</Field>
        <Field label="Satellites">{data && data.groups}</Field>
      </Flex>
    </Box>
  );
};
