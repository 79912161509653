import React, { useState, useEffect } from "react";
import { Box, Heading, Flex, Text } from "primitives";
import { SuspenseMutation } from "app/network";
import { SaveButton, CancelButton } from "components";
import { Form } from "app/shared";
import { clone } from "utils";
import { userCreateSchema, userCreateUISchema } from "./UserCreateSchema";
import { getKeycloakGroups } from "../services";
import { Group } from "../models";
import { Link } from "react-router-dom";

export const UserCreate = (props: any) => {
  const [data, setData] = useState<any>(null);
  const [groups, setGroups] = useState<Array<Group>>([]);

  useEffect(() => {
    getKeycloakGroups().then((res: any) => setGroups(res.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SuspenseMutation>
      {({ loading, error, action }) => {
        return (
          <Box color="text.default" data-testid="DashboardCreate" mx={3}>
            <Flex mb={2}>
              <Heading display={1}>Create user</Heading>
            </Flex>
            <Flex flexDirection="column" bg={"fill.0"} p={3}>
              <Form
                id="userCreate"
                formData={data ? clone(data) : {}}
                schema={userCreateSchema(groups)}
                uiSchema={userCreateUISchema}
                disabled={loading}
                onChange={(form: any) => setData(clone(form.formData))}
                onSubmit={
                  (form: any, e: any) => {
                    props.history.push("/usermanagement");
                  }
                  //action(() => console.log("🚀 ~ form", form))
                }
              >
                <Flex justifyContent="space-betwen">
                  <Box width={1}></Box>
                  <>
                    <Link to="/usermanagement">
                      <CancelButton mr={2}>Cancel</CancelButton>
                    </Link>
                    <SaveButton type="submit" disabled={loading}>
                      Save
                    </SaveButton>
                  </>
                </Flex>
                {error ? (
                  <Box my={2}>
                    <Text color="text.danger">Error...</Text>
                  </Box>
                ) : null}
              </Form>
            </Flex>
          </Box>
        );
      }}
    </SuspenseMutation>
  );
};
