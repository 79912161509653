import { SET_FEEDBACK, CLEAR_FEEDBACK } from "../models/constants";
import { Feedback } from "../models";

const initialState: Array<Feedback> = [];

const FeedbackDataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_FEEDBACK: {
      // Note: display single toast of same type instead of stacking up same messages
      const uniqueMessages = state.filter(
        (s) => s.title !== action.payload.title
      );
      return [action.payload, ...uniqueMessages];
    }
    case CLEAR_FEEDBACK:
      return state.filter((item) => item.id !== action.payload.id);
    default:
      return state;
  }
};

export default FeedbackDataReducer;
