import React, { Component } from "react";
import { getFrames } from "app/satellite/services";
import { PaginationSimple } from "app/shared";
import { Box, Text } from "primitives";
import config from "config/constants";
import { AuroraTelemetryFrame } from "app/telemetry/models";
import { PaginationParams } from "app/shared/list/components/PaginationSimple";
import { SatelliteInstance } from "app/satellite/models";
import { connect } from "react-redux";
import { filterByField, shouldFilter } from "components/table/Filter";
import { SatelliteFramesListTable } from "../SatelliteFramesListTable/SatelliteFramesListTable";

interface SatelliteFramesListProps {
  satellite: SatelliteInstance;
  fetchFrames: (
    satelliteId: number,
    pagination: PaginationParams,
    from: string
  ) => Promise<any>;
  options: any;
  refreshData: boolean;
  list?: any;
}

interface SatelliteFramesListState {
  list: AuroraTelemetryFrame[];
  pagination: PaginationParams;
  auxPaginationTimestamps: string[];
  disabledPagination: boolean;
  from: number;
  filters: any;
}

interface IFiltersTMFrameList {
  timestamp: string | null;
  satelliteId: string | null;
  frameName: string | null;
}

const initialState: SatelliteFramesListState = {
  list: [],
  pagination: { page: 1, perPage: 50 },
  auxPaginationTimestamps: [],
  disabledPagination: false,
  from: 24,
  filters: { timestamp: null, satelliteId: null, frameName: null }
};

class BaseSatelliteFramesList extends Component<
  SatelliteFramesListProps,
  SatelliteFramesListState
> {
  private interval?: ReturnType<typeof setTimeout>;
  private willUnmount: boolean;
  constructor(props: any) {
    super(props);
    this.willUnmount = false;
    this.state = {
      ...initialState
    };
  }

  componentDidMount() {
    this.loadData(0);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearTimeout(this.interval);
    }
    this.willUnmount = true;
  }

  componentWillUpdate(
    nextProps: SatelliteFramesListProps,
    nextState: SatelliteFramesListState
  ) {
    const { pagination, disabledPagination, from } = this.state;
    if (
      (nextState.pagination.page !== pagination.page ||
        nextState.pagination.perPage !== pagination.perPage ||
        nextState.from !== from) &&
      !disabledPagination
    ) {
      this.setState({ disabledPagination: true }, () => this.getFramesList());
    }

    const { refreshData } = this.props;
    if (refreshData !== nextProps.refreshData && !nextProps.refreshData) {
      clearTimeout(this.interval as ReturnType<typeof setTimeout>);
    }
    if (refreshData !== nextProps.refreshData && nextProps.refreshData) {
      this.loadData(0);
    }
  }

  private loadData(timer = config.timer.table) {
    const { disabledPagination } = this.state;
    this.interval = setTimeout(() => {
      !disabledPagination &&
        this.getFramesList().finally(() => {
          this.loadData();
        });
    }, timer);
  }

  private getFramesList() {
    const { satellite, fetchFrames } = this.props;
    const { pagination, auxPaginationTimestamps, from } = this.state;
    if (auxPaginationTimestamps[pagination.page - 1]) {
      pagination.to = auxPaginationTimestamps[pagination.page - 1];
    }
    const currentDate: Date = new Date();
    const fromDate: string = new Date(
      currentDate.setHours(currentDate.getHours() - from)
    ).toISOString();

    return fetchFrames(satellite.id, pagination, fromDate)
      .then((response: any) => {
        const data = response.data;
        if (data && data[data.length - 1]) {
          auxPaginationTimestamps[pagination.page] =
            data[data.length - 1].timestamp;
        }
        if (!this.willUnmount) {
          this.setState({
            list: data,
            pagination: pagination,
            auxPaginationTimestamps,
            disabledPagination: false
          });
        }

        return response;
      })
      .catch((error: Error) => {
        console.log("Error: ", error.message);
      });
  }

  onChange = (value: any, id: any) => {
    this.setState({
      filters: { ...this.state.filters, [id]: value }
    });
  };

  filterTMFramesList = (
    filters: IFiltersTMFrameList,
    tmFramList: any
  ): AuroraTelemetryFrame[] => {
    if (shouldFilter(filters) && tmFramList) {
      return filterByField(tmFramList, filters);
    }
    return tmFramList;
  };

  onTimeSelectorChange = (newFrom: number) => {
    if (newFrom) {
      this.setState({
        ...initialState,
        from: newFrom,
        filters: this.state.filters
      });
    }
  };

  render() {
    const { list, pagination, disabledPagination, filters, from } = this.state;
    const { options } = this.props;
    const filterableTMFramesList = this.filterTMFramesList(filters, list);
    return (
      <>
        {options.label && (
          <Text fontSize={18} m="10px 0">
            {options.label}
          </Text>
        )}
        {list && (
          <Box bg="fill.0" data-testid="telecommands-received">
            <SatelliteFramesListTable
              list={filterableTMFramesList}
              onChange={this.onChange}
              onTimeSelectorChange={this.onTimeSelectorChange}
              from={from}
            />
            <PaginationSimple
              pageSize={pagination.perPage}
              nextPageUrl={
                list.length >= pagination.perPage ? pagination.page + 1 : 0
              }
              previousPageUrl={pagination.page > 1 ? pagination.page - 1 : -1}
              onChange={(paginationUrl: string | number) => {
                this.setState({
                  pagination: {
                    page: paginationUrl as number,
                    perPage: pagination.perPage
                  },
                  disabledPagination: true
                });
              }}
              onPageSizeChange={(size: number) =>
                this.setState({
                  pagination: { page: pagination.page, perPage: size }
                })
              }
              disabled={disabledPagination}
              small={true}
            />
          </Box>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  refreshData: state.app.refreshData
});

const mapDispatchToProps = () => {
  return {
    fetchFrames: (
      satelliteId: number,
      pagination: PaginationParams,
      from: string
    ): Promise<any> => {
      return getFrames(satelliteId, {
        pageNumber: pagination.page,
        pageSize: pagination.perPage,
        from: from,
        to: pagination.to
      });
    }
  };
};

export const SatelliteFramesList = connect(
  mapStateToProps,
  mapDispatchToProps
)(BaseSatelliteFramesList);
