import React, { FC } from "react";
import { CancelButton, InputField, UploadButton, CheckBox } from "components";
import { Box } from "primitives";
import { ResourceBase } from "../models";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "primitives";
import { isValidArmsPath } from "./PathSelectorSchema";

interface ResourcePropertiesModalProps {
  selectedResource: ResourceBase | null;
  modalOpen: boolean;
  closeModal: () => void;
  onResourceMove: (
    resourceId: string,
    destination: string,
    copy: boolean,
    reload: any
  ) => void;
  onCopyResource: () => void;
  reload: any;
  isCopy: boolean;
}

const MoveModal: FC<ResourcePropertiesModalProps> = (props) => {
  // eslint-disable-next-line no-unused-vars
  const {
    modalOpen,
    closeModal,
    selectedResource,
    isCopy,
    reload,
    onCopyResource,
    onResourceMove
  } = props;
  const [destination, setDestination] = React.useState("");
  if (!selectedResource) return null;

  const isValid = isValidArmsPath(selectedResource, destination, "subpath");

  return (
    <Dialog open={modalOpen} maxWidth="md" overflow="hidden">
      <DialogTitle>Move Resource</DialogTitle>
      <DialogContent>
        <form>
          <Box mb={2}>
            <InputField
              id="name"
              value={`${
                selectedResource.dir === "."
                  ? ""
                  : `${selectedResource.dir.indexOf("/") === 0 ? "" : "/"}${
                      selectedResource.dir
                    }`
              }/${selectedResource.name}`}
              label="Current Path"
              disabled={true}
            />
          </Box>
          <Box mb={2}>
            <InputField
              onChange={(e) => {
                setDestination(e.target.value);
              }}
              id="name"
              value={destination}
              label="New Path"
              disabled={false}
            />
          </Box>
          <Box mb={2}>
            <CheckBox
              checked={isCopy}
              label={"Copy Resource"}
              onChange={() => onCopyResource()}
            />
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <UploadButton
          onClick={() => {
            onResourceMove(selectedResource.id, destination, isCopy, reload);
            closeModal();
          }}
          mx={1}
          disabled={!isValid || !selectedResource}
        >
          Move
        </UploadButton>
        <CancelButton onClick={() => closeModal()} mx={1}>
          Cancel
        </CancelButton>
      </DialogActions>
    </Dialog>
  );
};

export default MoveModal;
