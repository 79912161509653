import { TelecommandStack } from "../models";

const uuidv4 = require("uuid/v4");

// mocks for testing without sat
export async function mockTC(): Promise<MockRes> {
  return await new Promise<MockRes>((resolve) =>
    setTimeout(() => resolve(warn), 3000)
  );
}

export async function mockTCRes(type: string): Promise<MockRes> {
  if (type === "error") {
    return await new Promise<MockRes>((resolve) =>
      setTimeout(() => resolve(error), 3000)
    );
  }
  return await new Promise<MockRes>((resolve) =>
    setTimeout(() => resolve(success), 3000)
  );
}

interface MockRes {
  id: number;
  satId: number;
  status: string;
  response: any;
}
const warn = {
  id: 72853,
  satId: 85,
  status: "Sending",
  response: {
    statusName: "TcNone",
    level: "WARNING",
    reason: "TcNone"
  }
};
const error = {
  id: 72853,
  satId: 85,
  status: "Sending",
  response: {
    statusName: "TcNone",
    level: "WARNING",
    reason: "TcNone"
  }
};
const success = {
  id: 72811,
  satId: 85,
  status: "Sent",
  response: {
    statusName: "OK",
    level: "SUCCESS"
  }
};

const cspPingPayload1 = {
  telecommandUri: "csp://csp/ping",
  params: {
    csp: {
      node: 12,
      timeout: 1000,
      size: 1,
      flags: {
        fragmentation: 0,
        hmac: 0,
        xtea: 0,
        rdp: 0,
        crc: 0
      }
    }
  }
};
const cspPingPayload2 = {
  telecommandUri: "cspPingPayload2",
  params: {
    csp: {
      node: 12,
      timeout: 10000,
      size: 1,
      flags: {
        fragmentation: 0,
        hmac: 0,
        xtea: 0,
        rdp: 0,
        crc: 0
      }
    }
  }
};
const cspPingPayload3 = {
  telecommandUri: "cspPingPayload3",
  params: {
    csp: {
      node: 12,
      timeout: 10000,
      size: 1,
      flags: {
        fragmentation: 0,
        hmac: 0,
        xtea: 0,
        rdp: 0,
        crc: 0
      }
    }
  }
};

const cspPing1 = {
  telecommand: cspPingPayload1,
  passageId: 379910,
  id: uuidv4(),
  status: "success"
};
const cspPing2 = {
  telecommand: cspPingPayload2,
  passageId: 379910,
  id: uuidv4(),
  status: "error"
};

const cspPing3 = {
  telecommand: cspPingPayload3,
  passageId: 379910,
  id: uuidv4(),
  status: "sending"
};

// const cspPingRes = {
//   satId: 85,
//   id: 67904,
//   telecommandId: "csp://csp/ping",
//   tcLabel: "CSP Ping",
//   sentAt: "2022-05-12T14:53:35.080Z",
//   passageId: 379910,
//   groundStationName: "TestGS",
//   username: "dev@aurora",
//   tcType: "Sync",
//   status: "Sending",
//   response: {
//     statusName: "TcNone",
//     level: "WARNING",
//     reason: "TcNone"
//   },
//   telecommandExecutionPayload: {
//     telecommand: {
//       telecommandUri: "csp://csp/ping",
//       params: {
//         csp: {
//           node: 12,
//           timeout: 10000,
//           size: 1,
//           flags: {
//             fragmentation: 0,
//             hmac: 0,
//             xtea: 0,
//             rdp: 0,
//             crc: 0
//           }
//         }
//       }
//     },
//     passageId: 379910
//   },
//   telecommandProcessedPayload: null
// };

const stack1: TelecommandStack = {
  id: "stack1",
  name: "tc stack1",
  ownerId: "dev@",
  satelliteId: 97,
  status: "stopped",
  telecommandList: [cspPing1, cspPing2, cspPing3]
};

const stack2: TelecommandStack = {
  id: "stack2",
  name: "tc stack2",
  ownerId: "dev@",
  status: "stopped",
  satelliteId: 97,
  telecommandList: [cspPing1, cspPing2]
};

const stack3: TelecommandStack = {
  id: "stack3",
  name: "tc stack3",
  ownerId: "dev@",
  status: "stopped",
  satelliteId: 97,
  telecommandList: [cspPing1, cspPing2]
};

export const mockStackEmpty = [...Array(4).keys()].map((i) => {
  return {
    id: `telecommand_stack_${i}`,
    name: `telecommand stack ${i}`.toUpperCase(),
    ownerId: `stack ownder ${i}`,
    status: `stopped`,
    telecommandList: []
  };
});

export const mockStack = [stack1, stack2, stack3];
