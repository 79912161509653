export const transformFormData = (
  formData: any,
  satellites: any,
  groundStations: any
) => {
  const groundStationsBySatellite = formData?.groundStationsBySatellite.map(
    (sat: any) => {
      return {
        [findSatId(satellites, sat.satellite)]: sat.groundStations.map(
          (gs: any) => findGsIdFromName(groundStations, gs)
        )
      };
    }
  );

  return {
    name: formData.name,
    description: formData?.description,
    startTime: formData?.daterange.from,
    endTime: formData?.daterange.to,
    groundStationsBySatellite: Object.assign({}, ...groundStationsBySatellite),
    strategyId: 1
  };
};

export function createEditFormData(
  data: any,
  satellites: any[],
  groundStations?: any[]
) {
  const config: any = {};
  config.name = data?.name;
  config.description = data?.description;
  config.provider = data?.strategyId;
  config.daterange = {
    from: data?.startTime ? new Date(data.startTime) : null,
    to: data?.endTime ? new Date(data.endTime) : null
  };
  config.additionalData = {};

  const _satellites =
    data?.groundStationsBySatellite &&
    Array.from(
      new Set(
        Object.keys(data.groundStationsBySatellite).map((sat: any) => sat)
      )
    );

  config.groundStationsBySatellite = _satellites?.map(
    (satID: string | number) => {
      const selectedGroundStations = data?.groundStationsBySatellite[satID];
      return {
        satellite: satellites.find((sat: any) => sat.label === satID)?.label,
        groundStations: selectedGroundStations
      };
    }
  );

  return config;
}

function findGsIdFromName(groundStations: any[], groundStationName: string) {
  return groundStations.find((gs) => gs.groundStationName === groundStationName)
    ?.groundStationID;
}

function findSatId(satellites: any[], satellite: string) {
  return satellites.find((sat) => sat.label === satellite).id;
}

export function injectNamesFromIds(
  result: any,
  satellites: any[],
  groundStations: any[]
) {
  if (!result || !Array.isArray(result)) return;

  return result
    .map((r: any) => {
      const idsToNamesMapped = Object.entries(
        r.groundStationsBySatellite
      ).reduce((acc: any, [satId, stations]: [any, any]) => {
        const satellite = satellites.find((s: any) => s.id === Number(satId));
        if (satellite) {
          acc[satellite.name] = stations.map((gs: any) =>
            findGSId(groundStations, gs)
          );
        } else {
          acc[satId] = stations.map((gs: any) => findGSId(groundStations, gs));
        }
        return acc;
      }, {});

      return {
        ...r,
        groundStationsBySatellite: idsToNamesMapped,
        groundStationsBySatelliteIDs: r.groundStationsBySatellite
      };
    })
    .sort((a: any, b: any) => b.id - a.id);
}

export function getSatsFromStore(state: any, sats: any[] = []) {
  const list = state()?.constellations?.dashboard?.length
    ? state()?.constellations?.dashboard
    : sats[0]?.satelliteInstances;
  const satellites =
    list?.map((s: any) => ({ id: s?.id, name: s?.label })) || [];
  return satellites;
}

export function getGroundStationsFromStore(state: any, gs: any[] = []) {
  const list = state()?.groundStations?.groundStations?.length
    ? state()?.groundStations?.groundStations
    : gs;
  const groundStations =
    list?.map((gs: any) => ({
      id: gs.groundStationID,
      name: gs.groundStationName
    })) || [];
  return groundStations;
}

export function findGSId(groundStations: any[], groundStationId: number) {
  return (
    groundStations.find((gs) => gs.id === groundStationId)?.name ||
    groundStationId
  );
}

export function findSatName(satelliteID: any, satellites: any) {
  return (
    satellites.find((sat: any) => sat.id === satelliteID)?.name || satelliteID
  );
}

export function loadState(KEY: string) {
  try {
    const serializedState = localStorage.getItem(KEY);
    if (!serializedState) {
      return [];
    }
    const parsed = JSON.parse(serializedState);
    return parsed;
  } catch (e) {
    return undefined;
  }
}

export function saveState(KEY: string, state: any) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(KEY, serializedState);
  } catch (e) {
    console.log("TURBO ~ saveState ~ e", e);
  }
}

export function clearState(KEY: string) {
  return localStorage.removeItem(KEY);
}

export const validator = (formData: any, errors: any) => {
  const now = new Date();
  if (formData.daterange) {
    const { from, to } = formData.daterange;
    if (from && new Date(from) < now) {
      errors.daterange.from.addError("date cannot be in the past.");
    }
    if (to && new Date(to) < now) {
      errors.daterange.to.addError("date cannot be in the past.");
    }
  }
  return errors;
};

export function isEmptyValue(value: any): boolean {
  return (
    value === "" ||
    value === null ||
    value === undefined ||
    (typeof value === "object" &&
      !Array.isArray(value) &&
      Object.keys(value).length === 0)
  );
}

interface FormattedData {
  [key: string]: any;
}
export function mergeFormData(
  formData: FormattedData,
  formattedData: FormattedData
): FormattedData {
  return Object.entries(formData).reduce((acc: FormattedData, [key, value]) => {
    acc[key] = isEmptyValue(value) ? formattedData[key] : value;
    return acc;
  }, {});
}

export enum QuickRange {
  OneDay = "1 day",
  ThreeDays = "3 days",
  OneWeek = "1 week",
  TwoWeeks = "2 weeks",
  ThreeWeeks = "3 weeks",
  OneMonth = "1 month",
  TwoMonths = "2 months",
  ThreeMonths = "3 months"
}

export const calculateToDate = (quickrange: QuickRange): Date => {
  const now = new Date();

  const dateAdditions: Record<QuickRange, (date: Date) => Date> = {
    [QuickRange.OneDay]: (date) => new Date(date.setDate(date.getDate() + 1)),
    [QuickRange.ThreeDays]: (date) =>
      new Date(date.setDate(date.getDate() + 3)),
    [QuickRange.OneWeek]: (date) => new Date(date.setDate(date.getDate() + 7)),
    [QuickRange.TwoWeeks]: (date) =>
      new Date(date.setDate(date.getDate() + 14)),
    [QuickRange.ThreeWeeks]: (date) =>
      new Date(date.setDate(date.getDate() + 21)),
    [QuickRange.OneMonth]: (date) =>
      new Date(date.setMonth(date.getMonth() + 1)),
    [QuickRange.TwoMonths]: (date) =>
      new Date(date.setMonth(date.getMonth() + 2)),
    [QuickRange.ThreeMonths]: (date) =>
      new Date(date.setMonth(date.getMonth() + 3))
  };

  return dateAdditions[quickrange]?.(now) || now;
};

export const isFetching = (status: {
  [key: string]: string | null;
}): boolean => {
  return (
    Object.values(status).includes("loading") ||
    Object.values(status).some((value) => value !== "end")
  );
};
