import { connect } from "react-redux";
import { ConstellationSatelliteSelector } from "../components/ConstellationSatelliteSelector";
import { withRouter } from "react-router-dom";
import { setSatelliteVisibility } from "../services";
import { getConstellations, getConstellation } from "app/constellation";
import { getSatelliteDefinition } from "app/satelliteDefinition";
import { getUserDashboards, addDashboards } from "app/dashboard/services";

const mapStateToProps = (state) => ({
  constellations: state.constellations,
  dashboard: state.constellations.dashboard,
  selectedSatellite: state.constellations.dashboard.find(
    (satellite) => satellite.visible
  )
});

const mapDispatchToProps = () => {
  return {
    addDashboards: (dashboards) => addDashboards(dashboards),
    getConstellations: () => getConstellations(),
    getConstellation: (id) => getConstellation(id),
    getUserDashboards: (satelliteDefinitionIds) =>
      getUserDashboards(satelliteDefinitionIds),
    setSatelliteVisibility: (id) => setSatelliteVisibility(id),
    getSatelliteDefinition: (id) => getSatelliteDefinition(id)
  };
};

export const ConstellationSatelliteSelectorContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConstellationSatelliteSelector)
);
