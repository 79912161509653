import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgArrowLeft = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 5 9"
    color={color}
  >
    <path
      d="M.128 4.913v-.817L5 .044v.817L1.016 4.505 5 8.184V9z"
      fillRule="evenodd"
    />
  </Svg>
);

SvgArrowLeft.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgArrowLeft;
