import React from "react";

import { Box, Flex, Icon } from "primitives";
import { AlertConfig, IconsMap } from "../models";

type AlertNotificationPoliciesProps = {
  alertConfigs?: AlertConfig[];
};

type AlertNotificationPolicyProps = {
  policy: string;
};

export const NotificationPolicies = ({
  alertConfigs
}: AlertNotificationPoliciesProps) => {
  return (
    <>
      {Array.isArray(alertConfigs) &&
        alertConfigs.map((c: AlertConfig, idx: number) => {
          return (
            <div>
              <NotificationPolicy policy={c.notificationPolicy} key={idx} />
            </div>
          );
        })}
    </>
  );
};

const NotificationPolicy = ({ policy }: AlertNotificationPolicyProps) => {
  return (
    <Flex padding={"10px 0"}>
      {policy.split(",").map((p: string) => {
        const iconName = IconsMap[p as any];
        return (
          <Box paddingLeft={1}>
            {iconName && <Icon name={iconName} size={20} />}
          </Box>
        );
      })}
    </Flex>
  );
};
