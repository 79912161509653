import React from "react";
import { Flex, Text } from "primitives";
import { FlexLayoutProps } from "primitives/Flex";
import { backgroundColorOpacity } from "primitives/Box";

import styled, { css } from "styled-components";
import { themeGet } from "styled-system";

interface ItemMenuProps extends FlexLayoutProps {
  active?: boolean;
  children: React.ReactNode;
}

interface TitleMenuProps {
  children: React.ReactNode;
}

const TitleMenu = ({ children }: TitleMenuProps) => (
  <Flex overflow="visible" flex="1 0 auto">
    <Text fontSize={4} bold caps>
      {children}
    </Text>
  </Flex>
);

/** IconMenu */
const activeIcon = () =>
  css`
    border-color: ${themeGet("colors.palette.white.0")};
  `;

const IconMenu = styled(Flex)<ItemMenuProps>`
  ${(props) => props.active && activeIcon};
`;

IconMenu.defaultProps = {
  flex: "0 0 48px",
  height: "100%",
  borderLeft: 5,
  borderColor: "transparent",
  justifyContent: "center",
  alignItems: "center",
  pl: 1
};
/** --- */

/** ItemMenu */
const active = (props: ItemMenuProps) =>
  css`
    color: ${themeGet("colors.text.white")};
    ${backgroundColorOpacity({ ...props, bgOpacity: 1 })}
  `;

const ItemMenu = styled(Flex)<ItemMenuProps>`
  &:hover {
    ${active}

    ${IconMenu} {
      ${activeIcon}
    }
  }
  ${(props) => props.active && active}
`;

ItemMenu.defaultProps = {
  fontSize: 2,
  color: "text.default",
  alignItems: "center",
  height: 33,
  minHeight: 33,
  mx: -3,
  bg: "palette.brand.0",
  bgOpacity: 0,
  pr: 3,
  position: "relative",
  mb: 2
};

export { ItemMenu, IconMenu, TitleMenu };
