import React, { Component } from "react";
import { getFrameDefinition } from "../services";
import { updateFrameDefinition } from "../services";
import { SaveButton, InputField } from "components";

/**
 *  FrameDefinitionEdit component
 *  Provided a satellite definition,
 *  the component updates the frame definition.
 */
export class FrameDefinitionEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: {}
    };
  }

  componentDidMount() {
    const { record } = this.props;

    getFrameDefinition(record)
      .then(({ data }) => {
        this.setState({ data, loading: false });
      })
      .catch((error) => {
        // no frame definition
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  onChange = (event) => {
    const { value } = event.currentTarget;
    const { data } = this.state;
    this.setState({ data: { ...data, frameDefinition: value } });
  };

  submit = (e) => {
    e.preventDefault();
    const { data } = this.state;
    const { record } = this.props;
    updateFrameDefinition(data, record).then(() => {
      // this.props.history.push("/constellation");
    });
  };

  render() {
    return (
      <>
        <form
          onSubmit={(e) => this.submit(e)}
          data-testid="FrameDefinitionEdit"
        >
          {this.state.data && (
            <InputField
              name="frameDefinitionId"
              defaultValue={this.state.data.id}
              type="hidden"
              label=""
            />
          )}
          <InputField
            multiline={true}
            rows="10"
            name="frameDefinition"
            label="Frame definition"
            value={this.state.data.frameDefinition}
            onChange={(e) => this.onChange(e)}
            style={{ width: "100%" }}
            InputLabelProps={{
              shrink: true
            }}
          />
          <SaveButton
            type="submit"
            mt={2}
            data-testid="FrameDefinitionEditSaveButton"
          >
            Save Frame Definition
          </SaveButton>
        </form>
      </>
    );
  }
}
