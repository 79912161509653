import {
  FETCH_SATELLITE_START,
  FETCH_SATELLITE_SUCCESS,
  SELECT_SYSTEM,
  SELECT_DATASOURCES
} from "../models/constants";

import { FETCH_SATELLITE_DEFINITION_SUCCESS } from "app/satelliteDefinition/models/constants";
import { FETCH_READING_SUCCESS } from "app/dataSource/models/constants";

const initialState = {
  satellite: null,
  system: null,
  satelliteDefinition: [],
  dataSources: [],
  selectedDataSources: []
};

export const satelliteDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SATELLITE_START:
      return initialState;
    case FETCH_SATELLITE_SUCCESS:
      return {
        ...state,
        satellite: action.payload
      };
    case FETCH_SATELLITE_DEFINITION_SUCCESS:
      return {
        ...state,
        satelliteDefinition: action.payload
      };
    case SELECT_SYSTEM:
      return {
        ...state,
        system: action.payload,
        dataSources: []
      };
    case FETCH_READING_SUCCESS:
      return {
        ...state,
        dataSources: action.payload.data
      };

    case SELECT_DATASOURCES:
      return {
        ...state,
        selectedDataSources: action.payload
      };
    default:
      return state;
  }
};
