import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { TableCell } from "components";
import { Passage, NextPassageDuration } from "app/visibilityWindow/models";
import { getNextPassageRemainingTime } from "app/visibilityWindow/helpers";

interface IPassageRemainingTimeCell {
  passage: Passage;
  serverTime: Date;
}

const PassageRemainingTimeCellBase = ({
  passage,
  serverTime
}: IPassageRemainingTimeCell) => {
  const passageRemainingTime: NextPassageDuration = getNextPassageRemainingTime(
    passage,
    serverTime
  );

  return (
    <TableCell>
      {passageRemainingTime && passageRemainingTime.durationMinutes > 0
        ? passageRemainingTime.formatedDuration
        : moment(serverTime).isAfter(moment(passage.los))
        ? "Done"
        : "Running"}
    </TableCell>
  );
};

const mapStateToProps = (state: any) => {
  return {
    serverTime: state.visibilityWindow.serverTime
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const PassageRemainingTimeCell = connect(
  mapStateToProps,
  mapDispatchToProps
)(PassageRemainingTimeCellBase);
