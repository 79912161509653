/**
 * Exports available datasource data types
 */
export const DataType = {
  string: "string",
  int8: "int8",
  uint8: "uint8",
  int16: "int16",
  uint16: "uint16",
  int32: "int32",
  uint32: "uint32",
  int64: "int64",
  uint64: "uint64",
  float: "float",
  double: "double",
  boolean: "boolean"
};

/**
 * Exports int data types
 */
export const DataTypeInts = [
  DataType.int8,
  DataType.int16,
  DataType.int32,
  DataType.int64
];

/**
 * Exports unsigned int data types
 */
export const DataTypeUints = [
  DataType.uint8,
  DataType.uint16,
  DataType.uint32,
  DataType.uint64
];

/**
 * Exports numbers data types
 */
export const DataTypeNumbers = [
  ...DataTypeInts,
  ...DataTypeUints,
  DataType.float,
  DataType.double
];

export interface Alert {
  id: number;
  dataSourceId: number;
  valueBoundary: {
    minValue: number;
    maxValue: number;
  };
  alertLevels: {
    alertSeverity: "Critical" | "Warning";
    lowerThreshold: number;
    upperThreshold: number;
  };
}

export interface SimpleAlert {
  alertType: "SimpleAlert";
  valueBoundary: {
    minValue: number;
    maxValue: number;
  };
  alertLevels: [AlertLevel];
}

export interface ArrayAlert {
  alertType: "ArrayAlert";
  indexes: [AlertIndex];
}

export interface AlertIndex {
  index: number;
  valueBoundary: {
    minValue: number;
    maxValue: number;
  };
  alertLevels: [AlertLevel];
}

export interface AlertLevel {
  severity: string;
  lowerThreshold: number;
  upperThreshold: number;
}

export interface DataSource {
  id: number;
  name: string;
  fullyQualifiedName?: string;
  description: string;
  category: string;
  tmDataType: {
    type: string;
    arraySize: number;
  };
  units?: {
    unit: string;
  };
  alertId: object | number | null;
}

/**
 * Export all components. This is useful to interact with all choices.
 */
export const DataTypeAll = Object.values(DataType);

/**
 * Export alert levels
 */

export const AlertLevels = ["Warning", "Critical"];
export const AlertLevelsWarning = "Warning";
export const AlertLevelsCritical = "Critical";
