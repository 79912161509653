import React from "react";
import { Button, Heading, Flex, Icon, Text } from "primitives";
import styled, { keyframes } from "styled-components";
import palette from "styles/palette";

// Animations definintion
const slideInAnimation = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOutAnimation = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;

const Container = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  right: ${({ visible }) => (visible ? "0" : "-100%")};
  transition: ${({ visible }) => (visible ? "right 0s" : "right 0.5s")};
  width: 60%;
  height: 100%;
  background-color: ${palette.palette.blue[3]};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  animation: ${({ visible }) =>
      visible ? slideInAnimation : slideOutAnimation}
    0.5s forwards;
  padding: 10px 20px;
  cursor: default;
`;

const Root = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  cursor: pointer;
  z-index: 999;
`;

const ChildrenContainer = styled("div")`
  flex: 1;
  overflow: auto;
  max-height: 85%;
  & > div {
    height: 100%;
  }
`;

const InteractiveLayer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

interface NavigationDrawerProps {
  visible: boolean;
  onClose: () => void;
  heading?: string;
}

export const NavigationDrawer = ({
  visible,
  onClose,
  heading,
  children
}: React.PropsWithChildren<NavigationDrawerProps>) => {
  return (
    <Root visible={visible}>
      <InteractiveLayer onClick={onClose} />
      <Container visible={visible}>
        <Flex alignItems={"center"} mt={4} mb={6}>
          <Button mr={3} onClick={onClose}>
            <Text mr={2}>Close</Text>
            <Icon name="ArrowRight" size={15} />
          </Button>
          <Heading display={1}>{heading}</Heading>
        </Flex>
        <ChildrenContainer>
          {children || "Set a children for the NavigationDrawer"}
        </ChildrenContainer>
      </Container>
    </Root>
  );
};
