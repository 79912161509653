import { SelectedSatellite } from "app/satelliteDefinition/models";
import React, { Dispatch } from "react";
import { ThunkAction } from "redux-thunk";
import {
  StatusIndicatorTheme,
  ISODateString
} from "components/CountDown/models";

/**
 * Entities
 */
export interface MasterKey {
  key_id: string;
  status: KeyStatus;
  label: string;
  parent_key_id: string | null;
  key_type: KeyTypes;
}

export enum KeyStatus {
  PRE_OPERATIONAL = "PRE_OPERATIONAL",
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
  DEACTIVATED = "DEACTIVATED",
  DESTROYED = "DESTROYED"
}

export enum Channels {
  SUL = "SUL",
  SDL = "SDL",
  XDL = "XDL"
}

export enum FunctionalCategories {
  TEK = "TEK",
  TAK = "TAK"
}

export enum KeyTypes {
  master = "MASTER",
  session = "SESSION"
}

export interface SessionKey {
  key_id: string;
  status: KeyStatus;
  parent_key_id: string;
  activated_at: ISODateString | null;
  expires_at: ISODateString | null;
  channel: Channels | null;
  category: FunctionalCategories | null;
  key_type: KeyTypes;
}

export type { ISODateString } from "components/CountDown/models";
export { StatusIndicatorTheme } from "components/CountDown/models";

export type KeyStatusMap = {
  [key in keyof typeof KeyStatus]: {
    color: string;
    name: string;
    endpoint?: string;
    actionName?: string;
  };
};

export type ChannelMaps = {
  [key in keyof typeof Channels]: {
    label: string;
    bg: string;
  };
};

export type FunctionalCategoriesMaps = {
  [key in keyof typeof FunctionalCategories]: {
    label: string;
  };
};

export type ChannelsName = keyof typeof Channels;
export type FunctionalCategoriesName = keyof typeof FunctionalCategories;

export type InjectionSettings = {
  channels: Channels | "";
};

/**
 * Components
 */
// PROPS
export interface MasterKeyListProps {
  masterKeys: MasterKey[];
}

export interface KeyManagerProps {
  masterKeys: MasterKey[];
  fetchAllMasterKey: (satelliteID: number) => Promise<MasterKey[] | null>;
  fetchSessionKeys: (
    satelliteID: number,
    params: SessionKeysFromMaster
  ) => Promise<SessionKey[] | null>;
  selectedSatellite: SelectedSatellite;
}

export interface ContextMenuProps {
  x: number;
  y: number;
}

export interface SessionKeyContextMenuProps {
  onClose: () => void;
  data: SessionKey;
  setSessionKeyState: (
    satelliteID: number,
    params: SessionKeyRequestParams
  ) => SetSessionKeyStateResponse;
  selectedSatellite: SelectedSatellite;
}

export interface KeyStateIndicatorProps {
  status: KeyStatus;
  expires_at?: ISODateString;
  theme?: keyof typeof StatusIndicatorTheme;
}

export interface MasterKeyItemProps extends MasterKey {
  checked: boolean;
}

export interface MasterKeyDetailProps {
  selectedSatellite: SelectedSatellite;
  deriveSessionKey: (
    satelliteID: number,
    params: DeriveSessionKeysParams
  ) => DeriveSessionKeyResponse;
  sessionKeysMap: SessionKeysMap;
}

export interface ChangeStateDialog {
  key: MasterKey | SessionKey;
  isOpen: boolean;
  setOpen: Dispatch<IContextActions>;
  state: KeyStatus;
}

export interface ChangeStateDialogProps {
  selectedSatellite: SelectedSatellite;
  setMasterKeyState: (
    satelliteID: number,
    params: MasterKeyRequestParams
  ) => SetMasterKeyStateResponse;
  setSessionKeyState: (
    satelliteID: number,
    params: SessionKeyRequestParams
  ) => SetSessionKeyStateResponse;
}

export interface InjectSessionKeyDialogProps {
  selectedSatellite: SelectedSatellite;
  fetchSessionKey: (
    satelliteID: number,
    masterKeyId: string,
    sessionKeyID: string
  ) => FetchSessionKeyResponse;
}

// STATE
export interface ContextMenuState {
  coord: {
    x: number;
    y: number;
  };
  content: React.ReactNode;
}

export interface SessionKeysMap {
  [key: string]: SessionKey[];
}

export interface IContext {
  selectedSessionKey: SessionKey[];
  selectedMasterKey: MasterKey | null;
  contextMenu: React.ReactNode | null;
  changeStateDialog: ChangeStateDialog | null;
  applyedFilters: {
    sessionKey: {
      channels: ChannelsName[];
      functionalCategory: FunctionalCategoriesName[];
    };
  };
  injectSessionKeyDialog: Omit<ChangeStateDialog, "state"> | null;
}

export enum ContextActionsNames {
  selectedSessionKey = "selectedSessionKey",
  selectedMasterKey = "selectedMasterKey",
  contextMenu = "contextMenu",
  changeStateDialog = "changeStateDialog",
  applyedFilters = "applyedFilters",
  injectSessionKeyDialog = "injectSessionKeyDialog"
}

export type IContextActions = {
  type?: ContextActionsNames;
  payload: Partial<IContext> | IContext[keyof IContext];
};

/**
 * Redux
 */
export interface KeyManagerStoreState {
  masterKeys: MasterKey[];
  sessionKeysMap: SessionKeysMap;
}

export enum KeyManagerActionType {
  FetchMasterKey = "FETCH_MASTER_KEY",
  FetchAllMasterKey = "FETCH_ALL_MASTER_KEY",
  FetchSessionKey = "FETCH_SESSION_KEY",
  FetchAllSessionKey = "FETCH_ALL_SESSION_KEY",
  SetSessionKeyState = "SET_SESSION_KEY_STATE",
  DeriveSessionKey = "DERIVE_SESSION_KEY",
  SetMasterKeyState = "SET_MASTER_KEY_STATE"
}

export interface FetchMasterKeyAction {
  type: KeyManagerActionType.FetchMasterKey;
  payload: MasterKey | null;
}

export interface FetchAllMasterKeyAction {
  type: KeyManagerActionType.FetchAllMasterKey;
  payload: MasterKey[] | null;
}
export interface FetchAllSessionKeyAction {
  type: KeyManagerActionType.FetchAllSessionKey;
  payload: SessionKeysMap | null;
}

export interface FetchSessionKeyAction {
  type: KeyManagerActionType.FetchSessionKey;
  payload: { sessionKey: SessionKey | null; masterKeyId: string };
}
export interface SetSessionKeyStateAction {
  type: KeyManagerActionType.SetSessionKeyState;
  payload: SessionKeyRequestParams | null;
}

export interface DeriveSessionKeyAction {
  type: KeyManagerActionType.DeriveSessionKey;
  payload: DeriveSessionKeyActionPayload | null;
}

export interface SetMasterKeyStateAction {
  type: KeyManagerActionType.SetMasterKeyState;
  payload: MasterKeyRequestParams | null;
}

export type FetchMasterKeyActionResult = ThunkAction<
  Promise<MasterKey | ErrorResponse | null>,
  {},
  {},
  FetchMasterKeyAction
>;

export type FetchAllMasterKeyActionResult = ThunkAction<
  Promise<MasterKey[] | null>,
  {},
  {},
  FetchAllMasterKeyAction
>;

export type FetchAllSessionKeyActionResult = ThunkAction<
  Promise<SessionKey[] | null>,
  {},
  {},
  FetchAllSessionKeyAction
>;

export type FetchSessionKeyActionResult = ThunkAction<
  Promise<SessionKey | null>,
  {},
  {},
  FetchSessionKeyAction
>;

export type SetSessionKeyStateResult = ThunkAction<
  SetSessionKeyStateResponse,
  {},
  {},
  SetSessionKeyStateAction
>;

export type DeriveSessionKeyResult = ThunkAction<
  DeriveSessionKeyResponse,
  {},
  {},
  DeriveSessionKeyAction
>;

export type SetMasterKeyStateResult = ThunkAction<
  SetMasterKeyStateResponse,
  {},
  {},
  SetMasterKeyStateAction
>;

export interface SuccessResponse {
  data: any;
  status: number;
}
export interface MessageResponse {
  message: string;
}
export interface ErrorResponse {
  detail: [
    {
      loc: ["string", 0];
      msg: "string";
      type: "string";
    }
  ];
}

export interface SessionKeyDerivationResponse {
  data: SessionKey[];
  status: number;
}

export type SetSessionKeyStateResponse = Promise<
  MessageResponse | SuccessResponse | ErrorResponse | null
>;

export type SetMasterKeyStateResponse = Promise<
  MessageResponse | SuccessResponse | ErrorResponse | null
>;

export type InjectSessionKeyResponse = Promise<
  MessageResponse | SuccessResponse | ErrorResponse | null
>;

export type FetchSessionKeyResponse = Promise<SessionKey | null>;

export type DeriveSessionKeyResponse = Promise<
  SessionKeyDerivationResponse | ErrorResponse | MessageResponse | null
>;

export type DeriveSessionKeyActionPayload = {
  sessionKeys: SessionKey[];
  masterKey: string;
};
export type KeyManagerActions =
  | FetchMasterKeyAction
  | FetchAllMasterKeyAction
  | FetchAllSessionKeyAction
  | SetSessionKeyStateAction
  | DeriveSessionKeyAction
  | SetMasterKeyStateAction
  | FetchSessionKeyAction;

/**
 * Requests
 */
export interface SessionKeysFromMaster {
  master_key_id: string;
}

export interface SessionKeyRequestParams {
  master_key_id: string;
  session_key_id: string;
  state: KeyStatus;
}

export interface DeriveSessionKeysParams {
  master_key_id: string;
  num_keys: number;
}

export interface MasterKeyRequestParams {
  master_key_id: string;
  state: KeyStatus;
}
export interface InjectSessionKeyRequestParams {
  session_key_id: string;
  settings: InjectionSettings;
}
