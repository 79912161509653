import { MultiCheckBox } from "components";

const visibilityWindowListConfigOptionsSchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "telecommandsExecution.schema.json",
  title: "Aurora TelecommandsExecution",
  description:
    "The configuration options for the Aurora's TelecommandsExecution component",
  type: "object",
  definitions: {
    satellites: {
      $id: "#/properties/header/properties/satellites",
      title: "Satellites",
      description:
        "Show passages from these satellites. If none are selected this widget will show passages from the active sallite.",
      type: "array",
      default: [],
      items: {
        $id: "#/properties/header/properties/show/items",
        type: "number",
        enum: [-1],
        enumNames: []
      },
      uniqueItems: true
    }
  },
  properties: {
    satellites: {
      $ref: "#/definitions/satellites"
    }
  }
};

const visibilityWindowListConfigOptionsDefault = {
  satellites: []
};

const visibilityWindowListConfigOptionsUISchema = {
  satellites: { "ui:field": MultiCheckBox }
};

export {
  visibilityWindowListConfigOptionsSchema,
  visibilityWindowListConfigOptionsDefault,
  visibilityWindowListConfigOptionsUISchema
};
