import React, { useState, useRef } from "react";
import { Tooltip, CheckBox } from "components";
import "./TooltipMenu.css";
import { Box, Icon, Flex, Button, Text } from "primitives";
import styled from "styled-components";
import palette from "styles/palette";

const Counter = styled("div")`
  position: absolute;
  left: -10px;
  top: -10px;
  background-color: ${palette.palette.grey[5]};
  border-radius: 100%;
  width: 20px;
  height: 20px;
`;

interface DropDownProps {
  options: Array<{ id: string; name: string }>;
  setShowTooltip: Function;
  onSelect: Function;
  minWidth: number;
  submit?: boolean;
  form?: string;
}

const DropDown = ({
  options,
  setShowTooltip,
  onSelect,
  minWidth,
  submit,
  form
}: DropDownProps) => {
  const onClick = (selectedOption: { id: string; name: string }) => {
    onSelect(selectedOption);
    setShowTooltip(false);
    if (submit && form) {
      const submitButton = document.getElementById(
        "submitButton"
      ) as HTMLElement;
      submitButton.click();
    }
  };
  return (
    <>
      {submit && form ? (
        <Button
          id="submitButton"
          type="submit"
          form={form}
          className="submitButton"
        ></Button>
      ) : (
        ""
      )}
      {options.map((option: { id: string; name: string }) => {
        return (
          <Box
            className="item"
            key={option.id}
            maxWidth={250}
            minWidth={minWidth * 0.8}
            onClick={() => onClick(option)}
          >
            <div className="container">
              <Text cursor="pointer">{option.name}</Text>
            </div>
          </Box>
        );
      })}
    </>
  );
};

interface CheckListProps {
  options: Array<{ id: string; name: string }>;
  onConfirm: (checked: string[]) => void;
  onUndo: Function;
  minWidth: number;
  selected: string[];
}

const CheckList = ({
  options,
  onConfirm,
  onUndo,
  minWidth,
  selected
}: CheckListProps) => {
  const [checked, setChecked] = useState<string[]>(selected);

  const handleOnChange = (optId: string) => {
    if (checked.includes(optId)) {
      setChecked([...checked.filter((c) => c !== optId)]);
    } else {
      setChecked([...checked, optId]);
    }
  };

  return (
    <Box
      maxWidth={250}
      minWidth={minWidth * 0.8}
      className={"tooltip-menu--checklist"}
    >
      {options.map((opt, i) => (
        <Flex key={i} alignItems={"center"}>
          <Box>
            <CheckBox
              id={`tooltip-menu-item-${opt.id}`}
              onChange={() => handleOnChange(opt.id)}
              checked={checked.includes(opt.id)}
              value={opt.id}
              m={0}
            />
          </Box>
          <label htmlFor={`tooltip-menu-item-${opt.id}`}>{opt.name}</label>
        </Flex>
      ))}
      <Flex justifyContent={"flex-end"} mt={3}>
        <Text onClick={() => onUndo()} mr={1} className={"tooltip-menu--cta"}>
          Close
        </Text>
        <Text
          onClick={() => onConfirm(checked)}
          className={"tooltip-menu--cta"}
        >
          Apply
        </Text>
      </Flex>
    </Box>
  );
};

interface TooltipMenuProps {
  id?: string;
  title: string;
  options: Array<{ id: string; name: string }>;
  onSelect: Function;
  placement?: string;
  icon?: boolean;
  hideArrow?: boolean;
  disabled?: boolean;
  submit?: boolean;
  form?: string;
  type?: "dropdown" | "checklist";
  selected?: string[];
}

const TooltipMenu = ({
  id = "",
  title,
  options,
  onSelect,
  placement = "bottom-start",
  icon = true,
  hideArrow = true,
  disabled = false,
  submit = false,
  form = "",
  type = "dropdown",
  selected = []
}: TooltipMenuProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const ref = useRef(null as HTMLDivElement | null);

  return (
    <Tooltip
      m={
        placement.includes("bottom")
          ? "mb"
          : placement.includes("top")
          ? "mt"
          : (placement.includes("right") || placement.includes("left")) &&
            placement.includes("start")
          ? "mlr-start"
          : placement.includes("right") || placement.includes("left")
          ? "mlr"
          : "m-auto"
      }
      id={id}
      hideArrow={hideArrow}
      delayHide={500}
      delayShow={500}
      tooltipShown={showTooltip}
      placement={placement}
      trigger="click"
      tooltip={
        (type === "checklist" && (
          <CheckList
            selected={selected}
            minWidth={ref.current ? ref.current.offsetWidth : 0}
            onUndo={() => setShowTooltip(!showTooltip)}
            onConfirm={(checked) => {
              setShowTooltip(!showTooltip);
              onSelect(checked);
            }}
            options={options}
          />
        )) || (
          <DropDown
            minWidth={ref.current ? ref.current.offsetWidth : 0}
            onSelect={onSelect}
            setShowTooltip={setShowTooltip}
            options={options}
            submit={submit}
            form={form}
          ></DropDown>
        )
      }
    >
      <div className="divRef" ref={ref}>
        <Button
          ml={2}
          mr={2}
          disabled={disabled}
          position={"relative"}
          onClick={() => setShowTooltip(!showTooltip)}
        >
          {type === "checklist" && selected.length > 0 && (
            <Counter>{selected.length}</Counter>
          )}
          {icon && placement.includes("left") ? (
            <Flex mr={2}>
              <Icon name="ArrowLeft" size={14} />
            </Flex>
          ) : (
            ""
          )}
          <span>{title}</span>
          {icon &&
          (placement.includes("bottom") ||
            placement.includes("top") ||
            placement.includes("right")) ? (
            <Flex ml={2}>
              <Icon
                name={
                  placement.includes("bottom")
                    ? "ArrowDown"
                    : placement.includes("top")
                    ? "ArrowUp"
                    : placement.includes("right")
                    ? "ArrowRight"
                    : ""
                }
                size={14}
              />
            </Flex>
          ) : (
            ""
          )}
        </Button>
      </div>
    </Tooltip>
  );
};

export default TooltipMenu;
