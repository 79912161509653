import { GET_LIST } from "app/network";
import { passageManagerDataProvider } from "app/network/dataProvider";

// TODO: until fix past date issue is fixed on LEAF/BE
const TWO_HOURS_IN_MS = 2 * 60 * 60 * 1000;
const THREE_WEEKS_IN_MS = 3 * 7 * 24 * 60 * 60 * 1000;

export const defaultStartTime = new Date(
  Date.now() + TWO_HOURS_IN_MS
).toISOString();
const defaultEndTime = new Date(Date.now() + THREE_WEEKS_IN_MS).toISOString();

export const getPassageCandidatesForConfig = (
  configId: any,
  startTime?: string,
  endTime?: string
) => {
  const params = new URLSearchParams({
    availability: "available",
    bookingConfigurationID: configId.toString(),
    startTime: startTime || defaultStartTime,
    endTime: endTime || defaultEndTime
  });

  const query = `candidates?${params.toString()}`;

  return passageManagerDataProvider(GET_LIST, query);
};
