import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgLocation = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 7.7 11"
    color={color}
  >
    <g>
      <path d="M3.85-.005A3.855 3.855 0 0 0 0 3.845c0 1.949 3.85 7.15 3.85 7.15s3.85-5.2 3.85-7.15a3.854 3.854 0 0 0-3.85-3.85zM1.1 3.845a2.75 2.75 0 0 1 5.5 0c0 1.059-1.54 3.591-2.75 5.3C2.641 7.441 1.1 4.91 1.1 3.85z" />
      <path d="M3.85 2.195a1.65 1.65 0 1 0 1.65 1.65 1.652 1.652 0 0 0-1.65-1.65zm0 2.2a.55.55 0 1 1 .55-.55.551.551 0 0 1-.55.555z" />
    </g>
  </Svg>
);

SvgLocation.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgLocation;
