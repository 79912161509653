import React from "react";
import UplotReact from "uplot-react";
import "uplot/dist/uPlot.min.css";
import { GraphDataModel } from "../shared";
import { generateOptions } from "./utils";
import { Lazy } from "app/shared/lazyload";

export class LineChartNew {
  model: GraphDataModel;
  ref: any;

  constructor(model: GraphDataModel, ref: any = {}) {
    this.model = model;
    this.ref = ref;
  }

  draw = () => {
    const { data } = this.model.getLineChartData();
    const options = generateOptions(this.model);

    return (
      <Lazy>
        <UplotReact options={options} data={data} target={this.ref} />
      </Lazy>
    );
  };
}
