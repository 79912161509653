import React, { SFC } from "react";
import moment from "moment";

interface DateTimeProps {
  unixTimestampSec: number;
  outputFormat?: "epoch" | "sec";
  options?: { format: string };
}

export const DateTimeComponent: SFC<DateTimeProps> = ({
  unixTimestampSec,
  outputFormat,
  options
}) => {
  let timestamp = `${unixTimestampSec}`;
  const unixTsMillis = unixTimestampSec * 1000;
  switch (outputFormat) {
    case "epoch": {
      const epochFormat = (options && options.format) || "DD/MM/YYYY @ hh:mm";
      timestamp = moment(unixTsMillis).format(epochFormat);
      break;
    }
    case "sec": {
      timestamp = moment(unixTsMillis).format("hh:mm:ss");
      break;
    }
  }

  return (
    <div className={"dateTimeComponent"} data-testid="DateTimeComponent">
      {timestamp}
    </div>
  );
};
