import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgArrowUp = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 8.956 4.872"
    color={color}
  >
    <g>
      <g>
        <path d="M4.088 0h.816l4.052 4.872h-.815L4.496.888.816 4.872H0z" />
      </g>
    </g>
  </Svg>
);

SvgArrowUp.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgArrowUp;
