import {
  AuroraTelecommandPayload,
  TelecommandFormActionTypes
} from "../models";
import {
  flattenFormData,
  buildLocalTelecommandSpec,
  removeNullValues,
  toCheckOrParseData
} from "../utils/index";
import { useReducer } from "react";
import { TelecommandStack } from "app/telecommandStack/models";
import { clone } from "utils";

export const initialState = {
  formData: null,
  loading: false,
  hideDefaultFields: true,
  showDefaultFieldsVisualizationSwitch: true,
  telecommandSpecState: null,
  modalOpen: false,
  selectedTelecommandStackId: null,
  organization: ""
};

export function useFormState() {
  const [state, dispatch] = useReducer(
    telecommandExecutionFormReducer,
    initialState
  );

  const actions = {
    setInitialState: () =>
      dispatch({
        type: TelecommandFormActionTypes.setInitialState
      }),
    setFormData: (telecommandSpec: any, data: any) =>
      dispatch({
        type: TelecommandFormActionTypes.setFormData,
        telecommandSpec,
        data
      }),
    setDefaultFields: (
      hideDefaultFields: unknown,
      showDefaultFieldsVisualizationSwitch: unknown
    ) =>
      dispatch({
        type: TelecommandFormActionTypes.setDefaultFields,
        hideDefaultFields,
        showDefaultFieldsVisualizationSwitch
      }),

    setDefaultView: (defaultFieldsVisualization: unknown) => {
      if (defaultFieldsVisualization) {
        switch (defaultFieldsVisualization) {
          case "visible":
            dispatch({
              type: TelecommandFormActionTypes.setDefaultFields,
              hideDefaultFields: false,
              showDefaultFieldsVisualizationSwitch: false
            });
            break;
          case "hidden":
            dispatch({
              type: TelecommandFormActionTypes.setDefaultFields,
              hideDefaultFields: true,
              showDefaultFieldsVisualizationSwitch: false
            });
            break;
          case "defaultVisible":
            dispatch({
              type: TelecommandFormActionTypes.setDefaultFields,
              hideDefaultFields: false,
              showDefaultFieldsVisualizationSwitch: true
            });
            break;
          case "defaultHidden":
            dispatch({
              type: TelecommandFormActionTypes.setDefaultFields,
              hideDefaultFields: true,
              showDefaultFieldsVisualizationSwitch: true
            });
            break;
          default:
            break;
        }
      }
    },
    setTelecommandSpecState: (
      telecommandSpec: any,
      formData: any,
      automaticFill: any
    ) => {
      return dispatch({
        type: TelecommandFormActionTypes.setTelecommandSpecState,
        telecommandSpec,
        formData,
        automaticFill
      });
    },

    setSelectedTelecommandStackId: (tcStackId: string) =>
      dispatch({
        type: TelecommandFormActionTypes.setSelectedTelecommandStackId,
        tcStackId
      }),
    setLoading: (loading: boolean) =>
      dispatch({ type: TelecommandFormActionTypes.setLoading, loading }),
    setModalOpen: (modalOpen: boolean) =>
      dispatch({ type: TelecommandFormActionTypes.setModalOpen, modalOpen }),
    toggleHideConstants: (
      checked: boolean,
      showDefaultFieldsVisualizationSwitch: unknown
    ) =>
      dispatch({
        type: TelecommandFormActionTypes.setDefaultFields,
        hideDefaultFields: checked,
        showDefaultFieldsVisualizationSwitch:
          showDefaultFieldsVisualizationSwitch
      }),
    onFormSubmitWithConfirmation: (e: any) => {
      e.preventDefault();
      return dispatch({
        type: TelecommandFormActionTypes.setModalOpen,
        modalOpen: true
      });
    },

    onFormSubmit: (
      data: any,
      e: any,
      telecommandSpec: any,
      onSubmitTelecommandHandler: any,
      preventMultiSend: any
    ) => {
      // eslint-disable-next-line no-shadow
      const modifiedData = {
        ...data,
        formData: {}
      };

      toCheckOrParseData(modifiedData, data, telecommandSpec);

      actions.setLoading(true);
      actions.setModalOpen(false);

      try {
        const cleanedModifiedData = removeNullValues(clone(modifiedData));
        onSubmitTelecommandHandler(cleanedModifiedData);
        if (!preventMultiSend) {
          setTimeout(() => {
            actions.setLoading(false);
          }, 1000);
        }
      } catch (err2) {
        // eslint-disable-next-line no-console
        console.log(err2);
        actions.setLoading(false);
      }
      e && e.preventDefault();
    },

    onSendToStack: async (
      data: any,
      e: any,
      telecommandSpec: any,
      selectedPassage: any,
      telecommandStacks: any,
      selectedTelecommandStackId: any,
      addToStack: any
    ) => {
      // eslint-disable-next-line no-shadow
      const modifiedData = {
        ...data,
        formData: {}
      };

      toCheckOrParseData(modifiedData, data, telecommandSpec);

      const cleanedModifiedData = removeNullValues(modifiedData);
      const telecommandExecutionPayload = AuroraTelecommandPayload.from(
        clone(telecommandSpec),
        cleanedModifiedData.formData
          ? clone(cleanedModifiedData.formData)
          : data
      );
      if (selectedPassage && selectedPassage.passageID) {
        telecommandExecutionPayload.setPassageId(selectedPassage.passageID);
      }
      const payload = telecommandExecutionPayload.toOutputModel();

      const selectedTelecommandStack = telecommandStacks.telecommandStacks.find(
        (i: TelecommandStack) => i.id === selectedTelecommandStackId
      );
      // const removed= selectedTelecommandStack.telecommandList
      addToStack(payload, selectedTelecommandStack);

      e && e.preventDefault();
    }
  };

  return { state, actions };
}

const telecommandExecutionFormReducer = (state: any, action: any) => {
  switch (action.type) {
    case TelecommandFormActionTypes.setFormData: {
      if (!action.telecommandSpec) {
        return {
          ...state,
          formData: action.data
        };
      } else {
        const newFormData = flattenFormData(
          action.telecommandSpec,
          action.data
        );
        return {
          ...state,
          formData: newFormData
        };
      }
    }
    case TelecommandFormActionTypes.setLoading:
      return { ...state, loading: action.loading };
    case TelecommandFormActionTypes.setDefaultFields:
      return {
        ...state,
        hideDefaultFields: action.hideDefaultFields,
        showDefaultFieldsVisualizationSwitch:
          action.showDefaultFieldsVisualizationSwitch
      };
    case TelecommandFormActionTypes.setTelecommandSpecState: {
      const { telecommandSpec, formData, automaticFill } = action;
      const newTelecommandSpecState = buildLocalTelecommandSpec(
        telecommandSpec,
        formData,
        automaticFill
      );
      return {
        ...state,
        telecommandSpecState: newTelecommandSpecState
      };
    }
    case TelecommandFormActionTypes.setModalOpen:
      return {
        ...state,
        modalOpen: action.modalOpen
      };
    case TelecommandFormActionTypes.setSelectedTelecommandStackId:
      return {
        ...state,
        selectedTelecommandStackId: action.tcStackId
      };
    case TelecommandFormActionTypes.setInitialState:
      return { ...initialState, organization: state.organization };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};
