import { InputBase, SelectBox } from "app/shared";
import { ScriptListItem } from "app/scripting/models";

export const schemaCreate = (scripts: ScriptListItem[]) => ({
  type: "object",
  required: ["name", "steps"],
  properties: {
    name: {
      type: "string",
      title: "Name"
    },
    steps: {
      type: "array",
      hasRemove: true,
      items: {
        type: "object",
        required: ["order", "name", "scriptId"],
        properties: {
          order: {
            type: "number",
            title: "Order"
          },
          name: {
            type: "string",
            title: "Name"
          },
          scriptId: {
            type: "number",
            title: "Script",
            enum: scripts.map(it => it.id),
            enumNames: scripts.map(it => it.name)
          }
        }
      }
    }
  }
});

export const uiSchemaCreate = {
  name: { "ui:widget": InputBase },
  steps: {
    items: {
      order: { "ui:widget": InputBase },
      name: { "ui:widget": InputBase },
      scriptId: { "ui:widget": SelectBox }
    }
  }
};

export const schemaEdit = (scripts: ScriptListItem[]) => ({
  type: "object",
  properties: {
    id: {
      type: "number",
      title: "Id",
      kind: "constant"
    },
    ...schemaCreate(scripts).properties
  }
});

export const uiSchemaEdit = {
  id: { "ui:widget": InputBase },
  ...uiSchemaCreate
};
