import {
  dataProvider,
  GET_LIST,
  CREATE,
  GET_ONE,
  DELETE,
  UPDATE
} from "app/network";
import { TelecommandStack } from "../models";
import { mockStack } from "../reducers/mockstack";
import config from "config/constants";

export const getTelecommandStackById = (id: any): Promise<any> => {
  return dataProvider(GET_ONE, `telecommandstacks/${id}`);
};

// NOTE: this endpoint is not implemented yet
export const getTelecommandStackBySatId = (satId: any) => {
  return dataProvider(GET_ONE, `telecommandstacks/${satId}`);
};

export const getTelecommandStacksList = () => {
  return dataProvider(GET_LIST, `telecommandstacks`, {});
};

export const createTelecommandStack = (data: any) => {
  data = {
    telecommandList: data.telecommandList
      ? data.telecommandList.map((tc: any, i: any) => {
          if (typeof tc.id === "undefined") {
            return { ...tc, id: (new Date().getTime() + i).toString() };
          }
          return tc;
        })
      : [],
    name: data.name,
    description: data.description ? data.description : "",
    status: data.status,
    satelliteId: data.satelliteId,
    config: {
      refreshInterval: config.timer.refetchIntervalTCStackInMs
    },
    defaultTimeout: data.defaultTimeout
      ? data.defaultTimeout
      : config.timer.refetchIntervalTCStackInMs
  };

  return dataProvider(CREATE, `telecommandstacks`, { data });
};

export const updateTelecommandStack = (id: string, payload: any) => {
  const data = {
    name: payload.name,
    description: payload.description,
    status: payload.status,
    satelliteId: payload.satelliteId,
    config: {
      refreshInterval: config.timer.refetchIntervalTCStackInMs
    },
    defaultTimeout: payload.defaultTimeout,
    telecommandList: payload.telecommandList
  };
  return dataProvider(UPDATE, `telecommandstacks/${id}`, { data });
};

export const addTelecommandStack = (
  selectedTelecommandStack: TelecommandStack,
  payload: any
) => {
  const data = {
    name: selectedTelecommandStack.name,
    status: selectedTelecommandStack.status,
    telecommandList: payload
  };
  return dataProvider(
    UPDATE,
    `telecommandstacks/${selectedTelecommandStack.id}`,
    {
      data
    }
  );
};

export const deleteTelecommandStacks = (id: string) => {
  return dataProvider(DELETE, `telecommandstacks/${id}`);
};

const KEY = "telecommandStack";
export function loadState() {
  try {
    const serializedState = localStorage.getItem(KEY);
    if (!serializedState) {
      return mockStack;
    }
    // if (!serializedState) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
}

export function saveState(state: any) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(KEY, serializedState);
  } catch (e) {
    console.log("TURBO ~ saveState ~ e", e);
  }
}
