import { connect } from "react-redux";
import { TelecommandsListBase } from "./TelecommandsList";
import {
  selectTelecommand,
  selectTelecommandExecutionMode
} from "../../actions";
import { setFeedback, setNotificationStatus } from "app/feedback/actions";
import { refetchTCList } from "app/telecommandStack/actions";
import { TelecommandExecutionMode, TelecommandFormData } from "../../models";
import { FeedbackStatus } from "app/feedback/models";

const mapStateToProps = (state: any) => {
  return {
    selectedPassage: state.visibilityWindow.selectedPassage,
    notificationsConnected: state.app.notificationsStatus.isConnected
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    dispatchTelecommandSelectAction: (
      telecommandId: string,
      telecommandRecordId: number
    ) => dispatch(selectTelecommand(telecommandId, telecommandRecordId)),

    dispatchTelecommandExecutionModeAction: (
      executionMode: TelecommandExecutionMode,
      formData: TelecommandFormData
    ) => dispatch(selectTelecommandExecutionMode(executionMode, formData)),
    setFeedback: (title: string, status: FeedbackStatus, details = "") =>
      dispatch(setFeedback(title, status, details)),
    setStatus: (status: boolean) => dispatch(setNotificationStatus(status)),
    refetchTCList: (refetch: any) => dispatch(refetchTCList(refetch))
  };
};

export const TelecommandsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(TelecommandsListBase);
