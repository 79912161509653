import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgAdminSettings = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 19 21"
    color={color}
  >
    <g>
      <g>
        <path d="M7.2 7.2a3.6 3.6 0 1 0-3.6-3.6 3.6 3.6 0 0 0 3.6 3.6zm0-5.4a1.8 1.8 0 1 1-1.8 1.8 1.8 1.8 0 0 1 1.8-1.8z" />
        <path d="M7.6 16.4c0-.068.008-.133.01-.2H1.8v-.9a5.4 5.4 0 0 1 8.435-4.463 7.2 7.2 0 0 1 1.634-1.007A7.191 7.191 0 0 0 0 15.3V18h7.785a7.206 7.206 0 0 1-.185-1.6z" />
      </g>
      <path d="M17.858 15a3.929 3.929 0 0 0-.425-1.019l.81-.81-1.414-1.414-.81.81A3.914 3.914 0 0 0 15 12.142V11h-2v1.142a3.948 3.948 0 0 0-1.019.425l-.81-.81-1.414 1.414.81.81A3.929 3.929 0 0 0 10.142 15H9v2h1.142a3.915 3.915 0 0 0 .425 1.019l-.81.81 1.414 1.414.81-.81a3.914 3.914 0 0 0 1.019.425V21h2v-1.142a3.929 3.929 0 0 0 1.019-.425l.81.81 1.414-1.414-.81-.81A3.95 3.95 0 0 0 17.858 17H19v-2zM16 16a2 2 0 1 1-2-2 2 2 0 0 1 2 2z" />
    </g>
  </Svg>
);

SvgAdminSettings.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgAdminSettings;
