import React, { Component } from "react";
import { Box, Heading, Flex, Label } from "primitives";
import { SaveButton, InputField } from "components";

export class ConstellationCreate extends Component {
  state = {};

  componentWillMount() {
    this.props.getConstellations();
  }

  componentDidUpdate() {
    const { list } = this.props;
    if (list && list[0]) {
      this.props.history.push(`/constellation/${list[0].id}`);
    }
  }

  onChange = (event) => {
    const { name, value } = event.currentTarget;
    this.setState({ [name]: value });
  };

  submit = (e) => {
    e.preventDefault();
    const { satelliteInstances, ...data } = this.state;
    this.props.createConstellation(data).then((result) => {
      if (result.data.id) {
        this.props.selectConstellation(result.data);
        this.props.history.push(`/constellation/${result.data.id}`);
      }
    });
  };

  render() {
    return (
      <Box color="text.default" data-testid="ConstellationCreate">
        <Flex mb={2}>
          <Heading display={1}>Create Constellation</Heading>
        </Flex>
        <Flex flexDirection="column" bg="fill.0" p={3} mb={2}>
          <form onSubmit={(e) => this.submit(e)}>
            <Flex flexDirection="column" mb={3}>
              <Label>Name:</Label>
              <InputField
                name="name"
                required={true}
                onChange={(e) => this.onChange(e)}
              />
            </Flex>
            <Flex justifyContent="flex-start" mb={2}>
              <SaveButton variant="default" type="submit">
                Save
              </SaveButton>
            </Flex>
          </form>
        </Flex>
      </Box>
    );
  }
}
