import styled, { css } from "styled-components";
import {
  COMMON,
  TYPOGRAPHY,
  TypographyProps,
  CommonProps
} from "styles/constants";
import { NavLink, NavLinkProps } from "react-router-dom";
import {
  TestableProps,
  DATA_TEST_ID_ATTR_NAME
} from "components/testable/models";

export interface LinkProps
  extends NavLinkProps,
    TypographyProps,
    CommonProps,
    TestableProps {
  disabled?: boolean;
  fontSize?: string | number;
}

const disabled = (props: LinkProps) =>
  props.disabled ? css({ color: "grey", pointerEvents: "none" }) : null;

const LinkStyled = styled(NavLink)<LinkProps>`
  ${TYPOGRAPHY}
  ${COMMON}
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  ${disabled}

  &.${({ activeClassName }: LinkProps) => activeClassName} {
    text-decoration: underline;
  }
`;

LinkStyled.displayName = "Link";

LinkStyled.defaultProps = {
  color: "text.link",
  activeClassName: "active",
  [DATA_TEST_ID_ATTR_NAME]: LinkStyled.displayName
};

export default LinkStyled;
