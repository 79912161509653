import React from "react";
import { Box } from "primitives";
import { CONST_KIND } from "app/telecommand/models";
import { CustomSelect } from "components";

export const AutoCompleteSelect = ({
  id,
  value,
  readonly,
  autofocus: autoFocus,
  disabled,
  required,
  onBlur,
  onFocus,
  onChange,
  placeholder,
  classes,
  options,
  rawErrors,
  schema,
  ...props
}) => {
  const { enumOptions } = options;
  let kind = null;

  if (schema) {
    kind = schema.kind;
  }

  const selectedEnumOption = enumOptions.find(
    (enumOption) => enumOption.value === value
  );

  return (
    <Box overflow="visible" position="absolute" width="80%" mt={2} mb={2}>
      <CustomSelect
        disabled={disabled || readonly || (kind && kind === CONST_KIND)}
        onChange={(target) => {
          const selectedValue = target.value;
          onChange(selectedValue === "" ? options.emptyValue : selectedValue);
        }}
        placeholder={selectedEnumOption && selectedEnumOption.label}
        defaultInputValue={selectedEnumOption && selectedEnumOption.label}
        color="text.default"
        border={1}
        options={enumOptions}
        autoFocus={autoFocus}
      />
    </Box>
  );
};
