import React, { useMemo, useState, useEffect, useRef } from "react";
import CalendarTimeline, {
  TimelineMarkers,
  TimelineHeaders,
  DateHeader
} from "react-calendar-timeline";
import { TimeMarker } from "./components/TimelineMarker";
import { TimelineRendererProps } from "./models";
import { debounce } from "lodash";
import moment from "moment";
import { getDateAtUTCOffset, getUTCOffset, TIME_OFFSET } from "./utils/helpers";
import "react-calendar-timeline/lib/Timeline.css";

export const TimelineRenderer = (props: TimelineRendererProps) => {
  const [begin, setBegin] = useState<Date>(props.defaultTimeStart as Date);
  const [end, setEnd] = useState<Date>(props.defaultTimeEnd as Date);

  // Ref to track the initial render
  const isInitialRender = useRef(true);
  // Ref to track the dataProvider call
  const providing = useRef(false);

  useEffect(() => {
    if (isInitialRender.current) {
      // Skip the first execution
      isInitialRender.current = false;
      return;
    }

    if (!providing.current) provider(begin, end);
  }, [begin, end]);

  const provider = async (b: Date, e: Date) => {
    const { dataProvider } = props;
    if (!dataProvider) return;
    providing.current = true;
    await dataProvider(b, e);
    providing.current = false;
  };

  /**
   * @description If the visible time range goes ot the begin/end request the state get updated
   * with new range begin/end values.
   * begin/end are saved as local time and converted in UTC in the 'convertDataRequestToHTTP' service.
   * The range begin/end can't be saved with visibleTimeStart/visibleTimeEnd because they are local date rappresenting UTC.
   * So it is needed to add them the utc offset to rappreset a real local time.
   * @param visibleTimeStart a number rappresenting the unix timestamp. That's not a UTC time but a local time rappresenting a UTC time
   * @param visibleTimeEnd a number rappresenting the unix timestamp. That's not a UTC time but a local time rappresenting a UTC time
   */
  const handleCalendarTimeChange = debounce(
    (visibleTimeStart: number, visibleTimeEnd: number) => {
      const beginGMT = (begin || new Date()).toString();
      const endGMT = (end || new Date()).toString();
      const visibleStart = moment(visibleTimeStart).toDate();
      const visibleEnd = moment(visibleTimeEnd).toDate();
      const utcBegin = new Date(getDateAtUTCOffset(beginGMT));
      const utcEnd = new Date(getDateAtUTCOffset(endGMT));
      const utcOffset = getUTCOffset((begin as Date).toString()); //minutes
      const endOffset = moment
        .duration(moment(visibleEnd).diff(utcEnd))
        .asSeconds();
      const startOffset = moment
        .duration(moment(utcBegin).diff(visibleStart))
        .asSeconds();

      if (visibleStart < utcBegin && startOffset > TIME_OFFSET) {
        setBegin(moment(visibleStart).subtract(utcOffset, "minute").toDate());
      }

      if (visibleEnd > utcEnd && endOffset > TIME_OFFSET) {
        setEnd(moment(visibleEnd).subtract(utcOffset, "minute").toDate());
      }
    },
    500
  );

  return useMemo(() => {
    const tStart = new Date(getDateAtUTCOffset(begin.toISOString()));
    const tEnd = new Date(getDateAtUTCOffset(end.toISOString()));

    return (
      (props.items.length > 0 && (
        <CalendarTimeline
          {...props}
          defaultTimeStart={tStart}
          defaultTimeEnd={tEnd}
          onTimeChange={(
            visibleTimeStart,
            visibleTimeEnd,
            updateScrollCanvas
          ) => {
            updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
            return handleCalendarTimeChange(visibleTimeStart, visibleTimeEnd);
          }}
        >
          <TimelineHeaders>
            <DateHeader
              className={"test-class--primary-header"}
              unit="primaryHeader"
              style={{ pointerEvents: "none" }}
            />
            <DateHeader
              className={"test-class--secondary-header"}
              style={{ pointerEvents: "none" }}
            />
          </TimelineHeaders>
          <TimelineMarkers>
            <TimeMarker />
          </TimelineMarkers>
        </CalendarTimeline>
      )) || <></>
    );
  }, [props.items, props.groups, props.onItemSelect]);
};
