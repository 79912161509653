import uPlot from "uplot";
import { AlertDetails } from "../../models";

export function getSelectedDSIndexesWithAlerts(u: uPlot) {
  if (!u.data[u.data.length - 1].length) {
    return [];
  }
  const alertInfoSeries = u.data[u.data.length - 1][0] as any;
  const dsIndexes = Object.keys(alertInfoSeries)?.map((i) => Number(i));
  return dsIndexes || [];
}

export function getAlertDetails(u: uPlot, idx: number) {
  const dsIndexesWithAlertsEnabled = getSelectedDSIndexesWithAlerts(u);
  const alertInfoSeries = u.data[u.data.length - 1][0] as any;
  const alertDetails = dsIndexesWithAlertsEnabled
    .map((dsIdx) => {
      const timestamp = u.data[0][idx];
      const alertForSelectedIndex = alertInfoSeries[dsIdx][idx];
      // TEMP WORKAROUND UNTIL BE SUPPORTS AGGREGATION
      const aggregatedAlertDetail = aggregateAndShowMostCritical(
        alertForSelectedIndex
      );
      if (alertForSelectedIndex) {
        return { ...aggregatedAlertDetail, timestamp };
      }
      return null;
    })
    ?.filter(Boolean);

  return alertDetails || [];
}

export function aggregateAndShowMostCritical(alert: any) {
  const timestamp = alert?.timestamp;
  const value = alert?.value;

  const alertLevelsMap = new Map();
  for (const key in alert) {
    if (alert[key].type) {
      const alertLevel = alert[key].alertLevel;
      const alertKey = `${alertLevel.severity}-${alertLevel.condition}`;
      if (!alertLevelsMap.has(alertKey)) {
        alertLevelsMap.set(alertKey, alertLevel);
      }
    }
  }
  const alertLevels = Array.from(alertLevelsMap.values());

  const SHOW_ONLY_HIGHEST_PRIORITY = false;
  if (SHOW_ONLY_HIGHEST_PRIORITY) {
    let alertLevel = null;
    for (const key in alert) {
      if (alert[key].type === "DynamicAlertValue") {
        if (!alertLevel || alert[key].alertLevel.severity === "Critical") {
          alertLevel = alert[key].alertLevel;
        }
      }
    }
  }

  return {
    timestamp,
    value,
    type: "DynamicAlertValue",
    index: 1,
    alertLevels
  };
}

export function alertHasDetails(alertDetails: any[]) {
  if (!Array.isArray(alertDetails)) return false;
  const isNotEmpty = alertDetails.length > 0;
  const hasFullInfo = alertDetails[0]?.value;
  return isNotEmpty && hasFullInfo;
}

export const hasValue = (value: any) => value !== null && value !== undefined;
