import React from "react";
import formater from "date-fns/format";
import { Text } from "primitives";
import {
  TestableProps,
  DATA_TEST_ID_ATTR_NAME
} from "components/testable/models";

interface DateFormats {
  [name: string]: string;
}

interface DateTimeLabelProps extends TestableProps {
  value: Date | string;
  format?: string;
  fontSize?: number;
  color?: string;
}

export const dateFormats: DateFormats = {
  short: "DD MMM YYYY",
  long: "YYYY-MM-DD HH:mm:ss",
  time: "HH:mm:ss"
};

const DateTimeLabel = ({
  value,
  format,
  fontSize,
  color
}: DateTimeLabelProps) => {
  const formatSelected = (format && dateFormats[format]) || format;

  return (
    <Text fontSize={fontSize} color={color}>
      {formater(value, formatSelected)}
    </Text>
  );
};

DateTimeLabel.displayName = "DateTimeLabel";

DateTimeLabel.defaultProps = {
  format: "short",
  fontSize: 5,
  color: "palette.brand.0",
  [DATA_TEST_ID_ATTR_NAME]: DateTimeLabel.displayName
};

export default DateTimeLabel;
