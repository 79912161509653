import { RadioCombo } from "components";

const keyManagerConfigOptionsSchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "keymanager.schema.json",
  title: "Aurora Key Manager",
  description: "Injection Paramenters",
  type: "object",
  definitions: {
    channels: {
      $id: "#/definitions/channels",
      title: "Channels",
      description: "Set the key channel usage",
      type: "string",
      default: "",
      items: {
        $id: "#/definitions/channels/items",
        type: "number",
        enum: ["SDL", "SUL", "XDL"],
        enumNames: [
          "S-Band Downlink channel",
          "S-Band Uplink channel",
          "X-Band Downlink channel"
        ]
      },
      uniqueItems: true
    }
  },
  properties: {
    channels: {
      $ref: "#/definitions/channels"
    }
  },
  required: ["channels"]
};

const keyManagerConfigOptionsDefault = {
  channels: []
};

const keyManagerConfigOptionsUISchema = {
  channels: { "ui:field": RadioCombo }
};

export {
  keyManagerConfigOptionsSchema,
  keyManagerConfigOptionsDefault,
  keyManagerConfigOptionsUISchema
};
