import React from "react";
import { DropdownMenuLink } from "navigation/DropdownMenu";
import { useSelector } from "react-redux";

export const SettingsSelector = () => {
  const constellationId = useSelector(
    (state: any) =>
      state.constellations.selected && state.constellations.selected.id
  );

  return (
    <>
      <DropdownMenuLink to="/satellite-definition">Satellites</DropdownMenuLink>
      <DropdownMenuLink
        to={
          constellationId
            ? `/constellation/${constellationId}`
            : `/constellation`
        }
      >
        Constellation
      </DropdownMenuLink>
      <DropdownMenuLink to="/alert-definitions">Alerts</DropdownMenuLink>
      <DropdownMenuLink to="/dashboard">Dashboard</DropdownMenuLink>
      <DropdownMenuLink to="/scripts">Scripts</DropdownMenuLink>
      <DropdownMenuLink to="/resourcesv2">Resources</DropdownMenuLink>
      <DropdownMenuLink to="/procedures">Procedures</DropdownMenuLink>
      <DropdownMenuLink to="/telecommandstacks">
        Telecommand Stacks
      </DropdownMenuLink>
      <DropdownMenuLink to="/groundstations">Ground Stations</DropdownMenuLink>
      <DropdownMenuLink to="/keymanager">Key Manager</DropdownMenuLink>
      {process.env.REACT_APP_KEY_PLANNING_SYSTEM_ACTIVE === "true" && (
        <DropdownMenuLink to="/planningsystem">
          Planning System
        </DropdownMenuLink>
      )}
      {process.env.REACT_APP_PASSAGE_BOOKING === "true" && (
        <DropdownMenuLink to="/passage-manager">
          Passage Manager
        </DropdownMenuLink>
      )}
    </>
  );
};
