import React from "react";
import { KeycloakInstance } from "keycloak-js";

interface LogoutProps {
  kc: KeycloakInstance;
  children: React.ReactElement;
}

export const Logout = ({ kc, children }: LogoutProps) =>
  React.cloneElement(children, {
    onClick: () => {
      return kc.logout();
    }
  });
