import React from "react";
import AceEditor from "react-ace";
import { Box } from "primitives";

import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/snippets/python";
import "ace-builds/src-noconflict/mode-yaml";
import "ace-builds/src-noconflict/snippets/yaml";
import "ace-builds/src-noconflict/theme-monokai";
import "./EmbeddedEditorTheme";

enum Modes {
  python = "python",
  yaml = "yaml",
  json = "json"
}
interface EmbeddedEditorProps {
  id?: string;
  onChange: (value: string) => void;
  value: string | undefined;
  height?: string;
  width?: string;
  fontSize?: number;
  tabSize?: number;
  mode: Modes;
}

const EmbeddedEditor = (props: EmbeddedEditorProps) => {
  const {
    id,
    value,
    onChange,
    height,
    width,
    fontSize,
    tabSize,
    mode = Modes.python
  } = props;
  return (
    <Box my={2}>
      <AceEditor
        mode={mode}
        theme="aurora"
        width={width}
        height={height}
        name={id}
        onChange={(changedValue) => onChange(changedValue)}
        fontSize={fontSize}
        tabSize={tabSize}
        showGutter={true}
        highlightActiveLine={true}
        value={value}
        enableBasicAutocompletion={true}
        enableLiveAutocompletion={true}
        enableSnippets={true}
      />
    </Box>
  );
};

EmbeddedEditor.displayName = "EmbeddedEditor";

EmbeddedEditor.defaultProps = {
  height: "380px",
  width: "100%",
  fontSize: 14,
  tabSize: 2
};

export default EmbeddedEditor;
