import { InputBase, SelectBox } from "app/shared";
import { CheckBox } from "components";
import { DatasourceValueMapper } from "app/telemetry/visualizations/Histogram/DatasourceMapperComponent";
import { UnitConverter } from "app/telemetry/visualizations/shared/unitConverter";
import { scalingConfig } from "app/telemetry/visualizations/shared/configs";

const histogramConfigOptionsSchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "histogram.schema.json",
  title: "Aurora Histogram",
  description: "The configuration options for the Aurora's histogram component",
  type: "object",
  definitions: {
    dataSource: {
      $id: "#/definitions/dataSource",
      type: "object",
      title: "DataSource",
      description: "The data-source item to be added to the component",
      properties: {
        name: {
          $id: "#/definitions/dataSource/properties/name",
          title: "Name",
          description: "The original data-source name",
          type: "string"
        },
        histogram: {
          $id: "#/definitions/dataSource/properties/histogram",
          title: "histogram",
          description:
            "The histogram to be given to the data-source. When used, it overrides the original data-source name",
          type: "string"
        },
        id: {
          $id: "#/definitions/dataSource/properties/id",
          title: "Id",
          description: "The id of data-source",
          type: "integer",
          minimum: 1
        },
        indexes: {
          $id: "#/definitions/dataSource/properties/indexes",
          title: "Indexes",
          description:
            "In case of a vector data-source, represents the indexes to be presented. When is a scalar data-source, this field is not used. If it is an array data-source, and this field is not present or is empty, all the indexes should be considered",
          oneOf: [
            {
              type: "array",
              items: {
                title: "Items",
                description:
                  "An explanation about the purpose of this instance.",
                type: "object",
                properties: {
                  index: {
                    $id: "#/definitions/dataSource/properties/indexes/properties/index",
                    title: "Index",
                    description:
                      "The index where this properties will be reflected",
                    type: "integer",
                    minimum: 0
                  }
                },
                required: ["index"]
              }
            },
            {
              type: "null"
            }
          ]
        }
      },
      required: ["id"]
    }
  },
  properties: {
    dataSources: {
      $id: "#/properties/data-sources",
      title: "DataSources",
      description: "List of data-sources to be added to the component",
      type: "array",
      minItems: 1,
      items: {
        $ref: "#/definitions/dataSource"
      }
    },
    valueMapping: {
      collapsible: "enabled",
      title: "Datasource mapping configuration",
      description: "Override default properties for each datasource",
      type: "array",
      minItems: 0,
      items: {}
    },
    scale: scalingConfig("Histogram").configSchema,
    axes: {
      collapsible: "enabled",
      $id: "#/definitions/axes",
      title: "Axes settings",
      description: "configure settings for axes",
      type: "object",
      properties: {
        x: {
          title: "x-axis",
          type: "object",
          properties: {
            padding: {
              title: "padding",
              type: "number",
              default: 0.5
            },
            tickFormat: {
              title: "Tick Format",
              type: "string",
              default: "auto"
            },
            barWidth: {
              title: "bar width",
              type: "number",
              default: 5
            },
            defaultBarColor: {
              title: "Default Bar color",
              description:
                "bar color when no value is manually set in the mapping",
              type: "string",
              default: "#FFFFFF"
            },
            enableSort: {
              title: "Enable Sorting",
              type: "boolean",
              default: false
            },
            sortBy: {
              title: "Sorting Property (id, name, label)",
              description:
                "Sort x-axis bars by datasource property (id, name, or label)",
              type: "string",
              default: "name"
            },
            sortOrder: {
              title: "Sorting order",
              description: "Sort x-axis bars by datasource property",
              enumNames: ["ASC", "DESC"],
              enum: ["ASC", "DESC"],
              default: "ASC"
            },
            showGrid: {
              title: "Show grid lines",
              type: "boolean",
              default: false
            },
            gridColor: {
              title: "Grid line color",
              type: "string"
            },
            gridStrokeOpacity: {
              title: "Grid line opacity",
              type: "string"
            },
            showIndex: {
              title: "Show Index on label",
              type: "boolean",
              default: false
            }
          }
        },
        y: {
          title: "y-axis",
          type: "object",
          properties: {
            min: {
              title: "min",
              description:
                "min value for y-axis (setting 'auto' will create min based on min value in the dataset",
              type: "string",
              default: "auto"
            },
            max: {
              title: "max",
              description:
                "max value for y-axis (setting 'auto' will create min based on max value in the dataset",
              type: "string",
              default: "auto"
            },
            noOfTicks: {
              title: "Number of Ticks",
              description: "Number of ticks to display on y-axis",
              type: "number",
              default: 4
            },
            paddingTop: {
              title: "padding top",
              type: "number",
              default: 0
            }
          }
        }
      }
    },
    margin: {
      collapsible: "enabled",
      title: "Graph margins",
      description: "Set margins for histogram",
      type: "object",
      properties: {
        top: { type: "number" },
        left: { type: "number" },
        bottom: { type: "number" },
        right: { type: "number" }
      }
    },
    tooltip: {
      collapsible: "enabled",
      title: "tooltip styles",
      description: "Set styles for tooltip",
      type: "object",
      properties: {
        positionLeft: { title: "position left", type: "string" },
        positionTop: { title: "position top", type: "string" },
        opacity: { type: "number" },
        color: { type: "string" },
        "background-color": { title: "background color", type: "string" },
        padding: { type: "string" },
        position: { type: "string" }
      }
    },
    widgetName: {
      type: "string",
      title: "Histogram",
      default: "Histogram"
    },
    isGloballyControlled: {
      $id: "#/properties/isGloballyControlled",
      title: "Enable Time Controller",
      description:
        "Weather this widget should be controlled by global time controller. Enabling it will overide widget updatePeriod interval with global interval",
      type: "boolean",
      default: true
    }
  },
  required: ["dataSources"]
};

const histogramConfigOptionsDefault = {
  isGloballyControlled: true,
  dataSources: [],
  valueMapping: {},
  scale: {
    default: true,
    enableScaling: false,
    scaledUnit: "",
    showUnit: true,
    offset: 100,
    invalidValues: "4294967295",
    invalidValueOpacity: 20
  },
  margin: {
    top: 80,
    left: 80,
    bottom: 30,
    right: 40
  },
  axes: {
    x: {
      padding: 0.5,
      tickFormat: "",
      barWidth: 5,
      defaultBarColor: "#FFFFFF",
      enableSort: false,
      sortBy: "label",
      sortOrder: "DESC",
      showGrid: false,
      gridColor: "grey",
      gridStrokeOpacity: "0.01",
      showIndex: false
    },
    y: {
      min: "auto",
      max: "auto",
      noOfTicks: 4,
      paddingTop: 0
    }
  },
  tooltip: {
    left: -10,
    top: -20,
    opacity: 0,
    padding: "4px",
    color: "white",
    backgroungColor: "#353447",
    "z-index": "99999",
    position: "absolute"
  }
};

const histogramConfigOptionsUISchema = {
  isGloballyControlled: { "ui:widget": "hidden" },
  dataSources: { "ui:widget": "hidden" },
  valueMapping: {
    "ui:field": DatasourceValueMapper,
    items: {
      "ui:options": {
        orderable: true,
        addable: true,
        removable: true
      }
    }
  },
  scale: scalingConfig("Histogram").uiSchema,
  axes: {
    x: {
      padding: { "ui:widget": InputBase },
      tickFormat: { "ui:widget": InputBase },
      barWidth: { "ui:widget": InputBase },
      noOfTicks: { "ui:widget": InputBase },
      defaultBarColor: { "ui:widget": "color" },
      enableSort: { "ui:widget": CheckBox },
      sortBy: { "ui:widget": InputBase },
      sortOrder: { "ui:widget": SelectBox },
      showGrid: { "ui:widget": CheckBox },
      gridColor: { "ui:widget": "color" },
      gridStrokeOpacity: { "ui:widget": InputBase },
      showIndex: { "ui:widget": CheckBox }
    },
    y: {
      min: { "ui:widget": InputBase },
      max: { "ui:widget": InputBase },
      noOfTicks: { "ui:widget": InputBase },
      paddingTop: { "ui:widget": InputBase }
    }
  },
  margin: {
    top: { "ui:widget": InputBase },
    left: { "ui:widget": InputBase },
    bottom: { "ui:widget": InputBase },
    right: { "ui:widget": InputBase }
  },
  tooltip: {
    positionTop: { "ui:widget": InputBase },
    positionLeft: { "ui:widget": InputBase },
    color: { "ui:widget": "color" },
    "background-color": { "ui:widget": "color" },
    opacity: { "ui:widget": InputBase },
    position: { "ui:widget": InputBase },
    padding: { "ui:widget": InputBase }
  },
  widgetName: { "ui:widget": "hidden" }
};

const createUnitsEnumForDatasources = (formData: any) => {
  const selectedDataSourcesUnits = [
    ...new Set(formData.dataSources.map((i: any) => i.unit))
  ];

  if (selectedDataSourcesUnits.length) {
    // if there are datasources with multiple units, use first one as default
    console.warn(
      `widget contains datasources with multiple units  "${selectedDataSourcesUnits}" , choosing ${selectedDataSourcesUnits[0]} as default`
    );
  }

  const availableUnits = UnitConverter.getAvailableUnits();
  const units = availableUnits[selectedDataSourcesUnits[0] as string];

  if (
    ["ms", ...Object.keys(availableUnits)].includes(
      selectedDataSourcesUnits[0] as string
    )
  ) {
    return units;
  }
  return [""];
};

const createMappingSchemaForDatasources = (formData: any) => {
  if (!formData) return [];
  const schema = formData.dataSources.map((datasource: any) => {
    return {
      id: datasource.id,
      title: datasource.name,
      type: "object",
      properties: {
        id: {
          title: "dataSourceId",
          type: "number",
          default: datasource.id
        },
        label: {
          type: "string",
          default: datasource.label
        },
        color: {
          type: "string",
          default: "#FFFFFF"
        },
        index: {
          type: "number",
          default: extractIndex(datasource)
        }
      }
    };
  });
  return schema;
};

function extractIndex(datasource: { indexes: any }) {
  if (!datasource || !datasource.indexes || !Array.isArray(datasource.indexes))
    return 0;
  const indexes = datasource.indexes;
  return indexes[0].index;
}

export {
  histogramConfigOptionsSchema,
  histogramConfigOptionsDefault,
  histogramConfigOptionsUISchema,
  createMappingSchemaForDatasources,
  createUnitsEnumForDatasources
};
