import React from "react";
import styled from "styled-components";
import Box, { BoxProps, backgroundColorOpacity } from "primitives/Box";
import { themeGet } from "styled-system";

interface TableProps
  extends BoxProps,
    React.TableHTMLAttributes<HTMLTableElement> {
  children?: React.ReactNode | string;
}

interface TableCellProps
  extends BoxProps,
    React.TdHTMLAttributes<HTMLTableDataCellElement> {
  children?: React.ReactNode | string;
}

interface TableRowProps
  extends BoxProps,
    React.TdHTMLAttributes<HTMLTableRowElement> {
  children?: React.ReactNode | string;
}

interface TableHeaderProps
  extends BoxProps,
    React.TdHTMLAttributes<HTMLTableHeaderCellElement> {
  children?: React.ReactNode | string;
  hideHeader?: boolean;
}

const TableCellStyled = styled(Box)<BoxProps>`
  box-sizing: border-box;
  min-height: 48px;
  white-space: normal;
  text-wrap: nowrap;
`;

const TableCell = ({ children, ...props }: TableCellProps) => (
  <TableCellStyled as="td" {...props}>
    {children}
  </TableCellStyled>
);

TableCell.defaultProps = {
  p: 2,
  fontSize: 5
};

const TableHead = styled.thead<TableHeaderProps>`
  width: 100%;
  white-space: nowrap;
  text-transform: uppercase;
  ${(props) =>
    props.hideHeader
      ? `
    display: none;
  `
      : ``}
`;

const TableBody = styled.tbody`
  width: 100%;
  white-space: nowrap;

  ${TableCellStyled} {
    border-right: ${themeGet(`borders.2`)};
    border-color: ${themeGet(`colors.border.2`)};
  }

  ${TableCellStyled}:last-child {
    border-right: none;
  }
`;

const TableRowStyled = styled(Box)`
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  transition: background-color 0.1s ease-in;
  ${(props) =>
    backgroundColorOpacity({
      bgOpacity: 1,
      ...props
    })};

  &:hover {
    ${(props) =>
      backgroundColorOpacity({
        bgOpacity: 2,
        ...props
      })};
  }
`;

const TableRow = ({ children, ...props }: TableRowProps) => (
  <TableRowStyled as="tr" {...props}>
    {children}
  </TableRowStyled>
);

TableRow.defaultProps = {
  bg: "palette.brand.0",
  color: "palette.brand.0"
};

const TableStyled = styled(Box)`
  display: table;
  border-spacing: 0 1px;
  width: 100%;
  white-space: nowrap;
`;

const Table = ({ children, ...props }: TableProps) => (
  <TableStyled as="table" {...props}>
    {children}
  </TableStyled>
);

Table.defaultProps = {
  color: "palette.brand.0",
  backgroundColor: "palette.grey.5"
};

export { Table, TableHead, TableBody, TableRow, TableCell };
