import React from "react";
import { DialogContent } from "primitives";
import { Table, TableBody, TableRow, TableCell } from "components";
import { DialogContentInfoProps } from "app/planningSystem/models";

export const DialogContentInfo = ({ operation }: DialogContentInfoProps) => {
  return (
    (operation && (
      <DialogContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>{operation.name}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
    )) || <>The operation that you selected does not exists</>
  );
};
