import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgSend = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 42 42"
    color={color}
  >
    <path d="M41.374 0.456757C41.3688 0.45323 41.367 0.44798 41.3635 0.444536C41.353 0.435759 41.3426 0.434037 41.3321 0.425261C41.2394 0.344798 41.1293 0.292305 41.021 0.234562C40.986 0.217091 40.9598 0.187318 40.9231 0.17157C40.9178 0.169847 40.9143 0.169847 40.9109 0.168043C40.8725 0.152295 40.841 0.124326 40.8025 0.1103C40.6574 0.056084 40.5036 0.0385315 40.3481 0.0245879C40.3009 0.021061 40.2554 6.36279e-05 40.2082 6.36279e-05C40.0054 -0.00165881 39.801 0.0315597 39.6017 0.103328L1.15146 14.1015C0.541526 14.3237 0.104537 14.8662 0.015402 15.5101C-0.07021 16.154 0.204158 16.7926 0.733723 17.1706L12.3579 25.4838L13.9991 36.9921C14.1004 37.692 14.6125 38.2625 15.2977 38.4392C15.441 38.476 15.5878 38.4934 15.7311 38.4934C16.2712 38.4934 16.7938 38.2415 17.1276 37.7936L21.2522 32.2905L32.069 41.5783C32.3924 41.8548 32.7961 42 33.2085 42C33.4042 42 33.6 41.9668 33.7922 41.9003C34.3795 41.6903 34.8147 41.1811 34.9265 40.5652L41.9717 2.10325C42.0871 1.47333 41.8424 0.853903 41.374 0.456757ZM19.6723 28.5513L19.5465 28.7333L16.8637 32.315L15.8482 25.1934L31.0133 12.0333L19.6723 28.5513Z" />
  </Svg>
);

SvgSend.defaultProps = {
  width: "25",
  height: "25"
};
export default SvgSend;
