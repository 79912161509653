export interface TelecommandStack {
  id: string;
  name: string;
  description?: string;
  ownerId: string;
  status?: string;
  defaultTimeout?: number;
  satelliteId: number;
  config?: string;
  telecommandList: Telecommand[];
}

export interface TelecommandStackList {
  telecommandStacksList: TelecommandStack[];
}

export interface TelecommandPayload {
  telecommandUri: string;
  params: any;
}

export interface Telecommand {
  telecommand: TelecommandPayload;
  passageId: number;
  id?: string;
  status?: string;
  comment?: boolean;
}

export interface TelecommandStackState {
  telecommandStacks: TelecommandStack[];
  selectedStack: TelecommandStack | null;
  selectedTC?: any | null;
  refetchTC?: any;
}

export interface CreateTelecommandStackCommand {
  name: string;
  description?: string;
  satelliteId: number;
  defaultTimeout?: number;
  telecommandList?: Telecommand[];
}
export type EditTelecommandStackCommand = CreateTelecommandStackCommand;

export enum TelecommandStackActionTypes {
  AddToStack = "ADD_TO_STACK",
  RemoveFromStack = "REMOVE_FROM_STACK",
  GetTelecommandStacks = "GET_TELECOMMANDSTACKS",
  ReOrder = "REORDER",
  UpdateTCStatus = "UPDATE_TC_STATUS",
  ResetTCStatus = "RESET_TC_STATUS",
  UpdateStackStatus = "UPDATE_TC_STACK_STATUS",
  UpdateStack = "UPDATE_STACK",
  copyTelecommand = "COPY_TC",
  SetSelectedStack = "SET_SELECTED_STACK",
  SetSelectedTC = "SET_SELECTED_TC",
  RefetchTC = "REFETCH_TC",
  AddComment = "ADD_COMMENT"
}

export enum TelecommandStackStatus {
  RUNNING = "RUNNING",
  STOPPED = "STOPPED",
  ERROR = "ERROR",
  PAUSED = "PAUSED",
  SUCCESS = "SUCCESS"
}

export enum TelecommandStatus {
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING"
}

export enum ItemType {
  TELECOMMAND = "telecommand",
  COMMENT = "comment"
}
