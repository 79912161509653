import {
  ExecutionId,
  ScriptInput,
  Execution,
  ScriptListItem,
  ExecuteScriptCommand,
  ScriptExecutionListItem
} from "../models";
import { ScriptExecutionService } from "../services";
import { Dispatch } from "react";
import { ThunkAction } from "redux-thunk";

export enum ScriptActionType {
  ExecuteScript = "SCRIPT_EXECUTE",
  FetchScripts = "FETCH_SCRIPTS",
  TerminateExecution = "SCRIPT_TERMINATE_EXECUTION",
  StreamExecutionDetails = "SCRIPT_EXECUTION_DETAILS_STREAM",
  SelectExecution = "SCRIPT_EXECUTION_SELECT",
  SendInput = "SCRIPT_SEND_INPÛT",
  FetchRunningScripts = "FETCH_RUNNING_SCRIPTS"
}

export interface ExecuteScriptAction {
  type: ScriptActionType.ExecuteScript;
  execution: Execution;
}

export interface FetchScriptsAction {
  type: ScriptActionType.FetchScripts;
  payload: ScriptListItem[];
}

export interface TerminateScriptAction {
  type: ScriptActionType.TerminateExecution;
  execution: Execution;
}

export interface UpdateExecutionDetailsAction {
  type: ScriptActionType.StreamExecutionDetails;
  execution: Execution;
}

export interface SelectScriptExecutionAction {
  type: ScriptActionType.SelectExecution;
  execution: Execution;
}

export interface FetchRunningScriptAction {
  type: ScriptActionType.FetchRunningScripts;
  runningScripts: ScriptExecutionListItem[];
}

export type ScriptActions =
  | ExecuteScriptAction
  | TerminateScriptAction
  | SelectScriptExecutionAction
  | UpdateExecutionDetailsAction
  | FetchRunningScriptAction;

export type ExecuteScriptActionResult = ThunkAction<
  Promise<Execution>,
  {},
  {},
  ExecuteScriptAction
>;
export const executeScript = (
  cmd: ExecuteScriptCommand
): ExecuteScriptActionResult => {
  const scriptExecutionservice: ScriptExecutionService = ScriptExecutionService.default();
  return async (dispatch: Dispatch<ExecuteScriptAction>) => {
    const execution = await scriptExecutionservice.executeScript(cmd);
    dispatch({
      type: ScriptActionType.ExecuteScript,
      execution
    });
    return Promise.resolve(execution);
  };
};

export type TerminateScriptActionResult = ThunkAction<
  Promise<Execution>,
  {},
  {},
  TerminateScriptAction
>;
export const terminateExecution = (
  executionId: ExecutionId
): TerminateScriptActionResult => {
  const scriptExecutionservice: ScriptExecutionService = ScriptExecutionService.default();
  return async (dispatch: Dispatch<ExecuteScriptAction>) => {
    const execution = await scriptExecutionservice.stopScriptExecution(
      executionId
    );
    dispatch({
      type: ScriptActionType.ExecuteScript,
      execution
    });
    return Promise.resolve(execution);
  };
};

export type SentInputActionResult = ThunkAction<
  Promise<Execution>,
  {},
  {},
  UpdateExecutionDetailsAction
>;
export const sendInput = (
  executionId: ExecutionId,
  input: ScriptInput
): SentInputActionResult => {
  const scriptExecutionservice: ScriptExecutionService = ScriptExecutionService.default();
  return async (dispatch: Dispatch<UpdateExecutionDetailsAction>) => {
    const execution = await scriptExecutionservice.sendData(executionId, input);
    dispatch({
      type: ScriptActionType.StreamExecutionDetails,
      execution
    });
    return Promise.resolve(execution);
  };
};

export type FetchExecutionDetailsActionResult = ThunkAction<
  Promise<Execution>,
  {},
  {},
  UpdateExecutionDetailsAction
>;
export const fetchExecutionDetails = (
  executionId: ExecutionId
): FetchExecutionDetailsActionResult => {
  const scriptExecutionservice: ScriptExecutionService = ScriptExecutionService.default();
  return async (dispatch: Dispatch<UpdateExecutionDetailsAction>) => {
    const execution = await scriptExecutionservice.getExecution(executionId);
    dispatch({
      type: ScriptActionType.StreamExecutionDetails,
      execution
    });
    return Promise.resolve(execution);
  };
};

export type SelectExecutionDetailsActionResult = ThunkAction<
  Promise<Execution>,
  {},
  {},
  SelectScriptExecutionAction
>;
export const selectScriptExecution = (
  executionId: ExecutionId
): SelectExecutionDetailsActionResult => {
  const scriptExecutionservice: ScriptExecutionService = ScriptExecutionService.default();
  return async (dispatch: Dispatch<SelectScriptExecutionAction>) => {
    const execution = await scriptExecutionservice.getExecution(executionId);
    dispatch({
      type: ScriptActionType.SelectExecution,
      execution
    });
    return Promise.resolve(execution);
  };
};

export type FetchScriptsActionResult = ThunkAction<
  Promise<ScriptListItem[]>,
  {},
  {},
  FetchScriptsAction
>;
export const fetchScriptsAction = (): FetchScriptsActionResult => {
  const scriptExecutionservice: ScriptExecutionService = ScriptExecutionService.default();
  return async (dispatch: Dispatch<FetchScriptsAction>) => {
    const payload = await scriptExecutionservice.getScripts();
    dispatch({
      type: ScriptActionType.FetchScripts,
      payload
    });

    return Promise.resolve(payload);
  };
};

export type FetchRunningScriptActionResults = ThunkAction<
  Promise<ScriptExecutionListItem[]>,
  {},
  {},
  FetchRunningScriptAction
>;

export const fetchRunningScriptAction = (): FetchRunningScriptActionResults => {
  return async (dispatch: Dispatch<FetchRunningScriptAction>) => {
    const service = ScriptExecutionService.default();
    const runningScripts = await service.fetchRunningScripts();
    dispatch({
      type: ScriptActionType.FetchRunningScripts,
      runningScripts: runningScripts
    });
    return Promise.resolve(runningScripts);
  };
};
