import * as ActionTypes from "../models/constants";

/**
 * constellations
 * constellation's dashboard
 */

/* constellations */
export const fetchConstellationsStart = () => ({
  type: ActionTypes.FETCH_CONSTELLATIONS_START
});

export const fetchConstellationsSuccess = (payload) => ({
  payload,
  type: ActionTypes.FETCH_CONSTELLATIONS_SUCCESS
});

export const fetchConstellationsError = () => ({
  type: ActionTypes.FETCH_CONSTELLATIONS_ERROR
});
/**  */

/* constellation */
export const fetchConstellationStart = () => ({
  type: ActionTypes.FETCH_CONSTELLATION_START
});

export const fetchConstellationSuccess = (payload) => ({
  payload,
  type: ActionTypes.FETCH_CONSTELLATION_SUCCESS
});

export const fetchConstellationError = () => ({
  type: ActionTypes.FETCH_CONSTELLATION_ERROR
});

export const selectConstellation = (payload) => ({
  payload,
  type: ActionTypes.SELECT_CONSTELLATION
});

export const clearConstellation = () => ({
  type: ActionTypes.CLEAR_CONSTELLATION
});
/** */

/** constellation's dashboard */
export const addSatelliteToDashboard = (payload) => ({
  payload,
  type: ActionTypes.ADD_SATELLITE
});

export const addSatellitesToDashboard = (payload) => ({
  payload,
  type: ActionTypes.ADD_SATELLITES
});

export const removeSatelliteFromDashboard = (payload) => ({
  payload,
  type: ActionTypes.REMOVE_SATELLITE
});

export const cleanSatelliteFromDashboard = (payload) => ({
  payload,
  type: ActionTypes.CLEAN_SATELLITE
});

export const setSatelliteVisibility = (id) => ({
  payload: { id },
  type: ActionTypes.SET_SATELLITE_VISIBILITY
});
/** */
