import React, { SFC } from "react";
import { Text, Flex, Heading } from "primitives";
import { AuroraTelemetryFrame, AuxiliaryField } from "app/telemetry/models";
import { isURL } from "app/telemetry/utils";

export const FramePayloadAuxiliariesGrid: SFC<{
  tmFrame: AuroraTelemetryFrame;
}> = ({ tmFrame }) => {
  const auxiliaries: AuxiliaryField[] | undefined =
    tmFrame.payload && tmFrame.payload.frames && tmFrame.payload.frames[0]
      ? tmFrame.payload.frames[0].auxiliaries
      : undefined;
  return (
    <>
      {auxiliaries && (
        <>
          <Heading display={4} p={2} bg="fill.1">
            Auxiliaries:
          </Heading>
          <Flex flexWrap="wrap">
            {auxiliaries.map((ds, i) => (
              <Flex
                key={i}
                p={2}
                flex="0 1 25%"
                maxWidth="25%"
                flexDirection="column"
              >
                <Text bold mb={1}>
                  {ds.name}:
                </Text>
                <Text
                  wordBreak="break-word"
                  onClick={() => isURL(ds.data) && window.open(ds.data)}
                  style={{ cursor: isURL(ds.data) ? "pointer" : "" }}
                >
                  {ds.repr
                    ? Array.isArray(ds.repr)
                      ? ds.repr.join(", ")
                      : ds.repr
                    : ds.data}
                </Text>
              </Flex>
            ))}
          </Flex>
        </>
      )}
    </>
  );
};
