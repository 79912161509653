import React, { useState } from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import { TypographyProps, CommonProps, BorderProps } from "styles/constants";
import {
  TestableProps,
  DATA_TEST_ID_ATTR_NAME
} from "components/testable/models";
import { Box, Label } from "primitives";

interface CollapsibleProps
  extends TypographyProps,
    CommonProps,
    BorderProps,
    TestableProps {
  children: any;
  label?: string;
  labelColor?: string;
  onCollapse?: () => void;
}

const ExpandCollapse = styled(Box)`
  cursor: pointer;
  padding: 10px;
  border-bottom: ${themeGet("colors.palette.grey.1")};
`;

const FlexLabel = styled(Label)`
  display: flex;
`;

const ArrowUp = styled(Box)`
  box-sizing: border-box;
  height: 10px;
  width: 10px;
  margin-left: 10px;
  margin-top: 4px;
  border-style: solid;
  border-color: ${themeGet("colors.palette.brand.0")};
  border-width: 0px 1px 1px 0px;
  transform: rotate(225deg);
`;

const ArrowDown = styled(Box)`
  box-sizing: border-box;
  height: 10px;
  width: 10px;
  margin-left: 10px;
  border-style: solid;
  border-color: ${themeGet("colors.palette.brand.0")};
  border-width: 0px 1px 1px 0px;
  transform: rotate(45deg);
`;

const Collapsible = (props: CollapsibleProps) => {
  const { label, labelColor, children, onCollapse } = props;
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Box overflow="visible">
      <ExpandCollapse
        onClick={() => {
          setCollapsed(!collapsed);
          onCollapse && onCollapse();
        }}
      >
        <FlexLabel
          color={labelColor ? labelColor : "text.white"}
          cursor="pointer"
        >
          {label}
          {!collapsed ? <ArrowDown /> : <ArrowUp />}
        </FlexLabel>
      </ExpandCollapse>
      {!collapsed ? children : null}
    </Box>
  );
};

Collapsible.displayName = "Collapsible";

Collapsible.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: Collapsible.displayName
};

export default Collapsible;
