import React from "react";
import { Label } from "primitives";
import styled from "styled-components";
import { ArrowDown, ArrowUp } from "icons";

interface CollapsibleLabelProps {
  props: any;
  schema: any;
  id: string;
  label: string;
  show: boolean;
  setShow: (show: boolean) => void;
}

export const CollapsibleLabel = ({
  props,
  schema,
  id,
  label,
  show,
  setShow
}: CollapsibleLabelProps) => {
  const isRoot = props.id === "root";
  const isCollapsible = !isRoot && schema.collapsible;
  return isCollapsible ? (
    <Collapsible>
      <Label cursor="pointer" htmlFor={id} onClick={() => setShow(!show)}>
        {label}
      </Label>
      {show ? (
        <ArrowUp width="9" height="5" color="#8093A7" />
      ) : (
        <ArrowDown width="9" height="5" color="#8093A7" />
      )}
    </Collapsible>
  ) : (
    <Label htmlFor={id}>{label}</Label>
  );
};

export const shouldShowForm = (props: any, schema: any, show: boolean) => {
  const isRoot = props.id === "root";
  const isNestedField = props.id.split("_").length >= 3;
  const shouldSkipCollapsing = !schema.collapsible;
  if (shouldSkipCollapsing || isRoot || isNestedField) {
    return true;
  }
  return show;
};

export const Collapsible = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1f1e2c52;
  padding: 8.1px 4px;
`;
