import uPlot from "uplot";
import { getCoordinates } from "./coordinates";
import { CONFIG } from "./config";
import { hasValue } from "./utils";

export function plotAlerts(
  u: uPlot,
  seriesIdx: number,
  startIdx: number,
  endIdx: number,
  _: any
) {
  const { ctx } = u;
  const lines: any = [];

  ctx.save();
  let current = startIdx;
  while (current <= endIdx) {
    const { x, yMin, yMax } = getCoordinates(u, current, seriesIdx);
    storeLineData(u, seriesIdx, lines, current);
    const value = u.data[seriesIdx][current];
    if (hasValue(value)) {
      // Verify if multi colored lines are useful for soft+hard
      drawVerticalLine(ctx, { x, yMin, yMax }, CONFIG.defaultWidth);
    }
    current++;
  }
  ctx.restore();
}

function isWithinClickRange(x: any, line: any) {
  return Math.abs(x - line.x) < CONFIG.clickRange || true;
}

function drawVerticalLine(
  ctx: any,
  line: any,
  lineWidth = CONFIG.selectedWidth,
  strokeStyle = CONFIG.color
) {
  ctx.beginPath();
  ctx.moveTo(line.x, line.yMin);
  ctx.lineTo(line.x, line.yMax);
  ctx.strokeStyle = strokeStyle;
  ctx.lineWidth = lineWidth;
  ctx.stroke();
}

function storeLineData(u: any, seriesIdx: any, lines: any[], point: any) {
  const min = u.scales.y.min;
  const max = u.scales.y.max;
  const { scale } = u.series[seriesIdx];
  const value = u.data[seriesIdx][point];
  const x = value && u.valToPos(u.data[seriesIdx][point], "x", true);
  const yMin = Math.round(u.valToPos(Number(min), scale as string, true)) + 0.5;
  const yMax = Math.round(u.valToPos(Number(max), scale as string, true)) + 0.5;
  if (value !== null) {
    lines.push({ x, yMin, yMax, value: value, index: point });
  }
}
