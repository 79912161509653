import { DataTypeAll, DataTypeNumbers } from "app/dataSource/models";
import { grafanaConfigOptionsValidator } from "app/telemetry/visualizations/Grafana/config/Grafana";

interface DashboardComponent {
  componentName: string;
  allowedDataTypes: string[] | null;
  options: {};
  allowTextValue?: boolean;
  maxItems?: number;
  validator?: (formData: any, errors: any) => {};
}

interface DashboardComponentsBase {
  [key: string]: DashboardComponent;
  getByType?: any;
  getTextValueComponents?: any;
  getDataSourceComponents?: any;
}

export enum DashboardComponentKeys {
  Globe = "Globe",
  Label = "Label",
  RealTimeTable = "RealTimeTable",
  HistoricalTable = "HistoricalTable",
  RealTimeGraph = "RealTimeGraph",
  ProceduresExecution = "ProceduresExecution",
  ScriptExecutionList = "ScriptExecutionList",
  ScriptExecutionConsole = "ScriptExecutionConsole",
  TelecommandsExecution = "TelecommandsExecution",
  TelecommandsSent = "TelecommandsSent",
  TelemetryReceived = "TelemetryReceived",
  ConstellationTree = "ConstellationTree",
  VisibilityWindows = "VisibilityWindows",
  PassageCountdown = "PassageCountdown",
  OperationsList = "OperationsList",
  Grafana = "Grafana",
  TelecommandStack = "TelecommandStack",
  Histogram = "Histogram",
  Gauge = "Gauge"
}

/**
 * Exports dashboard components configurations
 */
export const DashboardComponents: DashboardComponentsBase = {
  Globe: {
    componentName: "Globe",
    allowedDataTypes: null,
    options: {}
  },
  Label: {
    componentName: "Simple Value",
    allowedDataTypes: DataTypeAll,
    allowTextValue: true,
    maxItems: 1,
    options: {}
  },
  RealTimeTable: {
    componentName: "Real Time Table",
    allowedDataTypes: DataTypeAll,
    options: {}
  },
  HistoricalTable: {
    componentName: "Historical Table",
    allowedDataTypes: null,
    options: {}
  },
  RealTimeGraph: {
    componentName: "Real Time Graph",
    allowedDataTypes: DataTypeNumbers,
    options: {}
  },
  ProceduresExecution: {
    componentName: "Procedures Execution",
    allowedDataTypes: null,
    options: {}
  },
  ScriptExecutionList: {
    componentName: "Script Execution List",
    allowedDataTypes: null,
    options: {}
  },
  ScriptExecutionConsole: {
    componentName: "Script Execution Console",
    allowedDataTypes: null,
    options: {}
  },
  TelecommandsExecution: {
    componentName: "Telecommands Execution",
    allowedDataTypes: null,
    options: {}
  },
  TelecommandsSent: {
    componentName: "Telecommands Sent",
    allowedDataTypes: null,
    options: {
      page: 1,
      perPage: 20
    }
  },
  TelemetryReceived: {
    componentName: "Telemetry Received",
    allowedDataTypes: null,
    options: {}
  },
  ConstellationTree: {
    componentName: "ConstellationTree",
    allowedDataTypes: null,
    options: {}
  },
  VisibilityWindows: {
    componentName: "Visibility Windows",
    allowedDataTypes: null,
    options: {}
  },
  PassageCountdown: {
    componentName: "Passage Countdown",
    allowedDataTypes: null,
    options: {}
  },
  OperationsList: {
    componentName: "Operations List",
    allowedDataTypes: null,
    options: {}
  },
  Grafana: {
    componentName: "Grafana Dashboard",
    allowedDataTypes: null,
    options: {},
    validator: grafanaConfigOptionsValidator
  },
  TelecommandStack: {
    componentName: "Telecommand Stack",
    allowedDataTypes: null,
    options: {}
  },
  Histogram: {
    componentName: "Histogram",
    allowedDataTypes: DataTypeAll,
    options: {}
  },
  Gauge: {
    componentName: "Gauge",
    allowedDataTypes: DataTypeAll,
    allowTextValue: true,
    maxItems: 1,
    options: {}
  }
};

DashboardComponents.getByType = (type: string) =>
  DashboardComponents[type] ? DashboardComponents[type] : {};

DashboardComponents.getDataSourceComponents = () => {
  return Object.keys(DashboardComponents).filter(
    (key) => DashboardComponents[key].allowedDataTypes
  );
};

/**
 * Export all components. This is useful to interact with all choices.
 */
export const DashboardComponentsAll = Object.keys(DashboardComponents).filter(
  (component: string) =>
    component !== "getByType" && component !== "getDataSourceComponents"
);

export const DashboardComponentsNames = Object.values(DashboardComponents).map(
  (dashboard: { componentName: string }) => dashboard.componentName
);

export const DashboardComponentsDataTypesRequired = Object.keys(
  DashboardComponents
).filter((key) => {
  return Array.isArray(DashboardComponents[key].allowedDataTypes);
});

export const DashboardComponentsDataTypesNotRequired = Object.keys(
  DashboardComponents
).filter((key) => {
  return !Array.isArray(DashboardComponents[key].allowedDataTypes);
});

export interface Dashboard {
  id: string;
  applicableSatDefinition: ApplicableSatDefinition;
  name: string;
  description: string;
  type: Type;
  ownerId: string;
  permissions: Permissions;
  size: Size;
  dimensions: Dimensions;
  components: Component[];
}

export interface ApplicableSatDefinition {
  id: number;
  name: string;
}

export interface Component {
  label: string;
  position: Size;
  size: Size;
  type: string;
  configOptions: null | string | any;
  dataSources: DashboardDataSourceDefinition[];
  yExtent?: null | number[];
}

export interface DashboardDataSourceDefinition {
  datasourceId: number;
  label: string | null;
}

export interface Size {
  row: number;
  col: number;
}

export interface Dimensions {
  width: number;
  height: number;
}

export enum Permissions {
  Private = "Private",
  Public = "Public"
}

export enum Type {
  PerSatellite = "PerSatellite"
}

export enum TypesWithConfigOptions {
  Globe = "Globe",
  PassageCountdown = "PassageCountdown",
  RealTimeTable = "RealTimeTable",
  Label = "Label",
  RealTimeGraph = "RealTimeGraph",
  VisibilityWindows = "VisibilityWindows",
  TelecommandsExecution = "TelecommandsExecution",
  Grafana = "Grafana",
  Histogram = "Histogram",
  Gauge = "Gauge",
  TelecommandsSent = "TelecommandsSent"
}

export type DataSource =
  | {
      id: number | string;
      label?: string;
      color?: string;
      index?: string;
      datasource?: string;
    }
  | {
      datasourceId?: number | string;
      label?: string;
    };

type ExtractKeys<T> = T extends any ? keyof T : never;
type DataSourceKeys = ExtractKeys<DataSource>;

export type ExtendedDataSource = Record<DataSourceKeys, any>;

export type Dashboards =
  | {
      id?: string;
      applicableSatDefinition?: ApplicableSatDefinition;
      name: string;
      description: string;
      type: Type;
      ownerId?: string;
      permissions: Permissions;
      size: Size;
      dimensions: Dimensions;
      components: Component[];
    }
  | {
      sat_name?: string;
    };

export type ExtendedDashboard = Record<ExtractKeys<Dashboards>, any>;
