type MessageHandler = (msg: MessageEvent) => void;

export class MockWorker {
  url: string;
  onmessage: MessageHandler;
  onerror: MessageHandler;
  constructor(stringUrl: string) {
    this.url = stringUrl;
    this.onmessage = () => {
      console.log("TURBO ~ MockWorker  onmessage:");
    };
    this.onerror = () => {
      console.log("TURBO ~ MockWorker  onerror:");
    };
  }
  postMessage(msg: any): void {
    this.onmessage(msg);
  }
}
