import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgSpinner = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 41 41"
    stroke={color}
    style={{ margin: "auto" }}
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(3 3)" strokeWidth="4">
        <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </Svg>
);

SvgSpinner.defaultProps = {
  width: "22",
  height: "22"
};
export default SvgSpinner;
