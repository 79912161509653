import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgReplay = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    viewBox="0 0 420 420"
    width={size || width}
    height={size || height}
    color={color}
  >
    <path d="M210 21c104.216 0 189 84.784 189 189s-84.784 189-189 189S21 314.216 21 210 105.784 21 210 21m0-21C94.031 0 0 94.024 0 210s94.031 210 210 210 210-94.024 210-210S325.969 0 210 0z" />
    <path d="M332.962 298.886c-.455-5.775-5.523-10.08-11.291-9.632l-14.882 1.19c42.448-51.142 38.031-127.316-11.361-172.928-51.037-47.138-130.914-43.96-178.059 7.091-47.131 51.037-43.953 130.914 7.091 178.052 21.252 19.621 48.853 31.409 77.707 33.187 5.789.357 10.766-4.046 11.123-9.835.364-5.782-4.039-10.78-9.835-11.123-24.031-1.477-47.033-11.298-64.743-27.657-42.546-39.284-45.185-105.84-5.901-148.379 39.277-42.539 105.833-45.192 148.372-5.901 40.691 37.576 44.772 100.065 10.675 142.653l-.007-13.874c0-5.789-4.704-10.5-10.493-10.493-5.81 0-10.507 4.711-10.5 10.5v41.272a10.502 10.502 0 0 0 11.333 10.465l41.132-3.276c5.789-.469 10.108-5.53 9.639-11.312z" />
  </Svg>
);

SvgReplay.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgReplay;
