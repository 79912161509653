import React from "react";
import { Switch, Route } from "react-router-dom";
import { PassageManager } from "./components/Booker";
import { ConfigCreateContainer } from "./components/Config/ConfigTable/ConfigCreate";
import { Configs } from "./components/Config";

export const PassageManagerRoutes = () => (
  <>
    <Switch>
      <Route path="/passage-manager" exact component={Configs} />
      <Route
        path="/passage-manager/create"
        exact
        component={ConfigCreateContainer}
      />
      <Route path="/passage-manager-configs" exact component={Configs} />
      <Route
        path="/passage-manager-candidates"
        exact
        component={PassageManager}
      />
    </Switch>
  </>
);
