import React from "react";
import { Flex } from "primitives";
import { InputField } from "components";
import { ExecutedTelecommand, IFiltersTCList } from "app/telecommand/models";
import { AuroraTelemetryFrame } from "app/telemetry/models";

const Filter = (props: any) => {
  const { field, onChange, value } = props;
  return (
    <Flex justifyContent="space-between" alignItems="center" width="100%">
      <InputField
        id={field}
        onChange={(e) => onChange(e.target.value, e.target.id)}
        fontSize={10}
        color="blue"
        value={value}
      ></InputField>
    </Flex>
  );
};

export default Filter;

export const shouldFilter = (filters: any) =>
  isObject(filters)
    ? Object.entries(filters).some((item) => Boolean(item[1]))
    : false;

const isObject = (item: any) => typeof item === "object";
const isEmptyArray = (item: any) => Array.isArray(item) && item.length < 1;

type Filterable = ExecutedTelecommand[] | AuroraTelemetryFrame[] | any[];

type FilterFields = IFiltersTCList | any;

type FilterResult = ExecutedTelecommand[] | AuroraTelemetryFrame[] | any;

export const filterByField = (
  list: Filterable,
  filters: FilterFields
): FilterResult => {
  if (isObject(filters) && !isEmptyArray(list)) {
    // eslint-disable-next-line array-callback-return
    Object.entries(filters).map((item: any) => {
      if (item[0] && item[1]) {
        list = list.filter((l: any) =>
          (item[0] === "response" ? l[item[0]].statusName : l[item[0]])
            .toString()
            .toLocaleLowerCase()
            .includes(item[1].toString().toLocaleLowerCase())
        );
      }
    });
  }
  return list;
};
