import React from "react";
import { DATA_TEST_ID_ATTR_NAME } from "components/testable/models";
import { Radio } from "components";
import { Flex } from "primitives";
import palette from "styles/palette";

const RadioCombo = (props: any) => {
  const { schema, formData, onChange } = props;

  let values: string[] = [];
  let labels: string[] = [];
  if (schema && schema.items && schema.items.enum) {
    values = schema.items.enum;
    labels = schema.items.enumNames || [];
  }

  const handleOnChange = (value: string) => {
    return value;
  };

  return (
    <Flex flexDirection="column" color={palette.palette.white[0]}>
      {values.map((value: string, index: number) => (
        <Radio
          name={schema.title.toLowerCase()}
          key={value}
          label={labels[index] || value}
          onChange={() => onChange(handleOnChange(value))}
        />
      ))}
    </Flex>
  );
};

RadioCombo.displayName = "RadioCombo";

RadioCombo.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: RadioCombo.displayName
};

export default RadioCombo;
