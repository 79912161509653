import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { DateTimePicker } from "components";
import { themeGet } from "styled-system";
import { isValidDate } from "app/shared/utils";

interface DatePickerProps {
  selected?: Date | string;
  handleChange: any;
  name: string;
  label: string;
  placeholder?: string;
  paddingTop?: string | undefined;
  margin?: string | undefined;
  pbLabel?: string | undefined;
  borderBottom?: string | undefined;
  box?: boolean;
  mb?: string | number;
}

const Label = styled.label`
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
`;

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

const StyledDiv = styled.div`
  background-color: #2e4162;
  height: 30px;
  border: 1px solid;
  border-color: ${themeGet("colors.text.default")}99;
  border-radius: 2px;
  margin-right: 8px;
`;

const DatePicker = (props: DatePickerProps) => {
  const [startDate, setStartDate] = useState(props.selected);

  useEffect(() => {
    setStartDate(props.selected);
  }, [props.selected]);

  const handleChange = (name: string, value: any) => {
    setStartDate(value);
    props.handleChange({ name, value });
  };

  const { paddingTop, margin, pbLabel, borderBottom, box, mb } = props;

  return (
    <Container style={{ marginBottom: mb }}>
      <Label
        style={{
          margin: margin ? `0 ${margin.slice(-4)}` : 0,
          paddingBottom: pbLabel ? pbLabel : 0
        }}
      >
        {props.label}
      </Label>
      {box ? (
        <StyledDiv>
          <DateTimePicker
            value={startDate || ""}
            onChange={(date: any) => {
              if (!isValidDate(date)) return;
              handleChange(props.name, date);
            }}
            position="absolute"
            paddingTop={paddingTop}
            margin={margin}
            borderBottom={borderBottom}
            {...props}
          />
        </StyledDiv>
      ) : (
        <DateTimePicker
          value={startDate || ""}
          onChange={(date: any) => {
            if (!isValidDate(date)) return;
            handleChange(props.name, date);
          }}
          position="absolute"
          paddingTop={paddingTop}
          margin={margin}
          borderBottom={borderBottom}
          {...props}
        />
      )}
    </Container>
  );
};

DatePicker.defaultProps = {
  dateFormat: "DD/MM/YYYY"
};

export default DatePicker;
