import styled from "styled-components";
import { FLEX, FlexProps } from "styles/constants";
import Box, { BoxProps } from "./Box";

export interface FlexLayoutProps extends FlexProps, BoxProps {}

const Flex = styled(Box)<FlexLayoutProps>`
  display: ${({ display }: FlexLayoutProps) => display || "flex"};
  ${FLEX}
`;

Flex.displayName = "Flex";

export default Flex;
