type UnitTypes = { [key: string]: number };
type AvailableUnits = { [key: string]: string[] };

export class UnitConverter {
  static unitConversions: { [unit: string]: UnitTypes } = {
    s: {
      seconds: 1,
      minutes: 1 / 60,
      hours: 1 / 3600,
      days: 1 / 86400,
      weeks: 1 / 604800,
      years: 1 / (60 * 60 * 24 * 365)
    },
    mA: {
      microampere: 1000,
      milliampere: 1,
      ampere: 0.001,
      kiloampere: 0.000001
    },
    mW: {
      microwatt: 1000,
      milliwatt: 1,
      watt: 0.001,
      kilowatt: 0.000001
    },
    mV: {
      microvolt: 1000,
      millivolt: 1,
      volt: 0.001,
      kilovolt: 0.000001
    }
  };

  getSymbol(inputUnit: string) {
    const SI_SYMBOLS: Record<string, string> = {
      seconds: "secs",
      minutes: "mins",
      hours: "hrs",
      days: "days",
      weeks: "weeks",
      years: "years",
      ampere: "A",
      microampere: "µA",
      milliampere: "mA",
      kiloampere: "kA",
      watt: "W",
      microwatt: "µW",
      milliwatt: "mW",
      kilowatt: "kW",
      volt: "V",
      microvolt: "µV",
      millivolt: "mV",
      kilovolt: "kV"
    };
    return SI_SYMBOLS[inputUnit.toLowerCase()] || "";
  }

  static getAvailableUnits = (): AvailableUnits => {
    const availableUnits: AvailableUnits = {};
    const units = UnitConverter.unitConversions;
    Object.keys(units).forEach((unit) => {
      availableUnits[unit] = Object.keys(units[unit]);
    });
    return availableUnits;
  };

  convert(unit: string, input: number, outputUnit: string): number {
    // some datasources have either ms and second as default units. convert ms to seconds for easier conversion
    if (unit === "ms") {
      input = input / 1000;
      unit = "s";
    }

    if (
      !UnitConverter.getAvailableUnits()[unit] ||
      !UnitConverter.unitConversions[unit]
    ) {
      // console.warn(`${unit} unit is not supported!`)
      return input;
    }

    const conversionFactors = UnitConverter.unitConversions[unit];
    if (conversionFactors[outputUnit]) {
      return input * conversionFactors[outputUnit];
    }
    // console.warn(`Cannot converting ${unit} into ${outputUnit}`)
    return input;
  }
}
