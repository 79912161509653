import { TelecommandListActions } from "../actions";
import {
  TelecommandListActionTypes,
  TelecommandListStoreState
} from "../models";

const initialState: TelecommandListStoreState = {
  responses: {}
};

const telecommandListReducer = (
  state = initialState,
  action: TelecommandListActions
): TelecommandListStoreState => {
  switch (action.type) {
    case TelecommandListActionTypes.TelecommandResponse:
      // @ts-ignore
      state.responses[action.executedTelecommandId] = action.response;
      return { ...initialState, responses: state.responses };
    case TelecommandListActionTypes.ClearResponse:
      return { ...initialState };
    default:
      return state;
  }
};

export default telecommandListReducer;
