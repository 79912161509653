import React, { CSSProperties } from "react";

export const initialContext = {
  top: 0,
  setTop: (value: number) => null,
  header: <></>,
  data: [],
  rowTemplate: <></>
};

export const SIZER_STYLES = {
  flexGrow: 1,
  display: "flex",
  flexFlow: "column",
  justifyContent: "flex-start",
  minHeight: "30%"
} as CSSProperties;

export const TABLE_STYLES = {
  position: "absolute",
  width: "100%",
  height: "auto",
  overflow: "auto"
} as CSSProperties;
