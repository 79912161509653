import { SHOW_DIALOG, HIDE_DIALOG } from "../models/constants";

export function showDialog(reference) {
  return {
    reference,
    type: SHOW_DIALOG
  };
}

export function hideDialog() {
  return {
    type: HIDE_DIALOG
  };
}
