import React, { Component } from "react";
import { createSystemDefinition } from "../services";
import { schemaGenerator, uiSchema } from "app/dataSource/models/schemas";
import { Form } from "app/shared";
import { AddButton, SaveButton, CancelButton } from "components";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Flex
} from "primitives";

export class SystemDefinitionCreate extends Component {
  state = {
    modalOpen: false
  };

  onChange(event) {
    const { name, value } = event.currentTarget;
    this.setState({ [name]: value });
  }

  hideDialog = () => {
    this.setState({ modalOpen: false });
  };

  openDialog = () => {
    this.setState({ modalOpen: true });
  };

  submit(e, { formData }) {
    e.preventDefault();
    const { record, parentDefinitionId } = this.props;
    createSystemDefinition(parentDefinitionId, record, formData)
      .then(({ data }) => {
        this.hideDialog();
        this.props.onCreate({ parentDefinitionId, ...data });
      })
      .catch((error) => {
        // this.props.showNotification(error.message, "error");
      });
  }

  render() {
    const { unitsList } = this.props;
    const { modalOpen } = this.state;
    const schema = schemaGenerator(unitsList);

    return (
      <>
        <AddButton onClick={() => this.openDialog()} mr={1}>
          System
        </AddButton>
        <Dialog
          open={modalOpen}
          maxWidth="md"
          data-testid="SystemDefinitionCreate"
        >
          <DialogTitle>Add System definition</DialogTitle>

          <DialogContent>
            <Form
              id="system-create"
              schema={schema}
              uiSchema={uiSchema}
              onSubmit={(data, e) => this.submit(e, data)}
            >
              <></>
            </Form>
          </DialogContent>
          <DialogActions>
            <Flex
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <SaveButton type="submit" form="system-create" mr={1}>
                Save
              </SaveButton>
              <CancelButton onClick={() => this.hideDialog()} mx={1}>
                Cancel
              </CancelButton>
            </Flex>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
