import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

interface ContainerProps {
  coord: { x: number; y: number };
}

interface HandleContextMenu {
  data?: any; // Any kind of data you want to pass to the MenuTemplate component
  menuTemplate: React.ComponentType<any>; // The content you want to show into the custom contextMenu (generally a list of actions)
  onClose?: VoidFunction;
}

const Container = styled("div")<ContainerProps>`
  position: absolute;
  top: ${(props) => props.coord.y}px;
  left: ${(props) => props.coord.x}px;
  border: 1px solid #ccc;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #181821;
  z-index: 1000;
  border-radius: 3px;
`;

export const useContextMenu = ({
  data,
  menuTemplate: MenuTemplate,
  onClose
}: HandleContextMenu) => {
  const [contextMenu, setContextMenu] = useState<React.ReactNode | null>(null);

  const handleContextMenu = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      const contextMenuSettings: React.ReactNode = (
        <Container coord={{ x: e.clientX, y: e.clientY }}>
          <MenuTemplate onClose={handleCloseContextMenu} data={data} />
        </Container>
      );

      setContextMenu(contextMenuSettings);
    },
    [data]
  );

  const handleCloseContextMenu = useCallback(() => {
    setContextMenu(null);
    onClose && onClose();
  }, [setContextMenu]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (contextMenu) {
        handleCloseContextMenu();
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [contextMenu, handleCloseContextMenu]);

  return { contextMenu, handleContextMenu };
};
