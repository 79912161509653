import { SpinBox, InputBase } from "app/shared";

/*

id -> id
name -> name
argType -> argType

Kind | Input status
manual -> disabled = false
auto -> ignore in frontend
constant -> disabled = true

dataType | Input type
int/double -> type: number
boolean -> type: checkbox
string -> type: string

range
min -> minimum
max -> maximum

default -> default

*/

class LiteralParser {
  /**
   * Mapping between dataType and html input types
   * @param {string} dataType
   * @returns {string}
   */
  getType(arg, dataType, groupParent) {
    switch (dataType) {
      case "int":
        return { type: "number" };
      case "double":
        if (groupParent) {
          return { type: "number" };
        }
        return { type: "string" };
      case "boolean":
        return { type: "checkbox" };
      default:
        return { type: "string" };
    }
  }

  /**
   *
   * @param {object} range
   * @returns {object}
   */
  getRange(data) {
    const range = {};
    const { min, max } = data || { min: null, max: null };

    if (min !== null) {
      range.minimum = min;
    }

    if (max !== null) {
      range.maximum = max;
    }

    return range;
  }

  /**
   *
   * @param {object} arg
   * @returns {object} schema literal
   */
  parse(arg, groupParent = false) {
    const {
      size,
      itemSpec,
      range,
      dataType,
      argType,
      default: defaultValue,
      ...rest
    } = arg;
    let defaultValueFormated = defaultValue;

    if (defaultValue && dataType === "int") {
      defaultValueFormated = parseInt(defaultValue, 10);
    }

    if (defaultValue && dataType === "double") {
      defaultValueFormated = parseFloat(defaultValue);
    }

    return {
      ...rest,
      argType,
      name: rest.id || rest.name,
      title: `${rest.name ? rest.name : ""}${
        size || dataType || rest.name ? ` -> ` : ``
      }${size || argType === "Array" ? `ARRAY(${size ? size : ""})` : ``}${
        itemSpec ? ` ${itemSpec.dataType}` : ``
      }${dataType ? `${dataType}` : ``}${
        itemSpec && itemSpec.range
          ? `[${itemSpec.range.min} .. ${itemSpec.range.max}]`
          : ``
      }${range ? `[${range.min} .. ${range.max}]` : ``}`,
      default: defaultValueFormated,
      ...this.getType(arg, dataType, groupParent),
      ...this.getRange(range)
    };
  }

  /**
   *
   * @param {object} arg
   * @returns {object} schema
   *
   */
  parseUI(arg, hideDefaults) {
    // const hiddenTypes = [schemaModels.AUTO_KIND, schemaModels.CONST_KIND];
    // if (hiddenTypes.includes(arg.kind)) {
    //   return { "ui:widget": "hidden" };
    // }

    if (arg.default !== undefined && hideDefaults) {
      return { "ui:widget": "hidden" };
    }

    if (arg.dataType === "int" && arg.range) {
      return {
        "ui:widget": SpinBox
      };
    }

    return {
      "ui:widget": InputBase
    };
  }
}

export const literalParser = new LiteralParser();
