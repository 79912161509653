import { Passage } from "../models";
import { PassageActions, PassageActionType } from "../actions";
import moment from "moment";

export interface SatellitePassages {
  [key: number]: {
    passages: Passage[];
    nextPassages: Passage[];
  };
}

interface PassageStoreState {
  selectedPassage: Passage | null;
  satellites: SatellitePassages;
  serverTime: Date;
  passages: Passage[];
}

const initialState: PassageStoreState = {
  satellites: {},
  selectedPassage: null,
  serverTime: new Date(),
  passages: []
};

export const passageReducer = (
  state: PassageStoreState = initialState,
  action: PassageActions
): PassageStoreState => {
  const { satellites } = state;
  switch (action.type) {
    case PassageActionType.FetchPassages:
      if (!satellites[action.payload.satelliteId])
        satellites[action.payload.satelliteId] = {
          passages: [],
          nextPassages: []
        };
      satellites[action.payload.satelliteId].passages = action.payload.passages;
      return { ...state, satellites: { ...satellites } };
    case PassageActionType.FetchNextPassages:
      if (!satellites[action.payload.satelliteId])
        satellites[action.payload.satelliteId] = {
          passages: [],
          nextPassages: []
        };
      satellites[action.payload.satelliteId].nextPassages =
        action.payload.passages;
      return { ...state, satellites: { ...satellites } };
    case PassageActionType.SelectPassage:
      return {
        ...state,
        selectedPassage: action.payload
      };
    case PassageActionType.FetchServerTime:
      return {
        ...state,
        serverTime: action.payload
      };
    case PassageActionType.TickTime:
      return {
        ...state,
        serverTime: moment(state.serverTime)
          .add(1, "seconds")
          .toDate()
      };
    case PassageActionType.FetchAllPassages:
      return {
        ...state,
        passages: action.payload
      };
    default:
      return state;
  }
};
