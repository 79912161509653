import React, { useEffect } from "react";
import { Telemetry } from "app/dataSource";
import { PassageCountdown } from "app/visibilityWindow/components/PassageCountdown";
import { TelecommandsList } from "app/telecommand/components/TelecommandsList";
import { TelecommandStack } from "app/telecommandStack/components/TelecommandStack/TelecommandStack";
import { TelecommandsExecution } from "app/telecommand/components/TelecommandExecution";
import { Procedure } from "app/procedure/components/ProcedureExecution";
import { VisibilityWindowsList } from "app/visibilityWindow/components/VisibilityWindowsList";
import { Flex } from "primitives";
import { DatasourceHistoryTableContainer } from "../../containers/DatasourceHistoryTableContainer";
import { ScriptExecutionConsole } from "app/scripting/components/ScriptConsole";
import { ScriptExecutionList } from "app/scripting/components/ScriptExecution";
import { OperationsList } from "app/resources_v2/components/OperationsList";
import config from "config/constants";
import { clone } from "utils";
import "../SatelliteTMDashboard.css";
import { useDispatch } from "react-redux";
// import { removeAllExtents } from "app/dashboard/actions";
import { ErrorBoundary } from "app/shared";
import {
  SimpleValueComponent,
  DataTableComponent,
  Cesium,
  SatelliteFramesList,
  LineChart,
  ConstellationTree,
  GrafanaIFrame,
  GraphRenderer,
  Gauge
} from "app/telemetry/visualizations";

// /**
//  * RenderDashboardComponentsByType help us
//  * to render the SatelliteDashboard TM.
//  * Must be wrapped to the Telemetry component
//  * to receive datasourceReadings
//  * @param {Array<Object>} component
//  */
//
export const RenderDashboardComponentsByType = ({
  component,
  satellite,
  options,
  updateConfigOptions,
  showModal,
  modalOpen,
  id
}: any) => {
  const dispatch = useDispatch();

  let dashboardComponent;
  let flexContainerOverflow = "auto";
  let flexContainerOverflowX = "auto";

  const datasourceIds = component.dataSources.map((it: any) => it.datasourceId);

  const datasourceLabels = {};
  component.dataSources.forEach(
    // @ts-ignore
    (ds: any) => (datasourceLabels[ds.datasourceId] = ds.label)
  );
  //FIXME: HERE
  switch (component.type) {
    case "Label":
      dashboardComponent = (
        <Telemetry
          satellite={satellite}
          satelliteDefinition={satellite.satelliteDefinition}
          ids={datasourceIds}
          interval={
            options && options.updatePeriod
              ? options.updatePeriod
              : config.timer.label
          }
          options={options}
        >
          {(props) => (
            <SimpleValueComponent
              {...props}
              id={id}
              showModal={showModal}
              options={{
                ...options,
                label: component.label
              }}
              type={component.type}
              // @ts-ignore
              updateConfigOptions={(configOptions) =>
                updateConfigOptions(clone(configOptions))
              }
            />
          )}
        </Telemetry>
      );
      break;
    case "RealTimeTable":
      dashboardComponent = (
        <Telemetry
          satellite={satellite}
          satelliteDefinition={satellite.satelliteDefinition}
          ids={datasourceIds}
          interval={
            options && options.updatePeriod
              ? options.updatePeriod
              : config.timer.table
          }
          options={options}
        >
          {(props) => (
            <DataTableComponent
              id={id}
              showModal={showModal}
              {...props}
              // @ts-ignore
              satellite={satellite}
              options={{ ...options, label: component.label, datasourceLabels }}
              type={component.type}
              updateConfigOptions={(configOptions) =>
                updateConfigOptions(clone(configOptions))
              }
            />
          )}
        </Telemetry>
      );
      break;
    case "Globe":
      flexContainerOverflow = "hidden";
      dashboardComponent = (
        <Cesium options={{ ...options, label: component.label }} />
      );
      break;
    case "TelecommandsSent":
      dashboardComponent = (
        <TelecommandsList
          type={component.type}
          id={id}
          showModal={showModal}
          satellite={satellite}
          options={{ ...options, perPage: 50, label: component.label }}
          updateConfigOptions={(configOptions) =>
            updateConfigOptions(clone(configOptions))
          }
        />
      );
      break;
    case "TelemetryReceived":
      dashboardComponent = (
        <SatelliteFramesList
          satellite={satellite}
          options={{ ...options, perPage: 50, label: component.label }}
        />
      );
      break;
    case "TelecommandsExecution":
      dashboardComponent = (
        <TelecommandsExecution
          satellite={satellite}
          options={{ ...options, label: component.label }}
        />
      );
      break;
    case "HistoricalTable":
      dashboardComponent = (
        <DatasourceHistoryTableContainer
          match={{ params: { constellationId: 1, id: satellite.id } }}
          options={{ ...options, label: component.label }}
        />
      );
      break;
    case "HistoricalGraph":
      dashboardComponent = (
        <DatasourceHistoryTableContainer
          match={{ params: { constellationId: 1, id: satellite.id } }}
          options={options}
        />
      );
      break;
    case "ProceduresExecution":
      dashboardComponent = <Procedure name={component.label} />;
      break;
    case "ScriptExecutionConsole":
      dashboardComponent = (
        <ScriptExecutionConsole
          refreshIntervalMillis={250}
          options={{ label: component.label }}
        />
      );
      break;
    case "ScriptExecutionList":
      dashboardComponent = <ScriptExecutionList name={component.label} />;
      break;
    case "RealTimeGraph":
      flexContainerOverflowX = "hidden";
      // add backward compatibility based on config value
      dashboardComponent = options?.useSVG ? (
        <LineChart
          id={id}
          showModal={showModal}
          satellite={satellite}
          ids={datasourceIds}
          type={component.type}
          options={{ ...options, label: component.label, datasourceLabels }}
          // @ts-ignore
          updateConfigOptions={(configOptions) =>
            updateConfigOptions(clone(configOptions))
          }
        />
      ) : (
        <Telemetry
          satellite={satellite}
          satelliteDefinition={satellite.satelliteDefinition}
          ids={datasourceIds}
          interval={
            options && options.updatePeriod
              ? options.updatePeriod
              : config.timer.label
          }
          options={options}
        >
          {(props) => {
            return (
              <GraphRenderer
                {...props}
                id={id}
                showModal={showModal}
                modalOpen={modalOpen}
                options={{
                  ...options,
                  label: component.label,
                  datasourceLabels
                }}
                type={component.type}
                // @ts-ignore
                updateConfigOptions={(configOptions) =>
                  updateConfigOptions(clone(configOptions))
                }
              />
            );
          }}
        </Telemetry>
      );
      break;
    case "ConstellationTree":
      dashboardComponent = (
        <ConstellationTree options={{ ...options, label: component.label }} />
      );
      break;
    case "VisibilityWindows":
      dashboardComponent = (
        <VisibilityWindowsList
          id={id}
          showModal={showModal}
          type={component.type}
          options={{ ...options, label: component.label }}
          // @ts-ignore
          updateConfigOptions={(configOptions) =>
            updateConfigOptions(clone(configOptions))
          }
        />
      );
      break;
    case "PassageCountdown":
      dashboardComponent = (
        <PassageCountdown
          id={id}
          showModal={showModal}
          // @ts-ignore
          updateConfigOptions={(configOptions) =>
            updateConfigOptions(clone(configOptions))
          }
          type={component.type}
          options={{ ...options, label: component.label }}
        />
      );
      break;
    case "OperationsList":
      dashboardComponent = (
        <OperationsList
          satellite={satellite}
          options={{ ...options, label: component.label }}
        />
      );
      break;
    case "Grafana":
      dashboardComponent = (
        <GrafanaIFrame
          // @ts-ignore
          satellite={satellite}
          options={{ ...options, label: component.label }}
        />
      );
      break;
    case "TelecommandStack":
      dashboardComponent = (
        <TelecommandStack
          satellite={satellite}
          options={{ ...options, label: component.label }}
        />
      );
      break;
    case "Histogram":
      dashboardComponent = (
        <Telemetry
          satellite={satellite}
          satelliteDefinition={satellite.satelliteDefinition}
          ids={datasourceIds}
          interval={
            options && options.updatePeriod
              ? options.updatePeriod
              : config.timer.label
          }
          options={options}
        >
          {(props) => (
            <GraphRenderer
              {...props}
              // @ts-ignore
              id={id}
              showModal={showModal}
              options={{
                ...options,
                label: component.label
              }}
              type={component.type}
              // @ts-ignore
              updateConfigOptions={(configOptions) =>
                updateConfigOptions(clone(configOptions))
              }
            />
          )}
        </Telemetry>
      );
      break;
    case "Gauge":
      dashboardComponent = (
        <Telemetry
          satellite={satellite}
          satelliteDefinition={satellite.satelliteDefinition}
          ids={datasourceIds}
          interval={
            options && options.updatePeriod
              ? options.updatePeriod
              : config.timer.label
          }
          options={options}
        >
          {(props) => (
            <GraphRenderer
              {...props}
              id={id}
              showModal={showModal}
              options={{
                ...options,
                label: component.label
              }}
              type={component.type}
              // @ts-ignore
              updateConfigOptions={(configOptions) =>
                updateConfigOptions(clone(configOptions))
              }
            />
          )}
        </Telemetry>
      );
      break;
    default:
      dashboardComponent = null;
      break;
  }

  return (
    <Flex
      alignSelf="stretch"
      alignItems="stretch"
      justifyContent="flex-start"
      flexDirection="column"
      height="100%"
      overflow={flexContainerOverflow}
      overflowX={flexContainerOverflowX}
    >
      <ErrorBoundary>{dashboardComponent}</ErrorBoundary>
    </Flex>
  );
};
