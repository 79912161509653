import {
  FETCH_CONSTELLATION_START,
  FETCH_CONSTELLATION_SUCCESS,
  FETCH_CONSTELLATION_ERROR,
  SELECT_CONSTELLATION,
  CLEAR_CONSTELLATION
} from "../models/constants";

const initialState = null;

/**
{
    "id": int,
    "label": string,
    "satelliteDefinitionSummary": {
      "satelliteDefinitionId": int,
      "name": string,
      "classes": Array<string>
    }
  }
*/

export const constellationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONSTELLATION_START:
    case CLEAR_CONSTELLATION:
    case FETCH_CONSTELLATION_ERROR:
      return initialState;
    case SELECT_CONSTELLATION:
    case FETCH_CONSTELLATION_SUCCESS:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};
