import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgMSTeams = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    viewBox="0 0 42 42"
    width={size || width}
    height={size || height}
    color={color}
  >
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M22.0319 6.96253C22.0319 7.84233 21.3187 8.55555 20.4389 8.55555C19.5591 8.55555 18.8458 7.84233 18.8458 6.96253C18.8458 6.08273 19.5591 5.36951 20.4389 5.36951C21.3187 5.36951 22.0319 6.08273 22.0319 6.96253Z"
          stroke="#ffffff"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>{" "}
        <mask id="path-2-inside-1_912_1868" fill="white">
          {" "}
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.5581 17.987C18.134 18.5067 18.8969 18.823 19.7337 18.8229H19.7491C21.5429 18.8229 22.997 17.3688 22.997 15.575V10.8694C22.997 10.3814 22.6013 9.98572 22.1133 9.98572H16.9584C17.4406 9.99765 17.8221 10.3979 17.8109 10.8801V16.2471C17.825 16.8544 17.7354 17.4401 17.5581 17.987Z"
          ></path>{" "}
        </mask>{" "}
        <path
          d="M19.7337 18.8229V17.8229H19.7336L19.7337 18.8229ZM17.5581 17.987L16.6068 17.6787L16.407 18.2952L16.8882 18.7294L17.5581 17.987ZM16.9584 9.98572V8.98572L16.9336 10.9854L16.9584 9.98572ZM17.8109 10.8801L16.8112 10.857L16.8109 10.8686V10.8801H17.8109ZM17.8109 16.2471H16.8109V16.2587L16.8112 16.2704L17.8109 16.2471ZM19.7336 17.8229C19.1538 17.823 18.6273 17.6049 18.2281 17.2446L16.8882 18.7294C17.6407 19.4085 18.6401 19.8231 19.7339 19.8229L19.7336 17.8229ZM19.7491 17.8229H19.7337V19.8229H19.7491V17.8229ZM21.997 15.575C21.997 16.8165 20.9906 17.8229 19.7491 17.8229V19.8229C22.0951 19.8229 23.997 17.9211 23.997 15.575H21.997ZM21.997 10.8694V15.575H23.997V10.8694H21.997ZM22.1133 10.9857C22.0491 10.9857 21.997 10.9337 21.997 10.8694H23.997C23.997 9.82908 23.1536 8.98572 22.1133 8.98572V10.9857ZM16.9584 10.9857H22.1133V8.98572H16.9584V10.9857ZM18.8106 10.9033C18.8346 9.86952 18.0169 9.01161 16.9831 8.98602L16.9336 10.9854C16.8644 10.9837 16.8096 10.9262 16.8112 10.857L18.8106 10.9033ZM18.8109 16.2471V10.8801H16.8109V16.2471H18.8109ZM18.5094 18.2953C18.7209 17.6428 18.8274 16.9452 18.8106 16.2239L16.8112 16.2704C16.8227 16.7637 16.7499 17.2373 16.6068 17.6787L18.5094 18.2953Z"
          fill="#ffffff"
          mask="url(#path-2-inside-1_912_1868)"
        ></path>{" "}
        <mask id="path-4-inside-2_912_1868" fill="white">
          {" "}
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.9503 6.02325C15.9503 7.14067 15.0445 8.04651 13.9271 8.04651C13.6783 8.04651 13.44 8.0016 13.2198 7.91946C13.2261 7.96126 13.2294 8.00405 13.2294 8.04761V8.96561C13.4534 9.01851 13.687 9.04651 13.9271 9.04651C15.5968 9.04651 16.9503 7.69295 16.9503 6.02325C16.9503 4.35356 15.5968 3 13.9271 3C12.2574 3 10.9038 4.35356 10.9038 6.02325C10.9038 6.43876 10.9877 6.8347 11.1393 7.19506H12.2775C12.0422 6.86442 11.9038 6.45999 11.9038 6.02325C11.9038 4.90584 12.8097 4 13.9271 4C15.0445 4 15.9503 4.90584 15.9503 6.02325Z"
          ></path>{" "}
        </mask>{" "}
        <path
          d="M13.2198 7.91946L13.5694 6.98255C13.2375 6.85871 12.8649 6.91971 12.5898 7.14292C12.3147 7.36613 12.1782 7.71823 12.231 8.06853L13.2198 7.91946ZM13.2294 8.96561H12.2294C12.2294 9.42933 12.5482 9.83222 12.9995 9.93882L13.2294 8.96561ZM11.1393 7.19506L10.2176 7.58296C10.3737 7.95386 10.7369 8.19506 11.1393 8.19506V7.19506ZM12.2775 7.19506V8.19506C12.6518 8.19506 12.9947 7.98606 13.1663 7.65342C13.3378 7.32078 13.3093 6.92018 13.0923 6.61524L12.2775 7.19506ZM13.9271 9.04651C15.5968 9.04651 16.9503 7.69295 16.9503 6.02325H14.9503C14.9503 6.58838 14.4922 7.04651 13.9271 7.04651V9.04651ZM12.8703 8.85636C13.2005 8.9796 13.5571 9.04651 13.9271 9.04651V7.04651C13.7995 7.04651 13.6794 7.0236 13.5694 6.98255L12.8703 8.85636ZM12.231 8.06853C12.2299 8.06116 12.2294 8.05412 12.2294 8.04761H14.2294C14.2294 7.95399 14.2224 7.86136 14.2087 7.77038L12.231 8.06853ZM12.2294 8.04761V8.96561H14.2294V8.04761H12.2294ZM13.9271 8.04651C13.7649 8.04651 13.6085 8.02763 13.4593 7.99239L12.9995 9.93882C13.2983 10.0094 13.609 10.0465 13.9271 10.0465V8.04651ZM15.9503 6.02325C15.9503 7.14067 15.0445 8.04651 13.9271 8.04651V10.0465C16.1491 10.0465 17.9503 8.24524 17.9503 6.02325H15.9503ZM13.9271 4C15.0445 4 15.9503 4.90584 15.9503 6.02325H17.9503C17.9503 3.80127 16.1491 2 13.9271 2V4ZM11.9038 6.02325C11.9038 4.90584 12.8097 4 13.9271 4V2C11.7051 2 9.90384 3.80127 9.90384 6.02325H11.9038ZM12.061 6.80717C11.9601 6.56739 11.9038 6.30317 11.9038 6.02325H9.90384C9.90384 6.57436 10.0152 7.102 10.2176 7.58296L12.061 6.80717ZM11.1393 8.19506H12.2775V6.19506H11.1393V8.19506ZM10.9038 6.02325C10.9038 6.67457 11.1109 7.28049 11.4628 7.77488L13.0923 6.61524C12.9735 6.44834 12.9038 6.24542 12.9038 6.02325H10.9038ZM13.9271 3C12.2574 3 10.9038 4.35356 10.9038 6.02325H12.9038C12.9038 5.45813 13.362 5 13.9271 5V3ZM16.9503 6.02325C16.9503 4.35356 15.5968 3 13.9271 3V5C14.4922 5 14.9503 5.45813 14.9503 6.02325H16.9503Z"
          fill="#ffffff"
          mask="url(#path-4-inside-2_912_1868)"
        ></path>{" "}
        <mask id="path-6-inside-3_912_1868" fill="white">
          {" "}
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.68371 17.4276C9.16343 19.7674 11.2055 21.5527 13.6947 21.6136C16.5887 21.5428 18.8783 19.1412 18.811 16.2471V10.8801C18.8221 10.3979 18.4407 9.99765 17.9584 9.98572H13.2295V16.5751C13.2295 17.0367 12.8626 17.4127 12.4044 17.4272C12.3953 17.4275 12.3862 17.4276 12.377 17.4276C12.3769 17.4276 12.3769 17.4276 12.3768 17.4276H8.68371Z"
          ></path>{" "}
        </mask>{" "}
        <path
          d="M8.68371 17.4276V16.4276C8.38327 16.4276 8.09876 16.5627 7.90884 16.7955C7.71893 17.0283 7.64375 17.3341 7.70409 17.6285L8.68371 17.4276ZM13.6947 21.6136L13.6702 22.6133C13.6865 22.6137 13.7029 22.6137 13.7192 22.6133L13.6947 21.6136ZM18.811 16.2471H17.811C17.811 16.2549 17.8111 16.2626 17.8112 16.2704L18.811 16.2471ZM18.811 10.8801L17.8112 10.857C17.8111 10.8647 17.811 10.8724 17.811 10.8801H18.811ZM17.9584 9.98572L17.9832 8.98602C17.9749 8.98582 17.9667 8.98572 17.9584 8.98572V9.98572ZM13.2295 9.98572V8.98572C12.6772 8.98572 12.2295 9.43343 12.2295 9.98572H13.2295ZM12.377 17.4276V16.4276L12.373 16.4276L12.377 17.4276ZM12.3768 17.4276V18.4276L12.3808 18.4276L12.3768 17.4276ZM7.70409 17.6285C8.27528 20.4144 10.7053 22.5408 13.6702 22.6133L13.7192 20.6139C11.7057 20.5646 10.0516 19.1204 9.66333 17.2268L7.70409 17.6285ZM13.7192 22.6133C17.1648 22.529 19.8909 19.6696 19.8107 16.2239L17.8112 16.2704C17.8657 18.6128 16.0126 20.5566 13.6702 20.6139L13.7192 22.6133ZM19.811 16.2471V10.8801H17.811V16.2471H19.811ZM19.8107 10.9033C19.8346 9.86953 19.0169 9.01161 17.9832 8.98602L17.9337 10.9854C17.8644 10.9837 17.8096 10.9262 17.8112 10.857L19.8107 10.9033ZM17.9584 8.98572H13.2295V10.9857H17.9584V8.98572ZM12.2295 9.98572V16.5751H14.2295V9.98572H12.2295ZM12.2295 16.5751C12.2295 16.4936 12.2955 16.4276 12.377 16.4276V18.4276C13.4001 18.4276 14.2295 17.5982 14.2295 16.5751H12.2295ZM12.373 16.4276L12.3729 16.4276L12.3808 18.4276L12.3809 18.4276L12.373 16.4276ZM12.3768 16.4276H8.68371V18.4276H12.3768V16.4276Z"
          fill="#ffffff"
          mask="url(#path-6-inside-3_912_1868)"
        ></path>{" "}
        <path
          d="M12.377 6.69507H3.84955C3.10256 6.69507 2.49699 7.30063 2.49699 8.04762V16.575C2.49699 17.322 3.10255 17.9276 3.84955 17.9276H12.377C13.124 17.9276 13.7295 17.322 13.7295 16.575V8.04762C13.7295 7.30064 13.124 6.69507 12.377 6.69507Z"
          stroke="#ffffff"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>{" "}
        <path
          d="M5.80132 10.1759H8.05131V15.1759V10.1759H10.3013"
          stroke="#ffffff"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>{" "}
      </g>
    </svg>
  </Svg>
);

SvgMSTeams.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgMSTeams;
