import { useMutation } from "./hooks";

interface SuspenseMutationResponse {
  response?: any;
  error?: any;
  loading: boolean;
  action: any;
}

interface SuspenseMutationProps {
  children: (props: SuspenseMutationResponse) => React.ReactElement;
}

export const SuspenseMutation = ({ children }: SuspenseMutationProps) => {
  const { response, loading, error, action } = useMutation();

  return children ? children({ loading, response, error, action }) : null;
};
