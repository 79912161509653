import { connect } from "react-redux";
import { ConstellationEdit } from "../components/ConstellationEdit";
import { getConstellation, updateConstellation } from "app/constellation";
import { withRouter } from "react-router-dom";
import { setFeedback } from "app/feedback/actions";

const mapStateToProps = (state) => ({
  selected: state.constellations.selected
});

const mapDispatchToProps = (dispatch) => {
  return {
    getConstellation: (id) => getConstellation(id),
    updateConstellation: (data) => updateConstellation(data),
    setFeedback: (title, status, details = "") =>
      dispatch(setFeedback(title, status, details))
  };
};

export const ConstellationEditContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConstellationEdit)
);
