import React, { FC } from "react";
import { Box, Flex, Text } from "primitives";
import styled from "styled-components";

interface RenderParamsProps {
  telecommand: any;
}
const RenderParams: FC<RenderParamsProps> = (props) => {
  const { telecommand } = props;

  return (
    <Flex overflow="visible">
      {renderNonDefaultParams(
        telecommand.nonDefaultParams,
        telecommand.telecommand.params
      )}
    </Flex>
  );
};

function renderNonDefaultParams(nonDefaults: any, params: any) {
  const paramComponents: any = [];
  if (nonDefaults) {
    return Object.entries(params).map((param, idx) => {
      renderRecursiveParams(nonDefaults, param, idx, paramComponents);
      return [...new Set(paramComponents)];
    });
  }
  return null;
}

function renderRecursiveParams(
  nonDefaults: any,
  param: any,
  idx: any,
  paramComponents: any
) {
  if (nonDefaults.includes(param[0])) {
    paramComponents.push(renderParams(param, idx));
  } else if (typeof param[1] === "object") {
    Object.entries(param[1]).map((param2, idx2) => {
      return renderRecursiveParams(nonDefaults, param2, idx2, paramComponents);
    });
  }
}

const addSpaces = (string: string) => {
  return string
    .split(",")
    .map((str) => str.trim())
    .join(", ");
};

function renderParams(params: any, idx: any) {
  const paramValue = String(params[1]);
  return (
    <Flex
      mr={2}
      ml={2}
      overflow="visible"
      key={idx}
      flexDirection="column"
      alignItems="center"
      borderLeft={"0.5px dashed"}
      borderColor="#6E8093"
      pl="20px"
    >
      <Text color="text.default" fontSize={3}>
        {params[0]}
      </Text>
      <Wrap len={paramValue.length}>
        <Text color="text.white" fontSize={4} p="2%" wrap>
          {addSpaces(paramValue)}
        </Text>
      </Wrap>
    </Flex>
  );
}

const Wrap = styled(Box)<{ len: number }>`
  overflow: visible !important;
  overflow-wrap: ${(props) => (props.len > 20 ? "break-word" : "none")};
  max-width: 304px;
`;

export default RenderParams;
