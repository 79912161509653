import { connect } from "react-redux";
import { SystemDefinitionCreate } from "../components/SystemDefinitionCreate";

const mapStateToProps = (state) => ({
  unitsList: state.dataSources.units
});

const mapDispatchToProps = () => {
  return {};
};

export const SystemDefinitionCreateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemDefinitionCreate);
