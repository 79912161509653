import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgAlert = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 15.002 15"
    color={color}
  >
    <g>
      <path d="M6.751 6h1.5v3.75h-1.5z" />
      <path d="M6.751 10.5h1.5V12h-1.5z" />
      <path d="M14.922 13.915L8.17.415a.749.749 0 0 0-1.341 0l-6.75 13.5A.749.749 0 0 0 .749 15h13.5a.75.75 0 0 0 .673-1.085zM1.964 13.5L7.501 2.427 13.037 13.5z" />
    </g>
  </Svg>
);

SvgAlert.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgAlert;
