import React, { Component } from "react";
import { generateReference } from "app/shared/utils";
import { Dialog as DialogRA, DialogTitle, DialogContent } from "primitives";

class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reference: generateReference(),
      error: false
    };
  }

  handleOpenDialog = () => {
    this.props.showDialog(this.state.reference);
  };

  handleCloseDialog = () => {
    this.props.hideDialog();
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  handleAction = () => {
    if (this.props.onAction) {
      this.props.onAction();
    }
  };

  opened() {
    return (
      this.props.dialog.show &&
      this.props.dialog.reference === this.state.reference
    );
  }

  render() {
    const {
      classes,
      record,
      maxWidth,
      fullScreen,
      title,
      children,
      button,
      actions
    } = this.props;

    return (
      <>
        {button({ title, classes, handleOpenDialog: this.handleOpenDialog })}
        <DialogRA
          fullScreen={fullScreen}
          open={this.opened()}
          maxWidth={maxWidth}
          onClose={this.handleCloseDialog}
        >
          <DialogTitle>
            {title} {record && <span>#{record.id}</span>}
          </DialogTitle>
          <DialogContent>{children}</DialogContent>
          {actions({
            title,
            classes,
            handleAction: this.handleAction,
            handleCloseDialog: this.handleCloseDialog
          })}
        </DialogRA>
      </>
    );
  }
}

export const DialogBase = Dialog;
