import React, { Component } from "react";
import { Box, Text, Flex } from "primitives";
import { clone } from "utils";
import {
  execTelecommand,
  getTelecommands,
  uploadTelecommand
} from "../services";
import { DashboardComponents } from "app/dashboard/models";
import { connect } from "react-redux";
import {
  TelecommandSelectionStep,
  TelecommandExecutionModeStep
} from "./TelecommandExecutionHelpers";
import { TelecommandCreationForm } from "./TelecommandExecutionForm";
import { TelecommandTerminal } from "./TelecommandExecutionTerminal";
import { TelecommandExecutionMode, AuroraTelecommandPayload } from "../models";
import {
  selectTelecommand,
  selectTelecommandExecutionMode,
  clearSelectedTelecommand
} from "../actions";
import { FeedbackStatus } from "app/feedback/models";
import { setFeedback } from "app/feedback/actions";
import { tcStack } from "app/telecommandStack/actions";
import { HttpErrorStatus } from "app/network/models";
import { Header } from "app/dashboard/components/Header";

class TelecommandExecution extends Component {
  constructor(props) {
    super(props);
    this.willUnmount = false;
    this.state = {
      telecommandSpecs: null,
      currentTelecommandSpec: null,
      file: null,
      loading: false
    };

    this.selectTelecommandAction = this.selectTelecommandAction.bind(this);
    this.selectModeAction = this.selectModeAction.bind(this);
    this.submitTelecommand = this.submitTelecommand.bind(this);
    this.uploadTelecommand = this.uploadTelecommand.bind(this);
  }

  componentWillUnmount() {
    this.willUnmount = true;
  }

  selectTelecommandAction(telecommandId) {
    const { dispatchTelecommandSelectAction } = this.props;
    dispatchTelecommandSelectAction(telecommandId);
  }

  selectModeAction(mode) {
    const { dispatchTelecommandExecutionModeAction } = this.props;
    dispatchTelecommandExecutionModeAction(mode);
  }

  submitTelecommand(data) {
    const { satellite, selectedPassage } = this.props;
    const { currentTelecommandSpec } = this.state;
    const telecommandExecutionPayload = AuroraTelecommandPayload.from(
      clone(currentTelecommandSpec),
      data.formData ? clone(data.formData) : data
    );
    if (selectedPassage && selectedPassage.passageID) {
      telecommandExecutionPayload.setPassageId(selectedPassage.passageID);
      try {
        execTelecommand(
          satellite.id,
          telecommandExecutionPayload.toOutputModel()
        )
          .then((res) => {
            this.props.setFeedback(
              "Telecommand successfully submited",
              FeedbackStatus.SUCCESS
            );
          })
          .catch((err) => {
            this.props.setFeedback(
              `Error submiting telecommand: ${HttpErrorStatus[err.status]}`,
              FeedbackStatus.ERROR
            );
          });
      } catch (error) {
        this.props.setFeedback(error, FeedbackStatus.ERROR);
      }
    } else {
      this.props.setFeedback("No passage selected", FeedbackStatus.ERROR);
    }
  }

  uploadTelecommand(file) {
    const { satellite } = this.props;
    const data = new FormData();
    data.append("data", file);
    uploadTelecommand(satellite.id, data);
  }

  updateCurrentTelecommandSpec(telecommandId) {
    const { telecommandSpecs } = this.state;
    this.setState({ currentTelecommandSpec: null });
    if (!telecommandId) return;
    const currentTc = telecommandSpecs.filter(
      (tc) => tc.id === telecommandId
    )[0];
    if (currentTc) this.setState({ currentTelecommandSpec: currentTc });
  }

  componentWillMount() {
    const { options } = this.props;
    if (options && options.defaults && options.defaults.fill) {
      this.selectModeAction(options.defaults.fill);
    } else {
      this.selectModeAction(TelecommandExecutionMode.Form);
    }
  }

  componentDidMount() {
    const { satellite } = this.props;
    this.props.clearSelectedTelecommand();

    getTelecommands(satellite.satelliteDefinitionSummary.satelliteDefinitionId)
      .then((response) => {
        if (!this.willUnmount && response && response.data) {
          this.setState({ telecommandSpecs: response.data }, () => {
            if (this.props.selectedTelecommandId) {
              this.updateCurrentTelecommandSpec(
                this.props.selectedTelecommandId
              );
            }
          });
        }
      })
      .catch((error) => {
        this.setState({ telecommandSpecs: null });
      });
  }

  componentDidUpdate(prevProps) {
    const { options, selectedExecutionMode } = this.props;
    if (prevProps.selectedTelecommandId !== this.props.selectedTelecommandId) {
      this.updateCurrentTelecommandSpec(this.props.selectedTelecommandId);
    }
    if (!selectedExecutionMode) {
      if (options && options.defaults && options.defaults.fill) {
        if (options.defaults.fill === "none") this.selectModeAction(null);
        else this.selectModeAction(options.defaults.fill);
      } else if (prevProps.selectedExecutionMode) {
        this.selectModeAction(prevProps.selectedExecutionMode);
      } else {
        this.selectModeAction(TelecommandExecutionMode.Form);
      }
    }
  }

  render() {
    const {
      selectedTelecommandId,
      selectedExecutionMode,
      formData,
      selectedTelecommandRecordId,
      options,
      satellite,
      addToStack,
      telecommandStacks,
      selectedPassage
    } = this.props;
    const { telecommandSpecs, currentTelecommandSpec } = this.state;

    return (
      <Box minWidth={326} height="100%">
        {options.label && (
          <Text fontSize={18} m="10px 0">
            {options.label}
          </Text>
        )}

        <Box
          bg="fill.0"
          p={2}
          data-testid="telecommand-execution"
          height={options.label ? `calc(100% - 21px)` : "100%"}
        >
          <Header my={12} ml={0}>
            <Flex width="100%" overflow="visible">
              <Box width="100%" overflow="visible">
                <TelecommandSelectionStep
                  telecommandSpecs={telecommandSpecs}
                  selectTelecommandAction={this.selectTelecommandAction}
                  currentTelecommandId={selectedTelecommandId}
                />
                <TelecommandExecutionModeStep
                  selectModeAction={this.selectModeAction}
                  executionMode={selectedExecutionMode}
                  options={options}
                />

                {/* {selectedTelecommandId &&
            selectedExecutionMode === TelecommandExecutionMode.JsonUpload && (
              <TelecommandFileUpload
                uploadTelecommand={this.uploadTelecommand}
              />
            )} */}
                {selectedTelecommandId &&
                  currentTelecommandSpec &&
                  selectedExecutionMode === TelecommandExecutionMode.Form && (
                    <TelecommandCreationForm
                      key={selectedTelecommandRecordId}
                      initialFormData={formData || null}
                      onSubmitTelecommandHandler={this.submitTelecommand}
                      satelliteId={satellite.id}
                      satelliteLabel={satellite.label}
                      defaultFieldsVisualization="defaultHidden"
                      automaticFill={!(options.defaults.automatic === false)}
                      sendConfirmation={options.send.sendConfirmation === true}
                      preventMultiSend={
                        !(options.send.preventMultiSend === false)
                      }
                      addToStack={addToStack}
                      telecommandStacks={telecommandStacks}
                      selectedPassage={selectedPassage}
                      setFeedback={this.props.setFeedback}
                      selectedTelecommandId={selectedTelecommandId}
                      telecommandSpecs={telecommandSpecs}
                    />
                  )}

                {selectedExecutionMode ===
                  TelecommandExecutionMode.Terminal && (
                  <TelecommandTerminal
                    telecommandSpecs={telecommandSpecs}
                    selectTelecommandAction={this.selectTelecommandAction}
                    key={selectedTelecommandRecordId}
                    telecommandSpec={currentTelecommandSpec}
                    onSubmitTelecommandHandler={this.submitTelecommand}
                    automaticFill={!(options.defaults.automatic === false)}
                    sendConfirmation={options.send.sendConfirmation === true}
                    preventMultiSend={options.send.preventMultiSend === true}
                  />
                )}
              </Box>
            </Flex>
          </Header>
        </Box>
      </Box>
    );
  }
}

TelecommandExecution.defaultProps = {
  ...DashboardComponents.TelecommandsExecution,
  label: ""
};

const mapStateToProps = (state) => {
  return {
    selectedTelecommandId: state.telecommandExecution.telecommandId,
    selectedTelecommandRecordId: state.telecommandExecution.telecommandRecordId,
    formData: state.telecommandExecution.telecommandFormData,
    selectedExecutionMode: state.telecommandExecution.telecommandExecutionMode,
    selectedPassage: state.visibilityWindow.selectedPassage,
    telecommandStacks: state.telecommandStack
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchTelecommandSelectAction: (telecommandId) =>
      dispatch(selectTelecommand(telecommandId)),

    dispatchTelecommandExecutionModeAction: (executionModeValue) => {
      try {
        let executionMode;
        switch (executionModeValue) {
          case TelecommandExecutionMode.Form:
            executionMode = TelecommandExecutionMode.Form;
            break;
          case TelecommandExecutionMode.JsonUpload:
            executionMode = TelecommandExecutionMode.JsonUpload;
            break;
          case TelecommandExecutionMode.Terminal:
            executionMode = TelecommandExecutionMode.Terminal;
            break;
          default:
            throw Error(
              `Unknown telecommand execution mode ${executionModeValue}. Supported values are: ${Object.values(
                TelecommandExecutionMode
              )}`
            );
        }

        dispatch(selectTelecommandExecutionMode(executionMode));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    },
    clearSelectedTelecommand: () => dispatch(clearSelectedTelecommand()),
    setFeedback: (title, status, details = "") =>
      dispatch(setFeedback(title, status, details)),
    addToStack: (telecommand, telecommandStack) =>
      tcStack.add(telecommand, telecommandStack)
  };
};

export const TelecommandsExecution = connect(
  mapStateToProps,
  mapDispatchToProps
)(TelecommandExecution);
