import React from "react";
import { SuspenseMutation } from "app/network";
import { Toggler } from "components";
import { DeleteButton, CancelButton } from "components";
import { connect } from "react-redux";
import { deleteProcedure } from "../services";
import { Procedure } from "../models";
import { Flex, Dialog, DialogTitle, DialogActions } from "primitives";

interface ProcedureDeleteProps {
  procedure: Procedure;
  onChange: () => void;
  deleteProcedure: (id: number) => Promise<any>;
}

export const ProcedureDelete = ({
  procedure,
  onChange,
  ...props
}: ProcedureDeleteProps) => {
  const submit = async (onToggle: () => void) => {
    await props.deleteProcedure(procedure.id);
    if (onChange) {
      onChange();
    }
    onToggle();
  };

  return (
    <Toggler>
      {([toggled, onToggle]) => (
        <>
          <DeleteButton onClick={onToggle}>Delete</DeleteButton>

          <Dialog open={toggled} maxWidth="sm" data-testid="ProcedureDelete">
            <SuspenseMutation>
              {({ loading, action }) => {
                return (
                  <>
                    <DialogTitle>Delete {procedure.name}?</DialogTitle>

                    <DialogActions>
                      <Flex alignItems="center" mx={1}>
                        <DeleteButton
                          onClick={() => action(() => submit(onToggle))}
                          mr={1}
                          disabled={loading}
                        >
                          Delete
                        </DeleteButton>
                        <CancelButton onClick={onToggle}>Cancel</CancelButton>
                      </Flex>
                    </DialogActions>
                  </>
                );
              }}
            </SuspenseMutation>
          </Dialog>
        </>
      )}
    </Toggler>
  );
};

const mapDispatchToProps = () => ({
  deleteProcedure
});

export const ProcedureDeleteContainer = connect(
  null,
  mapDispatchToProps
)(ProcedureDelete);
