import React, { useEffect, useRef } from "react";
import { GraphBuilder } from "./GraphBuilder";
import { GraphProps, isGauge, isTEST } from "./models";

const GraphBase = ({ width, height, data, configOptions }: GraphProps) => {
  const axesRef = useRef<SVGElement>(null);
  const graph = GraphBuilder.build(data, configOptions, height, width, axesRef);

  useEffect(() => {
    // TODO: refactor gauge tests to prevent needing a force re-render in tests
    if (isGauge(configOptions.widgetName) && isTEST) {
      graph.draw();
    }
  }, [data, configOptions]);
  return <>{graph.draw()}</>;
};

export const GraphBaseComponent = React.memo(
  GraphBase,
  (prevProps: GraphProps, nextProps: GraphProps) =>
    prevProps.modalOpen !== nextProps.modalOpen
);
