import React, { useState } from "react";
import { Box, Flex } from "primitives";
import styled from "styled-components";
import { themeGet } from "styled-system";
import { Tab } from "./Tab";

interface TabInputProps {
  tabs: string[];
  containers: (val: number, record: any) => JSX.Element;
  record?: any;
  disabled?: null | string[];
  margin?: string | number;
  color?: string;
}

export const TabInput = ({
  tabs,
  containers,
  record = null,
  disabled = null,
  margin = "0",
  color = themeGet("colors.text.white")
}: TabInputProps) => {
  const [value, setValue] = useState(0);

  const handleChange = (val: number) => setValue(val);

  return (
    <>
      <Box m={margin} overflow="visible">
        <Flex>
          <Box>
            <Flex>
              {tabs.map((label, index) => (
                <Flex flexDirection="column">
                  <Tab
                    key={label}
                    index={index}
                    label={label}
                    value={value}
                    color={color}
                    disabled={disabled && disabled.indexOf(label) !== -1}
                    onClick={handleChange}
                  ></Tab>
                  {index === value && <Underline />}
                </Flex>
              ))}
            </Flex>
          </Box>
        </Flex>
      </Box>
      {containers(value, record)}
    </>
  );
};

const Underline = styled.span`
  width: 100%;
  background-color: ${themeGet("colors.text.default")};
  height: 2px;
  bottom: 0;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;
