import React, { FormEvent, useState, FC, useEffect } from "react";
import { Form } from "app/shared";
import { clone } from "utils";
import { SaveButton, CancelButton } from "components";
import {
  configOptionsSchemaGenerator,
  configOptionsUISchemaGenerator
} from "app/dashboard/models/component";
import { GroundStation } from "app/groundStation";
import { SatelliteInstance } from "app/satellite/models";
import { DialogTitle, DialogActions, DialogContent, Dialog } from "primitives";

interface EditDashboardConfigurationModalProps {
  type: string;
  modalOpen: boolean;
  formData: any;
  updateConfigOptions: (formData: any) => void;
  closeModal: () => void;
  groundStations?: GroundStation[] | undefined;
  satelliteInstances?: SatelliteInstance[] | undefined;
  validator?: (formData: any, errors: any) => {};
  addChange?: () => void;
}

const EditDashboardConfigurationModal: FC<
  EditDashboardConfigurationModalProps
> = (props) => {
  const {
    type,
    modalOpen,
    groundStations,
    satelliteInstances,
    formData,
    updateConfigOptions,
    closeModal,
    validator,
    addChange
  } = props;
  const [data, setData] = useState<any>(clone(formData));

  // eslint-disable-next-line
  useEffect(() => setData(clone(formData)), [modalOpen]);

  useEffect(() => {
    const onKeyEsc = (e: { key: string }) => {
      if (e.key === "Escape") {
        closeModal();
      }
    };
    window.addEventListener("keyup", onKeyEsc);
    return () => window.removeEventListener("keyup", onKeyEsc);
  }, []);

  const configOptionsSchema = configOptionsSchemaGenerator(
    { type, ...formData },
    satelliteInstances ? satelliteInstances : [],
    groundStations ? groundStations : []
  );

  const configOptionsUISchema = configOptionsUISchemaGenerator({ type });

  const submit = (e: FormEvent, { formData: formDataToSubmit }: any) => {
    e.preventDefault();
    updateConfigOptions(formDataToSubmit);
    addChange && addChange();
    closeModal();
  };

  return (
    <Dialog open={modalOpen} maxWidth="md">
      <DialogTitle>Edit Configuration Options</DialogTitle>
      <DialogContent overflow>
        <Form
          // Note:Enable validate if validator for each widget is available, only grafana config form supports validation for now
          noValidate={!validator}
          validate={validator}
          id="edit-config-options"
          formData={data ? clone(data) : {}}
          schema={configOptionsSchema}
          uiSchema={configOptionsUISchema}
          onChange={(form: any, e: React.FormEvent<HTMLInputElement>) => {
            setData(clone(form.formData));
          }}
          onSubmit={(form: any, e: React.FormEvent<HTMLInputElement>) => {
            return submit(e, form);
          }}
        >
          <></>
        </Form>
      </DialogContent>
      <DialogActions>
        <SaveButton type="submit" form="edit-config-options" mr={1}>
          Save
        </SaveButton>
        <CancelButton mx={1} onClick={() => closeModal()}>
          Cancel
        </CancelButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditDashboardConfigurationModal;
