/**
 * @description Given a starting time(now) and an expiration time it calculate
 * and return the remaining time from now to the expiration time passed in this format `dd, hh, mm, ss`
 * @param now {ISOString}
 * @param expirationDate {ISOString}
 * @returns {string} Remaining time in dd, hh, mm, ss
 */
export const calculateRemainingTime = (expirationDateTime: Date): string => {
  try {
    // Check if the dates are valid
    if (isNaN(expirationDateTime.getTime())) {
      throw new Error("Invalid date format");
    }
    const currentDateTime = new Date();
    // Calculate the time difference in milliseconds between the two dates
    const timeDifferenceInMilliseconds =
      expirationDateTime.getTime() - currentDateTime.getTime();

    // Calculate the remaining time in hours
    const remainingTimeInHours =
      timeDifferenceInMilliseconds / (1000 * 60 * 60);

    // Format the remaining time as dd, hh, mm, ss
    const totalHours = Math.floor(remainingTimeInHours);
    if (remainingTimeInHours <= 0) return `00h, 00m, 00s`;

    const days = Math.floor(totalHours / 24);
    const hours = Math.floor(totalHours % 24);
    const minutes = Math.floor((remainingTimeInHours - totalHours) * 60);
    const seconds = Math.floor(
      ((remainingTimeInHours - totalHours) * 60 - minutes) * 60
    );

    const formattedDay = `${String(days).padStart(2, "0")}d, `;
    const formattedHours = `${String(hours).padStart(2, "0")}h`;
    const formattedMinutes = `${String(minutes).padStart(2, "0")}m`;
    const formattedSeconds = `${String(seconds).padStart(2, "0")}s`;

    const formattedRemainingTime = `${
      (days > 0 && formattedDay) || ""
    }${formattedHours}, ${formattedMinutes}, ${formattedSeconds}`;

    return formattedRemainingTime;
  } catch (error) {
    console.log(error);
    return `Error calculating the remaining time: ${error}`;
  }
};
