import React from "react";
import { createPortal } from "react-dom";
import useNode from "./hooks/useNode";

interface PortalProps {
  children: React.ReactNode | React.ReactNode[] | string;
  type?: string;
}

const Portal = ({ children, type = "portal" }: PortalProps) => {
  const node = useNode(type);
  if (!node) return null;
  return createPortal(children, node);
};

export default Portal;
