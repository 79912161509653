import React from "react";
import { Header } from "app/dashboard/components/Header";
import { Icon, Box, Flex } from "primitives";
import { TelecommandListHeaderProps } from "./models";

export const TelecommandListHeader = ({
  showModal,
  type,
  id
}: TelecommandListHeaderProps) => (
  <Header my={12} ml={2}>
    <Flex width={"100%"} justifyContent={"end"}>
      <Box cursor="pointer" padding={2} onClick={() => showModal(type, id)}>
        <Icon name={"SettingsMenu"} size={20} id="settings-gear" />
      </Box>
    </Flex>
  </Header>
);
