import React from "react";
import { InputField } from "components";
import { CONST_KIND } from "app/telecommand/models";

const Input = ({
  id,
  value,
  readonly: readOnly,
  autofocus: autoFocus,
  disabled,
  onBlur,
  onFocus,
  onChange,
  schema,
  classes,
  options,
  rawErrors,
  required,
  multiline,
  rows,
  width,
  maxLength,
  ...props
}) => {
  const {
    multipleOf: step,
    minimum: min,
    maximum: max,
    type,
    kind,
    fixedValue,
    password
  } = schema;

  if (fixedValue !== undefined && value !== fixedValue) onChange(fixedValue);

  const _onChange = ({ target: { value: fieldValue } }) => {
    return onChange(fieldValue === "" ? options.emptyValue : fieldValue);
  };
  const displayValue = (value) => {
    if (
      (id.includes("password") && value === "") ||
      (id.includes("username") && value === "")
    ) {
      return "********";
    }
    return value;
  };
  return (
    <InputField
      id={id}
      error={rawErrors && rawErrors.length > 0}
      type={password ? "password" : Array.isArray(type) ? type[0] : type}
      inputProps={{
        step,
        min,
        max,
        readOnly,
        disabled,
        autoFocus,
        "data-testid": id
      }}
      multiline={multiline}
      rows={rows}
      value={displayValue(value)}
      autoFocus={autoFocus}
      onChange={_onChange}
      onBlur={onBlur && ((event) => onBlur(props.id, event.target.value))}
      onFocus={onFocus && ((event) => onFocus(props.id, event.target.value))}
      disabled={disabled || (kind && kind === CONST_KIND)}
      width={width}
      maxLength={maxLength}
    />
  );
};

export const InputBase = Input;
