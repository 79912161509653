import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgPause = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    viewBox="0 0 60 60"
    width={size || width}
    height={size || height}
    color={color}
  >
    <path d="M30 0C13.458 0 0 13.458 0 30s13.458 30 30 30 30-13.458 30-30S46.542 0 30 0zm0 58C14.561 58 2 45.439 2 30S14.561 2 30 2s28 12.561 28 28-12.561 28-28 28z" />
    <path d="M33 46h8V14h-8v32zm2-30h4v28h-4V16zM19 46h8V14h-8v32zm2-30h4v28h-4V16z" />
  </Svg>
);

SvgPause.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgPause;
