import React from "react";
import { themeGet } from "styled-system";
import { theme } from "styles";
import { Flex, Text } from "primitives";
import styled from "styled-components";
import { FeedbackList } from "app/feedback/components/FeedbackList";
const year = new Date().getFullYear();

const FooterBarStyled = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  ${themeGet("mediaQueries.md")} {
    flex-direction: row;
    align-items: space-between;
    justify-content: flex-end;
  }
`;

FooterBarStyled.defaultProps = {
  height: themeGet("sizes.footerHeight")({ theme }),
  color: "text.label",
  fontSize: 3,
  borderTop: 3,
  borderColor: "border.1",
  px: 3
};

const FooterContainer = styled(Flex)`
  fontsize: 11;
  justify-content: space-between;
  width: 100%;
`;

const FeedbackListFooter = (FeedbackList as unknown) as React.ElementType<{
  showInFooter: boolean;
}>;

const CopyRight = () => (
  <Text>
    &copy; {year} AURORA Mission Control Platform powered by D-ORBIT Portugal
  </Text>
);

const FooterBar = () => {
  return (
    <FooterBarStyled>
      <FooterContainer>
        <FeedbackListFooter showInFooter />
        <CopyRight />
      </FooterContainer>
    </FooterBarStyled>
  );
};
export default FooterBar;
