import styled, { css, DefaultTheme } from "styled-components";
import { rgba } from "polished";
import { themeGet, backgroundColor as getBackgroundColor } from "styled-system";
import {
  COMMON,
  LAYOUT,
  BORDER,
  POSITION,
  CommonProps,
  LayoutProps,
  BorderProps,
  PositionProps
} from "styles/constants";
import { TestableProps } from "components/testable/models";

export interface BoxProps
  extends LayoutProps,
    CommonProps,
    BorderProps,
    TestableProps,
    PositionProps {
  bgOpacity?: number | string;
  borderOpacity?: number | string;
  theme?: DefaultTheme;
  overflowX?: string;
  cursor?: string;
  bgGradient?: string;
}

export const backgroundColorOpacity = ({ bg, bgOpacity, theme }: BoxProps) => {
  if (bgOpacity === undefined) return null;
  if (bg) {
    const { backgroundColor } = getBackgroundColor({ bg, theme });

    const opacity = themeGet(`opacities.${bgOpacity}`, "1")({ theme });
    return css({
      backgroundColor: rgba(backgroundColor, opacity)
    });
  }

  return null;
};

export const borderColorOpacity = ({
  borderColor,
  borderOpacity,
  theme
}: BoxProps) => {
  if (borderOpacity === undefined) return null;
  if (borderColor) {
    const color = themeGet(`colors.${borderColor}`, "")({ theme });

    const opacity = themeGet(`opacities.${borderOpacity}`, "5")({ theme });

    return css({
      borderColor: rgba(color, opacity)
    });
  }

  return null;
};

const Box = styled("div")<BoxProps>`
  ${COMMON}
  ${LAYOUT}
  ${BORDER}
  ${POSITION}
  ${backgroundColorOpacity}
  ${borderColorOpacity}
  cursor: ${({ cursor }: BoxProps) => cursor || "auto"};
  ${(props) =>
    `overflow: ${props.overflow !== "visible" ? "hidden" : "visible"};`}
  &:hover {
    ${(props) => `
    overflow: ${props.overflow || "auto"};
    overflow-x: ${props.overflowX ? props.overflowX : props.overflow}`}
  }
  background: ${(props) => props.bgGradient};
`;

Box.displayName = "Box";

export default Box;
