import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createGroundStationConfig } from "../services";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { fetchGroundStationsAction } from "../actions";
import { setFeedback } from "app/feedback/actions";
import GroundStationConfigCreate from "app/groundStation/components/GroundStationConfigCreate";

const mapStateToProps = (state: any) => ({
  scripts: state.scripts.scripts,
  groundStations: state.groundStations.groundStations,
  satellites: state.constellations.selected
    ? state.constellations.selected.satelliteInstances
    : []
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    createGroundStationConfig,
    fetchGroundStations: () => dispatch(fetchGroundStationsAction()),
    setFeedback: (title: string, status: any, details = "") =>
      dispatch(setFeedback(title, status, details))
  };
};

export const GroundStationConfigCreateContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GroundStationConfigCreate)
);
