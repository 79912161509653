import React from "react";
import styled from "styled-components";

export const ConfigSatGSMap: React.FC<{
  satellites?: string[];
  groundstations?: string[];
}> = ({ satellites, groundstations }) => {
  if (Array.isArray(satellites)) {
    return (
      <>
        {satellites.map((sat: string) => (
          <StyledSat key={sat}>{sat}</StyledSat>
        ))}
      </>
    );
  }
  return (
    <>
      {Array.isArray(groundstations) &&
        groundstations.map((gs: any) => (
          <StyledWrapper key={gs}>
            {gs?.map((gsName: string) => {
              return <StyledGS key={gsName}>{gsName}</StyledGS>;
            })}
          </StyledWrapper>
        ))}
    </>
  );
};

const StyledWrapper = styled.div`
  color: #7ab9dc;
  display: flex;
  flex-direction: row;
  margin: 4px 0px;
`;

const StyledSat = styled.div`
  color: #7ab9dc;
  padding: 2px 0px;
  margin: 4px 0px;
  background-color: #212232;
`;

const StyledGS = styled.span`
  padding: 2px 4px;
  margin: 0px 2px;
  background-color: #212232;
`;
