import React, { SFC, Component } from "react";
import { Flex, Box, Button, Icon, Text } from "primitives";
import { Toggler, ExportButton } from "components";
import {
  TelecommandExecutionStatus,
  ExecutedTelecommand,
  TelecommandPayload,
  TelecommandType,
  AuroraTelecommandPayload,
  TelecommandResponse,
  TelecommandResponseLevel,
  TelecommandResponseStatus
} from "../models";
import { connect } from "react-redux";
import { fetchTelecommandResponse, clearTelecommandResponse } from "../actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { FramePayloadBodyGrid } from "app/telemetry/components/FramePayloadBodyGrid";
import { TelemetryFrame, AuroraTelemetryFrame } from "app/telemetry/models";
import { TabInput } from "app/shared";
import { FramePayloadAuxiliariesGrid } from "app/telemetry/components/FramePayloadAuxiliariesGrid";
import ReactJson from "react-json-view";
import { exportToJson } from "utils/exportAsJson";
import { Filter } from "components";

/***
 * Telecommand List component: displays a list of telecommands, allowing the user to drill-down to the
 * details of a particular telecommand.
 */
type ResendTelecommandAction = (
  satId: number,
  telecommandExecutionPayload: AuroraTelecommandPayload
) => void;
type ReuseTelecommandAction = (
  telecommandRecordId: number,
  telecommandId: string,
  telecommandExecutionPayload: TelecommandPayload
) => void;
interface TelecommandListTableProps {
  executedTelecommands: ExecutedTelecommand[];
  resendTelecommandAction: ResendTelecommandAction;
  reuseTelecommandAction: ReuseTelecommandAction;
  onChange?: Function;
  options: any;
}

const ALL_COLUMNS = [
  "satId",
  "groundStationName",
  "username",
  "tcLabel",
  "sentAt",
  "status",
  "response"
];

export const TelecommandsListTable = ({
  executedTelecommands,
  resendTelecommandAction,
  reuseTelecommandAction,
  onChange,
  options
}: TelecommandListTableProps) => {
  return (
    <>
      <TelecommandsListTableHeader
        onChange={onChange}
        executedTelecommands={executedTelecommands}
        options={options}
      />
      {executedTelecommands &&
        executedTelecommands.map((it: ExecutedTelecommand) => {
          // High order function for each telecommand
          const telecommandId =
            it.telecommandExecutionPayload.telecommand.telecommandUri;
          const telecommandRecordId = it.id;
          const telecommandPayload = it.telecommandExecutionPayload.telecommand;
          const resendTelecommand = () =>
            resendTelecommandAction(
              it.satId,
              AuroraTelecommandPayload.build(telecommandPayload)
            );

          const reuseTelecommand = () =>
            reuseTelecommandAction(
              telecommandRecordId,
              telecommandId,
              telecommandPayload
            );

          // This avoids errors and add backword compatibility to the current widget instance in aurora that still do not have a configuration
          const columns = options.columns || ALL_COLUMNS;

          return (
            <Toggler key={it.id}>
              {([toggled, onToggle]) => (
                <Box p={2} bg="fill.1" fontSize={2}>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Flex flex={1} px={1}>
                      <Box mr={1}>
                        <ResendButton onClick={resendTelecommand} />
                      </Box>
                      <ReuseButton onClick={reuseTelecommand} />
                    </Flex>
                    {columns.includes("satId") && (
                      <Flex flex={1} px={1}>
                        {it.satId}
                      </Flex>
                    )}
                    {columns.includes("groundStationName") && (
                      <Flex flex={1} px={1}>
                        {it.groundStationName}
                      </Flex>
                    )}
                    {columns.includes("username") && (
                      <Flex flex={1} px={1}>
                        {it.username}
                      </Flex>
                    )}
                    {columns.includes("tcLabel") && (
                      <Flex flex={1} px={1}>
                        {it.tcLabel}
                      </Flex>
                    )}
                    {columns.includes("sentAt") && (
                      <Flex flex={1} px={1}>
                        {it.sentAt}
                      </Flex>
                    )}
                    {columns.includes("status") && (
                      <Flex flex={1} px={1}>
                        <TelecommandSentStatusIndicator status={it.status} />
                      </Flex>
                    )}
                    {columns.includes("response") && (
                      <Flex flex={1} px={1}>
                        <TelecommandResponseIndicator
                          responseStatus={it.response}
                        />
                      </Flex>
                    )}
                    <Flex justifyContent="center" flex={1} mr={1} px={1}>
                      <Button size="small" onClick={onToggle}>
                        {toggled ? (
                          <Icon name="ArrowUp" size="14" />
                        ) : (
                          <Icon name="ArrowDown" size="14" />
                        )}
                      </Button>
                    </Flex>
                  </Flex>

                  {toggled && (
                    <Box py={3}>
                      <TelecommandExecutionDetails
                        executedTelecommand={it}
                        clearTcResponse={function (): void {
                          throw new Error("Function not implemented");
                        }}
                        fetchTcResponse={function (
                          satelliteId: Number,
                          executedTelecommandId: Number,
                          from: string
                        ): void {
                          throw new Error("Function not implemented");
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Toggler>
          );
        })}
    </>
  );
};

// TODO: all table based widgets should be migrated to react-table
const TelecommandsListTableHeader = (props: any) => {
  const { onChange, executedTelecommands, options } = props;

  const selectFieldsForExport = (telecommandList: any) => {
    return telecommandList.map((telecommand: any) => {
      return {
        satelliteId: telecommand.satId,
        groundStationName: telecommand.groundStationName,
        username: telecommand.username,
        tcLabel: telecommand.tcLabel,
        sentAt: telecommand.sentAt,
        status: telecommand.status,
        response: telecommand.response.statusName,
        payload: telecommand.telecommandExecutionPayload
      };
    });
  };

  // This avoids errors and add backword compatibility to the current widget instance in aurora that still do not have a configuration
  const columns = options.columns || ALL_COLUMNS;

  return (
    <>
      <Box p={2} bg="fill.0" fontSize={2} color="text.default">
        <Flex justifyContent="space-between" width="100%">
          <Flex flex={1} px={1}></Flex>
          {columns.includes("satId") && (
            <Flex flex={1} flexDirection="column" px={1}>
              Sat Id
              <Filter field="satId" onChange={onChange} />
            </Flex>
          )}
          {columns.includes("groundStationName") && (
            <Flex flex={1} flexDirection="column" px={1}>
              Ground Station{" "}
              <Filter field="groundStationName" onChange={onChange} />
            </Flex>
          )}
          {columns.includes("username") && (
            <Flex flex={1} flexDirection="column" px={1}>
              Username <Filter field="username" onChange={onChange} />
            </Flex>
          )}
          {columns.includes("tcLabel") && (
            <Flex flex={1} flexDirection="column" px={1}>
              Label <Filter field="tcLabel" onChange={onChange} />
            </Flex>
          )}
          {columns.includes("sentAt") && (
            <Flex flex={1} flexDirection="column" px={1}>
              Timestamp <Filter field="sentAt" onChange={onChange} />
            </Flex>
          )}
          {columns.includes("status") && (
            <Flex flex={1} flexDirection="column" px={1}>
              Status <Filter field="status" onChange={onChange} />
            </Flex>
          )}
          {columns.includes("response") && (
            <Flex flex={1} flexDirection="column" px={1}>
              Response <Filter field="response" onChange={onChange} />
            </Flex>
          )}
          <Flex
            alignSelf="flex-start"
            flex={1}
            mr={1}
            justifyContent={"center"}
            px={1}
          >
            <ExportButton
              onClick={() =>
                exportToJson(
                  selectFieldsForExport(executedTelecommands),
                  "telecommands_list"
                )
              }
            />
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

/***
 * Telecommand execution indicator component: Allows the user to visualize if the telecommand has been sent or
 * if there is an error.
 */
interface StatusIndicatorProps {
  status: TelecommandExecutionStatus;
}
const TelecommandSentStatusIndicator: SFC<StatusIndicatorProps> = ({
  status
}) => {
  if (status === TelecommandExecutionStatus.Sent) {
    return (
      <Text bold ml={1}>
        {status}
      </Text>
    );
  } else if (status === TelecommandExecutionStatus.NotSent) {
    return (
      <Text bold ml={1} color="status.danger">
        {status}
      </Text>
    );
  } else {
    return (
      <Text bold ml={1} color="status.warning">
        {status}
      </Text>
    );
  }
};

/***
 * Telecommand response indicator component: Allows the user to visualize if the telecommand's response has been
 * received or if there is a timeout. There are 4 possible states:
 * - TcNone (no response received)
 * - TcAck (successful response received)
 * - TcNack (error response received)
 * - TcTimeout (Response timed out)
 */
interface TelecommandResponseIndicatorProps {
  responseStatus: TelecommandResponse;
}
export const TelecommandResponseIndicator: SFC<
  TelecommandResponseIndicatorProps
> = ({ responseStatus }) => {
  let color = "";
  switch (responseStatus.level) {
    case TelecommandResponseLevel.ERROR:
      color = "status.danger";
      break;
    case TelecommandResponseLevel.WARNING:
      color = "status.warning";
      break;
    case TelecommandResponseLevel.SUCCESS:
      color = "status.success";
      break;
    default:
      break;
  }
  return (
    <Text bold ml={1} color={color} data-testid="ResponseLabel">
      {responseStatus.statusName}
    </Text>
  );
};

/***
 * Quick action buttons to allow to re-use or re-send telecommands from the telecommand list
 */
interface ButtonWrapperProps {
  onClick: () => void;
}
const ResendButton: SFC<ButtonWrapperProps> = ({ onClick }) => (
  <Button size="smaller" onClick={onClick}>
    Re-send
  </Button>
);
const ReuseButton: SFC<ButtonWrapperProps> = ({ onClick }) => (
  <Button size="smaller" onClick={onClick}>
    Re-use
  </Button>
);

/***
 * Component that renders the details of a telecommand when the user expands a particular item from the list.
 * If the component has a reponse, then that is fetched from the telemetry service and presented to the user.
 */
interface TelecommandExecutionDetailsProps {
  executedTelecommand: ExecutedTelecommand;
  clearTcResponse: () => void;
  fetchTcResponse: (
    satelliteId: Number,
    executedTelecommandId: Number,
    from: string
  ) => void;
  response?: TelemetryFrame;
}

const statesWithoutResponse = [TelecommandResponseStatus.TcNone.toString()];

class BaseTelecommandExecutionDetails extends Component<TelecommandExecutionDetailsProps> {
  componentDidMount() {
    this.updateResponsePayload();
  }

  componentDidUpdate(prevProps: TelecommandExecutionDetailsProps) {
    if (
      this.props.executedTelecommand.id !== prevProps.executedTelecommand.id
    ) {
      this.updateResponsePayload();
    }
  }

  render() {
    const { executedTelecommand } = this.props;
    if (
      !statesWithoutResponse.includes(executedTelecommand.response.statusName)
    ) {
      return this.renderPayloadAndResponse();
    } else {
      return this.renderJustPayload();
    }
  }

  private updateResponsePayload() {
    const { executedTelecommand, fetchTcResponse, clearTcResponse } =
      this.props;
    const isSyncTc = executedTelecommand.tcType === TelecommandType.Sync;
    const hasResponse =
      executedTelecommand.response != null &&
      !statesWithoutResponse.includes(executedTelecommand.response.statusName);
    if (isSyncTc && hasResponse) {
      clearTcResponse();
      fetchTcResponse(
        executedTelecommand.satId,
        executedTelecommand.id,
        executedTelecommand.sentAt
      );
    } else {
      clearTcResponse();
    }
  }

  private renderJustPayload() {
    const { executedTelecommand } = this.props;
    return (
      <Box p={2}>
        <TabInput
          record={executedTelecommand}
          tabs={["Input"]}
          containers={(value: number, record: any) => (
            <>{value === 0 && this.renderUserInput(record)}</>
          )}
        />
      </Box>
    );
  }

  private renderUserInput(payload: ExecutedTelecommand) {
    return (
      <ReactJson
        src={payload.telecommandExecutionPayload}
        collapsed={1}
        theme="monokai"
        name="payload"
        style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 5 }}
      />
    );
  }

  private renderPayloadAndResponse() {
    const { response, executedTelecommand } = this.props;
    const showPrettifiedResponse =
      response && response.header && Object.keys(response.header).length !== 0;
    const tabs = ["Raw Response", "Input"];
    showPrettifiedResponse && tabs.unshift("Response");
    return (
      <Box
        bg="fill.2"
        px={2}
        py={3}
        data-tesid="BaseTelecommandExecutionDetails"
      >
        <TabInput
          record={executedTelecommand}
          tabs={tabs}
          containers={(value: number, record: any) => (
            <>
              {value === tabs.indexOf("Response") &&
                showPrettifiedResponse &&
                response &&
                this.renderResponse(response)}
              {value === tabs.indexOf("Raw Response") &&
                response &&
                this.renderRawResponse(response)}
              {value === tabs.indexOf("Input") && this.renderUserInput(record)}
            </>
          )}
        />
      </Box>
    );
  }

  private renderResponse(response: TelemetryFrame) {
    const frame = AuroraTelemetryFrame.of(response);
    return (
      <>
        <p>Frame name: {response.frameName}</p>
        <p>Frame id: {response.frameId}</p>
        <p>Receive time: {response.timestamp}</p>
        <FramePayloadAuxiliariesGrid tmFrame={frame} />
        <FramePayloadBodyGrid tmFrame={frame} />
      </>
    );
  }

  private renderRawResponse(response: TelemetryFrame) {
    let src = {};
    if (Array.isArray(response)) src = response.map((r) => r.payload);
    else src = response.payload;
    return (
      <ReactJson
        src={src}
        collapsed={false}
        theme="monokai"
        name="payload"
        style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 5 }}
      />
    );
  }
}

const mapStateToProps = (
  state: any,
  ownProps: TelecommandExecutionDetailsProps
) => ({
  response: state.telecommandList.responses[ownProps.executedTelecommand.id]
});
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  clearTcResponse: () => {
    dispatch(clearTelecommandResponse());
  },
  fetchTcResponse: (
    satelliteId: Number,
    executedTelecommandId: Number,
    from: string
  ) => {
    dispatch(
      fetchTelecommandResponse(satelliteId, executedTelecommandId, from)
    );
  }
});

const TelecommandExecutionDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(BaseTelecommandExecutionDetails);
