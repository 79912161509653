import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import { Box, Relative, Text } from "primitives";
import { TextProps } from "primitives/Text";
import { DATA_TEST_ID_ATTR_NAME } from "components/testable/models";

interface BadgeProps extends TextProps {
  children?: React.ReactNode | string;
  count: number | string;
  overflowCount: number;
}

const CountRelative = styled(Relative)`
  font-weight: ${themeGet("fontWeights.bold")};
  text-align: center;
`;

const CountAbsolute = styled(CountRelative)`
  position: absolute;
  top: 0;
  right: -8px;
  transform: translate(60%, -50%);
  transform-origin: 100% 0%;
`;

CountRelative.defaultProps = {
  p: "2px",
  border: 3,
  borderColor: "border.1"
};

CountAbsolute.defaultProps = { ...CountRelative.defaultProps };

const formatCount = (defaultCount: string | number, overflowCount: number) => {
  if (Number.isFinite(defaultCount as any)) {
    return defaultCount >= overflowCount ? `${overflowCount}+` : defaultCount;
  }
  return defaultCount;
};

const Badge = ({
  children,
  count: defaultCount,
  overflowCount,
  ...props
}: BadgeProps) => {
  const count = formatCount(defaultCount, overflowCount);
  const mr = children ? 4 : 0;
  const ml = children ? 2 : 0;

  return (
    <Box
      overflow="visible"
      position="relative"
      display="inline-block"
      mr={mr}
      ml={ml}
    >
      {count && children && (
        <CountAbsolute>
          <Text as="div" {...props}>
            {count}
          </Text>
        </CountAbsolute>
      )}
      {count && !children && (
        <CountRelative>
          <Text as="div" {...props}>
            {count}
          </Text>
        </CountRelative>
      )}
      {children}
    </Box>
  );
};

Badge.displayName = "Badge";

Badge.defaultProps = {
  count: 0,
  overflowCount: 9999,
  fontSize: 1,
  color: "text.white",
  [DATA_TEST_ID_ATTR_NAME]: Badge.displayName
};

export default Badge;
