import React, { Component, ChangeEvent } from "react";
import { Box, Heading, Flex } from "primitives";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  EditButton,
  DateTimeLabel,
  InputField
} from "components";
import { PaginationSimple, TabInput } from "app/shared";
import { Link } from "react-router-dom";
import { AddButton } from "components";
import { SuspenseQuery, Suspense } from "app/network";
import { connect } from "react-redux";
import { ScriptListItem } from "../models";
import { fetchScriptsAction } from "../actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ScriptDeleteContainer } from "./ScriptDelete";
import { ResourceListContainer } from "app/resources/components/ResourcesList";

interface ScriptListState {
  pageSize: number;
  page: number;
  searchText: string;
  total: number;
}

interface ScriptListProps {
  fetchScripts: () => Promise<ScriptListItem[]>;
}

const tabLabels = ["Scripts", "Resources"];

export class ScriptList extends Component<ScriptListProps, ScriptListState> {
  state = {
    pageSize: 25,
    page: 0,
    searchText: "",
    total: 0
  };

  onChangeSearch(
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) {
    const { value } = event.currentTarget;
    this.setState({ searchText: value, page: 0 });
  }

  filterScripts(scripts: ScriptListItem[]) {
    if (!Array.isArray(scripts)) return [];
    const { pageSize, page, searchText, total } = this.state;
    const minIndex = page * pageSize;
    const maxIndex = minIndex + pageSize;
    const result: ScriptListItem[] = scripts.filter((script: ScriptListItem) =>
      script.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
    );
    if (result.length !== total) {
      this.setState({ total: result.length });
    }
    return result.slice(minIndex, maxIndex);
  }

  renderScriptList() {
    const { pageSize, page, searchText, total } = this.state;
    return (
      <Suspense>
        <SuspenseQuery query={this.props.fetchScripts}>
          {({ response, reload }) => {
            const scripts = this.filterScripts(response);
            return (
              <Box data-testid="ScriptList" mx={3}>
                <Flex alignItems="center" justifyContent="space-between" mb={2}>
                  <Heading display={1}></Heading>
                  <Flex alignItems="flex-end">
                    <Box mr={4}>
                      <InputField
                        id="search-input"
                        label="search"
                        autoFocus={true}
                        value={searchText}
                        onChange={(
                          e:
                            | ChangeEvent<HTMLInputElement>
                            | ChangeEvent<HTMLTextAreaElement>
                        ) => this.onChangeSearch(e)}
                      />
                    </Box>
                    <Link to="/scripts/create">
                      <AddButton>Create</AddButton>
                    </Link>
                  </Flex>
                </Flex>
                <Table>
                  <TableHead>
                    <TableRow bg="fill.0">
                      <TableCell width="50">ID</TableCell>
                      <TableCell width="auto">Created Date</TableCell>
                      <TableCell width="auto">Name</TableCell>
                      <TableCell width="50" />
                      <TableCell width="50" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {scripts &&
                      scripts.map((script: ScriptListItem) => (
                        <TableRow key={script.id}>
                          <TableCell>#{script.id}</TableCell>
                          <TableCell>
                            <DateTimeLabel
                              value={new Date(script.dateCreated)}
                            />
                          </TableCell>
                          <TableCell>{script.name}</TableCell>
                          <TableCell>
                            <Link to={`/scripts/${script.id}`}>
                              <EditButton>Edit</EditButton>
                            </Link>
                          </TableCell>
                          <TableCell>
                            <ScriptDeleteContainer
                              script={{
                                id: Number(script.id),
                                name: script.name
                              }}
                              onChange={() => reload()}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <PaginationSimple
                  total={total}
                  page={page + 1}
                  pageSize={pageSize}
                  nextPageUrl={(page + 1) * pageSize < total ? page + 1 : 0}
                  previousPageUrl={page - 1}
                  onChange={(newPage: string | number) =>
                    this.setState({
                      page: Number(newPage),
                      pageSize
                    })
                  }
                  onPageSizeChange={(newPageSize: number) =>
                    this.setState({
                      page: 0,
                      pageSize: Number(newPageSize)
                    })
                  }
                />
              </Box>
            );
          }}
        </SuspenseQuery>
      </Suspense>
    );
  }

  renderResourceList() {
    return <ResourceListContainer />;
  }

  render() {
    return (
      <TabInput
        margin="0 16px"
        record={null}
        tabs={tabLabels}
        containers={(value: number) => (
          <>
            {value === 0 && this.renderScriptList()}
            {value === 1 && this.renderResourceList()}
          </>
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  fetchScripts: () => dispatch(fetchScriptsAction())
});

export const ScriptListContainer = connect(
  null,
  mapDispatchToProps
)(ScriptList);
