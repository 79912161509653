import React, {
  useContext,
  useRef,
  useMemo,
  useCallback,
  useEffect
} from "react";
import { useSelector } from "react-redux";
import {
  ALL_GROUND_STATIONS,
  Zoom,
  convertPassagesToTimelineFormat,
  getLegendList,
  handleTimelineDefaultRange
} from "app/visibilityWindow/helpers";
import { Box, Button, Flex } from "primitives";
import { VisibiltyWindowContext } from "app/visibilityWindow/context";
import {
  Id,
  ReactCalendarGroupRendererProps,
  TimelineGroupBase,
  ReactCalendarItemRendererProps
} from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import {
  TimelinePassageRenderer,
  TimelineLegend,
  TimelineFilter
} from "app/visibilityWindow/components/VisibilityWindowsList/components";
import { Passage, ITimeline } from "app/visibilityWindow/models";
import { DragHandle, TimelineRenderer } from "components";

const Timeline = ({ allPassages, dataProvider }: ITimeline) => {
  const { satelliteInstances } = useSelector(
    (state: any) => state.constellations.selected
  );

  const {
    state: {
      selectedGroundStation,
      passages,
      begin,
      end,
      calendarViewSelectedPassage,
      resetting,
      viewBySatellite,
      loading,
      filter
    },
    dispatch
  } = useContext(VisibiltyWindowContext);

  useEffect(() => {
    if (resetting) {
      dispatch({
        type: "resetting",
        payload: false
      });
    }
  }, [viewBySatellite]);

  const defaultTimeRange: { begin: Date; end: Date } = useMemo(() => {
    const { defaultTimeStart, defaultTimeEnd } = handleTimelineDefaultRange({
      begin: begin as Date,
      end: end as Date
    });
    return {
      begin: new Date(defaultTimeStart.toString()),
      end: new Date(defaultTimeEnd.toString())
    };
  }, [begin, end]);

  const timelineContainer =
    useRef() as React.MutableRefObject<HTMLInputElement>;

  const groupRenderer = ({
    group
  }: ReactCalendarGroupRendererProps<TimelineGroupBase>) => {
    return (
      <div
        onClick={(evt: React.MouseEvent<HTMLDivElement>) => {
          const selectedGs =
            selectedGroundStation === group.title
              ? ALL_GROUND_STATIONS
              : group.title;
          dispatch({
            type: "selectedGroundStation",
            payload: selectedGs
          });
        }}
      >
        <span>{group.title}</span>
      </div>
    );
  };

  const legendList = useMemo(
    () =>
      getLegendList(
        passages,
        viewBySatellite ? satelliteInstances : [],
        viewBySatellite
      ),
    [resetting, loading]
  );
  const legendIds = legendList.map((item) => item.id);

  const formatedData = useMemo(
    () =>
      convertPassagesToTimelineFormat(
        passages,
        satelliteInstances,
        viewBySatellite,
        legendIds
      ),
    [passages, viewBySatellite]
  );

  const handleItemSelect = useCallback(
    (passageID: Id, evt: React.MouseEvent) => {
      evt.persist && evt.persist();
      const selectedPassage = passages.filter(
        (passage) => passage.passageID === passageID
      );

      const exists = calendarViewSelectedPassage
        .map((p: Passage) => p.passageID)
        .includes(passageID as number);

      const incrementalUpdate = (exists && calendarViewSelectedPassage) || [
        ...calendarViewSelectedPassage,
        ...selectedPassage
      ];

      const newSelection = (evt.ctrlKey && incrementalUpdate) || [
        ...selectedPassage
      ];
      dispatch({ type: "calendarViewSelectedPassage", payload: newSelection });
    },
    [calendarViewSelectedPassage]
  );

  return (
    (!resetting && (
      <Box
        data-testid="calendar-timeline"
        style={{ display: "flex" }}
        ref={timelineContainer}
      >
        <Box style={{ flex: 1 }}>
          <div
            style={{
              position: "relative",
              overflow: "visible",
              height: 0,
              display: "flex"
            }}
          >
            <DragHandle label topLabel={5} absolute left={10} />
            <TimelineFilter
              allPassages={allPassages}
              satelliteInstances={satelliteInstances}
              viewBySatellite={viewBySatellite}
            />
          </div>
          <TimelineRenderer
            stackItems
            groups={formatedData.groups}
            items={formatedData.items}
            defaultTimeStart={defaultTimeRange.begin}
            defaultTimeEnd={defaultTimeRange.end}
            onItemSelect={handleItemSelect}
            onCanvasClick={() =>
              dispatch({
                type: "default",
                payload: {
                  selectedGroundStation: ALL_GROUND_STATIONS,
                  calendarViewSelectedPassage: []
                }
              })
            }
            groupRenderer={groupRenderer}
            minZoom={Zoom.min}
            maxZoom={Zoom.max}
            selected={
              (calendarViewSelectedPassage &&
                calendarViewSelectedPassage.map((p) => p.passageID)) ||
              []
            }
            itemRenderer={(itemProps: ReactCalendarItemRendererProps) => (
              <TimelinePassageRenderer {...itemProps} />
            )}
            buffer={1}
            dataProvider={dataProvider}
          />
        </Box>
        <Flex flexDirection="column" bg="#2E4162">
          <Button
            size="small"
            m={2}
            mt={1}
            onClick={() => {
              dispatch({
                type: "default",
                payload: {
                  viewBySatellite: !viewBySatellite,
                  resetting: true,
                  filter: undefined
                }
              });
            }}
          >
            {viewBySatellite ? "Ground Stations" : "Satellites"}
          </Button>
          <TimelineLegend list={legendList} filter={filter} />
        </Flex>
      </Box>
    )) || (
      <div
        style={{
          height:
            timelineContainer.current && timelineContainer.current.offsetHeight
        }}
      />
    )
  );
};

export { Timeline };
