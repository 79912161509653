import React, { Component, ChangeEvent, FormEvent } from "react";
import {
  Box,
  Flex,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Label
} from "primitives";
import { AddButton, SaveButton, CancelButton, InputField } from "components";
import { createSatelliteInstance } from "app/satellite/services";
import { SatelliteDefinition } from "app/satelliteDefinition/models";
import { Constellation } from "app/constellation/models";
import { SelectSimple as Select } from "components";

interface SatelliteInstanceCreateProps {
  constellation: Constellation;
  satelliteDefinitions: SatelliteDefinition[];
  onChange: () => void;
}

interface SatelliteInstanceCreateState {
  modalOpen: boolean;
  label: string | null;
  satelliteDefinition: number | string | null;
}

export class SatelliteInstanceCreate extends Component<
  SatelliteInstanceCreateProps,
  SatelliteInstanceCreateState
> {
  state = {
    modalOpen: false,
    label: null,
    satelliteDefinition: null
  };

  onChangeLabel(
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) {
    const { value } = event.currentTarget;
    this.setState({ label: value });
  }

  onChangeSatelliteDefinitionID(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.currentTarget;
    this.setState({ satelliteDefinition: value });
  }

  hideDialog = () => {
    this.setState({ modalOpen: false });
  };

  openDialog = () => {
    this.setState({ modalOpen: true });
  };

  handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const { modalOpen, ...satelliteInstance } = this.state;
    const { constellation } = this.props;

    createSatelliteInstance(constellation, satelliteInstance).then(() => {
      this.props.onChange();
      this.hideDialog();
    });
  };

  render() {
    const { satelliteDefinitions } = this.props;
    const { modalOpen } = this.state;

    return (
      <>
        <AddButton onClick={() => this.openDialog()}>Add</AddButton>
        <Dialog
          open={modalOpen}
          maxWidth="md"
          data-testid="SatelliteInstanceCreate"
        >
          <DialogTitle>Add Satellite instance</DialogTitle>
          <DialogContent>
            <form
              onSubmit={(e) => this.handleSubmit(e)}
              id="add-satellite-instance"
            >
              <Box mb={2}>
                <InputField
                  id="label"
                  required={true}
                  label="Label"
                  onChange={(
                    e:
                      | ChangeEvent<HTMLInputElement>
                      | ChangeEvent<HTMLTextAreaElement>
                  ) => this.onChangeLabel(e)}
                />
              </Box>
              <Box mt={3} width="100%">
                <Label>Satellite Definition</Label>
                <Select
                  width="100%"
                  required={true}
                  name="satelliteDefinition"
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    this.onChangeSatelliteDefinitionID(e)
                  }
                >
                  <option value="" />
                  {satelliteDefinitions &&
                    satelliteDefinitions.map(
                      (satelliteDefinition: SatelliteDefinition) => (
                        <option
                          key={satelliteDefinition.id}
                          value={satelliteDefinition.id}
                        >
                          {satelliteDefinition.name}
                        </option>
                      )
                    )}
                </Select>
              </Box>
            </form>
          </DialogContent>
          <DialogActions>
            <Flex
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <SaveButton type="submit" form="add-satellite-instance" mr={1}>
                Save
              </SaveButton>
              <CancelButton onClick={() => this.hideDialog()} mx={1}>
                Cancel
              </CancelButton>
            </Flex>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
