import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgPlay = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    viewBox="0 0 420 420"
    width={size || width}
    height={size || height}
    color={color}
  >
    <path d="M210 21c104.216 0 189 84.784 189 189s-84.784 189-189 189S21 314.216 21 210 105.784 21 210 21m0-21C94.031 0 0 94.024 0 210s94.031 210 210 210 210-94.024 210-210S325.969 0 210 0z" />
    <path d="M293.909 187.215l-111.818-73.591C162.792 100.926 147 109.445 147 132.545V287.42c0 23.1 15.813 31.647 35.147 18.998L293.86 233.31c19.327-12.663 19.348-33.397.049-46.095zm-14.903 30.653l-99.295 64.981c-6.44 4.221-11.711 1.372-11.711-6.328V143.437c0-7.7 5.264-10.535 11.697-6.3l99.33 65.366c6.433 4.228 6.426 11.144-.021 15.365z" />
  </Svg>
);

SvgPlay.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgPlay;
