import React, { FC } from "react";
import { CancelButton, InputField } from "components";
import { Box } from "primitives";
import { ResourceBase } from "../models";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "primitives";

interface ResourcePropertiesModalProps {
  selectedResource: ResourceBase | null;
  modalOpen: boolean;
  closeModal: () => void;
}

const ResourcePropertiesModal: FC<ResourcePropertiesModalProps> = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { modalOpen, closeModal, selectedResource } = props;

  if (!selectedResource) return null;

  return (
    <Dialog open={modalOpen} maxWidth="md" overflow="hidden">
      <DialogTitle>Edit Configuration Options</DialogTitle>
      <DialogContent>
        <form>
          <Box mb={2}>
            <InputField
              id="id"
              value={selectedResource.id}
              label="Id"
              disabled={true}
            />
          </Box>
          <Box mb={2}>
            <InputField
              id="name"
              value={`${
                selectedResource.dir === "."
                  ? ""
                  : `${selectedResource.dir.indexOf("/") === 0 ? "" : "/"}${
                      selectedResource.dir
                    }`
              }/${selectedResource.name}`}
              label="Path"
              disabled={true}
            />
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={() => closeModal()} mr={1}>
          Cancel
        </CancelButton>
      </DialogActions>
    </Dialog>
  );
};

export default ResourcePropertiesModal;
