import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgKey = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 36 36"
    color={color}
  >
    <rect
      x="6.33"
      y="10.71"
      width="9.71"
      height="2.57"
      rx="1"
      ry="1"
      transform="translate(-5.21 11.43) rotate(-45)"
    />
    <path d="M23.35,16.8l.63-.63A5,5,0,0,0,24,9.1L18.71,3.84a5,5,0,0,0-7.07,0L3.09,12.39a5,5,0,0,0,0,7.07l5.26,5.26a5,5,0,0,0,7.07,0l.4-.4L18,26.48h3.44v3h3.69v1.63L28,34h6V27.45ZM32,32H28.86l-1.77-1.76v-2.8H23.41v-3H18.8l-3-3L14,23.31a3,3,0,0,1-4.24,0L4.5,18a3,3,0,0,1,0-4.24l8.56-8.56a3,3,0,0,1,4.24,0l5.26,5.26a3,3,0,0,1,0,4.24l-2,2L32,28.28Z" />
    <rect x="0" y="0" width="36" height="36" fill-opacity="0" />
  </Svg>
);

SvgKey.defaultProps = {
  width: "15",
  height: "15"
};
export default SvgKey;
