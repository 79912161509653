import { connect } from "react-redux";
import { setSessionKeyStateAction } from "app/keyManager/actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { SessionKeyContextMenu } from "../components/SessionKeyContextMenu";
import { SessionKeyRequestParams } from "app/keyManager/models";
const mapStateToProps = (state: any) => ({
  selectedSatellite: state.constellations.dashboard.find(
    (satellite: any) => satellite.visible
  )
});
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    setSessionKeyState: (
      satelliteID: number,
      params: SessionKeyRequestParams
    ) => dispatch(setSessionKeyStateAction(satelliteID, params))
  };
};
export const SessionKeyContextMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionKeyContextMenu);
