import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ThemeProvider } from "styled-components";
import { Switch, Route } from "react-router-dom";
import { PageVisibility } from "navigation";
import { theme } from "styles";
import { MainLayout, Header, Sider, Content, Footer } from "layout";
import { Navbar, Sidebar, FooterBar } from "navigation";
import { Box, Text } from "primitives";
import { GlobalStyles } from "styles";
import "./App.css";
import { DashboardRouters } from "app/dashboard/routes";
import { ConstellationRouters } from "app/constellation/routes";
import { SatelliteDefinitionRouters } from "app/satelliteDefinition/routes";
import { AlertRouters } from "app/alert/routes";
import { ProcedureRouters } from "app/procedure/routes";
import { TelecommandStackRouters } from "app/telecommandStack/routes";
import { ScriptRouters } from "app/scripting/routes";
import { GroundStationsRouters } from "app/groundStation/routes";
import { ResourceRouters } from "app/resources/routes";
import { ResourceV2Routers } from "app/resources_v2/routes";
import { SatelliteFileDownloadRouters } from "./app/satelliteFileDownload/routes";
import { setRefreshData, unsetRefreshData } from "app/feedback/actions";
import { FeedbackList } from "app/feedback/components/FeedbackList";
import { UserRouters } from "app/user/routes";
import { KeyManagerRoutes } from "app/keyManager";
import { PlanningSystemRoutes } from "app/planningSystem";
import { PassageManagerRoutes } from "app/passageManager";

const App: React.FC = (props: any) => {
  const [showSideBar, setSideBarVisibility] = useState(
    JSON.parse(localStorage.getItem("showSideBar") || "true")
  );

  useEffect(() => {
    if (!localStorage.getItem("showSideBar")) {
      localStorage.setItem("showSideBar", "true");
    }
  }, []);

  const changeSideBarVisibility = () => {
    localStorage.setItem("showSideBar", JSON.stringify(!showSideBar));
    setSideBarVisibility(!showSideBar);
  };

  return (
    <PageVisibility
      onChange={(isVisible: boolean) =>
        isVisible
          ? props.setRefreshData(isVisible)
          : props.unsetRefreshData(isVisible)
      }
    >
      <Switch>
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyles />
            <MainLayout>
              <Header bg="fill.1">
                <Navbar />
              </Header>
              <Sider bg="fill.1" color="text.default">
                <Sidebar
                  showSideBar={showSideBar}
                  toggleSideBar={() => changeSideBarVisibility()}
                />
              </Sider>
              <Route
                render={({ location }) => {
                  if (location.pathname === "/") {
                    return (
                      <Content overflow="hidden">
                        <Box p={2} height="100%" overflow="hidden">
                          <PermissionsError keycloak={props.keycloak} />
                          <DashboardRouters />
                        </Box>
                      </Content>
                    );
                  } else {
                    return (
                      <Content overflow="auto" id="main-container">
                        <Box p={2}>
                          <PermissionsError keycloak={props.keycloak} />
                          <AlertRouters />
                          <DashboardRouters />
                          <ConstellationRouters />
                          <SatelliteDefinitionRouters />
                          <ScriptRouters />
                          <ProcedureRouters />
                          <TelecommandStackRouters />
                          <GroundStationsRouters />
                          <ResourceRouters />
                          <ResourceV2Routers />
                          <SatelliteFileDownloadRouters />
                          <UserRouters />
                          <KeyManagerRoutes />
                          {process.env.REACT_APP_KEY_PLANNING_SYSTEM_ACTIVE ===
                            "true" && <PlanningSystemRoutes />}
                          {process.env.REACT_APP_PASSAGE_BOOKING === "true" && (
                            <PassageManagerRoutes />
                          )}
                        </Box>
                      </Content>
                    );
                  }
                }}
              />

              <Footer bg="fill.2">
                <FooterBar />
              </Footer>

              <FeedbackList />
            </MainLayout>
          </>
        </ThemeProvider>
      </Switch>
    </PageVisibility>
  );
};

const PermissionsError = ({ keycloak }: any) => {
  if (
    (keycloak &&
      keycloak.resourceAccess["webgs-backend"] &&
      keycloak.resourceAccess["webgs-backend"].roles.includes("Viewer")) ||
    keycloak.resourceAccess["webgs-backend"].roles.includes("Operator")
  )
    return null;
  return (
    <Box m={2} mb={3} id="permissions-error">
      <Text color="text.danger">
        User does not have permissions. Please contact the administrator.
      </Text>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  keycloak: state.keycloak
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    setRefreshData: () => dispatch(setRefreshData()),
    unsetRefreshData: () => dispatch(unsetRefreshData())
  };
};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;
