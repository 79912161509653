import { CSSProperties } from "react";
import { keyframes } from "styled-components";
import {
  KeyStatus,
  KeyStatusMap,
  Channels,
  FunctionalCategories,
  ChannelMaps,
  FunctionalCategoriesMaps
} from "../models";
import palette from "styles/palette";

export const blinkAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const contextMenuDisabledAction: CSSProperties = {
  pointerEvents: "none",
  opacity: 0.6
};

export const keyStatusMap: KeyStatusMap = {
  [KeyStatus.PRE_OPERATIONAL]: {
    color: palette.status.info,
    name: "pre operational",
    endpoint: "<Pre operational endpoint does not exist>",
    actionName: ""
  },
  [KeyStatus.ACTIVE]: {
    color: palette.status.success,
    name: "active",
    endpoint: "activate",
    actionName: "activate"
  },
  [KeyStatus.SUSPENDED]: {
    color: palette.status.warning,
    name: "suspended",
    endpoint: "suspend",
    actionName: "suspend"
  },
  [KeyStatus.DEACTIVATED]: {
    color: palette.palette.purple[2],
    name: "deactivated",
    endpoint: "deactivate",
    actionName: "deactivate"
  },
  [KeyStatus.DESTROYED]: {
    color: palette.palette.red[0],
    name: "destroyed",
    endpoint: "destroy",
    actionName: "destroy"
  }
};

export const dialogTestIdPrefix = "ChangeStateDialog--";

export const channelMaps: ChannelMaps = {
  [Channels.SDL]: {
    label: "S-Band Downlink channel",
    bg: palette.palette.green[1]
  },
  [Channels.SUL]: {
    label: "S-Band Uplink channel",
    bg: palette.palette.orange[1]
  },
  [Channels.XDL]: {
    label: "X-Band Downlink channel",
    bg: palette.palette.purple[1]
  }
};

export const functionalCategoriesMaps: FunctionalCategoriesMaps = {
  [FunctionalCategories.TAK]: {
    label: "Traffic Authentication Keys"
  },
  [FunctionalCategories.TEK]: {
    label: "Traffic Encryption Keys"
  }
};

const oneDayInSeconds = 86400;

export const TTL = 12 * oneDayInSeconds; // 12 days
