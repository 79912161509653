class HttpError extends Error {
  constructor(message, status, body = null) {
    super(message);
    this.message = message;
    this.status = status;
    this.body = body;
    this.name = this.constructor.name;
    this.stack = new Error(message).stack;
  }
}

export default HttpError;
