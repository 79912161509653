import React from "react";
import { Switch, Route } from "react-router-dom";
import { SatelliteFileDownload } from "./components/SatelliteFileDownload";

export const SatelliteFileDownloadRouters = () => (
  <>
    <Switch>
      <Route
        path="/satellite/:id/download/:fileId"
        component={SatelliteFileDownload}
      />
    </Switch>
  </>
);
