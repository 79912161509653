import React from "react";
import styled from "styled-components";
import GridPrimitive, {
  GridProps as GridPrimitiveProps
} from "primitives/Grid";
import { AlignItemsProps } from "styled-system";

interface GridProps extends GridPrimitiveProps, AlignItemsProps {
  children?: React.ReactNode | string;
}

const GridBase = styled(GridPrimitive)<GridProps>`
  ${(props) => `align-items: ${props.alignItems || "unset"};`}
`;

const GridRowBase = styled(GridPrimitive)<GridProps>`
  display: unset;
  ${(props) =>
    `overflow: ${props.overflow !== "visible" ? "hidden" : "visible"};`}
  &:hover {
    ${(props) => `overflow: ${props.overflow || "auto"};`}
  }
`;

export const Grid = (props: GridProps) => <GridBase {...props} />;
export const GridRow = (props: GridProps) => <GridRowBase {...props} />;
