import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgPointer = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    viewBox="0 0 512 512"
    width={size || width}
    height={size || height}
    color={color}
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path d="M256,172.836c-45.926,0-83.157,37.237-83.157,83.164s37.23,83.157,83.157,83.157   c45.926,0,83.156-37.23,83.156-83.157S301.926,172.836,256,172.836z" />
      <path d="M440.562,233.98c-4.846-41.328-23.251-79.572-53.126-109.423C357.608,94.697,319.38,76.3,278.02,71.453V0   h-44.024v71.445c-41.345,4.831-79.589,23.236-109.432,53.111c-29.89,29.851-48.28,68.095-53.126,109.423H0v44.033h71.437   c4.847,41.329,23.236,79.58,53.126,109.431c29.828,29.867,68.072,48.265,109.432,53.111V512h44.024v-71.445   c41.329-4.831,79.572-23.236,109.416-53.111c29.89-29.851,48.28-68.102,53.126-109.431H512V233.98H440.562z M352.855,352.847   c-25.901,25.862-60.295,40.106-96.855,40.121c-36.561-0.015-70.954-14.259-96.855-40.121   c-25.854-25.893-40.098-60.287-40.113-96.847c0.015-36.56,14.259-70.954,40.113-96.847c25.901-25.87,60.294-40.113,96.855-40.121   c36.56,0.008,70.954,14.251,96.855,40.121c25.854,25.893,40.098,60.287,40.114,96.847   C392.952,292.56,378.709,326.954,352.855,352.847z" />
    </g>
  </Svg>
);

SvgPointer.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgPointer;
