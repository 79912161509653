import React from "react";
import { SuspenseMutation } from "app/network";
import { Toggler } from "components";
import { DeleteButton, CancelButton } from "components";
import { connect } from "react-redux";
import { deleteResource } from "../services";
import { Resource } from "../models";
import { Flex, Dialog, DialogTitle, DialogActions } from "primitives";

interface ResourceDeleteProps {
  resource: Resource;
  onChange: () => void;
  deleteResource: (id: number) => Promise<any>;
}

export const ResourceDelete = ({
  resource,
  onChange,
  ...props
}: ResourceDeleteProps) => {
  const submit = async (onToggle: () => void) => {
    await props.deleteResource(resource.id);
    if (onChange) {
      onChange();
    }
    onToggle();
  };

  return (
    <Toggler>
      {([toggled, onToggle]) => (
        <>
          <DeleteButton onClick={onToggle}>Delete</DeleteButton>

          <Dialog open={toggled} maxWidth="sm" data-testid="ResourceDelete">
            <SuspenseMutation>
              {({ loading, action }) => {
                return (
                  <>
                    <DialogTitle>Delete {resource.name}?</DialogTitle>

                    <DialogActions>
                      <Flex alignItems="center" mx={1}>
                        <DeleteButton
                          onClick={() => action(() => submit(onToggle))}
                          mr={1}
                          disabled={loading}
                        >
                          Delete
                        </DeleteButton>
                        <CancelButton onClick={onToggle}>Cancel</CancelButton>
                      </Flex>
                    </DialogActions>
                  </>
                );
              }}
            </SuspenseMutation>
          </Dialog>
        </>
      )}
    </Toggler>
  );
};

const mapDispatchToProps = () => ({
  deleteResource
});

export const ResourceDeleteContainer = connect(
  null,
  mapDispatchToProps
)(ResourceDelete);
