import objectPath from "object-path";
import { configOptionsGetTimeReferenceValue } from "app/telemetry/utils";

const sort = (
  passages: any[],
  sortBy: string,
  isSortAscending: boolean
): any[] => {
  passages = passages.sort((object1: any, object2: any) => {
    //Specific datasource reading sorting by time reference
    if (sortBy === "satelliteBootcountObt") {
      if (!object1.readings[0]) return -1;
      if (!object2.readings[0]) return 1;
      //Sort by satelliteBootcountObt. Format: [BOOT_COUNT] MILIS
      const bootCountMilis1 = configOptionsGetTimeReferenceValue(
        null,
        object1.readings[0],
        sortBy
      );
      const bootCountMilis2 = configOptionsGetTimeReferenceValue(
        null,
        object2.readings[0],
        sortBy
      );
      const bootCount1 = getStringBetweenSquareBrackets(bootCountMilis1);
      const bootCount2 = getStringBetweenSquareBrackets(bootCountMilis2);
      const milis1 =
        bootCountMilis1 && bootCountMilis1.length > 0
          ? bootCountMilis1.substring(bootCountMilis1.lastIndexOf(" ") + 1)
          : -1;
      const milis2 =
        bootCountMilis2 && bootCountMilis2.length > 0
          ? bootCountMilis2.substring(bootCountMilis2.lastIndexOf(" ") + 1)
          : -1;
      if (bootCount1 === bootCountMilis2) {
        return milis1 < milis2 ? -1 : milis1 > milis2 ? 1 : 0;
      } else {
        return bootCount1 < bootCount2 ? -1 : 1;
      }
    } else if (sortBy === "satelliteUtc" || sortBy === "groundUtc") {
      if (!object1.readings[0]) return -1;
      if (!object2.readings[0]) return 1;
      const date1 = new Date(
        configOptionsGetTimeReferenceValue(null, object1.readings[0], sortBy)
      );
      const date2 = new Date(
        configOptionsGetTimeReferenceValue(null, object2.readings[0], sortBy)
      );
      return date1.getTime() - date2.getTime();
    }
    ///

    switch (typeof objectPath.get(object1, sortBy)) {
      case "number":
        return (
          objectPath.get(object1, sortBy) - objectPath.get(object2, sortBy)
        );
      case "string":
        return objectPath
          .get(object1, sortBy)
          .localeCompare(objectPath.get(object2, sortBy));
      default:
        return (
          objectPath.get(object1, sortBy) - objectPath.get(object2, sortBy)
        );
    }
  });
  if (!isSortAscending) {
    passages = passages.reverse();
  }
  return passages;
};

const getStringBetweenSquareBrackets = (string: string) => {
  if (!string) return -1;
  const matches = string.match(/\[(.*?)\]/);
  if (matches) return matches[0];
  return -1;
};

/**
    Takes a array of objects and required @param label to sort the objects in alphabetical order. An additional third string @param "DESC" can be passed to reverse the order
*/
function sortObjectsByLabel<
  T extends { [key: string]: string },
  K extends keyof T,
  O extends "DESC"
>(arr: Array<T>, label: K, reverse?: O): Array<T> | null {
  if (!arr) {
    return null;
  }
  if (reverse) {
    return arr.sort((a: T, b: T) => b[label].localeCompare(a[label]));
  }
  return arr.sort((a: T, b: T) => a[label].localeCompare(b[label]));
}

export { sort, sortObjectsByLabel };
