import { GroundStation } from "../models";
import { GroundStationActions, GroundStationActionType } from "../actions";

interface GroundStationStoreState {
  groundStations: GroundStation[];
  selectedGroundStation?: GroundStation | null;
}

const initialState: GroundStationStoreState = {
  groundStations: [],
  selectedGroundStation: null
};

export const groundStationReducer = (
  state: GroundStationStoreState = initialState,
  action: GroundStationActions
): GroundStationStoreState => {
  switch (action.type) {
    case GroundStationActionType.FetchGroundStations:
      return { groundStations: action.payload };

    case GroundStationActionType.FetchGroundStation:
      return {
        ...state,
        selectedGroundStation: action.payload
      };

    default:
      return state;
  }
};
