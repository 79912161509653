import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { DialogContentHandle } from "app/planningSystem/components/OperationActivityDialog/DialogContentHandle";
import { fetchProceduresAction } from "app/procedure/actions";
import { fetchResourcesAction } from "app/resources/actions";
import {
  createOperationActivityAction,
  getAllOperationActivitiesAction,
  editOperationActivityAction
} from "app/planningSystem/actions";
import { CreateOaParams, PatchOaParams } from "app/planningSystem/models";

const mapStateToProps = (state: any) => ({
  satelliteInstances: state.constellations.selected.satelliteInstances
});
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  getProcedures: () => dispatch(fetchProceduresAction()),
  getResources: () => dispatch(fetchResourcesAction()),
  createOperationActivity: (params: CreateOaParams) =>
    dispatch(createOperationActivityAction(params)),
  editOperationActivity: (uuid: string, params: PatchOaParams) =>
    dispatch(editOperationActivityAction(uuid, params)),
  getAllOperationActivities: () => dispatch(getAllOperationActivitiesAction())
});

export const DialogContentHandleContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogContentHandle);
