import styled, { css } from "styled-components";
import { themeGet } from "styled-system";
import Grid, { GridProps } from "primitives/Grid";

const md = css`
  ${themeGet("mediaQueries.md")} {
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      "header header header"
      "sider content content"
      "sider footer footer";
  }
`;

const MainLayout = styled(Grid)<GridProps>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr auto;
  grid-template-areas:
    "header"
    "sider"
    "content"
    "footer";

  ${md}
`;

MainLayout.defaultProps = {
  height: "100%"
};

export default MainLayout;
