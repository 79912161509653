import React, { useCallback, useState, useEffect } from "react";
import {
  Suspense,
  LoadingError,
  useMutation,
  SuspenseQuery
} from "app/network";
import { Form } from "app/shared";
import {
  Text,
  Flex,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "primitives";
import useToggle from "components/toggler/hooks/useToggle";
import {
  getDataSourceAlert,
  editDataSourceAlert,
  deleteDataSourceAlert
} from "../services";
import {
  convertAlertFormDataToApiSpec,
  convertAlertApiSpecToFormData
} from "../helpers";
import { alertSchema, alertUiSchema } from "../models/schemas";
import { AlertLevels, Alert, DataSource } from "../models";
import { EditButton, CancelButton, SaveButton, DeleteButton } from "components";

interface AlertEditProps {
  dataSource: DataSource;
  onChange: (dataSource: DataSource, alert: Alert | null) => void;
}

export const AlertEdit = ({ dataSource, onChange }: AlertEditProps) => {
  const [toggled, onToggle] = useToggle({ defaultToggled: false });
  const query = useCallback(() => getDataSourceAlert(dataSource), [dataSource]);

  return (
    <>
      <EditButton onClick={onToggle}>Alert</EditButton>
      <Dialog open={toggled} maxWidth="md">
        <DialogTitle>
          Edit Alert to #{dataSource.id} {dataSource.name}
        </DialogTitle>
        <Suspense>
          <SuspenseQuery query={query}>
            {({ response, error }) => (
              <AlertEditContent
                response={response}
                error={error}
                dataSource={dataSource}
                onToggle={onToggle}
                onChange={onChange}
              />
            )}
          </SuspenseQuery>
        </Suspense>
      </Dialog>
    </>
  );
};

interface AlertCreateContentProps extends AlertEditProps {
  onToggle: () => void;
  response: any;
  error: any;
}

const initializeIsOneByOne = (formData: any) => {
  const isOneByOne = [];
  let minValue: any = null,
    maxValue: any = null;
  let isValueBoundaryOneByOne = false;
  formData.valueBoundary.valueBoundaries?.forEach((valueBoundary: any) => {
    if (minValue === null && maxValue === null) {
      minValue = valueBoundary.minValue;
      maxValue = valueBoundary.maxValue;
    }
    if (
      minValue !== valueBoundary.minValue ||
      maxValue !== valueBoundary.maxValue
    ) {
      isValueBoundaryOneByOne = true;
    }
  });
  if (isValueBoundaryOneByOne) {
    isOneByOne.push("valueBoundary");
  }
  AlertLevels.forEach((alertLevel) => {
    let lowerThreshold: any = null,
      upperThreshold: any = null;
    let isAlertLevelOneByOne = false;
    formData.alertLevels[alertLevel]?.thresholds.forEach((threshold: any) => {
      if (lowerThreshold === null && upperThreshold === null) {
        lowerThreshold = threshold.lowerThreshold;
        upperThreshold = threshold.upperThreshold;
      }
      if (
        lowerThreshold !== threshold.lowerThreshold ||
        upperThreshold !== threshold.upperThreshold
      ) {
        isAlertLevelOneByOne = true;
      }
    });
    if (isAlertLevelOneByOne) {
      isOneByOne.push(alertLevel);
    }
  });
  return isOneByOne;
};

const AlertEditContent = ({
  dataSource,
  onToggle,
  onChange,
  response,
  error
}: AlertCreateContentProps) => {
  const [formData, setFormData] = useState(
    convertAlertApiSpecToFormData(dataSource, response.data)
  );
  const arraySize = dataSource.tmDataType.arraySize;
  const [isOneByOne, setIsOneByOne] = useState(
    arraySize ? initializeIsOneByOne(formData) : []
  );
  const [values, setValues] = useState({
    valueBoundary: {
      minValue: undefined,
      maxValue: undefined
    }
  } as any);

  const {
    response: updateResponse,
    loading,
    error: errorMutation,
    action
  } = useMutation();

  const submit = useCallback(
    async (e, form) => {
      e.preventDefault();
      setFormData(form.formData);
      const alert = convertAlertFormDataToApiSpec(dataSource, form.formData);
      return editDataSourceAlert(dataSource, alert);
    },
    [dataSource]
  );

  const submitDelete = useCallback(() => {
    return deleteDataSourceAlert(dataSource);
  }, [dataSource]);

  useEffect(() => {
    if (updateResponse) {
      const { data } = updateResponse;
      onToggle();
      if (onChange) {
        onChange(dataSource, data);
      }
    }
  }, [updateResponse, dataSource, onChange, onToggle]);

  const handleFormChange = (
    form: any,
    e: React.FormEvent<HTMLInputElement>
  ) => {
    if (
      //eslint-disable-next-line
      form.formData.valueBoundary.isBulkAssign == "false" &&
      isOneByOne.indexOf("valueBoundary") === -1
    ) {
      form.formData.valueBoundary.isBulkAssign = false;
      const newValues = {
        valueBoundary: {
          minValue: form.formData.valueBoundary.minValue,
          maxValue: form.formData.valueBoundary.maxValue
        }
      };
      setValues({ ...values, ...newValues } as any);
      const newIsOneByOne = [...isOneByOne];
      newIsOneByOne.push("valueBoundary");
      setIsOneByOne(newIsOneByOne);
    }
    //eslint-disable-next-line
    if (form.formData.valueBoundary.isBulkAssign == "true") {
      form.formData.valueBoundary.isBulkAssign = true;
      const newIsOneByOne = [...isOneByOne];
      const index = newIsOneByOne.indexOf("valueBoundary");
      if (index > -1) {
        newIsOneByOne.splice(index, 1);
      }
      setIsOneByOne(newIsOneByOne);
    }

    AlertLevels.forEach((alertLevel) => {
      if (
        //eslint-disable-next-line
        form.formData.alertLevels[alertLevel].isBulkAssign == "false" &&
        isOneByOne.indexOf(alertLevel) === -1
      ) {
        form.formData.alertLevels[alertLevel].isBulkAssign = false;
        const newValues: Record<any, any> = {};
        if (
          form.formData.alertLevels[alertLevel].lowerThreshold ||
          form.formData.alertLevels[alertLevel].lowerThreshold === 0 ||
          form.formData.alertLevels[alertLevel].upperThreshold ||
          form.formData.alertLevels[alertLevel].upperThreshold === 0
        ) {
          newValues[alertLevel] = {
            thresholds: {
              lowerThreshold:
                form.formData.alertLevels[alertLevel].lowerThreshold,
              upperThreshold:
                form.formData.alertLevels[alertLevel].upperThreshold
            }
          };
        }
        setValues({ ...values, ...newValues } as any);
        const newIsOneByOne = [...isOneByOne];
        newIsOneByOne.push(alertLevel);
        setIsOneByOne(newIsOneByOne);
      }
      //eslint-disable-next-line
      if (form.formData.alertLevels[alertLevel].isBulkAssign == "true") {
        form.formData.alertLevels[alertLevel].isBulkAssign = true;
        const newIsOneByOne = [...isOneByOne];
        const index = newIsOneByOne.indexOf(alertLevel);
        if (index > -1) {
          newIsOneByOne.splice(index, 1);
        }
        setIsOneByOne(newIsOneByOne);
      }
    });
  };

  if (error) {
    return (
      <>
        <DialogContent>
          <LoadingError />
        </DialogContent>
        <DialogActions>
          <Flex justifyItems="flex-end" alignItems="center">
            <CancelButton onClick={onToggle}>Cancel</CancelButton>
          </Flex>
        </DialogActions>
      </>
    );
  }

  if (
    values.valueBoundary &&
    (values.valueBoundary.minValue || values.valueBoundary.minValue === 0) &&
    (values.valueBoundary.maxValue || values.valueBoundary.maxValue === 0)
  ) {
    formData.valueBoundary.valueBoundaries.forEach(
      (valueBoundary: any, index: number) => {
        formData.valueBoundary.valueBoundaries[index].minValue =
          values.valueBoundary.minValue;
        formData.valueBoundary.valueBoundaries[index].maxValue =
          values.valueBoundary.maxValue;
      }
    );
  }
  AlertLevels.forEach((alertLevel: string) => {
    if (
      values[alertLevel] &&
      (values[alertLevel].thresholds.lowerThreshold ||
        values[alertLevel].thresholds.lowerThreshold === 0) &&
      (values[alertLevel].thresholds.upperThreshold ||
        values[alertLevel].thresholds.upperThreshold === 0)
    ) {
      formData.alertLevels[alertLevel].thresholds.forEach(
        (threshold: any, index: number) => {
          formData.alertLevels[alertLevel].thresholds[index].lowerThreshold =
            values[alertLevel].thresholds.lowerThreshold;
          formData.alertLevels[alertLevel].thresholds[index].upperThreshold =
            values[alertLevel].thresholds.upperThreshold;
        }
      );
    }
  });

  return (
    <>
      {response && (
        <DialogContent>
          <Form
            id="alert-edit"
            formData={formData}
            schema={alertSchema(dataSource, isOneByOne, values)}
            uiSchema={alertUiSchema(dataSource, isOneByOne)}
            disabled={loading}
            onChange={(form: any, e: React.FormEvent<HTMLInputElement>) => {
              handleFormChange(form, e);
            }}
            onSubmit={(form: any, e: React.FormEvent<HTMLInputElement>) =>
              action(() => submit(e, form))
            }
          >
            <></>
          </Form>
        </DialogContent>
      )}

      <DialogActions>
        <Flex justifyItems="flex-end" alignItems="center">
          {errorMutation && (
            <Box mx={2}>
              <Text color="text.danger">Error...</Text>
            </Box>
          )}
          <SaveButton form="alert-edit" type="submit" mr={1} disabled={loading}>
            Save
          </SaveButton>
          <DeleteButton
            mr={1}
            onClick={() => action(() => submitDelete())}
            disabled={loading}
          >
            Delete
          </DeleteButton>

          <CancelButton onClick={onToggle} disabled={loading}>
            Cancel
          </CancelButton>
        </Flex>
      </DialogActions>
    </>
  );
};
