import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgArrowLeftPagination = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    color={color}
  >
    <g>
      <g>
        <path
          d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"
          fill="#7AB9DB"
        ></path>
      </g>
    </g>
  </Svg>
);

SvgArrowLeftPagination.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgArrowLeftPagination;
