import React, { useEffect } from "react";

interface PageVisibilityProps {
  /**
   * The callback function to be called when the page visibility changes
   */
  onChange: (isVisible: boolean) => void;
  /**
   * The component upon which should impact the onChange visibility API
   */
  children: JSX.Element | JSX.Element[];
}

const PageVisibility: React.FC<PageVisibilityProps> = ({
  onChange,
  children
}) => {
  useEffect(() => {
    const visibilityChangeAction = (): void => {
      onChange(!document.hidden);
    };
    // document.addEventListener("visibilitychange", visibilityChangeAction);

    return (): void => {
      document.removeEventListener("click", visibilityChangeAction);
    };
  }, []);

  return <>{children}</>;
};

export default PageVisibility;
