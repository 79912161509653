import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgCancel = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    viewBox="0 0 42 42"
    width={size || width}
    height={size || height}
    color={color}
  >
    <path
      d="M21.9772 1.08501C21.5697 0.689926 20.9155 0.712754 20.5366 1.13528L10.8296 11.9593C10.453 12.3792 9.80384 12.4047 9.39541 12.0157L4.95625 7.78763C4.55234 7.40292 3.91178 7.42304 3.5328 7.83233L1.03537 10.5295C0.667197 10.9272 0.682726 11.5457 1.07039 11.9243L9.69188 20.3453C10.0989 20.7429 10.7552 20.7212 11.1351 20.2976L24.7524 5.11467C25.111 4.71479 25.0897 4.103 24.7041 3.72907L21.9772 1.08501Z"
      fill={color}
    />
  </Svg>
);

SvgCancel.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgCancel;
