import React from "react";
import { SvgProps } from "primitives/Svg";

const SvgCopy = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    stroke={"#7AB9DB"}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
    <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
  </svg>
);

SvgCopy.defaultProps = {
  width: "16",
  height: "16"
};
export default SvgCopy;
