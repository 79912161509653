import React from "react";
import { Text, Link } from "primitives";
import { LinkProps } from "primitives/Link";

export const MenuItem = ({ children, to, disabled, ...props }: LinkProps) => {
  return (
    <Text caps mb={2} {...props}>
      <Text pl={2} pr={3} fontSize={4}>
        +
      </Text>
      <Link to={to} disabled={disabled}>
        {children}
      </Link>
    </Text>
  );
};

