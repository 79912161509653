import React, { Component } from "react";
import { Form } from "app/shared";
import { Box, Heading, Flex, Text } from "primitives";
import { SuspenseQuery, SuspenseMutation, Suspense } from "app/network";
import { CancelButton, SaveButton } from "components";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { fetchProcedure, editProcedure } from "../services";
import { schemaEdit, uiSchemaEdit } from "../models/schemas";
import { Procedure, EditProcedureCommand } from "../models";
import { ThunkDispatch } from "redux-thunk";
import { fetchScriptsAction } from "app/scripting/actions";
import { AnyAction } from "redux";
import { ScriptListItem } from "app/scripting/models";

type RouteParams = {
  id: string;
};

interface ProcedureEditProps extends RouteComponentProps<RouteParams> {
  fetchProcedure: (id: number) => Promise<Procedure>;
  editProcedure: (id: number, data: EditProcedureCommand) => Promise<Procedure>;
  fetchScripts: () => Promise<ScriptListItem[]>;
  scripts: ScriptListItem[];
}

interface ProcedureEditState {
  formData: EditProcedureCommand | null;
  formChange: boolean;
}

export class ProcedureEdit extends Component<
  ProcedureEditProps,
  ProcedureEditState
> {
  state = {
    formData: null,
    formChange: false
  };

  componentDidMount() {
    this.props.fetchScripts();
  }

  async submit(
    e: React.FormEvent<HTMLInputElement>,
    formData: EditProcedureCommand
  ) {
    e.preventDefault();

    const { id } = this.props.match.params;

    await this.props.editProcedure(parseInt(id, 10), formData);
    this.props.history.push("/procedures");
  }

  onChange(form: ProcedureEditState) {
    this.setState({ formData: form.formData, formChange: true });
  }

  query = () => {
    const { id } = this.props.match.params;
    return this.props.fetchProcedure(parseInt(id, 10));
  };

  render() {
    const { formData, formChange } = this.state;
    const { scripts } = this.props;
    return (
      <Suspense>
        <SuspenseQuery query={this.query}>
          {({ response }) => {
            return (
              response && (
                <Box color="text.default" data-testid="ProcedureEdit" mx={3}>
                  <Flex mb={2}>
                    <Heading display={1}>Procedure</Heading>
                  </Flex>
                  <Flex flexDirection="column" bg="fill.0" p={3}>
                    <SuspenseMutation>
                      {({ loading, error, action }) => (
                        <Form
                          formData={formData || response}
                          schema={schemaEdit(scripts || [])}
                          uiSchema={uiSchemaEdit}
                          disabled={loading}
                          onSubmit={(
                            form: { formData: EditProcedureCommand },
                            e: React.FormEvent<HTMLInputElement>
                          ) => action(() => this.submit(e, form.formData))}
                          onChange={(form: ProcedureEditState) =>
                            this.onChange(form)
                          }
                        >
                          <CancelButton
                            onClick={() =>
                              this.props.history.push("/procedures")
                            }
                            mr={2}
                          >
                            Cancel
                          </CancelButton>
                          <SaveButton
                            type="submit"
                            disabled={loading || !formChange}
                          >
                            Save
                          </SaveButton>

                          {error ? (
                            <Box my={2}>
                              <Text color="text.danger">Error...</Text>
                            </Box>
                          ) : null}
                        </Form>
                      )}
                    </SuspenseMutation>
                  </Flex>
                </Box>
              )
            );
          }}
        </SuspenseQuery>
      </Suspense>
    );
  }
}

const mapStateToProps = (state: any) => ({
  scripts: state.scripts.scripts
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  fetchProcedure,
  editProcedure,
  fetchScripts: () => dispatch(fetchScriptsAction())
});

export const ProcedureEditContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProcedureEdit)
);
