import React from "react";
import { Box, Text, Flex, Icon } from "primitives";
import { tcStack } from "app/telecommandStack/actions";
import { TelecommandResponseIndicator } from "app/telecommand/components/TelecommandsListTable";
import {
  Spinner,
  DeleteButton,
  EditButton,
  CopyButton,
  SendButton
} from "components";
import { clone } from "utils";
import RenderParams from "./RenderParams";
import { AddCommentToStack } from "./AddComment";
import { isComment, isTelecommand } from "app/telecommandStack/helpers";
import { useDispatch } from "react-redux";
import {
  SendStatusLight,
  getIndicatorBG,
  numOfGrid,
  getBgGradient
} from "./helpers";
import {
  TelecommandExecutionMode,
  AuroraTelecommandPayload
} from "app/telecommand/models";
import {
  selectTelecommand,
  selectTelecommandExecutionMode
} from "app/telecommand/actions";

export function RenderTelecommand(telecommand: any, index: number, props: any) {
  const { selectedPassage, satellite } = props;
  const dispatch = useDispatch();

  const onEdit = async (tc: any, idx: any) => {
    const telecommandId = tc.telecommand.telecommandUri;
    const telecommandExecutionPayload = tc.telecommand;
    const telecommandRecordId = Number(tc.id);
    dispatch(selectTelecommand(telecommandId, telecommandRecordId));

    const formData = AuroraTelecommandPayload.build(
      telecommandExecutionPayload
    ).toFormData();

    dispatch(
      selectTelecommandExecutionMode(TelecommandExecutionMode.Form, formData)
    );
    tcStack.editTc(tc, idx);
  };

  return (
    <>
      <Flex
        alignSelf="center"
        width={20}
        overflow="visible"
        cursor="grab"
        mb="16px"
      >
        <Icon size={12} name="Drag" />
      </Flex>
      <Flex
        alignItems="center"
        width="100%"
        ml="10px"
        pb={numOfGrid * 2}
        justifyContent="space-between"
      >
        {isTelecommand(telecommand) ? (
          <>
            <Text ml={2} fontSize="12px" bold color="text.default">
              {telecommand.telecommand.telecommandUri}
            </Text>
            <RenderParams telecommand={telecommand} />
          </>
        ) : (
          <Text ml={2} fontSize="12px" bold color="text.default">
            {telecommand.comment}
          </Text>
        )}
      </Flex>
      <Flex overflow="visible" alignItems="center" pb={numOfGrid * 2}>
        {isTelecommand(telecommand) && (
          <Box
            mr={2}
            ml="10px"
            overflow="visible"
            borderLeft={"0.5px dashed"}
            borderColor="#6E8093"
          >
            <SendButton
              onClick={() => {
                if (!selectedPassage) {
                  tcStack.toast("No passage selected", "ERROR");
                } else
                  return tcStack.sendTelecommand(
                    telecommand,
                    selectedPassage,
                    satellite,
                    true
                  );
              }}
            />
          </Box>
        )}

        <Box mr={2} overflow="visible">
          {isComment(telecommand) ? (
            <AddCommentToStack
              disabled={false}
              type="edit"
              comment={telecommand.comment}
              id={telecommand.id}
            />
          ) : (
            <EditButton
              height={25}
              width={33}
              onClick={() => {
                return onEdit(clone(telecommand), index);
              }}
            />
          )}
        </Box>
        {isTelecommand(telecommand) && (
          <Box mr={2} overflow="visible">
            <CopyButton onClick={() => tcStack.copy(telecommand)} />
          </Box>
        )}

        <Box mr={2} overflow="visible">
          <DeleteButton size={16} onClick={() => tcStack.remove(telecommand)} />
        </Box>

        {isTelecommand(telecommand) &&
          telecommand.status &&
          telecommand.status.level && (
            <Box mr={2} ml={2} overflow="visible" fontSize={"10px"}>
              <TelecommandResponseIndicator
                responseStatus={telecommand.status}
              />
            </Box>
          )}
        <Box
          overflow="visible"
          borderLeft={"0.5px dashed"}
          borderColor="#6E8093"
        >
          {isTelecommand(telecommand) &&
          telecommand.status &&
          telecommand.status.statusName &&
          telecommand.status.statusName === "TcNone" ? (
            <Box ml="13px" overflow="hidden">
              <Spinner />
            </Box>
          ) : (
            isTelecommand(telecommand) && (
              <SendStatusLight
                mr={2}
                ml="50%"
                bg={`${getIndicatorBG(telecommand.status)}`}
                bgGradient={`${getBgGradient(telecommand.status)}`}
              />
            )
          )}
        </Box>
      </Flex>
    </>
  );
}
