import { dataProvider, CREATE, UPDATE, DELETE } from "app/network";

export const createSystemDefinition = (parentDefinitionId, record, values) => {
  if (parentDefinitionId) values.parentDefinitionId = parentDefinitionId;
  if (!values.dataSources) {
    values.dataSources = [];
  }
  return dataProvider(
    CREATE,
    `satellite-definition/${record.id}/system-definition`,
    {
      data: values
    }
  );
};

export const editSystemDefinition = (parentRecord, record, values) => {
  const { id, systems, dataSources, ...data } = values;
  data.dataSources = dataSources;
  return dataProvider(
    UPDATE,
    `satellite-definition/${parentRecord.id}/system-definition`,
    {
      id: record.id,
      data
    }
  );
};

export const addDatasource = (
  satelliteDefinitionId,
  systemDefinitionId,
  datasource
) => {
  return dataProvider(
    CREATE,
    `satellite-definition/${satelliteDefinitionId}/system-definition/${systemDefinitionId}/datasource`,
    { data: datasource }
  );
};

export const deleteSystemDefinition = (parentRecord, record) => {
  return dataProvider(
    DELETE,
    `satellite-definition/${parentRecord.id}/system-definition`,
    {
      id: record.id
    }
  );
};
