export enum StatusIndicatorTheme {
  light = "#2e4162",
  dark = "#293650"
}

export type ISODateString = `${string}T${string}.${string}Z`;

export interface CountDownProps {
  expirationTime: ISODateString;
  theme?: keyof typeof StatusIndicatorTheme;
  onExpire?: Function;
}
