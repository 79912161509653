import { keyManagerDataProvider } from "app/network/dataProvider";
import { OperationType } from "app/network/models/constants";
import {
  SessionKey,
  SessionKeysFromMaster,
  DeriveSessionKeysParams,
  SessionKeyRequestParams,
  InjectSessionKeyRequestParams,
  SetSessionKeyStateResponse,
  InjectSessionKeyResponse,
  ErrorResponse,
  DeriveSessionKeyResponse
} from "../models";
import { keyStatusMap } from "../utils/constants";

/**
 * GET
 */

export const fetchAllSessionKey = async (
  satelliteID: number,
  params: SessionKeysFromMaster
): Promise<SessionKey[]> => {
  try {
    const response: any = await keyManagerDataProvider(
      OperationType.GET_LIST,
      `${satelliteID}/session`,
      { filter: params }
    );
    return (Array.isArray(response.data) && response.data) || [];
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchSessionKey = async (
  satelliteID: number,
  keyId: string
): Promise<SessionKey | null> => {
  try {
    const response: any = await keyManagerDataProvider(
      OperationType.GET_LIST,
      `${satelliteID}/session/${keyId}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

/**
 * POST
 */

export const deriveSessionKey = async (
  satelliteID: number,
  params: DeriveSessionKeysParams
): Promise<DeriveSessionKeyResponse> => {
  try {
    const response: any = await keyManagerDataProvider(
      OperationType.CREATE,
      `${satelliteID}/session`,
      {
        data: params
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    return error as ErrorResponse;
  }
};

export const setSessionKeyState = async (
  satelliteID: number,
  params: SessionKeyRequestParams
): Promise<SetSessionKeyStateResponse> => {
  try {
    const response = await keyManagerDataProvider(
      OperationType.CREATE,
      `${satelliteID}/session/${params.session_key_id}/${
        keyStatusMap[params.state].endpoint
      }`,
      {
        data: params
      }
    );
    return response as SetSessionKeyStateResponse;
  } catch (error) {
    console.log(error);
    return error as ErrorResponse;
  }
};

export const injectSessionKey = async (
  satelliteID: number,
  params: InjectSessionKeyRequestParams
): Promise<InjectSessionKeyResponse> => {
  try {
    const response: any = await keyManagerDataProvider(
      OperationType.CREATE,
      `${satelliteID}/session/${params.session_key_id}/adapter`,
      {
        data: params.settings
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    return error as ErrorResponse;
  }
};
