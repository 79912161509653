import { connect } from "react-redux";
import HistoricalLink from "../components/HistoricalLink";

const mapStateToProps = (state) => ({
  satellites: state.constellations.dashboard,
  constellation: state.constellations.selected
});

const mapDispatchToProps = {};

export const HistoricalLinkContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoricalLink);
