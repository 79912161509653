import { connect } from "react-redux";
import {
  fetchAllMasterKeyAction,
  fetchAllSessionKeyAction
} from "app/keyManager/actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { KeyManager } from "app/keyManager/components/KeyManager";
import { SessionKeysFromMaster } from "../models";
const mapStateToProps = (state: any) => ({
  masterKeys: state.keyManager.masterKeys,
  selectedSatellite: state.constellations.dashboard.find(
    (satellite: any) => satellite.visible
  )
});
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchAllMasterKey: (satelliteID: number) =>
      dispatch(fetchAllMasterKeyAction(satelliteID)),
    fetchSessionKeys: (satelliteID: number, params: SessionKeysFromMaster) =>
      dispatch(fetchAllSessionKeyAction(satelliteID, params))
  };
};

export const KeyManagerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KeyManager);
