import { createBrowserHistory } from "history";
import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { reducers } from "./reducers";

const initialState = {};
export const history = createBrowserHistory();

const middleware = applyMiddleware(thunk);
const store =
  process.env.NODE_ENV === "production"
    ? createStore(reducers, initialState, applyMiddleware(thunk))
    : createStore(reducers, initialState, composeWithDevTools(middleware));
export default store;
