import React, { Component, ChangeEvent } from "react";
import { convertOnboardTime } from "app/telemetry/utils";
import moment, { Moment } from "moment";
import { DatePicker, InputSimple } from "components";

interface OnboardTimeFilterProps {
  classes: {
    onboardTimeInput: string;
  };
  timeFilterChanged: (from: string, to: string) => void;
}

interface OnboardTimeFilterState {
  fromBoot: string;
  toBoot: string;
  fromMillis: string;
  toMillis: string;
}

const FROM_BOOT_NAME = "fromBoot";
const TO_BOOT_NAME = "toBoot";
const FROM_MILLIS_NAME = "fromMillis";
const TO_MILLIS_NAME = "toMillis";
const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm";

export class OnboardTimeFilter extends Component<
  OnboardTimeFilterProps,
  OnboardTimeFilterState
> {
  constructor(props: OnboardTimeFilterProps) {
    super(props);
    this.state = {
      fromBoot: "",
      toBoot: "",
      fromMillis: "",
      toMillis: ""
    };
  }

  render() {
    return (
      <>
        <InputSimple
          label="From boot"
          name={FROM_BOOT_NAME}
          my={1}
          mx={2}
          width="100px"
          onChange={(e) => this.onChangeOnboardTimeHandler(e)}
        />
        <InputSimple
          label="To boot"
          name={TO_BOOT_NAME}
          my={1}
          mx={2}
          width="100px"
          onChange={(e) => this.onChangeOnboardTimeHandler(e)}
        />
        <InputSimple
          label="From OBT (millis)"
          name={FROM_MILLIS_NAME}
          my={1}
          mx={2}
          width="100px"
          onChange={(e) => this.onChangeOnboardTimeHandler(e)}
        />
        <InputSimple
          label="To OBT (millis)"
          name={TO_MILLIS_NAME}
          my={1}
          mx={2}
          width="100px"
          onChange={(e) => this.onChangeOnboardTimeHandler(e)}
        />
      </>
    );
  }

  private onChangeOnboardTimeHandler(
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) {
    switch (e.target.name) {
      case FROM_BOOT_NAME:
        this.setState({ fromBoot: e.target.value }, () =>
          this.notifyOfChange()
        );
        break;
      case FROM_MILLIS_NAME:
        this.setState({ fromMillis: e.target.value }, () =>
          this.notifyOfChange()
        );
        break;
      case TO_BOOT_NAME:
        this.setState({ toBoot: e.target.value }, () => this.notifyOfChange());
        break;
      case TO_MILLIS_NAME:
        this.setState({ toMillis: e.target.value }, () =>
          this.notifyOfChange()
        );
        break;
    }
  }

  private notifyOfChange() {
    const { fromBoot, toBoot, fromMillis, toMillis } = this.state;
    if (fromBoot && toBoot && fromMillis && toMillis) {
      try {
        const from = convertOnboardTime(fromBoot, fromMillis);
        const to = convertOnboardTime(toBoot, toMillis);
        this.props.timeFilterChanged(from, to);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn(
          "Cannot set the string representation of the onboard time."
        );
      }
    }
  }
}

interface DateTimeFilterProps {
  timeFilterChanged: (from: string, to: string) => void;
  paddingTop?: string | undefined;
  margin?: string | undefined;
  pbLabel?: string | undefined;
  borderBottom?: string | undefined;
  box?: boolean;
  mb?: string | number;
  noLabel?: boolean;
  defaultValues?: { from: Date; to: Date | string };
}

interface DateTimeFilterState {
  from?: Moment;
  to?: Moment | string;
}

const FROM_DATE_NAME = "fromDate";
const TO_DATE_NAME = "toDate";

export class DateTimeFilter extends Component<
  DateTimeFilterProps,
  DateTimeFilterState
> {
  constructor(props: DateTimeFilterProps) {
    super(props);
    this.state = {
      from:
        (props.defaultValues && moment.utc(props.defaultValues.from)) ||
        undefined,
      to:
        props.defaultValues && typeof props.defaultValues === "string"
          ? props.defaultValues
          : (props.defaultValues && moment.utc(props.defaultValues.to)) ||
          undefined
    };
  }

  render() {
    const {
      paddingTop,
      margin,
      pbLabel,
      borderBottom,
      box,
      mb,
      noLabel,
      defaultValues
    } = this.props;
    return (
      <>
        <DatePicker
          paddingTop={paddingTop}
          margin={margin}
          pbLabel={pbLabel}
          borderBottom={borderBottom}
          box={box}
          mb={mb}
          selected={defaultValues && defaultValues.from}
          label={noLabel ? "" : "From"}
          name="fromDate"
          placeholder={noLabel ? `From ${DATE_TIME_FORMAT}` : DATE_TIME_FORMAT}
          handleChange={(e: { name: string; value: Date }) =>
            this.onChangeDateField(e)
          }
        />
        <DatePicker
          paddingTop={paddingTop}
          margin={margin}
          pbLabel={pbLabel}
          borderBottom={borderBottom}
          box={box}
          mb={mb}
          selected={defaultValues && defaultValues.to}
          label={noLabel ? "" : "To"}
          name="toDate"
          placeholder={noLabel ? `To ${DATE_TIME_FORMAT}` : DATE_TIME_FORMAT}
          handleChange={(e: { name: string; value: Date }) =>
            this.onChangeDateField(e)
          }
        />
      </>
    );
  }

  private onChangeDateField(e: { name: string; value: Date }) {
    switch (e.name) {
      case FROM_DATE_NAME:
        this.setState({ from: moment.utc(e.value) }, () => {
          this.notifyOfChange();
        });
        break;
      case TO_DATE_NAME:
        this.setState({ to: moment.utc(e.value) }, () => {
          this.notifyOfChange();
        });
        break;
    }
  }

  private notifyOfChange() {
    const { from, to } = this.state;
    const iso8601Format = "YYYY-MM-DDTHH:mm:ss[Z]";
    if (from && to) {
      if (from.isValid() && (to as Moment).isValid()) {
        this.props.timeFilterChanged(
          from.format(iso8601Format),
          (to as Moment).format(iso8601Format)
        );
      } else {
        // eslint-disable-next-line no-console
        console.warn("Invalid dates");
      }
    }
  }
}
