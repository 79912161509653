import {
  ADD_DASHBOARD,
  ADD_DASHBOARDS,
  UPDATE_DASHBOARD,
  SELECT_DASHBOARD,
  REMOVE_DASHBOARD,
  ADD_EXTENT_TO_GRAPH,
  REMOVE_ALL_EXTENTS
} from "../models/constants";

const initialState = {
  list: [],
  selected: undefined,
  graphs: {}
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DASHBOARD:
      return {
        ...state,
        list: state.list.concat(action.payload)
      };
    case ADD_DASHBOARDS:
      return {
        ...state,
        list: action.payload
      };
    case UPDATE_DASHBOARD:
      return updateDashboard(state, action);
    case REMOVE_DASHBOARD:
      return removeDashboard(state, action);
    case SELECT_DASHBOARD:
      return {
        ...state,
        selected: action.payload
      };
    case ADD_EXTENT_TO_GRAPH:
      return addExtent(state, action);
    case REMOVE_ALL_EXTENTS:
      return removeExtents(state);
    default:
      return state;
  }
};

const updateDashboard = (state, action) => {
  return {
    ...state,
    list: state.list.map((dashboard) => {
      if (dashboard.id === action.payload.id) {
        dashboard = action.payload;
      }
      return dashboard;
    })
  };
};

const removeDashboard = (state, action) => {
  return {
    ...state,
    list: state.list.filter((dashboard) => dashboard.id !== action.payload.id)
  };
};

const addExtent = (state, action) => {
  const { yExtent, index } = action.payload;
  if (!index && index !== 0) return state;
  if (state.selected.components[index].type !== "RealTimeGraph") return state;
  const newYExtents = { ...state.graphs.yExtents, [index]: yExtent };
  return {
    ...state,
    graphs: { ...state.graphs, yExtents: newYExtents }
  };
};

const removeExtents = (state) => {
  if (!state.graphs.yExtents) return state;
  const newGraphs = { ...state.graphs };
  delete newGraphs.yExtents;
  return {
    ...state,
    graphs: newGraphs
  };
};

export default dashboardReducer;
