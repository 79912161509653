import React from "react";
import { LoadingError } from "app/network";
import { useSuspenseQuery } from "./hooks";

interface SuspenseQueryResponse {
  response?: any;
  error?: any;
  reload: () => void;
}

interface SuspenseQueryProps {
  children: (props: SuspenseQueryResponse) => React.ReactElement;
  query: () => Promise<any>;
  errorFallback?: React.ReactNode;
  fallback?: React.ReactNode;
}

export const SuspenseQuery = ({
  children,
  query,
  errorFallback
}: SuspenseQueryProps) => {
  const { response, error, reload } = useSuspenseQuery(query);

  if (response || error) {
    if (!errorFallback && error) {
      return <LoadingError httpErrorStatus={error.status} />;
    }

    return children ? children({ response, error, reload }) : null;
  }

  return null;
};
