const initialState = {
  isConnected: false
};

const NotificationsStatusReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CONNECTED:
      return { isConnected: action.payload };
    default:
      return state;
  }
};

export default NotificationsStatusReducer;

export const CONNECTED = "CONNECTED";
