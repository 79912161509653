const FOT_SUB_DOMAIN = "ps.fot";

/**
 * @returns It reads from the process.env the Aurora API URL and modify it to be
 * compliant to the FOT API subdomain.
 */
export const getFotApiBaseUrl = (): string => {
  try {
    // Parse the URL using the URL class
    const parsedUrl = new URL(process.env.REACT_APP_API_URL || "");

    // Split the host by dots ('.')
    const hostParts = parsedUrl.host.split(".");

    // Modify the host components
    // Replace 'api' with 'ps.fot'
    hostParts[0] = FOT_SUB_DOMAIN;

    // Reconstruct the modified host
    parsedUrl.host = hostParts.join(".");

    let fotBaseUrl = parsedUrl.toString();

    // Remove the trailing slash if present
    if (fotBaseUrl.endsWith("/")) {
      fotBaseUrl = fotBaseUrl.slice(0, -1);
    }

    // Return the modified URL
    return fotBaseUrl;
  } catch (error) {
    // Error handling
    console.error("Invalid URL:", error);
    return "";
  }
};
