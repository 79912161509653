import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Flex } from "primitives";
import { clearFeedback } from "app/feedback/actions";
import { Feedback } from "./Feedback";
import {
  Feedback as FeedbackInterface,
  FeedbackStatus,
  getFeedbackStatus
} from "app/feedback/models";
import { setFeedback } from "app/feedback/actions";
import { useDispatch } from "react-redux";
interface FeedbackBaseProps {
  feedback: Array<FeedbackInterface>;
  hideFeedback: any;
  showInFooter?: boolean;
}

const NOTIFICATION_URL = process.env.REACT_APP_NOTIFICATION_URL;

enum MessageType {
  TELEMETRY_SYNC_PROGRESS = "TELEMETRY_SYNC_PROGRESS_NOTIFICATION",
  TELECOMMAND_SYNC_PROGRESS = "TELECOMMAND_SYNC_PROGRESS_NOTIFICATION",
  SERVICE_STATUS_NOTIFICATION = "SERVICE_STATUS_NOTIFICATION"
}

const handleEvent = (dispatch: any, msgType: string, data: any) => {
  const { payload } = typeof data === "string" ? JSON.parse(data) : data;
  let formattedMsg;
  switch (msgType) {
    case MessageType.TELEMETRY_SYNC_PROGRESS:
      // eslint-disable-next-line no-case-declarations
      const tsp = JSON.parse(payload);
      formattedMsg = `${tsp.name} - ${((tsp.current / tsp.total) * 100).toFixed(
        2
      )}% -
      ${tsp.current} / ${tsp.total} completed`;

      dispatch(setFeedback(formattedMsg, FeedbackStatus.SUCCESS, ""));
      break;
    case MessageType.TELECOMMAND_SYNC_PROGRESS:
      formattedMsg = `${payload.name} - ${(
        (payload.current / payload.total) *
        100
      ).toFixed(2)}% -
  ${payload.current} / ${payload.total} completed`;

      dispatch(setFeedback(formattedMsg, FeedbackStatus.SUCCESS, ""));
      break;
    case MessageType.SERVICE_STATUS_NOTIFICATION:
      formattedMsg = `Satellite: ${data.satelliteId} - ${data.name} - ${data.state}`;

      dispatch(
        setFeedback(
          formattedMsg,
          getFeedbackStatus(data.status),
          data.details,
          "showInFooter"
        )
      );
      break;
    default:
      return "";
  }
};

const FeedbackBaseList = ({
  feedback,
  hideFeedback,
  showInFooter
}: FeedbackBaseProps & RouteComponentProps) => {
  const eventSource = useRef<EventSource | null>(null);
  const [listening, setListening] = useState<boolean>(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const evtSource = eventSource.current;
    // console.log("Setting up event source for sse");

    if (!listening && process.env.NODE_ENV !== "test") {
      eventSource.current = new EventSource(`${NOTIFICATION_URL}`, {
        withCredentials: true
      });
      setListening(true);
    }
    if (evtSource) {
      evtSource.onerror = (e) => {
        if (evtSource) {
          console.log("EventSource closed:", e);
          evtSource.close();
          setTimeout(() => {
            setListening(false);
          }, 5000);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listening]);

  useEffect(() => {
    const evtSource = eventSource.current;
    Object.values(MessageType).forEach((msgTypeName) => {
      evtSource &&
        evtSource.addEventListener(msgTypeName, ((event: MessageEvent) => {
          const payload = JSON.parse(event.data);
          const msgType: any = event.type;
          if (Object.values(MessageType).includes(msgType)) {
            handleEvent(dispatch, msgType, payload);
          }
        }) as EventListener);
    });
    if (evtSource) {
      evtSource.onerror = (e) => {
        if (evtSource) {
          evtSource.close();
          console.log("EventSource closed:", e);
          evtSource.close();
          setTimeout(() => {
            setListening(false);
          }, 10000);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listening]);

  const footerFeedback =
    showInFooter && feedback.filter((item) => item.showInFooter)[0];
  return (
    <Flex>
      {footerFeedback && <Feedback feedback={footerFeedback} />}
      {feedback
        .filter((item) => !item.showInFooter)
        .map((item: any, index: number) => (
          <Feedback
            i={index}
            prevDet={
              feedback
                .slice(0, index)
                .filter((f: FeedbackInterface) => f.details).length
            }
            hideFeedback={hideFeedback}
            feedback={item}
            key={item.id}
          ></Feedback>
        ))}
    </Flex>
  );
};

const mapStateToProps = (state: any) => ({
  feedback: state.app.feedback
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    hideFeedback: (id: string) => dispatch(clearFeedback(id))
  };
};

export const FeedbackList = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedbackBaseList)
);
