import {
  FetchTimelineParams,
  FetchTimelineResponse,
  DisapproveParams,
  DisapproveTimelineError,
  DisapproveTimelineResponse,
  ApproveTimelineError,
  ApproveTimelineResponse,
  UpdateTimelineResponse,
  UpdateTimelineError,
  TimelineEntry
} from "app/planningSystem/models";
import { planningSystemDataProvider } from "app/network/dataProvider";
import { OperationType } from "app/network/models/constants";
import { HTTPResponse } from "app/network/dataProvider/model";

/**
 * GET
 */
export const fetchTimeline = async (
  params: FetchTimelineParams
): Promise<FetchTimelineResponse | null> => {
  try {
    const response = await planningSystemDataProvider(
      OperationType.GET_LIST,
      `timeline/`,
      { filter: params }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

/**
 * PUT
 */
export const disapproveMasterTimeline = async (
  params: DisapproveParams
): Promise<DisapproveTimelineResponse | DisapproveTimelineError | null> => {
  try {
    const response = await planningSystemDataProvider(
      OperationType.UPDATE_ONE,
      "timeline/master/disapprove/",
      { data: params }
    );
    return (
      ((response.data.detail || response.data.details) && response.data) ||
      (response as HTTPResponse).status
    );
  } catch (error) {
    return null;
  }
};

export const approveProposedTimeline = async (): Promise<
  ApproveTimelineResponse | ApproveTimelineError | null
> => {
  try {
    const response = await planningSystemDataProvider(
      OperationType.UPDATE_ONE,
      "timeline/proposed/approve/"
    );
    return (
      ((response.data.detail || response.data.details) && response.data) ||
      (response as HTTPResponse).status
    );
  } catch (error) {
    return null;
  }
};

export const updateProposedTimeline = async (
  params: TimelineEntry[]
): Promise<UpdateTimelineResponse | UpdateTimelineError | null> => {
  try {
    const response = await planningSystemDataProvider(
      OperationType.UPDATE,
      "timeline/proposed/",
      { data: params }
    );
    return (
      ((response.data.detail || response.data.details) && response.data) ||
      (response as HTTPResponse).status
    );
  } catch (error) {
    return null;
  }
};
