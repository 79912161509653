import React, { Component } from "react";
import { Text, Flex, Box, Absolute, Relative } from "primitives";
import { clone } from "utils";
import {
  AddButton,
  EditButton,
  DeleteButton,
  ConfigButton,
  CopyButton
} from "components";
import { componentSchemaGenerator, uiSchema } from "../models/component";
import { Form } from "app/shared";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { fetchGroundStations, GroundStation } from "app/groundStation";
import EditDashboardConfigurationModal from "app/dashboard/components/EditDashboardConfigurationModal";
import {
  DashboardComponents,
  TypesWithConfigOptions
} from "app/dashboard/models";

interface DashboardComponentsConfigurationState {
  selectedItemIndex: number | null;
  configOptionsModalItemIndex: number | null;
  groundStations: GroundStation[];
  width: number;
  showSideBar: boolean;
}

export class DashboardComponentsConfiguration extends Component<
  any,
  DashboardComponentsConfigurationState
> {
  private gridParentContainer: any;
  state: DashboardComponentsConfigurationState = {
    selectedItemIndex: null,
    configOptionsModalItemIndex: null,
    groundStations: [],
    width: window.innerWidth,
    showSideBar: JSON.parse(localStorage.getItem("showSideBar") as string)
  };
  constructor(props: any) {
    super(props);
    this.handleResize = this.handleResize.bind(this);
  }

  async componentWillMount() {
    const groundStations = await fetchGroundStations();
    this.setState({ groundStations });
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate() {
    const showSB = JSON.parse(localStorage.getItem("showSideBar") as string);
    if (showSB !== this.state.showSideBar) {
      this.setState({ showSideBar: showSB });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({
      width: window.innerWidth
    });
  }

  generateLayout() {
    const {
      schema: { formData }
    } = this.props;
    const dashboardSize = formData.size;
    const items = formData.components;
    if (!items) {
      return [];
    }
    const result = items.map((item: any, index: number) => {
      return {
        i: index.toString(),
        x: item.position.col - 1,
        y: item.position.row - 1,
        w: item.size.col,
        h: item.size.row,
        maxW: dashboardSize.col - item.position.col + 1
      };
    });
    return result;
  }

  generateLayoutItems() {
    const {
      schema: { formData }
    } = this.props;
    const items = formData.components;
    if (!items) {
      return null;
    }
    return items.map((item: any, index: number) => {
      return (
        <Flex key={index.toString()} flexDirection="column">
          <Flex pt={2} pr={2} flex={1} width="100%" justifyContent="flex-end">
            <EditButton
              maxHeight={34}
              ml={2}
              onClick={() => this.setState({ selectedItemIndex: index })}
            ></EditButton>
            <ConfigButton
              maxHeight={34}
              ml={2}
              onClick={() =>
                this.setState({ configOptionsModalItemIndex: index })
              }
              disabled={
                !item.type ||
                !Object.keys(TypesWithConfigOptions).includes(item.type)
              }
            ></ConfigButton>
            <CopyButton
              maxHeight={34}
              ml={2}
              onClick={() => this.duplicateItem(item)}
              disabled={!item.type}
            ></CopyButton>
            <DeleteButton
              maxHeight={34}
              ml={2}
              onClick={() => this.deleteItem(index)}
            ></DeleteButton>
          </Flex>
          <Flex flex={3} flexDirection="column" alignItems="center">
            <Text>
              #{index}
              {item.label ? ` - ${item.label}` : ""}
            </Text>
            {item.type ? (
              <Text bold>{DashboardComponents[item.type].componentName}</Text>
            ) : null}
          </Flex>
        </Flex>
      );
    });
  }

  duplicateItem(newItem: any) {
    const {
      schema: { formData }
    } = this.props;
    const items = formData.components;
    items.push(clone(newItem));
    this.props.onChange(items);
  }

  addItem() {
    const {
      schema: { formData }
    } = this.props;
    let items = formData.components;
    if (!items) {
      items = [];
    }
    const newItem = {
      label: "",
      type: "",
      size: {
        row: 1,
        col: 1
      },
      position: {
        row: 1,
        col: 1
      },
      dataSources: []
    };
    items.push(newItem);
    this.props.onChange(items);
  }

  deleteItem(index: number) {
    const {
      schema: { formData }
    } = this.props;
    const items = formData.components;
    items.splice(index, 1);
    this.setState({ selectedItemIndex: null }, () =>
      this.props.onChange(items)
    );
  }

  updateItemLabel(index: number, label: string) {
    const {
      schema: { formData }
    } = this.props;
    const items = formData.components;
    items[index].label = label;
  }

  updatePositionsAndSizes(newLayout: any) {
    const {
      schema: { formData }
    } = this.props;
    const items = formData.components;
    items.forEach((item: any, index: number) => {
      items[index].position.row = newLayout[index].y + 1;
      items[index].position.col = newLayout[index].x + 1;
      items[index].size.row = newLayout[index].h;
      items[index].size.col = newLayout[index].w;
    });
    this.props.onChange(items);
  }

  onFormChange(form: any) {
    const { selectedItemIndex } = this.state;
    if (selectedItemIndex !== null) {
      const {
        schema: { formData }
      } = this.props;
      const items = formData.components;
      const updatedItem = form.formData;
      if (
        items[selectedItemIndex].type &&
        items[selectedItemIndex].type !== updatedItem.type
      ) {
        updatedItem.configOptions = null;
      }
      items[selectedItemIndex] = updatedItem;
      this.props.onChange(items);
    }
  }

  onConfigOptionsChange(form: any) {
    const { selectedItemIndex } = this.state;
    if (selectedItemIndex !== null) {
      const {
        schema: { formData }
      } = this.props;
      const items = formData.components;
      const updatedConfigOptions = form.formData;
      items[selectedItemIndex].configOptions = updatedConfigOptions;
      this.props.onChange(items);
    }
  }

  render() {
    const {
      schema: {
        formData,
        dataSources,
        selectedSatelliteDefinition,
        satelliteInstances,
        addChange
      }
    } = this.props;

    const {
      groundStations,
      configOptionsModalItemIndex,
      showSideBar,
      width
    } = this.state;

    if (!formData) return null;
    const dashboardSize = formData.size;
    if (!dashboardSize || !dashboardSize.row || !dashboardSize.col) {
      return <Text>Dashboard size not yet defined.</Text>;
    }

    const { selectedItemIndex } = this.state;
    const layout = this.generateLayout();

    const schema = componentSchemaGenerator(
      dataSources,
      selectedSatelliteDefinition,
      selectedItemIndex !== null ? formData.components[selectedItemIndex] : null
    );

    return (
      <Flex flexDirection="column">
        <Relative id="layout-grid-container" pt={"24px"} pb={1}>
          <Absolute zIndex={1} right={0} top={0}>
            <Flex>
              <Box bg="palette.blue.4" ml="auto">
                <AddButton onClick={() => this.addItem()}>Add</AddButton>
              </Box>
            </Flex>
          </Absolute>
          <GridLayout
            className="layout"
            layout={layout}
            cols={dashboardSize.col}
            rowHeight={100}
            width={showSideBar ? width - 313 : width - 113}
            onDragStop={(newLayout: any) => {
              this.updatePositionsAndSizes(newLayout);
            }}
            onResizeStop={(newLayout: any) => {
              this.updatePositionsAndSizes(newLayout);
            }}
            onLayoutChange={(newLayout: any) => {
              this.updatePositionsAndSizes(newLayout);
            }}
          >
            {this.generateLayoutItems()}
          </GridLayout>
        </Relative>
        <Flex>
          {selectedItemIndex !== null ? (
            <Flex bg="palette.blue.4" width="100%" p={3} mb={2}>
              <Flex flexDirection="column">
                <Text fontSize={18}>Editing #{selectedItemIndex}</Text>
                <Form
                  formData={formData.components[selectedItemIndex]}
                  schema={schema}
                  uiSchema={uiSchema}
                  onChange={(form: any) => this.onFormChange(form)}
                >
                  <button type="submit" className="hidden">
                    Save
                  </button>
                </Form>
              </Flex>
            </Flex>
          ) : null}
          {configOptionsModalItemIndex !== null && (
            <EditDashboardConfigurationModal
              type={formData.components[configOptionsModalItemIndex].type}
              modalOpen={configOptionsModalItemIndex !== null}
              formData={
                formData.components[configOptionsModalItemIndex].configOptions
              }
              validator={
                DashboardComponents.getByType(
                  formData.components[configOptionsModalItemIndex].type
                ).validator
              }
              updateConfigOptions={(configOptions) =>
                (formData.components[
                  configOptionsModalItemIndex
                ].configOptions = configOptions)
              }
              closeModal={() =>
                this.setState({ configOptionsModalItemIndex: null })
              }
              groundStations={groundStations}
              satelliteInstances={satelliteInstances}
              addChange={addChange && addChange}
            />
          )}
        </Flex>
      </Flex>
    );
  }
}
