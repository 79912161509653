import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgArrowBack = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    viewBox="0 0 42 42"
    width={size || width}
    height={size || height}
    color={color}
  >
    <path d="M1.176 22.827L16.6026 38.241C17.6106 39.249 19.2402 39.249 20.2482 38.241C21.2562 37.2372 21.2562 35.6034 20.2482 34.5996L9.2106 23.5704L39.0054 23.5704C40.4292 23.5704 41.58 22.4196 41.58 21C41.58 19.5804 40.4292 18.4296 39.0054 18.4296L9.2064 18.4296L20.244 7.4004C21.252 6.3924 21.252 4.7628 20.244 3.759C19.236 2.751 17.6064 2.751 16.5984 3.759L1.176 19.173C0.671999 19.677 0.419999 20.3364 0.419999 20.9958C0.419999 21.6594 0.671999 22.323 1.176 22.827Z" />
  </Svg>
);

SvgArrowBack.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgArrowBack;
