import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import { TableCell } from "components";
import { Text } from "primitives";
import { TYPOGRAPHY, BORDER } from "styles/constants";
import { InputFieldProps } from "components/form/input/InputField";
import { Icon, Flex } from "primitives";
import { CommentProps } from "app/visibilityWindow/models";

export const Title = styled(Text)`
  text-transform: uppercase;
  font-size: 16px;
  color: ${themeGet("colors.palette.white.0")};
  margin-bottom: 8px;
`;

export const StyledTextArea = styled("textarea")`
  ${TYPOGRAPHY}
  ${BORDER}
  font-size: 15px;
  width: 100%;
  max-width: 500px;
  background-color: transparent;
  outline: none;
  border: white;
  color: ${themeGet("colors.palette.brand.0")};
  padding: 4px;
`;

const IconBox = styled(Flex)`
  cursor: pointer;
  justify-content: center;
  margin-top: 8px;
  margin-left: 4px;
  overflow: visible;

  &:hover {
    color: ${themeGet("colors.palette.grey.0")} !important;
  }
`;

const Container = styled(Flex)`
  background-color: transparent;
  outline: none;
  border: 1px solid ${themeGet("colors.palette.brand.0")};
  border-radius: 2px;
  padding: 0;
  flex-direction: column;
  align-items: flex-end;

  &:focus {
    border-width: 2px;
  }
  &:hover {
    border-width: 2px;
  }
`;

export const CommentArea = (props: InputFieldProps & CommentProps) => (
  <>
    <Container>
      <StyledTextArea {...props}> </StyledTextArea>
      <Flex>
        <IconBox onClick={() => props.onCancel()} data-testid="cancel-comment">
          <Icon name="Cancel" size={14} />
        </IconBox>
        <IconBox data-testid="save-comment">
          <Icon name="Check" size={16} />
        </IconBox>
      </Flex>
    </Container>
  </>
);

export const TableCellStyled = styled(TableCell)`
  width: 12.5%;
  white-space: nowrap;
`;

export const TableHeader = styled(TableCell)`
  z-index: 1;
`;
TableHeader.defaultProps = {
  position: "sticky",
  top: "2px",
  border: 4,
  borderColor: "fill.3",
  bg: "palette.blue.3"
};

export const TableHeaderStyled = styled(TableHeader)`
  width: 12.5%;
`;

export const enablePassageDetails = false;

export const TCell = styled(TableCell)`
  white-space: nowrap;
  min-width: 198px;
`;
