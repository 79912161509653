import { CheckBox } from "components";
import { InputBase, SelectBox } from "app/shared";
import { Label } from "primitives";
import { SatelliteInstance } from "app/satellite/models";
import { GroundStation } from ".";
import { FileWidget } from "./widget";

export const schemaCreate = (
  satellites: SatelliteInstance[],
  groundStations: GroundStation[],
  type: string
) => {
  const mainFields = {
    type: "object",
    required: ["satelliteID", "groundStationID"],
    properties: {
      satelliteID: {
        type: "number",
        title: "Satellite",
        enum: satellites.map((satellite: SatelliteInstance) => satellite.id),
        enumNames: satellites.map(
          (satellite: SatelliteInstance) => satellite.label
        )
      },
      groundStationID: {
        type: "number",
        title: "GroundStation",
        enum: groundStations.map(
          (groundStation: GroundStation) => groundStation.groundStationID
        ),
        enumNames: groundStations.map(
          (groundStation: GroundStation) => groundStation.groundStationName
        )
      },
      type: {}
    }
  };

  if (type) {
    mainFields.properties.type = {
      type: "string",
      title: type
    };
  }

  if (type === "VPN") {
    return {
      ...mainFields,
      ...{
        properties: {
          ...mainFields.properties,
          configuration: {
            title: "Configuration",
            type: "object",
            properties: {
              VPN: {
                title: "VPN",
                type: "object",
                required: ["IP", "port"],
                properties: {
                  IP: {
                    type: "string",
                    title: "IP"
                  },
                  port: {
                    type: "number",
                    title: "PORT"
                  },
                  username: {
                    type: "string",
                    title: "Username"
                  },
                  password: {
                    type: "string",
                    title: "Password",
                    password: true
                  }
                }
              }
            }
          }
        }
      }
    };
  } else if (type === "MQTT") {
    return {
      ...mainFields,
      ...{
        properties: {
          ...mainFields.properties,
          configuration: {
            title: "Configuration",
            type: "object",
            required: ["IP", "port", "uplinkTopic", "downlinkTopic"],
            properties: {
              IP: {
                type: "string",
                title: "IP"
              },
              port: {
                type: "number",
                title: "PORT"
              },
              uplinkTopic: {
                type: "string",
                title: "Uplink Topic"
              },
              downlinkTopic: {
                type: "string",
                title: "Downlink Topic"
              },
              username: {
                type: ["string", "null"],
                title: "Username"
              },
              password: {
                type: ["string", "null"],
                title: "Password",
                password: true
              },
              sslEnabled: {
                classNames: "sslEnabled-class-name",
                type: "boolean",
                title: "Enable SSL",
                enum: [false, true],
                default: false
              }
            },
            dependencies: {
              sslEnabled: {
                oneOf: [
                  {
                    properties: {
                      sslEnabled: {
                        enum: [false]
                      }
                    }
                  },
                  {
                    properties: {
                      sslEnabled: {
                        enum: [true]
                      },
                      certAuthority: {
                        title: "Certificate Authority (optional)",
                        type: "string"
                      },
                      clientCert: {
                        title: "Client Certificate ",
                        type: "string"
                      },
                      clientKey: {
                        title: "Client Key",
                        type: "string"
                      }
                    }
                  }
                ]
              }
            }
          }
        }
      }
    };
  } else {
    return {
      ...mainFields
    };
  }
};

export const schemaEdit = (
  satellites: SatelliteInstance[],
  groundStations: GroundStation[],
  type: string
) => ({
  type: "object",
  properties: {
    ...schemaCreate(satellites, groundStations, type).properties
  }
});

export const uiSchemaCreate = (type: string) => {
  const mainFields = {
    satelliteID: { "ui:widget": SelectBox },
    groundStationID: { "ui:widget": SelectBox },
    type: { "ui:widget": Label }
  };
  if (type === "VPN") {
    return {
      ...mainFields,
      configuration: {
        IP: { "ui:widget": InputBase },
        port: { "ui:widget": InputBase },
        username: { "ui:widget": InputBase },
        password: { "ui:widget": InputBase }
      }
    };
  } else if (type === "MQTT") {
    return {
      ...mainFields,
      configuration: {
        IP: { "ui:widget": InputBase },
        port: { "ui:widget": InputBase },
        downlinkTopic: { "ui:widget": InputBase },
        uplinkTopic: { "ui:widget": InputBase },
        username: { "ui:widget": InputBase },
        password: { "ui:widget": InputBase },
        sslEnabled: {
          "ui:widget": CheckBox,
          label: false
        },
        certAuthority: { "ui:widget": FileWidget },
        clientCert: { "ui:widget": FileWidget },
        clientKey: { "ui:widget": FileWidget }
      }
    };
  } else {
    return {
      ...mainFields
    };
  }
};

export const uiSchemaEdit = (type: string) => {
  return {
    ...uiSchemaCreate(type)
  };
};
