import React, { Component } from "react";
import { Box, Heading, Flex } from "primitives";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "components/table/Table";
import { Link } from "react-router-dom";
import { ConstellationDelete } from "./ConstellationDelete";
import { AddButton, EditButton } from "components";

export class ConstellationList extends Component {
  componentWillMount() {
    this.props.getConstellations();
  }

  componentDidUpdate() {
    const { list } = this.props;
    if (list && list[0]) {
      this.props.history.push(`/constellation/${list[0].id}`);
    }
  }

  render() {
    const { list } = this.props;
    if (!list || list.lenght === 0) {
      return null;
    }

    return (
      <Box data-testid="ConstellationList">
        <Flex alignItems="center" justifyContent="space-between" mb={2}>
          <Heading display={1}>Constellations</Heading>
          <Link to="/constellation/create">
            <AddButton>Create</AddButton>
          </Link>
        </Flex>
        <Table>
          <TableHead>
            <TableRow bg="fill.0">
              <TableCell width="50">ID</TableCell>
              <TableCell width="auto">Name</TableCell>
              <TableCell width="50" />
              <TableCell width="50" />
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((constellation) => (
              <TableRow key={constellation.id}>
                <TableCell>#{constellation.id}</TableCell>
                <TableCell>{constellation.name}</TableCell>
                <TableCell>
                  <Link to={`/constellation/${constellation.id}`}>
                    <EditButton>Edit</EditButton>
                  </Link>
                </TableCell>
                <TableCell>
                  <ConstellationDelete
                    record={constellation}
                    onChange={this.props.getConstellations}
                    selectConstellation={this.props.selectConstellation}
                    constellationId={this.props.constellationId}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    );
  }
}
