import React, { Component } from "react";
import { Form } from "app/shared";
import { Box, Heading, Flex, Text } from "primitives";
import { SuspenseQuery, SuspenseMutation, Suspense } from "app/network";
import { CancelButton, SaveButton } from "components";
import { RouteComponentProps } from "react-router";
import { GroundStation, GroundStationConfiguration } from "../models";
import { schemaEdit, uiSchemaEdit } from "../models/schemas";
import { SatelliteInstance } from "app/satellite/models";
import { formatConfigurationObject } from "../models/widget";

type RouteParams = {
  satelliteID: string;
  groundStationID: string;
};

interface GroundStationEditProps extends RouteComponentProps<RouteParams> {
  fetchGroundStationConfig: (
    satelliteID: number,
    groundStation: number
  ) => Promise<GroundStationConfiguration>;
  fetchGroundStations: () => Promise<GroundStation[]>;
  editGroundStation: (id: number, data: object) => Promise<GroundStation>;
  createGroundStationConfig: (
    data: GroundStationConfiguration
  ) => Promise<GroundStationConfiguration>;
  satellites: SatelliteInstance[];
  groundStations: GroundStation[];
}

interface GroundStationEditState {
  formData: any | null;
  formChange: boolean;
  groundStationType: string;
}

export class GroundStationEdit extends Component<
  GroundStationEditProps,
  GroundStationEditState
> {
  state = {
    formData: null,
    formChange: false,
    groundStationType: ""
  };

  componentDidMount() {
    this.props.fetchGroundStations().finally(() => {
      const { groundStationID } = this.props.match.params;
      if (this.getGroundStationById(parseInt(groundStationID, 10))) {
        this.setState({
          groundStationType: this.getGroundStationById(
            parseInt(groundStationID, 10)
          ).type
        });
      }
    });
  }

  async submit(
    e: React.FormEvent<HTMLInputElement>,
    formData: GroundStationConfiguration
  ) {
    e.preventDefault();

    const { groundStationID, satelliteID, type, configuration: c } = formData;
    const configuration = formatConfigurationObject(c);
    const newGroundStation = {
      groundStationID,
      satelliteID,
      type,
      configuration
    };
    await this.props.createGroundStationConfig(newGroundStation);
    this.props.history.push("/groundstations");
  }

  onChange(form: GroundStationEditState) {
    const currentFormData: any = this.state.formData;
    if (
      form.formData &&
      (currentFormData &&
        currentFormData.groundStationID !== form.formData.groundStationID) &&
      form.formData.groundStationID
    ) {
      //Update groundStationType
      if (this.getGroundStationById(form.formData.groundStationID)) {
        this.setState({
          groundStationType: this.getGroundStationById(
            form.formData.groundStationID
          ).type
        });
      }
    }
    this.setState({ formData: form.formData, formChange: true });
  }

  getGroundStationById(groundStationID: number): GroundStation {
    return this.props.groundStations.filter((groundStation: GroundStation) => {
      return groundStationID === groundStation.groundStationID;
    })[0];
  }

  query = () => {
    const { satelliteID, groundStationID } = this.props.match.params;
    return this.props.fetchGroundStationConfig(
      parseInt(satelliteID, 10),
      parseInt(groundStationID, 10)
    );
  };

  render() {
    const { formData, groundStationType, formChange } = this.state;
    const { satellites, groundStations } = this.props;

    if (!groundStationType) {
      return null;
    }

    return (
      <Suspense>
        <SuspenseQuery query={this.query}>
          {({ response }) => {
            return (
              response && (
                <Box color="text.default" mx={3}>
                  <Flex mb={2}>
                    <Heading display={1}>
                      Ground Station Configuration Edit
                    </Heading>
                  </Flex>
                  <Flex flexDirection="column" bg="fill.0" p={3}>
                    <SuspenseMutation>
                      {({ loading, error, action }) => {
                        return (
                          <Form
                            formData={formData || response}
                            formContext={formData || response}
                            schema={schemaEdit(
                              satellites || [],
                              groundStations,
                              groundStationType
                            )}
                            uiSchema={uiSchemaEdit(groundStationType)}
                            disabled={loading}
                            onSubmit={(
                              form: { formData: GroundStationConfiguration },
                              e: React.FormEvent<HTMLInputElement>
                            ) => action(() => this.submit(e, form.formData))}
                            onChange={(form: GroundStationEditState) =>
                              this.onChange(form)
                            }
                          >
                            <CancelButton
                              onClick={() =>
                                this.props.history.push("/groundstations")
                              }
                              mr={2}
                            >
                              Cancel
                            </CancelButton>
                            <SaveButton
                              type="submit"
                              disabled={loading || !formChange}
                            >
                              Save
                            </SaveButton>

                            {error ? (
                              <Box my={2}>
                                <Text color="text.danger">Error...</Text>
                              </Box>
                            ) : null}
                          </Form>
                        );
                      }}
                    </SuspenseMutation>
                  </Flex>
                </Box>
              )
            );
          }}
        </SuspenseQuery>
      </Suspense>
    );
  }
}

export default GroundStationEdit;
