import React, { useContext } from "react";
import { VisibiltyWindowContext } from "app/visibilityWindow/context";
import { VirtualizedTable } from "app/shared/VirtualizedTable";
import {
  PassageTableHeader,
  PassageRemainingTimeCell
} from "app/visibilityWindow/components/VisibilityWindowsList/components";
import { useSelector } from "react-redux";
import { getSatelliteName } from "app/shared/utils";
import { NextPassageDuration, Passage } from "app/visibilityWindow/models";
import {
  getNextPassageDuration,
  momentFormat
} from "app/visibilityWindow/helpers";
import {
  Toggler as PassageDetailsToggler,
  TableRow,
  TableCell
} from "components";
import {
  TableCellStyled,
  enablePassageDetails,
  TCell
} from "app/visibilityWindow/components/styledComponents";
import { PassageDetails } from "app/visibilityWindow/components/PassageDetails";
import { Button, Icon } from "primitives";
import moment from "moment";

const PassageTable = () => {
  const {
    state: { passages, calendarViewSelectedPassage },
    dispatch
  } = useContext(VisibiltyWindowContext);

  const tablePassages =
    (calendarViewSelectedPassage.length > 0 && calendarViewSelectedPassage) ||
    passages;

  return (
    (passages.length > 0 && (
      <VirtualizedTable
        data={tablePassages}
        header={<PassageTableHeader />}
        itemCount={tablePassages.length}
        itemSize={34}
        rowTemplate={RowTemplate}
      />
    )) || <></>
  );
};

const RowTemplate = ({ data: passage }: { data: Passage }) => {
  const { satelliteInstances } = useSelector(
    (state: any) => state.constellations.selected
  );

  const passageDuration: NextPassageDuration = getNextPassageDuration(passage);
  return (
    <PassageDetailsToggler key={passage.passageID}>
      {([show, toggleShow]) => (
        <>
          <TableRow
            data-passage={`{"id": "${passage.passageID}", "aos": "${moment(
              passage.aos
            ).valueOf()}" }`}
            data-testid="table-row"
            key={passage.passageID}
          >
            <TableCellStyled data-testid={passage.satelliteID.toString()}>
              {getSatelliteName(passage.satelliteID, satelliteInstances)}
            </TableCellStyled>
            <TableCellStyled>{passage.groundStationName}</TableCellStyled>
            <PassageRemainingTimeCell passage={passage} />
            <TableCellStyled>
              {moment.utc(passage.aos).format(momentFormat)}
            </TableCellStyled>
            <TableCellStyled>
              {moment.utc(passage.tca).format(momentFormat)}
            </TableCellStyled>
            <TableCellStyled>
              {moment.utc(passage.los).format(momentFormat)}
            </TableCellStyled>
            <TableCellStyled>
              {passageDuration.formatedDuration}
            </TableCellStyled>
            <TCell>{`${passage.maxElevation}º`}</TCell>
            {enablePassageDetails && (
              <TableCell maxWidth={48}>
                <Button size="small" onClick={toggleShow}>
                  {show ? (
                    <Icon name="ArrowUp" size="14" />
                  ) : (
                    <Icon name="ArrowDown" size="14" />
                  )}
                </Button>
              </TableCell>
            )}
          </TableRow>
          <PassageDetails show={show} />
        </>
      )}
    </PassageDetailsToggler>
  );
};

export { PassageTable };
