import * as ActionTypes from "../models/constants";

export const fetchSatelliteDefinitionStart = () => ({
  type: ActionTypes.FETCH_SATELLITE_DEFINITION_START
});

export const fetchSatelliteDefinitionSuccess = (payload) => ({
  payload,
  type: ActionTypes.FETCH_SATELLITE_DEFINITION_SUCCESS
});

export const fetchSatelliteDefinitionError = () => ({
  type: ActionTypes.FETCH_SATELLITE_DEFINITION_ERROR
});
