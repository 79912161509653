export default {
  Accept: "application/vnd.webgs.satellite-definition-v1+json; charset=UTF-8",
  ContentType:
    "application/vnd.webgs.satellite-definition-v1+json; charset=UTF-8",
  cache: "no-cache",
  TokenMinValidity: 5, // seconds
  timer: {
    defaultDataSourceReading: 2000,
    defaultGetExecutedTelecommands: 2000,
    graph: 2000,
    table: 3000,
    label: 3000,
    alerts: 5000,
    passages: 1000,
    passagesFetch: 10000,
    globe: 5000,
    clock: 1000,
    serverTimeSyncDuration: 30000,
    runningScripts: 1000,
    defaultGetOperationsList: 2000,
    refetchIntervalTCStackInMs: 150,
    defaultMaxTimeOutTCStackInMs: 30000
  }
};
