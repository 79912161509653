import React, { PureComponent, ChangeEvent, KeyboardEvent } from "react";
import { Flex, Button, Grid, Text } from "primitives";
import { InputField } from "components";
import { TelecommandSpec, TelecommandFormData } from "../models";
import { TelecommandSendConfirmation } from "./TelecommandExecutionHelpers";
import ReactJson from "react-json-view";
import objectPath from "object-path";
import { clone } from "utils";

interface TcCreationTerminalProps {
  telecommandSpecs: TelecommandSpec[];
  selectTelecommandAction: (telecommandId: string | null) => void;
  telecommandSpec: TelecommandSpec;
  onSubmitTelecommandHandler: (data: any) => Promise<any>;
  automaticFill: boolean;
  sendConfirmation: boolean;
  preventMultiSend: boolean;
}

interface InternalTerminalState {
  payload: TelecommandFormData;
  commandString: string;
  commandArgs: string[];
  requiredArgs: string[];
  loading: boolean;
  modalOpen: boolean;
  errorMsg: string;
}

export class TelecommandTerminal extends PureComponent<
  TcCreationTerminalProps,
  InternalTerminalState
> {
  private tc = {};

  constructor(props: TcCreationTerminalProps) {
    super(props);
    const defaultPayload = this.getDefaultValuesFromSpec(props.telecommandSpec);
    const payload = this.filterPayloadParams(
      props.telecommandSpec,
      defaultPayload
    );
    this.state = {
      payload: payload,
      commandString: props.telecommandSpec
        ? `${props.telecommandSpec.id} `
        : "",
      commandArgs: this.getInputSuggestionsFromPayload(payload),
      requiredArgs: this.getRequiredArgsFromSpec(props.telecommandSpec),
      loading: false,
      modalOpen: false,
      errorMsg: ""
    };
  }

  componentWillUpdate(
    nextProps: TcCreationTerminalProps,
    nextState: InternalTerminalState
  ) {
    const { telecommandSpecs, selectTelecommandAction } = this.props;
    const prevTelecommandId = this.state.commandString.split(" ")[0];
    const nextTelecommandId = nextState.commandString.split(" ")[0];
    if (prevTelecommandId !== nextTelecommandId) {
      const tcSpec = telecommandSpecs.find(
        (spec) =>
          spec.id.toLocaleLowerCase() === nextTelecommandId.toLocaleLowerCase()
      );
      if (tcSpec) {
        selectTelecommandAction(tcSpec.id);
      } else {
        selectTelecommandAction(null);
      }
    }
    if (nextState.commandString !== this.state.commandString) {
      this.updatePayload(nextState.commandString);
    }
  }

  componentDidUpdate(prevProps: TcCreationTerminalProps) {
    const props = this.props;
    if (
      (!prevProps.telecommandSpec && props.telecommandSpec) ||
      (prevProps.telecommandSpec &&
        props.telecommandSpec &&
        prevProps.telecommandSpec.id !== props.telecommandSpec.id)
    ) {
      const { commandString } = this.state;
      const newCommandString = props.telecommandSpec
        ? !commandString.startsWith(props.telecommandSpec.id)
          ? `${props.telecommandSpec.id} `
          : commandString
        : "";
      this.setState(
        {
          commandString: newCommandString,
          requiredArgs: this.getRequiredArgsFromSpec(props.telecommandSpec),
          errorMsg: ""
        },
        () => this.updatePayload(newCommandString)
      );
    }
  }

  commandInputOnChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState({ commandString: event.target.value });
  }

  commandInputOnKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    //If user presses TAB, autocomplete last input string
    if (event.key === "Tab") {
      event.preventDefault();
      let matchList: string[] = [];
      const { commandString, commandArgs } = this.state;
      const { telecommandSpecs } = this.props;
      const commandStringSplit = commandString.trim().split(" ");
      const isTcIdAutoComplete = commandStringSplit.length === 1;
      //Autocomplete telecommand id
      if (isTcIdAutoComplete)
        matchList = telecommandSpecs.map((spec) => spec.id);
      //Autocomplete telecommand params
      else matchList = commandArgs;
      const lastString: string | undefined = commandStringSplit.pop();
      const startsWithMatches: string[] = [];
      const otherMatches: string[] = [];
      matchList.forEach((match: string) => {
        if (
          lastString &&
          match.startsWith(lastString) &&
          commandString.indexOf(match) === -1
        ) {
          startsWithMatches.push(match);
        } else if (
          lastString &&
          match.toLowerCase().indexOf(lastString.toLowerCase()) !== -1 &&
          commandString.toLowerCase().indexOf(match.toLowerCase()) === -1
        ) {
          otherMatches.push(match);
        }
      });
      //Has only one "startWith" match
      if (lastString && startsWithMatches.length === 1) {
        const updatedCommandString: string = commandString
          .trim()
          .concat(startsWithMatches[0].replace(lastString, ""))
          .concat(isTcIdAutoComplete ? "" : "=");
        this.setState({ commandString: updatedCommandString });
      }
      //Has more than one "startWith" match
      else if (lastString && startsWithMatches.length > 1) {
        const startsWithMatchesSharedStart: string = this.sharedStart(
          startsWithMatches
        );
        const updatedCommandString: string = commandString
          .trim()
          .concat(startsWithMatchesSharedStart.replace(lastString, ""));
        this.setState({ commandString: updatedCommandString });
      }
      //Has other matches
      else if (lastString && otherMatches.length > 0) {
        const updatedCommandString: string = commandString
          .trim()
          .replace(new RegExp(`${lastString}$`), otherMatches[0])
          .concat(isTcIdAutoComplete ? "" : "=");
        this.setState({ commandString: updatedCommandString });
      }
    }
    //If user presses ENTER, send telecommand
    if (event.key === "Enter") {
      this.sendTelecommand();
    }
  }

  updatePayload(commandString: string) {
    const { telecommandSpec } = this.props;
    const { requiredArgs } = this.state;
    let updatedPayload = {
      ...this.getDefaultValuesFromSpec(telecommandSpec)
    };

    commandString.split(" ").forEach((stringValue: string, index: number) => {
      //comand arg format ex: command key1=value1 key2=value2
      if (stringValue.indexOf("=") !== -1) {
        const commandKeyValue: any[] = stringValue.split("=");
        if (commandKeyValue[0] && commandKeyValue[1]) {
          const path = commandKeyValue[0];
          const value = commandKeyValue[1];
          if (path.indexOf(".") !== -1) {
            objectPath.set(
              updatedPayload,
              path,
              this.convertArgDataToCorrectDataType(
                telecommandSpec,
                path,
                value,
                updatedPayload
              )
            );
          } else {
            updatedPayload[path] = this.convertArgDataToCorrectDataType(
              telecommandSpec,
              path,
              value,
              updatedPayload
            );
          }
        }
      }
      //comand arg format ex: command value1 value2
      else if (index > 0) {
        const requiredArg = requiredArgs[index - 1];
        if (requiredArg) {
          objectPath.set(
            updatedPayload,
            requiredArg,
            this.convertArgDataToCorrectDataType(
              telecommandSpec,
              requiredArg,
              stringValue,
              updatedPayload
            )
          );
        }
      }
    });

    updatedPayload = this.filterPayloadParams(telecommandSpec, updatedPayload);
    this.setState({
      payload: updatedPayload,
      commandArgs: this.getInputSuggestionsFromPayload(updatedPayload)
    });
  }

  sendTelecommand() {
    const { payload } = this.state;
    const newPayload = clone(payload);
    const { onSubmitTelecommandHandler, preventMultiSend } = this.props;
    const cleanData = this.validatePayload(newPayload);
    if (!cleanData) return null;
    this.setState({ loading: true, modalOpen: false, errorMsg: "" });
    onSubmitTelecommandHandler(cleanData);
    if (!preventMultiSend) {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000);
    }
  }

  render() {
    const { sendConfirmation, telecommandSpec } = this.props;
    const { payload, commandString, loading, modalOpen, errorMsg } = this.state;

    return (
      <Grid data-testid="TelecommandTerminal" mt={2} mb={2} pl={4} pr={4}>
        {errorMsg && <Text color="text.danger">{errorMsg}</Text>}
        <Flex mb={3} alignItems="center">
          <InputField
            id="command-input"
            required={false}
            value={commandString}
            onChange={(e: any) => this.commandInputOnChange(e)}
            onKeyDown={(e: any) => this.commandInputOnKeyDown(e)}
            autoFocus={true}
            multiline={true}
            onFocus={(e) => {
              //Place cursor at the end of the input text
              const val = e.target.value;
              e.target.value = "";
              e.target.value = val;
            }}
          />
          <Button
            disabled={loading}
            ml={2}
            onClick={() =>
              sendConfirmation
                ? this.setState({ modalOpen: true })
                : this.sendTelecommand()
            }
            maxHeight={40}
          >
            Send
          </Button>
        </Flex>
        {telecommandSpec && (
          <ReactJson
            src={payload}
            theme="monokai"
            name="payload"
            style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 5 }}
          />
        )}
        <TelecommandSendConfirmation
          cancel={() => this.setState({ modalOpen: false })}
          sendTelecommand={() => this.sendTelecommand()}
          modalOpen={modalOpen}
        />
      </Grid>
    );
  }

  //fill payload with default values
  private getDefaultValuesFromSpec(schema: TelecommandSpec) {
    const { automaticFill } = this.props;
    const payload: any = {};
    if (schema && schema.args) {
      schema.args.forEach((arg: any) => {
        if (automaticFill && arg.default) {
          if (isNaN(arg.default) || arg.argType === "Enum") {
            payload[arg.id] = arg.default;
          } else {
            payload[arg.id] = parseFloat(arg.default);
          }
        } else if (arg.groupSpec) {
          const getGroupArgs = (groupSpec: any) => {
            const groupArgs: any = {};
            groupSpec.forEach((groupArg: any) => {
              if (automaticFill && groupArg.default != null) {
                if (isNaN(groupArg.default) || groupArg.argType === "Enum") {
                  groupArgs[groupArg.id] = groupArg.default;
                } else {
                  groupArgs[groupArg.id] = parseFloat(groupArg.default);
                }
              } else if (groupArg.groupSpec) {
                groupArgs[groupArg.id] = {
                  ...getGroupArgs(groupArg.groupSpec)
                };
              } else {
                groupArgs[groupArg.id] = null;
              }
            });
            return groupArgs;
          };
          payload[arg.id] = { ...getGroupArgs(arg.groupSpec) };
        } else {
          payload[arg.id] = null;
        }
      });
    }
    return payload;
  }

  //filter payload parameters
  private filterPayloadParams(
    schema: any,
    payload: TelecommandFormData,
    path: string[] = []
  ) {
    this.tc = payload;
    /* eslint-enable no-unused-vars */
    if (!schema) return;
    const args = schema.args ? schema.args : schema;
    args.forEach((arg: any) => {
      if (arg.groupSpec) {
        const auxPath: any = [].concat(path as any);
        auxPath.push(arg.id);
        this.filterPayloadParams(arg.groupSpec, payload, auxPath);
      }
    });
    return payload;
  }

  //get argument auto-complete suggestions from payload
  private getInputSuggestionsFromPayload(payload: TelecommandFormData) {
    const args: string[] = [];
    const keys: string[] = payload && Object.keys(payload);
    if (keys) {
      keys.forEach((arg: string) => {
        const getGroupArgs = (groupPayload: any, commandStart: string) => {
          Object.keys(groupPayload).forEach((groupArg: any) => {
            if (
              typeof groupPayload[groupArg] === "object" &&
              groupPayload[groupArg]
            ) {
              getGroupArgs(
                groupPayload[groupArg],
                `${commandStart}.${groupArg}`
              );
            } else {
              if (isNaN(groupArg)) args.push(`${commandStart}.${groupArg}`);
              else args.push(`${commandStart}`);
            }
          });
        };
        if (typeof payload[arg] === "object" && payload[arg]) {
          getGroupArgs(payload[arg], arg);
        } else {
          args.push(arg);
        }
      });
    }
    return args;
  }

  //get required arguments
  private getRequiredArgsFromSpec(schema: TelecommandSpec) {
    const args: string[] = [];
    if (schema && schema.args) {
      schema.args.forEach((arg: any) => {
        const getGroupArgs = (groupSpec: any, commandStart: string) => {
          groupSpec.forEach((groupArg: any) => {
            if (groupArg.groupSpec) {
              getGroupArgs(
                groupArg.groupSpec,
                `${commandStart}.${groupArg.id}`
              );
            } else if (
              groupArg.default === undefined ||
              groupArg.default === null
            ) {
              args.push(`${commandStart}.${groupArg.id}`);
            }
          });
        };
        if (arg.groupSpec) {
          getGroupArgs(arg.groupSpec, arg.id);
        } else if (arg.default === undefined || arg.default === null) {
          args.push(arg.id);
        }
      });
    }
    return args;
  }

  //check for the longest shared start between strings, used for auto-complete
  private sharedStart(array: string[]) {
    let A = array.concat().sort(),
      a1 = A[0],
      a2 = A[A.length - 1],
      L = a1.length,
      i = 0;
    while (i < L && a1.charAt(i) === a2.charAt(i)) i++;
    return a1.substring(0, i);
  }

  //remove not filled arguments
  private validatePayload(payload: any, path: any[] = []) {
    const keys = Object.keys(payload);
    let result = payload;
    keys.forEach((key: string) => {
      if (result === null) return null;
      if (payload[key] === null) {
        delete payload[key];
        result = null;
        this.setState({ errorMsg: `Parameter ${key} cannot be empty` });
      } else if (
        typeof payload[key] === "object" &&
        !Array.isArray(payload[key])
      ) {
        const auxPath: any = [...path];
        auxPath.push(key);
        payload[key] = this.validatePayload(payload[key], auxPath);
        if (!payload[key]) {
          result = null;
          return null;
        }

        const childKeys = Object.keys(payload[key]);
        if (childKeys.length === 0) {
          delete payload[key];
        }
      } else {
        const { telecommandSpec } = this.props;
        const auxPath: any = [...path];
        auxPath.push(key);
        const stringPath = auxPath.join(".");
        const argType = this.getArgTypeFromSpec(telecommandSpec, stringPath);
        const dataType = this.getDataTypeFromSpec(telecommandSpec, stringPath);
        if (Array.isArray(payload[key])) {
          if (argType !== "Array") {
            result = null;
            this.setState({
              errorMsg: `Parameter ${key} should be a ${argType}`
            });
            return null;
          }
          let arraySize = this.getArgSizeFromSpec(telecommandSpec, stringPath);
          if (
            typeof arraySize === "string" &&
            (arraySize as any).charAt(0) === "@"
          ) {
            const auxArraySize: string = (arraySize as any).slice(1);
            if (payload[auxArraySize] && !isNaN(payload[auxArraySize])) {
              arraySize = payload[auxArraySize];
            }
          }
          if (payload[key].length !== arraySize) {
            result = null;
            this.setState({
              errorMsg: `Parameter ${key} array should have size ${arraySize}`
            });
            return null;
          }
          payload[key].forEach((value: any) => {
            if (!this.checkDataType(value, dataType)) {
              result = null;
              this.setState({
                errorMsg: `Parameter ${key} should be a ${dataType}`
              });
              return null;
            }
          });
        } else {
          if (argType !== "Literal") {
            result = null;
            this.setState({
              errorMsg: `Parameter ${key} should be a ${argType}`
            });
            return null;
          }
          if (!this.checkDataType(payload[key], dataType)) {
            result = null;
            this.setState({
              errorMsg: `Parameter ${key} should be a ${dataType}`
            });
            return null;
          }
          if (dataType !== "string") {
            const range: any = this.getDataRangeFromSpec(
              telecommandSpec,
              stringPath
            );
            if (
              range &&
              (payload[key] > range.max || payload[key] < range.min)
            ) {
              result = null;
              this.setState({
                errorMsg: `Parameter ${key} is not between the range [${range.min},${range.max}]`
              });
              return null;
            }
          }
        }
      }
    });
    return result;
  }

  private checkDataType = (value: any, dataType: string) => {
    if (isNaN(value) && dataType !== "string") return false;
    if (dataType === "int" && !Number.isInteger(value)) return false;
    return true;
  };

  //get an argument type given the spec and the argument path
  private getArgTypeFromSpec(spec: any, stringPath: string) {
    const splittedStringPath = stringPath.split(".");
    const splittedStringPathLength = splittedStringPath.length;
    let args: any = spec.args;
    let argType = "";
    splittedStringPath.forEach((auxPath: string, index: number) => {
      const arg = args.find((auxArg: any) => auxArg.id === auxPath);
      if (arg && splittedStringPathLength - index - 1 <= 0) {
        argType = arg.argType;
      } else if (arg && arg.groupSpec) {
        args = arg.groupSpec;
      }
    });
    return argType;
  }

  //get an argument data type given the spec and the argument path
  private getDataTypeFromSpec(spec: any, stringPath: string) {
    const splittedStringPath = stringPath.split(".");
    const splittedStringPathLength = splittedStringPath.length;
    let args: any = spec.args;
    let dataType = "string";
    splittedStringPath.forEach((auxPath: string, index: number) => {
      const arg = args.find((auxArg: any) => auxArg.id === auxPath);
      if (arg && splittedStringPathLength - index - 1 <= 0) {
        if (arg.argType === "Array") dataType = arg.itemSpec.dataType;
        else dataType = arg.dataType;
      } else if (arg && arg.groupSpec) {
        args = arg.groupSpec;
      }
    });
    return dataType;
  }

  //get an argument data range given the spec and the argument path
  private getDataRangeFromSpec(spec: any, stringPath: string) {
    const splittedStringPath = stringPath.split(".");
    const splittedStringPathLength = splittedStringPath.length;
    let args: any = spec.args;
    let range = null;
    splittedStringPath.forEach((auxPath: string, index: number) => {
      const arg = args.find((auxArg: any) => auxArg.id === auxPath);
      if (arg && splittedStringPathLength - index - 1 <= 0) {
        if (arg.range) range = arg.range;
      } else if (arg && arg.groupSpec) {
        args = arg.groupSpec;
      }
    });
    return range;
  }

  //get an argument (array) size given the spec and the argument path
  private getArgSizeFromSpec(spec: any, stringPath: string) {
    const splittedStringPath = stringPath.split(".");
    const splittedStringPathLength = splittedStringPath.length;
    let args: any = spec.args;
    let size = 0;
    splittedStringPath.forEach((auxPath: string, index: number) => {
      const arg = args.find((auxArg: any) => auxArg.id === auxPath);
      if (arg && splittedStringPathLength - index - 1 <= 0) {
        size = arg.size;
      } else if (arg && arg.groupSpec) {
        args = arg.groupSpec;
      }
    });
    return size;
  }

  //Convert argument data to correct data type
  private convertArgDataToCorrectDataType(
    telecommandSpec: TelecommandSpec,
    path: string,
    value: any,
    payload: any
  ) {
    if (!telecommandSpec) return;
    const argType = this.getArgTypeFromSpec(telecommandSpec, path);
    const dataType = this.getDataTypeFromSpec(telecommandSpec, path);
    if (argType === "Array") {
      return value
        ? value.split(",").map((valueElement: any) => {
            const auxValue =
              isNaN(valueElement) || dataType === "string"
                ? valueElement
                : parseFloat(valueElement);
            return auxValue;
          })
        : null;
    } else {
      return isNaN(value) || argType === "Enum" || dataType === "string"
        ? value
        : parseFloat(value);
    }
  }

  /*
   * AUX Functions for telecommand params filter
   */

  _getParent: any = (obj: any, value: any, parent: any) => {
    let p = null;
    if (obj === value) {
      p = parent;
    } else if (Array.isArray(value)) {
      for (const val of value) {
        p = this._getParent(obj, val, parent);
        if (p != null) {
          break;
        }
      }
    } else if (value && typeof value === "object") {
      /* eslint-disable no-unused-vars */
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [key, val] of Object.entries(value)) {
        p = this._getParent(obj, val, value);
        if (p != null) {
          break;
        }
      }
      /* eslint-enable no-unused-vars */
    }
    return p;
  };
}
