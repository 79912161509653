import { InputBase, SelectBox } from "app/shared";
import { FileWidget } from "app/shared/form";
export const schemaCreate = (sats: any) => {
  return {
    type: "object",
    required: ["name", "satelliteId"],
    properties: {
      name: {
        type: "string",
        title: "Name"
      },
      description: {
        type: "string",
        title: "Description"
      },
      satelliteId: {
        type: "number",
        title: "Satellite",
        enum: sats.map((s: any) => s.id),
        enumNames: sats.map((s: any) => s.name)
      },
      defaultTimeout: {
        type: "number",
        title: "Default Timeout(ms)"
      }
    }
  };
};

export const uiSchemaCreate = {
  name: { "ui:widget": InputBase },
  description: { "ui:widget": InputBase },
  satelliteId: { "ui:widget": SelectBox },
  defaultTimeout: { "ui:widget": "hidden" }
};

export const schemaEdit = (satellites: any) => {
  return {
    type: "object",
    properties: {
      id: {
        type: "string",
        title: "Id",
        kind: "constant"
      },
      ...schemaCreate(satellites).properties
    }
  };
};

export const uiSchemaEdit = {
  id: { "ui:widget": InputBase },
  ...uiSchemaCreate
};

export const uiSchemaImport = {
  file: {
    "ui:widget": FileWidget
  }
};

export const schemaImport = () => ({
  type: "object",
  properties: {
    file: {
      title: " ",
      type: "string"
    }
  }
});
