export interface ResourceBase {
  id: string;
  dir: string;
  name: string;
}

export interface ResourceMetadata {
  creationTimestamp: string;
  uuid: string;
  location: string;
}

export interface LocationsResponse {
  depth: number;
  directories: string[];
  repository: string;
  resources: ResourceBase[];
}

/**
 * Operations
 */

export interface Operation {
  opId: number;
  opType: OperationType;
  opStatus: OperationStatus;
  opProgress: number;
  satId: string;
  opSatPath: string;
  opResId: string;
  lastUpdate: Date;
}

export enum OperationType {
  DOWNLOAD = "download",
  UPLOAD = "upload"
}

export enum OperationStatus {
  ONGOING = "ongoing",
  PAUSED = "paused",
  COMPLETED = "completed",
  TERMINATED = "terminated"
}

export enum OperationStatusColor {
  "ongoing" = "text.default",
  "completed" = "text.success",
  "paused" = "text.warning",
  "terminated" = "text.danger"
}
