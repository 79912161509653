import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Flex, Button, Icon, Label, Heading } from "primitives";
import { InputField, SelectableList, DataListItem } from "components";
import { KeyManagerContext } from "../context";
import { SessionKeyFilter } from "./SessionKeyFilter";
import { SessionKeyItem } from "./SessionKeyItem";
import {
  MasterKeyDetailProps,
  SessionKey,
  ContextActionsNames
} from "../models";
import { CommonProps, COMMON, FLEX, FlexProps } from "styles/constants";

const Container = styled("div")`
  background-color: #293650;
  padding: 10px;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  font-size: 12px;
`;

const KeyVisualizr = styled("div")<FlexProps & CommonProps>`
  ${COMMON}
  ${FLEX}
  word-break: break-all;
  background-color: #1f1e2c;
  border-radius: 5px;
  padding: 10px;
  font-family: monospace;
`;

const MasterKeyInfo = styled("div")`
  margin-bottom: 40px;
  & input:hover {
    border-width: 0px 0px 1px 0px !important;
  }
`;

const NoSelectedKey = styled("div")`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const FieldContainer = styled("div")`
  width: 50px;
`;
export const MasterKeyDetail = ({
  selectedSatellite,
  deriveSessionKey,
  sessionKeysMap
}: MasterKeyDetailProps) => {
  const [num_keys, setNum_keys] = useState<number>(1);
  const {
    state: { selectedMasterKey, applyedFilters },
    dispatch
  } = useContext(KeyManagerContext);
  const handleCreateSessionKey = () => {
    if (selectedMasterKey) {
      return deriveSessionKey(selectedSatellite.id, {
        master_key_id: selectedMasterKey.key_id,
        num_keys
      });
    }
    console.log("There's no master key selected");
  };

  const handleSessionKeySelection = (selectedSessionKeys: DataListItem[]) => {
    dispatch({
      type: ContextActionsNames.selectedSessionKey,
      payload: selectedSessionKeys
    });
  };

  const filterSessionKey = (skeys: SessionKey[]) => {
    let filteredSessioKeys = skeys;
    const { channels, functionalCategory } = applyedFilters.sessionKey;
    if (channels.length > 0) {
      filteredSessioKeys = filteredSessioKeys.filter(
        (sk) => sk.channel && channels.includes(sk.channel)
      );
    }

    if (functionalCategory.length > 0) {
      filteredSessioKeys = filteredSessioKeys.filter(
        (sk) => sk.category && functionalCategory.includes(sk.category)
      );
    }

    return filteredSessioKeys;
  };

  const getSessionKeys = () => {
    const sk =
      (selectedMasterKey?.key_id &&
        sessionKeysMap &&
        sessionKeysMap[selectedMasterKey?.key_id]) ||
      null;

    return sk && filterSessionKey(sk);
  };

  const sessionKeys = getSessionKeys();

  return (
    <Container>
      {(selectedMasterKey && (
        <>
          <MasterKeyInfo>
            <Flex>
              <label style={{ alignContent: "center", marginRight: 10 }}>
                Master Key ID:
              </label>
              <KeyVisualizr flex={1}>{selectedMasterKey?.key_id}</KeyVisualizr>
            </Flex>
            <Flex mt={5} justifyContent={"flex-start"} alignItems={"stretch"}>
              <Label alignContent={"end"} width={"auto"} mr={2}>
                Create session keys
              </Label>
              <FieldContainer>
                <InputField
                  id={"key-creation-quantity"}
                  inputProps={{ type: "number", role: "key-creation-quantity" }}
                  placeholder="n°"
                  onChange={(
                    e:
                      | React.ChangeEvent<HTMLTextAreaElement>
                      | React.ChangeEvent<HTMLInputElement>
                  ) => {
                    return setNum_keys(Number(e.target.value));
                  }}
                />
              </FieldContainer>
              <Button
                data-testid={"derive-session-key"}
                title={"Create new session key"}
                type="button"
                onClick={handleCreateSessionKey}
                size={"small"}
                ml={1}
              >
                <Icon name="Add" size={9} />
              </Button>
            </Flex>
          </MasterKeyInfo>
          <Heading>Derived session keys</Heading>
          <Flex
            justifyContent={"space-between"}
            mb={sessionKeys && sessionKeys?.length > 0 ? 3 : 0}
          >
            <SessionKeyFilter />
          </Flex>

          {(sessionKeys && sessionKeys.length > 0 && (
            <SelectableList
              dataList={sessionKeys}
              onChange={handleSessionKeySelection}
              listItemTemplate={SessionKeyItem}
              scope={`${selectedSatellite.id}-${selectedMasterKey?.key_id}`}
            />
          )) || <NoSelectedKey>No session keys</NoSelectedKey>}
        </>
      )) || <NoSelectedKey>No active master key</NoSelectedKey>}
    </Container>
  );
};
