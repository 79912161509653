import { Flex, Absolute, Button } from "primitives";
import { Select } from "components";
import styled from "styled-components";
import { themeGet } from "styled-system";

export const IconBox = styled(Flex)`
  background-color: ${themeGet("colors.fill.0")};
  height: 30px;
  border: 1px solid ${themeGet("colors.text.default")}99;
  align-items: center;
  justify-content: center;
  border-radius: 2px 0 0 2px;
  overflow: visible !important;
  border-right: none;
  width: 30px;
  padding: 0 4px 0 8px;
`;

export const RefreshButton = styled(Button)`
  background-color: ${themeGet("colors.fill.0")};
  width: 35px;
  height: 30px;
  border: 1px solid ${themeGet("colors.text.default")}99;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 2px 0 0 2px;
  overflow: visible !important;
  padding: 0;
`;

export const ZoomOutButton = styled(Button)`
  background-color: ${themeGet("colors.fill.0")};
  width: 35px;
  height: 30px;
  border: 1px solid ${themeGet("colors.text.default")}99;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 2px 0 0 2px;
  overflow: visible !important;
  padding: 0;
  margin: 0 4px;
`;

export const ZoomInButton = styled(Button)`
  background-color: ${themeGet("colors.fill.0")};
  width: 35px;
  height: 30px;
  border: 1px solid ${themeGet("colors.text.default")}99;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 2px 0 0 2px;
  overflow: visible !important;
  padding: 0;
  margin: 0 4px;
`;

export const NextButton = styled(Button)`
  background-color: ${themeGet("colors.fill.0")};
  width: 35px;
  height: 30px;
  border: 1px solid ${themeGet("colors.text.default")}99;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 2px 0 0 2px;
  overflow: visible !important;
  padding: 0;
  margin-right: 4px;
`;
export const PrevButton = styled(Button)`
  background-color: ${themeGet("colors.fill.0")};
  width: 35px;
  height: 30px;
  border: 1px solid ${themeGet("colors.text.default")}99;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 2px 0 0 2px;
  overflow: visible !important;
  padding: 0;
  margin-right: 4px;
`;

export const SelectStyled = styled(Select)`
  font-size: 14px;
  border: 1px solid ${themeGet("colors.text.default")}99;
  text-transform: none;
  cursor: pointer;
  padding-left: 8px;
  padding-right: 24px;
  height: 30px;
  padding-top: 6px;
  padding-bottom: 6px;
`;

export const Clickable = styled(Absolute)`
  @media (max-width: 767.5px) {
    top: ${(props) => (props.showSideBar ? "311.7px" : "51.3px")};
  }
  cursor: pointer;
  top: 51.3px;
  right: 0;
  border: 1px solid ${themeGet("colors.text.default")}99;
  background-color: ${themeGet("colors.fill.0")};
  border-radius: 2px 0 0 2px;
  border-right: none;
  height: 30px;
  width: 26px;
  justify-content: center;
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${themeGet("colors.palette.blue.4")};
  }
`;

export const FlexStyled = styled(Flex)`
  align-items: flex-end;
  flex-wrap: wrap;
  margin-right: 10px;
  overflow: visible !important;
  justify-content: flex-end;
`;
