import { Palette, TextColors, StatusColors } from "styled-components";

export const palette: Palette = {
  white: ["#FFFFFF", "#FFFFFF0D"],
  black: ["#000000"],
  brand: ["#7AB9DB"],
  blue: ["#5DC8C9", "#8093A7", "#6E8093", "#2E4162", "#293A58", "#34435a"],
  grey: [
    "#F8F8F8",
    "#969696",
    "#707070",
    "#4D4D4D",
    "#27293A",
    "#1F1E2C",
    "#181821",
    "#0A1516",
    "#FFFFFFB3"
  ],
  green: ["#71DA2A", "#88BD40", "#243638"],
  red: ["#EA412F", "#DB4444"],
  orange: ["#FFD267", "#FFCB66", "#FFA010"],
  purple: ["#915CAE", "#834AAE", "#7986CB"],
  custom: [
    "linear-gradient( -90deg, white, white 50%, white 50%, white 50%, #DB4444 50%)",
    "#212232"
  ]
};

const text: TextColors = {
  white: palette.white[0],
  grey: palette.grey[1],
  label: palette.blue[1],
  default: palette.brand[0],
  muted: palette.blue[1],
  link: palette.brand[0],
  danger: palette.red[1],
  warning: palette.orange[1],
  success: palette.green[1]
};

const fill: string[] = [
  palette.blue[3],
  palette.grey[4],
  palette.grey[5],
  palette.grey[6],
  palette.grey[7],
  palette.black[0],
  palette.blue[5]
];

const border: string[] = [
  palette.brand[0],
  palette.grey[3],
  palette.blue[2],
  palette.red[1]
];

const status: StatusColors = {
  focus: palette.brand[0],
  danger: palette.red[1],
  warning: palette.orange[1],
  success: palette.green[1],
  info: palette.brand[0],
  outBonds: palette.purple[1],
  invalid: palette.custom[0]
};

export default { palette, text, fill, border, status };
