import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { SatelliteTMDashboard } from "../components/SatelliteTMDashboard";
import { withRouter } from "react-router-dom";
import { getActiveSatellite } from "app/shared/utils";
import { fetchGroundStationsAction } from "app/groundStation/actions";
import { selectDashboard } from "app/dashboard/actions";
import {
  fetchTelemetryAtInterval,
  cleanUpIntervals
} from "app/dashboard/services/fetchTelemetry";
import { setSatelliteVisibility } from "app/constellation/services";
import { editDashboard, getUserDashboard } from "app/dashboard/services";
import { hasTelemetryWidgets } from "app/dashboard/utils/verify";

const mapStateToProps = (state: any) => ({
  dashboards: state.dashboard.list,
  selectedDashboard: state.dashboard.selected,
  selectedSatellite: getActiveSatellite(state.constellations.dashboard),
  satelliteInstances: state.constellations.selected?.satelliteInstances,
  groundStations: state.groundStations.groundStations,
  timeController: hasTelemetryWidgets(state.dashboard.selected)
    ? state.datastore.timeController
    : null
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchGroundStations: () => dispatch(fetchGroundStationsAction()),
    editDashboard: (id: any, dashboardDefinition: any, idx: any) =>
      editDashboard(id, dashboardDefinition, idx),
    selectDashboard: (id: any) => dispatch(selectDashboard(id)),
    getUserDashboard: (id: string) => getUserDashboard(id),
    setSatelliteVisibility: (id: number) => setSatelliteVisibility(id),
    fetchTelemetryAtInterval: (id: string) =>
      dispatch(fetchTelemetryAtInterval(id)),
    cleanUpIntervals: () => cleanUpIntervals()
  };
};

export const SatelliteTMDashboardContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SatelliteTMDashboard)
);
