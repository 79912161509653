import ace from "ace-builds/src-noconflict/ace";

ace.define(
  "ace/theme/aurora",
  ["require", "exports", "module", "ace/lib/dom"],
  (require: any, exports: any) => {
    exports.isDark = true;
    exports.cssClass = "ace-aurora";
    exports.cssText = `
    .ace-aurora .ace_gutter {
      background: #343a40;
      color: #AAA;
    }
    .ace-aurora {
      background: #27293A;
      color: #7AB9DB;
    }
    .ace-aurora .ace_print-margin {
      width: 1px;
      background: #44475a;
    }
    .ace-aurora .ace_cursor {
      color: #FFFFFF;
    }
    .ace-aurora .ace_marker-layer .ace_selection {
      background: #44475a;
    }
    .ace-aurora .ace_marker-layer .ace_active-line {
      background: rgba(72, 82, 99, 0.2);
    }
    .ace-aurora .ace_gutter-active-line {
      background-color: #50565c;
    }
    .ace-aurora .ace_marker-layer .ace_selected-word {
      border: 1px solid #6E8093;
    }
    .ace-aurora .ace_invisible {
      color: #BFBFBF;
    }
    .ace-aurora .ace_indent-guide {
      background: rgba(255, 255, 255, 0.1);
    }
    .ace-aurora .ace_keyword {
      color: #6E8093;
      font-weight: bold;
    }
    .ace-aurora .ace_constant {
      color: #099;
    }
    .ace-aurora .ace_constant.ace_buildin {
      color: #0086B3;
    }
    .ace-aurora .ace_constant.ace_language {
      color: #C44;
    }
    .ace-aurora .ace_constant.ace_numeric {
      color: #099;
    }
    .ace-aurora .ace_constant.ace_character {
      color: #D14;
    }
    .ace-aurora .ace_constant.ace_other {
      color: #D14;
    }
    .ace-aurora .ace_support {
      color: #0086B3;
    }
    .ace-aurora .ace_support.ace_function {
      color: #0086B3;
    }
    .ace-aurora .ace_support.ace_constant {
      color: #0086B3;
    }
    .ace-aurora .ace_support.ace_class {
      font-style: italic;
      color: #0086B3;
    }
    .ace-aurora .ace_support.ace_type {
      font-style: italic;
      color: #0086B3;
    }
    .ace-aurora .ace_storage {
      color: #6E8093;
    }
    .ace-aurora .ace_storage.ace_type {
      color: #6E8093;
    }
    .ace-aurora .ace_invalid {
      color: #F8F8F0;
      background-color: #F92672;
    }
    .ace-aurora .ace_invalid.ace_deprecated {
      color: #F8F8F0;
      background-color: #AE81FF;
    }
    .ace-aurora .ace_string {
      color: #D14;
    }
    .ace-aurora .ace_string.ace_regexp {
      color: #009926;
    }
    .ace-aurora .ace_comment {
      color: #998;
      font-style: italic;
    }
    .ace-aurora .ace_variable {
      color: #7AB9DB;
    }
    .ace-aurora .ace_variable.ace_parameter {
      font-style: italic;
      color: #FD971F;
    }
    .ace-aurora .ace_entity.ace_other.ace_attribute-name {
      color: #7AB9DB;
    }
    .ace-aurora .ace_entity.ace_name.ace_function {
      color: #0086B3;
    }
    .ace-aurora .ace_entity.ace_name.ace_tag {
      color: #F92672;
    }
    .ace-aurora .ace_meta {
      color: #C44;  /* Colore per meta informazioni */
    }
    .ace-aurora .ace_paren {
      font-weight: bold;
    }
    .ace-aurora .ace_boolean {
      font-weight: bold;
    }
    .ace-aurora .ace_fold {
      background-color: #7AB9DB;
      border-color: #7AB9DB;
    }
    .ace-aurora .ace_marker-layer .ace_step {
      background: #fcff00;
    }
    .ace-aurora .ace_marker-layer .ace_stack {
      background: #a4e565;
    }
    .ace-aurora .ace_marker-layer .ace_breakpoint {
      background-color: #ff6347;
      color: #000;
    }
    .ace-aurora .ace_fold-widget {
      color: #AAA;
    }
    .ace-aurora .ace_fold-widget.ace_end {
      background-color: #FFAA00;
    }
    .ace-aurora .ace_whitespace {
      color: #BFBFBF;
    }
    .ace-aurora .ace_keyword.ace_operator {
      color: #F92672;
    }
    `;

    const dom = require("ace/lib/dom");
    dom.importCssString(exports.cssText, exports.cssClass);
  }
);

(() => {
  ace.require(["ace/theme/aurora"], (m: any) => {
    if (typeof module === "object" && typeof exports === "object" && module) {
      module.exports = m;
    }
  });
})();
