export const createToolTip = (engine: any, id: number, styles?: any) => {
  // clean up previous tooltip tips
  engine.selectAll(`#tooltip-${id}`).remove();
  const selector = `tooltip-${id}`;
  let tooltipStyles: any = {
    position: "absolute",
    opacity: 0,
    "background-color": "#353447",
    padding: "4px",
    color: "white",
    "z-index": "99999",
    left: -10,
    top: -20
  };

  tooltipStyles = styles ?? tooltipStyles;
  let tooltip: any = engine.select("body").select(selector);
  if (tooltip.empty()) {
    tooltip = engine
      .select("body")
      .append("div")
      .attr("id", selector)
      .attr("class", "tooltip");
    for (const s in tooltipStyles) {
      if (typeof s === "string") {
        tooltip.style(s, tooltipStyles[s]);
      }
    }
  } else {
    tooltip = engine.select("body").select(selector);
  }

  const tooltipContent = (ds: any) => {
    const reading = ds.readings[0]?.value[0]?.value;
    const receivedUTC = ds.readings[0].receiveTimestamp;
    const valueFormatted = `
          <p> <strong>${reading}</strong></p>
           satelliteUTC: <span>${receivedUTC}</span> 
          `;
    return valueFormatted;
  };

  // Event handlers
  const onMouseEnter = (e: any, ds: any) => {
    return tooltip.html(tooltipContent(ds)).style("opacity", 1);
  };
  const onMouseLeave = (e: any, ds: any) => {
    return tooltip.style("opacity", 0);
  };
  const onMouseMove = (e: any, ds: any) => {
    return tooltip
      .style("top", `${e.pageY - tooltipStyles.top}px`)
      .style("left", `${e.pageX - tooltipStyles.left}px`);
  };

  return {
    tooltip,
    onMouseEnter,
    onMouseLeave,
    onMouseMove
  };
};
