import React from "react";
import { AddButton, DeleteButton } from "components";
import { Flex, Box } from "primitives";

export const ArrayFieldTemplate = ({
  canAdd,
  disabled,
  items,
  onAddClick,
  readonly,
  schema
}) => {
  const { hasRemove } = schema;

  return (
    <Box m="2px">
      {items.map((element) => (
        <Flex key={element.index} mb="3px">
          <Box width="100%" pr="10px">
            {element.children}
          </Box>
          {hasRemove && (
            <div>
              <DeleteButton
                onClick={element.onDropIndexClick(element.index)}
                disabled={disabled || readonly}
              >
                Remove
              </DeleteButton>
            </div>
          )}
        </Flex>
      ))}
      <Flex justifyContent="flex-end">
        {canAdd && (
          <AddButton onClick={onAddClick} disabled={disabled || readonly}>
            Add
          </AddButton>
        )}
      </Flex>
    </Box>
  );
};
