import React, { useEffect } from "react";
import { Icon, Flex } from "primitives";
import { SelectContainer } from "components";
import { useSelector, useDispatch } from "react-redux";
import { DateTimeFilter } from "app/telemetry/visualizations";
import {
  WindowSizes,
  TimeRanges,
  TimeReferences,
  TimeControllerActionTypes,
  State,
  FetchingStatus,
  TelemetryComponents,
  DashboardComponent,
  TimeControllerProps
} from "../models/index";
import { fetchTelemetryAtInterval } from "app/dashboard/services/fetchTelemetry";
import { SatelliteInstance } from "app/satellite/models";
import {
  SelectStyled,
  IconBox,
  RefreshButton,
  ZoomOutButton,
  ZoomInButton,
  NextButton,
  PrevButton,
  Clickable,
  FlexStyled
} from "./styledComponents/index";

import {
  setQuickRange,
  setFromTo,
  resetTimeController,
  setNextRange,
  setPreviousRange,
  zoomOut,
  zoomIn
} from "../actions";
import { hasTelemetryWidgets } from "app/dashboard/utils/verify";
import { Dashboard } from "app/dashboard/models";

const {
  HIDE_TIME_CONTROLLER,
  UNHIDE_TIME_CONTROLLER,
  SET_REFRESH_INTERVAL,
  SET_TIME_REFERENCE
} = TimeControllerActionTypes;

export const TimeController = ({ dashboard }: TimeControllerProps) => {
  const showSideBar = JSON.parse(localStorage.getItem("showSideBar") || "");
  const {
    refreshInterval,
    timeReference,
    quickRange,
    showTimeController,
    from,
    to
  } = useSelector((state: State) => state.datastore.timeController);

  const fetchingStatus = useSelector(
    (state: any) => state.datastore.fetchStatus
  );
  const satellite = useSelector((state: State) =>
    state.constellations.dashboard.find((sat: SatelliteInstance) => sat.visible)
  );
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetTimeController());
    };
  }, []);

  if (dashboard && !hasTelemetryWidgets(dashboard as Dashboard)) {
    return null;
  }

  const defaultValues = {
    from: new Date(from),
    to: quickRange ? "now" : new Date(to)
  };

  return (
    <FlexStyled id="timeController" data-testid="timeController">
      {showTimeController && (
        <>
          <SelectContainer borderRadius="2px" mr={2} mb={2}>
            <SelectStyled
              id="timeReferenceSelector"
              borderL={0}
              value={timeReference}
              onChange={(e: { target: { value: string } }) => {
                dispatch({
                  type: SET_TIME_REFERENCE,
                  payload: e.target.value
                });
              }}
            >
              {Object.entries(TimeReferences).map((key, ind) => (
                <option key={ind} value={key[0]}>
                  {key[1]}
                </option>
              ))}
            </SelectStyled>
          </SelectContainer>
          <PrevButton
            id="prev-range"
            data-testid="prev-range"
            mb={2}
            title="prev-range"
            onClick={() => dispatch(setPreviousRange())}
            overflow="visible"
          >
            <Icon name="ArrowLeft" size={12} dataTestId="prev-range" />
          </PrevButton>
          <DateTimeFilter
            margin="2px 4px"
            paddingTop={"5px"}
            borderBottom="none"
            mb="8px"
            box
            noLabel
            defaultValues={defaultValues}
            timeFilterChanged={(from, to) => {
              dispatch(
                setFromTo({
                  from: new Date(from).valueOf(),
                  to: new Date(to).valueOf()
                })
              );
            }}
          />
          <NextButton
            id="next-range"
            data-testid="next-range"
            mb={2}
            title="next time range"
            onClick={() => dispatch(setNextRange())}
            overflow="visible"
          >
            <Icon name="ArrowRight" size={12} dataTestId="next-range" />
          </NextButton>
          <SelectContainer borderRadius="2px" mr={0} mb={2}>
            <IconBox>
              <Icon
                data-testid="toggle-time-controller"
                size={18}
                name="Clock"
              />
            </IconBox>
            <SelectStyled
              id="timeRangeSelector"
              pl="0 !important"
              borderL={0}
              value={quickRange as string}
              borderLeft="none !important"
              onChange={(e: { target: { value: string } }) => {
                if (e.target.value !== " ") {
                  dispatch(setQuickRange(e.target.value));
                }
              }}
            >
              <option value=" "></option>
              {Object.entries(TimeRanges).map((key, ind) => (
                <option key={ind} value={key[0]}>
                  {key[1]}
                </option>
              ))}
            </SelectStyled>
          </SelectContainer>
          <Flex>
            <ZoomInButton
              id="zoomout"
              data-testid="zoomout"
              mb={2}
              ml={2}
              title="zoom in"
              onClick={() => dispatch(zoomIn())}
              overflow="visible"
            >
              <Icon name="ZoomIn" dataTestId="zoom-in" />
            </ZoomInButton>
            <ZoomOutButton
              id="zoomout"
              data-testid="zoomout"
              mb={2}
              title="zoom out"
              onClick={() => dispatch(zoomOut())}
              overflow="visible"
            >
              <Icon name="ZoomOut" dataTestId="zoom-out" />
            </ZoomOutButton>
            <RefreshButton
              id="refreshDashboard"
              data-testid="refreshDashboard"
              mb={2}
              title="Refresh dashboard"
              onClick={() => {
                if (satellite) {
                  dispatch(fetchTelemetryAtInterval(satellite.id.toString()));
                }
              }}
              overflow="visible"
            >
              <Icon
                size={16}
                name="Refresh"
                animate={
                  fetchingStatus === FetchingStatus.fetching ? "spin" : "static"
                }
                dataTestId="time-controller-spinner"
              />
            </RefreshButton>
            <SelectContainer
              borderRadius="1px 2px 2px 1px"
              mb={2}
              mr={1}
              width={"45%"}
            >
              <SelectStyled
                id="refreshIntervalSelector"
                borderL={0}
                borderLeft="none !important"
                value={refreshInterval}
                onChange={(e: { target: { value: string } }) => {
                  dispatch({
                    type: SET_REFRESH_INTERVAL,
                    payload: e.target.value
                  });
                }}
              >
                <option value=" "></option>
                {Object.entries(WindowSizes).map((key, ind) => (
                  <option key={ind} value={key[0]}>
                    {key[1]}
                  </option>
                ))}
              </SelectStyled>
            </SelectContainer>
          </Flex>
        </>
      )}
      <Clickable
        id="toggle-time-controller"
        data-testid="toggle-time-controller"
        pb={showTimeController ? "6px" : 0}
        onClick={() => {
          showTimeController
            ? dispatch({
                type: HIDE_TIME_CONTROLLER
              })
            : dispatch({
                type: UNHIDE_TIME_CONTROLLER
              });
        }}
        showSideBar={showSideBar}
      >
        <Icon name={showTimeController ? "Hide" : "Clock"} size={18}></Icon>
      </Clickable>
    </FlexStyled>
  );
};
