import { connect } from "react-redux";
import { ConstellationCreate } from "../components/ConstellationCreate";
import {
  createConstellation,
  selectConstellation,
  getConstellations
} from "app/constellation";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state) => ({
  list: state.constellations.list
});

const mapDispatchToProps = () => {
  return {
    createConstellation: (data) => createConstellation(data),
    selectConstellation: (data) => selectConstellation(data),
    getConstellations: () => getConstellations()
  };
};

export const ConstellationCreateContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConstellationCreate)
);
