import React, { Component } from "react";
import { connect } from "react-redux";
import SortableTree, { changeNodeAtPath, map, find } from "react-sortable-tree";
import styled from "styled-components";
import NodeConstellationRenderer from "app/shared/tree/nodeConstellationRenderer";
import { Box, Flex, Icon, Text } from "primitives";
import { CheckBox } from "components";
import { getSatelliteDefinition } from "app/satelliteDefinition/services";
import { SatelliteDefinitionSystem } from "app/satelliteDefinition/models";
import { SatelliteInstance } from "app/satellite/models";
import { AlertDTO, AlertValueStatus, Severity, Colors } from "app/alert/models";
import { DataSource } from "app/dataSource/models";
import { TreeData } from "../models/index";

const Tree = styled(SortableTree)`
  .rst__tree::after {
    max-width: "100%";
  }

  .rst__nodeContent {
    max-width: "100%";
  }

  .rst__lineHalfHorizontalRight::before {
    top: 13px;
  }

  .rst__lineFullVertical::after,
  .rst__lineHalfVerticalTop::after,
  .rst__lineHalfVerticalBottom::after {
    width: 1px;
    left: 50%;
  }

  .rst__node .rst__lineBlock:last-child {
    display: none;
  }
`;

const NodeHeaderAction = styled(Flex)``;
NodeHeaderAction.defaultProps = {
  flex: "1 0 auto",
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "center",
  px: 2
};

const NodeHeader = styled(Box)``;
NodeHeader.defaultProps = {
  position: "relative",
  top: 0,
  width: "500px",
  ml: "44px",
  p: 2,
  bg: "palette.brand.0",
  bgOpacity: 3,
  border: 1,
  borderRadius: "2px",
  color: "text.default",
  fontSize: 2
};

const AlertIndicatorLight = styled(Box)``;
AlertIndicatorLight.defaultProps = {
  size: 8,
  borderRadius: "50%",
  ml: 1
};

interface DataSourceFilterTreeProps {
  satellite: SatelliteInstance;
  alertsDataSourceList: DataSource[];
  selectedDataSources: number[] | null;
  expandedDataSource: number | null;
  alertsByDataSource: AlertDTO[];
  toggleDataSource: (dataSourceId: number) => any;
  getSatelliteDefinition: (
    satelliteDefinitionId: number
  ) => Promise<SatelliteDefinitionSystem>;
}

interface DataSourceFilterTreeState {
  treeData: any;
  satelliteDefinition: SatelliteDefinitionSystem | null;
  contentExpandedList: number[];
}

class DataSourceFilterTreeBase extends Component<
  DataSourceFilterTreeProps,
  DataSourceFilterTreeState
> {
  state = {
    treeData: [],
    contentExpandedList: [],
    satelliteDefinition: null
  };
  private listRef: any = null;

  async componentWillUpdate(nextProps: DataSourceFilterTreeProps) {
    if (
      nextProps.satellite &&
      (!this.state.satelliteDefinition ||
        nextProps.satellite.id !== this.props.satellite.id)
    ) {
      const satelliteDefinition: SatelliteDefinitionSystem =
        await this.props.getSatelliteDefinition(
          nextProps.satellite.satelliteDefinitionSummary.satelliteDefinitionId
        );
      this.setState({ satelliteDefinition }, () => this.buildTree());
    }
  }

  componentDidUpdate(prevProps: DataSourceFilterTreeProps) {
    const { expandedDataSource } = this.props;
    if (
      expandedDataSource &&
      expandedDataSource !== prevProps.expandedDataSource
    ) {
      this.expandDataSource(expandedDataSource);
    }
  }

  expandDataSource(dataSourceId: number) {
    const { treeData } = this.state;
    const findResult: any = find({
      treeData,
      getNodeKey: ({ treeIndex }) => treeIndex,
      searchMethod: ({ node, path, treeIndex, searchQuery }) => {
        return (
          node.record &&
          node.record.dataSources &&
          node.record.dataSources.find(
            (dataSource: DataSource) => dataSource.id === dataSourceId
          )
        );
      },
      searchQuery: dataSourceId,
      expandAllMatchPaths: true
    });
    if (findResult && findResult.matches) {
      this.setState({ treeData: findResult.treeData }, () => {
        findResult.matches.forEach((match: any) => {
          this.expandNodeContent(match.node, match.path, this.getNodeKey);
        });
      });
    }
  }

  refreshTree() {
    const { treeData } = this.state;
    const updatedTreeData = map({
      treeData,
      callback: ({ node }: any) => {
        const system = node.record;
        return {
          ...node,
          alerts: this.getAlertsFromSystem(system, node.sat && node.sat.id)
        };
      },
      getNodeKey: ({ treeIndex }) => treeIndex,
      ignoreCollapsed: true
    });
    this.onChange(updatedTreeData);
  }

  getAlertsFromSystem(system: SatelliteDefinitionSystem, satelliteId: number) {
    if (!satelliteId) return [];
    const alerts = this.props.alertsByDataSource;
    const dataSourceIds = this.getDataSourcesFromSystemAndChild(system).map(
      (dataSource: DataSource) => dataSource.id
    );
    return (
      alerts &&
      alerts.filter((alert: AlertDTO) =>
        dataSourceIds.includes(alert.dataSourceId)
      )
    );
  }

  getDataSourcesFromSystemAndChild(system: SatelliteDefinitionSystem) {
    const systems = (system && system.systems) || [];
    const initial = system ? [...system.dataSources] : [];

    return systems.reduce((acc: any, cur: any) => {
      acc.push(...this.getDataSourcesFromSystemAndChild(cur));
      return acc;
    }, initial);
  }

  buildTree() {
    const { satelliteDefinition } = this.state;
    const { satellite } = this.props;
    const treeData: TreeData[] = [];
    if (satelliteDefinition !== null) {
      const tree = this.getTree(satelliteDefinition["systems"], satellite, 0);
      treeData.push({
        id: satelliteDefinition["id"],
        title: satellite["label"],
        expanded: true,
        children: tree,
        contentExpanded: false,
        header: true
      });
      this.setState({ treeData });
    }
  }

  getNodeKey = ({ treeIndex }: { treeIndex: number }) => treeIndex;

  getTree(
    systems: SatelliteDefinitionSystem[],
    satellite: SatelliteInstance,
    parentDefinitionId: number
  ): any {
    if (!systems) return [];
    const { contentExpandedList }: { contentExpandedList: number[] } =
      this.state;
    const { alertsDataSourceList } = this.props;
    const hasAlerts = (system: SatelliteDefinitionSystem) => {
      let hasAlertsResult = false;
      if (system.dataSources) {
        system.dataSources.forEach((dataSource: DataSource) => {
          const hasDataSourceWithAlert: DataSource | undefined =
            alertsDataSourceList.find((ds) => ds.id === dataSource.id);
          if (hasDataSourceWithAlert) {
            hasAlertsResult = true;
          }
        });
      }
      if (system.systems) {
        system.systems.forEach((systemIt: SatelliteDefinitionSystem) => {
          if (hasAlerts(systemIt)) {
            hasAlertsResult = true;
          }
        });
      }
      return hasAlertsResult;
    };
    const tree: any = [];
    systems.forEach((system: SatelliteDefinitionSystem) => {
      if (hasAlerts(system)) {
        const node: any = {
          id: system.id,
          title: system.name,
          expanded: false,
          contentExpanded: contentExpandedList.includes(system.id),
          record: { parentDefinitionId, ...system },
          children: this.getTree(system.systems, satellite, system.id),
          alerts: this.getAlertsFromSystem(system, satellite.id),
          sat: satellite
        };
        tree.push(node);
      }
    });
    return tree;
  }

  onChange(newData: any) {
    this.setState({ treeData: newData });
  }

  expandNodeContent(
    node: any,
    path: number[],
    getNodeKey: ({ treeIndex }: { treeIndex: number }) => number
  ) {
    const { treeData } = this.state;
    const updatedNode = {
      ...node,
      contentExpanded: !node.contentExpanded
    };
    const updatedTreeData = changeNodeAtPath({
      treeData,
      path,
      getNodeKey,
      newNode: updatedNode
    });

    const { contentExpandedList }: { contentExpandedList: any } = this.state;

    if (updatedNode.contentExpanded) {
      contentExpandedList.push(updatedNode.id);
    } else {
      const index = contentExpandedList.indexOf(updatedNode.id);
      if (index > -1) {
        contentExpandedList.splice(index, 1);
      }
    }

    this.setState({ contentExpandedList });

    this.onChange(updatedTreeData);

    if (this.listRef) {
      this.recomputeRowHeights();
    }
  }

  recomputeRowHeights() {
    if (this.listRef) {
      this.listRef.wrappedInstance.current.recomputeRowHeights();
    }
  }

  toggleExpandAll(expanded: boolean) {
    const { treeData }: { treeData: any } = this.state;

    if (!expanded) {
      this.setState({ contentExpandedList: [] });
    }

    const updatedTreeData = map({
      treeData,
      callback: ({ node }: any) => {
        return {
          ...node,
          expanded: node.header ? true : expanded,
          contentExpanded: node.header ? false : expanded
        };
      },
      getNodeKey: ({ treeIndex }) => treeIndex,
      ignoreCollapsed: false
    });

    this.onChange(updatedTreeData);

    if (this.listRef) {
      this.recomputeRowHeights();
    }
  }

  renderRow({ node }: any) {
    // Method needed to update the rowHeight props from SortableTree
    const defaultRowHeight = node.header ? 32 : 24;

    if (node.record && node.record.dataSources.length > 0) {
      const { alertsDataSourceList } = this.props;
      let numberOfDataSourcesWithAlerts = 0;
      node.record.dataSources.forEach((dataSource: DataSource) => {
        if (
          alertsDataSourceList.find(
            (alertDataSource: DataSource) =>
              alertDataSource.id === dataSource.id
          )
        ) {
          numberOfDataSourcesWithAlerts += 1;
        }
      });
      return (
        defaultRowHeight +
        (node.contentExpanded ? numberOfDataSourcesWithAlerts * 30 : 0)
      );
    }
    return defaultRowHeight;
  }

  getAlertSeverities(alerts: AlertDTO[]) {
    const alertSeverities: string[] = [];

    alerts.forEach((alert: AlertDTO) => {
      if (alert.value) {
        alert.value.forEach((value) => {
          if (
            value.alert.type === AlertValueStatus.AlertValue &&
            value.alert.severity
          ) {
            alertSeverities.push(value.alert.severity);
          } else if (value.alert.type === AlertValueStatus.OutOfBoundsValue) {
            alertSeverities.push(AlertValueStatus.OutOfBoundsValue);
          }
        });
      }
    });

    return alertSeverities;
  }

  renderAlertsSystem(node: any) {
    const { alerts } = node;
    if (!alerts) return null;

    const alertSeverities: string[] = this.getAlertSeverities(alerts);

    return (
      <Flex>
        {alertSeverities.includes(Severity.Normal) ? (
          <AlertIndicatorLight bg={Colors[Severity.Normal]} />
        ) : null}
        {alertSeverities.includes(Severity.Warning) ? (
          <AlertIndicatorLight bg={Colors[Severity.Warning]} />
        ) : null}
        {alertSeverities.includes(Severity.Critical) ? (
          <AlertIndicatorLight bg={Colors[Severity.Critical]} />
        ) : null}
        {alertSeverities.includes(AlertValueStatus.OutOfBoundsValue) ? (
          <AlertIndicatorLight bg={Colors.OutOfBounds} />
        ) : null}
      </Flex>
    );
  }

  renderAlertsDataSource(node: any, dataSourceId: number) {
    const { alerts } = node;
    if (!alerts) return null;

    const alert: AlertDTO = node.alerts.find(
      (alertAux: AlertDTO) => alertAux.dataSourceId === dataSourceId
    );
    if (alert) {
      const alertSeverities: string[] = this.getAlertSeverities([alert]);

      return (
        <Flex>
          {alertSeverities.includes(Severity.Normal) ? (
            <AlertIndicatorLight bg={Colors[Severity.Normal]} />
          ) : null}
          {alertSeverities.includes(Severity.Warning) ? (
            <AlertIndicatorLight bg={Colors[Severity.Warning]} />
          ) : null}
          {alertSeverities.includes(Severity.Critical) ? (
            <AlertIndicatorLight bg={Colors[Severity.Critical]} />
          ) : null}
          {alertSeverities.includes(AlertValueStatus.OutOfBoundsValue) ? (
            <AlertIndicatorLight bg={Colors.OutOfBounds} />
          ) : null}
        </Flex>
      );
    }
  }

  render() {
    const { treeData } = this.state;
    const { alertsDataSourceList, selectedDataSources, toggleDataSource } =
      this.props;

    return (
      <Box height="100%">
        <NodeHeader>
          <NodeHeaderAction onClick={() => this.toggleExpandAll(false)}>
            <Icon name="ArrowUp" size={10} />
            <Text ml={1}>collapse all</Text>
          </NodeHeaderAction>
        </NodeHeader>
        <Tree
          reactVirtualizedListProps={{
            ref: (ref: any) => (this.listRef = ref)
          }}
          treeData={treeData}
          nodeContentRenderer={NodeConstellationRenderer as any}
          isVirtualized={true}
          rowHeight={this.renderRow.bind(this)}
          generateNodeProps={({ node, path, ...rest }: any) => {
            const dataSourcesIds = node.record
              ? node.record.dataSources.map(
                  (dataSource: DataSource) => dataSource.id
                )
              : null;

            return {
              body: (
                <Flex
                  alignItems="stretch"
                  justifyContent="space-between"
                  flex="1 0 100%"
                  height="100%"
                >
                  <Flex alignItems="center">
                    <Box px={1}>{node.title}</Box>
                    <Box>{this.renderAlertsSystem(node)}</Box>
                  </Flex>
                </Flex>
              ),
              dataSources:
                node.contentExpanded &&
                dataSourcesIds &&
                dataSourcesIds.map((dataSourceId: number) => {
                  const ds = alertsDataSourceList.find(
                    (datasource) => datasource.id === dataSourceId
                  );
                  if (ds) {
                    return (
                      <Flex key={dataSourceId}>
                        <CheckBox
                          checked={
                            selectedDataSources !== null &&
                            selectedDataSources.includes(dataSourceId)
                          }
                          label={ds.name}
                          onChange={() => toggleDataSource(dataSourceId)}
                        />
                        <Flex
                          flex={3}
                          alignItems={"center"}
                          justifyContent={"flex-start"}
                        >
                          {this.renderAlertsDataSource(node, dataSourceId)}
                        </Flex>
                      </Flex>
                    );
                  }
                  return null;
                }),
              expandNodeContent: () =>
                this.expandNodeContent(node, path, this.getNodeKey),
              variableHeight: true
            };
          }}
          onChange={(newData: any) => this.onChange(newData)}
        />
      </Box>
    );
  }
}

const mapDispatchToProps = () => {
  return {
    getSatelliteDefinition: (id: number) => getSatelliteDefinition(id)
  };
};

export const DataSourceFilterTree = connect(
  null,
  mapDispatchToProps
)(DataSourceFilterTreeBase);
