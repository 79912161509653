export interface DataProviderResponse {
  data?: any;
  nextPageUrl?: string;
  previousPageUrl?: string;
  total?: number;
}

export interface DataProviderError {
  status: string;
  headers: any;
  body: any;
  name: string;
}

export const HttpErrorStatus: {
  [key: string]: string;
} = {
  400: "Bad Request",
  401: "Unauthorized",
  403: "Forbidden",
  404: "Not Found",
  500: "Server Error"
};
