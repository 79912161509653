export const FETCH_CONSTELLATIONS_START = "FETCH_CONSTELLATIONS_START";
export const FETCH_CONSTELLATIONS_SUCCESS = "FETCH_CONSTELLATIONS_SUCCESS";
export const FETCH_CONSTELLATIONS_ERROR = "FETCH_CONSTELLATIONS_ERROR";

export const FETCH_CONSTELLATION_START = "FETCH_CONSTELLATION_START";
export const FETCH_CONSTELLATION_SUCCESS = "FETCH_CONSTELLATION_SUCCESS";
export const FETCH_CONSTELLATION_ERROR = "FETCH_CONSTELLATION_ERROR";
export const CLEAR_CONSTELLATION = "CLEAR_CONSTELLATION";

export const SELECT_CONSTELLATION = "SELECT_CONSTELLATION";

export const ADD_SATELLITE = "ADD_SATELLITE";
export const ADD_SATELLITES = "ADD_SATELLITES";
export const REMOVE_SATELLITE = "REMOVE_SATELLITE";
export const CLEAN_SATELLITE = "REMOVE_SATELLITE";
export const SET_SATELLITE_VISIBILITY = "SET_SATELLITE_VISIBILITY";
