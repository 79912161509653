import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const ArrowSelect = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    viewBox="0 0 42 42"
    width={size || width}
    height={size || height}
    color={color}
    fill="#7AB9DB"
  >
    <path d="M7 10l5 5 5-5z" />
  </Svg>
);

ArrowSelect.defaultProps = {
  width: "24",
  height: "24"
};
export default ArrowSelect;
