import { TelecommandStackActionTypes, TelecommandStackState } from "../models";
import produce from "immer";

const initialState: TelecommandStackState = {
  telecommandStacks: [],
  selectedStack: null,
  selectedTC: null,
  refetchTC: null
};

const telecommandStackReducer = (state = initialState, action: any) =>
  produce(state, (draft: any) => {
    const stack = getStack(action, draft);
    switch (action.type) {
      case TelecommandStackActionTypes.AddToStack:
        if (isEditCmd(state)) {
          draft.selectedTC = null;
          stack.telecommandList.splice(state.selectedTC.index, 1, {
            ...action.payload,
            nonDefaultParams: action.nonDefaultParams,
            id: state.selectedTC.telecommand.id
          });
          break;
        }
        stack.telecommandList.push({
          telecommand: action.payload.telecommand,
          passageId: action.payload.passageId,
          nonDefaultParams: action.nonDefaultParams,
          id: new Date().getTime().toString()
        });
        stack.telecommandList = stack.telecommandList.map((tc: any) => ({
          ...tc,
          status: ""
        }));
        break;

      case TelecommandStackActionTypes.ResetTCStatus:
        stack.status = "";
        stack.telecommandList = action.selectedStack.telecommandList.map(
          (tc: any) => ({
            ...tc,
            status: ""
          })
        );
        break;

      case TelecommandStackActionTypes.UpdateTCStatus:
        stack.telecommandList.filter((tc: any) => {
          if (tc.id === action.telecommandId) {
            tc.status = action.payload;
          }
          return tc;
        });
        break;

      case TelecommandStackActionTypes.UpdateStackStatus:
        stack.status = action.payload;
        break;

      case TelecommandStackActionTypes.UpdateStack:
        draft.telecommandStacks[
          draft.telecommandStacks.findIndex(
            (tc: any) => tc.id === action.stackId
          )
        ] = action.payload;
        break;

      case TelecommandStackActionTypes.RemoveFromStack:
        stack.telecommandList = stack.telecommandList.filter(
          (i: any) => i.id !== action.payload.id
        );
        break;

      case TelecommandStackActionTypes.copyTelecommand:
        stack.telecommandList.push({
          telecommand: action.payload.telecommand,
          passageId: action.payload.passageId,
          nonDefaultParams: action.nonDefaultParams,
          id: new Date().getTime().toString()
        });
        break;

      case TelecommandStackActionTypes.ReOrder:
        stack.telecommandList = action.selectedStack.telecommandList;
        break;

      case TelecommandStackActionTypes.GetTelecommandStacks:
        draft.telecommandStacks = action.payload;
        break;

      case TelecommandStackActionTypes.SetSelectedStack:
        draft.selectedStack = draft.telecommandStacks.find(
          (tc: any) => tc.id === action.payload
        );
        break;

      case TelecommandStackActionTypes.SetSelectedTC:
        draft.selectedTC = action.payload;
        break;

      case TelecommandStackActionTypes.RefetchTC:
        draft.refetchTC = action.payload;
        break;

      case TelecommandStackActionTypes.AddComment:
        if (action.edit) {
          const index = stack.telecommandList.findIndex(
            (i: any) => i.id === action.id
          );
          stack.telecommandList.splice(index, 1, {
            // telecommand: action.payload,
            comment: action.payload,
            id: action.id
          });
          break;
        }
        stack.telecommandList.push({
          // telecommand: action.payload,
          id: new Date().getTime().toString(),
          comment: action.payload
        });
        break;

      default:
        return state;
    }
  });

export default telecommandStackReducer;

function isEditCmd(state: any) {
  if (state.selectedTC && state.selectedTC.index !== null) {
    return true;
  }
  return false;
}

function getStack(action: any, draft: any) {
  if (action.selectedStack && action.selectedStack.id) {
    return draft.telecommandStacks.find(
      (tc: any) => tc.id === action.selectedStack.id
    );
  }
  return (
    draft.telecommandStacks &&
    draft.telecommandStacks.find((tc: any) => tc.id === action.stackId)
  );
}
