import React, { useState, useEffect } from "react";
import { CustomMarker } from "react-calendar-timeline";
import config from "config/constants";
import moment from "moment";

const markerStyles = {
  zIndex: 100,
  backgroundColor: "#ddff00"
};

const getDateAtUTCOffset = (date: string): number => {
  const localDate = moment(new Date(date));

  const UTCOffset =
    (Math.sign(localDate.utcOffset()) === -1 && localDate.utcOffset()) ||
    -localDate.utcOffset();
  const dateAtUTCOffset = moment(new Date(date))
    .add(UTCOffset, "minutes")
    .valueOf();

  return dateAtUTCOffset;
};

export const TimeMarker = () => {
  const [timeMarkerDate, setTimeMarkerDate] = useState(
    getDateAtUTCOffset(new Date().toUTCString())
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeMarkerDate(getDateAtUTCOffset(new Date().toUTCString()));
    }, config.timer.passages);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <CustomMarker date={timeMarkerDate}>
      {({ styles }) => (
        <div
          data-testid="time-marker"
          style={{
            ...styles,
            ...markerStyles
          }}
        />
      )}
    </CustomMarker>
  );
};
