import React from "react";
import { SvgProps } from "primitives/Svg";

const SvgFile = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    stroke={"#fff"}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <g id="surface1">
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "#fff",
          fillOpacity: 1
        }}
        d="M 21.042969 5.542969 L 13.445312 5.542969 C 13.136719 5.542969 12.835938 5.417969 12.628906 5.203125 L 10.257812 2.703125 C 9.691406 2.136719 8.910156 1.839844 8.121094 1.84375 L 2.957031 1.84375 C 1.328125 1.84375 0 3.171875 0 4.800781 L 0 19.199219 C 0 20.828125 1.328125 22.15625 2.957031 22.15625 L 21.054688 22.15625 C 22.6875 22.148438 24.007812 20.816406 24 19.1875 L 24 8.5 C 24 6.871094 22.671875 5.542969 21.042969 5.542969 Z M 22.191406 19.191406 C 22.195312 19.824219 21.679688 20.34375 21.050781 20.347656 L 2.957031 20.347656 C 2.324219 20.347656 1.808594 19.832031 1.808594 19.199219 L 1.808594 4.800781 C 1.808594 4.167969 2.324219 3.652344 2.957031 3.652344 L 8.144531 3.652344 C 8.152344 3.652344 8.164062 3.652344 8.175781 3.652344 C 8.476562 3.652344 8.769531 3.773438 8.964844 3.96875 L 11.324219 6.453125 C 11.875 7.023438 12.652344 7.351562 13.445312 7.351562 L 21.042969 7.351562 C 21.675781 7.351562 22.191406 7.867188 22.191406 8.5 Z M 22.191406 19.191406 "
      />
    </g>
  </svg>
);

SvgFile.defaultProps = {
  width: "16",
  height: "16"
};
export default SvgFile;
