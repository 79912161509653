import React, { useContext, MouseEvent } from "react";
import { Flex } from "primitives";
import {
  TimelineCommandsProps,
  CalendarTimeline,
  TimelineTypes,
  ContextActionsNames,
  ITimeline
} from "app/planningSystem/models";
import {
  SelectSimple as Select,
  SaveButton,
  UndoButton,
  TooltipMenu,
  PointerButton,
  ResetButton,
  ApproveButton
} from "components";
import { PlanningSystemContext } from "app/planningSystem/context";
import {
  executeOverlapsToTrack,
  reverseCalendarTimeline
} from "app/planningSystem/utils/helpers";
import { store } from "app/store";
import {
  approveTimelineAction,
  updateProposedTimelineAction
} from "app/planningSystem/actions";
import { setFeedback } from "app/feedback/actions";
import { FeedbackStatus } from "app/feedback/models";
import { useSelector } from "react-redux";

export const TimelineCommands = ({
  availableSatellites,
  calendarTimeline,
  handleTimelineSelection,
  changeButtonsDisabled,
  handleUndoTimelineChanges,
  handleResetTimelinePosition,
  timelineItems,
  handleOverlaps
}: TimelineCommandsProps) => {
  const {
    dispatch,
    state: { selectedTimeline: selectedCalendarTimeline, selectedSatellites }
  } = useContext(PlanningSystemContext);

  const selectedTimeline: ITimeline = useSelector(
    (s: any) =>
      s.planningSystem.timelines?.timelines.find(
        (t: ITimeline) => t.uuid === selectedCalendarTimeline?.uuid
      )
  );

  const getMasterTimelineCommands = () => {
    return (
      <>
        <ResetButton
          mr={2}
          title={"Disapprove master timeline"}
          onClick={handleDisapprove}
        />
      </>
    );
  };

  const getOtherTimelineCommands = () => {
    return (
      <>
        <ApproveButton
          title={"Approve timeline"}
          mr={2}
          onClick={handleOnApprove}
        />
        <SaveButton
          title={"Save timeline"}
          mr={2}
          disabled={changeButtonsDisabled}
          onClick={handleOnSave}
        />
        <UndoButton
          title={"Undo changes"}
          mr={2}
          disabled={changeButtonsDisabled}
          onClick={handleUndoTimelineChanges}
        />
      </>
    );
  };

  const setSelectedSatellites = (selectedSat: string[]) =>
    dispatch({
      type: ContextActionsNames.selectedSatellites,
      payload: selectedSat
    });

  const handleOnApprove = async () => {
    try {
      const res = await store.dispatch(approveTimelineAction());
      if (res.details) {
        store.dispatch(setFeedback("Error", FeedbackStatus.ERROR, res.details));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnSave = async (e: MouseEvent) => {
    const overlaps = executeOverlapsToTrack(timelineItems);
    await handleOverlaps(overlaps);
    if (overlaps) return;
    const updatedTimelineItems = reverseCalendarTimeline(
      timelineItems,
      selectedTimeline
    );
    store.dispatch(updateProposedTimelineAction(updatedTimelineItems));
  };

  const handleDisapprove = () => {
    dispatch({
      type: ContextActionsNames.timelineDialog,
      payload: { isOpen: true }
    });
  };

  return (
    <Flex justifyContent={"space-between"} mb={4} overflow={"visible"}>
      <Select onChange={handleTimelineSelection}>
        {calendarTimeline.map((opt: CalendarTimeline, i: number) => (
          <option key={i} value={opt.timelineType}>
            {opt.name}
          </option>
        ))}
      </Select>

      <div>
        {(selectedCalendarTimeline?.timelineType === TimelineTypes.MASTER &&
          getMasterTimelineCommands()) ||
          getOtherTimelineCommands()}
        <PointerButton
          title={"Reset timeline position"}
          onClick={handleResetTimelinePosition}
          size={12}
        />
        <TooltipMenu
          selected={selectedSatellites}
          type={"checklist"}
          options={availableSatellites.map((sat) => ({
            id: sat.id.toString(),
            name: sat.label
          }))}
          title={"Satellites"}
          onSelect={setSelectedSatellites}
        />
      </div>
    </Flex>
  );
};
