import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgChart = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    viewBox="0 0 24 24"
    width={size || width}
    height={size || height}
    color={color}
  >
    <path
      fill="currentColor"
      d="M17.45,15.18L22,7.31V19L22,21H2V3H4V15.54L9.5,6L16,9.78L20.24,2.45L21.97,3.45L16.74,12.5L10.23,8.75L4.31,19H6.57L10.96,11.44L17.45,15.18Z"
    />
  </Svg>
);

SvgChart.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgChart;
