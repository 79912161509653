import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgSettingsMenu = ({
  width,
  height,
  size,
  color,
  id
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 18 18"
    color={color}
    id={id}
  >
    <g>
      <g>
        <path d="M10.494 18H7.506l-.955-2.231q-.312-.113-.608-.25l-2.251.9-2.112-2.112.9-2.251a7.68 7.68 0 0 1-.25-.608l-2.231-.955V7.505L2.23 6.55q.112-.312.25-.608l-.9-2.251 2.112-2.113 2.251.9q.3-.138.608-.25l.955-2.231h2.988l.955 2.231c.207.074.411.158.608.25l2.251-.9 2.113 2.112-.9 2.251q.138.3.25.608l2.231.955v2.988l-2.606 1.115-.708-1.655 1.514-.648v-.612l-1.9-.815-.12-.406a5.39 5.39 0 0 0-.434-1.056l-.2-.372.769-1.92-.433-.433-1.919.768-.372-.2a5.483 5.483 0 0 0-1.056-.434l-.407-.119-.815-1.9h-.612l-.815 1.9-.407.12a5.483 5.483 0 0 0-1.056.434l-.373.2-1.918-.768-.433.434.769 1.919-.2.372a5.455 5.455 0 0 0-.435 1.056l-.119.406-1.9.815v.612l1.9.815.119.406a5.382 5.382 0 0 0 .435 1.057l.2.372-.769 1.919.434.433 1.919-.769.372.2a5.432 5.432 0 0 0 1.055.434l.407.12.815 1.9h.612l.648-1.514 1.655.708z" />
      </g>
      <g>
        <g>
          <path d="M17.473 14.927l-5.012-5.013a3.567 3.567 0 0 0-4.383-4.383l2.187 2.187-2.547 2.547-2.187-2.187a3.567 3.567 0 0 0 4.383 4.383l5.013 5.013a1.8 1.8 0 1 0 2.546-2.547z" />
        </g>
      </g>
    </g>
  </Svg>
);

SvgSettingsMenu.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgSettingsMenu;
