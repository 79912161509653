import React, { useContext, useEffect } from "react";
import {
  MasterKey,
  MasterKeyListProps,
  KeyStatus,
  ContextActionsNames
} from "../models";
import styled from "styled-components";
import { KeyManagerContext } from "../context";
import { KeyStateIndicator } from "./KeyStateIndicator";
import { Flex } from "primitives";
import { DeleteButton, ActivateButton } from "components";
import { handleKeyStateDialog } from "app/keyManager/utils/helpers";
import palette from "styles/palette";

const Container = styled("div")`
  width: 60%;
  margin-right: 20px;
`;

const MasterKeyItem = styled("div")<{ checked: boolean }>`
  margin-bottom: 10px;
  background-color: ${(props) => (props.checked ? "#1F1E2C" : "#293650")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${palette.palette.blue[1]};
  cursor: pointer;
  padding: 10px;
  outline: ${(props) =>
    props.checked ? `1px solid${palette.palette.blue[1]}` : "none"}
  &:hover {
    outline: 1px solid ${palette.palette.blue[1]};
  }
`;

const KeyId = styled("div")`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: monospace;
`;

export const MasterKeyList = ({ masterKeys }: MasterKeyListProps) => {
  const {
    state: { selectedMasterKey },
    dispatch
  } = useContext(KeyManagerContext);

  useEffect(() => {
    dispatch({ type: ContextActionsNames.selectedSessionKey, payload: [] });
  }, [selectedMasterKey]);

  const handleSelection = (masterKey: MasterKey) => {
    const selected =
      masterKeys.find((mk: MasterKey) => mk.key_id === masterKey.key_id) ||
      null;
    dispatch({
      type: ContextActionsNames.selectedMasterKey,
      payload: selected
    });
  };

  return (
    <Container>
      {masterKeys.map((mk: MasterKey, i) => (
        <MasterKeyItem
          data-testid={"master-key-item"}
          key={i}
          checked={mk.key_id === selectedMasterKey?.key_id}
          onClick={() => handleSelection(mk)}
        >
          <KeyStateIndicator status={mk.status} theme={"light"} />{" "}
          <KeyId>{mk.label || mk.key_id}</KeyId>
          <Flex>
            <ActivateButton
              title="Activate"
              onClick={(e: React.MouseEvent) =>
                handleKeyStateDialog(e, mk, KeyStatus.ACTIVE, dispatch)
              }
              ml={1}
              disabled={mk.status === KeyStatus.ACTIVE}
            />
            <DeleteButton
              title="Destroy"
              onClick={(e: React.MouseEvent) =>
                handleKeyStateDialog(e, mk, KeyStatus.DESTROYED, dispatch)
              }
              ml={1}
            />
          </Flex>
        </MasterKeyItem>
      ))}
    </Container>
  );
};
