import React from "react";
import { Header } from "../Header";
import { ConfigsTable } from "./ConfigTable/ConfigsTable";

export const Configs = (props: any) => {
  return (
    <>
      <Header {...props} />
      <ConfigsTable />
    </>
  );
};
