import React from "react";
import { Box, Text } from "primitives";
import { CollapsibleLabel, shouldShowForm } from "./CollapsibleLabel";

export const FieldTemplate = (props) => {
  const {
    id,
    classNames,
    label,
    help,
    description,
    errors,
    children,
    hidden,
    schema
  } = props;
  const [show, setShow] = React.useState(false);
  //Deleted duplicated description
  //TODO: Understand why DashboardComponentsConfiguration doesn't work without this
  children[0].props.schema.description = undefined;
  return (
    <Box mb={1} mt={1} className={classNames}>
      {!hidden && !schema.hidden && (
        <>
          {props.uiSchema.label !== false && (
            <CollapsibleLabel
              id={id}
              label={label}
              props={props}
              schema={schema}
              show={show}
              setShow={setShow}
            />
          )}
          <Box minHeight={45}>
            <Text fontSize={14} color="text.grey">
              {description}
            </Text>
            {shouldShowForm(props, schema, show) ? children : null}
            <Text color="text.danger">{errors}</Text>
            {help}
          </Box>
        </>
      )}
    </Box>
  );
};
