import { connect } from "react-redux";
import {
  setMasterKeyStateAction,
  setSessionKeyStateAction
} from "app/keyManager/actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ChangeStateDialog } from "../components/ChangeStateDialog";
import {
  MasterKeyRequestParams,
  SessionKeyRequestParams
} from "app/keyManager/models";
const mapStateToProps = (state: any) => ({
  selectedSatellite: state.constellations.dashboard.find(
    (satellite: any) => satellite.visible
  )
});
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    setMasterKeyState: (satelliteID: number, params: MasterKeyRequestParams) =>
      dispatch(setMasterKeyStateAction(satelliteID, params)),
    setSessionKeyState: (
      satelliteID: number,
      params: SessionKeyRequestParams
    ) => dispatch(setSessionKeyStateAction(satelliteID, params))
  };
};
export const ChangeStateDialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeStateDialog);
