import {
  KeyManagerActionType,
  KeyManagerActions,
  KeyManagerStoreState,
  SessionKey
} from "../models";
import { masterKeyStateManager } from "../utils/helpers";

const initialState: KeyManagerStoreState = {
  masterKeys: [],
  sessionKeysMap: {}
};

export const keyManagerReducer = (
  state: KeyManagerStoreState = initialState,
  action: KeyManagerActions
): KeyManagerStoreState => {
  switch (action.type) {
    case KeyManagerActionType.FetchMasterKey:
      return {
        ...state,
        masterKeys: state.masterKeys.map(
          (mk) => (mk.key_id === action.payload?.key_id && action.payload) || mk
        )
      };

    case KeyManagerActionType.FetchAllMasterKey:
      return { ...state, masterKeys: action.payload || state.masterKeys };

    case KeyManagerActionType.FetchAllSessionKey:
      return {
        ...state,
        sessionKeysMap: { ...state.sessionKeysMap, ...(action.payload || {}) }
      };

    case KeyManagerActionType.DeriveSessionKey: {
      return {
        ...state,
        sessionKeysMap:
          (action.payload && {
            ...state.sessionKeysMap,
            [action.payload.masterKey]: [
              ...state.sessionKeysMap[action.payload.masterKey],
              ...action.payload.sessionKeys
            ]
          }) ||
          state.sessionKeysMap
      };
    }

    case KeyManagerActionType.SetMasterKeyState: {
      if (action.payload === null) return state;
      const masterKeys = masterKeyStateManager(
        action.payload?.master_key_id,
        state.masterKeys,
        action.payload.state
      );
      return {
        ...state,
        masterKeys
      };
    }

    case KeyManagerActionType.FetchSessionKey: {
      const updatedSessionKeys = state.sessionKeysMap[
        action.payload.masterKeyId
      ].map((sk: SessionKey) => {
        return (
          (sk.key_id === action.payload.sessionKey?.key_id &&
            action.payload.sessionKey) ||
          sk
        );
      });
      return {
        ...state,
        sessionKeysMap: {
          ...state.sessionKeysMap,
          [action.payload.masterKeyId]: updatedSessionKeys
        }
      };
    }

    default:
      return state;
  }
};
