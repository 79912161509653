import React, { useState } from "react";
import { TableCell, TableRow } from "components";
import { Flex, Text } from "primitives";
import { Title, CommentArea } from "../styledComponents";

const TableCellDetails = ({
  title,
  value,
  children,
  colSpan = 1
}: {
  title: string;
  value?: string | number;
  children?: React.ReactElement;
  colSpan?: number;
}) => {
  return (
    <TableCell colSpan={colSpan} border="none !important" width="12.5%">
      <Flex bg="fill.2" flexDirection="column">
        <Title> {title}</Title>
        {value && <Text>{value}</Text>}
        {children}
      </Flex>
    </TableCell>
  );
};

const PassageDetails = ({ show }: { show: boolean }) => {
  const [comment, setComment] = useState("");
  return (
    <>
      {show && (
        <TableRow bg="fill.2" data-testid="passage-details">
          <TableCellDetails title="RX Count" value="000000" />
          <TableCellDetails title="TX Count" value="000000" />
          <TableCellDetails title="Aurora ID" value="000000" />
          <TableCellDetails title="Provider ID" value="000000" />
          <TableCellDetails title="TC Sent" value="000000" />
          <TableCellDetails title="TM Received" value="000000" />
          <TableCellDetails title="Script Logs" value="000000" />
          <TableCellDetails colSpan={2} title="Comment">
            <CommentArea
              id="operator-comment"
              data-testid="operator-comment"
              value={comment}
              onChange={(e: { target: { value: string } }) =>
                setComment(e.target.value)
              }
              onCancel={() => setComment("")}
              placeholder="Add a comment"
            />
          </TableCellDetails>
        </TableRow>
      )}
    </>
  );
};

export default PassageDetails;
