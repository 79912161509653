import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import Text, { TextProps } from "./Text";

interface HeadingProps extends TextProps {
  display?: number;
}

const HeadingStyled = styled(Text)<HeadingProps>`
  font-weight: ${themeGet("fontWeights.bold")};
  font-size: ${(props: HeadingProps) => themeGet(`heading.${props.display}`)}px;
`;

const Heading = (props: HeadingProps) => <HeadingStyled as="h2" {...props} />;

Heading.displayName = "Heading";

Heading.defaultProps = {
  display: 2,
  m: 0,
  color: "text.default"
};

export default Heading;
