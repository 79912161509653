import React, { useState, useRef, useEffect } from "react";

const OPTIONS = {
  root: null,
  rootMargin: "0px",
  threshold: 0.8
};

export const Lazy: React.FC = ({ children }) => {
  const ref = useRef<any>();
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(ref.current);
          observer.disconnect();
        }
      });
    }, OPTIONS);
    observer.observe(ref.current);
    return () => {
      if (isVisible) {
        setIsVisible(false);
      }
      observer.unobserve(ref.current);
      observer.disconnect();
    };
  }, [ref]);

  return <div ref={ref}>{isVisible ? children : null}</div>;
};
