import React, { FC, useEffect } from "react";
import { Text, Box, Button, Icon, Flex } from "primitives";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { ScriptExecutionListItem, ExecutionId } from "app/scripting/models";
import { AnyAction } from "redux";
import {
  terminateExecution,
  fetchRunningScriptAction
} from "app/scripting/actions";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  DragHandle
} from "components";
import styled from "styled-components";

const TableHeader = styled(TableCell)``;
TableHeader.defaultProps = {
  position: "sticky",
  top: "2px",
  borderColor: "fill.3",
  bg: "palette.blue.3"
};
/***
 * Component that list all running scripts and the state of each
 */
interface ScriptExecutionProps {
  stopExecution: (executionId: ExecutionId) => Promise<any>;
  runningScripts?: ScriptExecutionListItem[];
  getRunningScripts: () => Promise<ScriptExecutionListItem[]>;
  name?: any;
}

const ScriptExecution: FC<ScriptExecutionProps> = ({
  getRunningScripts,
  runningScripts,
  stopExecution
}) => {
  useEffect(() => {
    getRunningScripts();
  }, [getRunningScripts]);

  const stopRunningScript = (scriptId: ExecutionId): void => {
    stopExecution(scriptId).then(() => {
      getRunningScripts();
    });
  };

  return (
    <>
      <Box
        data-testid="script-execution-list"
        overflow="visible"
        color="text.default"
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader py={20} width="auto">
                <Flex>
                  <DragHandle my={0} ml={0} />
                  Script
                </Flex>
              </TableHeader>
              <TableHeader width="auto">Procedure</TableHeader>
              <TableHeader width="auto">Status</TableHeader>
              <TableHeader width={50}></TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {runningScripts &&
              runningScripts.map(
                (script: ScriptExecutionListItem, i: number) => (
                  <TableRow key={i}>
                    <TableCell width="auto">
                      <Text caps>{script.scriptName}</Text>
                    </TableCell>
                    <TableCell width="auto">
                      <Text>{script.procedure && script.procedure.name}</Text>
                    </TableCell>
                    <TableCell width="auto">
                      <Text>{script.state}</Text>
                    </TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        onClick={() => stopRunningScript(script.id)}
                      >
                        <Icon name="Stop" />
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  runningScripts: state.scriptExecution.runningScripts
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  getRunningScripts: (): Promise<ScriptExecutionListItem[]> =>
    dispatch(fetchRunningScriptAction()),
  stopExecution: (executionId: ExecutionId): Promise<any> => {
    return dispatch(terminateExecution(executionId));
  }
});

export const ScriptExecutionList = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScriptExecution);
