import uPlot from "uplot";

const LEGEND_STYLES = {
  textAlign: "left",
  "background-color": "#353447",
  color: "white",
  opacity: 1,
  pointerEvents: "none",
  display: "inline-block",
  padding: "2px 0px",
  "font-size": "10px",
  position: "absolute",
  left: 0,
  top: 0,
  zIndex: 100
};

export const legendTooltipPlugin = (className = "tooltip-hover") => {
  let legendEl: any;

  function init(u: uPlot, opts: uPlot.Options) {
    legendEl = u.root.querySelector(".u-legend");
    legendEl?.classList?.remove("u-inline");
    className && legendEl?.classList?.add(className);
    if (legendEl) {
      legendEl.style && uPlot.assign(legendEl.style, LEGEND_STYLES);
      const overlayEl = u.over;
      overlayEl.style.overflow = "visible";
      legendEl && overlayEl.appendChild(legendEl);

      overlayEl.addEventListener("mouseenter", () => {
        if (legendEl.style) {
          legendEl.style.display = null;
        }
      });
      overlayEl.addEventListener("mouseleave", () => {
        if (legendEl.style) {
          legendEl.style.display = "none";
        }
      });
    }
  }

  function update(u: uPlot) {
    const { left, top } = u.cursor;
    const legendIsVisible = legendEl && legendEl.style;
    if (left && top && legendIsVisible) {
      if (left < 0 && top < 0) {
        legendEl.style.display = "none";
      }
    }
    if (legendIsVisible) {
      legendEl.style.transform = `translate(${left}px, ${top}px)`;
    }
  }

  return {
    hooks: {
      init: init,
      setCursor: update
    }
  };
};
