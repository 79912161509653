import { connect } from "react-redux";
import { DialogBase } from "../components/DialogBase";
import { showDialog, hideDialog } from "../actions";

const mapStateToProps = (state) => ({
  dialog: state.dialog
});

const mapDispatchToProps = (dispatch) => {
  return {
    showDialog: (reference) => dispatch(showDialog(reference)),
    hideDialog: () => dispatch(hideDialog())
  };
};

export const DialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogBase);
