import { connect } from "react-redux";
import { DashboardSelector } from "../components/DashboardSelector";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state) => ({
  dashboards: state.dashboard.list,
  selectedDashboardId: state.dashboard.selected
});

const mapDispatchToProps = () => {
  return {};
};

export const DashboardSelectorContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DashboardSelector)
);
