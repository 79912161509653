import {
  DISMISS_ALERT_ERROR,
  DISMISS_ALERT_SUCCESS,
  DISMISS_ALERT_START
} from "./../models/constants";

const initialState = {
  loading: false,
  error: ""
};

export const alertsDismissReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case DISMISS_ALERT_START:
      return { ...state, loading: true };
    case DISMISS_ALERT_ERROR:
      return { ...state, loading: false, error: action.payload };
    case DISMISS_ALERT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};
