import React from "react";
import { Text } from "primitives";
import styled from "styled-components";

export const Tab = ({ label, onClick, index, ...props }) => {
  const handleClick = () => {
    onClick(index);
  };
  return (
    <SingleTab index={index} {...props} onClick={handleClick}>
      <Text px={3} pt={1}>
        {label}
      </Text>
    </SingleTab>
  );
};

const SingleTab = styled.button`
  color: ${(props) => props.color};
  opacity: ${(props) => (props.value === props.index ? 1 : 0.7)};
  font-size: 0.875rem;
  max-width: 264px;
  min-width: 72px;
  min-height: 46px;
  border: 0;
  padding: 0;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  @media (min-width: 960px) {
    min-width: 160px;
    font-size: 0.8125rem;
  }
  @media (max-width: 960px) {
    min-width: 80px;
  }
`;
