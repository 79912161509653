import React from "react";
import { Switch, Route } from "react-router-dom";
import { ProcedureListContainer } from "./components/ProcedureList";
import { ProcedureCreateContainer } from "./components/ProcedureCreate";
import { ProcedureEditContainer } from "./components/ProcedureEdit";

export const ProcedureRouters = () => (
  <>
    <Switch>
      <Route
        path="/procedures/create"
        exact
        component={ProcedureCreateContainer}
      />
      <Route path="/procedures/:id" component={ProcedureEditContainer} />
      <Route path="/procedures" component={ProcedureListContainer} />
    </Switch>
  </>
);
