import React, { useState, useEffect, useRef, MutableRefObject } from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import { Text, Flex, Absolute } from "primitives";
import {
  Feedback as FeedbackInterface,
  FeedbackStatus
} from "app/feedback/models";
import { theme } from "styles";

interface FeedbackBaseProps {
  feedback: FeedbackInterface;
  hideFeedback?: any;
  i?: number;
  prevDet?: number;
}

const Container = styled(Absolute)`
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  z-index: 1400;
`;

const Close = styled(Absolute)`
  cursor: pointer;
`;

const ShowHideDetails = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
`;

export const Feedback = ({
  feedback,
  hideFeedback,
  i,
  prevDet
}: FeedbackBaseProps) => {
  const timeout: MutableRefObject<
    null | number | ReturnType<typeof setTimeout>
  > = useRef(null);
  const [title, setTitle] = useState(feedback.title);
  const [status, setStatus] = useState(feedback.status);
  const [details, setDetails] = useState(feedback.details);
  const [showDetails, setShowDetails] = useState(false);

  const color =
    status === FeedbackStatus.SUCCESS
      ? themeGet("colors.palette.green.1")({ theme })
      : status === FeedbackStatus.WARNING
      ? themeGet("colors.palette.orange.1")({ theme })
      : themeGet("colors.palette.red.1")({ theme });

  useEffect(() => {
    if (title && !feedback.title) {
      timeout.current = setTimeout(() => {
        setTitle("");
        setDetails("");
        setStatus(FeedbackStatus.SUCCESS);
        setShowDetails(false);
      }, 3000);
    } else {
      setTitle(feedback.title);
      setStatus(feedback.status);
      if (feedback.details) {
        setDetails(feedback.details);
      }
      if (timeout.current !== null) {
        clearTimeout(timeout.current as ReturnType<typeof setTimeout>);
        timeout.current = null;
      }
    }
  }, [feedback, title]);

  if (!title) return null;

  return feedback.showInFooter ? (
    <Text color={color}>{title}</Text>
  ) : (
    <Container
      zIndex={10}
      width={"300px"}
      right={"20px"}
      bottom={i === 0 ? 20 : 90 * (i as number) + 10 * (prevDet as number) + 20}
      boxShadow="0 2px 10px -10px"
      opacity={title ? 1 : 0}
    >
      <Flex
        position="relative"
        flexDirection="column"
        p={3}
        borderRadius={2}
        bg={color}
      >
        <Close
          onClick={() => hideFeedback(feedback.id)}
          right={"10px"}
          top={0}
          overflow="visible"
        >
          <Text color="white" fontSize="22px" bold>
            ×
          </Text>
        </Close>
        <Text mt={"2px"} color="white" fontSize="18px" bold>
          {title}
        </Text>
        <Flex flexDirection="column">
          {details && showDetails ? (
            <Text color="white" fontSize="16px">
              {details}
            </Text>
          ) : null}
          {details ? (
            showDetails ? (
              <ShowHideDetails
                color="white"
                onClick={() => setShowDetails(false)}
              >
                Hide details
              </ShowHideDetails>
            ) : (
              <ShowHideDetails
                color="white"
                onClick={() => setShowDetails(true)}
              >
                Show details
              </ShowHideDetails>
            )
          ) : null}
        </Flex>
      </Flex>
    </Container>
  );
};
