import { useState, useCallback } from "react";

export interface UseToggleProps {
  defaultToggled?: boolean;
  onToggle?: (value: boolean) => void;
}

export type UseToggleReturn = [boolean, () => void];

const useToggle = ({
  defaultToggled = false
}: UseToggleProps): UseToggleReturn => {
  const [toggled, setToggled] = useState(defaultToggled);

  const useToggleCallback = () => {
    setToggled((previousToggled) => !previousToggled);
  };

  const toggle = useCallback(useToggleCallback, []);

  return [toggled, toggle];
};

export default useToggle;
