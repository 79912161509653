import React from "react";
import { SuspenseMutation } from "app/network";
import { Toggler } from "components";
import { DeleteButton, CancelButton } from "components";
import { Group } from "../models";
import { Flex, Dialog, DialogTitle, DialogActions } from "primitives";

interface GroupDeleteProps {
  group: Group;
}

export const GroupDelete = ({ group }: GroupDeleteProps) => {
  return (
    <Toggler>
      {([toggled, onToggle]) => (
        <>
          <DeleteButton onClick={onToggle}>Delete</DeleteButton>

          <Dialog open={toggled} maxWidth="sm" data-testid="ScriptDelete">
            <SuspenseMutation>
              {({ loading, action }) => {
                return (
                  <>
                    <DialogTitle>Delete {group.name}?</DialogTitle>

                    <DialogActions>
                      <Flex alignItems="center">
                        <DeleteButton
                          //onClick={() => action(() => )}
                          mr={1}
                          disabled={loading}
                        >
                          Delete
                        </DeleteButton>
                        <CancelButton onClick={onToggle} mr={1}>
                          Cancel
                        </CancelButton>
                      </Flex>
                    </DialogActions>
                  </>
                );
              }}
            </SuspenseMutation>
          </Dialog>
        </>
      )}
    </Toggler>
  );
};
