import * as styles from "styled-system";

const COMMON_LIST = [
  styles.fontSize,
  styles.color,
  styles.space,
  styles.opacity
];

export interface CommonProps
  extends styles.SpaceProps,
    styles.FontSizeProps,
    styles.OpacityProps {
  color?: string;
  bg?: string;
  backgroundColor?: string;
}

const BORDER_LIST = [styles.borders, styles.boxShadow];

export interface BorderProps
  extends styles.BordersProps,
    styles.BoxShadowProps {}

const TYPOGRAPHY_LIST = [
  styles.fontFamily,
  styles.fontSize,
  styles.fontStyle,
  styles.fontWeight,
  styles.lineHeight,
  styles.textAlign
];

export interface TypographyProps
  extends styles.FontFamilyProps,
    styles.FontSizeProps,
    styles.FontStyleProps,
    styles.FontWeightProps,
    styles.LineHeightProps,
    styles.TextAlignProps {}

const LAYOUT_LIST = [
  styles.display,
  styles.size,
  styles.width,
  styles.height,
  styles.minWidth,
  styles.minHeight,
  styles.maxWidth,
  styles.maxHeight,
  styles.overflow,
  styles.verticalAlign,
  styles.position
];

export interface LayoutProps
  extends styles.DisplayProps,
    styles.SizeProps,
    styles.WidthProps,
    styles.HeightProps,
    styles.MinWidthProps,
    styles.MinHeightProps,
    styles.MaxWidthProps,
    styles.MaxHeightProps,
    styles.OverflowProps,
    styles.VerticalAlignProps,
    styles.PositionProps {}

const POSITION_LIST = [
  styles.position,
  styles.zIndex,
  styles.top,
  styles.right,
  styles.bottom,
  styles.left
];

export interface PositionProps
  extends styles.PositionProps,
    styles.ZIndexProps,
    styles.TopProps,
    styles.RightProps,
    styles.BottomProps,
    styles.LeftProps {}

const FLEX_LIST = [
  styles.display,
  styles.flexBasis,
  styles.flexDirection,
  styles.flexWrap,
  styles.alignContent,
  styles.alignItems,
  styles.justifyContent,
  styles.justifyItems,
  styles.flex,
  styles.justifySelf,
  styles.alignSelf,
  styles.order
];

export interface FlexProps
  extends styles.DisplayProps,
    styles.FlexBasisProps,
    styles.FlexDirectionProps,
    styles.FlexWrapProps,
    styles.AlignContentProps,
    styles.AlignItemsProps,
    styles.JustifyContentProps,
    styles.JustifyItemsProps,
    styles.FlexProps,
    styles.JustifySelfProps,
    styles.AlignSelfProps,
    styles.OrderProps {}

const GRID_LIST = [
  styles.gridGap,
  styles.gridRowGap,
  styles.gridColumnGap,
  styles.gridColumn,
  styles.gridRow,
  styles.gridArea,
  styles.gridAutoFlow,
  styles.gridAutoRows,
  styles.gridAutoColumns,
  styles.gridTemplateRows,
  styles.gridTemplateColumns,
  styles.gridTemplateAreas,
  ...LAYOUT_LIST
];

export interface GridProps
  extends styles.DisplayProps,
    styles.GridGapProps,
    styles.GridRowGapProps,
    styles.GridColumnGapProps,
    styles.GridColumnProps,
    styles.GridRowProps,
    styles.GridAreaProps,
    styles.GridAutoFlowProps,
    styles.GridAutoRowsProps,
    styles.GridAutoColumnsProps,
    styles.GridTemplateRowsProps,
    styles.GridTemplateColumnsProps,
    styles.GridTemplatesAreasProps {}

export const COMMON = styles.compose(...COMMON_LIST);
export const BORDER = styles.compose(...BORDER_LIST);
export const TYPOGRAPHY = styles.compose(...TYPOGRAPHY_LIST);
export const LAYOUT = styles.compose(...LAYOUT_LIST);
export const POSITION = styles.compose(...POSITION_LIST);
export const FLEX = styles.compose(...FLEX_LIST);
export const GRID = styles.compose(...GRID_LIST);
