import React, { Component } from "react";
import { Box, Heading, Flex, Text } from "primitives";
import { Form } from "app/shared";
import { SuspenseMutation } from "app/network";
import { schemaCreate, uiSchemaCreate } from "../schemas";
import { createSatelliteDefinition } from "../services";
import { SaveButton, CancelButton } from "components";
import { FeedbackStatus } from "app/feedback/models";

export class SatelliteDefinitionCreate extends Component {
  cancel = () => {
    this.props.history.push("/satellite-definition");
  };

  submit = (e, { formData }) => {
    e.preventDefault();
    return createSatelliteDefinition(formData)
      .then(() => {
        this.cancel();
      })
      .catch(() => {
        this.props.setFeedback("Unauthorized", FeedbackStatus.ERROR);
      });
  };

  render() {
    return (
      <SuspenseMutation>
        {({ loading, error, action }) => {
          return (
            <Box
              color="text.default"
              data-testid="SatelliteDefinitionCreate"
              mx={3}
            >
              <Flex mb={2}>
                <Heading display={1}>Satellite definition</Heading>
              </Flex>
              <Flex flexDirection="column" bg="fill.0" p={3}>
                <Form
                  schema={schemaCreate}
                  uiSchema={uiSchemaCreate}
                  onSubmit={(data, e) => action(() => this.submit(e, data))}
                >
                  <SaveButton type="submit" disabled={loading}>
                    Save
                  </SaveButton>
                  <CancelButton onClick={this.cancel} disabled={loading} mx={1}>
                    Cancel
                  </CancelButton>

                  {error ? (
                    <Box my={2}>
                      <Text color="text.danger">Error...</Text>
                    </Box>
                  ) : null}
                </Form>
              </Flex>
            </Box>
          );
        }}
      </SuspenseMutation>
    );
  }
}
