import React from "react";
import { Icon, Absolute, Box, Relative } from "primitives";

interface DragHandleProps {
  absolute?: boolean;
  relative?: boolean;
  label?: boolean;
  topLabel?: number;
  top?: number;
  left?: number;
  my?: number | string;
  m?: number | string;
  mr?: number | string;
  ml?: number | string;
  width?: number;
  color?: string;
}

const DragHandle = ({
  absolute = false,
  relative = false,
  label = false,
  topLabel = 59,
  top = 20,
  left = 8,
  my = 3,
  mr = 2,
  ml = 1,
  width = 15,
  color = "#7AB9DB",
  ...props
}: DragHandleProps) => {
  return (
    <>
      {absolute ? (
        <Absolute
          color={color}
          className="drag-handle"
          cursor="move"
          zIndex={5}
          left={left}
          top={label ? topLabel : top}
        >
          <Icon name="Drag" size={14} dataTestId="drag"></Icon>
        </Absolute>
      ) : relative ? (
        <Relative
          color={color}
          className="drag-handle"
          cursor="move"
          zIndex={5}
          left={left}
          top={label ? topLabel : top}
          width={width}
        >
          <Icon name="Drag" size={14}></Icon>
        </Relative>
      ) : (
        <Box
          my={my}
          mr={mr}
          ml={ml}
          color={color}
          cursor="move"
          className="drag-handle"
          width={width}
          height={14}
          overflow="visible"
          {...props}
        >
          <Icon name="Drag" size={14}></Icon>
        </Box>
      )}
    </>
  );
};

export default DragHandle;
