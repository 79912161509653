import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import {
  TYPOGRAPHY,
  BORDER,
  TypographyProps,
  CommonProps,
  BorderProps
} from "styles/constants";
import { Box } from "primitives";
import {
  TestableProps,
  DATA_TEST_ID_ATTR_NAME
} from "components/testable/models";

type HTMLInput = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

interface InputProps
  extends TypographyProps,
    BorderProps,
    CommonProps,
    TestableProps {}

const Container = styled(Box)`
  width: 100%;
`;

const StyledTextArea = styled("textarea")`
  ${TYPOGRAPHY}
  ${BORDER}
    background-color: transparent;
  outline: none;
  border-width: 0px 0px 1px 0px;
  width: 100%;
  color: ${(props) =>
    props.disabled
      ? themeGet("colors.palette.grey.1")
      : themeGet("colors.palette.brand.0")};
  padding: 5px 0px;
  &:focus {
    border-bottom-width: 2px;
  }
  &:hover {
    border-bottom-width: 2px;
  }
`;

const TextArea = (props: HTMLInput & InputProps) => {
  return (
    <Container>
      <StyledTextArea {...props} />
    </Container>
  );
};

TextArea.displayName = "Input";

TextArea.defaultProps = {
  fontSize: "16px",
  [DATA_TEST_ID_ATTR_NAME]: TextArea.displayName
};

export default TextArea;
