import React, { useState, useEffect } from "react";
import { InputField } from "components";
import { Form } from "app/shared";
import { clone } from "utils";
import {
  isAdmin,
  pathSelectorConfigOptionsSchema,
  pathSelectorConfigOptionsUISchema
} from "./PathSelectorSchema";
import "./PathSelector.css";
import { getWritableArmsPaths } from "../services";

export const PathSelector = (props: any) => {
  const [permissions, setPermissions] = useState(null);

  const { data, setData, path, setPath } = props;

  useEffect(() => {
    getWritableArmsPaths().then((res: any) => setPermissions(res.data));
  }, []);

  return (
    <>
      {permissions && !isAdmin(permissions) && (
        <Form
          className="arms-path-selector-cl"
          id="arms-path-selector"
          formData={data ? clone(data) : {}}
          schema={pathSelectorConfigOptionsSchema(permissions, data)}
          uiSchema={pathSelectorConfigOptionsUISchema}
          onChange={(form: any) => setData(clone(form.formData))}
        >
          <></>
        </Form>
      )}
      <InputField
        id="path"
        disabled={!isAdmin(permissions)}
        onChange={(e) => setPath(e.target.value)}
        value={path}
        label="ARMS Path"
      />
    </>
  );
};
