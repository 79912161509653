import {
  DashboardComponent,
  TelemetryComponents
} from "app/shared/timeController/models";
import { Dashboard } from "../models";

export const hasTelemetryWidgets = (dashboard: Dashboard) => {
  return (
    dashboard &&
    Array.isArray(dashboard.components) &&
    dashboard.components.some((component: DashboardComponent) =>
      (Object.values(TelemetryComponents) as string[]).includes(component.type)
    )
  );
};
