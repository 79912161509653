import React, { createContext } from "react";
import { IContext, IContextActions, Passage, DisplayBy } from "../models";
import { InitialValue, ALL_GROUND_STATIONS } from "../helpers";

export const initialState: IContext = {
  showCalendar: false,
  sortBy: "aos",
  isSortAscending: true,
  calendarViewSelectedPassage: [],
  passages: [],
  groundStations: [],
  selectedGroundStation: ALL_GROUND_STATIONS,
  begin: new Date(InitialValue.begin),
  end: new Date(InitialValue.end),
  filter: undefined,
  displayBy: "satellite",
  resetting: false,
  loading: false,
  viewBySatellite: true
};

export const reducer = (state: IContext, action: IContextActions): IContext => {
  switch (action.type) {
    case "showCalendar":
      return { ...state, showCalendar: action.payload as boolean };
    case "sortBy":
      return { ...state, sortBy: "aos" };
    case "isSortAscending":
      return { ...state, isSortAscending: action.payload as boolean };
    case "calendarViewSelectedPassage":
      return {
        ...state,
        calendarViewSelectedPassage: action.payload as Passage[]
      };
    case "passages":
      return { ...state, passages: action.payload as Passage[] };
    case "groundStations":
      return { ...state, groundStations: [] };
    case "selectedGroundStation":
      return { ...state, selectedGroundStation: action.payload };
    case "begin":
      return { ...state, begin: action.payload as Date };
    case "end":
      return { ...state, end: action.payload as Date };
    case "filter":
      return { ...state, filter: action.payload as number };
    case "displayBy":
      return { ...state, displayBy: action.payload as DisplayBy };
    case "resetting":
      return { ...state, resetting: action.payload as boolean };
    case "loading":
      return { ...state, loading: action.payload as boolean };
    default:
      return { ...state, ...(action.payload as any) };
  }
};

export const VisibiltyWindowContext = createContext<{
  state: IContext;
  dispatch: React.Dispatch<IContextActions>;
}>({ state: initialState, dispatch: () => null });
