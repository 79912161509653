import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgMessages = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 20 20"
    color={color}
  >
    <g>
      <path d="M17.003 0h-10a3 3 0 0 0-3 3v3h-1a3 3 0 0 0-3 3v4a3.006 3.006 0 0 0 2 2.829V20l5.333-4h5.667a3 3 0 0 0 3-3v-3h1a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3zm-3 13a1 1 0 0 1-1 1H6.667L4 16v-2H3a.989.989 0 0 1-1-1V9a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1zm4-6a1 1 0 0 1-1 1h-1.188A2.993 2.993 0 0 0 13 6H6V3a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1z" />
      <path d="M2.996 10.004h2v2h-2z" />
      <path d="M7 10.004h2v2H7z" />
      <path d="M11.003 10.004h2v2h-2z" />
    </g>
  </Svg>
);

SvgMessages.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgMessages;
