import React from "react";
import FormJsonSchema from "react-jsonschema-form";
import {
  FieldTemplate,
  ArrayFieldTemplate,
  ObjectFieldTemplate
} from "app/shared";

export const Form = ({ children, ...props }) => (
  <FormJsonSchema
    noHtml5Validate={true}
    ArrayFieldTemplate={ArrayFieldTemplate}
    FieldTemplate={FieldTemplate}
    ObjectFieldTemplate={ObjectFieldTemplate}
    data-testid="Form"
    {...props}
  >
    {children}
  </FormJsonSchema>
);
