import React from "react";
import { Switch, Route } from "react-router-dom";
import { UserManagementList } from "./components/UserManagement";
import { UserCreate } from "./components/UserCreate";
import { UserEdit } from "./components/UserEdit";
import { Profile } from "./components/Profile";

export const UserRouters = () => (
  <>
    <Switch>
      <Route path="/usermanagement" exact component={UserManagementList} />
      <Route path="/usermanagement/createuser" exact component={UserCreate} />
      <Route path="/usermanagement/user/:id" component={UserEdit} />
      <Route path="/profile" component={Profile} />
    </Switch>
  </>
);
