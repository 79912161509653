import React, { Component } from "react";
import { Form } from "app/shared";
import { Box, Heading, Flex, Text } from "primitives";
import { SuspenseMutation } from "app/network";
import { CancelButton, SaveButton } from "components";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { CreateTelecommandStackCommand } from "../models";
import { schemaCreate, uiSchemaCreate } from "../models/schemas";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { TelecommandStack as ITelecommandStack } from "app/telecommandStack/models";
import { createTelecommandStack } from "app/telecommandStack/services";

interface TelecommandStackCreateProps extends RouteComponentProps {
  createTelecommandStack: (
    data: CreateTelecommandStackCommand
  ) => Promise<ITelecommandStack>;
  satellites: any;
}

interface TelecommandStackCreateState {
  formData: CreateTelecommandStackCommand | null;
}

export class TelecommandStackCreate extends Component<
  TelecommandStackCreateProps,
  TelecommandStackCreateState
> {
  state = {
    formData: null
  };

  onChange(form: TelecommandStackCreateState) {
    this.setState({ formData: form.formData });
  }

  async submit(
    e: React.FormEvent<HTMLInputElement>,
    formData: CreateTelecommandStackCommand
  ) {
    e.preventDefault();
    await createTelecommandStack(formData);
    this.props.history.push("/telecommandstacks");
  }

  render() {
    const { formData } = this.state;
    const { satellites } = this.props;

    return (
      <Box color="text.default" data-testid="TelecommandStackCreate" mx={3}>
        <Flex mb={2}>
          <Heading display={1}>Telecommand Stack</Heading>
        </Flex>
        <Flex flexDirection="column" bg="fill.0" p={3}>
          <SuspenseMutation>
            {({ loading, error, action }) => {
              return (
                <Form
                  formData={formData}
                  schema={schemaCreate(satellites)}
                  uiSchema={uiSchemaCreate}
                  disabled={loading}
                  onSubmit={(
                    form: { formData: CreateTelecommandStackCommand },
                    e: React.FormEvent<HTMLInputElement>
                  ) => action(() => this.submit(e, form.formData))}
                  onChange={(form: TelecommandStackCreateState) =>
                    this.onChange(form)
                  }
                >
                  <CancelButton
                    onClick={() =>
                      this.props.history.push("/telecommandstacks")
                    }
                    mr={2}
                  >
                    Cancel
                  </CancelButton>
                  <SaveButton type="submit" disabled={loading}>
                    Save
                  </SaveButton>

                  {error ? (
                    <Box my={2}>
                      <Text color="text.danger">Error...</Text>
                    </Box>
                  ) : null}
                </Form>
              );
            }}
          </SuspenseMutation>
        </Flex>
      </Box>
    );
  }
}

const mapStateToProps = (state: any) => ({
  satellites: state.constellations.dashboard.map((sat: any) => ({
    id: sat.id,
    name: sat.label
  }))
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
) => ({});

export const TelecommandStackCreateContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TelecommandStackCreate)
);
