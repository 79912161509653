import React, { useState } from "react";
import { Form } from "app/shared";
import { Box } from "primitives";
import { SuspenseMutation } from "app/network";
import { uiSchemaImport, schemaImport } from "../models/schemas";
import { Heading, Flex, Text } from "primitives";
import { CancelButton, SaveButton } from "components";
import { useDispatch, useSelector } from "react-redux";
import { checkIfExisting, mapDSNamesToIDs, ACTION } from "../helpers";
import { isValidJSON } from "app/dashboard/utils/transformations";
import { setFeedback } from "app/feedback/actions";
import { FeedbackStatus } from "app/feedback/models";
import { updateAlertDefinition, createAlertDefinition } from "../services";

export const AlertDefinitionImport = (props: any) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const alertDefinitions = useSelector(
    (s: any) => s.alert.alerts.alertDefinitions
  );
  const satDef = useSelector(
    (s: any) => s.satellite.dashboard.satelliteDefinition
  );

  const submit = async (
    e: React.FormEvent<HTMLInputElement>,
    formData: any
  ) => {
    e.preventDefault();
    try {
      const imported = isValidJSON(formData.file) && JSON.parse(formData.file);
      const alertDefList = mapDSNamesToIDs(imported.alertDefinitions, satDef);

      for (const idx in alertDefList) {
        if (idx) {
          const { id, shouldUpdate } = checkIfExisting(
            alertDefinitions,
            alertDefList,
            idx
          );
          if (shouldUpdate) {
            const result = await updateAlertDefinition(
              id,
              alertDefList[idx].alert,
              true
            );
            handleToast(result, dispatch, ACTION.UPDATED);
          } else {
            const result = await createAlertDefinition(alertDefList[idx], true);
            handleToast(result, dispatch, ACTION.CREATED);
          }
        }
      }
    } catch (e: any) {
      dispatch(
        setFeedback(`Import failed`, FeedbackStatus.ERROR, e.toString())
      );
    } finally {
      props.history.push("/alert-definitions");
    }
  };

  return (
    <Box color="text.default" data-testid="alert-definition-export" mx={3}>
      <Flex mb={2}>
        <Heading display={1}>Alert Definition Export</Heading>
      </Flex>
      <Flex flexDirection="column" bg="fill.0" p={3}>
        <SuspenseMutation>
          {({ loading, error, action }) => {
            return (
              <Form
                formData={formData}
                schema={schemaImport()}
                uiSchema={uiSchemaImport}
                disabled={loading}
                onSubmit={(
                  form: { formData: any },
                  e: React.FormEvent<HTMLInputElement>
                ) => action(() => submit(e, form.formData))}
                onChange={(form: any) => () =>
                  setFormData({ formData: form.formData })
                }
              >
                <CancelButton
                  onClick={() => props.history.push("/alert-definitions")}
                  mr={2}
                  mt={4}
                >
                  Cancel
                </CancelButton>

                <SaveButton ml={2} mt={4} type="submit" disabled={loading}>
                  Import
                </SaveButton>

                {error ? (
                  <Box my={2}>
                    <Text color="text.danger">
                      Error...{console.log(error)}
                    </Text>
                  </Box>
                ) : null}
              </Form>
            );
          }}
        </SuspenseMutation>
      </Flex>
    </Box>
  );
};

function handleToast(result: any, dispatch: any, type: ACTION) {
  const dsId = result?.data?.dataSourceId;
  if (dsId) {
    dispatch(
      setFeedback(`${dsId} ${type} successfully`, FeedbackStatus.SUCCESS)
    );
  }
  if (result?.data?.error) {
    const message = result.data?.message || result.data?.error;
    dispatch(
      setFeedback(`Import failed`, FeedbackStatus.ERROR, message?.toString())
    );
  }
}
