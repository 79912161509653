import React, { useState, useEffect } from "react";
import { Box, Heading, Flex, Text } from "primitives";
import { SuspenseQuery, SuspenseMutation, Suspense } from "app/network";
import { SaveButton, CancelButton } from "components";
import { Form } from "app/shared";
import { clone } from "utils";
import { userCreateSchema, userCreateUISchema } from "./UserCreateSchema";
import {
  getKeycloakGroups,
  addUserToKeycloakGroup,
  removeUserToKeycloakGroup
} from "../services";
import { Group } from "../models";
import { Link, RouteComponentProps } from "react-router-dom";
import { userList } from "./utils";

type RouteParams = {
  id: string;
};

export const UserEdit = (props: RouteComponentProps<RouteParams>) => {
  const [data, setData] = useState<any>(null);
  const [groups, setGroups] = useState<Array<Group>>([]);
  const [satellites, setSatellites] = useState<Array<string>>([]);

  const query = () => {
    const { id } = props.match.params;
    const user = userList.filter((u) => u.id === Number(id));
    const user0: any = user[0];
    const formData = {
      ...user[0],
      satellites: user0.satellites.map((s: any) => s.id)
    };
    return Promise.resolve(formData);
  };

  useEffect(() => {
    getKeycloakGroups().then((res: any) => setGroups(res.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = async (
    formData: any,
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const { id } = props.match.params;
    e.preventDefault();
    const initialSat = (await query()).satellites;
    setSatellites(initialSat);

    const satelliteIDsToAdd = formData.satellites.filter(
      (s: any) => !satellites.some((sat) => sat === s)
    );
    const satelliteIDsToRemove = satellites.filter(
      (s: any) => !formData.satellites.some((sat: any) => sat === s)
    );

    satelliteIDsToAdd.map((satId: string) =>
      addUserToKeycloakGroup(String(id), satId)
    );
    satelliteIDsToRemove.map((satId: string) =>
      removeUserToKeycloakGroup(String(id), satId)
    );
    props.history.push("/usermanagement");
  };

  return (
    <Suspense>
      <SuspenseQuery query={query}>
        {({ response }) => {
          return (
            response && (
              <SuspenseMutation>
                {({ loading, error, action }) => {
                  return (
                    <Box
                      color="text.default"
                      data-testid="DashboardCreate"
                      mx={3}
                    >
                      <Flex mb={2}>
                        <Heading display={1}>Edit user</Heading>
                      </Flex>
                      <Flex flexDirection="column" bg={"fill.0"} p={3}>
                        <Form
                          id="userEdit"
                          formData={data || response}
                          schema={userCreateSchema(groups)}
                          uiSchema={userCreateUISchema}
                          disabled={loading}
                          onChange={(form: any, e: any) => {
                            setData(clone(form.formData));
                          }}
                          onSubmit={(form: any, e: any) => {
                            action(() => submit(form.formData, e));
                          }}
                        >
                          <Flex justifyContent="space-betwen">
                            <Box width={1}></Box>
                            <>
                              <Link to="/usermanagement">
                                <CancelButton mr={2}>Cancel</CancelButton>
                              </Link>
                              <SaveButton type="submit" disabled={loading}>
                                Save
                              </SaveButton>
                            </>
                          </Flex>

                          {error ? (
                            <Box my={2}>
                              <Text color="text.danger">Error...</Text>
                            </Box>
                          ) : null}
                        </Form>
                      </Flex>
                    </Box>
                  );
                }}
              </SuspenseMutation>
            )
          );
        }}
      </SuspenseQuery>
    </Suspense>
  );
};
