import * as ActionTypes from "../models/constants";
import { AlertDTO } from "../models";

/** Fetch Alerts count */
export const fetchAlertCountStart = () => ({
  type: ActionTypes.FETCH_ALERT_COUNT_START
});

export const fetchAlertCountSuccess = (payload: any) => ({
  payload,
  type: ActionTypes.FETCH_ALERT_COUNT_SUCCESS
});

export const fetchAlertCountError = () => ({
  type: ActionTypes.FETCH_ALERT_COUNT_ERROR
});

/** Fetch Alerts */
export const fetchAlertsStart = () => ({
  type: ActionTypes.FETCH_ALERT_START
});

export const fetchAlertsSuccess = (payload: any) => ({
  payload,
  type: ActionTypes.FETCH_ALERT_SUCCESS
});

export const fetchAlertsError = (error: string) => ({
  error,
  type: ActionTypes.FETCH_ALERT_ERROR
});

/** Dismiss Alerts */
export const dismissAlertsStart = () => ({
  type: ActionTypes.DISMISS_ALERT_START
});

export const dismissAlertsSuccess = () => ({
  type: ActionTypes.DISMISS_ALERT_SUCCESS
});

export const dismissAlertsError = (payload: string) => ({
  payload,
  type: ActionTypes.DISMISS_ALERT_ERROR
});

/** Set Alerts */
export const setAlerts = (payload: AlertDTO[]) => ({
  payload,
  type: ActionTypes.SET_ALERTS
});
