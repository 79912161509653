import React, { SFC } from "react";
import { Text, Flex, Heading } from "primitives";
import { DataSourceReading, AuroraTelemetryFrame } from "app/telemetry/models";

export const FramePayloadBodyGrid: SFC<{ tmFrame: AuroraTelemetryFrame }> = ({
  tmFrame
}) => {
  const dataSources: DataSourceReading[] = tmFrame.extractReadings();
  return (
    <>
      {dataSources.length > 0 && (
        <>
          <Heading display={4} p={2} bg="fill.1">
            Data sources:
          </Heading>
          <Flex flexWrap="wrap">
            {dataSources.map((ds, i) => (
              <Flex
                key={i}
                p={2}
                flex="0 1 25%"
                maxWidth="25%"
                flexDirection="column"
              >
                <Text bold mb={1}>
                  {ds.name}:
                </Text>
                <Text wordBreak="break-word">
                  {ds.repr
                    ? Array.isArray(ds.repr)
                      ? ds.repr.join(", ")
                      : ds.repr
                    : ds.data}
                </Text>
              </Flex>
            ))}
          </Flex>
        </>
      )}
    </>
  );
};
