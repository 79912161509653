import React, { useEffect, useReducer } from "react";
import { Heading, Flex } from "primitives";
import {
  MasterKey,
  KeyManagerProps,
  KeyStatus,
  ContextActionsNames
} from "../models";
import styled from "styled-components";
import {
  MasterKeyDetail,
  ChangeStateDialog,
  InjectSessionKeyDialog
} from "../containers";
import { initialState, reducer, KeyManagerContext } from "../context";
import { MasterKeyList } from "./MasterKeyList";

const Container = styled("div")`
  background-color: #2e4162;
  padding: 20px 10px;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
`;
const KeyManagerRoot = styled("div")`
  display: flex;
  flex-flow: column;
  height: 100%;
`;
const HeightFiller = styled("div")`
  flex-grow: 1;
  display: flex;
`;

export const KeyManager = ({
  fetchAllMasterKey,
  fetchSessionKeys,
  masterKeys,
  selectedSatellite
}: KeyManagerProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { contextMenu, selectedMasterKey } = state;

  useEffect(() => {
    if (selectedSatellite?.id) {
      fetchAllMasterKey(selectedSatellite.id);
    }
  }, [selectedSatellite]);
  useEffect(() => {
    const initialSelected =
      masterKeys?.find((mk: MasterKey) => mk.status === KeyStatus.ACTIVE) ||
      masterKeys[0];
    dispatch({
      type: ContextActionsNames.selectedMasterKey,
      payload: initialSelected
    });
  }, [masterKeys]);
  useEffect(() => {
    if (selectedMasterKey) {
      fetchSessionKeys(selectedSatellite.id, {
        master_key_id: selectedMasterKey.key_id
      });
    }
  }, [selectedMasterKey]);

  return (
    <KeyManagerContext.Provider value={{ state, dispatch }}>
      <KeyManagerRoot data-testid={"key-manager-root"}>
        <Heading display={1} marginBottom={4}>
          Key Manager
        </Heading>
        <Container>
          <Heading display={2} marginBottom={4}>
            Master keys
          </Heading>
          <HeightFiller>
            <Flex padding={1} width={"100%"}>
              <MasterKeyList masterKeys={masterKeys} />
              <MasterKeyDetail />
            </Flex>
          </HeightFiller>
        </Container>
        {contextMenu}
        <ChangeStateDialog />
        <InjectSessionKeyDialog />
      </KeyManagerRoot>
    </KeyManagerContext.Provider>
  );
};
