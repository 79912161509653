import { InputBase, SelectBox } from "app/shared";
import { FileWidget } from "app/shared/form";
import { CheckBox, InputSimple } from "components";
import { AlertDefinitionDTO } from ".";
import { AlertDefinitionEditor } from "../components/AlertsEditor";
export const schemaCreate = (datasources: any = []) => {
  return {
    type: "object",
    required: ["dataSourceId"],
    properties: {
      alertType: {
        type: "string",
        title: "Alert Type",
        enum: ["DynamicAlert"],
        enumNames: ["Dynamic Alert"],
        default: "DynamicAlert"
      },
      dataSourceId: {
        searchableList: true,
        type: "number",
        title: "Main datasource to raise alert on",
        enum: datasources.map((ds: any) => ds.id),
        enumNames: datasources.map((ds: any) => ds.name)
      },
      ...schemaConfig().properties
    }
  };
};

export const schemaConfig = () => {
  return {
    type: "object",
    required: ["dataSourceId"],
    properties: {
      alertConfigs: {
        type: "array",
        hasRemove: true,
        items: {
          type: "object",
          properties: {
            name: {
              type: "string",
              title: "Name for the alert configuration",
              default: ""
            },
            severity: {
              type: "string",
              title: "Alert level",
              enum: ["Warning", "Critical"],
              enumNames: ["WARNING", "CRITICAL"],
              default: "WARNING"
            },
            condition: {
              type: "string",
              title: "Alert Conditions "
            },
            applyToAllIndexes: {
              type: "boolean",
              title: "Apply condition to all datasource indexes",
              default: true
            },
            instantReference: {
              title: "Multi parameter check",
              type: "string",
              description: "Add contraints for multiple param checks",
              enumNames: [
                "value received at the same second-precision time",
                "Last value received"
              ],
              enum: ["SAME_SECOND", "LAST_VALUE"],
              default: "SAME_SECOND"
            },
            notificationPolicy: {
              title: "Notification Policy",
              description: "Choose an action to take when alert is triggered",
              type: "object",
              properties: {
                notifyOnTeams: {
                  title: "Notify on MS Teams",
                  type: "boolean",
                  default: true
                },
                notifyByEmail: {
                  title: "Notify through an email",
                  type: "boolean",
                  default: false
                },
                playSoundOnFE: {
                  title: "Play a warning sound on Aurora Frontend",
                  type: "boolean",
                  default: false
                }
              }
            }
          }
        }
      }
    }
  };
};

export const uiSchemaConfigs = {
  alertConfigs: {
    items: {
      name: { "ui:widget": InputBase },
      severity: { "ui:widget": SelectBox },
      condition: { "ui:widget": AlertDefinitionEditor },
      applyToAllIndexes: { "ui:widget": CheckBox },
      instantReference: { "ui:widget": SelectBox },
      notificationPolicy: {
        notifyOnTeams: { "ui:widget": CheckBox },
        notifyByEmail: { "ui:widget": CheckBox },
        playSoundOnFE: { "ui:widget": CheckBox }
      }
    }
  }
};

export const uiSchemaCreate = {
  alertType: { "ui:widget": SelectBox },
  dataSourceId: { "ui:widget": SelectBox },
  ...uiSchemaConfigs
};

export const schemaEdit = () => {
  return {
    type: "object",
    properties: {
      id: {
        type: "number",
        title: "Id",
        kind: "constant"
      },
      dataSourceId: {
        type: "string",
        title: "Main datasource to raise alert on"
      },
      ...schemaConfig().properties
    }
  };
};

export const uiSchemaEdit = {
  id: { "ui:widget": "hidden" },
  dataSourceId: { "ui:widget": InputBase, "ui:disabled": true },
  ...uiSchemaConfigs
};

export const uiSchemaImport = {
  file: {
    "ui:widget": FileWidget
  }
};

export const schemaImport = () => ({
  type: "object",
  properties: {
    file: {
      title: " ",
      type: "string"
    }
  }
});

export const schemaExport = (alertDefinitions: AlertDefinitionDTO[] = []) => {
  return {
    type: "object",
    properties: {
      datasourceName: {
        type: "string",
        title: "alertDefinitions",
        enum: [
          "ALL",
          ...alertDefinitions.map((d: AlertDefinitionDTO) => d.datasourceName)
        ],
        enumNames: [
          "ALL",
          ...alertDefinitions.map((d: AlertDefinitionDTO) => d.datasourceName)
        ]
      },
      ...({
        exportAll: {
          type: "boolean",
          title: "Export all available alert definition for current satellite",
          default: false
        }
      } || {})
    }
  };
};

export const uiSchemaExport = {
  datasourceName: { "ui:widget": SelectBox },
  ...({
    exportAll: { "ui:widget": CheckBox }
  } || {})
};

export const schemaDeleteAll = {
  type: "object",
  title: "Delete all alert definitions?",
  properties: {
    confirmDeleteAll: {
      type: "boolean",
      title: "Are you sure you want to delete all alert definitions?",
      description:
        "Deletion is a one-way action. You won't be able to recover the deleted alert definitions",
      default: false
    }
  }
};

export const uiSchemaDeleteAll = {
  confirmDeleteAll: { "ui:widget": CheckBox }
};
