import React, { Component } from "react";
import { Box, Heading, Flex, Text } from "primitives";
import { Link } from "react-router-dom";
import { SuspenseQuery, Suspense } from "app/network";
import { AddButton, EditButton, DownloadButton } from "components";
import { DashboardDelete } from "./DashboardDelete";
import { DataProviderResponse } from "app/network/models";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { LoadingError } from "app/network";
import { FeedbackStatus } from "app/feedback/models";
import { Dashboard } from "../models";
import { RouteComponentProps } from "react-router-dom";

interface DashboardListProps extends RouteComponentProps {
  getUserDashboardsLists: (
    satelliteDefinitionId: number | string | null
  ) => Promise<DataProviderResponse>;
  updateUserDashboardsLists: (
    satelliteDefinitionId: number | string | null,
    data: any
  ) => Promise<DataProviderResponse>;
  getUserDashboards: (
    satelliteDefinitionId: number | string | null
  ) => Promise<DataProviderResponse>;
  addDashboards: (dashboards: any) => Promise<DataProviderResponse>;
  selectedSatellite: any;
  setFeedback: (title: string, status: FeedbackStatus) => void;
}

interface DashboardListState {
  selectedSatelliteDefinitionId: number | string | null;
  data: any;
  shouldReload: boolean;
}

interface Error {
  status: number;
}

// a little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (
  source: any[],
  destination: any[],
  droppableSource: any,
  droppableDestination: any
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: any = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? "#50678A" : "#34435a",
  ...draggableStyle
});

const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? "#6a7e9c" : "#54657f",
  padding: grid,
  maxWidth: 400,
  minWidth: 350,
  height: "100%",
  margin: "10px 20px"
});

const DashboardListHeader = () => {
  return (
    <Flex alignItems="center" justifyContent="space-between" mb={2} mx={3}>
      <Heading display={1}>Dashboards</Heading>
      <Flex>
        <Link to="/dashboard/create">
          <AddButton>Create</AddButton>
        </Link>
        <Link
          to={{
            pathname: `/dashboards/importexport`,
            state: { type: "Import" }
          }}
        >
          <AddButton ml={2}>Import</AddButton>
        </Link>
        <Link
          to={{
            pathname: `/dashboards/importexport`,
            state: { type: "Export" }
          }}
        >
          <DownloadButton ml={2}>Export</DownloadButton>
        </Link>
      </Flex>
    </Flex>
  );
};

interface ErrorProps {
  shouldReload: boolean;
  reload: () => void;
  error: { status: number; error?: any; message?: any };
  setShouldReloadFalse: () => void;
}

const Error = ({
  shouldReload,
  reload,
  error,
  setShouldReloadFalse
}: ErrorProps) => {
  const isDuplicatedReq = error?.error === "Duplicated request aborted.";
  if ((shouldReload && reload) || error.error?.code == 20 || isDuplicatedReq) {
    setShouldReloadFalse();
    reload();
    return null;
  }
  console.error(error?.message);
  return <LoadingError httpErrorStatus={error.status} />;
};

export class DashboardList extends Component<
  DashboardListProps,
  DashboardListState
> {
  state = {
    selectedSatelliteDefinitionId: null,
    data: null,
    shouldReload: false
  };

  query = (): Promise<DataProviderResponse> => {
    const { selectedSatelliteDefinitionId } = this.state;
    return this.props.getUserDashboardsLists(selectedSatelliteDefinitionId);
  };

  componentWillMount() {
    const { selectedSatellite } = this.props;
    if (selectedSatellite && selectedSatellite.satelliteDefinitionSummary) {
      this.props
        .getUserDashboards(
          selectedSatellite.satelliteDefinitionSummary.satelliteDefinitionId
        )
        .then(({ data }) => {
          this.props.addDashboards(data || []);
        });
    }
  }

  componentDidMount() {
    const { selectedSatellite } = this.props;
    if (selectedSatellite) {
      this.setState({
        selectedSatelliteDefinitionId:
          selectedSatellite.satelliteDefinitionSummary.satelliteDefinitionId
      });
    }
  }

  componentDidUpdate(prevProps: DashboardListProps) {
    const { selectedSatellite } = this.props;
    const { selectedSatelliteDefinitionId } = this.state;
    if (
      selectedSatellite &&
      prevProps.selectedSatellite &&
      prevProps.selectedSatellite.satelliteDefinitionSummary
        .satelliteDefinitionId !==
        selectedSatellite.satelliteDefinitionSummary.satelliteDefinitionId &&
      selectedSatelliteDefinitionId !==
        selectedSatellite.satelliteDefinitionSummary.satelliteDefinitionId
    ) {
      this.setState({
        selectedSatelliteDefinitionId:
          selectedSatellite.satelliteDefinitionSummary.satelliteDefinitionId,
        shouldReload: true,
        data: null
      });
    }
  }

  /**
   * A semi-generic way to handle multiple lists. Matches
   * the IDs of the droppable container to the names of the
   * source arrays stored in the state.
   */
  id2List = {
    listed: "listed",
    unlisted: "unlisted"
  };

  getList = (id: string, data: any) => (data as any)[(this.id2List as any)[id]];

  showErrorFeedback(e: Error) {
    if (e.status === 401 || e.status === 403) {
      this.props.setFeedback("Unauthorized", FeedbackStatus.ERROR);
    }
  }

  onDragEnd = (result: any, newData: any) => {
    const { source, destination } = result;
    const { selectedSatelliteDefinitionId } = this.state;
    const { selectedSatellite } = this.props;

    // dropped outside the list
    if (!destination || selectedSatelliteDefinitionId === null) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const listed = reorder(
        this.getList(source.droppableId, newData),
        source.index,
        destination.index
      );
      if (source.droppableId === "unlisted") {
        return null;
      }
      const prevData = { ...newData };
      newData.listed = listed;
      this.setState({ data: newData });
      const updatedData = { listed: listed.map((d: any) => d.id) };
      this.props
        .updateUserDashboardsLists(selectedSatelliteDefinitionId, updatedData)
        .then((response) => {
          this.setState({ data: response.data });
          if (selectedSatellite) {
            this.props
              .getUserDashboards(
                selectedSatellite.satelliteDefinitionSummary
                  .satelliteDefinitionId
              )
              .then(({ data }) => {
                this.props.addDashboards(data || []);
              });
          }
        })
        .catch((e: Error) => {
          console.log("TURBO >>> ~ e:", e);
          this.setState({ data: prevData });
          this.showErrorFeedback(e);
        });
    } else {
      const moveResult = move(
        this.getList(source.droppableId, newData),
        this.getList(destination.droppableId, newData),
        source,
        destination
      );
      this.setState({ data: moveResult });
      const updatedData = { listed: moveResult.listed.map((d: any) => d.id) };
      this.props
        .updateUserDashboardsLists(selectedSatelliteDefinitionId, updatedData)
        .then((response) => {
          this.setState({ data: response.data });
          if (selectedSatellite) {
            this.props
              .getUserDashboards(
                selectedSatellite.satelliteDefinitionSummary
                  .satelliteDefinitionId
              )
              .then(({ data }) => {
                this.props.addDashboards(data || []);
              });
          }
        })
        .catch((e: Error) => {
          this.setState({ data: newData });
          this.showErrorFeedback(e);
        });
    }
  };

  selectSatellite = (e: React.ChangeEvent<HTMLSelectElement>) =>
    this.setState({
      data: null,
      selectedSatelliteDefinitionId: parseInt(e.target.value),
      shouldReload: true
    });

  setShouldReloadFalse = () => {
    this.setState({ shouldReload: false });
  };

  render() {
    const { selectedSatelliteDefinitionId, data, shouldReload } = this.state;
    return (
      selectedSatelliteDefinitionId && (
        <>
          <DashboardListHeader />
          <Suspense>
            <SuspenseQuery errorFallback={true} query={this.query}>
              {({ response, error, reload }) => {
                const { listed, unlisted } =
                  data && !Array.isArray(data)
                    ? data
                    : response
                    ? response.data
                    : "";
                return error ? (
                  <Error
                    reload={reload}
                    error={error}
                    shouldReload={shouldReload}
                    setShouldReloadFalse={this.setShouldReloadFalse}
                  />
                ) : (
                  <Flex flexDirection="column">
                    {shouldReload
                      ? this.setState({ shouldReload: false }, () => reload())
                      : ""}
                    <DragDropContext
                      onDragEnd={(result: any) =>
                        this.onDragEnd(result, data ? data : response.data)
                      }
                    >
                      <Flex mx="auto">
                        <Flex flexDirection="column" overflow="visible">
                          <Flex overflow="visible" justifyContent="center">
                            <Text fontSize={18} bold color="text.default">
                              Listed
                            </Text>
                          </Flex>
                          <Droppable droppableId="listed">
                            {(provided: any, snapshot: any) => (
                              <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                              >
                                {listed &&
                                  listed.map((item: any, index: number) => (
                                    <Draggable
                                      key={item.id}
                                      draggableId={item.id}
                                      index={index}
                                    >
                                      {(
                                        draggableProvided: any,
                                        draggableSnapshot: any
                                      ) => (
                                        <Flex
                                          ref={draggableProvided.innerRef}
                                          {...draggableProvided.draggableProps}
                                          {...draggableProvided.dragHandleProps}
                                          style={getItemStyle(
                                            draggableSnapshot.isDragging,
                                            draggableProvided.draggableProps
                                              .style
                                          )}
                                        >
                                          <Flex alignItems="center">
                                            <Box mr={1} mb={1}>
                                              <Text color="text.default">
                                                ::
                                              </Text>
                                            </Box>
                                            {item.name}
                                          </Flex>
                                          <Flex
                                            marginLeft="auto"
                                            overflow="visible"
                                            alignItems="center"
                                          >
                                            <Box
                                              mr={2}
                                              ml={3}
                                              overflow="visible"
                                            >
                                              <Link
                                                to={`/dashboard/edit/${item.id}`}
                                              >
                                                <EditButton overflow="visible">
                                                  Edit
                                                </EditButton>
                                              </Link>
                                            </Box>
                                            <DashboardDelete
                                              dashboard={item}
                                              onChange={() => {
                                                const filtered = (
                                                  data ? data : response.data
                                                ).listed.filter(
                                                  (d: Dashboard) =>
                                                    d.id !== item.id
                                                );
                                                this.setState({
                                                  data: {
                                                    listed: filtered,
                                                    unlisted:
                                                      (data &&
                                                        (
                                                          data as {
                                                            unlisted: Dashboard[];
                                                          }
                                                        ).unlisted) ||
                                                      response.data.unlisted
                                                  }
                                                });
                                              }}
                                            />
                                          </Flex>
                                        </Flex>
                                      )}
                                    </Draggable>
                                  ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </Flex>
                        <Flex flexDirection="column" overflow="visible">
                          <Flex overflow="visible" justifyContent="center">
                            <Text fontSize={18} bold color="text.default">
                              Unlisted
                            </Text>
                          </Flex>
                          <Droppable droppableId="unlisted">
                            {(provided: any, snapshot: any) => (
                              <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                              >
                                {unlisted &&
                                  unlisted.map((item: any, index: number) => (
                                    <Draggable
                                      key={item.id}
                                      draggableId={item.id}
                                      index={index}
                                    >
                                      {(
                                        draggableProvided: any,
                                        draggableSnapshot: any
                                      ) => (
                                        <Flex
                                          ref={draggableProvided.innerRef}
                                          {...draggableProvided.draggableProps}
                                          {...draggableProvided.dragHandleProps}
                                          style={getItemStyle(
                                            draggableSnapshot.isDragging,
                                            draggableProvided.draggableProps
                                              .style
                                          )}
                                        >
                                          <Flex alignItems="center">
                                            {item.name}
                                          </Flex>
                                          <Flex
                                            marginLeft="auto"
                                            overflow="visible"
                                            alignItems="center"
                                          >
                                            <Box
                                              mr={2}
                                              ml={3}
                                              overflow="visible"
                                            >
                                              <Link
                                                to={`/dashboard/edit/${item.id}`}
                                              >
                                                <EditButton overflow="visible">
                                                  Edit
                                                </EditButton>
                                              </Link>
                                            </Box>
                                            <DashboardDelete
                                              dashboard={item}
                                              onChange={() => {
                                                const filtered = (
                                                  data ? data : response.data
                                                ).unlisted.filter(
                                                  (d: Dashboard) =>
                                                    d.id !== item.id
                                                );
                                                this.setState({
                                                  data: {
                                                    listed:
                                                      (data &&
                                                        (
                                                          data as {
                                                            listed: Dashboard[];
                                                          }
                                                        ).listed) ||
                                                      response.data.listed,
                                                    unlisted: filtered
                                                  }
                                                });
                                              }}
                                            />
                                          </Flex>
                                        </Flex>
                                      )}
                                    </Draggable>
                                  ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </Flex>
                      </Flex>
                    </DragDropContext>
                  </Flex>
                );
              }}
            </SuspenseQuery>
          </Suspense>
        </>
      )
    );
  }
}
