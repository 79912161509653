import { Buttons } from "styled-components";
import colors from "./palette";
import { rgba } from "polished";

const buttons: Buttons = {
  default: {
    color: colors.text.default,
    backgroundColor: colors.fill[0],
    "&:hover:not(:disabled)": {
      backgroundColor: colors.fill[1]
    },
    borderColor: colors.border[0]
  },
  cancel: {
    color: colors.text.default,
    backgroundColor: colors.palette.white[0],
    "&:hover:not(:disabled)": {
      backgroundColor: colors.palette.grey[0]
    },
    borderColor: colors.border[0]
  },
  success: {
    color: colors.palette.white[0],
    backgroundColor: colors.palette.green[0],
    "&:hover:not(:disabled)": {
      backgroundColor: colors.palette.green[1]
    },
    borderColor: colors.border[0]
  },
  danger: {
    color: colors.palette.white[0],
    backgroundColor: colors.palette.red[0],
    "&:hover:not(:disabled)": {
      backgroundColor: colors.palette.red[1]
    },
    borderColor: colors.border[0]
  },
  outline: {
    color: colors.text.default,
    backgroundColor: "transparent",
    "&:hover:not(:disabled)": {
      backgroundColor: rgba(colors.palette.brand[0], 0.1)
    },
    borderColor: colors.border[0]
  }
};

export default buttons;
