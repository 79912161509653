import React, { useState, useEffect } from "react";
import { Box, Text, Button } from "primitives";
import { Table, TableHead, TableBody, TableRow, TableCell } from "components";
import { Link } from "react-router-dom";
import { PaginationSimple } from "app/shared";
import { useSelector, useDispatch } from "react-redux";
import { Candidate } from "app/passageManager/models";
import { getCandidateStatus, Status } from "./Status";
import { BookCandidateContainer } from "../BookCandidate/BookCandidate";
import { CancelPassage } from "../BookCandidate/CancelPassage";
import { syncBookedAndAvailablePassagesAction } from "app/passageManager/actions";
import { data } from "app/telemetry/test/mockdata";

export const CandidatesTable = () => {
  const dispatch = useDispatch();
  const { config, allPassages } = useSelector((s: any) => s.passageManager);

  useEffect(() => {
    const from = new Date();
    const to = new Date(new Date().setMonth(new Date().getMonth() + 2));
    const satIds = Object.keys(config?.groundStationsBySatelliteIDs || {}).map(
      Number
    );
    dispatch(
      syncBookedAndAvailablePassagesAction(satIds, from, to, config?.id)
    );
  }, []);

  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(allPassages?.total);

  return (
    <Box
      data-testid="passage-manager-list"
      className="passage-manager-list"
      mx={3}
    >
      {config && (
        <Text m={1} fontSize={5} bold color="text.default">
          {" "}
          CANDIDATES FETCHED USING CONFIG: {config?.name}{" "}
        </Text>
      )}
      <Table>
        <TableHead>
          <TableRow bg="fill.0">
            <TableCell width="10" align="center">
              id
            </TableCell>
            <TableCell width="20" align="center">
              Start
            </TableCell>
            <TableCell width="20" align="center">
              End
            </TableCell>
            <TableCell width="10" align="center">
              GS
            </TableCell>
            <TableCell width="5" align="center">
              Max Elevation
            </TableCell>
            <TableCell width="15" align="center">
              Satellite
            </TableCell>
            <TableCell width="10" align="center">
              Provider
            </TableCell>
            <TableCell width="35" align="center">
              Status
            </TableCell>
            <TableCell width="5" align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(allPassages) &&
            allPassages.length > 0 &&
            allPassages.map((candidate: Candidate, idx: number) => {
              const { bookable, status } = getCandidateStatus(candidate);
              return (
                <TableRow key={candidate.passageID}>
                  <TableCell width="2%">
                    <Link
                      to={{ pathname: `/edit-passage`, state: { candidate } }}
                    ></Link>
                    {candidate.passageID}
                  </TableCell>
                  <TableCell width="1%">
                    {new Date(candidate.aos).toLocaleString()}
                  </TableCell>
                  <TableCell width="auto">
                    {new Date(candidate.los).toLocaleString()}
                  </TableCell>
                  <TableCell>{candidate.groundStationName}</TableCell>
                  <TableCell>{candidate.maxElevation}</TableCell>
                  <TableCell align="center">
                    {candidate.satellite || candidate?.satelliteID}
                  </TableCell>
                  <TableCell align="center" width="4%">
                    {candidate.provider}
                  </TableCell>
                  <TableCell align="center" width="5%">
                    <Status status={status} bookable={bookable} />
                  </TableCell>
                  <TableCell align="center" width="1%">
                    {status !== "BOOKED" ? (
                      <BookCandidateContainer
                        candidate={candidate}
                        bookingConfigId={config?.id}
                        bookable={bookable}
                      />
                    ) : (
                      <CancelPassage candidate={candidate} config={config} />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <PaginationSimple
        total={total}
        page={page + 1}
        pageSize={pageSize}
        nextPageUrl={(page + 1) * pageSize < total ? page + 1 : 0}
        previousPageUrl={page - 1}
        onChange={(newPage: string | number) => {
          setPage(Number(newPage));
          setPageSize(pageSize);
        }}
        onPageSizeChange={(newPageSize: number) => {
          setPage(0);
          setPageSize(Number(newPageSize));
        }}
      />
    </Box>
  );
};
