import styled from "styled-components";
import { Flex } from "primitives";

const SelectContainer = styled(Flex)``;

SelectContainer.defaultProps = {
  bg: "fill.0"
};

export default SelectContainer;
