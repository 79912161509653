import React, { useRef } from "react";
import styled from "styled-components";
import { DATA_TEST_ID_ATTR_NAME } from "components/testable/models";
import { Text } from "primitives";
import ReactDOM from "react-dom";
import Transition from "react-transition-group/Transition";
import { themeGet } from "styled-system";

interface DialogProps {
  open: boolean;
  children: React.ReactNode;
  fullWidth?: boolean;
  maxWidth?: string;
  overflow?: string;
  height?: string;
}

const DialogBox = styled.div<any>`
  background: #2e4162 !important;
  max-width: ${(props) =>
    props.maxWidth === "sm"
      ? "600px"
      : props.maxWidth === "md"
      ? "960px"
      : props.maxWidth === "lg"
      ? "1280px"
      : props.maxWidth === "xl"
      ? "1920px"
      : "600px"};
  width: ${(props) => (props.fullWidth ? "100%" : "calc(100% - 64px)")};
  flex: 0 1 auto;
  max-height: calc(100% - 96px);
  margin: 48px;
  display: flex;
  outline: none;
  overflow-y: auto;
  flex-direction: column;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  border-radius: 4px;
  overflow: ${(props) => (props.overflow ? props.overflow : "auto")};
  position: relative;
  height: ${(props) => (props.height ? props.height : "auto")}
}
`;

const DialogOuter = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1300;
  position: fixed;
}
`;

export const DialogContainer = styled.div<any>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  position: fixed;
  touch-action: none;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
}
`;

const duration = 195;

const defaultStyle = {
  transition: `opacity ${duration}ms cubic-bezier(0.4, 0, 0.2, 1)`,
  opacity: 0
};

const transitionStyles: any = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 }
};

export const Dialog = ({ open, children, ...props }: DialogProps) => {
  const nodeRef = useRef<any>(null);

  if (!open) return null;

  return ReactDOM.createPortal(
    <Transition
      nodeRef={nodeRef}
      timeout={{ appear: 0, enter: 195, exit: 195 }}
      in={open}
      appear={true}
    >
      {(state) => {
        return (
          <DialogOuter
            ref={nodeRef}
            style={{
              ...defaultStyle,
              ...transitionStyles[state]
            }}
            open={open}
            {...props}
          >
            <DialogContainer {...props}></DialogContainer>
            <DialogBox {...props}>{children}</DialogBox>
          </DialogOuter>
        );
      }}
    </Transition>,
    document.body
  );
};

Dialog.displayName = "Dialog";
Dialog.defaultProps = {
  variant: "default",
  type: "div",
  [DATA_TEST_ID_ATTR_NAME]: Dialog.displayName
};

export default DialogContainer;

const DialogText = styled(Text)<any>`
  color: ${themeGet("colors.text.default")};
  font-size: 1.3125rem;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.16667em;
`;

const TextContainer = styled.div<any>`
  flex: 0 0 auto;
  color: ${themeGet("colors.text.default")};
  margin: 0;
  padding: 24px 24px 20px;
`;

export const DialogTitle = ({ children }: { children: React.ReactNode }) => (
  <TextContainer>
    <DialogText>{children}</DialogText>
  </TextContainer>
);

const ActionsContainer = styled.div<any>`
  flex: 0 0 auto;
  margin: 0 !important;
  display: flex;
  padding: 8px !important;
  align-items: center;
  justify-content: flex-end;
`;

export const DialogActions = ({ children }: { children: React.ReactNode }) => (
  <ActionsContainer>{children}</ActionsContainer>
);

const ContentContainer = styled.div<any>`
  flex: 1 1 auto;
  padding: 0 24px 24px;
  overflow: ${(props) => (props.overflow ? "visible" : "auto")};
`;

export const DialogContent = ({
  children,
  ...props
}: {
  children: React.ReactNode;
  overflow?: boolean;
}) => <ContentContainer {...props}>{children}</ContentContainer>;
