import {
  dataProvider,
  visibilityDataProvider,
  GET_ONE,
  GET_LIST,
  UPDATE,
  CREATE,
  DELETE
} from "app/network";
import { store } from "app/store";
import * as actions from "../actions";
// import { createKeycloakGroup } from "app/user/services";
// import { sendMessage } from "utils/sendMessageToSW";
export const getSatelliteDefinitions = (pagination, type) => {
  if (type.summary) {
    return dataProvider(GET_LIST, `satellite-definition?summary=true`);
  }
  return dataProvider(GET_LIST, `satellite-definition`, {
    pagination
  });
};

export const getSatelliteDefinition = (id) => {
  store.dispatch(actions.fetchSatelliteDefinitionStart());

  return dataProvider(GET_ONE, `satellite-definition`, { id })
    .then(({ data }) => {
      store.dispatch(actions.fetchSatelliteDefinitionSuccess(data));
      return data;
    })
    .catch((error) => {
      store.dispatch(actions.fetchSatelliteDefinitionError());
      throw error;
    });
};

export const getDataSources = (id) => {
  return dataProvider(GET_ONE, `satellite-definition/${id}/datasources`);
};

export const updateSatelliteDefinition = (data) => {
  const { id, systems, availableTelecommands, ...satelliteDefinition } = data;

  satelliteDefinition.classes = data.classes
    ? data.classes.map((element) => element.name)
    : [];

  return dataProvider(UPDATE, `satellite-definition`, {
    id,
    data: satelliteDefinition
  });
};

export const createSatelliteDefinition = async (data) => {
  const { id, ...satelliteDefinition } = data;

  satelliteDefinition.classes = data.classes
    ? data.classes.map((element) => element.name)
    : [];

  // await createKeycloakGroup(data.name);
  // sendMessage("CLEAR_CACHE");

  return dataProvider(CREATE, `satellite-definition`, {
    data: satelliteDefinition
  });
};

export const deleteSatelliteDefinition = ({ id }) => {
  return dataProvider(DELETE, `satellite-definition`, { id });
};

export const getTLE = (satelliteID) => {
  return visibilityDataProvider(GET_ONE, `satellites/${satelliteID}/tle`)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      return null;
    });
};

export const setTLE = (satelliteID, data) => {
  return visibilityDataProvider(UPDATE, `satellites/${satelliteID}/tle`, {
    data
  })
    .then(({ res }) => {
      return res;
    })
    .catch((error) => {
      return null;
    });
};

export const getCZML = (satelliteID) => {
  return visibilityDataProvider(GET_ONE, `satellites/${satelliteID}/tle/czml`)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      return null;
    });
};
