import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Flex } from "primitives";
import { Form } from "app/shared";
import {
  calculateToDate,
  createEditFormData,
  mergeFormData,
  QuickRange
} from "app/passageManager/utils";
import { bookerSchemaEdit, createbookerUISchema } from "./bookerSchema";
import { defaultStartTime } from "app/passageManager/services/candidates";

interface BookerFormProps {
  type: string;
  readonly: boolean;
  submit: any;
  formRef: any;
  config: any;
}
export const BookerForm = ({
  type,
  readonly,
  submit,
  formRef,
  config
}: BookerFormProps) => {
  const [selectedConfigId, setSelectedConfigId] = useState(config);
  const [lastFormData, setFormData] = useState<any>({});
  const [initialFormData, setInitialFormData] = useState<any>({});

  const satellites = useSelector((s: any) => s.constellations.dashboard);
  const groundStations = useSelector(
    (s: any) => s.groundStations?.groundStations
  );

  const { data: configs } = useSelector(
    (s: any) => s.passageManager.bookerConfigs
  );

  const transformData = (formData: any): any => {
    if (
      formData.quickrange &&
      Object.values(QuickRange).includes(formData.quickrange as QuickRange)
    ) {
      formData.daterange = {
        from: defaultStartTime,
        to: calculateToDate(formData.quickrange as QuickRange).toISOString()
      };
    } else if (formData.daterange?.from && formData.daterange?.to) {
      formData.daterange = {
        from: new Date(formData.daterange.from).toISOString(),
        to: new Date(formData.daterange.to).toISOString()
      };
    }
    return formData;
  };

  const onChange = useCallback(
    ({ formData }) => {
      const configId = formData?.configuration || selectedConfigId?.id;
      setSelectedConfigId(configId);
      const selectedConfig = configs?.find((pc: any) => pc.id === configId);

      if (configId) {
        const formattedData = createEditFormData(
          selectedConfig,
          satellites,
          groundStations
        );
        setInitialFormData(formattedData);
        let mergedFormData = {
          ...mergeFormData(formData, formattedData),
          ...formattedData,
          daterange: {
            from: formData.daterange.from || formattedData.daterange.from,
            to: formData.daterange.to || formattedData.daterange.from
          }
        };
        setFormData(mergedFormData);
        // TODO: fix date formatting
        // setFormData(fmtDateRange(mergedFormData));
      } else {
        setFormData(formData);
      }
    },
    [configs, satellites, groundStations, selectedConfigId]
  );

  const { sats, gs } = getSatGSFromFrom(initialFormData, lastFormData);
  return (
    <Flex flexDirection="column" bg="fill.0" p={5}>
      <Form
        ref={formRef}
        noValidate
        id="passage-manager"
        formData={transformData(lastFormData)}
        schema={bookerSchemaEdit(sats, gs, type, configs, selectedConfigId)}
        uiSchema={createbookerUISchema(readonly)}
        disabled={false}
        onSubmit={(
          form: { formData: any },
          e: React.FormEvent<HTMLInputElement>
        ) => {
          return submit(e, form.formData);
        }}
        onChange={(form: any) => onChange(form)}
      >
        <></>
      </Form>
    </Flex>
  );
};

function getSatGSFromFrom(initialFormData: any, lastFormData: any) {
  const GsBySatMap =
    initialFormData?.groundStationsBySatellite ||
    lastFormData?.groundStationsBySatellite;

  const gs = [
    ...new Set(
      GsBySatMap?.map((m: any) => ({
        groundStationName: m.groundStations
      })).flat()
    )
  ];
  const sats = [
    ...new Set(GsBySatMap?.map((m: any) => ({ label: m.satellite })))
  ];

  return { gs, sats };
}
