import React, { Component } from "react";
import { deleteSystemDefinition } from "../services";
import { DeleteButton, CancelButton } from "components";
import { Flex, Dialog, DialogTitle, DialogActions } from "primitives";

export class SystemDefinitionDelete extends Component {
  state = {
    modalOpen: false
  };

  onChange(event) {
    const { name, value } = event.currentTarget;
    this.setState({ [name]: value });
  }

  hideDialog = () => {
    this.setState({ modalOpen: false });
  };

  openDialog = () => {
    this.setState({ modalOpen: true });
  };

  handleSubmit = () => {
    const { parentRecord, record } = this.props;
    deleteSystemDefinition(parentRecord, record)
      .then(() => {
        this.props.onDelete();
        this.hideDialog();
      })
      .catch((error) => {
        //this.props.showNotification(error.message, "error");
      });
  };

  render() {
    const { record } = this.props;
    const { modalOpen } = this.state;

    return (
      <>
        <DeleteButton
          onClick={() => this.openDialog()}
          mr={1}
          title="Delete System"
          height={27}
        />
        <Dialog
          open={modalOpen}
          maxWidth="sm"
          data-testid="SystemDefinitionDelete"
        >
          <DialogTitle>Delete {record.name}?</DialogTitle>

          <DialogActions>
            <Flex
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <DeleteButton onClick={(e) => this.handleSubmit(e)} mr={1}>
                Delete
              </DeleteButton>
              <CancelButton onClick={() => this.hideDialog()} mr={1}>
                Cancel
              </CancelButton>
            </Flex>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
