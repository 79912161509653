import React, { Component, createRef } from "react";
import { Relative } from "primitives";
import {
  clearReadings,
  getGraphOptions,
  graphFormatData,
  isCorrectData
} from "./LineDataHelpers";
import { LineChartContainer } from "./LineChartContainer";
import { getXDomain, getYDomain } from "./utils/options";
import { clone } from "utils";
import { LineChartDataState, LineChartDataProps } from "./models";

export class LineChartData extends Component<
  LineChartDataProps,
  LineChartDataState
> {
  private containerRef: any = createRef<HTMLDivElement>();

  state: LineChartDataState = {
    formatedDataList: [],
    refresh: false
  };

  componentWillReceiveProps(nextProps: LineChartDataProps) {
    const {
      datasourceReading,
      options,
      firstFetch,
      setFirstFetch,
      timeController
    } = nextProps;
    const { formatedDataList, refresh } = this.state;
    if (datasourceReading) {
      let newFormatedDataList = graphFormatData(
        datasourceReading,
        options,
        firstFetch ? clearReadings(clone(formatedDataList)) : formatedDataList,
        timeController
      );
      if (
        newFormatedDataList &&
        newFormatedDataList.some((ds, index) => ds.key !== index)
      ) {
        newFormatedDataList = [];
        if (!refresh) {
          this.setState({ refresh: true }, () =>
            this.setState({ refresh: false })
          );
        }
      }
      this.setState(
        {
          formatedDataList: newFormatedDataList
        },
        () => firstFetch && setFirstFetch(false)
      );
    }
  }

  render() {
    const { formatedDataList, refresh } = this.state;
    const { height, options, setPaused, timeController, id } = this.props;
    const { showAxis, showLegend, showGrid, hasBoundAxis } = getGraphOptions(
      this.props
    );

    return refresh ? null : (
      <Relative
        data-testid="real-time-graph"
        height={height || "calc(100% - 4px)"}
        overflow="visible"
        bg={"palette.grey.5"}
        ref={this.containerRef}
      >
        <LineChartContainer
          data={
            isCorrectData(options, formatedDataList) ? formatedDataList : []
          }
          xDomain={getXDomain(options, timeController)}
          yDomain={getYDomain(options)}
          showLegend={showLegend}
          showGrid={showGrid}
          showAxis={showAxis}
          hasBoundAxis={hasBoundAxis}
          options={options}
          setPaused={(paused: boolean) => setPaused(paused)}
          containerRef={this.containerRef}
          id={id}
        />
      </Relative>
    );
  }
}
