import { DataSource } from "app/dataSource/models";

export const searchDataSourceById = (
  systems: any,
  id: number | string,
  datasource: any[] = []
) => {
  systems.forEach((system: any) => {
    if (datasource[0]) return;
    if (system.dataSources) {
      system.dataSources.forEach((ds: any) => {
        if (datasource[0]) return;
        if (typeof id === 'number' && ds.id === Number(id)) {
          datasource.push(ds);
        }
        if (typeof id === 'string' && ds.fullyQualifiedName === id) {
          datasource.push(ds);
        }
      });
    }
    if (system.systems.length > 0) {
      return searchDataSourceById(system.systems, id, datasource);
    }
  });
  return datasource[0];
};

export const searchTree = (tree: any, target: any) => {
  if (tree.id === target) {
    return tree;
  }
  if (tree.children) {
    for (const child of tree.children) {
      const res: any = searchTree(child, target);
      if (res) {
        return res;
      }
    }
  }
};

export const getKeyByValue = (object: any, value: any) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const hasAllowedDataType = (
  dataSource: DataSource,
  allowedDataTypes: string[]
) => {
  return allowedDataTypes.includes(dataSource.tmDataType.type);
};
