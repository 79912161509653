import React from "react";
import styled from "styled-components";
import { CommonProps, LAYOUT, COMMON } from "styles/constants";
import { DATA_TEST_ID_ATTR_NAME } from "components/testable/models";
import { Icon, Flex } from "primitives";
import palette from "styles/palette";

interface TagProps extends React.HTMLAttributes<HTMLDivElement>, CommonProps {
  bg?: string; // hexadecimal
  label: string; // the text to be shown in the tag
  icon?: string;
}

const Container = styled("div")<TagProps>`
  ${COMMON}
  ${LAYOUT}
  font-family: monospace;
  font-size: 12px;
  background-color: ${({ bg }) => bg || "unset"};
  padding: 5px;
  border-radius: 4px;
  display: inline-block;
  outline: ${({ bg }) =>
    (bg && "unset") || `1px solid ${palette.palette.blue[1]}`}
  vertical-align: middle;
  * {
    cursor: inherit;
  }
`;

const Label = styled("div")<TagProps>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
  margin-left: ${({ icon }) => (icon && "5px") || "unset"};
`;

export const Tag = (props: TagProps) => {
  return (
    <Container {...props}>
      <Flex alignItems={"center"}>
        {props.icon && <Icon name={props.icon} />}
        <Label {...props}>{props.label}</Label>
      </Flex>
    </Container>
  );
};

Tag.displayName = "Tag";

Tag.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: Tag.displayName
};
