import { store } from "app/store";
import {
  FETCH_BOOKER_ERROR,
  FETCH_BOOKER_CANDIDATES_START,
  FETCH_BOOKER_CANDIDATES_END
} from "./types";

export enum NetworkState {
  LOADING = "loading",
  FAILED = "failed",
  END = "end",
  SUCCESS = "success",
  IDLE = "idle"
}

const createFetchAction = (type: string, payload: any) => ({ type, payload });

export const start = (payload: any) =>
  createFetchAction(FETCH_BOOKER_CANDIDATES_START, payload);
export const error = (payload: any) =>
  createFetchAction(FETCH_BOOKER_ERROR, payload);
export const end = (payload: any) =>
  createFetchAction(FETCH_BOOKER_CANDIDATES_END, payload);

export const status = (actionCreator: (payload: any) => any, payload: any) =>
  store.dispatch(actionCreator(payload));
