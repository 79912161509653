import { MultiCheckBox } from "components";

const telecommandsSentConfigOptionsSchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "telecommandsSent.schema.json",
  title: "Aurora TelecommandsSent",
  description:
    "The configuration options for the Aurora's TelecommandsSent component",
  type: "object",
  definitions: {
    columns: {
      $id: "#/properties/header/properties/columns",
      title: "columns",
      description: "Select the column you want to display in the table.",
      type: "array",
      default: [],
      items: {
        $id: "#/properties/header/properties/show/items",
        type: "number",
        enum: [
          "satId",
          "groundStationName",
          "username",
          "tcLabel",
          "sentAt",
          "status",
          "response"
        ],
        enumNames: [
          "Satellite ID",
          "Ground station name",
          "User name",
          "TC label",
          "Sent at",
          "Status",
          "Response"
        ]
      },
      uniqueItems: true
    }
  },
  properties: {
    columns: {
      $ref: "#/definitions/columns"
    }
  }
};

const telecommandsSentConfigOptionsDefault = {
  columns: []
};

const telecommandsSentConfigOptionsUISchema = {
  columns: { "ui:field": MultiCheckBox }
};

export {
  telecommandsSentConfigOptionsSchema,
  telecommandsSentConfigOptionsDefault,
  telecommandsSentConfigOptionsUISchema
};
