import React from "react";
import { SuspenseMutation } from "app/network";
import { Toggler } from "components";
import { DeleteButton, CancelButton } from "components";
import { connect } from "react-redux";
import { deleteGroundStationConfig } from "../services";
import { GroundStationConfiguration } from "../models";
import { Flex, Dialog, DialogTitle, DialogActions } from "primitives";

interface GroundStationConfigDeleteProps {
  groundStationConfig: GroundStationConfiguration;
  satelliteName: string;
  onChange: () => void;
  deleteGroundStationConfig: (
    satelliteID: number,
    groundStationID: number
  ) => Promise<any>;
}

export const GroundStationConfigDelete = ({
  groundStationConfig,
  satelliteName,
  onChange,
  ...props
}: GroundStationConfigDeleteProps) => {
  const submit = async (onToggle: () => void) => {
    await props.deleteGroundStationConfig(
      groundStationConfig.satelliteID,
      groundStationConfig.groundStationID
    );
    if (onChange) {
      onChange();
    }
    onToggle();
  };

  return (
    <Toggler>
      {([toggled, onToggle]) => (
        <>
          <DeleteButton onClick={onToggle}>Delete</DeleteButton>

          <Dialog open={toggled} maxWidth="sm">
            <SuspenseMutation>
              {({ loading, action }) => {
                return (
                  <>
                    <DialogTitle>
                      Delete {satelliteName}/
                      {groundStationConfig.groundStationName}?
                    </DialogTitle>

                    <DialogActions>
                      <Flex alignItems="center" mx={1}>
                        <DeleteButton
                          onClick={() => action(() => submit(onToggle))}
                          mr={1}
                          disabled={loading}
                        >
                          Delete
                        </DeleteButton>
                        <CancelButton onClick={onToggle}>Cancel</CancelButton>
                      </Flex>
                    </DialogActions>
                  </>
                );
              }}
            </SuspenseMutation>
          </Dialog>
        </>
      )}
    </Toggler>
  );
};

const mapDispatchToProps = () => ({
  deleteGroundStationConfig
});

export const GroundStationConfigDeleteContainer = connect(
  null,
  mapDispatchToProps
)(GroundStationConfigDelete);
