import React from "react";
import { Switch, Route } from "react-router-dom";
import { ResourceSContainer } from "./components/Resources";

export const ResourceV2Routers = () => (
  <>
    <Switch>
      <Route path="/resourcesv2" component={ResourceSContainer} />
    </Switch>
  </>
);
