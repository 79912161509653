interface Config {
  skippedProps: string[];
  formatters: Record<string, (value: any) => string>;
}

const defaultConfig: Config = {
  skippedProps: ["index"],
  formatters: {
    timestamp: (value) => new Date(value * 1000).toLocaleString()
  }
};

export function displayAlertInfo(
  alertData: any,
  userConfig: Partial<Config> = {}
): string {
  const config = { ...defaultConfig, ...userConfig };
  if (typeof alertData !== "object") {
    return `<pre>${JSON.stringify(alertData, null, 2)}</pre>`;
  }
  const transformedData = transformAlertData(alertData);
  return renderArray(transformedData, config);
}

function transformAlertData(data: any): any {
  if (Array.isArray(data)) {
    return data.filter((item) => item.type !== "NormalValue" && item);
  }
  return data;
}

function renderArray(array: any[], config: Config): string {
  return array
    .map((obj, index) => renderObject(obj, config, index < array.length - 1))
    .join("<hr class='alert-separator'>");
}

function renderObject(
  obj: Record<string, any>,
  config: Config,
  separator: boolean
): string {
  const result = Object.entries(obj)
    .filter(([key]) => !config.skippedProps.includes(key))
    .map(([key, value]) => renderKeyValuePair(key, value, config))
    .filter(Boolean)
    .join("<br>");
  return result + (separator ? "<br>" : "");
}

function renderKeyValuePair(key: string, value: any, config: Config): string {
  if (typeof value === "object" && value !== null) {
    return renderObject(value, config, false);
  }
  const formattedValue = formatValue(key, value, config);
  return `${formatKey(key)}: ${formattedValue}`;
}

function formatKey(key: string): string {
  return `<span class="alert-detail-key">${key}</span>`;
}

function formatValue(key: string, value: any, config: Config): string {
  const formatter = config.formatters[key];
  const formattedValue = formatter ? formatter(value) : value;
  const valueClass = typeof value === "string" ? value.toLowerCase() : "";
  return `<span class="alert-detail-value ${key} ${valueClass}">${formattedValue}</span>`;
}
