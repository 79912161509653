import React, { Component } from "react";
import { editSystemDefinition } from "../services";
import { schemaGenerator, uiSchema } from "app/dataSource/models/schemas";
import { Form } from "app/shared";
import { EditButton, SaveButton, CancelButton } from "components";
import { UnitManagement } from "./UnitManagement";
import {
  Flex,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "primitives";

export class SystemDefinitionEdit extends Component {
  state = {
    modalOpen: false,
    formData: null
  };

  onChange(event) {
    const { name, value } = event.currentTarget;
    this.setState({ [name]: value });
  }

  hideDialog = () => {
    this.setState({ modalOpen: false });
  };

  openDialog = () => {
    const { record } = this.props;
    this.setState({ modalOpen: true, formData: record });
  };

  submit(e, { formData }) {
    e.preventDefault();
    const { parentRecord, record } = this.props;
    const formatedData = this.formatData(formData);
    editSystemDefinition(parentRecord, record, formatedData)
      .then(({ data }) => {
        this.hideDialog();
        this.props.onUpdate({ parentDefinitionId: parentRecord.id, ...data });
      })
      .catch((error) => {
        //this.props.showNotification(error.message, "error");
      });
  }

  formatData(data) {
    const dataSources = data.dataSources.map((ds) => {
      const { alertId, ...dataSource } = ds;
      return {
        ...dataSource,
        units:
          dataSource.units && dataSource.units.unit ? dataSource.units : null
      };
    });

    return { ...data, dataSources };
  }

  render() {
    const { modalOpen, formData } = this.state;
    const { record, unitsList, setFeedback } = this.props;
    const schema = schemaGenerator(unitsList);

    return (
      <>
        <EditButton onClick={() => this.openDialog()} mr={1}>
          System
        </EditButton>
        <Dialog
          open={modalOpen}
          maxWidth="md"
          data-testid="SystemDefinitionEdit"
        >
          <DialogTitle>Edit {record.name}</DialogTitle>
          <UnitManagement unitsList={unitsList} setFeedback={setFeedback} />
          <DialogContent>
            <Form
              id="system-edit"
              formData={formData}
              schema={schema}
              uiSchema={uiSchema}
              onChange={(form, event) =>
                this.setState({ formData: form.formData })
              }
              onSubmit={(data, e) => this.submit(e, data)}
            >
              <></>
            </Form>
          </DialogContent>
          <DialogActions>
            <Flex
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <SaveButton type="submit" form="system-edit" mr={1}>
                Save
              </SaveButton>
              <CancelButton onClick={() => this.hideDialog()} mx={1}>
                Cancel
              </CancelButton>
            </Flex>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
