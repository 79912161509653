import React from "react";
import { Box, Text } from "primitives";
import {
  Colors,
  AlertType,
  ISimpleAndArrayAlertDetails,
  IAlertDetails,
  IDynamicAlertDetails
} from "../models";
import styled from "styled-components";

export function AlertDetails({ type, details, colorsByValue }: IAlertDetails) {
  if (!details) return null;
  if (type === AlertType.DynamicAlert) {
    return <DynamicAlertDetails details={details} />;
  }
  if (type !== AlertType.DynamicAlert) {
    return (
      <SimpleAndArrayAlertDetails
        details={details}
        colorsByValue={colorsByValue}
      />
    );
  }
  return null;
}

function SimpleAndArrayAlertDetails({
  details,
  colorsByValue
}: ISimpleAndArrayAlertDetails) {
  return (
    details &&
    details?.split(",").map((detail: string, index: number) => {
      return detail ? (
        <Box key={index}>
          <Text color={colorsByValue[index]}>{detail}</Text>
          {index - 1 < details.length ? <br /> : null}
        </Box>
      ) : null;
    })
  );
}

function DynamicAlertDetails({ details }: IDynamicAlertDetails) {
  return details?.map((detail: any, index: number) => {
    if (detail?.type === "DynamicAlertValue") {
      const color =
        Colors[
          (detail?.alertLevel?.severity as keyof typeof Colors) || "Default"
        ];
      return (
        <AlertIndexDetails>
          <Box key={index}>
            <Text color={color}>[{detail?.index}] </Text>
            <Text color={color}>
              Condition: {detail?.alertLevel?.condition}
            </Text>
            {index - 1 < details.length ? <br /> : null}
          </Box>
        </AlertIndexDetails>
      );
    }
  });
}

export const AlertIndexDetails = styled(Box)`
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 1px dotted #7ab9db;
`;
