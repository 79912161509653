import { Passage } from "../models";
import { Dispatch } from "react";
import { ThunkAction } from "redux-thunk";
import {
  fetchPassages,
  fetchNextPassages,
  fetchAllPassages
} from "../services";
import { getServerTime } from "../helpers";

export enum PassageActionType {
  FetchPassages = "FETCH_PASSAGES",
  FetchNextPassages = "FETCH_NEXT_PASSAGES",
  SelectPassage = "SELECT_PASSAGE",
  FetchServerTime = "FETCH_SERVER_TIME",
  TickTime = "TICK_SERVER_TIME",
  FetchAllPassages = "FETCH_ALL_PASSAGES"
}

export interface SelectPassageAction {
  type: PassageActionType.SelectPassage;
  payload: Passage | null;
}

export interface FetchPassagesAction {
  type: PassageActionType.FetchPassages;
  payload: { satelliteId: number; passages: Passage[] };
}

export interface FetchServerTimeAction {
  type: PassageActionType.FetchServerTime;
  payload: Date;
}

export interface TickTimeAction {
  type: PassageActionType.TickTime;
}

export interface FetchNextPassagesAction {
  type: PassageActionType.FetchNextPassages;
  payload: { satelliteId: number; passages: Passage[] };
}

export interface FetchAllPassagesAction {
  type: PassageActionType.FetchAllPassages;
  payload: Passage[];
}

export type PassageActions =
  | FetchPassagesAction
  | SelectPassageAction
  | FetchNextPassagesAction
  | TickTimeAction
  | FetchServerTimeAction
  | FetchAllPassagesAction;

export type FetchPassagesActionResult = ThunkAction<
  Promise<Passage[]>,
  {},
  {},
  FetchPassagesAction
>;

export type FetchAllPassagesActionResult = ThunkAction<
  Promise<Passage[]>,
  {},
  {},
  FetchAllPassagesAction
>;

export const fetchPassagesAction = (
  satelliteId: number
): FetchPassagesActionResult => {
  return async (dispatch: Dispatch<FetchPassagesAction>) => {
    try {
      const passages = await fetchPassages(satelliteId);
      dispatch({
        type: PassageActionType.FetchPassages,
        payload: { satelliteId, passages }
      });
      return Promise.resolve(passages);
    } catch (e) {
      return Promise.resolve([]);
    }
  };
};

export type FetchNextPassagesActionResult = ThunkAction<
  Promise<Passage[]>,
  {},
  {},
  FetchNextPassagesAction
>;
export const fetchNextPassagesAction = (
  satelliteId: number
): FetchNextPassagesActionResult => {
  return async (dispatch: Dispatch<FetchNextPassagesAction>) => {
    try {
      const passages = await fetchNextPassages(satelliteId);
      dispatch({
        type: PassageActionType.FetchNextPassages,
        payload: { satelliteId, passages }
      });
      return Promise.resolve(passages);
    } catch (e) {
      return Promise.resolve([]);
    }
  };
};

export type SelectPassageActionResult = ThunkAction<
  Promise<Passage | null>,
  {},
  {},
  SelectPassageAction
>;
export const selectPassageAction = (
  passage: Passage | null
): SelectPassageActionResult => {
  return async (dispatch: Dispatch<SelectPassageAction>) => {
    dispatch({
      type: PassageActionType.SelectPassage,
      payload: passage
    });
    return Promise.resolve(passage);
  };
};

export type FetchServerTimeActionResult = ThunkAction<
  Promise<Date | null>,
  {},
  {},
  FetchServerTimeAction
>;

export const fetchServerTime = (): FetchServerTimeActionResult => {
  return async (dispatch: Dispatch<FetchServerTimeAction>) => {
    try {
      const now = await getServerTime();
      dispatch({
        type: PassageActionType.FetchServerTime,
        payload: now
      });
      return Promise.resolve(now);
    } catch (e) {
      return Promise.resolve(null);
    }
  };
};

export const tickTime = () => ({ type: PassageActionType.TickTime });

export const fetchAllPassagesAction = (
  satelliteIds: number[],
  begin: Date,
  end: Date
): FetchAllPassagesActionResult => {
  return async (dispatch: Dispatch<FetchAllPassagesAction>) => {
    try {
      const passages = await fetchAllPassages(
        satelliteIds,
        begin as Date,
        end as Date
      );
      dispatch({
        type: PassageActionType.FetchAllPassages,
        payload: passages
      });
      return Promise.resolve(passages);
    } catch (e) {
      return Promise.resolve([]);
    }
  };
};
