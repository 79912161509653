import React, { useState, useCallback, useEffect } from "react";
import { DataSourceTreeField } from "app/shared";
import { AddButton, SaveButton, CancelButton } from "components";
import { Box, Flex } from "primitives";
import useToggle from "components/toggler/hooks/useToggle";
import { searchDataSourceById } from "./utils";
import { Dialog, DialogTitle, DialogActions } from "primitives";
import { DataSourcesMenu } from "./DataSourcesMenu";

export interface FormDataOnChangeProps {
  dataSources: number[];
  systems: number[] | undefined;
}

export interface DataSourceTreeFieldOnChangeProps {
  dataSources: number[];
  systems: number[];
  dataSourceLabels?: any;
  dataSourceIndexes?: any;
}

export interface SelectDataSourcesProps {
  satelliteDefinition: any;
  selectedDataSources: DataSourceIdLabel[];
  selectedSystems?: number[];
  onChange: (props: FormDataOnChangeProps) => void;
  showConfig: boolean | undefined;
  maxItems: number | undefined;
  allowedDataTypes: string[] | undefined;
  buttonSize?: string;
  m?: string;
  defaultToggled?: boolean;
  isVirtualized?: boolean;
}

export interface DataSourceIdLabel {
  datasourceId: number;
  label: string | null;
  indexes: any[];
}

const AddBtn = React.memo(AddButton);
const textStyles = { textTransform: "uppercase" } as any;

export const SelectDataSources = ({
  satelliteDefinition,
  selectedDataSources,
  selectedSystems,
  onChange,
  showConfig,
  maxItems,
  allowedDataTypes,
  buttonSize = "small",
  m,
  defaultToggled = false,
  isVirtualized
}: SelectDataSourcesProps) => {
  const [toggled, onToggle] = useToggle({
    defaultToggled: defaultToggled ? defaultToggled : false
  });

  const getDataSourceIds = (datasources: DataSourceIdLabel[]) => {
    datasources = datasources.filter((item) => item);
    return datasources.map((dataSource: DataSourceIdLabel) => {
      return dataSource.datasourceId ? dataSource.datasourceId : dataSource;
    });
  };

  const getDataSourceLabels = (datasources: DataSourceIdLabel[]) => {
    const labels = {};
    datasources.forEach((dataSource: DataSourceIdLabel) => {
      (labels as any)[dataSource.datasourceId] = dataSource.label;
    });
    return labels;
  };

  const getDataSourceIndexes = (datasources: DataSourceIdLabel[]) => {
    const indexes = {};
    datasources.forEach((dataSource: DataSourceIdLabel) => {
      (indexes as any)[dataSource.datasourceId] =
        dataSource.indexes &&
        dataSource.indexes.map((dsI) => (isNaN(dsI) ? dsI.index : dsI));
    });
    return indexes;
  };

  const [dataSources, setDataSources] = useState(
    getDataSourceIds(selectedDataSources)
  );

  const [initialSelectedDS, setInitialSelectedDS] = useState(
    selectedDataSources
  );

  const addDataSources = (dataSource: (DataSourceIdLabel)[]) => {
    const selectedDS = [...new Set([...initialSelectedDS, ...dataSource])];
    setInitialSelectedDS(selectedDS);
  };

  const [dataSourceLabels, setDataSourceLabels] = useState(
    getDataSourceLabels(selectedDataSources)
  );
  const [dataSourceIndexes, setDataSourceIndexes] = useState(
    getDataSourceIndexes(selectedDataSources)
  );
  const [systems, setSystems] = useState(selectedSystems);
  const [satelliteSystems, setSatelliteSystems] = useState([]);

  const generateDataSourceData = useCallback(() => {
    const newDataSources: any = [];
    dataSources.forEach((dataSource: any) => {
      const systemDataSource: any = searchDataSourceById(
        satelliteSystems,
        dataSource
      );
      const newDataSource = {
        datasourceId: dataSource,
        name: systemDataSource
          ? systemDataSource.name
          : (dataSourceLabels as any)[dataSource]
          ? (dataSourceLabels as any)[dataSource]
          : null,
        label: (dataSourceLabels as any)[dataSource]
          ? (dataSourceLabels as any)[dataSource]
          : null,
        indexes: (dataSourceIndexes as any)[dataSource]
          ? (dataSourceIndexes as any)[dataSource]
          : null,
        unit:
          systemDataSource && systemDataSource.units
            ? systemDataSource.units.unit
            : null
      };
      newDataSources.push(newDataSource);
    });
    return newDataSources;
  }, [dataSources, dataSourceLabels, dataSourceIndexes, satelliteSystems]);

  const submit = useCallback(() => {
    onChange({
      dataSources: showConfig ? generateDataSourceData() : dataSources,
      systems
    });
    onToggle();
    const selectedDS = initialSelectedDS.filter((ds: any) =>
      dataSources.includes(ds.id)
    );
    setInitialSelectedDS(selectedDS);
  }, [
    onChange,
    onToggle,
    dataSources,
    systems,
    showConfig,
    generateDataSourceData
  ]);

  const cancel = useCallback(() => {
    setDataSources(getDataSourceIds(selectedDataSources));
    setSystems(selectedSystems);
    onToggle();

    const selectedDS = initialSelectedDS.filter((ds: any) =>
      dataSources.includes(ds.id)
    );
    setInitialSelectedDS(selectedDS);
  }, [onToggle, selectedSystems, selectedDataSources]);

  useEffect(() => {
    setDataSources(getDataSourceIds(selectedDataSources));
    setDataSourceLabels(getDataSourceLabels(selectedDataSources));
    setDataSourceIndexes(getDataSourceIndexes(selectedDataSources));
  }, [selectedDataSources]);

  useEffect(() => {
    setSystems(selectedSystems);
  }, [selectedSystems]);

  const satId = satelliteDefinition && satelliteDefinition.id;
  useEffect(() => {
    if (satId) {
      setSatelliteSystems(satelliteDefinition.systems);
    }
  }, [satId]);

  if (!satelliteDefinition) return null;

  return (
    <>
      <AddBtn size={buttonSize} m={m} onClick={onToggle} style={textStyles}>
        {"Select Data Sources"}
      </AddBtn>
      <Dialog open={toggled} maxWidth="md" height="100%">
        <DialogTitle>Select Data Sources</DialogTitle>
        <Flex height="100%">
          <Box overflow="hidden !important" width="65%">
            <DataSourceTreeField
              key={satelliteDefinition.id}
              satelliteDefinition={satelliteDefinition}
              selectedDataSources={dataSources}
              dataSourceLabels={dataSourceLabels}
              dataSourceIndexes={dataSourceIndexes}
              selectedSystems={systems}
              satelliteSystems={satelliteSystems}
              onChange={(props: DataSourceTreeFieldOnChangeProps) => {
                setDataSourceIndexes(props.dataSourceIndexes);
                setDataSourceLabels(props.dataSourceLabels);
                setDataSources(props.dataSources);
                setSystems(props.systems);
              }}
              showConfig={showConfig}
              maxItems={maxItems}
              allowedDataTypes={allowedDataTypes}
              isVirtualized={isVirtualized}
              addDataSources={addDataSources}
            />
          </Box>
          <DataSourcesMenu
            dataSources={dataSources}
            setDataSources={setDataSources}
            initialSelectedDS={initialSelectedDS}
          />
        </Flex>
        <DialogActions>
          <Flex justifyItems="flex-end" alignItems="center">
            <SaveButton mr={1} onClick={submit}>
              Save
            </SaveButton>

            <CancelButton onClick={cancel} mx={1}>
              Cancel
            </CancelButton>
          </Flex>
        </DialogActions>
      </Dialog>
    </>
  );
};
