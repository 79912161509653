import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgHelpDesk = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 15 15"
    color={color}
  >
    <g>
      <g>
        <g>
          <path d="M4.5 9.75a4.509 4.509 0 0 0-4.5 4.5V15h9v-.75a4.5 4.5 0 0 0-4.5-4.5z" />
        </g>
        <g>
          <circle
            cx={2.25}
            cy={2.25}
            r={2.25}
            transform="translate(2.25 4.5)"
          />
        </g>
        <g>
          <path d="M14.25 0h-6a.752.752 0 0 0-.75.75V4.5a.752.752 0 0 0 .75.75v1.5a.749.749 0 0 0 1.2.6l2.805-2.1h1.995A.752.752 0 0 0 15 4.5V.75a.752.752 0 0 0-.75-.75z" />
        </g>
      </g>
    </g>
  </Svg>
);

SvgHelpDesk.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgHelpDesk;
