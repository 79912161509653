import React, { Component } from "react";
import { Box, Heading, Flex } from "primitives";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "components/table/Table";
import { SatelliteDefinitionDelete } from "./SatelliteDefinitionDelete";
import { getSatelliteDefinitions } from "../services";
import { SuspenseQuery, Suspense } from "app/network";
import { AddButton, EditButton } from "components";

export class SatelliteDefinitionList extends Component {
  query = () =>
    getSatelliteDefinitions({ page: 1, perPage: 10 }, { summary: true });
  render() {
    return (
      <Suspense>
        <SuspenseQuery query={this.query}>
          {({ response, reload }) => {
            return (
              <Box data-testid="SatelliteDefinitionList" mx={3}>
                <Flex alignItems="center" justifyContent="space-between" mb={2}>
                  <Heading display={1}>Satellite definition</Heading>
                  <AddButton to="/satellite-definition/create">
                    Create
                  </AddButton>
                </Flex>
                <Table>
                  <TableHead>
                    <TableRow bg="fill.0">
                      <TableCell width="50">ID</TableCell>
                      <TableCell width="auto">Name</TableCell>
                      <TableCell width="50" />
                      <TableCell width="50" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {response &&
                      Array.isArray(response.data) &&
                      response.data.map((satelliteDefinition) => (
                        <TableRow key={satelliteDefinition.id}>
                          <TableCell>#{satelliteDefinition.id}</TableCell>
                          <TableCell>{satelliteDefinition.name}</TableCell>
                          <TableCell>
                            <EditButton
                              to={`/satellite-definition/${satelliteDefinition.id}`}
                            >
                              Edit
                            </EditButton>
                          </TableCell>
                          <TableCell>
                            <SatelliteDefinitionDelete
                              record={satelliteDefinition}
                              onChange={() => reload()}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            );
          }}
        </SuspenseQuery>
      </Suspense>
    );
  }
}
