import React from "react";
import styled, { css } from "styled-components";
import { Text } from "primitives";
import { TextProps } from "primitives/Text";
import { DATA_TEST_ID_ATTR_NAME } from "components/testable/models";
import { CommonProps, COMMON, FLEX, FlexProps } from "styles/constants";

interface LabelProps extends TextProps, CommonProps, FlexProps {
  hidden?: boolean;
  htmlFor?: string;
  cursor?: string;
  width?: string;
  onClick?: () => void;
}

const accessiblyHide = (props: LabelProps) =>
  props.hidden
    ? css({
        position: "absolute",
        width: "1px",
        height: "1px",
        clip: "rect(1px, 1px, 1px, 1px)"
      })
    : null;
// @ts-ignore
const LabelStyled = styled(Text)<LabelProps>`
  ${COMMON}
  ${FLEX}
  display: block;
  text-transform: uppercase;
  width: ${(props) => (props.width ? props.width : "100%")};
  cursor: ${(props) => (props.cursor ? props.cursor : "")};
  ${accessiblyHide}
`;

const Label = (props: LabelProps) => <LabelStyled as="label" {...props} />;

Label.displayName = "Label";

Label.defaultProps = {
  fontSize: 5,
  fontWeight: "normal",
  color: "text.label",
  margin: 0,
  [DATA_TEST_ID_ATTR_NAME]: Label.displayName
};

export default Label;
