import React, { useContext } from "react";
import { VisibiltyWindowContext } from "app/visibilityWindow/context";
import {
  ITimelineFilter,
  Passage,
  OptionData
} from "app/visibilityWindow/models";
import { getLegendList } from "app/visibilityWindow/helpers";
import {
  SelectContainerSimple as SelectContainer,
  SelectLabelSimple as SelectLabel,
  SelectSimple as Select
} from "components";

const ALL_SATELLITES = "All";

const TimelineFilter = ({
  allPassages = [],
  satelliteInstances,
  viewBySatellite
}: ITimelineFilter) => {
  const {
    state: { filter },
    dispatch
  } = useContext(VisibiltyWindowContext);

  const mapSatelitesToOptions = (
    passages: Passage[]
  ): React.ReactElement<HTMLOptionElement>[] => {
    const filterList: OptionData[] = getLegendList(
      passages,
      viewBySatellite ? satelliteInstances : [],
      viewBySatellite
    );

    return [
      <option key={0}>{ALL_SATELLITES}</option>,
      ...filterList.map((passage: OptionData, i: number) => {
        return (
          <option key={i + 1} value={passage.id as number}>
            {passage.label}
          </option>
        );
      })
    ];
  };

  const options: React.ReactElement<
    HTMLOptionElement
  >[] = mapSatelitesToOptions(allPassages);

  return (
    <div data-testid="timeline-filter" style={{ zIndex: 1 }}>
      <SelectContainer
        style={{ width: 90, marginLeft: 55, transform: "translateY(4px)" }}
      >
        <SelectLabel>
          {viewBySatellite ? "Satellites" : "Ground Stations"}
        </SelectLabel>
        <Select
          value={filter}
          onChange={(e) => {
            e.persist();
            dispatch({
              type: "filter",
              payload:
                (typeof e.target.value === "string" &&
                  parseInt(e.target.value)) ||
                undefined
            });
          }}
        >
          {[options]}
        </Select>
      </SelectContainer>
    </div>
  );
};

export { TimelineFilter };
