import moment from "moment";

export const getUTCOffset = (date: string): number => {
  const localDate = moment(new Date(date));

  return (
    (Math.sign(localDate.utcOffset()) === -1 && localDate.utcOffset()) ||
    -localDate.utcOffset()
  );
};

export const applyUTCOffset = (date: number): Date => {
  const isoStringDate = new Date(date).toISOString();
  const offset = -getUTCOffset(isoStringDate);
  const computedLocalDate = new Date(date + offset * 60 * 1000);

  return computedLocalDate;
};

/**
 * @description This helper is necessary since react-calendar-timeline does not
 * manage UTC time. Passed UTC date will be transformed in local dates.
 * This helper takes a local date in string format and add/remove
 * the time offset to indicate a UTC time still reamaining flagged as local time.
 * @param date {string}
 * @returns unix timestamp
 */
export const getDateAtUTCOffset = (date: string): number => {
  const UTCOffset = getUTCOffset(date);
  const dateAtUTCOffset = moment(new Date(date))
    .add(UTCOffset, "minutes")
    .valueOf();

  return dateAtUTCOffset;
};

// Avoid request for small time change
export const TIME_OFFSET = 60;
