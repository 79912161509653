import React, { useState } from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";

import { uploadTelecommandFile } from "../services";
import { Box, Flex, Button } from "primitives";
import { InputField } from "components";

const TelecommandFileInput = ({
  id,
  onChange,
  disabled,
  rawErrors,
  required,
  options
}) => {
  const [file, setFile] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);

  const _onFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    fileUpload(file).then((response) => {
      if (response && response.data && response.data.objectKey) {
        const outputPath = response.data.objectKey;
        const outputFileName = getOutputFileName(outputPath);
        onChange(outputFileName);
        setUploadSuccess(true);
      } else {
        setUploadFailed(true);
      }
    });
  };

  const fileUpload = async (inputFile) => {
    const formData = new FormData();
    formData.append("file", inputFile);
    const response = await uploadTelecommandFile(options.satelliteId, formData);
    return response;
  };

  const _onChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <form onSubmit={(e) => _onFormSubmit(e)}>
      <Flex overflow="visible" height={30} mt={1}>
        <Flex flex={1} overflow="visible">
          <InputField
            id={id}
            error={rawErrors && rawErrors.length > 0}
            file={true}
            inputProps={{
              disabled,
              "data-testid": id
            }}
            onChange={_onChange}
            disabled={disabled}
            required={required}
          />
        </Flex>
        <ActionContainer
          bg={uploadSuccess || uploadFailed ? "fill.0" : ""}
          border={uploadSuccess || uploadFailed ? 0 : 1}
          overflow="visible"
        >
          {!uploadSuccess && !uploadFailed ? (
            <UploadButton type="submit">Upload File</UploadButton>
          ) : uploadSuccess ? (
            <Box p={1}>
              <span>File Uploaded</span>
            </Box>
          ) : (
            <Box p={1}>
              <span>File Failed</span>
            </Box>
          )}
        </ActionContainer>
      </Flex>
    </form>
  );
};

const ActionContainer = styled(Flex)`
  border-color: ${themeGet("colors.palette.brand.0")};
  border-radius: 6px;
`;

const UploadButton = styled(Button)`
  border: none;
  background-color: transparent;
  font-size: 14px;
  &:hover {
    background-color: transparent !important;
  }
`;

const getOutputFileName = (outputPath) => {
  const slashIndex = outputPath.indexOf("/");
  const outputFileName =
    slashIndex !== -1 ? outputPath.substring(slashIndex + 1) : outputPath;
  return outputFileName;
};

export default TelecommandFileInput;
