import React, { Component } from "react";
import { Form } from "app/shared";
import { Box, Heading, Flex, Text } from "primitives";
import { SuspenseQuery, SuspenseMutation, Suspense } from "app/network";
import { CancelButton, SaveButton } from "components";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { schemaEdit, uiSchemaEdit } from "../models/schemas";
import { EditTelecommandStackCommand } from "../models";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { TelecommandStack } from "app/telecommandStack/models";
import {
  getTelecommandStackById,
  updateTelecommandStack
} from "app/telecommandStack/services";

type RouteParams = {
  id: string;
};

interface TelecommandStackEditProps extends RouteComponentProps<RouteParams> {
  getTelecommandStackById: (id: string) => Promise<TelecommandStack>;
  editTelecommandStack: (
    id: number,
    data: EditTelecommandStackCommand
  ) => Promise<TelecommandStack>;
  satellites: any;
}

interface TelecommandStackEditState {
  formData: EditTelecommandStackCommand | null;
  formChange: boolean;
}

export class TelecommandStackEdit extends Component<
  TelecommandStackEditProps,
  TelecommandStackEditState
> {
  state = {
    formData: null,
    formChange: false
  };

  async submit(
    e: React.FormEvent<HTMLInputElement>,
    formData: EditTelecommandStackCommand
  ) {
    e.preventDefault();

    const { id } = this.props.match.params;

    await updateTelecommandStack(id, formData);
    this.props.history.push("/telecommandstacks");
  }

  onChange(form: TelecommandStackEditState) {
    this.setState({ formData: form.formData, formChange: true });
  }

  query = () => {
    const { id } = this.props.match.params;
    return this.props.getTelecommandStackById(id);
  };

  render() {
    const { formData, formChange } = this.state;
    const { satellites } = this.props;
    return (
      <Suspense>
        <SuspenseQuery query={this.query}>
          {({ response }) => {
            return (
              response && (
                <Box
                  color="text.default"
                  data-testid="TelecommandStackEdit"
                  mx={3}
                >
                  <Flex mb={2}>
                    <Heading display={1}>Telecommand Stack</Heading>
                  </Flex>
                  <Flex flexDirection="column" bg="fill.0" p={3}>
                    <SuspenseMutation>
                      {({ loading, error, action }) => {
                        return (
                          <Form
                            formData={formData || response.data}
                            schema={schemaEdit(satellites)}
                            uiSchema={uiSchemaEdit}
                            disabled={loading}
                            onSubmit={(
                              form: { formData: EditTelecommandStackCommand },
                              e: React.FormEvent<HTMLInputElement>
                            ) => action(() => this.submit(e, form.formData))}
                            onChange={(form: TelecommandStackEditState) =>
                              this.onChange(form)
                            }
                          >
                            <CancelButton
                              onClick={() =>
                                this.props.history.push("/telecommandstacks")
                              }
                              mr={2}
                            >
                              Cancel
                            </CancelButton>
                            <SaveButton
                              type="submit"
                              disabled={loading || !formChange}
                            >
                              Save
                            </SaveButton>

                            {error ? (
                              <Box my={2}>
                                <Text color="text.danger">Error...</Text>
                              </Box>
                            ) : null}
                          </Form>
                        );
                      }}
                    </SuspenseMutation>
                  </Flex>
                </Box>
              )
            );
          }}
        </SuspenseQuery>
      </Suspense>
    );
  }
}

const mapStateToProps = (state: any) => ({
  satellites: state.constellations.dashboard.map((sat: any) => ({
    id: sat.id,
    name: sat.label
  }))
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  getTelecommandStackById: (id: string) => getTelecommandStackById(id)
});

export const TelecommandStackEditContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TelecommandStackEdit)
);
