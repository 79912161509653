import { DataSource } from "app/telemetry/models/datasources";
import React from "react";
import { findObjectByProperty } from "../shared/utils";

type IGraphModel = any;

export class Histogram {
  engine: any;
  model: IGraphModel;
  tooltip: any;
  constructor(model: IGraphModel, graphEngine: any, tooltip: any) {
    this.engine = graphEngine;
    this.model = model;
    this.tooltip = tooltip;
  }

  createScaleX = () => {
    const { sortedData, padding, boundedWidth } = this.model.getXOptions();

    const scaleX = this.engine
      .scaleBand()
      .range([0, boundedWidth])
      .domain(
        sortedData.map((ds: DataSource) => this.model.getDataSourceLabel(ds))
      )
      .padding(padding);
    return scaleX;
  };

  createAxisX = () => {
    const {
      valueMapping,
      showGrid,
      showXAxis,
      gridColor,
      gridStrokeOpacity,
      boundedHeight
    } = this.model.getXOptions();

    const svgEl = this.engine.select(this.model.axesRef.current);
    const xAxis = this.engine
      .axisBottom(this.createScaleX())
      // @ts-ignore
      .tickFormat((val, i, ...rest) => {
        return this.model.formatLabelAxisX(val, valueMapping);
      });

    const xAxisGrid = this.engine
      .axisBottom(this.createScaleX())
      .tickSize(boundedHeight)
      .tickFormat(() => " ");

    showXAxis &&
      svgEl
        .append("g")
        .attr("transform", `translate(0,${boundedHeight})`)
        .call(xAxis);

    showGrid &&
      svgEl
        .append("g")
        .attr("class", "grid-x")
        .style("stroke", gridColor)
        .style("stroke-opacity", gridStrokeOpacity)
        .call(xAxisGrid);

    return xAxis;
  };

  createScaleY = () => {
    const { OFFSET, min, max, boundedHeight } = this.model.getYOptions();

    const scaleY = this.engine
      .scaleLinear()
      .domain([min, max + max * OFFSET])
      .range([boundedHeight, 0]);
    return scaleY;
  };

  createAxisY = () => {
    const {
      noOfTicks,
      showUnit,
      showYAxis,
      unitSymbol,
      paddingTop,
      showGrid,
      gridColor,
      gridStrokeOpacity,
      boundedWidth
    } = this.model.getYOptions();

    const svgEl = this.engine.select(this.model.axesRef.current);
    const yAxis = this.engine
      .axisLeft(this.createScaleY())
      .ticks(noOfTicks)
      // @ts-ignore
      .tickFormat((v, e) => {
        return `${v} ${showUnit ? unitSymbol : ""}`;
      });

    const yAxisGrid = this.engine
      .axisLeft(this.createScaleY())
      .tickSize(-boundedWidth)
      .tickFormat(() => " ");

    showYAxis &&
      svgEl
        .append("g")
        .attr("transform", `translate(0,${paddingTop})`)
        .call(yAxis);

    showGrid &&
      svgEl
        .append("g")
        .attr("class", "grid-y")
        .attr("transform", `translate(0,${paddingTop})`)
        .style("stroke", gridColor)
        .style("stroke-opacity", gridStrokeOpacity)
        .call(yAxisGrid);

    return yAxis;
  };

  createBarCoordinates = (datasource: any) => {
    const { barWidth, OFFSET, maxValidValue, boundedHeight } =
      this.model.getBarOptions();

    const currentDataSourceValue = this.model.getDataSourceValue(datasource);
    const getX = this.createScaleX();
    const x = getX(this.model.getDataSourceLabel(datasource));
    const width = this.createScaleX().bandwidth() + barWidth;

    const getY = this.createScaleY();
    const y =
      currentDataSourceValue > maxValidValue
        ? this.createScaleY()(maxValidValue + OFFSET)
        : getY(this.model.getDataSourceValue(datasource));
    // If no data is available, pass 0 as height to prevent NaN values in svg
    const height = boundedHeight - (y ? y : boundedHeight);

    return {
      x,
      y,
      height,
      width
    };
  };

  getBarColorFromConfig(datasource: any) {
    const {
      valueMapping,
      invalidValueOpacity,
      defaultBarColor,
      validDataSources
    } = this.model.getBarOptions();

    const id = datasource.dataSource.id;
    const mapping = findObjectByProperty(valueMapping, id, "id");
    const value = this.model.getDataSourceValue(datasource);

    const isNotValidValue = !validDataSources
      .map((ds: DataSource) => this.model.getDataSourceValue(ds))
      .includes(value);

    if (mapping?.color) {
      // TODO: validate color values
      return isNotValidValue
        ? mapping.color + invalidValueOpacity
        : mapping.color;
    }
    return defaultBarColor;
  }

  createBars = () => {
    if (!Array.isArray(this.model.getData())) return;
    const bars = this.model.getData().map((datasource: any, i: any) => {
      const { height, y, x, width } = this.createBarCoordinates(datasource);
      const barColor = this.getBarColorFromConfig(datasource);
      return (
        <rect
          data-testid="bar"
          onMouseEnter={(e) => this.tooltip.onMouseEnter(e, datasource)}
          onMouseLeave={(e) => this.tooltip.onMouseLeave(e, datasource)}
          onMouseMove={(e) => this.tooltip.onMouseMove(e, datasource)}
          key={i}
          fill={barColor}
          x={x}
          y={y}
          width={width}
          height={height}
        />
      );
    });
    return bars;
  };

  draw = () => {
    const svgEl = this.engine.select(this.model.axesRef.current);
    svgEl.selectAll("*").remove();
    this.createAxisX();
    this.createAxisY();

    const {
      width,
      height,
      margins: { left, top }
    } = this.model.getDimensions();

    return (
      <svg width={width} height={height} data-testid="histogram">
        <g
          width={width}
          height={height}
          transform={`translate(${[left, top].join(",")})`}
        >
          {this.createBars()}
        </g>
        <g
          width={width}
          height={height}
          ref={this.model.axesRef}
          transform={`translate(${[left, top].join(",")})`}
        />
      </svg>
    );
  };
}
