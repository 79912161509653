import React, { Component } from "react";
import { SelectContainer, SelectLabel, Select } from "components";

export class ConstellationSatelliteSelector extends Component {
  componentDidMount() {
    this.props.getConstellations();
  }

  componentDidUpdate(prevProps) {
    const { constellations, selectedSatellite: satellite } = this.props;
    const selectedSatellite = satellite || {};
    const previousSelectedSatellite = prevProps.selectedSatellite || {};

    // Get constellation satellites on first update
    if (
      !constellations.selected &&
      constellations &&
      constellations.list.length > 0
    ) {
      this.props.getConstellation(constellations.list[0].id);
    }

    // Get user dashboards when satellite changes
    if (
      selectedSatellite &&
      selectedSatellite.id &&
      selectedSatellite.id !== previousSelectedSatellite.id
    ) {
      this.getUserDashboards();
      this.props.getSatelliteDefinition(
        selectedSatellite.satelliteDefinitionSummary.satelliteDefinitionId
      );
    }
  }

  getUserDashboards() {
    const { selectedSatellite } = this.props;

    if (selectedSatellite) {
      this.props
        .getUserDashboards(
          selectedSatellite.satelliteDefinitionSummary.satelliteDefinitionId
        )
        .then(({ data }) => {
          this.props.addDashboards(data || []);
        });
    }
  }

  onHandleChange = (satellite) => {
    this.props.setSatelliteVisibility(satellite);
    // when sat is switched, navigate to default which is currently set at first dashboard.
    this.props.history.push({
      pathname: "/dashboard/view/default",
      state: { default: true }
    });
  };

  handleConstellationChange = (e) => {
    this.props.getConstellation(
      this.props.constellations.list.find(
        (c) => c.id === Number(e.target.value)
      ).id
    );
  };

  render() {
    const { dashboard, selectedSatellite } = this.props;
    const previousSelectedSat = parseInt(
      JSON.parse(sessionStorage.getItem("selectedSat"))
    );
    return (
      <>
        <SelectContainer bg="transparent" data-testid="constellationSelector">
          <SelectLabel htmlFor="satellite">Constellation:</SelectLabel>
          <Select
            id={"constellation"}
            color="text.success"
            onChange={this.handleConstellationChange}
          >
            {this.props.constellations.list.map((c) => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))}
          </Select>
        </SelectContainer>
        <SelectContainer bg="transparent" data-testid="SatelliteSelector">
          <SelectLabel htmlFor="satellite">Satellite:</SelectLabel>
          <Select
            id="satellite"
            value={
              (previousSelectedSat && previousSelectedSat) ||
              (selectedSatellite && selectedSatellite.id)
            }
            onChange={(event) => this.onHandleChange(event.target.value)}
            color="text.success"
          >
            {dashboard &&
              dashboard
                .sort((a, b) => {
                  if (a.label < b.label) return -1;
                  if (a.label > b.label) return 1;
                  return 0;
                })
                .map((satellite) => (
                  <option key={satellite.id} value={satellite.id}>
                    {satellite.label}
                  </option>
                ))}
          </Select>
        </SelectContainer>
      </>
    );
  }
}
