import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { createSelector } from "reselect";
import { PlanningSystemTimeline } from "app/planningSystem/components/Timeline";
import { passageToTimeline } from "app/planningSystem/utils/helpers";

const getPassages = (state: any) => state.visibilityWindow.passages;
const getCalendarPassages = createSelector([getPassages], (passages) =>
  passageToTimeline(passages)
);

const mapStateToProps = (state: any) => ({
  availableSatellites: state.constellations.dashboard,
  calendarTimeline: state.planningSystem.calendarTimeline,
  passages: getCalendarPassages(state)
});
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {};
};

export const PlanningSystemTimelineContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanningSystemTimeline);
