import { CSSProperties } from "styled-components";
import { CalendarTimelineGroup } from "app/planningSystem/models";

const oneDayInSeconds = 86400;

export enum Zoom {
  min = (oneDayInSeconds / 144) * 1000, // 10 minutes in ms
  max = 7 * oneDayInSeconds * 1000
}

export const contextMenuDisabledAction: CSSProperties = {
  pointerEvents: "none",
  opacity: 0.6
};

export const INITIAL_FILTERS = {
  name: null,
  oaType: null,
  status: null
};

/**
 * Timeline costant values
 */
export const passageTimelineGroup = "passage-timeline-group";

export const dateTimeFormat = "ddd MMM DD YYYY HH:mm:ss";

export const passageGroup = {
  id: passageTimelineGroup,
  title: "Passages"
};

const masterTimelineGroup: CalendarTimelineGroup = {
  id: "master-timeline-group",
  title: "Master Timeline"
};

const proposedTimelineGroup: CalendarTimelineGroup = {
  id: "proposed-timeline-group",
  title: "Proposed Timeline"
};

const rejectedTimelineGroup: CalendarTimelineGroup = {
  id: "rejected-timeline-group",
  title: "Rejected Timeline"
};

export const calendartimelineGroups = {
  MASTER: masterTimelineGroup,
  PROPOSED: proposedTimelineGroup,
  REJECTED: rejectedTimelineGroup
};

// colors hexadecimal are taken from palette
export enum TimelineEntryTypeColor {
  EXECUTE = "#7AB9DB",
  TRACK = "#34435a",
  CONFLICT = "#EA412F",
  SELECTED = "#FFD267"
}
