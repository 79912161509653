import React, { useEffect, useReducer } from "react";
import { initialState, VisibiltyWindowContext, reducer } from "../../context";
import {
  Passage,
  VisibilityWindowsListBaseProps
} from "app/visibilityWindow/models";
import { getActiveSatellite } from "app/shared/utils";
import { connect } from "react-redux";
import { Relative } from "primitives";
import {
  WidgetHeader,
  Timeline,
  PassageTable,
  Filters
} from "app/visibilityWindow/components/VisibilityWindowsList/components";
import { getPassages, filterPassages } from "app/visibilityWindow/helpers";
import { sort } from "utils/arrays";

export const VisibilityWindowsListBase = (
  props: VisibilityWindowsListBaseProps
) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { passages, selectedSatellite, options } = props;

  const {
    begin,
    end,
    selectedGroundStation,
    showCalendar,
    sortBy,
    isSortAscending,
    filter
  } = state;

  useEffect(() => {
    if (!state.loading) {
      /**
       * This should be called only at the first rendering and
       * when begin&end are updated from the date picker
       */
      dataProvider(begin, end);
    }
  }, [options.satellites, begin, end]);

  const dataProvider = async (begin?: Date, end?: Date) => {
    if (begin && end) {
      const timerange = { begin, end };
      // Get the passsages and save them into the store
      dispatch({ type: "default", payload: { loading: true, begin, end } });
      await getPassages(selectedSatellite, options, timerange);
      dispatch({ type: "loading", payload: false });
    }
  };

  useEffect(() => {
    // Apply filters to all passages and save filtered result into the context
    const visibleResults = handleSortFilter(passages);
    if (sortBy) {
      dispatch({
        type: "default",
        payload: visibleResults
      });
    }
  }, [sortBy, isSortAscending, selectedGroundStation, filter, passages]);

  const handleSortFilter = (passgs: Passage[]) => {
    let filteredResults = filterPassages(passgs, state);
    if (sortBy) {
      filteredResults = {
        passages: sort(filteredResults.passages, sortBy, isSortAscending),
        groundStations: filteredResults.groundStations
      };
    }
    return filteredResults;
  };

  return (
    <VisibiltyWindowContext.Provider value={{ state, dispatch }}>
      <WidgetHeader {...props} />
      <Relative
        data-testid="visibility-windows"
        style={{
          display: "flex",
          flexFlow: "column",
          height: "100%"
        }}
      >
        {showCalendar && (
          <Timeline allPassages={passages} dataProvider={dataProvider} />
        )}
        {!showCalendar && begin && end && <Filters />}
        <PassageTable />
      </Relative>
    </VisibiltyWindowContext.Provider>
  );
};
const mapStateToProps = (state: any) => ({
  selectedSatellite: getActiveSatellite(state.constellations.dashboard),
  satelliteInstances: state.constellations.selected.satelliteInstances,
  passages: state.visibilityWindow.passages
});

const mapDispatchToProps = (dispatch: any) => ({});

export const VisibilityWindowsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(VisibilityWindowsListBase);
