import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { OperationActivityPagination } from "app/planningSystem/components/OperationActivityPagination";
import { getAllOperationActivitiesAction } from "app/planningSystem/actions";
import { FetchAllOperationActivityParams } from "app/planningSystem/models";

const mapStateToProps = (state: any) => ({
  pagination: state.planningSystem.oaPagination
});
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  getAllOperationActivities: (params?: FetchAllOperationActivityParams) =>
    dispatch(getAllOperationActivitiesAction(params))
});

export const OaPaginationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OperationActivityPagination);
