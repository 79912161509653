import styled from "styled-components";
import Area from "./Area";

const Sider = styled(Area)``;

Sider.defaultProps = {
  gridArea: "sider",
  height: "100%",
  overflow: "auto"
};

export default Sider;
