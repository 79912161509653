import { InputBase, SelectBox } from "app/shared";
import {
  DataTypeAll,
  AlertLevels,
  AlertLevelsWarning,
  AlertLevelsCritical
} from "./index";

export const alertSchema = (dataSource, isOneByOne = [], values = {}) => {
  const arraySize = dataSource.tmDataType.arraySize;
  let valueBoundaries = {};
  if (isOneByOne.indexOf("valueBoundary") === -1) {
    valueBoundaries = {
      minValue: {
        type: "number",
        title: "Min value",
        default:
          !values.valueBoundary || isNaN(values.valueBoundary.minValue)
            ? undefined
            : values.valueBoundary.minValue
      },
      maxValue: {
        type: "number",
        title: "Max value",
        default:
          !values.valueBoundary || isNaN(values.valueBoundary.maxValue)
            ? undefined
            : values.valueBoundary.maxValue
      }
    };
  } else {
    let items = [];
    Array.from(Array(arraySize)).forEach((x, index) => {
      const item = {
        type: "object",
        title: index.toString(),
        required: ["minValue", "maxValue"],
        properties: {
          minValue: {
            type: "number",
            title: "Min value",
            default:
              values.valueBoundary && !isNaN(values.valueBoundary.minValue)
                ? values.valueBoundary.minValue
                : undefined
          },
          maxValue: {
            type: "number",
            title: "Max value",
            default:
              values.valueBoundary && !isNaN(values.valueBoundary.maxValue)
                ? values.valueBoundary.maxValue
                : undefined
          }
        }
      };
      items.push(item);
    });
    valueBoundaries = {
      valueBoundaries: {
        type: "array",
        title: "Indexes",
        items: items
      }
    };
  }

  let thresholds = {};
  AlertLevels.forEach((alertLevel) => {
    if (isOneByOne.indexOf(alertLevel) === -1) {
      thresholds[alertLevel] = {
        lowerThreshold: {
          type: "number",
          title: "Lower Threshold",
          default:
            !values[alertLevel] ||
            isNaN(values[alertLevel].thresholds.lowerThreshold)
              ? undefined
              : values[alertLevel].lowerThreshold
        },
        upperThreshold: {
          type: "number",
          title: "Upper Threshold",
          default:
            !values[alertLevel] ||
            isNaN(values[alertLevel].thresholds.upperThreshold)
              ? undefined
              : values[alertLevel].upperThreshold
        }
      };
    } else {
      let items = [];
      Array.from(Array(arraySize)).forEach((x, index) => {
        const item = {
          type: "object",
          title: index.toString(),
          properties: {
            lowerThreshold: {
              type: "number",
              title: "Lower Threshold",
              default:
                values[alertLevel] &&
                values[alertLevel].thresholds &&
                !isNaN(values[alertLevel].thresholds.lowerThreshold)
                  ? values[alertLevel].thresholds.lowerThreshold
                  : undefined
            },
            upperThreshold: {
              type: "number",
              title: "Upper Threshold",
              default:
                values[alertLevel] &&
                values[alertLevel].thresholds &&
                !isNaN(values[alertLevel].thresholds.upperThreshold)
                  ? values[alertLevel].thresholds.upperThreshold
                  : undefined
            }
          }
        };
        items.push(item);
      });
      thresholds[alertLevel] = {
        thresholds: {
          type: "array",
          title: "Indexes",
          items: items
        }
      };
    }
  });

  const schema = {
    type: "object",
    required: ["valueBoundary", "alertLevels"],
    properties: {
      valueBoundary: {
        type: "object",
        required:
          isOneByOne.indexOf("valueBoundary") === -1
            ? ["maxValue", "minValue"]
            : [],
        properties: {
          isBulkAssign: {
            type: "boolean",
            title: "Assign Type",
            description:
              "Bulk assign will apply the same values for every index. You can also assign values index by index. Tip: If you change to the assign type to 'One by One', all indexes will be filled with the 'Bulk' assign current values.",
            default: isOneByOne.indexOf("valueBoundary") === -1,
            oneOf: [
              {
                const: true,
                title: "Bulk"
              },
              {
                const: false,
                title: "One By One"
              }
            ]
          },
          ...valueBoundaries
        }
      },
      alertLevels: {
        title: "Alert Levels",
        type: "object",
        description:
          "Alert levels with empty threshold values will not be created.",
        properties: {
          Warning: {
            type: "object",
            title: "Warning",
            properties: {
              isBulkAssign: {
                type: "boolean",
                title: "Assign Type",
                default: isOneByOne.indexOf(AlertLevelsWarning) === -1,
                oneOf: [
                  {
                    const: true,
                    title: "Bulk"
                  },
                  {
                    const: false,
                    title: "One By One"
                  }
                ]
              },
              ...thresholds[AlertLevelsWarning]
            }
          },
          Critical: {
            type: "object",
            title: "Critical",
            properties: {
              isBulkAssign: {
                type: "boolean",
                title: "Assign Type",
                default: isOneByOne.indexOf(AlertLevelsCritical) === -1,
                oneOf: [
                  {
                    const: true,
                    title: "Bulk"
                  },
                  {
                    const: false,
                    title: "One By One"
                  }
                ]
              },
              ...thresholds[AlertLevelsCritical]
            }
          }
        }
      }
    }
  };

  return schema;
};

export const alertUiSchema = (dataSource, isOneByOne = []) => {
  const arraySize = dataSource.tmDataType.arraySize;
  let valueBoundary = {};
  if (isOneByOne.indexOf("valueBoundary") === -1) {
    valueBoundary = {
      minValue: {
        "ui:widget": InputBase
      },
      maxValue: {
        "ui:widget": InputBase
      }
    };
  } else {
    valueBoundary.valueBoundaries = {
      items: {
        minValue: {
          "ui:widget": InputBase,
          classNames: "width-50"
        },
        maxValue: {
          "ui:widget": InputBase,
          classNames: "width-50"
        }
      }
    };
  }
  const alertLevelsInputs = {};
  AlertLevels.forEach((alertLevel) => {
    if (isOneByOne.indexOf(alertLevel) === -1) {
      alertLevelsInputs[alertLevel] = {
        lowerThreshold: {
          "ui:widget": InputBase
        },
        upperThreshold: {
          "ui:widget": InputBase
        }
      };
    } else {
      alertLevelsInputs[alertLevel] = {};
      alertLevelsInputs[alertLevel].thresholds = {
        items: {
          lowerThreshold: {
            "ui:widget": InputBase,
            classNames: "width-50"
          },
          upperThreshold: {
            "ui:widget": InputBase,
            classNames: "width-50"
          }
        }
      };
    }
  });
  const schema = {
    valueBoundary: {
      isBulkAssign: { "ui:widget": arraySize ? SelectBox : "hidden" },
      ...valueBoundary
    },
    alertLevels: {
      Warning: {
        isBulkAssign: { "ui:widget": arraySize ? SelectBox : "hidden" },
        ...alertLevelsInputs[AlertLevelsWarning]
      },
      Critical: {
        isBulkAssign: { "ui:widget": arraySize ? SelectBox : "hidden" },
        ...alertLevelsInputs[AlertLevelsCritical]
      }
    }
  };

  return schema;
};

export const dataSourceSchemaGenerator = (units) => {
  const unitsChoices = [null, ...units.map((el) => el.unit)];

  return {
    type: "object",
    required: ["name", "tmDataType"],
    properties: {
      name: {
        type: "string",
        title: "Name"
      },
      description: {
        type: ["string", "null"]
      },
      tmDataType: {
        type: "object",
        title: "Data type",
        required: ["type"],
        properties: {
          type: {
            type: "string",
            enum: DataTypeAll
          },
          arraySize: {
            type: "number",
            title: "Array size"
          }
        }
      },
      units: {
        type: ["object", "null"],
        default: null,
        properties: {
          unit: {
            type: ["string", "null"],
            default: null,
            enum: unitsChoices
          }
        }
      }
    }
  };
};

export const dataSourceUiSchema = {
  name: { "ui:widget": InputBase },
  description: { "ui:widget": InputBase },
  tmDataType: {
    type: { "ui:widget": SelectBox },
    arraySize: { "ui:widget": InputBase }
  },
  units: {
    unit: {
      "ui:widget": SelectBox,
      "ui:emptyValue": null
    }
  }
};

export const schemaGenerator = (units = []) => {
  return {
    type: "object",
    required: ["name"],
    properties: {
      name: {
        type: "string",
        title: "Name"
      },
      dataSources: {
        type: "array",
        hasRemove: true,
        items: dataSourceSchemaGenerator(units)
      }
    }
  };
};

export const uiSchema = {
  name: { "ui:widget": InputBase },
  dataSources: { "ui:widget": "hidden" }
};
