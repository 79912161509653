import React, { useContext, ChangeEvent } from "react";
import { VisibiltyWindowContext } from "app/visibilityWindow/context";
import { Flex, Box } from "primitives";
import {
  SelectContainerSimple as SelectContainer,
  SelectLabelSimple as SelectLabel,
  SelectSimple as Select,
  DatePicker
} from "components";

const Filters = () => {
  const {
    state: { groundStations, selectedGroundStation, begin, end },
    dispatch
  } = useContext(VisibiltyWindowContext);

  return (
    <Flex
      bg="fill.0"
      p={2}
      alignItems="flex-end"
      overflow="visible"
      data-testid={"vw-filters"}
    >
      <Box ml={7} minWidth="123px">
        <SelectContainer width="123px">
          <SelectLabel>Ground Station</SelectLabel>
          <Select
            data-testid={"visibility-windows-gs-filter"}
            width="115px"
            fontSize="14px"
            pb="1px"
            pt={0}
            height={27}
            borderColor="#fff"
            iconPt="1px"
            value={selectedGroundStation as string}
            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
              dispatch({
                type: "selectedGroundStation",
                payload: event.target.value
              })
            }
          >
            {groundStations.map((groundStation) => (
              <option key={groundStation} value={groundStation}>
                {groundStation}
              </option>
            ))}
          </Select>
        </SelectContainer>
      </Box>
      <Box overflow="visible" mr={2}>
        <DatePicker
          data-testid={"visibility-windows-from"}
          label="From"
          name="fromDate"
          placeholder={"Select a date"}
          handleChange={(event: any) =>
            dispatch({
              type: "begin",
              payload: event.value
            })
          }
          selected={begin}
        />
      </Box>
      <Box overflow="visible">
        <DatePicker
          data-testid={"visibility-windows-to"}
          label="To"
          name="toDate"
          placeholder={"Select a date"}
          handleChange={(event: any) =>
            dispatch({
              type: "end",
              payload: event.value
            })
          }
          selected={end}
        />
      </Box>
    </Flex>
  );
};

export { Filters };
