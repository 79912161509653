import { combineReducers } from "redux";
import constellationReducer from "app/constellation/reducers";
import satelliteReducer from "app/satellite/reducers";
import { dialogReducer } from "app/shared/dialog";
import dataSourcesReducer from "app/dataSource/reducers";
import { dashboardReducer, telemetryDataReducer } from "app/dashboard";
import appReducer from "app/feedback/reducers";
import alertReducer from "app/alert/reducers";
import { scriptExecutionReducer, scriptReducer } from "app/scripting/reducers";
import telecommandExecutionReducer from "app/telecommand/reducers/telecommandExecution";
import telecommandListReducer from "app/telecommand/reducers/telecommandList";
import { procedureReducer } from "app/procedure/reducers";
import { passageReducer } from "app/visibilityWindow";
import { groundStationReducer } from "app/groundStation/reducers";
import { resourceReducer } from "app/resources/reducers";
import telecommandStackReducer from "app/telecommandStack/reducers/telecommandStack";
import {
  timeControllerReducer,
  fetchStatusReducer
} from "app/shared/timeController";
import { grafanaReducer } from "app/telemetry/visualizations/Grafana/GrafanaIFrame";
import { keyManagerReducer } from "app/keyManager/reducers";
import { planningSystemReducer } from "app/planningSystem/reducers";
import { passageManagerReducer } from "app/passageManager/reducers";

export const dataReducers = combineReducers({
  telemetry: telemetryDataReducer,
  timeController: timeControllerReducer,
  fetchStatus: fetchStatusReducer
  // passages: passagesDataReducer -- add additional reducers here
});

export const reducers = combineReducers({
  grafana: grafanaReducer,
  alert: alertReducer,
  constellations: constellationReducer,
  satellite: satelliteReducer,
  dialog: dialogReducer,
  dashboard: dashboardReducer,
  datastore: dataReducers,
  dataSources: dataSourcesReducer,
  groundStations: groundStationReducer,
  telecommandExecution: telecommandExecutionReducer,
  telecommandList: telecommandListReducer,
  telecommandStack: telecommandStackReducer,

  // TODO: Potential refactor
  scripts: scriptReducer,
  scriptExecution: scriptExecutionReducer,
  procedures: procedureReducer,

  resources: resourceReducer,
  visibilityWindow: passageReducer,
  app: appReducer,
  keycloak: (keycloak = {}) => keycloak,
  passageManager: passageManagerReducer,
  keyManager: keyManagerReducer,
  ...((process.env.REACT_APP_KEY_PLANNING_SYSTEM_ACTIVE === "true" && {
    planningSystem: planningSystemReducer
  }) ||
    {})
});
