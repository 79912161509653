import { SatelliteInstance } from "app/satellite/models";
import { AlertDetails, AlertValueResponse } from "app/alert/models";
import { SatelliteDefinition } from "app/satelliteDefinition";
export interface TelemetryFrameHeader {
  auxiliaries: AuxiliaryField[];
}

export interface TelemetryFramePayload {
  auxiliaries: AuxiliaryField[];
  frames: Frame[];
}

export interface TelemetryFrame {
  satelliteId: Number;
  frameId: Number;
  submittedTelecommandId?: number;
  timestamp: string;
  frameName: string;
  header: TelemetryFrameHeader;
  payload: TelemetryFramePayload;
}

export interface Frame {
  id: number;
  name: string;
  systems?: Frame[];
  dataSources?: FrameDataSource[];
  auxiliaries?: AuxiliaryField[];
}

export interface AuxiliaryField {
  name: string;
  data: any;
  repr: string | null;
}

export interface FrameDataSource {
  id: number;
  name: string;
  data: any;
  repr: string | null;
}

export class AuroraTelemetryFrame implements TelemetryFrame {
  readonly satelliteId: Number;
  readonly frameId: Number;
  readonly timestamp: string;
  readonly frameName: string;
  readonly header: TelemetryFrameHeader;
  readonly payload: TelemetryFramePayload;

  static of(frame: TelemetryFrame) {
    return new AuroraTelemetryFrame(
      frame.satelliteId,
      frame.frameId,
      frame.timestamp,
      frame.frameName,
      frame.header,
      frame.payload
    );
  }

  private constructor(
    satelliteId: Number,
    frameId: Number,
    timestamp: string,
    frameName: string,
    header: any,
    payload: any
  ) {
    this.satelliteId = satelliteId;
    this.frameId = frameId;
    this.timestamp = timestamp;
    this.frameName = frameName;
    this.header = header;
    this.payload = payload;
  }

  extractReadings(): DataSourceReading[] {
    if (this.payload.frames) return this.extract(this.payload.frames[0], []);
    return [];
  }

  private extract(
    rootSystem: any,
    current: DataSourceReading[]
  ): DataSourceReading[] {
    let extracted: DataSourceReading[] = [];
    if (rootSystem.dataSources) {
      rootSystem.dataSources.forEach((ds: FrameDataSource) => {
        extracted.push({
          name: ds.name,
          data: ds.data,
          repr: ds.repr
        });
      });
    }

    if (rootSystem.systems) {
      rootSystem.systems.forEach(
        (sys: any) => (extracted = extracted.concat(this.extract(sys, [])))
      );
    }

    return current.concat(extracted);
  }
}

export interface DataSourceReading {
  name: string;
  data: any;
  repr: any;
}

export interface TelemetryDataSourceReadingProps {
  datasourceReading: DataSourceReading;
  nextPageUrl: string;
  previousPageUrl: string;
}

export interface TelemetryProps {
  children: (
    props: TelemetryDataSourceReadingProps | null
  ) => React.ReactElement | null;
  ids?: number[];
  satellite: SatelliteInstance;
  interval?: number;
  system?: any;
  multiple?: boolean;
  params?: {
    from: string | null;
    to?: string | null;
    paginationUrl?: string | null;
    pageSize?: number | null;
    windowSize?: number | null;
    timeUnit?: "Hours" | "Minutes" | "Seconds";
  };
  autoUpdates?: boolean;
  options?: any;
  satelliteDefinition?: SatelliteDefinition;
}

/**
 * Telemetry Response and DTO
 * **/

//Endpoint Responses
export interface DataSourceResponse {
  id: number;
  name: string;
  tmDataType: null;
  units: { unit: string } | null;
}

export interface ValueResponse {
  index: number | null;
  value: string;
  alert: [AlertValueResponse];
}

export interface ReadingResponse {
  bootCount: number;
  onboardTimeMillis: number;
  sendTimestamp: string;
  receiveTimestamp: string;
  valueIsArray: boolean;
  value: ValueResponse[];
  valueHumanReadable: string;
}

export interface DataSourceReadingResponse {
  dataSource: DataSourceResponse;
  readings: ReadingResponse[];
}

//DTOs
export interface DataSourceDTO {
  id: number;
  name: string | string[];
  tmDataType: null;
  units: { unit: string } | null;
  index?: number | null;
}

export interface ValueDTO {
  index: number | null;
  value: string;
  alert: AlertDetails | null;
}

export interface ReadingDTO {
  bootCount: number;
  onboardTimeMillis: number;
  sendTimestamp: string;
  receiveTimestamp: string;
  valueIsArray: boolean;
  value: ValueDTO[];
  valueHumanReadable: string;
}

export interface DataSourceReadingDTO {
  dataSource: DataSourceDTO;
  readings: ReadingDTO[];
}

/**
 * Graph Telemetry AUX
 */

export interface FormatedData {
  dataSource: DataSourceDTO;
  readings: FormatedReading[];
  key?: number;
}

export interface FormatedReading {
  id?: string;
  x: number;
  originalX: string;
  y: number;
  originalY: string;
  index: number;
  alert: ValueDTO | null;
}

export interface GraphReading {
  bootCount: number;
  onboardTimeMillis: number;
  sendTimestamp: string;
  receiveTimestamp: string;
  valueIsArray: boolean;
  value: ValueDTO;
  valueHumanReadable: string;
}

export interface GraphDataSourceReading {
  dataSource: DataSourceDTO;
  readings: GraphReading[];
  key?: number;
}

export interface Variable {
  label: string;
  name: string;
  value: string | number;
}

export const variableInitialState = [
  { name: "adcs", label: "ADCS", value: " " },
  { name: "eps", label: "EPS", value: " " },
  { name: "obc", label: "OBC", value: " " },
  { name: "payme", label: "PAY-ME", value: " " },
  { name: "pro", label: "PRO", value: " " }
];

/**
 * Telemetry Time Reference
 */

export enum TimeReference {
  SendUtcTime = "SendUtcTime",
  ReceiveUtcTime = "ReceiveUtcTime",
  OnboardTime = "OnboardTime"
}

export enum Ion {
  ionscv2 = "IONSCV2",
  ionscv3 = "IONSCV3",
  ionscv4 = "IONSCV4",
  ionscv5 = "IONSCV5",
  ionscv6 = "IONSCV6"
}

export enum GrafanaVariables {
  one = "1",
  two = "2",
  all = "All"
}

export enum QuickTimeRanges {
  "now-5m" = "5 mins",
  "now-10m" = "10 mins",
  "now-15m" = "15 mins",
  "now-30m" = "30 mins",
  "now-1h" = "1 hour",
  "now-3h" = "3 hours",
  "now-6h" = "6 hours",
  "now-12h" = "12 hours",
  "now-24h" = "24 hours",
  "now-2d" = "2 days",
  "now-7d" = "7 days"
}
