import { keyManagerDataProvider } from "app/network/dataProvider";
import { OperationType } from "app/network/models/constants";
import {
  MasterKey,
  MasterKeyRequestParams,
  SetMasterKeyStateResponse,
  ErrorResponse
} from "../models";
import { keyStatusMap } from "../utils/constants";

export const fetchMasterKey = async (
  satelliteID: number,
  params: { master_key_id: string }
): Promise<MasterKey | ErrorResponse | null> => {
  try {
    const response: any = await keyManagerDataProvider(
      OperationType.GET_LIST,
      `${satelliteID}/master/${params.master_key_id}`
    );
    return response.data;
  } catch (error) {
    return error as ErrorResponse;
  }
};

export const fetchAllMasterKey = async (
  satelliteID: number
): Promise<MasterKey[]> => {
  try {
    const response: any = await keyManagerDataProvider(
      OperationType.GET_LIST,
      `${satelliteID}/master`
    );
    return (Array.isArray(response.data) && response.data) || [];
  } catch (error) {
    return [];
  }
};

export const setMasterKeyState = async (
  satelliteID: number,
  params: MasterKeyRequestParams
): Promise<SetMasterKeyStateResponse> => {
  try {
    const response: any = await keyManagerDataProvider(
      OperationType.CREATE,
      `${satelliteID}/master/${params.master_key_id}/${
        keyStatusMap[params.state].endpoint
      }`
    );
    return response;
  } catch (error) {
    return error as ErrorResponse;
  }
};
