import React, { useState, useEffect, ChangeEvent, useCallback } from "react";
import { Flex, Box, Heading } from "primitives";
import { getKeycloakGroups } from "../services";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  InputField,
  AddButton,
  EditButton
} from "components";
import { PaginationSimple } from "app/shared";
import { filterList } from "./utils";
import { Group } from "../models";
import { GroupDelete } from "./GroupDelete";
import SortableTree from "react-sortable-tree";
import styled from "styled-components";

const Tree = styled(SortableTree)`
  .rst__rowContents {
    border: none;
    border-radius: 0px;
    background-color: #1f1e2c;
  }
  .rst__rowTitle {
    font-weight: normal;
  }
  .rst__rowContents {
    -webkit-box-shadow: none;
    min-width: 150px;
  }
  .rst__row > * {
    height: 41px;
  }
`;

export const GroupsList = () => {
  const [groups, setGroups] = useState<Array<Group>>([]);
  useEffect(() => {
    getKeycloakGroups().then((res: any) => setGroups(res.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex flexDirection="column">
      <RenderGroups groups={groups} />
    </Flex>
  );
};

interface RenderGroupsProps {
  groups: Group[];
}

const RenderGroups = ({ groups }: RenderGroupsProps) => {
  const [pageSize, setPageSize] = useState<number>(25);
  const [page, setPage] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>("");

  const onChangeSearch = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;
    setSearchText(value);
    setPage(0);
  };

  const filteredGroups = groups
    ? filterList(groups, page, pageSize, searchText)
    : [];

  return (
    <Box data-testid="GroupsList" mx={3}>
      <Flex alignItems="center" justifyContent="space-between" mb={2}>
        <Heading display={1}></Heading>
        <Flex alignItems="flex-end">
          <Box mr={4}>
            <InputField
              id="search-input"
              label="search"
              autoFocus={true}
              value={searchText}
              onChange={(
                e:
                  | ChangeEvent<HTMLInputElement>
                  | ChangeEvent<HTMLTextAreaElement>
              ) => onChangeSearch(e)}
            />
          </Box>
          <AddButton>Create</AddButton>
        </Flex>
      </Flex>
      <Table>
        <TableHead>
          <TableRow bg="fill.0">
            <TableCell width="300">NAME</TableCell>
            <TableCell width="900">SUBGROUPS</TableCell>
            <TableCell width="50" />
            <TableCell width="50" />
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredGroups.map((group: any) => {
            return (
              <TableRow key={group.id}>
                <TableCell>{group.name}</TableCell>
                <TableCell>
                  <RenderSubGroups groups={group.subGroups} />
                </TableCell>
                <TableCell align="center">
                  <EditButton>Edit</EditButton>
                </TableCell>
                <TableCell>
                  <GroupDelete
                    group={group}
                    //onChange={}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <PaginationSimple
        pageSize={pageSize}
        nextPageUrl={filteredGroups.length >= pageSize ? page + 1 : 0}
        previousPageUrl={page - 1}
        onChange={(newPage: string | number) => setPage(Number(newPage))}
        onPageSizeChange={(newPageSize: number) => {
          setPage(0);
          setPageSize(Number(newPageSize));
        }}
      />
    </Box>
  );
};

// Convert to tree structure using "react-sortable-tree"
const RenderSubGroups = (props: any) => {
  const [treeData, setTreeData] = useState<any>(null);

  const getTree = useCallback((systems: any): any[] => {
    if (!systems) return [];

    return systems.map((system: any) => {
      return {
        id: system.id,
        title: system.name,
        expanded: true,
        contentExpanded: true,
        children: getTree(system.subGroups)
      };
    });
  }, []);

  const buildTree = useCallback(() => {
    const subGroups = props.groups;
    // eslint-disable-next-line no-shadow
    const treeData: any = [];
    subGroups.forEach((subGroup: any) => {
      const tree = getTree(subGroup.subGroups);
      treeData.push({
        id: subGroup.id,
        title: subGroup.name,
        children: tree,
        expanded: true,
        contentExpanded: true,
        header: true
      });
    });
    setTreeData(treeData);
  }, [getTree, props.groups]);

  useEffect(() => buildTree(), [buildTree]);

  return (
    <Box height={131} width={900}>
      <Tree
        treeData={treeData}
        onChange={(data: any) => setTreeData(data)}
        getNodeKey={({ node }: any) => node.id}
        canDrag={false}
      />
    </Box>
  );
};
