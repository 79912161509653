import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { CustomSelect as Select } from "components";
import {
  TelecommandStackState,
  TelecommandStack as ITelecommandStack
} from "app/telecommandStack/models";
import { selectStack } from "app/telecommandStack/actions";
import { sortObjectsByLabel } from "utils/arrays";
import { filterTCStacksBySatID } from "../helpers";

export const StackSelector = () => {
  const { telecommandStacks } = useSelector<any, TelecommandStackState>(
    (state: any) => state.telecommandStack
  );
  const { id } = useSelector<any, any>((state: any) =>
    state.constellations.dashboard.find((satellite: any) => satellite.visible)
  );

  const options = filterTCStacksBySatID(id, telecommandStacks);

  const dispatch = useDispatch();
  return (
    <Select
      placeholder="Select Telecommand Stack"
      onChange={(e: any) => dispatch(selectStack(e.value))}
      border={1}
      options={sortObjectsByLabel(options, "label")}
      color="text.default"
    />
  );
};
