import React, { Component } from "react";
import { Form } from "app/shared";
import { Box, Heading, Flex, Text } from "primitives";
import { SuspenseMutation } from "app/network";
import { CancelButton, SaveButton } from "components";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { createScript } from "../services";
import { Script, CreateScriptCommand } from "../models";
import { schemaCreate, uiSchemaCreate } from "../models/schemas";
import { Resource } from "app/resources/models";
import { fetchResourcesAction } from "app/resources/actions";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

interface ScriptCreateProps extends RouteComponentProps {
  createScript: (data: CreateScriptCommand) => Promise<Script>;
  resources: Resource[];
  fetchResources: () => Promise<Resource[]>;
}

interface ScriptCreateState {
  formData: Script | null;
}

export class ScriptCreate extends Component<
  ScriptCreateProps,
  ScriptCreateState
> {
  state = {
    formData: null
  };

  componentDidMount() {
    this.props.fetchResources();
  }
  onChange(form: ScriptCreateState) {
    this.setState({ formData: form.formData });
  }

  async submit(
    e: React.FormEvent<HTMLInputElement>,
    formData: CreateScriptCommand
  ) {
    e.preventDefault();

    await this.props.createScript(formData);
    this.props.history.push("/scripts");
  }

  render() {
    const { formData } = this.state;
    const { resources } = this.props;

    return (
      <Box color="text.default" data-testid="ScriptCreate" mx={3}>
        <Flex mb={2}>
          <Heading display={1}>Script</Heading>
        </Flex>
        <Flex flexDirection="column" bg="fill.0" p={3}>
          <SuspenseMutation>
            {({ loading, error, action }) => (
              <Form
                formData={formData}
                schema={schemaCreate(resources || [])}
                uiSchema={uiSchemaCreate}
                disabled={loading}
                onSubmit={(
                  form: { formData: CreateScriptCommand },
                  e: React.FormEvent<HTMLInputElement>
                ) => action(() => this.submit(e, form.formData))}
                onChange={(form: ScriptCreateState) => this.onChange(form)}
              >
                <Box mt={4}>
                  <CancelButton
                    onClick={() => this.props.history.push("/scripts")}
                    mr={2}
                  >
                    Cancel
                  </CancelButton>
                  <SaveButton type="submit" disabled={loading}>
                    Save
                  </SaveButton>
                </Box>
                {error ? (
                  <Box my={2}>
                    <Text color="text.danger">Error...</Text>
                  </Box>
                ) : null}
              </Form>
            )}
          </SuspenseMutation>
        </Flex>
      </Box>
    );
  }
}

const mapStateToProps = (state: any) => ({
  resources: state.resources.resources
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  createScript,
  fetchResources: () => dispatch(fetchResourcesAction())
});

export const ScriptCreateContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ScriptCreate)
);
