import { Execution, ScriptListItem, ScriptExecutionListItem } from "../models";
import {
  ScriptActions,
  ScriptActionType,
  FetchScriptsAction
} from "../actions";
import { isEqual } from "lodash";

/**
 * Script execution store state
 */
interface ScriptExecutionStoreState {
  currentExecution?: Execution;
  runningScripts: ScriptExecutionListItem[];
}

const initialState: ScriptExecutionStoreState = {
  currentExecution: undefined,
  runningScripts: []
};

export const scriptExecutionReducer = (
  state: ScriptExecutionStoreState = initialState,
  action: ScriptActions
): ScriptExecutionStoreState => {
  switch (action.type) {
    case ScriptActionType.TerminateExecution: // Intentional fall-through
    case ScriptActionType.SelectExecution: // Intentional fall-through
    case ScriptActionType.ExecuteScript:
      // Always forces the current execution to update
      return { ...state, currentExecution: action.execution };
    case ScriptActionType.StreamExecutionDetails:
      // We only update the current execution if the incoming data is for the current execution
      if (
        (state.currentExecution &&
          state.currentExecution.id === action.execution.id) ||
        !state.currentExecution
      ) {
        return { ...state, currentExecution: action.execution };
      } else {
        return { ...state };
      }
    case ScriptActionType.FetchRunningScripts: {
      const oldScripts = state.runningScripts;
      const newScripts = action.runningScripts;

      const scriptsChanged = !isEqual(oldScripts, newScripts);
      return (
        (scriptsChanged && {
          ...state,
          runningScripts: action.runningScripts
        }) ||
        state
      );
    }
    default:
      return state;
  }
};

/**
 * Script definitions
 */
interface ScriptStoreState {
  scripts: ScriptListItem[];
}

const scriptsInitialState: ScriptStoreState = {
  scripts: []
};

export const scriptReducer = (
  state: ScriptStoreState = scriptsInitialState,
  action: FetchScriptsAction
): ScriptStoreState => {
  switch (action.type) {
    case ScriptActionType.FetchScripts:
      return { scripts: action.payload };
    default:
      return state;
  }
};
