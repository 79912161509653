import * as ActionTypes from "../models/constants";

/** satellite */
export const fetchSatelliteStart = () => ({
  type: ActionTypes.FETCH_SATELLITE_START
});

export const fetchSatelliteSuccess = (payload) => ({
  payload,
  type: ActionTypes.FETCH_SATELLITE_SUCCESS
});

export const fetchSatelliteError = () => ({
  type: ActionTypes.FETCH_SATELLITE_ERROR
});
/**  */

/** system */
export const selectSystemStart = (payload) => ({
  payload,
  type: ActionTypes.SELECT_SYSTEM
});
/**  */

/** dataSources */
export const selectDataSourcesStart = (payload) => ({
  payload,
  type: ActionTypes.SELECT_DATASOURCES
});
/**  */
