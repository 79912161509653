import { connect } from "react-redux";
import { SatelliteDefinitionCreate } from "../components/SatelliteDefinitionCreate";
import { withRouter } from "react-router-dom";
import { setFeedback } from "app/feedback/actions";

const mapDispatchToProps = (dispatch) => {
  return {
    setFeedback: (title, status, details = "") =>
      dispatch(setFeedback(title, status, details))
  };
};

export const SatelliteDefinitionCreateContainer = withRouter(
  connect(
    null,
    mapDispatchToProps
  )(SatelliteDefinitionCreate)
);
