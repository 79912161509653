import { connect } from "react-redux";
import { DashboardList } from "../components/DashboardList";
import {
  getUserDashboardsLists,
  updateUserDashboardsLists,
  addDashboards,
  getUserDashboards
} from "app/dashboard/services";
import { withRouter } from "react-router-dom";
import { setFeedback } from "app/feedback/actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { FeedbackStatus } from "app/feedback/models";

const mapStateToProps = (state: any) => ({
  constellationSelected: state.constellations.selected,
  dashboard: state.dashboard,
  selectedSatellite: state.constellations.dashboard.find(
    (satellite: any) => satellite.visible
  )
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getUserDashboardsLists: (satelliteDefinitionId: number | string | null) =>
      getUserDashboardsLists(satelliteDefinitionId),
    updateUserDashboardsLists: (
      satelliteDefinitionId: number | string | null,
      data: any
    ) => updateUserDashboardsLists(satelliteDefinitionId, data),
    getUserDashboards: (satelliteDefinitionIds: any) =>
      getUserDashboards(satelliteDefinitionIds),
    addDashboards: (dashboards: any) => addDashboards(dashboards),
    setFeedback: (title: string, status: FeedbackStatus, details = "") =>
      dispatch(setFeedback(title, status, details))
  };
};

export const DashboardListContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DashboardList)
);
