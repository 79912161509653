import React, { useCallback, useRef, useEffect } from "react";
import { Flex } from "primitives";
import { useDispatch, useSelector } from "react-redux";
import { DownloadButton, CancelButton } from "components";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Box,
  Button,
  Text
} from "primitives";
import useToggle from "components/toggler/hooks/useToggle";
import { BookerForm } from "./BookerForm";
import {
  getBookerConfigs,
  getPassageCandidates
} from "app/passageManager/actions";
import { Spinner } from "components";
import { isFetching } from "app/passageManager/utils";

export const BookerModal = (props: any) => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const { status } = useSelector((s: any) => s.passageManager);
  const defaultToggled = false;
  const [toggled, onToggle] = useToggle({
    defaultToggled: defaultToggled ? defaultToggled : false
  });

  const { config } = useSelector((s: any) => s.passageManager);

  useEffect(() => {
    dispatch(getBookerConfigs());
  }, []);

  const submit = useCallback(
    async (e: React.FormEvent<HTMLInputElement>, formData: any) => {
      e.preventDefault();
      const { configuration, daterange: { from = null, to = null } = {} } =
        formData || {};
      const result = await dispatch(
        getPassageCandidates(configuration, from, to)
      );
      if (Array.isArray(result)) {
        onToggle();
        props.history.push({ pathname: "/passage-manager-candidates" });
      }
    },
    []
  );

  const cancel = useCallback(() => {
    onToggle();
  }, []);

  return (
    <Flex ml={2}>
      <Flex justifyItems="space-between">
        {isFetching(status) ? (
          <Button size={"small"}>
            <Spinner size={14} />{" "}
          </Button>
        ) : (
          <DownloadButton size={"small"} onClick={onToggle}>
            {"FETCH CANDIDATES"}
          </DownloadButton>
        )}
      </Flex>
      <Dialog open={toggled} maxWidth="md" height="100%">
        <DialogTitle>
          Fetch Passages from Providers with a given configuration
        </DialogTitle>
        <Flex height="100%">
          <Box overflow="scroll" width="100%">
            <BookerForm
              {...props}
              formRef={formRef}
              closeModal={cancel}
              submit={submit}
              type="booker"
              readonly={true}
              config={config}
            />
          </Box>
        </Flex>
        <DialogActions>
          <Flex justifyItems="flex-end" alignItems="center">
            <CancelButton onClick={cancel} mx={1}>
              Cancel
            </CancelButton>
            <Button
              mx={2}
              type="submit"
              id="passage-manager"
              form="passage-manager"
            >
              {status?.candidates === "loading" ? (
                <Flex flexDirection="row" mx={2}>
                  <Spinner size={14} />
                  <Text color="text.default" mx={2}>
                    RUNNING...
                  </Text>
                </Flex>
              ) : (
                <Text color="text.default"> RUN </Text>
              )}
            </Button>
          </Flex>
        </DialogActions>
      </Dialog>
    </Flex>
  );
};
