import { connect } from "react-redux";
import { Logout } from "../components/Logout";

const mapStateToProps = (state: any) => ({
  kc: state.keycloak
});

export const LogoutContainer = connect(
  mapStateToProps,
  null
)(Logout);
