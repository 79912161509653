import { connect } from "react-redux";
import { deriveSessionKeyAction } from "app/keyManager/actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { MasterKeyDetail } from "../components/MasterKeyDetail";
import { DeriveSessionKeysParams } from "app/keyManager/models";
const mapStateToProps = (state: any) => ({
  selectedSatellite: state.constellations.dashboard.find(
    (satellite: any) => satellite.visible
  ),
  sessionKeysMap: state.keyManager.sessionKeysMap
});
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    deriveSessionKey: (satelliteID: number, params: DeriveSessionKeysParams) =>
      dispatch(deriveSessionKeyAction(satelliteID, params))
  };
};
export const MasterKeyDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MasterKeyDetail);
