import React from "react";
import { OptionData } from "app/visibilityWindow/models";
import { ColorOptions } from "app/visibilityWindow/helpers";

const TimelineLegend = ({
  list,
  filter
}: {
  list: OptionData[];
  filter: number | undefined;
}) => {
  return (
    (list.length > 0 && (
      <div
        style={{
          backgroundColor: "#2E4162",
          color: "#FFFFFF",
          padding: "0px 10px",
          height: "100%"
        }}
      >
        <div
          data-testid="timeline-legend"
          style={{ padding: 0, lineHeight: 1.5 }}
        >
          {list.map((item: OptionData, i: number) => {
            if (!filter || filter === item.id) {
              const color: any = ColorOptions[i] || "#b1d6ed";
              return (
                <div style={{ display: "flex", alignItems: "center" }} key={i}>
                  <span
                    style={{
                      height: 15,
                      width: 20,
                      marginRight: 10,
                      display: "inline-block",
                      backgroundColor: color
                    }}
                  ></span>
                  <span data-testid={item.id} style={{ whiteSpace: "nowrap" }}>
                    {item.label}
                  </span>
                </div>
              );
            }
          })}
        </div>
      </div>
    )) || <></>
  );
};

export { TimelineLegend };
