import { connect } from "react-redux";
import { DashboardEdit } from "../components/DashboardEdit";
import { getUserDashboard, editDashboard } from "../services";
import { getSatelliteDefinition } from "app/satelliteDefinition/services";
import { withRouter } from "react-router-dom";
import { setFeedback } from "app/feedback/actions";

const mapStateToProps = (state) => ({
  constellations: state.constellations.list
});

const mapDispatchToProps = (dispatch) => {
  return {
    editDashboard: (id, dashboardDefinition) =>
      editDashboard(id, dashboardDefinition),
    getUserDashboard: (id) => getUserDashboard(id),
    getSatelliteDefinition: (id) => getSatelliteDefinition(id),
    setFeedback: (title, status, details = "") =>
      dispatch(setFeedback(title, status, details))
  };
};

export const DashboardEditContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DashboardEdit)
);
