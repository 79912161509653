import React, { Component } from "react";
import { DropdownMenuLink } from "navigation/DropdownMenu";
export class UserManagementSelector extends Component {
  onChange() {
    // eslint-disable-next-line no-console
    console.log("TODO: Add action");
  }

  render() {
    return (
      <DropdownMenuLink to="/usermanagement">User Management</DropdownMenuLink>
    );
  }
}
