import React, { memo, useState, useEffect } from "react";
import {
  NextEventsProps,
  ISODateString,
  StartTimes,
  CalendarTimeline,
  StartTimesTypes
} from "../models";
import { Box, Heading } from "primitives";
import { getNearestEvent } from "app/planningSystem/utils/helpers";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  CountDown,
  TableHead
} from "components";
import { applyUTCOffset } from "components/TimelineRenderer/utils/helpers";

const getStartTimes = (selectedTimeline: CalendarTimeline): StartTimes => {
  const operations = selectedTimeline.items.filter(
    (itm) => itm?.group === selectedTimeline.groups[0]?.id
  );

  const passages = selectedTimeline.items.filter(
    (itm) => itm?.group === selectedTimeline.groups[1]?.id
  );

  /**
   * Getting the next events
   */
  const nearestOperation = getNearestEvent(operations);
  const nearestPassage = getNearestEvent(passages);

  /**
   * Adding the UTC offset to `start_time` since the Countdown compare it to a local `now`
   */
  const operationStartTime =
    nearestOperation &&
    applyUTCOffset(nearestOperation.start_time).toISOString();
  const passageStartTime =
    nearestPassage && applyUTCOffset(nearestPassage.start_time).toISOString();

  return { operationStartTime, passageStartTime };
};

export const NextEvents = memo(
  ({ selectedTimeline }: NextEventsProps) => {
    const [startTimes, setStartTimes] = useState<StartTimes>(
      getStartTimes(selectedTimeline)
    );

    useEffect(() => {
      setStartTimes({
        ...getStartTimes(selectedTimeline)
      });
    }, [selectedTimeline]);

    const handleOnExpire = (startTimeType: StartTimesTypes) => {
      setStartTimes({
        ...startTimes,
        [startTimeType]: getStartTimes(selectedTimeline)[startTimeType]
      });
    };

    return (
      <Box mb={9}>
        <Heading mb={3} mt={20} display={1}>
          Next Events
        </Heading>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Event name</TableCell>
              <TableCell>Start at</TableCell>
              <TableCell>Count down</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Next operation:</TableCell>
              <TableCell>{startTimes.operationStartTime}</TableCell>
              <TableCell>
                {(startTimes.operationStartTime && (
                  <CountDown
                    onExpire={() => handleOnExpire(StartTimesTypes.operation)}
                    expirationTime={
                      startTimes.operationStartTime as ISODateString
                    }
                  />
                )) ||
                  "No operation found"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Next passage:</TableCell>
              <TableCell>{startTimes.passageStartTime}</TableCell>
              <TableCell>
                {(startTimes.passageStartTime && (
                  <CountDown
                    onExpire={() => handleOnExpire(StartTimesTypes.passage)}
                    expirationTime={
                      startTimes.passageStartTime as ISODateString
                    }
                  />
                )) ||
                  "No passage found"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return (
      nextProps.selectedTimeline.uuid === prevProps.selectedTimeline.uuid &&
      nextProps.selectedTimeline.items.length ===
        prevProps.selectedTimeline.items.length
    );
  }
);
