import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AlertCounter } from "../components/AlertCounter";
import { getAlertcount } from "../services";

const mapStateToProps = (state: any) => ({
  alertCounter: state.alert.alertCounter,
  satellites: state.constellations.dashboard,
  refreshData: state.app.refreshData
});

const mapDispatchToProps = () => {
  return {
    getAlertcount: (satelliteId: number) => getAlertcount(satelliteId)
  };
};

export const AlertCounterContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AlertCounter)
);
