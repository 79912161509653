import {
  configOptionsGetTimeReferenceLabel,
  configOptionsGetTimeReferenceValue
} from "app/telemetry/utils";
import { createGlobalStyle } from "styled-components";
import moment from "moment";
import {
  DataSourceReadingDTO,
  ReadingDTO,
  ValueDTO,
  GraphDataSourceReading,
  GraphReading,
  FormatedData,
  DataSourceDTO,
  FormatedReading
} from "app/telemetry/models";
import { DataSourceOption } from "../shared/models";
import { AlertValueStatus } from "app/alert/models";
import {
  getBoundAxisX,
  getBoundAxisY,
  getSegmentTimeRange,
  getXDomain
} from "./utils/options";
import { clone } from "utils";
import { ITimeController } from "app/shared/timeController/models";
import { isEqual } from "lodash";
import { getDateFromTimeController } from "app/shared/timeController/utils";

export const GraphDataViewGlobalStyles = createGlobalStyle`
.tooltip-circle {
    cursor: pointer;
  }

  .graph-svg {
    fill: #000;
    fill-opacity: 0.3;
    background-color: rgb(31, 30, 44);
  }

  .graph-line-path {
    fill: none;
  }

  .axis {
    stroke: #fff;
  }

  .axis-labels text {
    fill: #fff;
    fill-opacity: 0.9;
    font-size: 12px;
    text-anchor: middle;
  }

  .axis-labels line {
    stroke: #fff;
  }

  .gridline {
    opacity: 0.2;
  }

  .tooltip-circle-highlighted {
    stroke-width: 20;
	stroke-linecap: round;
  }
`;

export const graphFormatData = (
  telemetryData: DataSourceReadingDTO[],
  options: any,
  previousFormatedData: FormatedData[] | null,
  timeController: ITimeController
): FormatedData[] => {
  const { datasourceLabels, dataSources } = options;
  //Flatten Telemetry Data
  const flatTelemetryData: GraphDataSourceReading[] = flattenTelemetryData(
    telemetryData,
    dataSources
  );

  //Filter out and reorder array datasources indexes
  let reorderedTelemetryData: any = [];
  const alreadyOrderedDataSources: number[] = [];
  if (dataSources) {
    flatTelemetryData.forEach((data: GraphDataSourceReading, index: number) => {
      if (dataSources && data.dataSource.id && data.readings.length > 0) {
        if (alreadyOrderedDataSources.indexOf(data.dataSource.id) === -1) {
          alreadyOrderedDataSources.push(data.dataSource.id);
          const dataSource: any = dataSources.find(
            (ds: any) => ds.id === data.dataSource.id
          );
          if (dataSource && dataSource.indexes) {
            dataSource.indexes.forEach((dataSourceIndex: any) => {
              flatTelemetryData.forEach((tD: GraphDataSourceReading) => {
                const readingIndex: number | null | undefined =
                  tD.dataSource.index;
                if (
                  data.dataSource.id === tD.dataSource.id &&
                  (readingIndex === null ||
                    readingIndex === dataSourceIndex.index)
                ) {
                  reorderedTelemetryData.push(tD);
                }
              });
            });
          } else {
            //If datasource indexes config options === null
            reorderedTelemetryData.push(data);
            alreadyOrderedDataSources.pop();
          }
        }
      } else {
        reorderedTelemetryData.push(data);
      }
    });
  } else {
    reorderedTelemetryData = flatTelemetryData;
  }

  const xDomain = getXDomain(options, timeController);
  const formatedDataList = reorderedTelemetryData
    .map((data: GraphDataSourceReading, index: number) => {
      //Ensure Sorted Data Readings
      //Fixes strange lines problem
      data.readings = data.readings.sort((a: any, b: any) => {
        if (
          configOptionsGetTimeReferenceValue(options, a) <
          configOptionsGetTimeReferenceValue(options, b)
        )
          return -1;
        if (
          configOptionsGetTimeReferenceValue(options, a) >
          configOptionsGetTimeReferenceValue(options, b)
        )
          return 1;
        return 0;
      });

      // the x with the seconds information has as reference the moment
      // when the operator opens the graph.
      const readings: any[] = [];
      data.readings.forEach((reading: GraphReading) => {
        const xValue = new Date(
          configOptionsGetTimeReferenceValue(options, reading)
        ).getTime();
        if (!xDomain || (xValue >= xDomain[0] && xValue <= xDomain[1]))
          readings.push({
            id: `${data.dataSource.id}[${data.dataSource.index}]-${xValue}`,
            x: xValue,
            y: Number(reading.value.value),
            originalX: `${moment
              .utc(configOptionsGetTimeReferenceValue(options, reading))
              .format(
                "YYYY-MM-DD HH:mm:ss.SSS"
              )} ${configOptionsGetTimeReferenceLabel(options)}`,
            originalY: reading.value.value,
            alert: getAlertFromReading(reading)
          });
      });

      if (datasourceLabels && datasourceLabels[data.dataSource.id]) {
        const arrayInitialCharacter = data.dataSource.name.indexOf("[");
        const datasourceName =
          arrayInitialCharacter === -1
            ? data.dataSource.name
            : (data.dataSource.name as string).substring(
                0,
                arrayInitialCharacter
              );
        data.dataSource.name = (data.dataSource.name as string).replace(
          datasourceName as string,
          datasourceLabels[data.dataSource.id]
        );
      }

      return {
        dataSource: data.dataSource,
        readings,
        key: index
      };
    })
    .filter((formatedData: any) => formatedData !== null);

  //Bound parameters
  const boundAxisX = getBoundAxisX(options);
  const boundAxisY = getBoundAxisY(options);
  if (boundAxisX && boundAxisY) {
    const datasourceX = formatedDataList.find(
      (d: any) =>
        d.dataSource.id === boundAxisX.dataSource &&
        (d.dataSource.index === null ||
          d.dataSource.index === boundAxisX.dataSourceIndex)
    );
    const datasourceY = formatedDataList.find(
      (d: any) =>
        d.dataSource.id === boundAxisY.dataSource &&
        (d.dataSource.index === null ||
          d.dataSource.index === boundAxisY.dataSourceIndex)
    );
    if (!datasourceX || !datasourceY) return [];
    const segmentTimeRange = getSegmentTimeRange(options);
    const readings: any[] = [];
    datasourceX.readings.forEach((readingX: any) => {
      const compareDate = moment(readingX.x);
      datasourceY &&
        datasourceY.readings.forEach((readingY: any) => {
          const startDate = moment(readingY.x).subtract(
            segmentTimeRange,
            "seconds"
          );
          const endDate = moment(readingY.x).add(segmentTimeRange, "seconds");
          if (compareDate.isBetween(startDate, endDate, undefined, "[]")) {
            readings.push({
              x: readingX.y,
              y: readingY.y,
              originalX: readingX.y,
              originalY: readingY.y
            });
          }
        });
    });

    const dataSource: DataSourceDTO = {
      id: 0,
      name: [datasourceX.dataSource.name, datasourceY.dataSource.name],
      tmDataType: null,
      units: null
    };
    const boundAxisDataList = [
      {
        key: 0,
        readings,
        dataSource
      }
    ];

    return boundAxisDataList;
  }
  //
  const { isGloballyControlled } = options;
  const { quickRange, from } = getDateFromTimeController(timeController);
  const isLiveStream = isGloballyControlled
    ? Boolean(quickRange)
    : !options.timeReference.from && !options.timeReference.to;
  if (!isLiveStream || (boundAxisX && boundAxisY)) return formatedDataList;

  /**Live stream fetch optimization */
  previousFormatedData &&
    previousFormatedData.forEach((previousData: FormatedData, index) => {
      (previousData.readings as any) = previousData.readings.filter(
        (reading: FormatedReading) =>
          moment(reading.x) >=
          moment().subtract(
            isGloballyControlled
              ? Math.round(
                  (new Date(Date.now()).valueOf() - new Date(from).valueOf()) /
                    60000
                )
              : options.liveStream.from,
            "minutes"
          )
      );
      const currentReadingIndex: number = formatedDataList.findIndex(
        (d: FormatedData) =>
          d.dataSource.id === previousData.dataSource.id &&
          (d.dataSource.index !== null ||
            d.dataSource.index === previousData.dataSource.index)
      );
      const currentReading: FormatedData =
        formatedDataList[currentReadingIndex];
      if (!currentReading) return false;
      currentReading.readings.forEach(
        (rd) =>
          !previousData.readings.find((pRd) => pRd.id === rd.id) &&
          previousFormatedData[index].readings.push(rd)
      );
      formatedDataList.splice(currentReadingIndex, 1);
    });

  previousFormatedData &&
    formatedDataList.forEach((formatedData: FormatedData) =>
      previousFormatedData.push(formatedData)
    );

  previousFormatedData &&
    previousFormatedData.forEach((formatedData, index) =>
      previousFormatedData[index].readings.sort((a, b) => a.x - b.x)
    );

  return previousFormatedData ? previousFormatedData : formatedDataList;
};

const getAlertFromReading = (reading: GraphReading) => {
  let alert = null;
  if (reading.value) {
    alert =
      reading.value.alert &&
      reading.value.alert.type !== AlertValueStatus.NormalValue
        ? reading.value.alert
        : null;
  }
  return alert;
};

const flattenTelemetryData = (
  telemetryData: DataSourceReadingDTO[],
  dataSources: DataSourceOption[]
): GraphDataSourceReading[] => {
  const flatTelemetryData: GraphDataSourceReading[] = [];

  telemetryData.forEach((tD: DataSourceReadingDTO) => {
    let dataSourceReading: GraphDataSourceReading = {
      dataSource: Object.assign({}, tD.dataSource),
      readings: []
    };
    const dataSourceName = tD.dataSource.name;
    if (tD.readings && tD.readings.length > 0) {
      const isArray = tD.readings[0].valueIsArray;
      if (!isArray) {
        dataSourceReading.dataSource.index = null;
        dataSourceReading.key = flatTelemetryData.length;
        tD.readings.forEach((reading: ReadingDTO) => {
          const flatReading: GraphReading = {
            ...reading,
            value: reading.value[0]
          };
          dataSourceReading.readings.push(flatReading);
        });
        flatTelemetryData.push(dataSourceReading);
      } else {
        const numberOfIndexes = tD.readings[0].value.length;
        Array.from(Array(numberOfIndexes)).forEach(
          (indexNumber: number, index: number) => {
            dataSourceReading = {
              dataSource: Object.assign({}, tD.dataSource),
              readings: []
            };
            dataSourceReading.dataSource.index = index;
            dataSourceReading.dataSource.name = `${dataSourceName}[${index}]`;
            dataSourceReading.key = flatTelemetryData.length;
            dataSourceReading.readings = [];
            tD.readings.forEach((reading: ReadingDTO) => {
              const indexReading = reading.value.find(
                (value: ValueDTO) => value.index === index
              );
              if (indexReading) {
                const flatReading: GraphReading = {
                  ...reading,
                  value: indexReading
                };
                dataSourceReading.readings.push(flatReading);
              }
            });
            flatTelemetryData.push(dataSourceReading);
          }
        );
      }
    } else {
      const dataSource = dataSources.find((ds) => ds.id === tD.dataSource.id);
      if (dataSource && dataSource.indexes && dataSource.indexes.length > 1) {
        dataSource.indexes.forEach((index) => {
          const dataSourceReadingClone = clone(dataSourceReading);
          dataSourceReadingClone.key = flatTelemetryData.length;
          dataSourceReadingClone.dataSource.name = `${dataSourceReadingClone.dataSource.name}[${index.index}]`;
          flatTelemetryData.push(dataSourceReadingClone);
        });
      } else {
        dataSourceReading.key = flatTelemetryData.length;
        flatTelemetryData.push(dataSourceReading);
      }
    }
  });
  return flatTelemetryData;
};

export const clearReadings = (formatedDataList: FormatedData[]) => {
  formatedDataList.forEach((formatedData: FormatedData) => {
    formatedData.readings = [];
  });
  return formatedDataList;
};

export const getGraphOptions = (props: any) => {
  const { options, advancedMode } = props;
  const showLegend =
    !options.legend ||
    !options.legend.visibilityMode ||
    options.legend.visibilityMode === "visible" ||
    advancedMode;
  const showAxis =
    !options.axis ||
    !options.axis.visibilityMode ||
    options.axis.visibilityMode === "visible" ||
    advancedMode;
  const showGrid =
    !options.grid ||
    !options.grid.visibilityMode ||
    options.grid.visibilityMode === "visible" ||
    advancedMode;

  const hasBoundAxis = getBoundAxisX(options) && getBoundAxisY(options);

  return { showLegend, showAxis, showGrid, hasBoundAxis };
};

export const isCorrectData = (options: any, formatedDataList: any) => {
  const optionsDsIds =
    options && options.dataSources.map((ds: { id: number }) => ds.id);
  const dataListDsIds = [
    ...new Set(
      formatedDataList.map(
        (ds: { dataSource: { id: any } }) => ds.dataSource.id
      )
    )
  ];
  return isEqual(optionsDsIds.sort(), dataListDsIds.sort());
};
