import uPlot from "uplot";

export function getCoordinates(u: uPlot, point: number, seriesIdx: number) {
  const { scale } = u.series[seriesIdx];
  const min = u.scales.y.min;
  const max = u.scales.y.max;
  const x = u.valToPos(u.data[0][point], "x", true);
  const yMin = Math.round(u.valToPos(Number(min), scale as string, true)) + 0.5;
  const yMax = Math.round(u.valToPos(Number(max), scale as string, true)) + 0.5;

  return { x, yMin, yMax };
}
