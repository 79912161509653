export const createResourceDTO = (data: any) => {
  let lastSlash = data.path.lastIndexOf("/");
  let dir = "";
  let name = "";
  if (lastSlash >= 0) {
    dir = data.path.slice(0, lastSlash);
    name = data.path.slice(++lastSlash, data.path.length);
  } else {
    name = data.path;
  }
  const dto = {
    resourceLocation: {
      dir,
      name
    }
  };
  return dto;
};

export const updateResourceDTO = (data: any) => {
  const dto = {
    location: data.path
  };
  return dto;
};

export const listOperationsDTO = (data: any) => {
  const dto = {
    opType: data.operationType,
    satIds: data.satelliteId
  };
  return dto;
};
