import React, { useContext } from "react";
import { Flex, Dialog, DialogTitle, DialogActions } from "primitives";
import { SuspenseMutation } from "app/network";
import {
  CancelButton,
  DeleteButton,
  ActivateButton,
  DeactivateButton,
  SuspendButton
} from "components";
import { KeyManagerContext } from "../context";
import {
  KeyStatus,
  ChangeStateDialogProps,
  SessionKeyRequestParams,
  MasterKeyRequestParams,
  ContextActionsNames
} from "../models";
import {
  keyStatusMap,
  dialogTestIdPrefix,
  TTL as ttl
} from "../utils/constants";
import { capitalize, isSessionKey, isMasterKey } from "../utils/helpers";

export const ChangeStateDialog = ({
  selectedSatellite,
  setMasterKeyState,
  setSessionKeyState
}: ChangeStateDialogProps) => {
  const {
    state: { changeStateDialog },
    dispatch
  } = useContext(KeyManagerContext);
  const { key, isOpen, setOpen, state } = changeStateDialog || {
    isOpen: false
  };

  const getActionParams = ():
    | SessionKeyRequestParams
    | MasterKeyRequestParams => {
    const _isSessionKey = isSessionKey(key);

    const baseParams = {
      master_key_id: (_isSessionKey
        ? key.parent_key_id
        : key?.key_id) as string,
      state: state as KeyStatus
    };

    const skParams = {
      ...baseParams,
      session_key_id: key?.key_id,
      ...(state === KeyStatus.ACTIVE && { ttl })
    };

    return (_isSessionKey && skParams) || baseParams;
  };

  const mutateAction = () => {
    try {
      const params = getActionParams();
      if (isSessionKey(key)) {
        setSessionKeyState(
          selectedSatellite.id,
          params as SessionKeyRequestParams
        );
      } else if (isMasterKey(key)) {
        setMasterKeyState(selectedSatellite.id, params);
      }

      return (
        setOpen &&
        setOpen({ type: ContextActionsNames.changeStateDialog, payload: null })
      );
    } catch (e) {
      console.warn(e);
    }
  };

  const actionButtonRenderer = (): React.ReactElement => {
    switch (state) {
      case KeyStatus.ACTIVE:
        return (
          <ActivateButton
            data-testid={"activate-button"}
            onClick={mutateAction}
          >
            Activate
          </ActivateButton>
        );
      case KeyStatus.DEACTIVATED:
        return (
          <DeactivateButton
            data-testid={"deactivate-button"}
            onClick={mutateAction}
          >
            Deactivate
          </DeactivateButton>
        );
      case KeyStatus.DESTROYED:
        return (
          <DeleteButton data-testid={"delete-button"} onClick={mutateAction}>
            Destroy
          </DeleteButton>
        );
      case KeyStatus.SUSPENDED:
        return (
          <SuspendButton data-testid={"suspend-button"} onClick={mutateAction}>
            Suspend
          </SuspendButton>
        );
      default:
        return <></>;
    }
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      data-testid={`${dialogTestIdPrefix}${
        state && keyStatusMap[state].actionName
      }`}
    >
      <SuspenseMutation>
        {({ loading, action }) => {
          return (
            <>
              <DialogTitle>{`${
                state && capitalize(keyStatusMap[state].actionName || "")
              } the key?`}</DialogTitle>

              <DialogActions>
                <Flex alignItems="center" mx={1}>
                  {actionButtonRenderer()}
                  <CancelButton
                    ml={1}
                    onClick={() =>
                      setOpen &&
                      setOpen({
                        type: ContextActionsNames.changeStateDialog,
                        payload: null
                      })
                    }
                  >
                    Cancel
                  </CancelButton>
                </Flex>
              </DialogActions>
            </>
          );
        }}
      </SuspenseMutation>
    </Dialog>
  );
};
