import { displayAlertInfo } from "./alertInfo";
import { STYLES } from "./tooltip";
import uPlot from "uplot";
import { alertHasDetails, getAlertDetails } from "./utils";

export function clickListener(u: uPlot) {
  const graph = u.root.querySelector(".u-over");
  if (!graph) return;
  // @ts-ignore
  graph.addEventListener("click", (e: MouseEvent) => handleClick(e, u));
}

function handleClick(e: MouseEvent, u: uPlot) {
  const idx = getClickedIndex(e, u);
  // @ts-ignore
  const alertDetails = getAlertDetails(u, idx);
  if (!alertHasDetails(alertDetails)) {
    hideTooltip(u);
    return;
  }
  showTooltip(u, alertDetails);
}

function getClickedIndex(e: MouseEvent, u: uPlot): number | null {
  const graph = u.root.querySelector(".u-over");
  if (!graph) return null;
  const rect = graph.getBoundingClientRect();
  const left = e.clientX - rect.left;
  return u.posToIdx(left);
}

function showTooltip(u: uPlot, alertDetails: any) {
  const alertTooltip = u.root.querySelector(".uplot-tooltip") as HTMLElement;
  if (!alertTooltip) return;
  const overlayEl = u.over;
  overlayEl.style.overflow = "visible";
  overlayEl.appendChild(alertTooltip);

  alertTooltip.classList.add("tooltip-hover");
  Object.assign(alertTooltip.style, STYLES);
  alertTooltip.innerHTML = displayAlertInfo(alertDetails);

  positionTooltip(u, alertTooltip);
  alertTooltip.style.display = "block";
}

function hideTooltip(u: uPlot) {
  const alertTooltip = u.root.querySelector(".uplot-tooltip") as HTMLElement;
  if (alertTooltip) {
    alertTooltip.style.display = "none";
  }
}

function positionTooltip(u: uPlot, tooltip: HTMLElement) {
  const graph = u.root.querySelector(".u-over") as HTMLElement;
  if (!graph) return;

  const { left: cLeft, top: cTop } = u.cursor;
  const graphDim = graph.getBoundingClientRect();
  const tooltipDim = tooltip.getBoundingClientRect();

  // @ts-ignore
  const isNearGraphEdge = cLeft > graphDim.width * 0.8;
  const shiftY = 10;
  let shiftX = 20;
  if (isNearGraphEdge) {
    shiftX = -(tooltipDim.width + 20);
  }
  const left = Math.max(
    0,
    // @ts-ignore
    Math.min(cLeft + shiftX, graphDim.width - tooltipDim.width)
  );
  const top = Math.max(
    0,
    // @ts-ignore
    Math.min(cTop - shiftY, graphDim.height - tooltipDim.height)
  );
  tooltip.style.transform = `translate(${left}px, ${top}px)`;
}
