const LINE_WIDTH = 0.5;
const isNotSet = (arr: any[]) => Array.isArray(arr) && arr.length === 0;

enum THRESHOLD_COLORS {
  "warning" = "#FFEA00",
  "critical" = "#F3172D"
}

export const thresholdsPlugin = (limits: any): uPlot.Plugin => ({
  hooks: {
    drawSeries(u: uPlot) {
      const warning = limits?.warning ?? [];
      const critical = limits?.critical ?? [];
      if (isNotSet(warning) && isNotSet(critical)) {
        return;
      }
      const thresholds = [
        ...warning.map((val: any) => {
          return {
            value: val,
            color: THRESHOLD_COLORS.warning,
            width: LINE_WIDTH
          };
        }),
        ...critical.map((val: any) => {
          return {
            value: val,
            color: THRESHOLD_COLORS.critical,
            width: LINE_WIDTH
          };
        })
      ];

      thresholds.forEach((t) => createThresholdLines(u, t));
    }
  }
});

function createThresholdLines(u: uPlot, threshold: any) {
  const ctx = u.ctx;
  const offsetYAxis = 80;
  const graphWidth = u.bbox.width;

  const y = u.valToPos(threshold.value, "y", true);
  // @ts-ignore
  ctx.strokeStyle = threshold.color;
  ctx.lineWidth = threshold.width;
  ctx.beginPath();
  // @ts-ignore
  ctx.moveTo(offsetYAxis, y);
  // @ts-ignore
  ctx.lineTo(graphWidth + offsetYAxis, y);
  ctx.stroke();
}
