import React, { createRef } from "react";
import * as d3 from "d3";
import moment from "moment";
import { themeGet } from "styled-system";
import { withTheme } from "styled-components";
import { FormatedReading } from "app/telemetry/models";
import {
  getCriticalLimits,
  getGridThickness,
  getPlotThickness,
  getWarningLimits,
  getXTicks,
  getYTicks,
  convertSelectionToTime
} from "./utils/options";
import { getAlertValueColor } from "app/alert/helpers";
import { Colors, Severity } from "app/alert/models";
import { getDataSourceAlert } from "app/dataSource";
import { DataProviderResponse } from "app/network/models";
import { AlertLevel } from "app/dataSource/models";
import { Absolute, Button, Icon } from "primitives";
import { GraphLegend } from "./LineLegend";
import { isEqual } from "lodash";
import { LineChartState, LineChartProps } from "./models";

const MARGIN = {
  top: 25,
  left: 55,
  bottom: 40,
  right: 0
};

const xFormatTime = (value: number) => {
  return moment(value).utc().format("HH:mm:ss");
};
const xFormatDate = (value: number) => {
  return moment(value).utc().format("DD/MM");
};

const yFormat = (value: number) => {
  if (value.toString().length > 5) return value.toExponential();
  else return value;
};

const MIN_ZOOM_PIXELS = 20;

class LineChart extends React.Component<LineChartProps, LineChartState> {
  private id: number;
  private xTicks: any;
  private yTicks: any;
  private svg: any;
  private line: any;
  private x: any;
  private y: any;
  private color: any;
  private keys: any;
  private min: any;
  private max: any;
  private tooltipDiv: any;
  private updatingChart = false;
  private renderChart = false;
  private mergedData: FormatedReading[] = [];
  private dataSourceAlertData: any = {
    warning: [],
    critical: []
  };
  private selection: any;
  private fixedTooltip: null | string | undefined = null;

  private legendRef: any = createRef<HTMLDivElement>();
  private yUnit: string | null = null;
  private brush: any = null;

  constructor(props: LineChartProps) {
    super(props);
    this.state = {
      selectedIndex: null,
      selectedDataPoint: null,
      dimensions: {
        width: 0,
        height: 0,
        margin: MARGIN
      },
      extent: null
    };
    //Generate random id
    this.id = Math.random();
  }

  async componentDidMount() {
    this.tooltipDiv = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .attr("id", "tooltip")
      .style("opacity", 0);
    this.renderLine();

    if (
      this.props.options.limits &&
      this.props.options.limits.type === "datasource"
    ) {
      this.updateDataSourceLimits();
    }
  }

  componentWillUnmount() {
    this.tooltipDiv.remove();
  }

  componentWillReceiveProps(nextProps: LineChartProps) {
    const { showAxis, data } = this.props;
    const { dimensions } = this.state;
    this.updateDimensions();
    if (this.yUnit === null && data.length) {
      const units = [
        ...new Set(
          data.map((ds) => ds.dataSource.units && ds.dataSource.units.unit)
        )
      ];
      this.yUnit = units.length === 1 && units[0] !== "-" ? units[0] : "";
      if (this.yUnit && showAxis) {
        this.setState({
          dimensions: {
            ...dimensions,
            margin: {
              ...dimensions.margin,
              left: 70
            }
          }
        });
      }
    }
    if (nextProps.showAxis !== showAxis && this.yUnit) {
      const newLMargin = nextProps.showAxis ? 70 : 55;
      this.setState({
        dimensions: {
          ...dimensions,
          margin: {
            ...dimensions.margin,
            left: newLMargin
          }
        }
      });
    }
  }

  componentDidUpdate(prevProps: LineChartProps, prevState: LineChartState) {
    const { selectedIndex } = prevState;
    const { options } = prevProps;
    const { id, timeController, yExtents } = this.props;
    const { quickRange, from, to } = timeController;
    const { extent } = this.state;
    if (prevProps.showLegend !== this.props.showLegend) {
      this.updateDimensions();
    }
    if (options && options.isGloballyControlled) {
      const yExtent = yExtents && yExtents[id];
      if (!yExtent && extent) {
        this.setState({ extent: null }, () => this.updateChart());
      } else if (yExtent && (!extent || !isEqual(yExtent, extent.y))) {
        this.setState({ extent: { x: null, y: yExtent } }, () =>
          this.updateChart()
        );
      }
    }
    if (
      this.props.xDomain &&
      prevProps.xDomain &&
      this.props.xDomain[0] !== prevProps.xDomain[0] &&
      !extent &&
      (!(options && options.isGloballyControlled) || quickRange)
    ) {
      this.updateChart();
    }
    if (
      options &&
      options.isGloballyControlled &&
      (from !== prevProps.timeController.from ||
        to !== prevProps.timeController.to) &&
      !quickRange
    ) {
      this.updateChart();
    }
    if (selectedIndex !== this.state.selectedIndex) {
      this.renderTooltips();
    }
    if (options.plot.type !== this.props.options.plot.type) {
      this.renderLine();
    }
    if (
      this.props.options.limits &&
      options.limits &&
      ((this.props.options.limits.type !== options.limits.type &&
        this.props.options.limits.type === "datasource") ||
        this.props.options.limits.dataSourceIndex !==
          options.limits.dataSourceIndex ||
        this.props.options.limits.dataSource !== options.limits.dataSource)
    )
      this.updateDataSourceLimits();

    if (!isEqual(this.props.data, prevProps.data)) {
      this.updateChart();
    }
  }

  getInnerDimensions(width: number, height: number) {
    const { dimensions } = this.state;
    const { showLegend } = this.props;
    const innerWidth = width - dimensions.margin.left;
    const legendHeight =
      this.legendRef.current && this.legendRef.current.clientHeight;
    const innerHeight =
      height -
      dimensions.margin.bottom -
      dimensions.margin.top -
      (showLegend ? legendHeight || 26 : 0);
    return { innerWidth, innerHeight };
  }

  updateDimensions() {
    const { options, addExtentToGraph, id, containerRef } = this.props;
    const { dimensions, extent } = this.state;
    const { clientWidth: width, clientHeight: height } = containerRef.current;
    const { innerWidth, innerHeight } = this.getInnerDimensions(width, height);

    if (innerHeight !== dimensions.height || innerWidth !== dimensions.width) {
      dimensions.width = innerWidth;
      dimensions.height = innerHeight;
      if (options && options.isGloballyControlled && extent && extent.y) {
        addExtentToGraph({ index: id, yExtent: null });
      }
      this.setState({ dimensions, extent: null }, () => {
        this.updateChart();
        this.renderClipping();

        // Update the brushing
        this.brush.extent([
          [dimensions.margin.left, dimensions.margin.top],
          [dimensions.width, dimensions.height]
        ]);
        this.selection.call(this.brush);
      });
    }
  }

  setNewExtent(selection: number[][]) {
    const { extent } = this.state;
    const { options, addExtentToGraph } = this.props;
    // creating a new extent from user zoom selection
    const newExtent = {
      x:
        selection[1][0] - selection[0][0] > MIN_ZOOM_PIXELS
          ? [selection[0][0], selection[1][0]]
          : extent && extent.x,
      y:
        selection[1][1] - selection[0][1] > MIN_ZOOM_PIXELS
          ? [selection[0][1], selection[1][1]]
          : extent && extent.y
    };
    if (!isEqual(newExtent, extent)) {
      // setting the new extent
      if (options && options.isGloballyControlled) {
        addExtentToGraph({ index: this.props.id, yExtent: newExtent.y });
      } else {
        this.setState({ extent: newExtent }, () => {
          this.updateChart();
        });
      }
    }
  }

  renderZoomUtils() {
    const { dimensions } = this.state;
    const { options, setFromTo } = this.props;

    // Add brushing
    this.brush = d3
      .brush()
      .extent([
        [dimensions.margin.left, dimensions.margin.top],
        [dimensions.width, dimensions.height]
      ])
      .on("end", (event) => {
        const { selection } = event;
        if (
          options &&
          options.isGloballyControlled &&
          selection &&
          selection[1][0] - selection[0][0] > MIN_ZOOM_PIXELS
        ) {
          const newXDomain = convertSelectionToTime(
            selection,
            this.props.xDomain,
            this.state.dimensions
          );
          if (newXDomain) {
            setFromTo({
              from: new Date(newXDomain[0]).valueOf(),
              to: new Date(newXDomain[1]).valueOf()
            });
          }
        }
        if (selection) {
          if (options && !options.isGloballyControlled)
            this.props.setPaused(true);
          this.setNewExtent(selection);
          this.brush.move(this.selection, null);
        }
      });

    // Create the line variable: where both the line and the brush take place
    const line = this.svg
      .append("g")
      .attr("clip-path", `url(#${`clip-${this.id}`})`);

    // Add the brushing
    this.selection = line.append("g").attr("class", "brush").call(this.brush);
  }

  renderClipping() {
    const { dimensions } = this.state;
    if (document.getElementById(`defs-${this.id}`)) {
      (document.getElementById(`defs-${this.id}`) as HTMLElement).remove();
    }

    this.svg
      .append("defs")
      .attr("id", `defs-${this.id}`)
      .append("svg:clipPath")
      .attr("id", `clip-${this.id}`)
      .attr("class", `clip-${this.id}`)
      .append("svg:rect")
      .attr("id", `clip-rect-${this.id}`)
      .attr("width", dimensions.width - dimensions.margin.left)
      .attr("height", dimensions.height - dimensions.margin.bottom)
      .attr("x", dimensions.margin.left)
      .attr("y", dimensions.margin.bottom);
  }

  renderTicks() {
    const { options, showGrid, showAxis, hasBoundAxis } = this.props;
    const { dimensions } = this.state;

    this.xTicks = this.x
      .ticks(getXTicks(options))
      .map((d: number, index: number) =>
        this.x(d) > dimensions.margin.bottom && this.x(d) < dimensions.width ? (
          <g
            transform={`translate(${this.x(d)},${
              dimensions.height + dimensions.margin.bottom
            })`}
            key={index}
          >
            {showAxis && (
              <>
                <text transform="translate(0,-10)">
                  {hasBoundAxis ? d : xFormatTime(d)}
                </text>
                {hasBoundAxis ? (
                  d
                ) : (
                  <text transform="translate(0,5)">{xFormatDate(d)}</text>
                )}
                <line
                  x1="0"
                  x2="0"
                  y1="0"
                  y2="5"
                  transform={`translate(0, ${-dimensions.margin.bottom})`}
                />
              </>
            )}
            {showGrid && (
              <line
                className="gridline"
                strokeWidth={getGridThickness(options)}
                x1="0"
                x2="0"
                y1={-dimensions.height}
                y2="3"
                transform={`translate(0,-5)`}
              />
            )}
          </g>
        ) : null
      );

    this.yTicks = this.y
      .ticks(getYTicks(options))
      .map((d: number, index: number) =>
        this.y(d) > 10 && this.y(dimensions.height) < dimensions.height ? (
          <g
            transform={`translate(${dimensions.margin.left},${this.y(d)})`}
            key={index}
          >
            {showAxis && (
              <>
                <text style={{ textAnchor: "end" }} x="-10" y="4">
                  {`${yFormat(d)} ${this.yUnit ? this.yUnit : ""}`}
                </text>
                <line x1="0" x2="5" y1="0" y2="0" transform="translate(-5,0)" />
              </>
            )}
            {showGrid && (
              <line
                className="gridline"
                strokeWidth={getGridThickness(options)}
                x1="0"
                x2={dimensions.width - dimensions.margin.left}
                y1="0"
                y2="0"
                transform="translate(-5,0)"
              />
            )}
          </g>
        ) : null
      );
  }

  closeTooltips() {
    const { selectedDataPoint } = this.state;
    const tooltips = document.getElementsByClassName(
      "tooltip"
    ) as HTMLCollectionOf<HTMLElement>;
    for (const tooltip of tooltips) {
      if (tooltip && tooltip.style) tooltip.style.display = "none";
    }
    if (selectedDataPoint) {
      const element = document.getElementById(selectedDataPoint.id as string);
      if (element) {
        const { options } = this.props;
        const plotThickness = getPlotThickness(options);
        const radius = document.createAttribute("r");
        radius.value = plotThickness + 1;
        element.attributes.setNamedItem(radius);
      }
    }
    this.tooltipDiv.style("opacity", 0).style("display", "none !important");
    this.setState({ selectedDataPoint: null });
    this.fixedTooltip = null;
  }

  renderTooltips() {
    const { selectedIndex, selectedDataPoint } = this.state;
    const { options } = this.props;
    const plotThickness = getPlotThickness(options);
    this.svg.selectAll(".tooltip-circle").remove();

    const topTooltips: string[] = [];
    this.svg &&
      this.svg
        .selectAll("tooltip-circle")
        .data(this.mergedData)
        .enter()
        .append("circle")
        .attr("class", "tooltip-circle")
        .attr("id", (d: FormatedReading) => {
          if (selectedIndex === d.index) {
            topTooltips.push(d.id as string);
          }
          return d.id;
        })
        .attr("r", (d: FormatedReading) =>
          selectedDataPoint && selectedDataPoint.id === d.id
            ? plotThickness + 3
            : plotThickness + 1
        )
        .attr("cx", (d: FormatedReading) => this.x(d.x))
        .attr("cy", (d: FormatedReading) => this.y(d.y))
        .attr("fill", (d: FormatedReading) => this.getDataPointColor(d))
        .attr("fill-opacity", (d: FormatedReading) => {
          return selectedIndex === null || selectedIndex === d.index ? 1 : 0.2;
        })
        .attr("clip-path", `url(#clip-${this.id})`)
        .on("mouseover", (event: React.MouseEvent, d: FormatedReading) => {
          // de-highlight previous highlighted data point
          if (this.fixedTooltip) {
            const element = document.getElementById(
              this.fixedTooltip as string
            );
            if (element) {
              element.setAttribute("r", plotThickness + 1);
              element.setAttribute("fill", this.getDataPointColor(d, true));
            }
            this.fixedTooltip = null;
          }
          // highlight event target (data point)
          const radius = document.createAttribute("r");
          radius.value = plotThickness + 3;
          (event.target as any).attributes.setNamedItem(radius);
          //
          if (selectedIndex === null || selectedIndex === d.index) {
            const { data } = this.props;
            if (!selectedDataPoint || selectedDataPoint.id !== d.id)
              this.setState({ selectedDataPoint: d });
            const dataSource = data[d.index].dataSource;
            const color = d.alert ? getAlertValueColor(d.alert) : null;
            let hexColor = "#f5f5f5";
            if (color) hexColor = themeGet(color)(this.props);
            this.tooltipDiv.style("display", "inline");
            this.tooltipDiv.transition().duration(200).style("opacity", 1);
            this.tooltipDiv
              .html(
                `<div style="display: flex; flex-direction: column; color: ${hexColor}">
                <div style="display: flex; justify-content: space-between">                                   
                    <div style="display: flex; margin-bottom: 4px; align-items: center">
                        <div style="background-color: ${this.color(
                          d.index
                        )}; height: 4px; width: 14px; margin-right: 8px; border-radius: 1px">
                        </div>
                        <span>${
                          Array.isArray(dataSource.name)
                            ? dataSource.name[1]
                            : dataSource.name
                        } ${
                          dataSource.units && dataSource.units.unit !== "-"
                            ? dataSource.units.unit
                            : ""
                        }</span>
                        <span style="margin-left: 16px; color: ${hexColor}">${d.y.toFixed(
                          4
                        )}</span>
                </div>
                    <span onclick="document.getElementById('reset-selected-data-point-${
                      this.id
                    }').click()" 
                    style="cursor: pointer; margin-left: 12px; margin-top: -3px; color: #f5f5f5c2">
                    &#x2715
                    </span>
                </div>
                <span>${d.originalX}</span>
                </div>`
              )
              .style("left", `${event.pageX + 10}px`)
              .style("top", `${event.pageY - 28}px`)
              .style("position", "absolute");
          }
        })
        .on("mouseout", (event: React.MouseEvent, d: FormatedReading) => {
          if (this.fixedTooltip) return false;
          this.tooltipDiv
            .transition()
            .duration(500)
            .style("opacity", 0)
            .style("display", "none");

          const radius = document.createAttribute("r");
          radius.value = plotThickness + 1;
          (event.target as any).attributes.setNamedItem(radius);
          this.setState({ selectedDataPoint: null });
        })
        .on("click", (event: React.MouseEvent, d: FormatedReading) => {
          this.fixedTooltip = d.id;
        });

    //Tool tip circles from the selected path should be on top of the others
    //In order to do this we are using the <use> tag solution
    //Better explained here: https://stackoverflow.com/questions/19958624/apply-z-index-to-svg-path/24013696
    if (topTooltips.length > 0) {
      this.svg &&
        this.svg
          .selectAll("tooltip-circle-top")
          .data(topTooltips)
          .enter()
          .append("use")
          .attr("href", (id: string) => `#${id}`);
    }
  }

  getDataPointColor(d: FormatedReading, deselectDataPoint = false) {
    const { options } = this.props;
    const { selectedDataPoint } = this.state;
    if (
      !deselectDataPoint &&
      selectedDataPoint &&
      selectedDataPoint.id === d.id
    )
      return "#fff";
    else if (!d.alert || options.showAlerts === false)
      return this.color(d.index);
    else {
      const color = getAlertValueColor(d.alert);
      let hexColor = "";
      if (color) hexColor = themeGet(color)(this.props);
      return hexColor;
    }
  }

  renderLine() {
    const { options, hasBoundAxis } = this.props;
    if (hasBoundAxis) return null;
    switch (options.plot.type) {
      case "line":
        this.line = d3
          .line()
          .x(((d: FormatedReading) => this.x(d.x)) as any)
          .y(((d: FormatedReading) => this.y(d.y)) as any);
        // .curve(d3.curveCatmullRom.alpha(0.5));
        break;
      case "scatter":
        this.line = null;
        break;
      default:
        this.line = null;
        break;
    }
  }

  renderLimits() {
    const { options } = this.props;
    const { dimensions } = this.state;

    this.svg && this.svg.selectAll(".warning-limit").remove();
    this.svg && this.svg.selectAll(".critical-limit").remove();
    if (options.limits) {
      this.svg &&
        this.svg
          .selectAll("warning-limit")
          .data(getWarningLimits(options, this.dataSourceAlertData))
          .enter()
          .append("line")
          .attr("class", "warning-limit")
          .attr("stroke-width", "1")
          .attr(
            "stroke",
            themeGet(`colors.${Colors[Severity.Warning]}`)(this.props)
          )
          .attr("x1", dimensions.margin.left)
          .attr("y1", (value: number) => this.y(value))
          .attr("x2", dimensions.width)
          .attr("y2", (value: number) => this.y(value));
      this.svg &&
        this.svg
          .selectAll("critical-limit")
          .data(getCriticalLimits(options, this.dataSourceAlertData))
          .enter()
          .append("line")
          .attr("class", "critical-limit")
          .attr("stroke-width", "1")
          .attr(
            "stroke",
            themeGet(`colors.${Colors[Severity.Critical]}`)(this.props)
          )
          .attr("x1", dimensions.margin.left)
          .attr("y1", (value: number) => this.y(value))
          .attr("x2", dimensions.width)
          .attr("y2", (value: number) => this.y(value));
    }
  }

  async updateDataSourceLimits() {
    try {
      const alertResponse: DataProviderResponse = await getDataSourceAlert({
        id: this.props.options.limits.dataSource
      });

      let alert = null;
      if (!alertResponse.data.alert.indexes) {
        alert = alertResponse.data.alert;
      } else if (alertResponse.data.alert.indexes.length === 1) {
        alert = alertResponse.data.alert.indexes[0];
      } else {
        alert = alertResponse.data.alert.indexes.find(
          (index: any) =>
            index.index === this.props.options.limits.dataSourceIndex
        );
      }
      const warning = alert.alertLevels.find(
        (alertLevel: AlertLevel) => alertLevel.severity === Severity.Warning
      );
      if (warning) {
        this.dataSourceAlertData.warning = [
          warning.lowerThreshold,
          warning.upperThreshold
        ];
      }
      const critical = alert.alertLevels.find(
        (alertLevel: AlertLevel) => alertLevel.severity === Severity.Critical
      );
      if (warning) {
        this.dataSourceAlertData.critical = [
          critical.lowerThreshold,
          critical.upperThreshold
        ];
      }
    } catch (e) {
      this.dataSourceAlertData.warning = [];
      this.dataSourceAlertData.critical = [];
    }
  }

  updateChart() {
    if (this.updatingChart) return;
    this.updatingChart = true;
    const { data, xDomain, yDomain, hasBoundAxis } = this.props;
    const { dimensions, extent } = this.state;

    this.mergedData = [];
    data.forEach((d, index) => {
      d.readings.forEach((rd, _index) => (d.readings[_index].index = index));
      this.mergedData = [...this.mergedData, ...d.readings];
    });

    if (yDomain[0] !== null) this.min = yDomain[0];
    else {
      const _min = Number(d3.min(this.mergedData, (d) => d.y));
      if (!this.min || _min < this.min) {
        this.min = _min;
      }
    }

    if (yDomain[1] !== null) this.max = yDomain[1];
    else {
      const _max = Number(d3.max(this.mergedData, (d) => d.y));
      if (!this.max || _max > this.max) {
        this.max = _max;
      }
    }

    this.keys = data.map((d) => d.key);
    this.color = d3
      .scaleOrdinal()
      .domain(this.keys)
      .range([...d3.schemeSet2, ...d3.schemeSet1, ...d3.schemeSet3]);

    //Has zoom selection
    if (extent && extent.x) {
      this.x = d3
        .scaleLinear()
        .domain([this.x.invert(extent.x[0]), this.x.invert(extent.x[1])])
        .range([
          dimensions.margin.left,
          dimensions.width - dimensions.margin.left
        ]);
    } else {
      this.x = d3
        .scaleLinear()
        .domain(
          xDomain && !hasBoundAxis
            ? xDomain
            : (d3.extent(this.mergedData, (d: FormatedReading) => d.x) as [
                number,
                number
              ])
        )
        .range([dimensions.margin.left, dimensions.width]);
    }

    if (extent && extent.y) {
      this.y = d3
        .scaleLinear()
        .domain([this.y.invert(extent.y[1]), this.y.invert(extent.y[0])])
        .range([dimensions.height, dimensions.margin.bottom]);
    } else {
      this.y = d3
        .scaleLinear()
        .domain([this.min, this.max])
        .range([dimensions.height, dimensions.margin.bottom]);
    }

    this.renderTicks();
    this.renderTooltips();
    this.renderLimits();
    if (!this.renderChart) {
      this.renderZoomUtils();
    }

    this.renderChart = true;
    this.updatingChart = false;
    this.forceUpdate();
  }

  resetZoom() {
    const { options, addExtentToGraph } = this.props;
    if (options && options.isGloballyControlled) {
      addExtentToGraph({ index: this.props.id, yExtent: null });
    } else {
      this.setState({ extent: null }, () => this.updateChart());
      this.props.setPaused(false);
    }
  }

  render() {
    const { data, showAxis, options, containerRef, showLegend } = this.props;
    const width =
      (containerRef.current && containerRef.current.clientWidth) || 0;
    const height =
      (containerRef.current && containerRef.current.clientHeight) || 0;
    const { dimensions, selectedIndex, extent } = this.state;
    return (
      <>
        <Absolute
          width={"100%"}
          top={0}
          right={74}
          zIndex={1}
          display="flex"
          px={2}
        >
          <Button
            id="reset-zoom"
            data-testid="reset-zoom"
            ml="auto"
            size="small"
            onClick={() => this.resetZoom()}
            disabled={extent === null}
          >
            <Icon name={"Reset"} size={20} />
          </Button>
        </Absolute>
        <Button
          id={`reset-selected-data-point-${this.id}`}
          display="none"
          onClick={() => {
            this.closeTooltips();
          }}
        ></Button>
        <svg
          width={width}
          height={height}
          ref={(handle) => (this.svg = d3.select(handle))}
          className="graph-svg"
        >
          {this.renderChart && showAxis && (
            <line
              className="axis"
              x1={dimensions.margin.left}
              x2={dimensions.width}
              y1={dimensions.height}
              y2={dimensions.height}
            />
          )}
          {this.renderChart && showAxis && (
            <line
              className="axis"
              x1={dimensions.margin.left}
              x2={dimensions.margin.left}
              y1={dimensions.margin.bottom}
              y2={dimensions.height}
            />
          )}
          {this.renderChart &&
            this.line &&
            data.map((d, index) => (
              <path
                id={`${this.id}-${d.dataSource.name}`}
                d={this.line(d.readings)}
                stroke={
                  options.plot.color ? options.plot.color : this.color(d.key)
                }
                strokeWidth={getPlotThickness(options)}
                opacity={
                  selectedIndex === null || selectedIndex === index ? 1 : 0.2
                }
                className="graph-line-path"
                clipPath={`url(#clip-${this.id})`}
                key={index}
              />
            ))}
          <g className="axis-labels">{this.xTicks}</g>
          <g className="axis-labels">{this.yTicks}</g>
        </svg>
        <GraphLegend
          keys={this.keys}
          showLegend={showLegend}
          legendRef={this.legendRef}
          color={this.color}
          selectedIndex={selectedIndex}
          data={data}
          onChange={(key: number) =>
            this.setState({
              selectedIndex: key === selectedIndex ? null : key
            })
          }
        />
      </>
    );
  }
}

export default withTheme(LineChart as any);
