import {
  FetchAllOperationActivityParams,
  FetchAllOperationActivityResponse,
  CreateOaParams,
  CreateOaResponse,
  CreateOaResponseErrors,
  PatchOaParams,
  EditOaResponse,
  EditOaResponseErrors,
  DeleteOaResponse,
  DeleteOaResponseErrors
} from "app/planningSystem/models";
import { planningSystemDataProvider } from "app/network/dataProvider";
import { OperationType } from "app/network/models/constants";
import { HTTPResponse } from "app/network/dataProvider/model";

/**
 * GET
 */
export const fetchAllOperationActivity = async (
  params?: FetchAllOperationActivityParams
): Promise<FetchAllOperationActivityResponse> => {
  try {
    const response = await planningSystemDataProvider(
      OperationType.GET_LIST,
      `oa/`,
      { filter: params }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return { oas: [], pagination: {} };
  }
};

/**
 * POST
 */

export const createOperationActivity = async (
  params: CreateOaParams
): Promise<CreateOaResponse | CreateOaResponseErrors | null> => {
  try {
    const response = await planningSystemDataProvider(
      OperationType.CREATE,
      `oa/`,
      {
        data: params
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

/**
 * PATCH
 */
export const editOperationActivity = async (
  uuid: string,
  data: PatchOaParams
): Promise<{
  data: EditOaResponse | EditOaResponseErrors | null;
  status: number | null;
}> => {
  try {
    const response = (await planningSystemDataProvider(
      OperationType.PATCH_ONE,
      `oa/${uuid}`,
      { data }
    )) as Promise<HTTPResponse>;
    return { data: (await response).data, status: (await response).status };
  } catch (error) {
    console.log(error);
    return { data: null, status: null };
  }
};

/**
 * DELETE
 */
export const deleteOperationActivity = async (
  uuid: string
): Promise<{
  data: DeleteOaResponse | DeleteOaResponseErrors | null;
  status: number | null;
}> => {
  try {
    const response = (await planningSystemDataProvider(
      OperationType.DELETE,
      `oa/${uuid}`
    )) as Promise<HTTPResponse>;
    return { data: (await response).data, status: (await response).status };
  } catch (error) {
    console.log(error);
    return { data: null, status: null };
  }
};
