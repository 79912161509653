import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgProfile = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 14.4 18"
    color={color}
  >
    <g>
      <path d="M7.2 7.2a3.6 3.6 0 1 0-3.6-3.6 3.6 3.6 0 0 0 3.6 3.6zm0-5.4a1.8 1.8 0 1 1-1.8 1.8 1.8 1.8 0 0 1 1.8-1.8z" />
      <path d="M7.2 8.1A7.208 7.208 0 0 0 0 15.3V18h14.4v-2.7a7.208 7.208 0 0 0-7.2-7.2zm5.4 8.1H1.8v-.9a5.4 5.4 0 0 1 10.8 0z" />
    </g>
  </Svg>
);

SvgProfile.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgProfile;
