import React, { FormEvent, useState, FC, useEffect } from "react";
import { UploadButton, CancelButton } from "components";
import Dropzone, { FileWithPath } from "react-dropzone";
import { Box, Flex, Text } from "primitives";
import { ResourceBase } from "../models";
import { PathSelector } from "./PathSelector";
import { isValidArmsPath } from "./PathSelectorSchema";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "primitives";

interface UploadResourceModalProps {
  selectedResource: ResourceBase | null;
  modalOpen: boolean;
  closeModal: () => void;
  createNewResource: (fileName: string, file: any) => void;
  editResource: (fileName: string, file: any) => void;
  path: string;
}

const UploadResourceModal: FC<UploadResourceModalProps> = (props) => {
  const [file, setFile] = useState<any>(null);
  const [path, setPath] = useState<string>("");
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const { modalOpen, closeModal, selectedResource } = props;

  useEffect(() => {
    if (modalOpen && selectedResource) {
      setPath(selectedResource.name);
    }
    if (!modalOpen) {
      setFile(null);
    }
  }, [modalOpen, selectedResource]);

  const buildPath = (formData: any) => {
    if (formData) {
      const { organization, fleet, satellite, subpath, filename } = formData;
      const tmpPath = `/${organization}/${fleet}/${satellite}${subpath}/${filename}`;
      const cleanedPath = tmpPath
        .replace(/null|undefined/g, "")
        .replace(/\/\//g, "/");
      setPath(cleanedPath);
    }
  };

  useEffect(() => {
    buildPath(data);
  }, [data]);

  const submit = (e: FormEvent) => {
    const { editResource } = props;
    e.preventDefault();
    try {
      setLoading(true);
      if (file) {
        sendResource();
      } else if (selectedResource) {
        editResource(path, null);
        closeModal();
      }
      setFile(null);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
    setLoading(false);
    setData(null);
    setPath("");
  };

  const sendResource = () => {
    const { createNewResource, editResource } = props;
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = function() {
      if (selectedResource) editResource(path, reader.result);
      else createNewResource(path, reader.result);
      closeModal();
    };
  };

  const onDropAccepted = (files: FileWithPath[]) => {
    setFile(files[0]);
  };

  /**
   * data:  form data
   * path:  arms path
   * ignore: object key in form data to ignore
   */
  const isValid = isValidArmsPath(data, path, "subpath");

  return (
    <Dialog open={modalOpen} maxWidth="md" overflow="hidden">
      <DialogTitle>ARMS Path Selector </DialogTitle>
      <DialogContent>
        <form>
          {!selectedResource && (
            <>
              <PathSelector
                data={data}
                setData={setData}
                path={path}
                setPath={setPath}
              />
              <Box my={2}>
                {!isValid ? (
                  <Text fontSize={16} m="10px 0" color="text.danger">
                    Add complete path to the file (/folder/filename.ext)
                  </Text>
                ) : (
                  ""
                )}
              </Box>
            </>
          )}
          <Dropzone onDrop={(f) => onDropAccepted(f)} multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <>
                <div {...getRootProps()}>
                  <Box mb={2} p={3} bg="fill.0" border={1}>
                    <input {...getInputProps()} />
                    <p>Drop a file to upload, or click to select it.</p>
                  </Box>
                </div>
                {file && (
                  <Flex
                    p={2}
                    my={2}
                    bg="fill.0"
                    key={file.path}
                    alignItems="center"
                  >
                    <Text bold>{`File:`}</Text>
                    <Text ml={1}>{` ${file.path} - ${file.size} bytes`}</Text>
                  </Flex>
                )}
              </>
            )}
          </Dropzone>
        </form>
      </DialogContent>
      <DialogActions>
        <UploadButton
          type="submit"
          form="arms-path-selector"
          onClick={(e) => submit(e)}
          mr={1}
          disabled={loading || !file || (!isValid && !selectedResource)}
        >
          Upload
        </UploadButton>
        <CancelButton
          onClick={() => {
            closeModal();
            setData(null);
            setPath("");
          }}
          mx={1}
        >
          Cancel
        </CancelButton>
      </DialogActions>
    </Dialog>
  );
};

export default UploadResourceModal;
