/* eslint-disable no-debugger */
import React from "react";
import { useSelector } from "react-redux";
import { Passage, RemainingTime } from "app/visibilityWindow/models";
import {
  getRemainingTime,
  remainingTimeFormat
} from "app/visibilityWindow/helpers";

interface ICountDown {
  passage: Passage;
  passageType: "current" | "next";
}

const PassageSelectorOption = ({ passage, passageType }: ICountDown) => {
  const { serverTime } = useSelector((state: any) => state.visibilityWindow);

  const futureTime = passageType === "next" ? "aos" : "los";
  const remainingTime: RemainingTime = getRemainingTime({
    futureTime: new Date(passage[futureTime]),
    format: remainingTimeFormat[passageType],
    serverTime
  });

  const option = (passageType === "next" && (
    <option key={passage.passageID} value={passage.passageID} disabled={true}>
      {`${passage.groundStationName} | Next passage in: ${remainingTime.formatedDuration}`}
    </option>
  )) || (
    <option key={passage.passageID} value={passage.passageID}>
      {`${
        passage.groundStationName ? passage.groundStationName : " - "
      } | Passage Ends In: ${remainingTime.formatedDuration}`}
    </option>
  );

  return (remainingTime.durationMinutes > 0 && option) || <></>;
};

export { PassageSelectorOption };
