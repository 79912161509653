import {
  ADD_SATELLITE,
  ADD_SATELLITES,
  REMOVE_SATELLITE,
  CLEAN_SATELLITE,
  SET_SATELLITE_VISIBILITY,
  FETCH_CONSTELLATION_SUCCESS,
  SELECT_CONSTELLATION,
  CLEAR_CONSTELLATION
} from "../models/constants";
import { FETCH_SATELLITE_DEFINITION_SUCCESS } from "app/satelliteDefinition";
import { GET_ORBITS_SUCCESS } from "app/satellite";

const initialState = [];

/**
[
  {
    satellite: {},
    satelliteDefinitionSummary: {},
    satelliteDefinition: {},
    dataSources: [],
    visible: true,
    orbit: {}
  },
  {...}
]
*/

export const constellationDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SATELLITES: {
      const selectedSat = parseInt(
        JSON.parse(sessionStorage.getItem("selectedSat")) ||
          JSON.parse(localStorage.getItem("selectedSat"))
      );
      // Check if the selectedSat is included in the set of passed satellites
      const constellationChanged = !action.payload
        .map((s) => s.id)
        .includes(selectedSat);
      return action.payload.map((satellite, index) => {
        const oldSatellite = getSatelliteByID(state, satellite.id);
        satellite.visible =
          (selectedSat && selectedSat === satellite.id) ||
          ((constellationChanged || !selectedSat) && index === 0);

        return { ...oldSatellite, ...satellite };
      });
    }

    case ADD_SATELLITE:
      return [...state, action.payload];

    case REMOVE_SATELLITE:
      return state.filter((satellite) => satellite.id !== action.payload);

    case SELECT_CONSTELLATION:
    case CLEAR_CONSTELLATION:
    case CLEAN_SATELLITE:
      return initialState;

    case SET_SATELLITE_VISIBILITY:
      return state.map((satellite) => {
        if (satellite.id === parseInt(action.payload.id, 10)) {
          satellite.visible = true;
        } else {
          satellite.visible = false;
        }
        return satellite;
      });

    case FETCH_SATELLITE_DEFINITION_SUCCESS:
      return updateSatelliteDefinition(state, action);

    // case GET_ORBITS_SUCCESS:
    //   return state.map((satellite, index) => {
    //     satellite.orbit = action.payload[index];
    //     return satellite;
    //   });

    default:
      return state;
  }
};

const getSatelliteByID = (state, id) =>
  state.filter((satellite) => {
    return satellite.id === id;
  })[0];

const updateSatelliteDefinition = (state, action) =>
  state.map((satellite) => {
    if (
      action.payload &&
      satellite.satelliteDefinitionSummary.satelliteDefinitionId ===
        action.payload.id
    ) {
      satellite.satelliteDefinition = action.payload;
    }
    return satellite;
  });
