import { connect } from "react-redux";
import { ConstellationList } from "../components/ConstellationList";
import { getConstellations, selectConstellation } from "app/constellation";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state) => ({
  list: state.constellations.list,
  constellationId:
    state.constellations.selected && state.constellations.selected.id
});

const mapDispatchToProps = () => {
  return {
    getConstellations: () => getConstellations(),
    selectConstellation: (data) => selectConstellation(data)
  };
};

export const ConstellationListContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConstellationList)
);
