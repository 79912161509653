import { useState, useCallback, useEffect } from "react";

interface UseSuspenseQueryResponse {
  response?: any;
  error?: any;
  reload: () => void;
}

const queryCache = new Map();

export const useSuspenseQuery = (
  query: () => Promise<any>
): UseSuspenseQueryResponse => {
  const [, setReload] = useState(false);
  const reload = useCallback(() => {
    queryCache.delete(query);
    setReload((prevReload) => !prevReload);
  }, [query]);

  useEffect(() => {
    return () => {
      queryCache.delete(query);
    };
  }, [query]);

  if (queryCache.has(query)) {
    const { response, error } = queryCache.get(query);

    if (error) {
      return { error, reload };
    }

    if (response) {
      return { response, error, reload };
    }
  }

  throw query()
    .then((res: any) => {
      queryCache.set(query, { response: res });
    })
    .catch((e: any) => {
      queryCache.set(query, { error: e });
    });
};
