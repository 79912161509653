import { SatelliteInstance } from "app/satellite/models";

export const WindowSizes = {
  1000: "1s",
  2000: "2s",
  3000: "3s",
  4000: "4s",
  5000: "5s",
  10000: "10s",
  20000: "20s",
  30000: "30s",
  40000: "40s",
  50000: "50s",
  60000: "1min",
  300000: "5min"
};

export enum TimeRanges {
  "now-1m" = "Last 1 mins",
  "now-5m" = "Last 5 mins",
  "now-10m" = "Last 10 mins",
  "now-15m" = "Last 15 mins",
  "now-30m" = "Last 30 mins",
  "now-1h" = "Last 1 hour",
  "now-2h" = "Last 2 hour",
  "now-3h" = "Last 3 hours",
  "now-6h" = "Last 6 hours",
  "now-12h" = "Last 12 hours",
  "now-24h" = "Last 24 hours",
  "now-2d" = "Last 2 days",
  "now-3d" = "Last 3 days",
  "now-5d" = "Last 5 days",
  "now-7d" = "Last 7 days"
}

export enum TimeReferences {
  satelliteUtc = "Satellite UTC",
  groundUtc = "Ground Station UTC"
  // Note: requires additional parameters which can be set from widget config
  // satelliteBootcountObt = "Satellite OBT"
}

export enum FetchingStatus {
  idle = "idle",
  fetching = "fetching"
}

export interface ITimeController {
  refreshInterval: number;
  quickRange: string | Date;
  timeReference: string;
  from: Date | string;
  to: Date | string;
  timeUnit: string;
  windowSize: number;
  showTimeController: boolean;
}

export enum TimeControllerActionTypes {
  HIDE_TIME_CONTROLLER = "HIDE_TIME_CONTROLLER",
  UNHIDE_TIME_CONTROLLER = "UNHIDE_TIME_CONTROLLER",
  TELEMETRY_FETCHED = "TELEMETRY_FETCHED",
  SET_REFRESH_INTERVAL = "SET_REFRESH_INTERVAL",
  SET_TIME_REFERENCE = "SET_TIME_REFERENCE",
  SET_TIME_RANGE = "SET_TIME_RANGE",
  SET_FROM_TO = "SET_FROM_TO",
  FETCH_READING_START = "FETCH_READING_START",
  FETCH_READING_END = "FETCH_READING_END",
  RESET_TIME_CONTROLLER = "RESET_TIME_CONTROLLER",
  SET_WINDOW_SIZE = "SET_WINDOW_SIZE",
  ZOOM_OUT = "ZOOM_OUT",
  ZOOM_IN = "ZOOM_IN",
  NEXT_RANGE = "NEXT_RANGE",
  PREV_RANGE = "PREV_RANGE"
}

export interface State {
  datastore: {
    timeController: ITimeController;
  };
  constellations: {
    dashboard: SatelliteInstance[];
  };
}

export enum TelemetryComponents {
  Label = "Label",
  RealTimeTable = "RealTimeTable",
  // ConstellationTree = "ConstellationTree",
  // HistoricalTable = "HistoricalTable",
  HistoricalGraph = "HistoricalGraph",
  RealTimeGraph = "RealTimeGraph",
  Histogram = "Histogram",
  Gauge = "Gauge"
}

export interface DashboardComponent {
  type: TelemetryComponents | string;
}

export interface TimeControllerProps {
  dashboard?: {
    components: DashboardComponent[];
  };
}
