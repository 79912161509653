import React, { useMemo } from "react";
import { ReactCalendarItemRendererProps } from "react-calendar-timeline";

export const TimelinePassageRenderer = ({
  item,
  itemContext,
  getItemProps,
  getResizeProps
}: ReactCalendarItemRendererProps) => {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
  const itemProps = getItemProps(item.itemProps || {});
  return useMemo(() => {
    return (
      <div
        {...itemProps}
        data-passage={`{"id": "${item.id}", "aos": "${item.start_time}" }`}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ""}

        <div
          className="rct-item-content"
          style={{ maxHeight: `${itemContext.dimensions.height}` }}
        >
          {itemContext.title}
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ""}
      </div>
    );
  }, [itemProps.style.left, itemProps.style.top, itemContext.selected]);
};
