import { scriptDataProvider } from "app/network/dataProvider";
import { OperationType } from "app/network/models/constants";
import { Resource, ResourceDetail } from "../models";

export const fetchResources = async (): Promise<Resource[]> => {
  const response: any = await scriptDataProvider(
    OperationType.GET_LIST,
    `resources`
  );
  return response.data;
};

export const createResource = async (data: FormData) => {
  const response = await scriptDataProvider(OperationType.UPLOAD, `resources`, {
    data
  });

  return response;
};

export const editResource = async (
  id: string,
  data: FormData
): Promise<any> => {
  const response = await scriptDataProvider(OperationType.UPLOAD, `resources`, {
    id,
    data
  });

  return response;
};

export const fetchResource = async (id: string): Promise<ResourceDetail> => {
  const response: any = await scriptDataProvider(
    OperationType.GET_ONE,
    `resources`,
    {
      id
    }
  );
  return response.data;
};

export const deleteResource = async (id: number): Promise<any> => {
  await scriptDataProvider(OperationType.DELETE, `resources`, {
    id
  });
};
