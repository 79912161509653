import React, { Component } from "react";
import { fetchFileDownloadLink } from "../services";

export class SatelliteFileDownload extends Component {
  componentDidMount() {
    fetchFileDownloadLink(
      this.props.match.params.id,
      this.props.match.params.fileId
    ).then((data) => {
      window.location.href = data.downloadLink;
    });
  }

  render() {
    return <h3>Download will start automatically...</h3>;
  }
}
