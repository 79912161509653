import React, { useRef } from "react";
import { Flex, Icon, Absolute, Relative } from "primitives";
import { DragHandle } from "components";

export const GraphAutoSizer = (props: any) => {
  const ref = useRef<HTMLDivElement>(null);
  const { type, id, showModal, children, height } = props;
  const h = (ref.current && ref.current.clientHeight - 20) || 0;
  const w = (ref.current && ref.current.clientWidth) || 0;
  return (
    <Relative
      id={props.idAttribute}
      data-testid={props.idAttribute}
      height={height || "calc(100% - 4px)"}
      overflow="visible"
      bg={"palette.grey.5"}
      ref={ref}
    >
      <Flex
        color="#fff"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        overflow="visible"
        backgroundColor="#1f1e2c"
        position="relative"
      >
        <DragHandle absolute top={4} left={8} />
        <Absolute
          cursor="pointer"
          padding={2}
          onClick={() => showModal(type, id)}
          right={0}
          top={"-5px"}
          color="text.default"
          zIndex={1}
          data-testid="settings-icon"
        >
          <Icon name={"SettingsMenu"} size={18} id="settings-gear" />
        </Absolute>
        {children({ w, h })}
      </Flex>
    </Relative>
  );
};
