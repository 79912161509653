import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgDrag = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 42 42"
    color={color}
    id="drag-handle"
  >
    <path d="M24.0002 9.5252H20.9765H30.7283L20.9627 0L11.2723 9.5252H18.0001V18.409H8.92962V24.3067H18.0001V33.2227H24.0002V24.3067H33.0001V18.409H24.0002V9.5252V9.5252ZM20.9647 42L30 33.2227H12.0003L20.9647 41.9997V42ZM0 21.3227L8.92962 30.2045V12.5112L0 21.3227ZM33.0001 12.5112V30.2042L42 21.3224L33.0001 12.5109V12.5112Z" />
  </Svg>
);

SvgDrag.defaultProps = {
  width: "25",
  height: "25"
};
export default SvgDrag;
