import React from "react";
import { useState, useRef, useContext } from "react";
import { TableBody, Table } from "components";
import { FixedSizeList } from "react-window";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import {
  IVirtualizedTable,
  IVirtualizedTableContext,
  ITableRow
} from "./models";
import { initialContext, SIZER_STYLES, TABLE_STYLES } from "./helpers";

/** Context for cross component communication */
const VirtualTableContext = React.createContext<IVirtualizedTableContext>(
  initialContext
);

/** The virtual table. It basically accepts all of the same params as the original FixedSizeList.*/
export const VirtualizedTable = ({
  data,
  header,
  rowTemplate,
  ...rest
}: IVirtualizedTable) => {
  const listRef = useRef<FixedSizeList | null>();
  const [top, setTop] = useState(0);

  return (
    <VirtualTableContext.Provider
      value={{ top, setTop, header, data, rowTemplate }}
    >
      <AutoSizer style={{ ...SIZER_STYLES }}>
        {({ height, width }: Size) => (
          <FixedSizeList
            {...rest}
            height={height || "100%"}
            width={width || "100%"}
            innerElementType={Inner}
            onItemsRendered={(props) => {
              const style =
                listRef.current &&
                // @ts-ignore private method access
                listRef.current._getItemStyle(props.overscanStartIndex);
              setTop((style && style.top) || 0);

              // Call the original callback
              rest.onItemsRendered && rest.onItemsRendered(props);
            }}
            ref={(el) => (listRef.current = el || listRef.current)}
          >
            {TableRow}
          </FixedSizeList>
        )}
      </AutoSizer>
    </VirtualTableContext.Provider>
  );
};

const TableRow = ({ index }: ITableRow) => {
  const { data, rowTemplate } = useContext(VirtualTableContext);

  const Tr = rowTemplate as React.ElementType;
  return <Tr data={data[index]} />;
};

/**
 * Capture what would have been the top elements position and apply it to the table.
 * Other than that, render an optional header.
 **/
const Inner = ({ children, ...rest }: React.HTMLProps<HTMLDivElement>) => {
  const { header, top } = useContext(VirtualTableContext);
  return (
    <div {...rest}>
      <Table data-testid="data-table-simple" style={{ ...TABLE_STYLES, top }}>
        {header}
        <TableBody>{children}</TableBody>
      </Table>
    </div>
  );
};
