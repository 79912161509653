import React, { Component, createRef } from "react";
import GridLayout from "react-grid-layout";
import { Grid } from "components/grid/Grid";
import { Flex, Heading, Relative } from "primitives";
import { DashboardComponents, Dashboard } from "app/dashboard/models";
import EditDashboardConfigurationModal from "app/dashboard/components/EditDashboardConfigurationModal";
import "./SatelliteTMDashboard.css";
import { TimeController } from "app/shared";
import { RenderDashboardComponentsByType } from "./helpers/renderComponentByType";
import {
  generateLayout,
  getRowColNumber,
  updatePositionsAndSizes,
  composeComponentKey
} from "./helpers/layout";
import { GroundStation } from "app/groundStation";
const sizeColRow = 1;
const isTEST = process.env.NODE_ENV === "test";

interface TMDashboardProps {
  fetchGroundStations: () => Promise<GroundStation[]>;
  fetchTelemetryAtInterval: (id: any) => void;
  cleanUpIntervals: () => Promise<any>;
  selectDashboard: (id: any) => void;
  editDashboard: (id: any, dashboardDefinition: any, idx: any) => void;
  getUserDashboard: (id: any) => Promise<any>;
  dashboards: any;
  satellite: any;
  location: any;
  match: any;
  history: any;
  satelliteInstances: any;
  groundStations: any;
  selectedDashboard: any;
  setSatelliteVisibility: any;
  timeController?: any;
}

interface TMDashboardState {
  modalOpen: boolean;
  showModal: (type: string, id: number) => void;
  componentType: string | null;
  id: number | null;
  width: number;
  height: number;
  showSideBar: boolean;
}

class TMDashboard extends Component<TMDashboardProps, TMDashboardState> {
  private layoutGrid: any;
  constructor(props: any) {
    super(props);
    this.state = {
      modalOpen: false,
      componentType: null,
      id: null,
      showSideBar: JSON.parse(localStorage.getItem("showSideBar") || ""),
      width: window.innerWidth,
      height: window.innerHeight,
      showModal: (type: string, id: number) => {
        this.setState({
          modalOpen: true,
          componentType: type,
          id: id
        });
      }
    };
    this.handleResize = this.handleResize.bind(this);
    this.layoutGrid = createRef<HTMLDivElement>();
  }
  componentWillMount() {
    this.props.fetchGroundStations();
    this.startFetchingTelemetry();
  }

  componentDidMount() {
    this.startFetchingTelemetry();
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(): void {
    const {
      getUserDashboard,
      satelliteInstances,
      setSatelliteVisibility,
      match: {
        params: { id }
      }
    } = this.props;
    const dashboard = this.filterDashboard();
    // check if dashboard requested is not for current satellite
    const shouldFetchDashboard = !dashboard && id !== "default";

    // if databoard id is not for current satellite, get correct dashboard and switch to that satellite
    shouldFetchDashboard &&
      !isTEST &&
      getUserDashboard(id).then((data) => {
        const satelliteId = satelliteInstances?.filter(
          (s: any) =>
            s.satelliteDefinitionSummary.satelliteDefinitionId ===
            data.applicableSatDefinition?.id
        )[0]?.id;
        !satelliteId && console.warn(`Sat id not found`);
        satelliteId && setSatelliteVisibility(satelliteId);
      });

    const showSB = JSON.parse(localStorage.getItem("showSideBar") || "");
    if (showSB !== this.state.showSideBar) {
      this.setState({ showSideBar: showSB });
    }
    this.startFetchingTelemetry();
  }

  startFetchingTelemetry() {
    const { dashboards, satellite, match, selectDashboard, selectedDashboard } =
      this.props;
    const dashboard =
      dashboards &&
      dashboards.find(
        (d: { id: any }) =>
          d.id === match.params.id ||
          (isTEST && selectedDashboard && d.id === selectedDashboard.id)
      );
    if (dashboard) {
      selectDashboard(dashboard);
      this.props.fetchTelemetryAtInterval(satellite.id);
    }
  }

  componentWillReceiveProps(nextProps: { dashboards: string | any[] }) {
    const { dashboards } = this.props;
    const next = JSON.stringify(nextProps.dashboards);
    const current = JSON.stringify(dashboards);
    if (next !== current && nextProps.dashboards.length > 0) {
      this.startFetchingTelemetry();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    // clear all background intervals when user is not using TM dashboards
    this.props.cleanUpIntervals();
  }

  handleResize() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  updateConfigOptions(
    dashboard: Dashboard,
    componentIndex: number,
    configOptions: any
  ) {
    if (dashboard && componentIndex >= 0 && configOptions) {
      dashboard.components[componentIndex].configOptions = configOptions;
      const { id } = dashboard;
      return this.props.editDashboard(id, dashboard, componentIndex);
    }
  }

  generateLayoutItems(dashboard: Dashboard) {
    const { satellite, satelliteInstances, groundStations } = this.props;
    const { id, componentType } = this.state;

    const shouldRenderModal = (component: { type: string }, index: number) => {
      return component.type === componentType && index === id;
    };
    const items = dashboard.components;
    if (!items) {
      return null;
    }

    return items.map((component, index) => {
      const key = composeComponentKey(dashboard, index);
      return (
        <Flex
          className="react-grid-itemTM"
          alignSelf="stretch"
          key={key}
          flexDirection="column"
        >
          <Flex
            alignSelf="stretch"
            flex={3}
            flexDirection="column"
            alignItems="center"
          >
            <RenderDashboardComponentsByType
              id={index}
              key={key}
              modalOpen={this.state.modalOpen}
              showModal={this.state.showModal}
              component={component}
              satellite={satellite}
              options={
                component.configOptions ||
                DashboardComponents.getByType(component.type).options
              }
              updateConfigOptions={(configOptions: any) =>
                this.updateConfigOptions(dashboard, index, configOptions)
              }
            />

            {/*  
             This renders dashboard config modal ONLY when settings icon on specific component is clicked.
            As modal state is shared across components,this prevents the modal from being 
            rendered for other components as well
            */}
            {shouldRenderModal(component, index) && (
              <EditDashboardConfigurationModal
                type={component.type}
                modalOpen={this.state.modalOpen}
                formData={
                  component.configOptions ||
                  DashboardComponents.getByType(component.type).options
                }
                validator={
                  DashboardComponents.getByType(component.type).validator
                }
                updateConfigOptions={(configOptions) =>
                  this.updateConfigOptions(dashboard, index, configOptions)
                }
                satelliteInstances={satelliteInstances}
                groundStations={groundStations}
                closeModal={() =>
                  this.setState({
                    modalOpen: false,
                    componentType: null,
                    id: null
                  })
                }
              />
            )}
          </Flex>
        </Flex>
      );
    });
  }

  renderDashboardComponents(dashboard: Dashboard) {
    const { width, height, showSideBar } = this.state;
    const offsetTop =
      this.layoutGrid.current && this.layoutGrid.current.offsetTop;
    const layout = generateLayout(
      dashboard,
      width,
      height,
      showSideBar,
      offsetTop
    );
    const { rowNumber, colNumber } = getRowColNumber(
      dashboard,
      width,
      height,
      showSideBar,
      offsetTop
    );

    return (
      <>
        <Flex alignSelf="stretch" flexDirection="column" id="layout-wrapper">
          <Relative id="layout-grid-container" pt={3} ref={this.layoutGrid}>
            <GridLayout
              draggableHandle=".drag-handle"
              allowOverlap={false}
              className="react-grid-layoutTM"
              layout={layout}
              cols={colNumber}
              rows={rowNumber}
              rowHeight={sizeColRow - 10}
              colWidth={sizeColRow - 10}
              width={
                dashboard.dimensions.width
                  ? dashboard.dimensions.width - 30
                  : width - (showSideBar && width >= 768 ? 230 : 30)
              }
              onDragStop={(newLayout: any) => {
                updatePositionsAndSizes(
                  newLayout,
                  dashboard,
                  width,
                  height,
                  showSideBar,
                  offsetTop
                );
              }}
              onResizeStop={(
                newLayout: any,
                oldItem: any,
                newItem: { h: number; w: number; i: string }
              ) => {
                if (newItem.h < 20) newItem.h = 20;
                if (newItem.w < 20) newItem.w = 20;
                updatePositionsAndSizes(
                  newLayout,
                  dashboard,
                  width,
                  height,
                  showSideBar,
                  offsetTop
                );
              }}
            >
              {this.generateLayoutItems(dashboard)}
            </GridLayout>
          </Relative>
        </Flex>
      </>
    );
  }

  filterDashboard() {
    const {
      dashboards,
      match: {
        params: { id }
      },
      selectedDashboard
    } = this.props;
    const dashboard = dashboards.find(
      (d: any) =>
        d.id === id ||
        (isTEST && selectedDashboard && d.id === selectedDashboard.id)
    );
    return dashboard;
  }

  getDashboard() {
    const { dashboards, location, selectDashboard } = this.props;

    const isDefault =
      location.state?.default || location.pathname.includes("default");

    if ((location.pathname === "/" && dashboards.length > 0) || isDefault) {
      const hasDashboard = dashboards[0]?.id;
      if (isDefault && hasDashboard) {
        selectDashboard(dashboards[0]);
      }
      if (hasDashboard) {
        return dashboards[0];
      }
    }

    const dashboard = this.filterDashboard();
    if (dashboard) {
      return dashboard;
    }
  }

  render() {
    const { dashboards, satellite } = this.props;
    const dashboard = this.getDashboard();

    if (!dashboard || !dashboard.components) {
      if (Array.isArray(dashboards) && dashboards.length < 1) {
        return <>No dashboards configured for {satellite.label}</>;
      }
      return <>Loading...</>;
    }
    return (
      <Grid
        gridTemplateRows="auto 1fr"
        gridTemplateColumns="1fr"
        py={2}
        color="text.default"
        height="100%"
        width="100%"
        overflow="hidden"
        data-testid="SatelliteTMDashboard"
      >
        <Flex
          justifyContent="space-between"
          flexWrap="nowrap"
          overflow="visible"
        >
          <Heading display={1} mb={2} ml="10px">
            {satellite.label} - {dashboard.name}
          </Heading>
          <TimeController dashboard={dashboard} key={satellite.id} />
        </Flex>

        {this.renderDashboardComponents(dashboard)}
      </Grid>
    );
  }
}

export const SatelliteTMDashboard = TMDashboard;
