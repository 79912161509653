import React from "react";
import { Switch, Route } from "react-router-dom";
import { PlanningSystem } from "./containers";

export const PlanningSystemRoutes = () => (
  <>
    <Switch>
      <Route path="/planningsystem" exact component={PlanningSystem} />
    </Switch>
  </>
);
