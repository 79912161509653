import { literalParser } from "../parsers/literal";
import { enumParser } from "../parsers/enum";
import * as schemaModels from "app/telecommand/models";

/*
id -> id
name -> name

size | constantSize
minimum -> minItems

size | dynamicSize
@pattern

groupSpec -> items
*/
class GroupParser {
  /**
   *
   * @param {object} size
   * @returns {object}
   */
  getLimits(arg) {
    const { size } = arg;

    if (`${size}`.startsWith("@")) {
      return {
        pattern: size
      };
    }

    return {
      minItems: size,
      maxItems: size
    };
  }

  /**
   *
   * @param {object} itemSpec
   * @returns {object}
   */
  getItems(groupSpec) {
    const properties = {};
    const required = [];

    groupSpec.forEach((arg) => {
      if (
        arg.kind !== schemaModels.CONST_KIND &&
        arg.kind !== schemaModels.AUTO_KIND
      ) {
        required.push(arg.id);
      }

      switch (arg.argType) {
        case schemaModels.ARRAY_ARG_TYPE:
          properties[arg.id] = literalParser.parse(arg);
          break;
        case schemaModels.GROUP_ARG_TYPE:
          properties[arg.id] = groupParser.parse(arg);
          break;
        case schemaModels.ENUM_ARG_TYPE:
          properties[arg.id] = enumParser.parse(arg);
          break;
        default:
          properties[arg.id] = literalParser.parse(arg, true);
          break;
      }
    });

    return {
      items: {
        type: "object",
        required,
        properties
      }
    };
  }

  /**
   *
   * @param {object} arg
   * @returns {object} schema array
   */
  parse(arg) {
    const { groupSpec, id, name, argType } = arg;

    return {
      type: "array",
      argType,
      id,
      name: id,
      title: name,
      ...this.getItems(groupSpec),
      ...this.getLimits(arg)
    };
  }

  /**
   *
   * @param {object} arg
   * @returns {object} schema
   *
   */
  parseUI(arg, hideDefaults) {
    const { groupSpec } = arg;
    const properties = {};

    groupSpec.forEach((group) => {
      let hidden = true;
      switch (group.argType) {
        case schemaModels.ARRAY_ARG_TYPE:
          properties[group.id] = literalParser.parseUI(group, hideDefaults);
          break;
        case schemaModels.GROUP_ARG_TYPE:
          properties[group.id] = groupParser.parseUI(group, hideDefaults);
          for (const key in properties[group.id].items) {
            if (
              properties[group.id].items[key]["ui:widget"] !== "hidden" &&
              properties[group.id].items[key]["ui:widget"] !== undefined
            ) {
              hidden = false;
              break;
            }
          }
          if (hidden) properties[group.id].classNames = "hidden";
          break;
        case schemaModels.ENUM_ARG_TYPE:
          properties[group.id] = enumParser.parseUI(group, hideDefaults);
          break;
        default:
          properties[group.id] = literalParser.parseUI(group, hideDefaults);
          break;
      }
    });

    return {
      type: "array",
      items: properties,
      classNames: arg.name === "" ? "hidden-title" : ""
    };
  }
}

export const groupParser = new GroupParser();
