import { Procedure } from "../models";
import { ProcedureActions, ProcedureActionType } from "../actions";

interface ProcedureStoreState {
  procedures: Procedure[];
  selectedProcedure?: Procedure | null;
}

const initialState: ProcedureStoreState = {
  procedures: [],
  selectedProcedure: null
};

export const procedureReducer = (
  state: ProcedureStoreState = initialState,
  action: ProcedureActions
): ProcedureStoreState => {
  switch (action.type) {
    case ProcedureActionType.FetchProcedures:
      return { procedures: action.payload };
    case ProcedureActionType.FetchProcedure:
      if (action.payload) {
        return {
          ...state,
          selectedProcedure: {
            ...action.payload,
            steps: action.payload.steps.sort((a, b) => a.order - b.order)
          }
        };
      } else {
        return {
          ...state,
          selectedProcedure: action.payload
        };
      }
    default:
      return state;
  }
};
