import React from "react";
import { Switch, Route } from "react-router-dom";
import { TelecommandStackCreateContainer } from "./components/TelecommandStackCreate";
import { TelecommandStackEditContainer } from "./components/TelecommandStackEdit";
import { TelecommandStackImportContainer } from "./components/TelecommandStackImport";
import { TelecommandStackListContainer } from "./components/TelecommandStackList";

export const TelecommandStackRouters = () => (
  <>
    <Switch>
      <Route
        path="/telecommandstacks/create"
        exact
        component={TelecommandStackCreateContainer}
      />
      <Route
        path="/telecommandstacks/import"
        exact
        component={TelecommandStackImportContainer}
      />
      <Route
        path="/telecommandstacks/:id"
        component={TelecommandStackEditContainer}
      />
      <Route
        path="/telecommandstacks"
        component={TelecommandStackListContainer}
      />
    </Switch>
  </>
);
