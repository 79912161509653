import React from "react";
import { Flex, Text } from "primitives";
import { store } from "app/store";

const Avatar = () => (
  <Flex flexDirection="column" alignItems="center" p={4}>
    <Text fontSize={5} color="text.white" mb={1} caps center>
      {store.getState().keycloak.idTokenParsed &&
        store.getState().keycloak.idTokenParsed.name}
    </Text>
    <Text fontSize={2} color="text.default" mb={1} caps>
      {store.getState().keycloak.resourceAccess["webgs-backend"] && [
        store.getState().keycloak.resourceAccess["webgs-backend"].roles[0]
      ]}
    </Text>
  </Flex>
);

export default Avatar;
