import {
  telecommandsExecutionConfigOptionsSchema,
  telecommandsExecutionConfigOptionsDefault,
  telecommandsExecutionConfigOptionsUISchema
} from "../../telecommand/components/config/TelecommandsExecution";
import {
  passageCountdownConfigOptionsSchema,
  passageCountdownConfigOptionsDefault,
  passageCountdownConfigOptionsUISchema
} from "../../visibilityWindow/components/PassageCountdown";
import {
  visibilityWindowListConfigOptionsSchema,
  visibilityWindowListConfigOptionsDefault,
  visibilityWindowListConfigOptionsUISchema
} from "../../visibilityWindow/components/VisibilityWindowsList";

import {
  gaugeConfigOptionsSchema,
  gaugeConfigOptionsDefault,
  gaugeConfigOptionsUISchema,
  histogramConfigOptionsSchema,
  histogramConfigOptionsDefault,
  histogramConfigOptionsUISchema,
  tableConfigOptionsSchema,
  tableConfigOptionsDefault,
  tableConfigOptionsUISchema,
  labelConfigOptionsDefault,
  labelConfigOptionsUISchema,
  lineChartConfigOptionsSchema,
  lineChartConfigOptionsDefault,
  lineChartConfigOptionsUISchema,
  globeConfigOptionsSchema,
  globeConfigOptionsDefault,
  globeConfigOptionsUISchema,
  grafanaConfigOptionsSchema,
  grafanaConfigOptionsDefault,
  grafanaConfigOptionsUISchema,
  labelConfigOptionsSchema
} from "app/telemetry/visualizations";

import {
  telecommandsSentConfigOptionsSchema,
  telecommandsSentConfigOptionsDefault,
  telecommandsSentConfigOptionsUISchema
} from "app/telecommand/components/TelecommandsList/schema";

export const configOptionsSchemaBuilder = (dashboardType: string): any => {
  const dashboardTypes: { [key: string]: any } = {
    RealTimeTable: {
      schema: tableConfigOptionsSchema,
      default: tableConfigOptionsDefault,
      uiSchema: tableConfigOptionsUISchema
    },
    Label: {
      schema: labelConfigOptionsSchema,
      default: labelConfigOptionsDefault,
      uiSchema: labelConfigOptionsUISchema
    },
    RealTimeGraph: {
      schema: lineChartConfigOptionsSchema,
      default: lineChartConfigOptionsDefault,
      uiSchema: lineChartConfigOptionsUISchema
    },
    Globe: {
      schema: globeConfigOptionsSchema,
      default: globeConfigOptionsDefault,
      uiSchema: globeConfigOptionsUISchema
    },
    TelecommandsExecution: {
      schema: telecommandsExecutionConfigOptionsSchema,
      default: telecommandsExecutionConfigOptionsDefault,
      uiSchema: telecommandsExecutionConfigOptionsUISchema
    },
    PassageCountdown: {
      schema: passageCountdownConfigOptionsSchema,
      default: passageCountdownConfigOptionsDefault,
      uiSchema: passageCountdownConfigOptionsUISchema
    },
    VisibilityWindows: {
      schema: visibilityWindowListConfigOptionsSchema,
      default: visibilityWindowListConfigOptionsDefault,
      uiSchema: visibilityWindowListConfigOptionsUISchema
    },
    Grafana: {
      schema: grafanaConfigOptionsSchema,
      default: grafanaConfigOptionsDefault,
      uiSchema: grafanaConfigOptionsUISchema
    },
    Histogram: {
      schema: histogramConfigOptionsSchema,
      default: histogramConfigOptionsDefault,
      uiSchema: histogramConfigOptionsUISchema
    },
    Gauge: {
      schema: gaugeConfigOptionsSchema,
      default: gaugeConfigOptionsDefault,
      uiSchema: gaugeConfigOptionsUISchema
    },
    TelecommandsSent: {
      schema: telecommandsSentConfigOptionsSchema,
      default: telecommandsSentConfigOptionsDefault,
      uiSchema: telecommandsSentConfigOptionsUISchema
    }
  };
  return dashboardTypes[dashboardType];
};
