import { Box } from "primitives";
import styled from "styled-components";
import { TelecommandStackStatus } from "app/telecommandStack/models";
import {
  TelecommandResponse,
  TelecommandResponseLevel
} from "app/telecommand/models";
import { theme } from "styles";

// Helper export functions
export function isAllowedDrag(selectedStack: any, result: any) {
  if (
    selectedStack.telecommandList[result.destination.index].status &&
    selectedStack.telecommandList[result.destination.index].status.statusName
  ) {
    return false;
  }
  return true;
}

export function getStackStatus(selectedStack: any) {
  if (selectedStack) {
    const stackIsRunning =
      selectedStack.status === TelecommandStackStatus.RUNNING;
    const stackIsPaused =
      selectedStack.status === TelecommandStackStatus.PAUSED;
    const stackIsStopped =
      selectedStack.status === TelecommandStackStatus.STOPPED;

    const stackHasErrored =
      selectedStack.status === TelecommandStackStatus.ERROR;

    const stackIsResumable =
      selectedStack.telecommandList.filter((t: any) => t.status !== "").length >
        0 &&
      (stackIsPaused || stackIsStopped || stackHasErrored);
    const canReset = !stackIsRunning;
    const canSave = !stackIsRunning;
    return { stackIsRunning, stackIsResumable, canReset, canSave };
  }
  return {
    stackIsRunning: false,
    stackIsResumable: false,
    canRest: true,
    canSave: false
  };
}

export const getBgGradient = (status: TelecommandResponse) => {
  if (status && status.reason === TelecommandResponseLevel.INVALID) {
    return theme.colors.status.invalid;
  }
};

export const getIndicatorBG = (status: TelecommandResponse) => {
  let color = "white";
  switch (status && status.level) {
    case TelecommandResponseLevel.ERROR:
      color = "status.danger";
      return color;
    case TelecommandResponseLevel.WARNING:
      color = "status.warning";
      return color;
    case TelecommandResponseLevel.SUCCESS:
      color = "status.success";
      return color;
    default:
      return color;
  }
};

export const numOfGrid = 8;
export const getItemStyle = (
  isDragging: any,
  draggableStyle: any,
  telecommand: any
) => {
  return {
    userSelect: "none",
    width: "100%",
    padding: numOfGrid * 2,
    paddingBottom: 0,

    margin: `0 0 ${numOfGrid}px 0`,
    boxShadow: !telecommand.comment ? "rgba(0, 0, 0, 0.16) 0px 1px 4px" : "",
    background: !telecommand.comment
      ? isDragging
        ? "#50678A"
        : "#34435a"
      : "#ffffff05",
    ...draggableStyle
  };
};

type PositionProperty = /*unresolved*/ any;
type OverflowYProperty = /*unresolved*/ any;

export const getListStyle = (isDraggingOver: any) => ({
  padding: numOfGrid,
  height: "100%",
  width: "100%",
  overflowY: "scroll" as OverflowYProperty,
  maxHeight: "80vh",
  position: "relative" as PositionProperty
});

export const SendStatusLight = styled(Box)``;
SendStatusLight.defaultProps = {
  size: "20px",
  borderRadius: "50%",
  margin: "0 auto"
};
