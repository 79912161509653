import React from "react";
import { TabInput } from "app/shared";
import { UsersList } from "./Users";
import { GroupsList } from "./groups";

interface UserManagementListState {
  pageSize: number;
  page: number;
  searchText: string;
}

const tabLabels = ["Users", "Satellites"];

export const UserManagementList = () => {
  const renderUsersList = () => {
    return <UsersList />;
  };

  const renderGroupsList = () => {
    return <GroupsList />;
  };

  return (
    <TabInput
      margin="0 16px"
      record={null}
      tabs={tabLabels}
      containers={(value: number) => (
        <>
          {value === 0 && renderUsersList()}
          {value === 1 && renderGroupsList()}
        </>
      )}
    />
  );
};
