import React, { Component } from "react";
import { Form } from "app/shared";
import { Box, Heading, Flex, Text } from "primitives";
import { SuspenseMutation } from "app/network";
import { CancelButton, SaveButton } from "components";
import { RouteComponentProps } from "react-router";
import { GroundStation, GroundStationConfiguration } from "../models";
import { schemaCreate, uiSchemaCreate } from "../models/schemas";
import { SatelliteInstance } from "app/satellite/models";
import { FeedbackStatus } from "app/feedback/models";
import { formatConfigurationObject } from "../models/widget";

interface GroundStationConfigProps extends RouteComponentProps {
  satellites: SatelliteInstance[];
  groundStations: GroundStation[];
  fetchGroundStations: () => Promise<GroundStation[]>;
  createGroundStationConfig: (data: any) => Promise<GroundStationConfiguration>;
  setFeedback: (title: string, status: FeedbackStatus) => void;
}

interface GroundStationConfigState {
  formData: any | null;
  groundStationType: string;
}

export class GroundStationConfig extends Component<
  GroundStationConfigProps,
  GroundStationConfigState
> {
  state = {
    formData: null,
    groundStationType: ""
  };

  componentDidMount() {
    this.props.fetchGroundStations();
  }

  onChange(form: any) {
    const currentFormData: any = this.state.formData;
    if (
      form.formData &&
      (!currentFormData ||
        currentFormData.groundStationID !== form.formData.groundStationID) &&
      form.formData.groundStationID
    ) {
      //Update groundStationType
      if (this.getGroundStationById(form.formData.groundStationID)) {
        this.setState({
          groundStationType: this.getGroundStationById(
            form.formData.groundStationID
          ).type
        });
      }
    }

    this.setState({ formData: form.formData });
  }

  getGroundStationById(groundStationID: number): GroundStation {
    return this.props.groundStations.filter((groundStation: GroundStation) => {
      return groundStationID === groundStation.groundStationID;
    })[0];
  }

  async submit(
    e: React.FormEvent<HTMLInputElement>,
    formData: any,
    groundStationType: string
  ) {
    e.preventDefault();

    const { groundStationID, satelliteID, configuration: c } = formData;
    const configuration = formatConfigurationObject(c);
    const newGroundStation = {
      groundStationID,
      satelliteID,
      type: groundStationType,
      configuration
    };

    await this.props.createGroundStationConfig(newGroundStation);
    this.props.history.push("/groundstations");
  }

  render() {
    const { formData, groundStationType } = this.state;
    const { satellites, groundStations, setFeedback } = this.props;

    const schema = schemaCreate(
      satellites || [],
      groundStations,
      groundStationType
    );

    const uiSchema = uiSchemaCreate(groundStationType);

    return (
      <SuspenseMutation>
        {({ loading, error, action }) => {
          return (
            <Box color="text.default" mx={3}>
              <Flex mb={2}>
                <Heading display={1}>Ground Station configuration</Heading>
              </Flex>
              <Flex flexDirection="column" bg="fill.0" p={3}>
                <Form
                  formData={formData}
                  schema={schema}
                  uiSchema={uiSchema}
                  disabled={loading}
                  onSubmit={(
                    form: { formData: any },
                    e: React.FormEvent<HTMLInputElement>
                  ) => {
                    action(() =>
                      this.submit(e, form.formData, groundStationType).catch(
                        (err: { status: number }) => {
                          if (err.status === 403) {
                            setFeedback("Unauthorized", FeedbackStatus.ERROR);
                          }
                        }
                      )
                    );
                  }}
                  onChange={(form: GroundStationConfigState) =>
                    this.onChange(form)
                  }
                >
                  <CancelButton
                    onClick={() => this.props.history.push("/groundstations")}
                    mr={2}
                  >
                    Cancel
                  </CancelButton>
                  <SaveButton type="submit" disabled={loading}>
                    Save
                  </SaveButton>
                  {error ? (
                    <Box my={2}>
                      <Text color="text.danger">Error...</Text>
                    </Box>
                  ) : null}
                </Form>
              </Flex>
            </Box>
          );
        }}
      </SuspenseMutation>
    );
  }
}

export default GroundStationConfig;
