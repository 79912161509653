import { displayAlertInfo } from "./alertInfo";
import { createToolTip, STYLES } from "./tooltip";
import uPlot from "uplot";
import { getAlertDetails } from "./utils";
import { palette } from "styles/palette";

export function cursorTracker(u: uPlot) {
  const { left, top, idx } = u.cursor;
  const alertDetailsDiv = u.root.querySelector(".uplot-tooltip") as any;
  const alertDetails = getAlertDetails(u, idx || 0);
  // add option in config if this is useful
  const enableCursor = false;
  const shouldShowAlertDetails =
    idx != null && alertDetails.length > 0 && enableCursor;
  if (!alertDetailsDiv) {
    createToolTip(u);
  }
  if (alertDetailsDiv && shouldShowAlertDetails) {
    const shiftX = 100;
    const alertsForDSidx = 0;
    const _alertSeries = u.data[u.data.length - 1][0] as any;
    const alertSeries = _alertSeries[alertsForDSidx];
    const additionalInfo = alertSeries[idx];
    alertDetailsDiv.innerHTML = displayAlertInfo(additionalInfo);
    Object.assign(alertDetailsDiv.style, STYLES);
    alertDetailsDiv.style["background-color"] = palette["green" as string][1];
    alertDetailsDiv.style.left = `${left || 0 + shiftX}px`;
    alertDetailsDiv.style.top = `${top}px`;
  } else {
    if (alertDetailsDiv) {
      // keep click alert div open
      // infoBox.style.display = 'none';
    }
  }
}
