import React from "react";
import { Switch, Route } from "react-router-dom";
import { KeyManager } from "./containers";

export const KeyManagerRoutes = () => (
  <>
    <Switch>
      <Route path="/keymanager" exact component={KeyManager} />
    </Switch>
  </>
);
