import React, { useMemo } from "react";
import {
  KeyStateIndicatorProps,
  StatusIndicatorTheme,
  KeyStatus
} from "../models";
import styled, { css } from "styled-components";
import { blinkAnimation, keyStatusMap } from "../utils/constants";
import { capitalize } from "../utils/helpers";
import { Tag } from "components";
import { CountDown } from "components";

const Indicator = styled("div")<{ color: string; expiring?: boolean }>`
  border-radius: 100%;
  height: 12px;
  min-width: 12px;
  background: ${(props) => props.color};
  margin-right: 6px;
  ${(props) =>
    props.expiring &&
    css`
      animation: ${blinkAnimation} 1s infinite;
    `}
`;

const Container = styled("div")`
  display: flex;
  margin-right: 6px;
  align-items: center;
  min-height: 24px;
`;

export const KeyStateIndicator = ({
  status,
  expires_at,
  theme = "dark"
}: KeyStateIndicatorProps) => {
  return useMemo(
    () => (
      <Container
        title={capitalize(keyStatusMap[status].name)}
        data-testid={"key-indicator"}
      >
        <Indicator color={keyStatusMap[status].color} />
        {(expires_at && status === KeyStatus.ACTIVE && (
          <CountDown expirationTime={expires_at} theme={theme} />
        )) || (
          <Tag
            bg={StatusIndicatorTheme[theme]}
            label={capitalize(keyStatusMap[status].name)}
          />
        )}
      </Container>
    ),
    [status, expires_at]
  );
};
