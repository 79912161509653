import { literalParser, enumParser, groupParser, fileParser } from "./parsers";
import * as schemaModels from "../models";

/**
 * SchemaGenerator
 * The JSON schema generator must be able to create a schema based on the API response;
 * Usage:
 * import {schemaGenerator} from "schemaGenerator";
 * const schema = schemaGenerator.generate(data);
 * const uiSchema = schemaGenerator.uiSchema(data);
 */
class SchemaGenerator {
  /**
   *
   * @param {object} data
   * @returns {object} schema
   *
   */
  generate(data) {
    if (!data) return {};
    const { args } = data;
    const properties = {};
    const required = [];

    args.forEach((arg) => {
      if (
        arg.kind !== schemaModels.CONST_KIND &&
        arg.kind !== schemaModels.AUTO_KIND
      ) {
        required.push(arg.id);
      }

      switch (arg.argType) {
        case schemaModels.LITERAL_ARG_TYPE:
          properties[arg.id] = literalParser.parse(arg);
          break;
        case schemaModels.ENUM_ARG_TYPE:
          properties[arg.id] = enumParser.parse(arg);
          break;
        case schemaModels.ARRAY_ARG_TYPE:
          properties[arg.id] = literalParser.parse(arg);
          break;
        case schemaModels.GROUP_ARG_TYPE:
          properties[arg.id] = groupParser.parse(arg);
          break;
        case schemaModels.FILE_ARG_TYPE:
          properties[arg.id] = fileParser.parse(arg);
          break;
        default:
          properties[arg.id] = arg;
          break;
      }
    });

    return {
      type: "object",
      required,
      properties
    };
  }
}

export const schemaGenerator = new SchemaGenerator();
