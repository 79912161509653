import React, { Component, ChangeEvent } from "react";
import { Box, Icon, Flex } from "primitives";
import { PaginationSimple } from "app/shared";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  UploadButton,
  DownloadButton,
  ConfigButton,
  BackButton,
  DeleteButton,
  TableSort
} from "components";
import { InputField } from "components";
import { SuspenseQuery, Suspense, LoadingError } from "app/network";
import { connect } from "react-redux";
import { LocationsResponse, ResourceBase } from "../models";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { Spinner } from "components";

import {
  getResourcesByPath,
  createResource,
  uploadResource,
  updateResource,
  updateResourceMetadata,
  downloadResourceLastVersion,
  deleteResource,
  moveResource
} from "../services";
import UploadResourceModal from "./UploadResourceModal1";
import ResourcePropertiesModal from "./ResourcePropertiesModal";
import DeleteModal from "./DeleteModal";
import MoveModal from "./MoveModal";
import { setFeedback } from "app/feedback/actions";
import { FeedbackStatus } from "app/feedback/models";

interface ResourceListState {
  pageSize: number;
  page: number;
  searchText: string;
  currentPath: string;
  isUploadModalOpen: boolean;
  isPropertiesModalOpen: boolean;
  isMoveModalOpen: boolean;
  isDeleteModalOpen: boolean;
  isPermanentDelete: boolean;
  isCopy: boolean;
  selectedResource: ResourceBase | null;
  sortBy: string | null;
  isSortAscending: boolean;
  downloadingIds: string[];
  total: number;
}

interface ResourceListProps {
  setFeedback: (
    title: string,
    status: FeedbackStatus,
    details?: string
  ) => void;
}

interface Error {
  status: number;
  body: { reason: string };
}

const REPOSITORY_ID = 1;

export class ResourceList extends Component<
  ResourceListProps,
  ResourceListState
> {
  state = {
    pageSize: 25,
    page: 0,
    searchText: "",
    currentPath: "",
    isUploadModalOpen: false,
    isPropertiesModalOpen: false,
    isMoveModalOpen: false,
    isDeleteModalOpen: false,
    isPermanentDelete: false,
    isCopy: false,
    selectedResource: null,
    sortBy: "name",
    isSortAscending: true,
    sortDesc: "desc",
    downloadingIds: [],
    total: 0
  };

  onChangeSearch(
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) {
    const { value } = event.currentTarget;
    this.setState({ searchText: value, page: 0 });
  }

  showErrorFeedback(e: Error) {
    if (e.status === 400 || e.status === 403 || e.status === 500) {
      this.props.setFeedback(
        "Unauthorized",
        FeedbackStatus.ERROR,
        e.body ? e.body.reason : ""
      );
    }
  }

  onResourceCreate = async (path: string, file: any, reload: any) => {
    try {
      const resourceCreateResponse: any = await createResource(REPOSITORY_ID, {
        path
      });
      const { operationId } = resourceCreateResponse.data;
      if (operationId) {
        await uploadResource(REPOSITORY_ID, operationId, file);
        reload();
      }
    } catch (e) {
      this.showErrorFeedback(e as Error);
      console.error(e);
    }
  };

  onResourceDownload = (resourceId: string, resourceName: string) => {
    this.setState({
      downloadingIds: [...this.state.downloadingIds, resourceId]
    });

    downloadResourceLastVersion(REPOSITORY_ID, resourceId, resourceName).then(
      () =>
        this.setState({
          downloadingIds: this.state.downloadingIds.filter(
            (id) => id !== resourceId
          )
        })
    );
  };

  onResourceDelete = async (
    selectedResource: ResourceBase,
    isLastResource: boolean,
    isPermanentDelete: boolean,
    reload: any
  ) => {
    try {
      const path = `${selectedResource.dir}/${selectedResource.name}`;
      await deleteResource(REPOSITORY_ID, path, isPermanentDelete);
      isLastResource ? this.navigateBack(reload) : reload();
    } catch (e) {
      this.showErrorFeedback(e as Error);
      console.error(e);
    }
  };

  onPermanentDelete = () => {
    this.setState({ isPermanentDelete: !this.state.isPermanentDelete });
  };

  onCopyResource = () => {
    this.setState({ isCopy: !this.state.isCopy });
  };

  onResourceMove = async (
    resourceId: string,
    destination: string,
    copy: boolean,
    reload: any
  ) => {
    try {
      await moveResource(REPOSITORY_ID, resourceId, destination, copy);
      reload();
    } catch (e) {
      this.showErrorFeedback(e as Error);
      console.error(e);
    }
  };

  onResourceEdit = async (path: string, file: any, reload: any) => {
    const { selectedResource } = this.state;
    if (!selectedResource) return;
    try {
      if (file) {
        //Update metadata and file
        const resourceCreateResponse: any = await updateResource(
          REPOSITORY_ID,
          (selectedResource as any).id,
          { path }
        );
        const { operationId } = resourceCreateResponse.data;
        if (operationId) {
          await uploadResource(REPOSITORY_ID, operationId, file);
          reload();
        }
      } else {
        // Update metadata only
        await updateResourceMetadata(
          REPOSITORY_ID,
          (selectedResource as any).id,
          { path }
        );
        reload();
      }
    } catch (e) {
      this.showErrorFeedback(e as Error);
      console.error(e);
    }
  };

  closeModal() {
    this.setState({
      isUploadModalOpen: false,
      isPropertiesModalOpen: false,
      isMoveModalOpen: false,
      isPermanentDelete: false,
      isDeleteModalOpen: false,
      isCopy: false,
      selectedResource: null
    });
  }

  filterResources(data: LocationsResponse) {
    if (!Array.isArray(data?.directories)) return [];
    const { pageSize, page, searchText, total } = this.state;
    const minIndex = page * pageSize;
    const maxIndex = minIndex + pageSize;
    const directories = data.directories.filter((dir: string) =>
      dir.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
    );
    const resources: ResourceBase[] = data.resources.filter(
      (resource: ResourceBase) =>
        resource.name
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase())
    );

    const dirResources = [...directories, ...resources];

    if (dirResources.length !== total) {
      this.setState({ total: dirResources.length });
    }

    return dirResources.slice(minIndex, maxIndex);
  }

  query = () => {
    const { currentPath, sortBy, isSortAscending } = this.state;
    return getResourcesByPath(
      REPOSITORY_ID,
      currentPath,
      sortBy,
      isSortAscending
    );
  };

  navigate = (directory: string, reload: any) => {
    let { currentPath } = this.state;
    currentPath += directory;
    this.setState({ currentPath, page: 0, searchText: "" }, () => reload());
  };

  isLastResource = (resources: any) => {
    return Array.isArray(resources) && resources.length === 1;
  };

  setSort(newSortBy: string) {
    const { sortBy, isSortAscending } = this.state;
    this.setState({
      sortBy: newSortBy,
      isSortAscending: newSortBy === sortBy ? !isSortAscending : true
    });
  }

  createdDate = (creation: any) => {
    if (!isNaN(new Date(creation).getTime())) {
      return new Date(creation).toLocaleString();
    }
  };

  sort = (arr: any[], sortBy: string, isSortAscending: boolean) => {
    if (sortBy === "name") {
      const filtered = arr.sort((a, b) =>
        a.toString().localeCompare(b, undefined, { sensitivity: "base" })
      );
      return isSortAscending ? filtered : filtered.reverse();
    }
    if (sortBy === "creation") {
      const filtered = arr.sort(
        (a, b) =>
          new Date(a.creation).getTime() - new Date(b.creation).getTime()
      );
      return isSortAscending ? filtered : filtered.reverse();
    }
  };

  navigateBack = (reload: any) => {
    let { currentPath } = this.state;
    currentPath = currentPath.slice(0, -1);
    const lastSlashIndex = currentPath.lastIndexOf("/");
    if (lastSlashIndex < 0) currentPath = "";
    else currentPath = `${currentPath.slice(0, lastSlashIndex + 1)}`;
    this.setState({ currentPath, page: 0 }, () => reload());
  };

  render() {
    const {
      pageSize,
      page,
      searchText,
      isUploadModalOpen,
      isPropertiesModalOpen,
      selectedResource,
      currentPath,
      sortBy,
      isSortAscending,
      isDeleteModalOpen,
      isMoveModalOpen,
      total
    } = this.state;

    return (
      <Suspense>
        <SuspenseQuery query={this.query} errorFallback={LoadingError}>
          {({ response, reload, error }) => {
            if (error) {
              /*
              e.g test/test/file
              
              if test dir contains only single file, and when file is deleted, parent dir "test" will also be deleted throwing "dir not found error". When this error is thrown, we want to navigateBack to root and reload the dir list instead of reloading in current (deleted) dir.
              */

              if (error.code === 500) {
                this.navigateBack(reload);
              }
              return <LoadingError httpErrorStatus={error.status} />;
            }
            let resources: any[] = [];
            if (response.data) {
              resources = this.filterResources(response.data);
              this.sort(resources, sortBy, isSortAscending);
            }
            return (
              <Box data-testid="ResourcesList" mx={3}>
                <Flex alignItems="center" justifyContent="space-between" mb={2}>
                  <BackButton
                    mr={2}
                    onClick={() => this.navigateBack(reload)}
                    disabled={!currentPath}
                  />
                  <Flex
                    marginRight="40px"
                    p={2}
                    bg="palette.blue.4"
                    border={2}
                    flex={1}
                    borderRadius={6}
                  >
                    {`/${currentPath}`}
                  </Flex>
                  <Flex alignItems="flex-end">
                    <Box mr={4}>
                      <InputField
                        id="search-input"
                        label="search"
                        autoFocus={true}
                        value={searchText}
                        onChange={(
                          e:
                            | ChangeEvent<HTMLInputElement>
                            | ChangeEvent<HTMLTextAreaElement>
                        ) => this.onChangeSearch(e)}
                      />
                    </Box>
                    <Box>
                      <UploadButton
                        onClick={() =>
                          this.setState({ isUploadModalOpen: true })
                        }
                      >
                        Upload
                      </UploadButton>
                    </Box>
                  </Flex>
                </Flex>
                <Table>
                  <TableHead>
                    <TableRow bg="fill.0">
                      <TableCell width="50" />
                      <TableCell width="auto">
                        <TableSort
                          id="name"
                          name="Name"
                          isSortAscending={isSortAscending}
                          sortBy={sortBy}
                          setSort={(id: string) => this.setSort(id)}
                        />
                      </TableCell>
                      <TableCell width="150">
                        <TableSort
                          id="creation"
                          name=" Date Created"
                          isSortAscending={isSortAscending}
                          sortBy={sortBy}
                          setSort={(id: string) => this.setSort(id)}
                        />
                      </TableCell>
                      <TableCell width="50" />
                      <TableCell width="50" />
                      <TableCell width="50" />
                      <TableCell width="50" />
                      <TableCell width="50" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resources.map((resource: any) => {
                      //If directory
                      if (
                        typeof resource === "string" ||
                        resource instanceof String
                      ) {
                        const directory = resource
                          .substring(1)
                          .replace(currentPath, "");
                        return (
                          <TableRow key={directory}>
                            <TableCell>
                              <Flex justifyContent="center">
                                <Icon name="Folder" size={20} />
                              </Flex>
                            </TableCell>
                            <TableCell>
                              <Flex
                                color={
                                  directory.includes("quarantine") ? "grey" : ""
                                }
                                cursor="pointer"
                                onClick={() => this.navigate(directory, reload)}
                              >
                                {directory}
                              </Flex>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        );
                      } else {
                        return (
                          <TableRow key={resource.id}>
                            <TableCell>
                              <Flex justifyContent="center">
                                <Icon name="File" size={20} />
                              </Flex>
                            </TableCell>
                            <TableCell>{resource.name}</TableCell>
                            <TableCell>
                              {this.createdDate(resource.creation)}
                            </TableCell>
                            <TableCell>
                              <ConfigButton
                                onClick={() =>
                                  this.setState({
                                    isPropertiesModalOpen: true,
                                    selectedResource: resource
                                  })
                                }
                              >
                                {" "}
                                Properties
                              </ConfigButton>
                            </TableCell>
                            <TableCell>
                              <UploadButton
                                onClick={() =>
                                  this.setState({
                                    isUploadModalOpen: true,
                                    selectedResource: resource
                                  })
                                }
                              >
                                {" "}
                                Update
                              </UploadButton>
                            </TableCell>
                            <TableCell>
                              {(this.state.downloadingIds as string[]).includes(
                                resource.id
                              ) ? (
                                <Spinner size={25} />
                              ) : (
                                <DownloadButton
                                  onClick={() =>
                                    this.onResourceDownload(
                                      resource.id,
                                      resource.name
                                    )
                                  }
                                >
                                  Download
                                </DownloadButton>
                              )}
                            </TableCell>
                            <TableCell>
                              <UploadButton
                                onClick={() =>
                                  this.setState({
                                    isMoveModalOpen: true,
                                    selectedResource: resource
                                  })
                                }
                              >
                                Move
                              </UploadButton>
                            </TableCell>
                            <TableCell>
                              <DeleteButton
                                onClick={() =>
                                  this.setState({
                                    isDeleteModalOpen: true,
                                    selectedResource: resource
                                  })
                                }
                              >
                                Delete
                              </DeleteButton>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    })}
                  </TableBody>
                </Table>
                <PaginationSimple
                  total={total}
                  page={page + 1}
                  pageSize={pageSize}
                  nextPageUrl={(page + 1) * pageSize < total ? page + 1 : 0}
                  previousPageUrl={page - 1}
                  onChange={(newPage: string | number) =>
                    this.setState({
                      page: Number(newPage),
                      pageSize
                    })
                  }
                  onPageSizeChange={(newPageSize: number) =>
                    this.setState({
                      page: 0,
                      pageSize: Number(newPageSize)
                    })
                  }
                />
                <UploadResourceModal
                  modalOpen={isUploadModalOpen}
                  path={currentPath}
                  selectedResource={selectedResource}
                  closeModal={() => this.closeModal()}
                  createNewResource={(path, file) => {
                    this.onResourceCreate(path, file, reload);
                  }}
                  editResource={(path, file) => {
                    this.onResourceEdit(path, file, reload);
                  }}
                />
                {selectedResource && (
                  <>
                    <ResourcePropertiesModal
                      modalOpen={isPropertiesModalOpen}
                      selectedResource={selectedResource}
                      closeModal={() => this.closeModal()}
                    />
                    <DeleteModal
                      modalOpen={isDeleteModalOpen}
                      selectedResource={selectedResource}
                      closeModal={() => this.closeModal()}
                      onResourceDelete={this.onResourceDelete}
                      isLastResource={resources.length === 1}
                      reload={reload}
                      isPermanentDelete={this.state.isPermanentDelete}
                      onPermanentDelete={this.onPermanentDelete}
                    />
                    <MoveModal
                      modalOpen={isMoveModalOpen}
                      selectedResource={selectedResource}
                      closeModal={() => this.closeModal()}
                      onResourceMove={this.onResourceMove}
                      reload={reload}
                      isCopy={this.state.isCopy}
                      onCopyResource={this.onCopyResource}
                    />
                  </>
                )}
              </Box>
            );
          }}
        </SuspenseQuery>
      </Suspense>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    setFeedback: (title: string, status: FeedbackStatus, details = "") =>
      dispatch(setFeedback(title, status, details))
  };
};

export const ResourceSContainer = connect(
  null,
  mapDispatchToProps
)(ResourceList);
