import { InputBase } from "app/shared";

const grafanaConfigOptionsSchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "grafana.schema.json",
  title: "Grafana Dashboard",
  name: "Grafana",
  description: "The configuration options to embed Grafana dashboards Aurora",
  type: "object",
  definitions: {
    url: {
      $id: "#/definitions/url",
      description: "Complete URL of the Grafana dashboard",
      type: "string"
    }
  },
  properties: {
    url: {
      $ref: "#/definitions/url"
    }
  },
  required: ["url"]
};

const grafanaConfigOptionsDefault = {
  url:
    "http://af5f7637f19b04e979d21072c9df69fb-1335672067.eu-west-1.elb.amazonaws.com/d-solo/peMIoDfnz/telemetry-service-dashboard?tab=queries&orgId=2&panelId=44&refresh=5s&from=1645179436306&to=1645201036306"
};

const grafanaConfigOptionsUISchema = {
  url: { "ui:widget": InputBase }
};

const grafanaConfigOptionsValidator = (formData: any, errors: any) => {
  const { url } = formData;
  if (!isValidGrafanaUrl(url)) {
    errors.url.addError("Not a valid grafana URL");
  }
  return errors;
};

const isValidGrafanaUrl = (url: string) => {
  return (
    url.startsWith("http://") ||
    (url.startsWith("https://") && url.includes("orgId"))
  );
};

export {
  grafanaConfigOptionsValidator,
  grafanaConfigOptionsSchema,
  grafanaConfigOptionsDefault,
  grafanaConfigOptionsUISchema
};
