import React from "react";
import styled, { css } from "styled-components";
import { variant, themeGet } from "styled-system";
import {
  LAYOUT,
  LayoutProps,
  COMMON,
  CommonProps,
  BORDER,
  BorderProps
} from "styles/constants";
import { Flex, Link } from "primitives";
import {
  TestableProps,
  DATA_TEST_ID_ATTR_NAME
} from "components/testable/models";

const buttonStyle = variant({
  key: "buttons"
});

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    CommonProps,
    LayoutProps,
    TestableProps,
    BorderProps {
  children?: any;
  variant?: string;
  size?: string | number;
  disabled?: boolean;
  to?: string;
  hoverBackgroundColor?: string;
  buttonSize?: string | number;
}

enum ButtonSize {
  smaller = "smaller",
  small = "small",
  medium = "medium",
  large = "large"
}
const { smaller, small, medium, large } = ButtonSize;

const size = (props: ButtonProps) => {
  switch (props.buttonSize) {
    case smaller:
      return css`
        font-size: ${themeGet("fontSizes.2")}px;
        padding: ${themeGet("space.1")}px ${themeGet("space.1")}px;
      `;
    case small:
      return css`
        font-size: ${themeGet("fontSizes.3")}px;
        padding: ${themeGet("space.1")}px ${themeGet("space.2")}px;
      `;
    case medium:
      return css`
        font-size: ${themeGet("fontSizes.6")}px;
        padding: ${themeGet("space.1")}px ${themeGet("space.3")}px;
      `;
    case large:
      return css`
        font-size: ${themeGet("fontSizes.7")}px;
        padding: ${themeGet("space.1")}px ${themeGet("space.3")}px;
      `;
    default:
      return css`
        font-size: ${themeGet("fontSizes.5")}px;
        padding: ${themeGet("space.1")}px ${themeGet("space.3")}px;
      `;
  }
};

const ButtonStyled = styled("button")<ButtonProps>`
  ${buttonStyle}
  ${COMMON}
  ${LAYOUT}
  ${size}

  border: ${themeGet("borders.1")};
  border-radius: ${themeGet("radii.2")}px;
  ${BORDER}

  -webkit-font-smoothing: antialiased;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  outline: none;
  font-weight: ${themeGet("fontWeights.bold")};
  line-height: 1.5;
  cursor: pointer;
  &:disabled {
    opacity: 0.25;
    pointer-events: none;
  }

  :content {
    display: flex;
  }

  &:hover {
    background-color: ${(props) =>
      themeGet(`colors.${props.hoverBackgroundColor}`)} !important;
  }

  ${Link}:hover {
    text-decoration: none;
  }
`;

const Button = ({ children, to, ...props }: ButtonProps) => {
  const hasButtonSize = Object.values(ButtonSize).includes(
    props.size as ButtonSize
  );
  return (
    <ButtonStyled
      {...props}
      buttonSize={props.size}
      size={hasButtonSize ? undefined : props.size}
    >
      {to ? (
        <Link to={to}>
          <Flex alignItems="center">{children}</Flex>
        </Link>
      ) : (
        <Flex
          cursor="pointer"
          alignItems="center"
          justifyContent="center"
          overflow={props.overflow || "auto"}
        >
          {children}
        </Flex>
      )}
    </ButtonStyled>
  );
};

Button.displayName = "Button";

Button.defaultProps = {
  variant: "default",
  type: "button",
  [DATA_TEST_ID_ATTR_NAME]: Button.displayName
};

export default Button;
