import { InputBase } from "app/shared";
import { MultiCheckBox } from "components";

export const userCreateSchema = (groups: any) => {
  return {
    $schema: "http://json-schema.org/draft-07/schema#",
    $id: "table.schema.json",
    type: "object",
    definitions: {
      name: {
        $id: "#/definitions/userCreate",
        type: "string",
        default: ""
      },
      role: {
        $id: "#/definitions/userCreate",
        type: "string",
        default: ""
      },
      satellites: {
        $id: "#/properties/header/properties/satellites",
        title: "Satellites",
        type: "array",
        default: [],
        items: {
          $id: "#/properties/header/properties/show/items",
          type: "string",
          enum: groups ? groups.map((group: { id: string }) => group.id) : [],
          enumNames: groups
            ? groups.map((group: { name: string }) => group.name)
            : []
        },
        uniqueItems: true
      }
    },
    properties: {
      name: {
        $ref: "#/definitions/name"
      },
      role: {
        $ref: "#/definitions/role"
      },
      satellites: {
        $ref: "#/definitions/satellites"
      }
    },
    required: ["name", "role"]
  };
};

export const userCreateUISchema = {
  name: { "ui:widget": InputBase },
  role: { "ui:widget": InputBase },
  satellites: { "ui:field": MultiCheckBox }
};
