import React, { useEffect } from "react";
import { Box, Heading, Flex } from "primitives";
import { Link } from "react-router-dom";
import { AddButton } from "components";
import { useSelector } from "react-redux";
import { SelectSimple as Select } from "components";

export const TelecommandStackListHeader = (props: any) => {
  const { selectSatellite, selectedSatelliteId } = props;
  const satellites = useSelector<any, any>(
    (state: any) => state.constellations.dashboard
  );
  const selected = satellites.find((s: any) => s.visible);
  const id = selected && selected.id;

  useEffect(() => {
    id && selectSatellite(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Flex alignItems="center" justifyContent="space-between" mb={4} mx={3}>
      <Heading display={1}>Telecommand Stacks</Heading>
      <Flex>
        <Box ml={2} width={126} mr={2}>
          <Select
            width="100%"
            required={true}
            name="Satellite"
            onChange={(e) => selectSatellite(parseInt(e.target.value))}
            value={selectedSatelliteId || ""}
          >
            <option />
            {satellites &&
              satellites.map((sat: any) => (
                <option key={sat.id} value={sat.id}>
                  {sat.label}
                </option>
              ))}
          </Select>
        </Box>
        <Link to="/telecommandstacks/import">
          <AddButton mr={2}>Import</AddButton>
        </Link>
        <Link to="/telecommandstacks/create">
          <AddButton>Create</AddButton>
        </Link>
      </Flex>
    </Flex>
  );
};
