import {
  SimpleAlert,
  ArrayAlert,
  AlertIndex,
  DataSource,
  AlertLevels,
  AlertLevel
} from "../models";

export const convertAlertFormDataToApiSpec = (
  dataSource: DataSource,
  formData: any
) => {
  const arraySize = dataSource.tmDataType.arraySize;
  if (!arraySize) {
    const alertLevels: [AlertLevel] = [] as any;
    AlertLevels.forEach((alertLevel) => {
      if (
        formData.alertLevels[alertLevel] &&
        (formData.alertLevels[alertLevel].lowerThreshold ||
          formData.alertLevels[alertLevel].lowerThreshold === 0) &&
        (formData.alertLevels[alertLevel].upperThreshold ||
          formData.alertLevels[alertLevel].upperThreshold === 0)
      ) {
        const newAlertLevel: AlertLevel = {
          severity: alertLevel,
          lowerThreshold: formData.alertLevels[alertLevel].lowerThreshold,
          upperThreshold: formData.alertLevels[alertLevel].upperThreshold
        };
        alertLevels.push(newAlertLevel);
      }
    });
    const alert: SimpleAlert = {
      alertType: "SimpleAlert",
      valueBoundary: {
        minValue: formData.valueBoundary.minValue,
        maxValue: formData.valueBoundary.maxValue
      },
      alertLevels
    };
    return alert;
  } else {
    const indexes: [AlertIndex] = [] as any;
    Array.from(Array(arraySize)).forEach((x, index) => {
      const alertLevels: [AlertLevel] = [] as any;
      AlertLevels.forEach((alertLevel) => {
        if (
          formData.alertLevels[alertLevel] &&
          ((formData.alertLevels[alertLevel].isBulkAssign &&
            (formData.alertLevels[alertLevel].lowerThreshold ||
              formData.alertLevels[alertLevel].lowerThreshold === 0) &&
            (formData.alertLevels[alertLevel].upperThreshold ||
              formData.alertLevels[alertLevel].upperThreshold === 0)) ||
            (!formData.alertLevels[alertLevel].isBulkAssign &&
              (formData.alertLevels[alertLevel].thresholds[index]
                .lowerThreshold ||
                formData.alertLevels[alertLevel].thresholds[index]
                  .lowerThreshold === 0) &&
              (formData.alertLevels[alertLevel].thresholds[index]
                .upperThreshold ||
                formData.alertLevels[alertLevel].thresholds[index]
                  .upperThreshold === 0)))
        ) {
          const newAlertLevel: AlertLevel = {
            severity: alertLevel,
            lowerThreshold: formData.alertLevels[alertLevel].isBulkAssign
              ? formData.alertLevels[alertLevel].lowerThreshold
              : formData.alertLevels[alertLevel].thresholds[index]
                  .lowerThreshold,
            upperThreshold: formData.alertLevels[alertLevel].isBulkAssign
              ? formData.alertLevels[alertLevel].upperThreshold
              : formData.alertLevels[alertLevel].thresholds[index]
                  .upperThreshold
          };
          alertLevels.push(newAlertLevel);
        }
      });
      const newIndex: AlertIndex = {
        index,
        valueBoundary: {
          minValue: formData.valueBoundary.isBulkAssign
            ? formData.valueBoundary.minValue
            : formData.valueBoundary.valueBoundaries[index].minValue,
          maxValue: formData.valueBoundary.isBulkAssign
            ? formData.valueBoundary.maxValue
            : formData.valueBoundary.valueBoundaries[index].maxValue
        },
        alertLevels
      };
      indexes.push(newIndex);
    });
    const alert: ArrayAlert = {
      alertType: "ArrayAlert",
      indexes
    };
    return alert;
  }
};

export const convertAlertApiSpecToFormData = (
  dataSource: DataSource,
  formData: {
    dataSourceId: number;
    alert: any;
  }
) => {
  const arraySize = dataSource.tmDataType.arraySize;
  if (!arraySize) {
    const alertLevels: any = {};
    formData.alert.alertLevels?.forEach((alertLevel: any) => {
      alertLevels[alertLevel.severity] = {
        lowerThreshold: alertLevel.lowerThreshold,
        upperThreshold: alertLevel.upperThreshold
      };
    });
    const alertFormData = {
      valueBoundary: {
        ...formData.alert.valueBoundary
      },
      alertLevels: {
        ...alertLevels
      }
    };
    return alertFormData;
  } else {
    const valueBoundaries: any = [];
    const alertLevels: any = {};
    AlertLevels.forEach((alertLevel) => {
      alertLevels[alertLevel] = {};
      alertLevels[alertLevel].thresholds = [];
    });
    const indexes = formData?.alert?.indexes;
    Array.from(Array(arraySize)).forEach((x, index) => {
      const existingIndex: AlertIndex = indexes?.find(
        (element: AlertIndex) => element.index === index
      );
      const newValueBoundary = {
        minValue: existingIndex
          ? existingIndex.valueBoundary.minValue
          : undefined,
        maxValue: existingIndex
          ? existingIndex.valueBoundary.maxValue
          : undefined
      };
      valueBoundaries.push(newValueBoundary);
      AlertLevels.forEach((alertLevel) => {
        const existingAlertLevel = existingIndex
          ? existingIndex.alertLevels.find(
              (element: AlertLevel) => element.severity === alertLevel
            )
          : null;
        const newAlertLevel = {
          lowerThreshold:
            existingIndex && existingAlertLevel
              ? existingAlertLevel.lowerThreshold
              : undefined,
          upperThreshold:
            existingIndex && existingAlertLevel
              ? existingAlertLevel.upperThreshold
              : undefined
        };
        alertLevels[alertLevel].lowerThreshold =
          existingAlertLevel && existingAlertLevel.lowerThreshold;
        alertLevels[alertLevel].upperThreshold =
          existingAlertLevel && existingAlertLevel.upperThreshold;
        alertLevels[alertLevel].thresholds.push(newAlertLevel);
      });
    });
    const alertFormData = {
      valueBoundary: {
        minValue: valueBoundaries[0].minValue,
        maxValue: valueBoundaries[0].maxValue,
        valueBoundaries
      },
      alertLevels: {
        ...alertLevels
      }
    };
    return alertFormData;
  }
};
