import {
  dataProvider,
  GET_LIST,
  CREATE,
  GET_ONE,
  UPLOAD,
  telemetryDataProvider
} from "app/network";

/**
 * @param {number} satelliteDefinitionId
 */
export const getTelecommands = (satelliteDefinitionId) => {
  return dataProvider(
    GET_LIST,
    `satellite-definition/${satelliteDefinitionId}/availableTelecommands`
  );
};

/**
 * @param {number, object} satelliteId, telecommandData
 */
export const execTelecommand = (satelliteId, data) => {
  let apiVersion = "";
  if (data.telecommand.params) apiVersion = "v1";
  return dataProvider(
    CREATE,
    `${apiVersion}/satellite/${satelliteId}/telecommands`,
    {
      data
    }
  );
};

/**
 * @param {number, object} satelliteId, telecommandData
 */
export const uploadTelecommand = (satelliteId, data) => {
  return dataProvider(UPLOAD, `satellite/${satelliteId}/telecommands`, {
    data
  });
};

/**
 * @param {number} satelliteId
 */
export const getExecTelecommands = (satelliteId, pagination) => {
  return dataProvider(GET_LIST, `satellite/${satelliteId}/telecommands`, {
    pagination
  });
};

/**
 * @param {number} satelliteId
 */
export const getExecTelecommand = (satelliteId, executionId) => {
  return dataProvider(
    GET_LIST,
    `satellite/${satelliteId}/telecommands/${executionId}`
  );
};

export const getSyncTelecommandResponse = (
  satelliteId,
  executedTelecommandId,
  from
) => {
  return telemetryDataProvider(
    GET_ONE,
    `satellite/${satelliteId}/telecommand-response`,
    { id: executedTelecommandId, from }
  );
};

/**
 * @param {number, object} satelliteId, telecommandData
 */
export const uploadTelecommandFile = (satelliteId, data) => {
  return dataProvider(UPLOAD, `satellite/${satelliteId}/telecommands/file`, {
    data
  });
};
