import React from "react";
import PropTypes from "prop-types";
import { Icon } from "primitives";

const Spinner = (props: { color: string; size: number }) => (
  <Icon name="Spinner" {...props}></Icon>
);

Spinner.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

Spinner.defaultProps = {
  color: "#FFCB66",
  size: 22
};

export default Spinner;
