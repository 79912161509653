import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgSettings = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 20 20"
    color={color}
  >
    <g transform="translate(-15 -562)">
      <path d="M23.753 571.833A4.961 4.961 0 0 0 22 571.1V570h-2v1.1a4.969 4.969 0 0 0-1.753.732l-.783-.783-1.414 1.414.783.783A4.987 4.987 0 0 0 16.1 575H15v2h1.1a4.98 4.98 0 0 0 .732 1.753l-.783.783 1.414 1.414.783-.783A4.969 4.969 0 0 0 20 580.9v1.1h2v-1.1a4.969 4.969 0 0 0 1.753-.732l.783.783 1.414-1.414-.783-.783A4.961 4.961 0 0 0 25.9 577H27v-2h-1.1a4.961 4.961 0 0 0-.732-1.753l.783-.783-1.414-1.414zM24 576a3 3 0 1 1-3-3 3 3 0 0 1 3 3z" />
      <circle cx={1} cy={1} r={1} transform="translate(20 575)" />
      <path d="M33.858 566a3.929 3.929 0 0 0-.425-1.019l.81-.81-1.414-1.414-.81.81a3.914 3.914 0 0 0-1.019-.425V562h-2v1.142a3.948 3.948 0 0 0-1.019.425l-.81-.81-1.414 1.414.81.81a4 4 0 0 0-.425 1.019H25v2h1.142a3.985 3.985 0 0 0 .425 1.019l-.81.81 1.414 1.414.81-.81a3.929 3.929 0 0 0 1.019.425V572h2v-1.142a3.929 3.929 0 0 0 1.019-.425l.81.81 1.414-1.414-.81-.81a3.95 3.95 0 0 0 .425-1.019H35v-2zM32 567a2 2 0 1 1-2-2 2 2 0 0 1 2 2z" />
    </g>
  </Svg>
);

SvgSettings.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgSettings;
