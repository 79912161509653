import {
  GET_LIST,
  CREATE,
  UPDATE_ONE,
  DELETE_LIST,
  UPDATE,
  DELETE
} from "app/network";
import { convertToLocalTelemetryModel } from "app/telemetry/models/dto";
import { telemetryDataProvider, dataProvider } from "app/network";
import { store } from "app/store";
import { convertOnboardTime } from "app/telemetry/utils";
import * as actions from "../actions";
import { clone } from "utils";
import qs from "qs";
import {
  updateTelemetryData,
  updateHistoricalTelemetryData
} from "app/dashboard/models/telemetry";
import { getDateFromTimeController } from "app/shared/timeController/utils";

/**
 * Walks through satellite definition
 * to find datasource's ids by name
 * @param {Array<string>} dataSourceNames
 * @param {object} systems
 * @param {Array<empty>} ids
 */
const findDataSourceIds = (dataSourceNames, systems, ids) => {
  systems.forEach((system) => {
    system.dataSources.forEach((dataSource) => {
      if (dataSourceNames.includes(dataSource.name)) {
        ids.push(dataSource.id);
      }
    });
    if (system.systems) {
      findDataSourceIds(dataSourceNames, system.systems, ids);
    }
  });
};

/**
 * Gets findDataSourceIds results and put into an array
 * @param {Array<string>} dataSourceNames
 * @param {object} systems
 * @returns {Array<number>}
 */
export const findDataSourcesIDsFromSystems = (dataSourceNames, systems) => {
  const ids = [];
  findDataSourceIds(dataSourceNames, systems, ids);
  return ids;
};

/** Walks through satellite definition
 * and all datasource into an array
 * @param {object} systems
 * @param {Array<empty>} dataSources
 */
export const getDataSources = (systems, dataSources) => {
  systems &&
    systems.forEach((system) => {
      dataSources.push(...system.dataSources);
      if (system.systems) {
        getDataSources(system.systems, dataSources);
      }
    });
};

/**
 * Gets getDataSources results and put into an array
 * @param {Array<string>} dataSourceNames
 * @param {object} systems
 * @returns {Array<number>}
 */
export const getDataSourcesFromSystemDefinition = (systems) => {
  const dataSources = [];
  getDataSources(systems, dataSources);
  return dataSources;
};

/**
 * Get data from telemetry api by datasource's ids
 * returns and store into the redux
 *
 * @param {object | string} satellite
 * @param {Array<number>} ids
 * @returns {Promise}
 */
export const getReadingsByDataSources = async (
  satellite,
  ids,
  params = {},
  options = {}
) => {
  const { timeController } = store.getState().datastore;
  const { from, to } = getDateFromTimeController(timeController);
  store.dispatch(actions.fetchReadingStart());
  options = clone(options);
  let filter = {
    datasources: ids.join(","),
    windowSize: params.windowSize || 1,
    ...{
      ...params,
      from,
      to: params.to ? params.to : to
    }
  };
  if (options && options.timeReference) {
    // If no time interval is selected it should fectch datapoints from the last 15 minutes
    if (!options.timeReference.from && !options.timeReference.to) {
      if (params.from)
        options.timeReference.from = new Date(Date.now() - params.from * 1000);
      if (params.to)
        options.timeReference.to = new Date(Date.now() - params.to * 1000);
    }

    filter = { ...filter, ...options.timeReference };
    delete filter.reference;

    //TODO/WORKAROUND: Change backend to match timeReference.reference enum
    switch (options.timeReference.reference) {
      case "satelliteUtc":
        filter.timeReference = "SendUtcTime";
        break;
      case "groundUtc":
        filter.timeReference = "ReceiveUtcTime";
        break;
      case "satelliteBootcountObt":
        filter.timeReference = "OnboardTime";
        if (filter.fromBootcount && filter.toBootcount) {
          filter.from = convertOnboardTime(
            filter.fromBootcount.toString(),
            filter.fromBootcountTimestamp.toString()
          );
          filter.to = convertOnboardTime(
            filter.toBootcount.toString(),
            filter.toBootcountTimestamp.toString()
          );
        }
        delete filter.fromBootcount;
        delete filter.toBootcount;
        delete filter.fromBootcountTimestamp;
        delete filter.toBootcountTimestamp;
        break;
      default:
        break;
    }
  }
  try {
    const satID = satellite.id ? satellite.id : satellite;
    const { data } = await telemetryDataProvider(
      GET_LIST,
      `satellite/${satID}/telemetry`,
      {
        filter
      }
    );
    //Convert Alert Response Data format to Alert Local Data format
    const convertedData = [];
    Array.isArray(data) &&
      data.forEach((d) => {
        convertedData.push(convertToLocalTelemetryModel(d));
      });
    if (
      filter.windowSize > 1 &&
      convertedData.some((cd) => cd.readings.length)
    ) {
      store.dispatch(updateHistoricalTelemetryData(convertedData, []));
    } else if (filter.windowSize === 1) {
      store.dispatch(updateTelemetryData(convertedData));
    }
    store.dispatch(actions.fetchReadingEnd());
    return convertedData;
  } catch (error) {
    console.log("TURBO ~ telemetryDataProvider error", error);
    store.dispatch(actions.fetchReadingError());
  }
};

/**
 * Get data from telemetry api by datasource's ids
 *
 * @param {object} satellite
 * @param {Array<number>} ids
 * @returns {Promise}
 */
export const getReadingsMultipleDataSources = (
  satellite,
  params,
  options = {}
) => {
  const { paginationUrl, ...defaultParams } = params;

  let requestParams = defaultParams;
  if (paginationUrl) {
    const url = new URL(paginationUrl);
    requestParams = {
      filter: {
        ...qs.parse(url.search, { ignoreQueryPrefix: true }),
        ...defaultParams.filter
      }
    };
  }

  return telemetryDataProvider(
    GET_LIST,
    `satellite/${satellite.id}/history`,
    requestParams
  ).then((response) => {
    return response;
  });
};

export const getDataSourceUnits = (pagination) => {
  store.dispatch(actions.fetchDatasourceUnitsStart());
  return dataProvider(GET_LIST, `datasource-unit`, {
    pagination
  })
    .then((response) => {
      store.dispatch(actions.fetchDatasourceUnitsSuccess(response.data));
      return response;
    })
    .catch((error) => {
      store.dispatch(actions.fetchDatasourceUnitsError());
    });
};

export const deleteDataSourceUnit = (unit) => {
  return dataProvider(DELETE, `datasource-unit/${unit}`);
};

export const createDataSourceUnit = (unit) => {
  return dataProvider(CREATE, `datasource-unit/`, {
    data: { unit }
  });
};

export const getDataSourceAlert = (dataSource) => {
  return dataProvider(GET_LIST, `datasource/${dataSource.id}/alert-definition`);
};

export const createDataSourceAlert = (dataSource, alert) => {
  return dataProvider(CREATE, `datasource/${dataSource.id}/alert-definition`, {
    data: alert
  });
};

export const editDataSourceAlert = (dataSource, alert) => {
  const { active, dataSourceId, id, ...data } = alert;
  return dataProvider(
    UPDATE_ONE,
    `datasource/${dataSource.id}/alert-definition`,
    {
      data
    }
  );
};

export const deleteDataSourceAlert = (dataSource) => {
  return dataProvider(
    DELETE_LIST,
    `datasource/${dataSource.id}/alert-definition`
  );
};

export const editDataSource = (dataSource, data) => {
  const { alertId, id, ...dataSourceData } = data;
  return dataProvider(UPDATE, `datasource`, {
    id: dataSource.id,
    data: dataSourceData
  });
};
