import React from "react";
import { Loading } from "./Loading";

interface SuspenseProps {
  children: React.ReactElement;
  fallback?: React.ReactNode;
}

export const Suspense = React.memo(({ children, fallback }: SuspenseProps) => {
  return (
    <React.Suspense fallback={fallback || <Loading />}>
      {children}
    </React.Suspense>
  );
});
