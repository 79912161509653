import React, { useEffect, useState, useCallback } from "react";
import { Tag } from "components";
import { StatusIndicatorTheme, CountDownProps } from "./models";
import { palette } from "styles/palette";
import { calculateRemainingTime } from "./helpers";

export const CountDown = ({
  expirationTime,
  theme = "dark",
  onExpire
}: CountDownProps) => {
  const expirationDateTime = new Date(expirationTime);
  const [remainingTime, setRemainingTime] = useState<string>(
    calculateRemainingTime(expirationDateTime)
  );
  let timer: NodeJS.Timeout;

  useEffect(() => {
    clearInterval(timer);
    setRemainingTime(calculateRemainingTime(expirationDateTime));
    timer = setInterval(count, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [expirationTime]);

  const count = useCallback(() => {
    const now = new Date();

    setRemainingTime(calculateRemainingTime(expirationDateTime));

    /**
     * Coundown finished
     */
    if (now > expirationDateTime) {
      onExpire && onExpire();
      return clearInterval(timer);
    }
  }, [expirationTime]);

  return (
    <div data-testid={"countDown"}>
      <Tag
        color={palette.green[1]}
        bg={StatusIndicatorTheme[theme]}
        label={remainingTime}
      />
    </div>
  );
};
