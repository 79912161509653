import React, { Component } from "react";
import { Form } from "app/shared";
import { Box, Heading, Flex, Text } from "primitives";
import { SuspenseQuery, SuspenseMutation, Suspense } from "app/network";
import { schemaGenerator, uiSchema } from "../models/schemas";
import { SaveButton, CancelButton } from "components";
import { getDataSourcesFromSystemDefinition } from "app/dataSource";
import { validateFormData } from "../utils/validators";
import { FeedbackStatus } from "app/feedback/models";

export class DashboardEdit extends Component {
  state = {
    formData: null,
    formChanges: 0,
    dataSources: [],
    satelliteDefinitions: [],
    satelliteDefinitionId: null,
    selectedSatelliteDefinition: null,
    satelliteInstances: []
  };

  constructor(props) {
    super(props);
    this.addChange = this.addChange.bind(this);
  }

  componentDidUpdate() {
    this.retrieveSatelliteDefinitionList();
  }

  retrieveSatelliteDefinitionList() {
    const { constellations } = this.props;
    const { satelliteDefinitions } = this.state;

    if (
      satelliteDefinitions.length === 0 &&
      constellations &&
      constellations.length
    ) {
      const satDefs = [];
      const satInstances = [];
      constellations.forEach((constellation) => {
        constellation.satelliteInstances.forEach((satellite) => {
          const satDefId =
            satellite.satelliteDefinitionSummary.satelliteDefinitionId;
          const index = satDefs.findIndex(
            (it) => it.satelliteDefinitionId === satDefId
          );

          // Filter duplicated satellite definitions
          if (index <= -1) {
            if (satellite.satelliteDefinitionSummary.satelliteDefinitionId) {
              satellite.satelliteDefinitionSummary.id =
                satellite.satelliteDefinitionSummary.satelliteDefinitionId;
            }
            satDefs.push(satellite.satelliteDefinitionSummary);
          }
          satInstances.push(satellite);
        });
      });
      this.setState({
        satelliteDefinitions: satDefs,
        satelliteInstances: satInstances
      });
    }
  }

  updateSatelliteDefinitionDataSources(satelliteDefinitionId) {
    this.props
      .getSatelliteDefinition(satelliteDefinitionId)
      .then((satelliteDefinition) => {
        if (!satelliteDefinition) return;
        const dataSources = getDataSourcesFromSystemDefinition(
          satelliteDefinition.systems
        );
        this.setState({
          satelliteDefinitionId,
          dataSources,
          selectedSatelliteDefinition: satelliteDefinition
        });
      });
  }

  submit(e, { formData }) {
    e.preventDefault();
    const { id } = this.props.match.params;

    return this.props.editDashboard(id, formData).then(() => {
      this.props.setFeedback(
        "Dashboard successfully saved.",
        FeedbackStatus.SUCCESS
      );
      const { satelliteDefinitionId } = this.state;
      this.props.history.push(
        `/dashboard?satelliteDefinitionId=${satelliteDefinitionId}`
      );
    });
  }

  onChange(form) {
    let { satelliteDefinitionId, formChanges } = this.state;
    const selectedSatelliteDefinitionId = form.formData.satDefinitionId;

    if (!selectedSatelliteDefinitionId) {
      this.setState({ selectedSatelliteDefinition: null });
    } else if (selectedSatelliteDefinitionId !== satelliteDefinitionId) {
      this.updateSatelliteDefinitionDataSources(selectedSatelliteDefinitionId);
    }

    //Set default data source labels
    const formData = form.formData;
    if (formData) {
      formData.components.forEach((component, componentIndex) => {
        component.dataSources.forEach((dataSource, dataSourceIndex) => {
          const currentLabel =
            formData.components[componentIndex].dataSources[dataSourceIndex]
              .label;
          if (!currentLabel) {
            const dataSourceId =
              formData.components[componentIndex].dataSources[dataSourceIndex]
                .datasourceId;
            const ds = this.state.dataSources.find(
              (d) => d.id === dataSourceId
            );
            if (ds) {
              formData.components[componentIndex].dataSources[
                dataSourceIndex
              ].label = ds.name;
              formData.components[componentIndex].dataSources[
                dataSourceIndex
              ].name = ds.name;
            }
          }
        });
      });
    }

    //Update dashboard row size
    let rowSize = 1;
    formData.components.forEach((component) => {
      if (component.position.row + component.size.row - 1 > rowSize) {
        rowSize = component.position.row + component.size.row - 1;
      }
    });
    formData.size.row = rowSize;
    this.setState({ formData: form.formData, formChanges: ++formChanges });
  }

  addChange() {
    let { formChanges } = this.state;
    this.setState({ formChanges: ++formChanges });
  }

  query = () => {
    const { id } = this.props.match.params;

    return this.props.getUserDashboard(id).then((dashboard) => {
      this.updateSatelliteDefinitionDataSources(
        dashboard.applicableSatDefinition.id
      );

      // Transform received payload to edit payload
      dashboard.id = undefined;
      dashboard.satDefinitionId = dashboard.applicableSatDefinition.id;
      dashboard.applicableSatDefinition = undefined;
      dashboard.ownerId = undefined;
      return dashboard;
    });
  };

  transformData(data) {
    data &&
      data.components &&
      data.components.forEach((component, componentIndex) => {
        if (
          component.configOptions &&
          component.dataSources &&
          component.configOptions.dataSources
        ) {
          component.dataSources.forEach((datasource, datasourceIndex) => {
            const datasourceConfigOptions = component.configOptions.dataSources.find(
              (ds) => ds.id === datasource.datasourceId
            );
            if (datasourceConfigOptions) {
              data.components[componentIndex].dataSources[datasourceIndex] = {
                ...datasourceConfigOptions,
                ...data.components[componentIndex].dataSources[datasourceIndex]
              };
            }
          });
        }
      });
    if (!this.state.formData) {
      this.setState({ formData: data });
    }
    return data;
  }

  render() {
    const {
      dataSources,
      satelliteDefinitions,
      satelliteDefinitionId,
      selectedSatelliteDefinition,
      formData,
      satelliteInstances,
      formChanges
    } = this.state;

    const schema = schemaGenerator(
      dataSources,
      satelliteDefinitions,
      satelliteDefinitionId,
      selectedSatelliteDefinition,
      formData,
      satelliteInstances,
      this.addChange
    );

    return (
      <Suspense>
        <SuspenseQuery query={this.query}>
          {({ response }) => {
            return (
              response && (
                <Box color="text.default" data-testid="DashboardEdit" mx={3}>
                  <Flex mb={2}>
                    <Heading display={1}>Dashboard</Heading>
                  </Flex>
                  <Flex flexDirection="column" bg="fill.0" p={3}>
                    <SuspenseMutation>
                      {({ loading, error, action }) => (
                        <Form
                          formData={
                            formData
                              ? this.transformData(formData)
                              : this.transformData(response)
                          }
                          schema={schema}
                          uiSchema={uiSchema}
                          validate={(data, errors) =>
                            validateFormData(data, errors, dataSources)
                          }
                          disabled={loading}
                          onSubmit={(data, e) =>
                            action(() => this.submit(e, data))
                          }
                          onChange={(form) => {
                            this.onChange(form);
                          }}
                          onError={(errors) =>
                            this.props.setFeedback(
                              "Error saving dashboard.",
                              FeedbackStatus.ERROR,
                              errors.map((error) => error.stack).join(", ")
                            )
                          }
                        >
                          <CancelButton
                            onClick={() =>
                              this.props.history.push(
                                `/dashboard?satelliteDefinitionId=${satelliteDefinitionId}`
                              )
                            }
                            mr={2}
                          >
                            Cancel
                          </CancelButton>
                          <SaveButton
                            type="submit"
                            disabled={loading || formChanges <= 1}
                          >
                            Save
                          </SaveButton>

                          {error ? (
                            <Box my={2}>
                              <Text color="text.danger">
                                Error...{console.log("error ", error)}
                              </Text>
                            </Box>
                          ) : null}
                        </Form>
                      )}
                    </SuspenseMutation>
                  </Flex>
                </Box>
              )
            );
          }}
        </SuspenseQuery>
      </Suspense>
    );
  }
}
