import { connect } from "react-redux";
import {
  getReadingsByDataSources,
  getReadingsMultipleDataSources
} from "../services";
import { DataSourceReading } from "../components/DataSourceReading";
import { createSelector } from "reselect";

// a custom mapStateToProps function to enable dynamic passing of state based on component props. This prevents unnessary state passing into dashboards where it is not needed and causes unnessary rerenders.

const makeMapStateToProps = () => {
  // create a light slice of readings to pass it down to widgets
  const __historicalReadings = makeHistoricalReadingsSelector();
  const __currentReadings = makeCurrentReadingsSelector();
  const mapStateToProps = (state, ownProps) => {
    // Handle graph data separately to prevent double rendering when TM
    // is received from background fetchers. (current TM requests resolve first)
    const isGraph = ownProps.options?.widgetName === "RealTimeGraph";
    if (isGraph) {
      const historicalReadings = __historicalReadings(state, ownProps);
      return {
        // No need to refresh data on tab change when timeController is handling
        // refresh
        refreshData: null,
        historicalReadings
      };
    }
    const currentReading = __currentReadings(state, ownProps);
    // Pass refreshDate to historicalTable as it is not controlled by timecontroller and has no configOptions
    if (!ownProps.options) {
      return {
        refreshData: state.app.refreshData,
        currentReading
      };
    }
    return {
      refreshData: null,
      currentReading
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = () => {
  return {
    getReadingsByDataSources: (satellite, ids, params, options) =>
      getReadingsByDataSources(satellite, ids, params, options),
    getReadingsMultipleDataSources: (satellite, ids, options) =>
      getReadingsMultipleDataSources(satellite, ids, options)
  };
};

export const DataSourceReadingContainer = connect(
  makeMapStateToProps,
  mapDispatchToProps
)(DataSourceReading);

// SELECTOR HELPERS
// factory to create memoized selectors based on current state in redux store and datasource ids contained in widget props. Selectors of subsets of datasources will ensure that graphs or widgets update ONLY when relevant datasources have new data available in the store.
const makeCurrentReadingsSelector = () =>
  createSelector(
    [
      (state) => state.datastore.telemetry.currentReading,
      (_, ownProps) => ownProps.ids
    ],
    (readings, props) => {
      return extractReadingsByDatasourceIds(readings, props);
    }
  );

const makeHistoricalReadingsSelector = () =>
  createSelector(
    [
      (state) => state.datastore.telemetry.historicalReadings,
      (_, ownProps) => ownProps.ids
    ],
    (readings, props) => {
      return extractReadingsByDatasourceIds(readings, props);
    }
  );

// create a lighter object of tm readings only based on widget props
function extractReadingsByDatasourceIds(readings, ownProps) {
  const ids = ownProps;
  const obj = readings;

  if (!ids || !obj || Object.keys(obj).length === 0) {
    return [];
  }
  const result = {};
  ids.forEach((id) => {
    if (Object.prototype.hasOwnProperty.call(obj, id)) {
      result[id] = obj[id];
    }
  });
  return Object.values(result);
}
