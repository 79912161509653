import React, { Component } from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import SortableTree, { toggleExpandedForAll } from "react-sortable-tree";
import NodeRendererDefault from "app/shared/tree/nodeRendererDefault";
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from "react-sortable-hoc";
import {
  Heading,
  Flex,
  Button,
  Box,
  Text,
  Icon,
  Label,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "primitives";
import {
  SaveButton,
  Switch,
  InputField,
  CheckBox,
  InputSimple
} from "components";
import { arrayMove } from "app/shared/utils";
import { hasAllowedDataType } from "./utils";

const DATASOURCE_NODE = "ds";
const SYSTEM_NODE = "system";

const ClickableFlex = styled(Flex)`
  cursor: pointer;
`;

const Link = styled("span")`
  color: ${themeGet("colors.palette.brand.0")};
  font-size: 12px;
  margin: 5px;
  text-decoration: underline;
  cursor: pointer;
`;

export class DataSourceTreeField extends Component {
  state = {
    treeData: [],
    dataSources: [],
    systems: [],
    searchString: "",
    searchFocusIndex: null,
    searchFound: [],
    dataSourceLabels: {},
    modalOpen: null,
    dataSourceIndexes: {},
    indexesOrder: []
  };

  componentDidMount() {
    const {
      satelliteDefinition,
      selectedDataSources,
      selectedSystems,
      dataSourceLabels,
      dataSourceIndexes,
      satelliteSystems
    } = this.props;
    const systems = satelliteSystems ? satelliteSystems : [];
    const treeData = this.formatSystemTree(systems);
    this.onChange(treeData, satelliteDefinition);
    this.setState({
      dataSources: selectedDataSources,
      dataSourceLabels,
      dataSourceIndexes,
      systems: selectedSystems || []
    });
  }

  componentDidUpdate(prevProps) {
    const {
      satelliteSystems,
      satelliteDefinition,
      selectedDataSources
    } = this.props;
    if (prevProps.selectedDataSources !== selectedDataSources) {
      this.setState({
        dataSources: selectedDataSources
      });
    }
    if (prevProps.satelliteSystems !== satelliteSystems) {
      const systems = satelliteSystems ? satelliteSystems : [];
      const treeData = this.formatSystemTree(systems);
      this.onChange(treeData, satelliteDefinition);
    }
  }

  onChange(treeData, satelliteDefinition) {
    let data = treeData;
    if (satelliteDefinition) {
      data = [
        {
          id: satelliteDefinition.id,
          title: satelliteDefinition.name,
          expanded: true,
          children: data
        }
      ];
    }
    this.setState({ treeData: data });
  }

  toggleExpandAll(expanded) {
    const treeData = this.state.treeData;
    const newTreeData = toggleExpandedForAll({
      treeData,
      expanded: expanded
    });

    this.onChange(newTreeData);
  }

  formatDataSourceTree(systemId, dataSources) {
    if (!dataSources) return [];
    return dataSources.map((dataSource) => {
      return {
        id: dataSource.id,
        title: dataSource.name,
        label: dataSource.label,
        expanded: false,
        record: { ...dataSource, type: DATASOURCE_NODE },
        systemId
      };
    });
  }

  formatSystemTree(systems, parentDefinitionId) {
    const { bySystems } = this.props;
    if (!systems) return [];
    if (bySystems) {
      return systems.map((system) => {
        return {
          id: system.id,
          title: system.name,
          expanded: false,
          record: { parentDefinitionId, ...system, type: SYSTEM_NODE },
          dataSources: [
            ...this.formatDataSourceTree(system.id, system.dataSources)
          ],
          children: [...this.formatSystemTree(system.systems, system.id)]
        };
      });
    }
    return systems.map((system) => {
      return {
        id: system.id,
        title: system.name,
        expanded: false,
        record: { parentDefinitionId, ...system, type: SYSTEM_NODE },
        children: [
          ...this.formatDataSourceTree(system.id, system.dataSources),
          ...this.formatSystemTree(system.systems, system.id)
        ]
      };
    });
  }

  onSelectDataSource(node, checked) {
    const { maxItems, allowedDataTypes, addDataSources } = this.props;
    const dataSources = this.state.dataSources.filter((dataSource) => {
      return dataSource !== node.record.id;
    });

    const dataSourceLabels = this.state.dataSourceLabels;
    dataSourceLabels[node.record.id] = node.record.name;

    if (
      checked &&
      (!maxItems || dataSources.length + 1 <= maxItems) &&
      (!allowedDataTypes || hasAllowedDataType(node.record, allowedDataTypes))
    ) {
      dataSources.push(node.record.id);
      addDataSources([node.record]);
    } else {
      const systems = this.state.systems.filter((id) => {
        return id !== node.systemId;
      });
      this.setState({ systems });
    }

    this.setState({ dataSources, dataSourceLabels }, () =>
      this.props.onChange(this.state)
    );
  }

  /**
   * Return all datasources/system in the tree
   * @param {object} system
   */
  getAllDataSourcesFromSystem(system, addNodes) {
    const { bySystems } = this.props;
    const dataSources = [];
    const systems = [];
    const nodes = [];

    if (bySystems) {
      system.dataSources.forEach((node) => {
        dataSources.push(node.id);
      });
    }

    // Get all datasource id's from the current system
    system.children &&
      system.children.forEach((node) => {
        if (!bySystems) {
          if (node.record.type === DATASOURCE_NODE) {
            dataSources.push(node.id);
            if (addNodes) nodes.push(node);
          }
        }

        if (node.record.type === SYSTEM_NODE) {
          // If the node is a system then get all DataSources
          const [ds, sys] = this.getAllDataSourcesFromSystem(node);
          dataSources.push(...ds);
          systems.push(node.id, ...sys);
        }
      });

    return [dataSources, systems, nodes];
  }

  onSelectSystem(system, checked, addNodes) {
    let systems = this.state.systems.filter((id) => {
      return id !== system.id;
    });

    let [
      dataSources,
      selectedSystems,
      nodes
    ] = this.getAllDataSourcesFromSystem(system, addNodes);

    systems.push(...selectedSystems);

    if (checked) {
      // Select the uniques datasources and system in the current node
      const { maxItems, addDataSources } = this.props;
      if (
        !maxItems ||
        dataSources.length + this.state.dataSources.length <= maxItems
      ) {
        systems.push(system.id);
        dataSources = [...new Set([...dataSources, ...this.state.dataSources])];
        addDataSources(nodes);
      } else return;
    } else {
      // Remove all datasources and system from the current node
      dataSources = this.state.dataSources.filter((id) => {
        return !dataSources.includes(id);
      });
      systems = this.state.systems.filter((id) => {
        return id !== system.id && !selectedSystems.includes(id);
      });
    }

    this.setState({ systems, dataSources }, () =>
      this.props.onChange(this.state)
    );
  }

  hasDataSources(node) {
    return this.getAllDataSourcesFromSystem(node).length > 0;
  }

  hasSelectedChildrenOrIsSelected(node) {
    const { dataSources } = this.state;
    const childDataSources = this.getAllDataSourcesFromSystem(node)[0];
    if (dataSources && childDataSources) {
      return (
        dataSources.includes(node.id) ||
        dataSources.some((ds) => childDataSources.includes(ds))
      );
    }
    return false;
  }

  customSearchMethod({ node, searchQuery }) {
    return (
      searchQuery &&
      node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  }

  selectPrevMatch() {
    const { searchFocusIndex, searchFound } = this.state;
    const searchFoundCount = searchFound.length;
    this.setState({
      searchFocusIndex:
        searchFocusIndex !== null
          ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
          : searchFoundCount - 1
    });
  }

  selectNextMatch() {
    const { searchFocusIndex, searchFound } = this.state;
    const searchFoundCount = searchFound.length;
    this.setState({
      searchFocusIndex:
        searchFocusIndex !== null
          ? (searchFocusIndex + 1) % searchFoundCount
          : 0
    });
  }

  setSearchString = (searchString) => this.setState({ searchString });

  onDataSourceLabelChange(event) {
    const { name, value } = event.currentTarget;
    const { dataSourceLabels } = this.state;
    dataSourceLabels[name] = value;
    this.setState({ dataSourceLabels }, () => this.props.onChange(this.state));
  }

  openConfigurationModal(record) {
    const { dataSourceIndexes } = this.state;
    if (dataSourceIndexes[record.id]) {
      this.setState({ modalOpen: record.id });
    } else {
      dataSourceIndexes[record.id] = Array.from(
        Array(record.tmDataType.arraySize).keys()
      );
      this.setState({ modalOpen: record.id, dataSourceIndexes });
    }
  }

  selectAllIndexes(record) {
    const { dataSourceIndexes } = this.state;
    dataSourceIndexes[record.id] = Array.from(
      Array(record.tmDataType.arraySize).keys()
    );
    this.setState({ dataSourceIndexes }, () => this.props.onChange(this.state));
  }

  deselectAllIndexes(record) {
    const { dataSourceIndexes } = this.state;
    dataSourceIndexes[record.id] = [];
    this.setState({ dataSourceIndexes }, () => this.props.onChange(this.state));
  }

  toggleIndex(record, index) {
    const { dataSourceIndexes } = this.state;
    if (dataSourceIndexes[record.id].includes(index)) {
      dataSourceIndexes[record.id] = dataSourceIndexes[record.id].filter(
        function(item) {
          return item !== index;
        }
      );
    } else {
      dataSourceIndexes[record.id].push(index);
    }
    this.setState({ dataSourceIndexes }, () => this.props.onChange(this.state));
  }

  reorderIndexes(datasourceId, array, oldIndex, newIndex) {
    if (newIndex > array.length - 1) {
      return false;
    }
    const { dataSourceIndexes } = this.state;
    array = arrayMove(array, oldIndex, newIndex);
    dataSourceIndexes[datasourceId] = array;
    this.setState({ dataSourceIndexes });
  }

  addAll() {
    const { searchFound, dataSources } = this.state;
    const ids = searchFound.map((item) => item.node && item.node.id);
    this.setState({ dataSources: [...dataSources, ...ids] }, () =>
      this.props.onChange(this.state)
    );

    const nodes = searchFound.map((item) => item.node);
    this.props.addDataSources(nodes);
  }

  render() {
    const {
      dataSources,
      systems,
      searchString,
      searchFocusIndex,
      searchFound,
      dataSourceLabels,
      modalOpen,
      dataSourceIndexes
    } = this.state;
    const { showConfig, isVirtualized } = this.props;
    const searchFoundCount = searchFound.length;

    return (
      <>
        <Flex my={2} ml="24px" overflow="visible">
          <Button
            size="small"
            variant="outline"
            onClick={() => this.toggleExpandAll(true)}
            mr={1}
          >
            Expand All
          </Button>
          <Button
            size="small"
            variant="outline"
            onClick={() => this.toggleExpandAll(false)}
          >
            Collapse All
          </Button>
          <Flex px={2} alignItems="center">
            <Box overflow="visible">
              <InputSimple
                data-testid="data-sources-input"
                width={195}
                p="6px 0 7px"
                placeholder="Search"
                onChange={(event) => this.setSearchString(event.target.value)}
              />
              {searchString && (
                <Text
                  color="#fff"
                  ml={-50}
                  cursor="pointer"
                  onClick={() => this.addAll()}
                >
                  Add all
                </Text>
              )}
            </Box>
            <Button
              type="button"
              disabled={!searchFoundCount}
              onClick={() => this.selectPrevMatch()}
              mx={1}
            >
              <Icon name="ArrowLeft" size={14} />
            </Button>

            <Button
              type="submit"
              disabled={!searchFoundCount}
              onClick={() => this.selectNextMatch()}
            >
              <Icon name="ArrowRight" size={14} />
            </Button>

            {searchString && (
              <Text color="text.white" mx={2}>
                {searchFoundCount > 0 ? searchFocusIndex + 1 : 0}
                &nbsp;/&nbsp;
                {searchFoundCount || 0}
              </Text>
            )}
          </Flex>
        </Flex>

        <SortableTree
          style={{ padding: "4px 24px 24px" }}
          treeData={this.state.treeData}
          onChange={(treeData) => this.onChange(treeData)}
          isVirtualized={isVirtualized ? isVirtualized : true}
          nodeContentRenderer={NodeRendererDefault}
          searchMethod={this.customSearchMethod}
          searchQuery={searchString}
          searchFocusOffset={searchFocusIndex}
          onlyExpandSearchedNodes={true}
          searchFinishCallback={(matches) =>
            this.setState({
              searchFound: matches,
              searchFocusIndex:
                matches.length > 0 ? searchFocusIndex % matches.length : 0
            })
          }
          generateNodeProps={({ node, ...params }) => {
            const renderCheckbox = () => {
              if (node.record) {
                if (node.record.type === DATASOURCE_NODE) {
                  return (
                    <>
                      <Switch
                        checked={dataSources.includes(node.record.id)}
                        onChange={(event) =>
                          this.onSelectDataSource(
                            node,
                            event.currentTarget.checked
                          )
                        }
                      />
                    </>
                  );
                }

                if (node.record.type === SYSTEM_NODE) {
                  return (
                    <Switch
                      checked={systems.includes(node.id)}
                      onChange={(event) =>
                        this.onSelectSystem(
                          node,
                          event.currentTarget.checked,
                          true
                        )
                      }
                    />
                  );
                }
              }

              return null;
            };

            const DragHandle = sortableHandle(() => (
              <Box mt={1}>
                <Text color="text.default">::</Text>
              </Box>
            ));

            const SortableItem = sortableElement(({ value, draggable }) => (
              <Flex>
                {draggable ? <DragHandle /> : null}
                {value}
              </Flex>
            ));

            const SortableContainer = sortableContainer(({ children }) => {
              return <Flex>{children}</Flex>;
            });
            const renderConfig = () => {
              if (node.record) {
                if (node.record.type === DATASOURCE_NODE) {
                  //Merge selected indexes with non selected indexes
                  const allIndexes = Array.from(
                    Array(node.record.tmDataType.arraySize).keys()
                  );
                  const indexesOrderedList =
                    dataSourceIndexes[node.record.id] && allIndexes
                      ? dataSourceIndexes[node.record.id].concat(
                          allIndexes.filter(
                            (item) =>
                              dataSourceIndexes[node.record.id].indexOf(item) <
                              0
                          )
                        )
                      : [];
                  //
                  return (
                    <>
                      {showConfig && dataSources.includes(node.record.id) ? (
                        <Flex ml={4} alignItems="center">
                          <Label width="70px">Label</Label>
                          <InputField
                            name={node.record.id}
                            width="150px"
                            required={false}
                            value={dataSourceLabels[node.record.id]}
                            onChange={(e) => this.onDataSourceLabelChange(e)}
                          />
                          {node.record.tmDataType.arraySize &&
                          node.record.tmDataType.arraySize > 0 ? (
                            <ClickableFlex
                              mr={3}
                              overflow="visible"
                              cursor="pointer"
                              onClick={() =>
                                this.openConfigurationModal(node.record)
                              }
                            >
                              <Icon
                                name="Settings"
                                size={20}
                                color={"text.white"}
                              />
                            </ClickableFlex>
                          ) : null}
                          <Dialog
                            open={node.record.id === modalOpen}
                            maxWidth="sm"
                          >
                            <DialogTitle>
                              {dataSourceLabels[node.record.id]
                                ? dataSourceLabels[node.record.id]
                                : node.record.name}{" "}
                              configuration
                            </DialogTitle>

                            <DialogContent>
                              {node.record.tmDataType.arraySize &&
                              node.record.tmDataType.arraySize > 0 ? (
                                <Flex flexDirection="column">
                                  <Label>Indexes</Label>
                                  <Flex flexWrap="wrap">
                                    <SortableContainer
                                      axis="x"
                                      pressDelay={300}
                                      onSortEnd={({ oldIndex, newIndex }) =>
                                        this.reorderIndexes(
                                          node.record.id,
                                          dataSourceIndexes[node.record.id],
                                          oldIndex,
                                          newIndex
                                        )
                                      }
                                    >
                                      {indexesOrderedList.map(
                                        (index, indexN) => {
                                          const isIndexSelected =
                                            dataSourceIndexes[node.record.id] &&
                                            dataSourceIndexes[
                                              node.record.id
                                            ].includes(index);
                                          const value = (
                                            <CheckBox
                                              key={index}
                                              checked={isIndexSelected}
                                              label={
                                                (index || index === 0) &&
                                                !isNaN(index)
                                                  ? index
                                                  : ""
                                              }
                                              onChange={(event) =>
                                                this.toggleIndex(
                                                  node.record,
                                                  index
                                                )
                                              }
                                              labelWidth={"18px"}
                                            />
                                          );
                                          return (
                                            <SortableItem
                                              key={`item-${indexN}`}
                                              index={indexN}
                                              value={value}
                                              disabled={!isIndexSelected}
                                              draggable={isIndexSelected}
                                            />
                                          );
                                        }
                                      )}
                                    </SortableContainer>
                                  </Flex>
                                  <Flex>
                                    <Link
                                      onClick={() =>
                                        this.selectAllIndexes(node.record)
                                      }
                                    >
                                      Select All
                                    </Link>
                                    <Link
                                      onClick={() =>
                                        this.deselectAllIndexes(node.record)
                                      }
                                    >
                                      Deselect All
                                    </Link>
                                  </Flex>
                                </Flex>
                              ) : null}
                            </DialogContent>
                            <DialogActions>
                              <Flex
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                              >
                                <SaveButton
                                  variant="default"
                                  mr={2}
                                  onClick={() =>
                                    this.setState({ modalOpen: null })
                                  }
                                >
                                  Save
                                </SaveButton>
                              </Flex>
                            </DialogActions>
                          </Dialog>
                        </Flex>
                      ) : null}
                    </>
                  );
                }
              }

              return null;
            };

            const hasSelectedChildrenOrIsSelected = this.hasSelectedChildrenOrIsSelected(
              node
            );
            return {
              body: (
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  height="100%"
                  overflow="visible"
                  p={2}
                  border={params.isSearchFocus && `2px solid #F8F8F8;`}
                  bg={hasSelectedChildrenOrIsSelected ? "fill.6" : "fill.2"}
                >
                  <Heading display={3}>{node.title}</Heading>
                  {renderConfig()}
                  {renderCheckbox()}
                </Flex>
              )
            };
          }}
        />
      </>
    );
  }
}
