import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  getActiveSatellite,
  getPaddingForCustomAspectRatio
} from "app/shared/utils";
import { fetchGroundStations, GroundStation } from "app/groundStation";
import { Passage } from "app/visibilityWindow/models";
import { DashboardComponents } from "app/dashboard/models";
import { Flex, Text, Icon, Box, Absolute } from "primitives";
import { SatelliteInstance } from "app/satellite/models";
import { SatellitePassages } from "app/visibilityWindow/reducers";
import { Header } from "app/dashboard/components/Header";
import { FitText } from "app/visibilityWindow/components/FitText";
import {
  updatePassages as refreshPassages,
  RETRY_ON_FAIL
} from "app/visibilityWindow/helpers";
import { isEqual } from "lodash";

interface PassageCountdownBaseProps {
  options: any;
  type: string;
  id: string;
  satellitePassages: SatellitePassages;
  satelliteInstances: SatelliteInstance[];
  selectedSatellite: SatelliteInstance;
  updateConfigOptions: (configOptions: any) => void;
  showModal: (componentType: String, id: String) => void;
  serverTime: Date;
}

interface PassageCountdownBaseState {
  shouldRender: boolean;
  isHovering: boolean;
  groundStations: GroundStation[];
  passage: Passage | undefined;
}

/**
 * Primary component to display the visibility windows.
 */
class PassageCountdownBase extends Component<
  PassageCountdownBaseProps,
  PassageCountdownBaseState
> {
  static defaultProps: any;
  private containerRef: any;
  private padding: number;
  private satIds: number[];
  private updating: boolean;
  private failedNextPassage: boolean;

  constructor(props: PassageCountdownBaseProps) {
    super(props);

    this.state = {
      shouldRender: false,
      isHovering: false,
      groundStations: [],
      passage: undefined
    };
    this.containerRef = React.createRef();
    this.padding = 0;
    this.satIds = [];
    this.updating = false;
    this.failedNextPassage = false;
  }

  async componentDidMount() {
    // 1.Decide which satellite[s] passage show in the widget
    this.setActiveSatellitesOrDefault();
    // 2.create the item in the store at "satellitePassages" for this satellite
    await this.fetchAllPassages();
    // 3.read "satellitePassages" in the store and set the current|next in the component state
    const passage = await this.getPassage();

    const groundStations = await fetchGroundStations();
    this.setState({
      groundStations,
      shouldRender: true,
      passage
    });
  }

  setActiveSatellitesOrDefault = () => {
    this.satIds =
      this.props.options.satellites && this.props.options.satellites.length > 0
        ? this.props.options.satellites
        : [this.props.selectedSatellite.id];
  };

  async componentDidUpdate(
    prevProps: Readonly<PassageCountdownBaseProps>,
    prevState: Readonly<PassageCountdownBaseState>,
    snapshot?: any
  ) {
    const isPassageExipred = moment(moment(this.props.serverTime)).isAfter(
      this.state.passage && this.state.passage.los
    );

    const needPassages =
      isPassageExipred && !this.updating && !this.failedNextPassage;
    const settingsChanged = !isEqual(prevProps.options, this.props.options);

    if (settingsChanged) {
      this.setActiveSatellitesOrDefault();
    }

    if (needPassages || settingsChanged) {
      this.updating = true;
      await this.updatePassage();
      this.updating = false;
    }
  }

  async updatePassage() {
    let passage: Passage;
    passage = await this.getPassage();
    if (passage) {
      // Get passage from the store
      return this.setState({ passage });
    }
    // Fetch new passage from the server
    const passages = await this.fetchAllPassages();
    passage = await this.getPassage();
    this.setState({ passage });

    // Server returned empty result?
    this.failedNextPassage = passages.flat(2).length === 0;
    return setTimeout(() => {
      this.failedNextPassage = false;
    }, RETRY_ON_FAIL);
  }

  /**
   * The widget can be configured with multiple satellites.
   * Here make a request for each satellite.
   */
  fetchAllPassages = () => {
    const promises: Array<Promise<Passage[][]>> = [];

    this.satIds.forEach((id) => {
      promises.push(refreshPassages({ satelliteId: id }));
    });
    return Promise.all(promises);
  };

  async getPassage() {
    const { options, satellitePassages, serverTime } = this.props;
    let allPassages: Passage[] = [];
    const { groundstations } = options;

    this.satIds.forEach((satId: number) => {
      if (satellitePassages[satId]) {
        const { passages, nextPassages } = satellitePassages[satId];
        allPassages = allPassages.concat([...passages, ...nextPassages]);
        allPassages = allPassages.sort(
          (passage1: Passage, passage2: Passage) =>
            new Date(passage1.aos).getTime() - new Date(passage2.aos).getTime()
        );
      }
    });

    if (!groundstations || groundstations.length === 0) {
      allPassages = allPassages.filter((p: Passage) =>
        moment(moment(serverTime)).isBefore(p.los)
      );

      return allPassages[0];
    }

    return allPassages.filter(
      (passage: Passage) =>
        groundstations.indexOf(passage.groundStationID) > -1 &&
        moment(moment(serverTime)).isBefore(passage.los)
    )[0];
  }

  renderPassageCountdown() {
    const { options, satelliteInstances, showModal, type, serverTime, id } =
      this.props;
    const { passage } = this.state;
    // 4.avoid negative countDown
    const isPassageExipred = moment(moment(serverTime)).isAfter(
      passage && passage.los
    );
    const isCurrentPassage =
      passage &&
      moment(moment(serverTime)).isAfter(passage.aos) &&
      moment(moment(serverTime)).isBefore(passage.los);
    const { isHovering } = this.state;
    const { gsVisibilityMode, closeApproachTime } = options;
    const satellite: SatelliteInstance | undefined =
      passage &&
      satelliteInstances.find((sat) => sat.id === passage.satelliteID);

    const showGroundStation =
      gsVisibilityMode === "alwaysVisible" ||
      (gsVisibilityMode === "onHoverVisible" && isHovering);
    this.padding = getPaddingForCustomAspectRatio(
      this.containerRef,
      showGroundStation ? 5 : 3
    );
    return (
      <Flex
        width="100%"
        onMouseEnter={() => !isHovering && this.setState({ isHovering: true })}
        onMouseLeave={() => isHovering && this.setState({ isHovering: false })}
        flexDirection="column"
        overflow="visible"
        position="relative"
      >
        <Header my={12} ml={0} center={true}>
          <Absolute right={0}>
            <Box
              cursor="pointer"
              padding={2}
              onClick={() => showModal(type, id)}
            >
              <Icon name={"SettingsMenu"} size={20} id="settings-gear" />
            </Box>
          </Absolute>
        </Header>
        <Flex
          pl={this.padding ? `${this.padding / 2}%` : 0}
          pr={this.padding ? `${this.padding / 2}%` : 0}
          flexDirection="row"
          flex={1}
          borderBottom="1"
          overflow="visible"
        >
          <Flex
            flexDirection="column"
            flex={2}
            alignItems="center"
            justifyContent="center"
            overflow="visible"
            mr={3}
          >
            <FitText
              minFontSize={20}
              compressor={6}
              textAlign="center"
              width="100%"
              color="palette.white.0"
            >
              {isCurrentPassage ? `Passage ends in` : `Next passage in`}
            </FitText>
            {passage && !isPassageExipred ? (
              <FitText
                minFontSize={25}
                compressor={2.5}
                color={
                  isCurrentPassage
                    ? "palette.green.0"
                    : Math.abs(
                        moment(serverTime).diff(passage.aos, "seconds")
                      ) <=
                      closeApproachTime * 60
                    ? "palette.orange.0"
                    : "palette.red.1"
                }
                textAlign="center"
                width="100%"
              >
                {isCurrentPassage
                  ? moment
                      .duration(moment(passage.los).diff(moment(serverTime)))
                      .format("HH:mm:ss")
                  : moment
                      .duration(moment(passage.aos).diff(moment(serverTime)))
                      .format("HH:mm:ss")}
              </FitText>
            ) : (
              <Text width="100%" textAlign="center" color="palette.grey.2">
                - - - - - - -
              </Text>
            )}
          </Flex>
          {showGroundStation ? (
            <Flex
              justifyContent="center"
              flexDirection="column"
              flex={1}
              alignItems="center"
              mb={4}
              overflow="visible"
            >
              <Flex
                justifyContent="flex-end"
                flexDirection="column"
                flex={1}
                alignItems="center"
                overflow="visible"
              >
                <FitText
                  minFontSize={20}
                  textAlign="center"
                  width="100%"
                  color="palette.white.0"
                >{`Ground Station`}</FitText>

                <Flex>
                  <FitText
                    minFontSize={25}
                    textAlign="center"
                    width="100%"
                    color={!passage ? "palette.grey.2" : ""}
                  >
                    {passage ? passage.groundStationName : "- - - - - - -"}
                  </FitText>
                </Flex>
              </Flex>
              <Flex
                justifyContent="flex-start"
                flexDirection="column"
                flex={1}
                alignItems="center"
                mt={4}
                overflow="visible"
              >
                <FitText
                  minFontSize={20}
                  textAlign="center"
                  width="100%"
                  color="palette.white.0"
                >{`Satellite`}</FitText>

                <Flex>
                  <FitText
                    minFontSize={25}
                    textAlign="center"
                    width="100%"
                    color={!passage ? "palette.grey.2" : ""}
                  >
                    {satellite ? satellite.label : "- - - - - - -"}
                  </FitText>
                </Flex>
              </Flex>
            </Flex>
          ) : null}
        </Flex>
      </Flex>
    );
  }

  render() {
    const { options } = this.props;

    return (
      <Flex
        color="text.default"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        overflow="visible"
        flex={1}
        ref={this.containerRef}
      >
        {options.label && (
          <Text fontSize={18} bold>
            {options.label}
          </Text>
        )}
        <Flex
          data-testid="passage-countdown"
          flex={1}
          mt={1}
          p={2}
          width="100%"
          bg="fill.1"
        >
          {this.state.shouldRender && this.renderPassageCountdown()}
        </Flex>
      </Flex>
    );
  }
}

PassageCountdownBase.defaultProps = {
  ...DashboardComponents.VisibilityWindows
};

const mapStateToProps = (state: any) => {
  return {
    serverTime: state.visibilityWindow.serverTime,
    satellitePassages: state.visibilityWindow.satellites,
    satelliteInstances: state.constellations.selected.satelliteInstances,
    selectedSatellite: getActiveSatellite(state.constellations.dashboard)
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const PassageCountdown = connect(
  mapStateToProps,
  mapDispatchToProps
)(PassageCountdownBase);
