export const filterList = (list, page, pageSize, searchText) => {
  const minIndex = page * pageSize;
  const maxIndex = minIndex + pageSize;
  const result = list
    .filter((item) =>
      item.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
    )
    .slice(minIndex, maxIndex);
  return result;
};

export const userList = [
  {
    id: 1,
    name: "operator+mk2@aurora",
    role: "admin",
    satellites: [
      { name: "ION-SCV2", id: "d5edab51-6280-40e1-a738-9392c78d4e72" },
      { name: "ION-SCV3", id: "4dfaf2d2-dd48-4245-81fe-3a9230526380" }
    ],
    password: "1"
  },
  {
    id: 2,
    name: "operator@aurora",
    role: "admin",
    satellites: [
      { name: "ION-SCV2", id: "7461ffbf-9347-46fc-b68d-05102c7dbbcc" },
      { name: "ION-SCV3", id: "4dfaf2d2-dd48-4245-81fe-3a9230526380" }
    ],
    password: "1"
  },
  {
    id: 3,
    name: "operator+mk3@aurora",
    role: "admin",
    satellites: [
      { name: "ION-SCV2", id: "d5edab51-6280-40e1-a738-9392c78d4e72" },
      { name: "ION-SCV3", id: "4dfaf2d2-dd48-4245-81fe-3a9230526380" }
    ],
    password: "1"
  },
  {
    id: 4,
    name: "operator+mk4@aurora",
    role: "admin",
    satellites: [
      { name: "ION-SCV2", id: "d5edab51-6280-40e1-a738-9392c78d4e72" },
      { name: "ION-SCV3", id: "4dfaf2d2-dd48-4245-81fe-3a9230526380" }
    ],
    password: "1"
  },
  {
    id: 5,
    name: "operator+mk5@aurora",
    role: "admin",
    satellites: [
      { name: "ION-SCV2", id: "d5edab51-6280-40e1-a738-9392c78d4e72" },
      { name: "ION-SCV3", id: "4dfaf2d2-dd48-4245-81fe-3a9230526380" }
    ],
    password: "1"
  },
  {
    id: 6,
    name: "operator+mk6@aurora",
    role: "admin",
    satellites: [
      { name: "ION-SCV2", id: "d5edab51-6280-40e1-a738-9392c78d4e72" },
      { name: "ION-SCV3", id: "4dfaf2d2-dd48-4245-81fe-3a9230526380" }
    ],
    password: "1"
  }
];
