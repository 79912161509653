import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  InputField,
  AddButton,
  EditButton
} from "components";
import { Box, Heading, Flex } from "primitives";
import { PaginationSimple } from "app/shared";
import { Link } from "react-router-dom";
import { filterList, userList } from "./utils";
import { User } from "../models";
import { UserDelete } from "./UserDelete";

export const UsersList = () => {
  const [users, setUsers] = useState<Array<User>>([]);

  useEffect(() => {
    setUsers(userList);
  }, []);

  return (
    <Flex flexDirection="column">
      <RenderUsers users={users} />
    </Flex>
  );
};

interface RenderUsersProps {
  users: User[];
}

const RenderUsers = ({ users }: RenderUsersProps) => {
  const [pageSize, setPageSize] = useState<number>(25);
  const [page, setPage] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>("");

  const onChangeSearch = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;
    setSearchText(value);
    setPage(0);
  };

  const filteredUsers = users
    ? filterList(users, page, pageSize, searchText)
    : [];

  const displaySatellites = (user: User) => {
    let finalGroups = user.satellites[0].name;
    user.satellites
      .map((s: any) => s.name)
      .slice(1)
      .forEach((sat: string) => (finalGroups += `, ${sat}`));
    return finalGroups;
  };

  return (
    <Box data-testid="UsersList" mx={3}>
      <Flex alignItems="center" justifyContent="space-between" mb={2}>
        <Heading display={1}></Heading>
        <Flex alignItems="flex-end">
          <Box mr={4}>
            <InputField
              id="search-input"
              label="search"
              autoFocus={true}
              value={searchText}
              onChange={(
                e:
                  | ChangeEvent<HTMLInputElement>
                  | ChangeEvent<HTMLTextAreaElement>
              ) => onChangeSearch(e)}
            />
          </Box>
          <Link to="/usermanagement/createuser">
            <AddButton>Create</AddButton>
          </Link>
        </Flex>
      </Flex>
      <Table>
        <TableHead>
          <TableRow bg="fill.0">
            <TableCell width="200">USER NAME</TableCell>
            <TableCell width="200">ROLE</TableCell>
            <TableCell width="800">SATELLITES</TableCell>
            <TableCell width="50" />
            <TableCell width="50" />
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredUsers.map((user: User) => {
            return (
              <TableRow key={user.id}>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>{displaySatellites(user)}</TableCell>
                <TableCell>
                  <Link to={`/usermanagement/user/${user.id}`}>
                    <EditButton>Edit</EditButton>
                  </Link>
                </TableCell>
                <TableCell>
                  <UserDelete
                    user={user}
                    //onChange={}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <PaginationSimple
        pageSize={pageSize}
        nextPageUrl={filteredUsers.length >= pageSize ? page + 1 : 0}
        previousPageUrl={page - 1}
        onChange={(newPage: string | number) => setPage(Number(newPage))}
        onPageSizeChange={(newPageSize: number) => {
          setPage(0);
          setPageSize(Number(newPageSize));
        }}
      />
    </Box>
  );
};
