import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgAurora = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="172.061"
    height="82"
    fill="white"
    viewBox="0 0 72.061 18"
    color="palette.white.0"
  >
    <path d="M29.349 16.51h-2.365l-.405-2.706h-2.876l-.4 2.706h-2.156l2.386-14.916h3.43zm-5.347-4.731h2.258l-1.129-7.543z"></path>
    <path d="M32.672 1.594V13.1c0 1.066.469 1.449 1.214 1.449s1.215-.383 1.215-1.449V1.594h2.215v11.352c0 2.387-1.193 3.75-3.494 3.75s-3.493-1.363-3.493-3.75V1.594z"></path>
    <path d="M43.814 16.51a4.588 4.588 0 0 1-.213-1.833v-2.344c0-1.385-.469-1.9-1.534-1.9h-.81v6.073h-2.343V1.594h3.536c2.429 0 3.473 1.13 3.473 3.431v1.172c0 1.534-.49 2.535-1.534 3.025 1.171.49 1.555 1.62 1.555 3.175v2.3a4.383 4.383 0 0 0 .256 1.811zM41.257 3.725v4.581h.916c.874 0 1.406-.383 1.406-1.577v-1.47c0-1.065-.362-1.534-1.193-1.534z"></path>
    <path d="M47.284 5.174c0-2.387 1.257-3.75 3.557-3.75s3.558 1.363 3.558 3.75v7.756c0 2.386-1.257 3.75-3.558 3.75s-3.557-1.364-3.557-3.75zm2.343 7.9c0 1.065.469 1.47 1.214 1.47s1.215-.4 1.215-1.47V5.025c0-1.066-.469-1.471-1.215-1.471s-1.214.4-1.214 1.471z"></path>
    <path d="M60.813 16.51a4.608 4.608 0 0 1-.213-1.833v-2.344c0-1.385-.468-1.9-1.534-1.9h-.809v6.073h-2.344V1.594h3.537c2.429 0 3.472 1.13 3.472 3.431v1.172c0 1.534-.49 2.535-1.533 3.025 1.171.49 1.555 1.62 1.555 3.175v2.3a4.4 4.4 0 0 0 .255 1.811zM58.257 3.725v4.581h.916c.873 0 1.406-.383 1.406-1.577v-1.47c0-1.065-.362-1.534-1.193-1.534z"></path>
    <path d="M72.061 16.51h-2.364l-.4-2.706h-2.881l-.405 2.706h-2.152l2.386-14.916h3.43zm-5.347-4.731h2.258l-1.129-7.543z"></path>
    <path d="M15.466 17.998l-.123-.009-.1-.014a2.156 2.156 0 0 1-.247-.058 2.19 2.19 0 0 1-.258-.097 2.533 2.533 0 0 1-.162-.078 4.94 4.94 0 0 1-.433-.257 12.319 12.319 0 0 1-.658-.472 28.625 28.625 0 0 1-1.17-.944c-.753-.637-1.477-1.292-2.182-1.968s-1.394-1.371-2.065-2.084c-.167-.179-.334-.357-.5-.54s-.329-.365-.49-.551a25.002 25.002 0 0 1-.948-1.14.179.179 0 0 1 .213-.274c.455.2.9.413 1.338.63q.329.162.656.331c.218.11.434.223.649.337q1.292.683 2.551 1.4c.263.149.525.3.786.45a78.657 78.657 0 0 1-.426-1.1c-.674-1.787-1.322-3.6-2.077-5.352a83.599 83.599 0 0 0-1.158-2.6l-.118-.262-.112.176c-.253.4-.5.807-.752 1.214Q6.929 5.96 6.195 7.204c-.99 1.651-1.959 3.317-2.937 4.981l-1.463 2.5-1.471 2.493a.174.174 0 0 1-.311-.155c1.479-3.572 3.036-7.1 4.642-10.627.406-.878.814-1.756 1.236-2.633.211-.438.427-.875.649-1.313s.447-.875.711-1.331c.068-.115.138-.231.225-.361.045-.065.091-.133.161-.219a1.579 1.579 0 0 1 .146-.162 1.615 1.615 0 0 1 .161-.138L8.019.19c.031-.019.071-.042.108-.06A1.231 1.231 0 0 1 9.16.099c.065.028.091.043.134.066s.06.036.09.056a1.585 1.585 0 0 1 .188.15 2.218 2.218 0 0 1 .163.171c.075.09.125.157.174.223.1.129.173.244.247.356a29.126 29.126 0 0 1 .788 1.304c.49.859.95 1.716 1.408 2.577l.688 1.288.694 1.283c.464.856.924 1.715 1.364 2.586s.846 1.758 1.211 2.673c.09.229.183.459.267.693s.171.47.251.712.157.488.235.752a9.954 9.954 0 0 1 .172.704c.01.056.021.115.031.2.005.042.011.086.015.145a2.136 2.136 0 0 1 .006.237v.092c0 .041-.005.064-.013.119s-.013.087-.03.162a1.694 1.694 0 0 1-.069.232 1.777 1.777 0 0 1-.171.339 1.747 1.747 0 0 1-.428.453 1.722 1.722 0 0 1-.411.221 1.637 1.637 0 0 1-.311.086 1.992 1.992 0 0 1-.219.024h-.081zM1.138 6.82a.871.871 0 1 1 .87.871.87.87 0 0 1-.87-.871z"></path>
  </Svg>
);

export default SvgAurora;
