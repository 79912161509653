import {
  FetchAllMasterKeyAction,
  FetchAllSessionKeyAction,
  FetchSessionKeyAction,
  SetSessionKeyStateAction,
  SetMasterKeyStateAction,
  DeriveSessionKeyAction,
  FetchAllMasterKeyActionResult,
  FetchAllSessionKeyActionResult,
  FetchSessionKeyActionResult,
  DeriveSessionKeyResult,
  KeyManagerActionType,
  SessionKeysFromMaster,
  SetSessionKeyStateResult,
  SetMasterKeyStateResult,
  SessionKeyRequestParams,
  DeriveSessionKeysParams,
  MasterKeyRequestParams,
  SuccessResponse,
  SessionKeyDerivationResponse,
  FetchMasterKeyActionResult,
  FetchMasterKeyAction,
  MasterKey
} from "../models";
import { Dispatch } from "react";
import { fetchAllMasterKey, fetchMasterKey } from "../services/masterKey";
import {
  fetchAllSessionKey,
  fetchSessionKey,
  setSessionKeyState,
  deriveSessionKey
} from "../services/sessionKey";
import { setMasterKeyState } from "../services/masterKey";
import { setFeedback } from "app/feedback/actions";
import { FeedbackStatus } from "app/feedback/models";
import { store } from "app/store";

export const fetchMasterKeyAction = (
  satelliteID: number,
  params: { master_key_id: string }
): FetchMasterKeyActionResult => {
  return async (dispatch: Dispatch<FetchMasterKeyAction>) => {
    try {
      const payload = await fetchMasterKey(satelliteID, {
        master_key_id: params.master_key_id
      });
      dispatch({
        type: KeyManagerActionType.FetchMasterKey,
        payload: payload as MasterKey
      });
      return Promise.resolve(payload);
    } catch (e) {
      return Promise.resolve(null);
    }
  };
};

export const fetchAllMasterKeyAction = (
  satelliteID: number
): FetchAllMasterKeyActionResult => {
  return async (dispatch: Dispatch<FetchAllMasterKeyAction>) => {
    try {
      const payload = await fetchAllMasterKey(satelliteID);
      dispatch({
        type: KeyManagerActionType.FetchAllMasterKey,
        payload
      });
      return Promise.resolve(payload);
    } catch (e) {
      return Promise.resolve(null);
    }
  };
};

export const fetchAllSessionKeyAction = (
  satelliteID: number,
  params: SessionKeysFromMaster
): FetchAllSessionKeyActionResult => {
  return async (dispatch: Dispatch<FetchAllSessionKeyAction>) => {
    try {
      const payload = await fetchAllSessionKey(satelliteID, params);
      dispatch({
        type: KeyManagerActionType.FetchAllSessionKey,
        payload: { [params.master_key_id]: payload }
      });
      return Promise.resolve(payload);
    } catch (error) {
      return Promise.resolve(null);
    }
  };
};

export const fetchSessionKeyAction = (
  satelliteID: number,
  masterKeyId: string,
  sessionKeyID: string
): FetchSessionKeyActionResult => {
  return async (dispatch: Dispatch<FetchSessionKeyAction>) => {
    try {
      const sessionKey = await fetchSessionKey(satelliteID, sessionKeyID);

      if (sessionKey) {
        dispatch({
          type: KeyManagerActionType.FetchSessionKey,
          payload: { sessionKey, masterKeyId }
        });
      }

      return Promise.resolve(sessionKey);
    } catch (error) {
      return Promise.resolve(null);
    }
  };
};

export const setSessionKeyStateAction = (
  satelliteID: number,
  params: SessionKeyRequestParams
): SetSessionKeyStateResult => {
  return async (dispatch: Dispatch<SetSessionKeyStateAction>) => {
    try {
      const response = await setSessionKeyState(satelliteID, params);

      if ((response as SuccessResponse).status !== 204) {
        store.dispatch(
          setFeedback(
            (response as SuccessResponse)?.data.type || "Error",
            FeedbackStatus.ERROR,
            (response as SuccessResponse)?.data.description ||
              (response as SuccessResponse)?.data.detail
          )
        );
        return Promise.resolve(null);
      }
      await store.dispatch(
        fetchSessionKeyAction(
          satelliteID,
          params.master_key_id,
          params.session_key_id
        )
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.resolve(null);
    }
  };
};

export const deriveSessionKeyAction = (
  satelliteID: number,
  params: DeriveSessionKeysParams
): DeriveSessionKeyResult => {
  return async (dispatch: Dispatch<DeriveSessionKeyAction>) => {
    try {
      const response = await deriveSessionKey(satelliteID, params);

      if ((response as SuccessResponse).status !== 201) {
        store.dispatch(
          setFeedback(
            (response as SuccessResponse)?.data.type || "Error",
            FeedbackStatus.ERROR,
            (response as SuccessResponse)?.data.description ||
              (response as SuccessResponse)?.data.detail
          )
        );
        return Promise.resolve(null);
      }

      // After the session key derivation the state of master key change.
      await store.dispatch(
        fetchMasterKeyAction(satelliteID, {
          master_key_id: params.master_key_id
        })
      );

      // Saving the derived session keys in the store.
      dispatch({
        type: KeyManagerActionType.DeriveSessionKey,
        payload: {
          masterKey: params.master_key_id,
          sessionKeys: (response as SessionKeyDerivationResponse)?.data
        }
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.resolve(null);
    }
  };
};

export const setMasterKeyStateAction = (
  satelliteID: number,
  params: MasterKeyRequestParams
): SetMasterKeyStateResult => {
  return async (dispatch: Dispatch<SetMasterKeyStateAction>) => {
    try {
      const response = await setMasterKeyState(satelliteID, params);

      if ((response as SuccessResponse).status !== 204) {
        store.dispatch(
          setFeedback(
            (response as SuccessResponse)?.data.type || "Error",
            FeedbackStatus.ERROR,
            (response as SuccessResponse)?.data.description ||
              (response as SuccessResponse)?.data.detail
          )
        );
        return Promise.resolve(null);
      }

      dispatch({
        type: KeyManagerActionType.SetMasterKeyState,
        payload: params
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.resolve(null);
    }
  };
};
