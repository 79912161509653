import React from "react";
import { TypographyProps, CommonProps, BorderProps } from "styles/constants";
import {
  TestableProps,
  DATA_TEST_ID_ATTR_NAME
} from "components/testable/models";
import { Flex } from "primitives";
import { ChromePicker, RGBColor } from "react-color";

interface HTMLInput extends React.InputHTMLAttributes<HTMLInputElement> {
  onChange: any;
  value?: any;
}

interface InputProps
  extends TypographyProps,
    CommonProps,
    BorderProps,
    TestableProps {}

const fromRgbaToString = (color: RGBColor) =>
  `rgba(${color.r},${color.g},${color.b},${color.a})`;

const ColorPicker = (props: HTMLInput & InputProps) => {
  const { onChange, value } = props;

  return (
    <Flex p={2}>
      <ChromePicker
        color={value}
        onChange={(color) => onChange(fromRgbaToString(color.rgb))}
      />
    </Flex>
  );
};

ColorPicker.displayName = "ColorPicker";

ColorPicker.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: ColorPicker.displayName
};

export default ColorPicker;
