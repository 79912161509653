import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgArrowForward = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    viewBox="0 0 42 42"
    width={size || width}
    height={size || height}
    color={color}
  >
    <path d="M40.824 19.173L25.3974 3.759C24.3894 2.751 22.7598 2.751 21.7518 3.759C20.7438 4.7628 20.7438 6.3966 21.7518 7.4004L32.7894 18.4296H2.9946C1.5708 18.4296 0.42 19.5804 0.42 21C0.42 22.4196 1.5708 23.5704 2.9946 23.5704H32.7936L21.756 34.5996C20.748 35.6076 20.748 37.2372 21.756 38.241C22.764 39.249 24.3936 39.249 25.4016 38.241L40.824 22.827C41.328 22.323 41.58 21.6636 41.58 21.0042C41.58 20.3406 41.328 19.677 40.824 19.173Z" />
  </Svg>
);

SvgArrowForward.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgArrowForward;
