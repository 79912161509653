import React, { useContext } from "react";
import { SessionKeyContextMenuProps, KeyStatus } from "../models";
import styled from "styled-components";
import { Icon } from "primitives";
import { KeyManagerContext } from "../context";
import { contextMenuDisabledAction } from "app/keyManager/utils/constants";
import {
  handleKeyStateDialog,
  handleInjectSessionKey
} from "app/keyManager/utils/helpers";
const Container = styled("div")`
  & ul {
    list-style-type: none;
    padding: 2px 0;
    margin: 0;
  }
  & li {
    font-size: 12px;
    padding: 8px;
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
      background-color: #3d5076;
    }
    & span {
      margin-left: 6px;
    }
  }
`;
export const SessionKeyContextMenu = ({
  onClose,
  data: sessionKey
}: SessionKeyContextMenuProps) => {
  const { dispatch } = useContext(KeyManagerContext);

  const canInject =
    sessionKey.status !== KeyStatus.ACTIVE || sessionKey.channel;

  const disableActive = sessionKey.status === KeyStatus.ACTIVE;

  return (
    <Container data-testid={"session-key-context-menu"}>
      <ul>
        <li
          style={(disableActive && contextMenuDisabledAction) || {}}
          data-testid={"ctx-activate"}
          onClick={(e) => {
            handleKeyStateDialog(e, sessionKey, KeyStatus.ACTIVE, dispatch);
            onClose();
          }}
        >
          <Icon name="Play" size={12} />
          <span>Activate</span>
        </li>
        <li
          data-testid={"ctx-suspend"}
          onClick={(e) => {
            handleKeyStateDialog(e, sessionKey, KeyStatus.SUSPENDED, dispatch);
            onClose();
          }}
        >
          <Icon name="Pause" size={12} />
          <span>Suspend</span>
        </li>
        <li
          data-testid={"ctx-deactivate"}
          onClick={(e) => {
            handleKeyStateDialog(
              e,
              sessionKey,
              KeyStatus.DEACTIVATED,
              dispatch
            );
            onClose();
          }}
        >
          <Icon name="Stop" size={12} />
          <span>Deactivate</span>
        </li>
        <li
          style={
            (sessionKey.status === KeyStatus.ACTIVE &&
              contextMenuDisabledAction) ||
            {}
          }
          data-testid={"ctx-destroy"}
          onClick={(e) => {
            handleKeyStateDialog(e, sessionKey, KeyStatus.DESTROYED, dispatch);
            onClose();
          }}
        >
          <Icon name="Delete" size={12} />
          <span>Destroy</span>
        </li>
        <li
          style={(canInject && contextMenuDisabledAction) || {}}
          data-testid={"ctx-inject"}
          onClick={(e) => {
            handleInjectSessionKey(e, sessionKey, dispatch);
            onClose();
          }}
        >
          <Icon name="Upload" size={12} />
          <span>Use for encryption</span>
        </li>
      </ul>
    </Container>
  );
};
