import React, { Component } from "react";
import { SelectDataSources } from "app/shared";
import { Telemetry } from "app/dataSource";
import { PaginationSimple, DownloadFile } from "app/shared";
import { createExportRequest, getExportRequest } from "app/satellite/services";
import { Table, TableHead, TableRow, TableCell, TableBody } from "components";
import { Box, Text, Flex, Grid } from "primitives";
import { TimeReference } from "app/telemetry/models";
import config from "config/constants";
import { Header } from "app/dashboard/components/Header";
import {
  SelectContainerSimple as SelectContainer,
  SelectLabelSimple as SelectLabel,
  SelectSimple as Select
} from "components";
import styled from "styled-components";
import { themeGet } from "styled-system";
import { OnboardTimeFilter, DateTimeFilter } from "../HistoricalDataFilters";

const Typography = styled.p`
  text-align: center;
  color: ${themeGet("colors.text.default")};
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.46429em;
  margin: 0;
  display: block;
`;

const NoData = () => <Typography>No TM data</Typography>;

const TimeForReference = ({ timeReference, timeInfo }) => {
  if (timeReference === TimeReference.OnboardTime) {
    return (
      <span>
        {timeInfo.bootCount} | {timeInfo.onboardTimeMillis}
      </span>
    );
  } else if (timeReference === TimeReference.SendUtcTime) {
    return <span>{timeInfo.sendTimestamp}</span>;
  } else {
    return <span>{timeInfo.receiveTimestamp}</span>;
  }
};

export class DatasourceHistoryTable extends Component {
  state = {
    timeReference: TimeReference.ReceiveUtcTime,
    selectedSystems: [],
    paginationUrl: null,
    pageSize: 25,
    from: null,
    to: null,
    selectedDataSources: []
  };

  componentDidMount() {
    if (!this.props.satellite) {
      const { id } = this.props.match.params;
      this.props.getSatellite(id);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.satellite && this.props.satellite) {
      const { satellite } = this.props;
      const { satelliteDefinitionSummary } = satellite;
      const { satelliteDefinitionId } = satelliteDefinitionSummary;
      this.props.getSatelliteDefinition(satelliteDefinitionId);
    }
  }

  onChangeTimeReference(e) {
    const { value } = e.target;
    this.setState({
      timeReference: value,
      from: null,
      to: null,
      paginationUrl: null
    });
  }

  onChangeTimeReferenceHandler = (e) => this.onChangeTimeReference(e);

  renderContent(tmData) {
    const { pageSize } = this.state;

    if (!tmData || (tmData && !tmData.datasourceReading)) {
      return <NoData />;
    }

    const rows = tmData.datasourceReading.data;
    const labels = Object.values(tmData.datasourceReading.dataSourceNames);
    const labelsKey = Object.keys(tmData.datasourceReading.dataSourceNames);
    const keys = Object.keys(rows);

    const { nextPageUrl, previousPageUrl } = tmData;
    return (
      <>
        <div>
          <Table>
            {keys.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell>
                    <NoData />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                <TableHead>
                  <TableRow bg="fill.0" color="text.white" py={2}>
                    <TableCell
                      position="sticky"
                      top={0}
                      bg="fill.1"
                      width="auto"
                      border={4}
                      borderColor="fill.3"
                    >
                      Time
                    </TableCell>
                    {labels.map((label, idx) => {
                      return (
                        <TableCell
                          position="sticky"
                          top={0}
                          bg="fill.1"
                          width="auto"
                          border={4}
                          borderColor="fill.3"
                          key={labelsKey[idx]}
                        >
                          {label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {keys.map((key) => (
                    <TableRow key={key}>
                      <TableCell>
                        <TimeForReference
                          timeReference={this.state.timeReference}
                          timeInfo={rows[key].timeInfo}
                        />
                      </TableCell>
                      {Object.entries(rows[key].data).map(
                        ([sourceName, source]) => {
                          const sourceValue = source || {};
                          return (
                            <TableCell key={`${key}-${sourceName}`}>
                              {sourceValue.valueHumanReadable ||
                                (Array.isArray(sourceValue.value)
                                  ? sourceValue.value
                                    .map((value) => value.value)
                                    .join(",")
                                  : sourceValue.value)}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </div>

        <PaginationSimple
          pageSize={pageSize}
          nextPageUrl={nextPageUrl}
          previousPageUrl={previousPageUrl}
          onChange={(paginationUrl) => this.setState({ paginationUrl })}
          onPageSizeChange={(size) =>
            this.setState({ pageSize: size, paginationUrl: null })
          }
          small={true}
        />
      </>
    );
  }

  simplifySatDef(satelliteDefinition) {
    const { id, name, satelliteDefinitionSummary, systems } = satelliteDefinition;
    return { id, name, satelliteDefinitionSummary, systems };
  }

  render() {
    const { satellite, options, dashboard } = this.props;
    const satelliteDefinition = this.simplifySatDef(
      dashboard.satelliteDefinition
    );

    const {
      selectedSystems,
      timeReference,
      from,
      to,
      selectedDataSources,
      ...params
    } = this.state;
    const exportFilters = {
      timeReference,
      from,
      to,
      datasources: selectedDataSources.join(",")
    };

    // TODO: Add a debounce on the query so that the request is only sent when the from and to values stabilize
    return (
      <div data-testid="DataSourceTree">
        {options.label && (
          <Text fontSize={18} m="10px 0">
            {options.label}
          </Text>
        )}
        <Box
          data-testid="historical-table"
          overflow="visible"
          bg="fill.0"
          p={2}
          minWidth={263}
        >
          <Grid item xs={12} overflow="visible">
            <Header my={12} width={18} ml={0}>
              <Flex
                justifyContent="space-around"
                alignItems="flex-end"
                width="100%"
                overflow="visible"
                flexWrap="wrap"
              >
                <SelectContainer width="160px" m="4px 8px" overflow="visible">
                  <SelectLabel>Time reference</SelectLabel>
                  <Select
                    fontSize="14px"
                    borderColor="#fff"
                    width="160px"
                    pb={0}
                    height={34}
                    iconPt="7px"
                    value={timeReference}
                    onChange={this.onChangeTimeReferenceHandler}
                    className="grafanaSelect"
                  >
                    <option value={TimeReference.ReceiveUtcTime}>
                      Ground Station UTC
                    </option>
                    <option value={TimeReference.SendUtcTime}>
                      Satellite UTC
                    </option>
                    <option value={TimeReference.OnboardTime}>
                      Satellite OBT
                    </option>
                  </Select>
                </SelectContainer>

                {timeReference === TimeReference.OnboardTime ? (
                  <OnboardTimeFilter
                    margin="4px 8px"
                    pbLabel="4px"
                    width="100px"
                    timeFilterChanged={(from, to) =>
                      this.setState({ from, to, paginationUrl: null })
                    }
                  />
                ) : (
                  <DateTimeFilter
                    margin="4px 8px"
                    pbLabel="4px"
                    timeFilterChanged={(from, to) =>
                      this.setState({ from, to, paginationUrl: null })
                    }
                  />
                )}

                <SelectDataSources
                  satelliteDefinition={satelliteDefinition}
                  selectedDataSources={selectedDataSources}
                  selectedSystems={selectedSystems}
                  onChange={({ dataSources, systems }) => {
                    this.setState({
                      selectedSystems: systems,
                      selectedDataSources: dataSources,
                      paginationUrl: null
                    });
                  }}
                  buttonSize="smaller"
                  m="4px 8px"
                />

                {selectedDataSources.length > 0 && from && to && (
                  <DownloadFile
                    title="Export as CSV"
                    createExportRequest={createExportRequest}
                    getExportRequest={getExportRequest}
                    params={{ satellite, filter: exportFilters }}
                    size="smaller"
                    m="4px 8px"
                  />
                )}
              </Flex>
              <Box width={32}></Box>
            </Header>
            {satellite && (
              <Box bg="fill.3" overflow="auto" style={{ marginTop: "8px" }}>
                <Telemetry
                  satellite={satellite}
                  ids={selectedDataSources}
                  multiple={true}
                  params={{ from, to, timeReference, ...params }}
                  autoUpdates={true}
                  interval={config.timer.table}
                >
                  {(tmData) => this.renderContent(tmData)}
                </Telemetry>
              </Box>
            )}
          </Grid>
        </Box>
      </div>
    );
  }
}
