import React, { useRef } from "react";
import { Text } from "primitives";
import { TextAlignProperty } from "csstype";

interface FitTextProps {
  children: string;
  minFontSize?: number;
  compressor?: number;
  color?: string;
  textAlign?: TextAlignProperty;
  width?: string;
}

export const FitText = ({
  minFontSize = 0,
  compressor = 10,
  children,
  ...props
}: FitTextProps) => {
  const currentElement = useRef(null);

  const parentElement =
    currentElement.current &&
    (currentElement.current as HTMLElement).parentElement;

  const parentWidth =
    parentElement && (parentElement as HTMLElement).offsetWidth;

  const fontSize = parentWidth
    ? (minFontSize * parentWidth) / (compressor * 100)
    : minFontSize;

  return (
    <Text
      id="fit-text"
      data-testid="fit-text"
      ref={currentElement}
      {...props}
      fontSize={fontSize > minFontSize ? fontSize : minFontSize}
    >
      {children}
    </Text>
  );
};
