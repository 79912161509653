import { GetAlertsResponse } from "app/alert/models";
import { DataSource, Reading } from "app/telemetry/models/datasources";

export interface DataSources {
  dataSource: DataSource;
  readings: Reading[];
  currentReadings: Reading[];
  historicalReadings?: Reading[];
}

export interface TMDataSource {
  readonly [index: number]: DataSources;
}

export interface IState {
  currentReading: TMDataSource;
  historicalReadings: TMDataSource;
  config: IConfig;
}

export interface FetchTelemetryAction {
  type: FetchTelemetryActionTypes.UPDATE_TM_DATA;
  payload: DataSources[];
}

export interface FetchHistoricalTelemetryWorkerAction {
  type: FetchTelemetryActionTypes.UPDATE_HISTORICAL_TM_DATA_BY_WORKER;
  payload: DataSources[] | any;
}

export interface FetchHistoricalTelemetryAction {
  type: FetchTelemetryActionTypes.UPDATE_HISTORICAL_TM_DATA;
  payload: CombinedPayload;
}

export interface FetchTelemetryStartAction {
  type: FetchTelemetryActionTypes.FETCH_READING_START;
}

export interface FetchTelemetrySuccessAction {
  type: FetchTelemetryActionTypes.FETCH_READING_SUCCESS;
}

export interface FetchTelemetryErrorAction {
  type: FetchTelemetryActionTypes.FETCH_READING_ERROR;
}

export type TelemetryDataReducerActions =
  | FetchTelemetryAction
  | FetchHistoricalTelemetryAction
  | FetchHistoricalTelemetryWorkerAction
  | FetchTelemetryStartAction
  | FetchTelemetrySuccessAction
  | FetchTelemetryErrorAction;

export enum FetchTelemetryActionTypes {
  UPDATE_TM_DATA = "UPDATE_TM_DATA",
  UPDATE_HISTORICAL_TM_DATA = "UPDATE_HISTORICAL_TM_DATA",
  UPDATE_HISTORICAL_TM_DATA_BY_WORKER = "UPDATE_HISTORICAL_TM_DATA_BY_WORKER",
  FETCH_READING_FOR_CURRENT_DASHBOARDS = "FETCH_READING_FOR_CURRENT_DASHBOARDS",
  FETCH_TELEMETRY_AT_INTERVAL_START = "FETCH_TELEMETRY_AT_INTERVAL_START",
  FETCH_READING_START = "FETCH_READING_START",
  FETCH_READING_SUCCESS = "FETCH_READING_SUCCESS",
  FETCH_READING_ERROR = "FETCH_READING_ERROR"
}

export enum TMIntervals {
  TM1 = "TM1",
  TM2 = "TM2"
}

export const updateTelemetryData = (payload: DataSources) => ({
  payload,
  type: FetchTelemetryActionTypes.UPDATE_TM_DATA
});

type CombinedPayload = {
  payload: DataSources;
  alerts: GetAlertsResponse;
};

export const updateHistoricalTelemetryData = (
  payload: CombinedPayload,
  alerts?: GetAlertsResponse
) => {
  return {
    payload: { payload, alerts },
    type: FetchTelemetryActionTypes.UPDATE_HISTORICAL_TM_DATA
  };
};

export const fetchTelemetryForCurrentDashboard = () => ({
  type: FetchTelemetryActionTypes.FETCH_READING_FOR_CURRENT_DASHBOARDS
});

export interface IConfig {
  from: string | null;
  to: string | null;
  timeReference: string | null;
  windowSize: number | null;
}

export interface IDatasourcesForCurrentDashboard {
  datasourcesOnCurrentDashboard: number[];
  realTimeGraphDatasources: number[];
  refreshInterval: number;
  dsWithAlerts: number[];
}
