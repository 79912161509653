import React, { Component, useState } from "react";
import SortableTree, {
  addNodeUnderParent,
  changeNodeAtPath,
  removeNodeAtPath,
  toggleExpandedForAll,
  getNodeAtPath
} from "react-sortable-tree";
import { SystemDefinitionCreateContainer as SystemDefinitionCreate } from "../containers/SystemDefinitionCreateContainer";
import { SystemDefinitionEditContainer as SystemDefinitionEdit } from "../containers/SystemDefinitionEditContainer";
import { SystemDefinitionDelete } from "./SystemDefinitionDelete";
import NodeRendererDefault from "app/shared/tree/nodeRendererDefault";
import { searchTree } from "app/shared/tree/utils";
import { Heading, Text, Flex, Icon, Button, Box, Absolute } from "primitives";
import { createSystemDefinition } from "../services";
import { CopyButton, InputField, PasteButton } from "components";
import { DataSourceEdit } from "app/dataSource/components/DataSourceEdit";
import { AlertList } from "app/dataSource";

const DEFAULT_DATASOURCE_PER_PAGE = 10;

export class SystemTreeInput extends Component {
  constructor(props) {
    super(props);

    this.initialNode = {
      title: "",
      expanded: false,
      children: []
    };

    const systems = this.props.record ? this.props.record.systems : [];
    const treeData = this.formatSystemTree(systems);

    this.state = {
      treeData,
      copySelectedNode: null,
      nodePaths: {}
    };
  }

  onChange(treeData) {
    this.setState({ treeData });
    if (this.props.onChange) {
      const data = this.parseSystemTree(treeData);
      this.props.onChange(data);
    }
    this.recomputeRowHeights();
  }

  onCreate(data) {
    const newSystem = this.formatSystemTree([data])[0];
    this.addNode(newSystem);
  }

  toggleExpandAll(expanded) {
    const treeData = this.state.treeData;
    const newTreeData = toggleExpandedForAll({
      treeData,
      expanded: expanded
    });

    this.onChange(newTreeData);
  }

  addNode(data) {
    if (!data.id) return;
    const treeData = this.state.treeData;
    const newTreeData = treeData.concat(data);
    this.onChange(newTreeData);
  }

  addNodeParent(data, path, getNodeKey) {
    const { treeData, addAsFirstChild } = this.state;
    const newSystem = this.formatSystemTree([data])[0];
    const newTreeData = addNodeUnderParent({
      treeData,
      parentKey: path[path.length - 1],
      expandParent: true,
      expanded: true,
      getNodeKey,
      newNode: newSystem,
      addAsFirstChild
    }).treeData;
    this.onChange(newTreeData);
    return newTreeData;
  }

  changeNode(data, path, getNodeKey) {
    const { treeData } = this.state;
    const updatedSystem = this.formatSystemTree([data])[0];
    const nodeChildren = getNodeAtPath({
      treeData,
      path,
      getNodeKey
    }).node.children;
    updatedSystem.children = nodeChildren;
    const updatedTreeData = changeNodeAtPath({
      treeData,
      path,
      getNodeKey,
      newNode: updatedSystem
    });
    this.onChange(updatedTreeData);
  }

  removeNode(path, getNodeKey) {
    const treeData = this.state.treeData;
    const newTreeData = removeNodeAtPath({
      treeData,
      path,
      getNodeKey
    });

    this.onChange(newTreeData);
  }

  expandNodeContent(node, path, getNodeKey) {
    const { treeData } = this.state;
    const updatedNode = {
      ...node,
      contentExpanded: !node.contentExpanded,
      perPage: DEFAULT_DATASOURCE_PER_PAGE
    };
    const updatedTreeData = changeNodeAtPath({
      treeData,
      path,
      getNodeKey,
      newNode: updatedNode
    });
    this.onChange(updatedTreeData);
    this.recomputeRowHeights();
  }

  changeFilter(node, path, getNodeKey, filter) {
    const { treeData } = this.state;
    let contentExpanded = node.contentExpanded;
    if (!node.filter && filter) {
      contentExpanded = true;
    }
    const updatedNode = {
      ...node,
      filter,
      contentExpanded
    };
    const updatedTreeData = changeNodeAtPath({
      treeData,
      path,
      getNodeKey,
      newNode: updatedNode
    });
    this.onChange(updatedTreeData);
    this.recomputeRowHeights();
  }

  changePerPage(node, path, getNodeKey, perPage) {
    const { treeData } = this.state;
    const updatedNode = {
      ...node,
      perPage
    };
    const updatedTreeData = changeNodeAtPath({
      treeData,
      path,
      getNodeKey,
      newNode: updatedNode
    });
    this.onChange(updatedTreeData);
    this.recomputeRowHeights();
  }

  formatSystemTree(systems, parentDefinitionId) {
    if (!systems) return [];
    return systems.map((system) => {
      return {
        id: system.id,
        title: system.name,
        expanded: false,
        contentExpanded: false,
        record: { parentDefinitionId, ...system },
        children: this.formatSystemTree(system.systems, system.id)
      };
    });
  }

  parseSystemTree(systems) {
    if (!systems) return [];
    return systems.map((system) => {
      return {
        id: system.id,
        name: system.title,
        systems: this.parseSystemTree(system.children)
      };
    });
  }

  renderRow({ node }) {
    // Method needed to update the rowHeight props from SortableTree
    const defaultRowHeight = 65;
    const rowHeight = 45;
    const rowHeightMarginBottom = 50;

    if (node.record && node.record.dataSources) {
      let numberOfDatasources = node.record.dataSources.length;
      if (node.filter) {
        numberOfDatasources = node.record.dataSources.filter(
          (ds) => ds.name.indexOf(node.filter) >= 0
        ).length;
      }
      const { perPage } = node;
      if (numberOfDatasources > perPage) {
        numberOfDatasources = perPage;
      }
      return (
        defaultRowHeight +
        (node.contentExpanded
          ? (numberOfDatasources + 1) * rowHeight + rowHeightMarginBottom
          : 0)
      );
    }
    return defaultRowHeight;
  }

  recomputeRowHeights() {
    if (this.listRef) {
      this.listRef.wrappedInstance.current.recomputeRowHeights();
    }
  }

  duplicateNode(parentRecord, getNodeKey, child, parentId) {
    const { copySelectedNode, treeData, nodePaths } = this.state;
    const newNodeRecord = child ? child : copySelectedNode.record;
    delete newNodeRecord.id;
    delete newNodeRecord.parentDefinitionId;
    treeData.forEach((node) => {
      if (node.title === newNodeRecord.name) {
        newNodeRecord.name = `Copy of ${newNodeRecord.name}`;
      }
    });
    createSystemDefinition(
      parentId ? parentId : null,
      parentRecord,
      newNodeRecord
    ).then(({ data }) => {
      if (!parentId) {
        this.onCreate(data);
      } else {
        const newNode = {
          parentDefinitionId: parentId,
          ...data
        };
        this.addNodeParent(newNode, nodePaths[parentId], getNodeKey);
      }
      this.setState({ copySelectedNode: null });
      if (newNodeRecord.systems) {
        newNodeRecord.systems.forEach((child) => {
          this.duplicateNode(parentRecord, getNodeKey, child, data.id);
        });
      }
    });
  }

  render() {
    const { record, height } = this.props;
    const getNodeKey = ({ treeIndex }) => treeIndex;
    const { copySelectedNode, nodePaths } = this.state;
    return (
      <>
        <Flex my={2} data-testid="SystemTreeInput" minHeight={27} ml={22}>
          <SystemDefinitionCreate
            record={record}
            onCreate={(data) => this.onCreate(data)}
          />
          {!copySelectedNode ? (
            <CopyButton
              mx={1}
              backgroundColor={"transparent"}
              disabled
            ></CopyButton>
          ) : (
            <PasteButton
              mx={1}
              backgroundColor={"transparent"}
              onClick={() => this.duplicateNode(record, getNodeKey)}
            ></PasteButton>
          )}
          <Button
            mx={1}
            size="small"
            variant="outline"
            onClick={() => this.toggleExpandAll(true)}
          >
            Expand All
          </Button>
          <Button
            mx={1}
            size="small"
            variant="outline"
            onClick={() => this.toggleExpandAll(false)}
          >
            Collapse All
          </Button>
        </Flex>

        <Box height={height || "600px"} overflow="hidden" mt={1}>
          <SortableTree
            reactVirtualizedListProps={{
              ref: (ref) => (this.listRef = ref)
            }}
            onChange={(treeData) => {
              this.onChange(treeData);
            }}
            treeData={this.state.treeData}
            rowHeight={this.renderRow.bind(this)}
            isVirtualized={true}
            canDrag={false}
            canDrop={() => false}
            nodeContentRenderer={NodeRendererDefault}
            generateNodeProps={({ node, path }) => {
              if (nodePaths && !nodePaths[node.id]) {
                nodePaths[node.id] = path;
                this.setState({ nodePaths });
              }
              return {
                body: (
                  <SystemTreeRow
                    node={node}
                    parentRecord={record}
                    listRef={this.listRef}
                    changeNode={(data) =>
                      this.changeNode(data, path, getNodeKey)
                    }
                    addNodeParent={(data, parentId) => {
                      this.addNodeParent(
                        data,
                        parentId ? nodePaths[parentId] : path,
                        getNodeKey
                      );
                    }}
                    removeNode={() => this.removeNode(path, getNodeKey)}
                    expandNodeContent={() =>
                      this.expandNodeContent(node, path, getNodeKey)
                    }
                    changeFilter={(filter) =>
                      this.changeFilter(node, path, getNodeKey, filter)
                    }
                    changePerPage={(perPage) =>
                      this.changePerPage(node, path, getNodeKey, perPage)
                    }
                    perPage={node.perPage}
                    copySelectedNode={copySelectedNode}
                    changeCopySelectedNode={(copySelectedNode) =>
                      this.setState({ copySelectedNode })
                    }
                    treeData={this.state.treeData}
                  />
                )
              };
            }}
          />
        </Box>
      </>
    );
  }
}

const hasDatasourcesAlerts = (node) => {
  if (node.dataSources.filter((dataSource) => dataSource.alertId).length > 0) {
    return true;
  }

  let hasAlert = false;

  node.systems.forEach((system) => {
    if (hasDatasourcesAlerts(system)) {
      hasAlert = true;
    }
  });

  return hasAlert;
};

const SystemTreeRow = ({
  node,
  parentRecord,
  changeNode,
  addNodeParent,
  removeNode,
  expandNodeContent,
  copySelectedNode,
  changeCopySelectedNode,
  treeData,
  changeFilter,
  changePerPage,
  perPage
}) => {
  const [filter, setFilter] = useState("");
  const onFilterChange = (value) => {
    setFilter(value);
    changeFilter(value);
  };
  const copyNode = () => {
    if (!copySelectedNode) {
      changeCopySelectedNode(node);
    } else if (copySelectedNode.id === node.id) {
      changeCopySelectedNode(null);
    }
  };
  const duplicateNode = (newRecord, parent) => {
    const newNodeRecord = newRecord ? newRecord : copySelectedNode.record;
    delete newNodeRecord.id;
    const searchResult = searchTree({ children: treeData }, node.id);
    if (searchResult.children) {
      searchResult.children.forEach((n) => {
        if (n.title === newNodeRecord.name) {
          newNodeRecord.name = `Copy of ${newNodeRecord.name}`;
        }
      });
    }
    createSystemDefinition(
      parent ? parent.id : node.id,
      parentRecord,
      newNodeRecord
    ).then(({ data }) => {
      const newNode = {
        parentDefinitionId: parent ? parent.id : node.id,
        ...data
      };
      addNodeParent(newNode, parent ? parent.id : null);
      changeCopySelectedNode(null);
      //Duplicate the sub-systems
      if (newNodeRecord.systems) {
        newNodeRecord.systems.forEach((child) => {
          duplicateNode(child, data);
        });
      }
    });
  };
  return (
    <Flex flexDirection="column" overflow="visible" justifyContent="center">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        m={2}
        overflow="visible"
      >
        <Flex mr={2} overflow="visible">
          <Flex flexDirection="column" overflow="visible">
            <Heading display={3}>{node.title}</Heading>
            {node.record.dataSources.length > 0 && (
              <Text fontSize={4}>
                {node.record.dataSources.length} DataSources
              </Text>
            )}
          </Flex>
        </Flex>
        <Flex alignItems="center" overflow="visible">
          <>
            <SystemDefinitionEdit
              record={node.record}
              parentRecord={parentRecord}
              onUpdate={(data) => changeNode(data)}
            />
            <SystemDefinitionCreate
              record={parentRecord}
              onCreate={(data) => addNodeParent(data)}
              parentDefinitionId={node.id}
            />
            <DataSourceEdit
              dataSource={null}
              onChange={(data) => changeNode(data)}
              satelliteDefinitionId={parentRecord.id}
              record={node.record}
            />
            {!copySelectedNode || copySelectedNode.id === node.id ? (
              <CopyButton
                mr={1}
                height={27}
                backgroundColor={
                  copySelectedNode && copySelectedNode.id === node.id
                    ? "palette.brand.0"
                    : "transparent"
                }
                onClick={() => copyNode()}
              ></CopyButton>
            ) : (
              <PasteButton
                mr={1}
                backgroundColor={"transparent"}
                onClick={() => duplicateNode()}
              ></PasteButton>
            )}
            <SystemDefinitionDelete
              record={node.record}
              parentRecord={parentRecord}
              onDelete={removeNode}
            />
            {node.record.dataSources.length > 0 && (
              <Flex alignItems="center">
                <InputField
                  value={filter}
                  onChange={(event) =>
                    onFilterChange(event.target.value.trim())
                  }
                  placeholder="Search datasources"
                  containerStyle={{ margin: "0 4px", minWidth: 51 }}
                />
                <Button
                  ml={1}
                  size="small"
                  height={27}
                  variant="outline"
                  onClick={expandNodeContent}
                >
                  <Icon
                    name={node.contentExpanded ? "ArrowUp" : "ArrowDown"}
                    size="14"
                  />
                </Button>
              </Flex>
            )}
            {hasDatasourcesAlerts(node.record) && (
              <Absolute width={32} right={-31}>
                <Icon name="Alert" color="text.danger" />
              </Absolute>
            )}
          </>
        </Flex>
      </Flex>
      {node.contentExpanded && (
        <Flex overflow="scroll" maxHeight="8460px">
          {node.record && node.record.dataSources.length > 0 && (
            <AlertList
              dataSources={node.record.dataSources}
              filter={node.filter}
              changePerPage={(pageSize) => changePerPage(pageSize)}
              perPage={perPage}
            />
          )}
        </Flex>
      )}
    </Flex>
  );
};
