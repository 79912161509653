import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { PlanningSystem } from "app/planningSystem/components/PlanningSystem";
import {
  getTimelineAction,
  getCalendarTimelineAction,
  getAllOperationActivitiesAction
} from "app/planningSystem/actions";
import {
  FetchTimelineParams,
  FetchTimelineResponse,
  FetchAllOperationActivityParams
} from "app/planningSystem/models";
import { fetchAllPassagesAction } from "app/visibilityWindow/actions";

const mapStateToProps = (state: any) => ({});
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getTimeline: (params: FetchTimelineParams) =>
      dispatch(getTimelineAction(params)),
    getAllPassages: (availableSatellites: number[], from: Date, to: Date) =>
      dispatch(fetchAllPassagesAction(availableSatellites, from, to)),
    getCalendarTimeline: (timelines: FetchTimelineResponse) =>
      dispatch(getCalendarTimelineAction(timelines)),
    getAllOperationActivities: (params?: FetchAllOperationActivityParams) =>
      dispatch(getAllOperationActivitiesAction(params))
  };
};

export const PlanningSystemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanningSystem);
