import React, { Component } from "react";
import { getExecTelecommands } from "../../services";
import { PaginationSimple } from "app/shared";
import { TelecommandsListTable } from "../TelecommandsListTable";
import { DashboardComponents } from "app/dashboard/models";
import { Box, Text } from "primitives";
import { execTelecommand } from "../../services";
import {
  TelecommandExecutionMode,
  AuroraTelecommandPayload,
  TelecommandPayload,
  ExecutedTelecommand,
  IFiltersTCList
} from "../../models";
import { TelecommandsListBaseProps, TelecommandsListBaseState } from "./models";
import { FeedbackStatus } from "app/feedback/models";
import { HttpErrorStatus, DataProviderError } from "app/network/models";
import { filterByField, shouldFilter } from "components/table/Filter";
import { clone } from "utils";
import { isTCResponseEvent, parseSSE, shouldRefetchTCList } from "../../utils";
import { TelecommandListHeader } from "./TelecommandListHeader";
import { HTTPResponse } from "app/network/dataProvider/model";

/**
 * Primary component to display the telecommand list. Connects the list table with the pagination.
 */
export class TelecommandsListBase extends Component<
  TelecommandsListBaseProps,
  TelecommandsListBaseState
> {
  private willUnmount: boolean;
  // any for now until we add typings to dashboard.
  static defaultProps: any;
  private eventSource: EventSource;
  private customEventType = "TELECOMMAND_NOTIFICATION";
  private NOTIFICATION_URL = process.env.REACT_APP_NOTIFICATION_URL;
  private refetch: number | null | ReturnType<typeof setTimeout>;
  private fetching: boolean;

  constructor(props: TelecommandsListBaseProps) {
    super(props);
    this.willUnmount = false;
    this.refetch = null;
    this.eventSource = new EventSource(`${this.NOTIFICATION_URL}`);
    this.state = {
      executedTelecommands: null,
      totalInitial: 0,
      error: false,
      pagination: {
        page: props.options.page || 1,
        perPage: props.options.perPage || 50
      },
      filters: {
        satId: null,
        groundStationName: null,
        username: null,
        tcLabel: null,
        timestamp: null,
        status: null,
        response: null
      }
    };

    this.resendTelecommand = this.resendTelecommand.bind(this);
    this.reuseTelecommand = this.reuseTelecommand.bind(this);
    this.fetching = false;
  }

  /**
   * Sets the pagination for the telecommands list. If no pagination info is provided
   * it defaults back to null
   * @param pagination - object with page and perPage keys or null
   * @param cb - function to be executed once the pagination has been stored in the compontent state
   */
  setPagination(pag: number | null, cb: () => void) {
    const paginationParams = {
      page: pag ? pag : this.props.options.page ? this.props.options.page : 1,
      perPage: this.state.pagination.perPage
        ? this.state.pagination.perPage
        : 50
    };

    // TODO: is this the best way to express this or is there a way to easily transform the callback into a promise?
    this.setState(
      {
        pagination: paginationParams
      },
      cb
    );
  }

  async getExecTelecommandsList() {
    const { satellite } = this.props;
    const pagination = this.state.pagination;
    if (!this.fetching) {
      try {
        this.fetching = true;
        const response = (await getExecTelecommands(
          satellite.id,
          pagination
        )) as HTTPResponse;
        if (!this.willUnmount) {
          this.setState({
            executedTelecommands: Array.isArray(response.data)
              ? response.data
              : [],
            totalInitial: response.total || 0
          });
        }
        this.fetching = false;
        return response;
      } catch {
        this.props.setFeedback(
          "Error fetching Telecommand sent list ",
          FeedbackStatus.ERROR
        );
        this.fetching = false;
        this.setState({ error: true, executedTelecommands: [] });
      }
    }
  }

  resendTelecommand(
    satId: number,
    telecommandExecutionPayload: AuroraTelecommandPayload
  ) {
    const { selectedPassage } = this.props;
    if (selectedPassage && selectedPassage.passageID) {
      telecommandExecutionPayload.setPassageId(selectedPassage.passageID);

      execTelecommand(satId, telecommandExecutionPayload.toOutputModel())
        .then(() => {
          // Reset pagination
          // NOTE: abort controllers should take care of repetitive requests
          // this.setPagination(null, () => this.getExecTelecommandsList());
          this.props.setFeedback(
            "Telecommand successfully submited",
            FeedbackStatus.SUCCESS
          );
        })
        /* tslint:disable-next-line */
        .catch((err: DataProviderError) =>
          this.props.setFeedback(
            `Error submiting telecommand: ${
              HttpErrorStatus[err.status] as any
            }`,
            FeedbackStatus.ERROR
          )
        );
    } else {
      this.props.setFeedback("No passage selected", FeedbackStatus.ERROR);
    }
  }

  reuseTelecommand(
    telecommandRecordId: number,
    telecommandId: string,
    telecommandExecutionPayload: TelecommandPayload
  ) {
    this.props.dispatchTelecommandSelectAction(
      telecommandId,
      telecommandRecordId
    );
    const formData = AuroraTelecommandPayload.build(
      clone(telecommandExecutionPayload)
    ).toFormData();

    this.props.dispatchTelecommandExecutionModeAction(
      TelecommandExecutionMode.Form,
      formData
    );
  }

  componentDidMount() {
    const {
      satellite: { id },
      refetchTCList
    } = this.props;
    this.getExecTelecommandsList();
    this.eventSource.addEventListener(this.customEventType, ((
      event: MessageEvent
    ) => {
      const sse = parseSSE(event);
      if (sse) {
        if (shouldRefetchTCList(sse, id)) {
          if (isTCResponseEvent(sse)) {
            refetchTCList(sse.payload);
          }
          this.getExecTelecommandsList();
        }
      }
    }) as EventListener);
    this.eventSource.onopen = () => {
      this.props.setStatus(true);
    };
    this.eventSource.onerror = (e) => {
      this.props.setStatus(false);
      console.log("Event source err: ", e);
    };
  }

  clearRefetch() {
    if (this.refetch) {
      clearTimeout(this.refetch as ReturnType<typeof setTimeout>);
    }
  }
  componentWillUnmount() {
    this.eventSource.close();
    this.willUnmount = true;
    this.clearRefetch();
  }

  componentDidUpdate(prevProps: TelecommandsListBaseProps, prevState: any) {
    if (
      prevProps.satellite.id !== this.props.satellite.id ||
      prevState.pagination !== this.state.pagination
    ) {
      this.getExecTelecommandsList();
    }
    if (!this.props.notificationsConnected) {
      this.refetch = setTimeout(() => this.getExecTelecommandsList(), 6000);
    }
    if (this.props.notificationsConnected && this.refetch) {
      clearTimeout(this.refetch as ReturnType<typeof setTimeout>);
    }
  }

  onChange = (value: string, id: string) => {
    this.setState({
      filters: { ...this.state.filters, [id]: value }
    });
  };

  filterTelecommandList = (
    filters: IFiltersTCList,
    executedTelecommands: any
  ): ExecutedTelecommand[] => {
    if (shouldFilter(filters) && executedTelecommands) {
      return filterByField(executedTelecommands, filters);
    }
    return executedTelecommands;
  };

  render() {
    const { executedTelecommands, totalInitial, filters, pagination } =
      this.state;
    const { options } = this.props;
    const filterableTelecommandList = this.filterTelecommandList(
      filters,
      executedTelecommands
    );
    return (
      <>
        {options.label && (
          <Text fontSize={18} m="10px 0">
            {options.label}
          </Text>
        )}
        <Box color="text.default" bg="fill.0" data-testid="telecommands-sent">
          <TelecommandListHeader
            type={this.props.type}
            id={this.props.id}
            showModal={this.props.showModal}
          />
          <>
            <TelecommandsListTable
              executedTelecommands={filterableTelecommandList}
              resendTelecommandAction={this.resendTelecommand}
              reuseTelecommandAction={this.reuseTelecommand}
              onChange={this.onChange}
              options={options}
            />
            <PaginationSimple
              onChange={(pag: string | number) => {
                this.setPagination(pag as number, () => {
                  this.getExecTelecommandsList();
                });
              }}
              total={totalInitial}
              pageSize={pagination.perPage}
              nextPageUrl={
                totalInitial > pagination.perPage * pagination.page
                  ? pagination.page + 1
                  : 0
              }
              previousPageUrl={pagination.page > 1 ? pagination.page - 1 : -1}
              onPageSizeChange={(size: number) => {
                this.setState({
                  pagination: { page: 1, perPage: size }
                });
              }}
              page={pagination.page}
            />
          </>
        </Box>
      </>
    );
  }
}

TelecommandsListBase.defaultProps = {
  ...DashboardComponents.TelecommandsList
};
