import React, { Component } from "react";
import { Box, Text, Flex } from "primitives";
import { DeleteButton, PlayButton, PauseButton } from "components";
import { connect } from "react-redux";
import { DataProviderResponse } from "app/network/models";
import { SatelliteInstance } from "app/satellite/models";
import { constants } from "config";
import { DashboardComponents } from "app/dashboard/models";
import {
  Operation,
  OperationType,
  OperationStatus,
  OperationStatusColor
} from "../models";
import { getOperationsList, updateDownload, updateUpload } from "../services";
import { TabInput } from "app/shared";
import { PaginationParams } from "app/shared/list/components/PaginationSimple";
import { PaginationSimple } from "app/shared";
import { Header } from "app/dashboard/components/Header";

interface OperationsListBaseProps {
  options: any;
  satellite: SatelliteInstance;
}

interface OperationsListBaseState {
  operations: Operation[];
  operationType: OperationType;
  pagination: PaginationParams;
}

/**
 * Primary component to display the telecommand list. Connects the list table with the pagination.
 */
class OperationsListBase extends Component<
  OperationsListBaseProps,
  OperationsListBaseState
> {
  private interval: number | null | ReturnType<typeof setTimeout>;
  private willUnmount: boolean;
  // any for now until we add typings to dashboard.
  static defaultProps: any;

  constructor(props: OperationsListBaseProps) {
    super(props);
    this.interval = null;
    this.willUnmount = false;
    this.state = {
      operationType: OperationType.DOWNLOAD,
      operations: [],
      pagination: {
        page: props.options.page || 1,
        perPage: props.options.perPage || 10
      }
    };
  }

  setPagination(pagination: number | null, cb: () => void) {
    const paginationParams = {
      page: pagination
        ? pagination
        : this.props.options.page
        ? this.props.options.page
        : 1,
      perPage: this.state.pagination.perPage
        ? this.state.pagination.perPage
        : 10
    };

    // TODO: is this the best way to express this or is there a way to easily transform the callback into a promise?
    this.setState(
      {
        pagination: paginationParams
      },
      cb
    );
  }

  getOperationsList = async () => {
    const { satellite } = this.props;
    const { operationType } = this.state;
    this.clearInterval();
    try {
      const getOperationsListResponse: DataProviderResponse = await getOperationsList(
        operationType,
        satellite.id
      );
      if (
        getOperationsListResponse.data &&
        getOperationsListResponse.data.operationsList
      ) {
        const operations = getOperationsListResponse.data.operationsList;
        this.setState({ operations });
      }
      this.interval = setTimeout(
        () => this.getOperationsList(),
        constants.timer.defaultGetOperationsList
      );
    } catch (err) {
      console.error(err);
    }
  };

  dismissOperation = async (
    operationId: number,
    operationStatus: OperationStatus
  ) => {
    const { satellite } = this.props;
    const { operationType } = this.state;
    try {
      if (operationType === OperationType.DOWNLOAD)
        await updateUpload(operationId, satellite.id, operationStatus, "true");
      else
        await updateUpload(operationId, satellite.id, operationStatus, "true");
      this.getOperationsList();
    } catch (err) {
      console.error(err);
    }
  };

  updateOperation = (operationId: number, operationStatus: OperationStatus) => {
    const { satellite } = this.props;
    const { operationType } = this.state;
    try {
      if (operationType === OperationType.DOWNLOAD) {
        updateDownload(operationId, satellite.id, operationStatus);
      } else {
        updateUpload(operationId, satellite.id, operationStatus);
      }
    } catch (err) {
      console.error(err);
    }
  };

  componentDidUpdate(
    prevProps: OperationsListBaseProps,
    prevState: OperationsListBaseState
  ) {
    const { operationType } = this.state;
    if (operationType !== prevState.operationType) {
      this.clearInterval();
      this.interval = setTimeout(() => this.getOperationsList(), 0);
    }
  }

  componentDidMount() {
    this.interval = setTimeout(() => this.getOperationsList(), 0);
  }

  componentWillUnmount() {
    this.clearInterval();
    this.willUnmount = true;
  }

  clearInterval() {
    if (this.interval) {
      clearTimeout(this.interval as ReturnType<typeof setTimeout>);
    }
  }

  renderOperations() {
    const { operations, operationType, pagination } = this.state;
    const currentPage = pagination.page - 1;
    return operations
      .slice(
        currentPage * pagination.perPage,
        currentPage * pagination.perPage + pagination.perPage
      )
      .map((operation: Operation) => {
        return (
          <Flex p={2} border={2} m={1} key={operation.opId}>
            <Flex flex={3} flexDirection="column">
              <Flex>
                <Text bold>From:</Text>
                <Text>
                  {operationType === OperationType.DOWNLOAD
                    ? operation.opSatPath
                    : operation.opResId}
                </Text>
              </Flex>
              <Flex mt={1}>
                <Text bold>To:</Text>
                <Text>
                  {operationType === OperationType.UPLOAD
                    ? operation.opSatPath
                    : operation.opResId}
                </Text>
              </Flex>
            </Flex>
            <Flex
              flex={1}
              alignItems="center"
              justifyContent="center"
              overflow="visible"
            >
              <Text bold color={OperationStatusColor[operation.opStatus]}>
                {operation.opStatus}
              </Text>
            </Flex>
            <Flex
              flex={1}
              alignItems="center"
              justifyContent="center"
              overflow="visible"
            >
              <Text bold>{operation.opProgress}%</Text>
            </Flex>
            <Flex
              flex={2}
              alignItems="center"
              justifyContent="center"
              overflow="visible"
            >
              <PlayButton
                m={1}
                size={20}
                //   disabled={operation.opStatus !== OperationStatus.PAUSED}
                disabled={true}
                onClick={() =>
                  this.updateOperation(operation.opId, OperationStatus.ONGOING)
                }
              />
              <PauseButton
                m={1}
                size={20}
                //   disabled={operation.opStatus !== OperationStatus.ONGOING}
                disabled={true}
                onClick={() =>
                  this.updateOperation(operation.opId, OperationStatus.PAUSED)
                }
              />
              <DeleteButton
                m={1}
                size={20}
                onClick={() =>
                  this.dismissOperation(operation.opId, operation.opStatus)
                }
              >
                {" "}
                Dismiss{" "}
              </DeleteButton>
            </Flex>
          </Flex>
        );
      });
  }

  render() {
    const { operationType, operations, pagination } = this.state;
    const { options } = this.props;
    return (
      <Box minWidth={488}>
        {options.label && (
          <Text fontSize={18} m="10px 0">
            {options.label}
          </Text>
        )}
        <Box
          color="text.default"
          p={1}
          bg="fill.0"
          data-testid="operations-list"
          overflowX="hidden"
        >
          <Header my={3} width={16}>
            <Flex>
              <TabInput
                record={null}
                tabs={["Downloads", "Uploads"]}
                containers={(value: number) => (
                  <>
                    {value === 0 &&
                      operationType !== OperationType.DOWNLOAD &&
                      this.setState({
                        operationType: OperationType.DOWNLOAD,
                        pagination: {
                          page: options.page || 1,
                          perPage: options.perPage || 10
                        }
                      })}
                    {value === 1 &&
                      operationType !== OperationType.UPLOAD &&
                      this.setState({
                        operationType: OperationType.UPLOAD,
                        pagination: {
                          page: options.page || 1,
                          perPage: options.perPage || 10
                        }
                      })}
                  </>
                )}
              />
            </Flex>
          </Header>

          {this.renderOperations()}
          <PaginationSimple
            onChange={(pag: string | number) =>
              this.setPagination(pag as number, () => null)
            }
            total={operations.length}
            pageSize={pagination.perPage}
            nextPageUrl={
              operations.length > pagination.perPage * pagination.page
                ? pagination.page + 1
                : 0
            }
            previousPageUrl={pagination.page > 1 ? pagination.page - 1 : -1}
            onPageSizeChange={(size: number) => {
              this.setState({
                pagination: { page: 1, perPage: Number(size) }
              });
            }}
            page={pagination.page}
          />
        </Box>
      </Box>
    );
  }
}

OperationsListBase.defaultProps = {
  ...DashboardComponents.OperationsList
};

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const OperationsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(OperationsListBase);
