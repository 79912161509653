import React from "react";
import { CONST_KIND } from "app/telecommand/models";
import { SelectSimple as Select, CustomSelect } from "components";
import { disabledInProd, isProd } from "app/shared/utils";

interface SelectBoxProps {
  id: string;
  value: string | number | undefined;
  readonly?: boolean;
  disabled?: boolean;
  onBlur?: any;
  required?: boolean;
  onFocus?: any;
  onChange?: any;
  placeholder?: string;
  options?: any;
  schema?: any;
  autofocus?: boolean;
  fontSize?: string | number;
  height?: string | number;
  width?: string;
  iconPt?: string;
  noBorderBottom?: boolean;
}

export const SelectBox = (props: SelectBoxProps): JSX.Element => {
  const {
    id,
    value,
    readonly,
    autofocus,
    disabled,
    onBlur,
    onFocus,
    onChange,
    placeholder,
    options,
    schema
  } = props;
  // NOTE: temporarily disable histogram widget ONLY in production while feature is still in development. This allows merge into master without worrying about operators actually using a wip feature.
  const { enumOptions: _enumOptions } = options;
  const enumOptions = isProd
    ? _enumOptions.filter((i: any) => !disabledInProd.includes(i.label))
    : _enumOptions;

  let kind = null;

  if (schema) {
    kind = schema.kind;
  }

  const _onChange = (selectValue: string) => {
    return onChange(selectValue === "" ? options.emptyValue : selectValue);
  };

  return schema?.searchableList ? (
    <SearchableListSelector
      enumOptions={enumOptions}
      _onChange={_onChange}
      value={typeof value === "undefined" || value === null ? "" : value}
    />
  ) : (
    <Select
      width="auto"
      data-testid={id}
      autoFocus={autofocus}
      {...props}
      disabled={disabled || readonly || (kind && kind === CONST_KIND)}
      onBlur={
        onBlur &&
        ((event) => {
          onBlur(id, event.target.value);
        })
      }
      onFocus={
        onFocus &&
        ((event) => {
          onFocus(id, event.target.value);
        })
      }
      onChange={(target) => {
        const {
          target: { value: fieldValue }
        } = target;
        _onChange(fieldValue);
      }}
      value={typeof value === "undefined" || value === null ? "" : value}
    >
      <option value="">{placeholder}</option>
      {enumOptions.map(
        (
          { value: option, label }: { value: string; label: string },
          i: number
        ) => {
          return (
            <option key={i} value={option}>
              {label}
            </option>
          );
        }
      )}
    </Select>
  );
};

export const SearchableListSelector = ({
  enumOptions,
  _onChange,
  value
}: any) => {
  const selectedValue = () => {
    if (enumOptions && Array.isArray(enumOptions)) {
      return enumOptions.find((i: { value: any }) => i.value === value);
    }
    return null;
  };

  return (
    <CustomSelect
      className="datasource-selector"
      menuPortalTarget={document.body}
      styles={datasourceSelectorCustomStyles || {}}
      placeholder="Select a datasource"
      onChange={(e: any) => _onChange(e.value)}
      value={selectedValue()}
      border={1}
      options={enumOptions}
      color="text.default"
    />
  );
};

// Override styles for datasource selector in alert-definition to prevent conflict with other selectors
const datasourceSelectorCustomStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? "white" : "#2E4162",
    color: state.isFocused ? "#2E4162" : "#7ab9db"
  }),
  menu: (provided: any) => {
    return {
      ...provided,
      zIndex: 9999,
      backgroundColor: "#2E4162",
      fontSize: "12px",
      color: "white"
    };
  },
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999
  })
};
