import React, { useState, useCallback } from "react";
import { Table, TableHead, TableBody, TableRow, TableCell } from "components";
import { AlertEdit } from "./AlertEdit";
import { AlertCreate } from "./AlertCreate";
import { Icon, Flex } from "primitives";
import { Alert, DataSource, DataTypeNumbers } from "../models";
import { DataSourceEdit } from "../components/DataSourceEdit";
import {
  PaginationSimple,
  PaginationParams
} from "app/shared/list/components/PaginationSimple";

interface AlertListProps {
  dataSources: DataSource[];
  filter: string;
  changePerPage: (perPage: number) => any;
  perPage: number;
}

const initialPagination = {
  page: 1,
  perPage: 10
};

export const AlertList = ({
  dataSources,
  filter,
  changePerPage,
  perPage
}: AlertListProps) => {
  const [items, setItems] = useState(dataSources);
  const [pagination, setPagination] = useState(initialPagination);

  const onChange = useCallback(
    (dataSource: DataSource, alert: Alert | null) => {
      setItems((i) =>
        i.map((item: DataSource) => {
          if (dataSource.id === item.id) {
            item.alertId = alert ? alert : null;
          }
          return item;
        })
      );
    },
    []
  );

  const onChangeDataSource = useCallback((dataSource: DataSource) => {
    setItems((i) =>
      i.map((item: DataSource) => {
        if (dataSource.id === item.id) {
          return dataSource;
        }
        return item;
      })
    );
  }, []);

  const canAddAlert = useCallback(
    (dataSource: DataSource) => {
      if (DataTypeNumbers.indexOf(dataSource.tmDataType.type) !== -1) {
        if (dataSource.alertId) {
          return <AlertEdit dataSource={dataSource} onChange={onChange} />;
        } else {
          return <AlertCreate dataSource={dataSource} onChange={onChange} />;
        }
      }
      return null;
    },
    [onChange]
  );

  const changePagination = (newPagination: string | number) => {
    const paginationParams = newPagination
      ? {
          page: newPagination,
          perPage: pagination.perPage ? pagination.perPage : 10
        }
      : initialPagination;

    setPagination(paginationParams as PaginationParams);
  };

  const filteredItems = items.filter(
    (ds) => !filter || ds.name.indexOf(filter) >= 0
  );
  const paginatedItems = filteredItems.slice(
    (pagination.page - 1) * pagination.perPage,
    pagination.page * pagination.perPage
  );

  if (paginatedItems.length !== perPage) {
    changePerPage(paginatedItems.length);
  }

  return (
    <Flex flexDirection="column" flex={1} overflow="visible" bg="fill.2">
      <Table data-testid="AlertList">
        <TableHead hideHeader={false}>
          <TableRow bg="fill.0">
            <TableCell width="20" />
            <TableCell width="50">ID</TableCell>
            <TableCell width="auto">Data Source Name</TableCell>
            <TableCell width="50">Alert</TableCell>
            <TableCell width="42" />
          </TableRow>
        </TableHead>
        <TableBody>
          {items &&
            paginatedItems.map((dataSource) => {
              return (
                <TableRow key={dataSource.id} height={40}>
                  <TableCell>
                    <DataSourceEdit
                      dataSource={dataSource}
                      onChange={onChangeDataSource}
                    />
                  </TableCell>
                  <TableCell>#{dataSource.id}</TableCell>
                  <TableCell>{dataSource.name}</TableCell>

                  <TableCell>{canAddAlert(dataSource)}</TableCell>
                  <TableCell>
                    {dataSource.alertId && (
                      <Icon name="Alert" color="text.danger" />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <PaginationSimple
        onChange={(newPagination: string | number) =>
          changePagination(newPagination)
        }
        total={filteredItems.length}
        pageSize={pagination.perPage}
        nextPageUrl={
          filteredItems.length > pagination.perPage * pagination.page
            ? pagination.page + 1
            : 0
        }
        previousPageUrl={pagination.page > 1 ? pagination.page - 1 : -1}
        onPageSizeChange={(size: number) => {
          setPagination({ page: 1, perPage: Number(size) });
          changePerPage(size);
        }}
        page={pagination.page}
      />
    </Flex>
  );
};
