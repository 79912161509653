import { connect } from "react-redux";
import LineChart from "./LineChartComponent";
import { addExtentToGraph } from "app/dashboard/actions";
import { setFromTo } from "app/shared/timeController/actions";

const mapStateToProps = (state: any) => ({
  yExtents: state.dashboard.graphs.yExtents,
  timeController: state.datastore.timeController
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    addExtentToGraph: (payload: { index: number; yExtent: number[] | null }) =>
      dispatch(addExtentToGraph(payload)),
    setFromTo: (payload: { from: number; to: number }) =>
      dispatch(setFromTo(payload))
  };
};

export const LineChartContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LineChart);
