import React, { Component } from "react";
import { Form } from "app/shared";
import { Box, Heading, Flex, Text } from "primitives";
import { SuspenseMutation } from "app/network";
import { schemaGenerator, uiSchema } from "../models/schemas";
import { SaveButton, CancelButton } from "components";
import { getDataSourcesFromSystemDefinition } from "app/dataSource";
import { validateFormData } from "../utils/validators";
import { FeedbackStatus } from "app/feedback/models";

export class DashboardCreate extends Component {
  state = {
    formData: null,
    dataSources: [],
    satelliteDefinitions: [],
    satelliteDefinitionId: null,
    selectedSatelliteDefinition: null,
    satelliteInstances: []
  };

  componentDidMount() {
    this.retrieveSatelliteDefinitionList();
  }

  retrieveSatelliteDefinitionList() {
    const { constellations } = this.props;
    const { satelliteDefinitions } = this.state;

    if (
      satelliteDefinitions.length === 0 &&
      constellations &&
      constellations.length
    ) {
      const satDefs = [];
      const satInstances = [];
      constellations.forEach((constellation) => {
        constellation.satelliteInstances.forEach((satellite) => {
          const satDefId =
            satellite.satelliteDefinitionSummary.satelliteDefinitionId;
          const index = satDefs.findIndex(
            (it) => it.satelliteDefinitionId === satDefId
          );

          // Filter duplicated satellite definitions
          if (index <= -1) {
            if (satellite.satelliteDefinitionSummary.satelliteDefinitionId) {
              satellite.satelliteDefinitionSummary.id =
                satellite.satelliteDefinitionSummary.satelliteDefinitionId;
            }
            satDefs.push(satellite.satelliteDefinitionSummary);
          }
          satInstances.push(satellite);
        });
      });
      this.setState({
        satelliteDefinitions: satDefs,
        satelliteInstances: satInstances
      });
    }
  }

  updateSatelliteDefinitionDataSources(satelliteDefinitionId) {
    this.props
      .getSatelliteDefinition(satelliteDefinitionId)
      .then((satelliteDefinition) => {
        if (!satelliteDefinition) return;
        const dataSources = getDataSourcesFromSystemDefinition(
          satelliteDefinition.systems
        );
        this.setState({
          satelliteDefinitionId,
          dataSources,
          selectedSatelliteDefinition: satelliteDefinition
        });
      });
  }

  submit(e, { formData }) {
    e.preventDefault();

    return this.props
      .createDashboard(formData)
      .then(() => {
        this.props.history.push(`/dashboard`);
      })
      .catch(() => {
        this.props.setFeedback("Unauthorized", FeedbackStatus.ERROR);
      });
  }

  onChange(form) {
    const { satelliteDefinitionId } = this.state;
    const selectedSatelliteDefinitionId = form.formData.satDefinitionId;

    //Update dashboard row size
    let rowSize = Math.max(
      ...form.formData.components.map(
        (element) => element.position.row - 1 + element.size.row
      )
    );
    if (
      form.formData.components.length > 0 &&
      rowSize !== form.formData.size.row
    )
      form.formData.size.row = rowSize;

    if (!selectedSatelliteDefinitionId) {
      this.setState({ selectedSatelliteDefinition: null });
    } else if (selectedSatelliteDefinitionId !== satelliteDefinitionId) {
      this.updateSatelliteDefinitionDataSources(selectedSatelliteDefinitionId);
    }

    this.setState({ formData: form.formData });
  }

  render() {
    const {
      dataSources,
      satelliteDefinitions,
      satelliteDefinitionId,
      selectedSatelliteDefinition,
      formData,
      satelliteInstances
    } = this.state;

    const schema = schemaGenerator(
      dataSources,
      satelliteDefinitions,
      satelliteDefinitionId,
      selectedSatelliteDefinition,
      formData,
      satelliteInstances
    );

    return (
      <SuspenseMutation>
        {({ loading, error, action }) => {
          return (
            <Box color="text.default" data-testid="DashboardCreate" mx={3}>
              <Flex mb={2}>
                <Heading display={1}>Dashboard</Heading>
              </Flex>
              <Flex flexDirection="column" bg={"fill.0"} p={3}>
                <Form
                  formData={formData}
                  schema={schema}
                  uiSchema={uiSchema}
                  validate={(data, errors) =>
                    validateFormData(data, errors, dataSources)
                  }
                  disabled={loading}
                  onSubmit={(data, e) => action(() => this.submit(e, data))}
                  onChange={(form) => this.onChange(form)}
                >
                  <CancelButton
                    onClick={() => this.props.history.push(`/dashboard`)}
                    mr={2}
                  >
                    Cancel
                  </CancelButton>
                  <SaveButton type="submit" disabled={loading}>
                    Save
                  </SaveButton>

                  {error ? (
                    <Box my={2}>
                      <Text color="text.danger">Error...</Text>
                    </Box>
                  ) : null}
                </Form>
              </Flex>
            </Box>
          );
        }}
      </SuspenseMutation>
    );
  }
}
