import React, { Component } from "react";
import { deleteConstellation } from "app/constellation";
import { CancelButton, DeleteButton } from "components";
import { Flex, Dialog, DialogTitle, DialogActions } from "primitives";

export class ConstellationDelete extends Component {
  state = {
    modalOpen: false
  };

  hideDialog = () => {
    this.setState({ modalOpen: false });
  };

  openDialog = () => {
    this.setState({ modalOpen: true });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { record } = this.props;

    deleteConstellation(record).then(() => {
      if (record.id === this.props.constellationId) {
        this.props.selectConstellation(null);
      }
      this.props.onChange();
      this.hideDialog();
    });
  };

  render() {
    const { record } = this.props;
    const { modalOpen } = this.state;

    return (
      <>
        <DeleteButton onClick={() => this.openDialog()}>Delete</DeleteButton>
        <Dialog open={modalOpen} maxWidth="sm">
          <DialogTitle>Delete {record.name}?</DialogTitle>

          <DialogActions>
            <Flex
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              mx={1}
            >
              <DeleteButton onClick={(e) => this.handleSubmit(e)} mr={1}>
                Delete
              </DeleteButton>
              <CancelButton onClick={() => this.hideDialog()}>
                Cancel
              </CancelButton>
            </Flex>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
