import styled from "styled-components";
import Area from "./Area";

const Header = styled(Area)``;

Header.defaultProps = {
  gridArea: "header"
};

export default Header;
