import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store } from "app/store";
import { BrowserRouter } from "react-router-dom";
import "react-sortable-tree/style.css";
import Keycloak from "keycloak-js";
import { Ion, BingMapsApi } from "cesium";
import styled from "styled-components";
import Area from "./layout/Area";
import { Icon, Box, Flex } from "primitives";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// don't send error logs during development
const isDEV = process.env.NODE_ENV === "development"
!isDEV && Sentry.init({
  dsn: "https://1d1b3a052ff94bdf9cb32d19cc81f3c5@aurora-sentry.dorbit.space/3",
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_TARGET_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

const render = () =>
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById("app-root")
  );

const Content = styled(Area)`
  background-image: radial-gradient(rgb(128, 128, 128, 0.3) 1px, transparent 0);
  background-size: 12px 12px;
  background-position: -40px -40px;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  position: absolute;
  background-color: rgb(31, 30, 44);
  color: white;
  font-size: 19px;
  top: 0px;
  left: 0px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
`;

const FailedToInitialize = (
  <Content>
    <Flex m="auto" flexDirection="column">
      <Box m="auto">
        <Icon size={20} name="Aurora"></Icon>
      </Box>
      <Box mt={20}>Authentication service is down</Box>
    </Flex>
  </Content>
);

//Keycloak init
const KEYCLOACK_CONFIG_DIR_PATH = "/keycloack-config/";
const kc: any = Keycloak(
  `${KEYCLOACK_CONFIG_DIR_PATH}${process.env.REACT_APP_KEYCLOACK_CONFIG_FILE}`
);
kc.init({
  onLoad: "login-required",
  promiseType: "native",
  checkLoginIframe: false
}).then(
  (authenticated: boolean) => {
    if (authenticated) {
      store.getState().keycloak = kc;
      render();
    }
  },
  () => {
    ReactDOM.render(FailedToInitialize, document.getElementById("app-root"));
  }
);
//

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();

//Cesium Tokens
Ion.defaultAccessToken = process.env.REACT_APP_ION_DEFAULT_ACCESS_TOKEN;
BingMapsApi.defaultKey = process.env.REACT_APP_BINGMAPS_API_DEFAULT_KEY;

if (process.env.NODE_ENV === "development") {
  if (module.hot) {
    module.hot.accept("./App", () => render());
  }
}
