import React from "react";
import { SvgProps } from "primitives/Svg";

const SvgFile = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    stroke={"#fff"}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <g id="surface1">
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "#fff",
          fillOpacity: 1
        }}
        d="M 20.5 6 L 15.5 6 C 15.222656 6 15 5.773438 15 5.5 L 15 0.5 C 15 0.222656 14.777344 0 14.5 0 L 4.5 0 C 3.671875 0 3 0.671875 3 1.5 L 3 22.5 C 3 23.328125 3.671875 24 4.5 24 L 19.5 24 C 20.328125 24 21 23.328125 21 22.5 L 21 6.5 C 21 6.222656 20.777344 6 20.5 6 Z M 20 22.5 C 20 22.773438 19.777344 23 19.5 23 L 4.5 23 C 4.222656 23 4 22.773438 4 22.5 L 4 1.5 C 4 1.226562 4.222656 1 4.5 1 L 14 1 L 14 5.5 C 14 6.328125 14.671875 7 15.5 7 L 20 7 Z M 20 22.5 "
      />
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "#fff",
          fillOpacity: 1
        }}
        d="M 20.851562 6.148438 L 14.851562 0.148438 C 14.65625 -0.046875 14.339844 -0.046875 14.144531 0.148438 C 13.949219 0.34375 13.949219 0.660156 14.144531 0.855469 L 20.144531 6.855469 C 20.242188 6.949219 20.371094 7 20.5 7 C 20.628906 7 20.757812 6.949219 20.851562 6.855469 C 21.046875 6.660156 21.046875 6.34375 20.851562 6.148438 Z M 20.851562 6.148438 "
      />
    </g>
  </svg>
);

SvgFile.defaultProps = {
  width: "16",
  height: "16"
};
export default SvgFile;
