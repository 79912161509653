import { some } from "lodash";
import { updateDateRange } from "app/shared/timeController/actions";
import { TypedArray } from "uplot";
const lastRanges: Range[] = [];
const pushIfNew = (r: Range) => !some(lastRanges, r) && lastRanges.push(r);

type Range = {
  from: any;
  to: any;
};

let previousFromTo: Range = {
  from: null,
  to: null
};

export function zoomPlugin(): uPlot.Plugin {
  return {
    hooks: {
      setSelect(u: uPlot) {
        if (u.select.width > 5) {
          const min = u.posToVal(u.select.left, "x") * 1000;
          const max = u.posToVal(u.select.left + u.select.width, "x") * 1000;
          const selectedTimeRange = {
            from: min,
            to: max
          };
          const timestamps = u.data[0];

          previousFromTo = selectedTimeRange;
          pushIfNew(selectedTimeRange);
          updateDateRange(selectedTimeRange);
        }
      }
    }
  };
}

// todo
export function doubleClickResetPlugin(setFromTo: any) {
  function init(u: uPlot) {
    u.over.ondblclick = () => {
      lastRanges.length && setFromTo(lastRanges[0]);
      lastRanges.pop();
    };
  }
  return {
    hooks: {
      init: init
    }
  };
}

// todo
const _tooltipPlugin = (): uPlot.Plugin => ({
  hooks: {
    init(u: uPlot, opts: uPlot.Options) {
      void u;
      void opts;
    }
  }
});

export const timeInUTC = (time: any) =>
  new Date(
    new Date(time * 1000).getTime() +
      new Date(time * 1000).getTimezoneOffset() * 60000
  );

function normalizeArrayLength(data: any) {
  const maxLength = Math.max(
    ...data.map((arr: any) => (Array.isArray(arr) ? arr.length : 0))
  );
  return data.map((arr: any) => {
    if (Array.isArray(arr)) {
      while (arr.length < maxLength) {
        arr.push(null);
      }
      return arr;
    } else {
      return arr;
    }
  });
}

function filterTimestamps(
  timestamps: any[] | TypedArray,
  selectedTimeRange: { from: any; to: any }
) {
  return timestamps.filter(
    (timestamp) =>
      timestamp >= selectedTimeRange.from && timestamp <= selectedTimeRange.to
  );
}
