import React from "react";
import { Heading, Flex } from "primitives";
import { HeaderActions } from "./HeaderActions";

export const Header = (props: any) => {
  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" mb={4} mx={3}>
        <Heading display={1}>Passage Manager</Heading>
        <HeaderActions {...props} />
      </Flex>
    </>
  );
};
