import React, { Component } from "react";
import { Form } from "app/shared";
import { Box, Heading, Flex, Text } from "primitives";
import { SuspenseMutation } from "app/network";
import { CancelButton, SaveButton } from "components";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { schemaCreate, uiSchemaCreate } from "../models/schemas";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { createAlertDefinition, getDataSourceLabels } from "../services";
import {
  AlertDefinitionDTO,
  CreateAlertDefinitionCommand,
  SchemaErrors
} from "../models";
import { alertConditionValidator } from "../helpers";
import { DataSource } from "app/telemetry/models/datasources";
import { HTTPResponse } from "app/network/dataProvider/model";

interface AlertDefinitionCreateProps extends RouteComponentProps {
  createAlertDefinition: (
    data: CreateAlertDefinitionCommand
  ) => Promise<AlertDefinitionDTO>;
  datasources: DataSource[];
}

interface AlertDefinitionCreateState {
  formData: CreateAlertDefinitionCommand | null;
}

export class AlertDefinitionCreate extends Component<
  AlertDefinitionCreateProps,
  AlertDefinitionCreateState
> {
  state = {
    formData: null,
    datasource: {}
  };

  onChange(form: AlertDefinitionCreateState) {
    this.setState({ formData: form.formData });
  }

  async submit(
    e: React.FormEvent<HTMLInputElement>,
    formData: CreateAlertDefinitionCommand
  ) {
    e.preventDefault();
    const { data, status } = (await createAlertDefinition(
      formData as any
    )) as HTTPResponse;
    if (status === 201) {
      this.props.history.push("/alert-definitions");
    }
  }

  render() {
    const { formData } = this.state;
    const { datasources } = this.props;

    return (
      <Box color="text.default" data-testid="AlertDefinitionCreate" mx={3}>
        <Flex mb={2}>
          <Heading display={1}>Create Alert Definition</Heading>
        </Flex>
        <Flex flexDirection="column" bg="fill.0" p={3}>
          <SuspenseMutation>
            {({ loading, error, action }) => {
              return (
                <Form
                  validate={(
                    formData: CreateAlertDefinitionCommand,
                    errors: SchemaErrors
                  ) => alertConditionValidator(formData, errors, datasources)}
                  formData={formData}
                  schema={schemaCreate(datasources)}
                  uiSchema={uiSchemaCreate}
                  disabled={loading}
                  onSubmit={(
                    form: { formData: CreateAlertDefinitionCommand },
                    e: React.FormEvent<HTMLInputElement>
                  ) => action(() => this.submit(e, form.formData))}
                  onChange={(form: AlertDefinitionCreateState) =>
                    this.onChange(form)
                  }
                >
                  <CancelButton
                    onClick={() =>
                      this.props.history.push("/alert-definitions")
                    }
                    mr={2}
                  >
                    Cancel
                  </CancelButton>
                  <SaveButton type="submit" disabled={loading}>
                    Save
                  </SaveButton>

                  {error ? (
                    <Box my={2}>
                      <Text color="text.danger">Error...</Text>
                    </Box>
                  ) : null}
                </Form>
              );
            }}
          </SuspenseMutation>
        </Flex>
      </Box>
    );
  }
}

const mapStateToProps = (state: any) => ({
  datasources: getDataSourceLabels(
    state.satellite.dashboard.satelliteDefinition
  )
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
) => ({});

export const AlertDefinitionCreateContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AlertDefinitionCreate)
);
