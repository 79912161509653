import React, { useState } from "react";
import { Form } from "app/shared";
import { Box, Heading, Flex, Text } from "primitives";
import { SuspenseMutation } from "app/network";
import { CancelButton, SaveButton } from "components";
import { useDispatch, useSelector } from "react-redux";
import { ActionType, getSchemas } from "../models/schemas";
import { exportToJson } from "utils/exportAsJson";
import { isValidJSON, processImportPayload, transformDashboardPayload } from "../utils/transformations";
import { FeedbackStatus } from "app/feedback/models";
import { ExtendedDashboard } from "../models";
import { setFeedback } from "app/feedback/actions";
import { RouteComponentProps } from "react-router";
import { editDashboard, createDashboard } from "app/dashboard/services";


export const DashboardImportExport = ({ location, history }: RouteComponentProps) => {
  const dispatch = useDispatch();
  const { state: op } = location;
  const [formData, setFormData] = useState({})
  const dashboards = useSelector((s: any) => s.dashboard.list);
  const satDef = useSelector((s: any) => s.satellite.dashboard.satelliteDefinition);

  const submit = async (e: React.FormEvent<HTMLInputElement>, formData: any, type: string) => {
    e.preventDefault();
    if (type === ActionType.EXPORT) {
      const exportablePayload = transformDashboardPayload(dashboards, formData, satDef)
      exportToJson(exportablePayload, formData.dashboardId)
      return history.push("/dashboard");
    }

    try {
      const importedFormData = isValidJSON(formData.file) && JSON.parse(formData.file);
      const dashboardList = processImportPayload(importedFormData, satDef)

      for (const dashboard in dashboardList) {
        if (dashboard) {
          const existingDashboard = dashboards.filter((d: ExtendedDashboard) => d.name == dashboardList[dashboard].name)
          const id = existingDashboard[0]?.id
          if (id) {
            const { name } = await editDashboard(id, dashboardList[dashboard]);
            name && dispatch(setFeedback(`${name} updated successfully`, FeedbackStatus.SUCCESS));
          } else {
            const { data: { name } } = await createDashboard(dashboardList[dashboard]);
            name && dispatch(setFeedback(`${name} created successfully`, FeedbackStatus.SUCCESS));
          }
        }
      }
    } catch (e: any) {
      dispatch(setFeedback(`Import failed`, FeedbackStatus.ERROR, e.toString()));
    } finally {
      history.push("/dashboard");
    }
  }

  return (
    <Box color="text.default" data-testid="dashboard-import-export" mx={3}>
      <Flex mb={2}>
        <Heading display={1}>Dashboard  {(op as any)?.type}</Heading>
      </Flex>
      <Flex flexDirection="column" bg="fill.0" p={3}>
        <SuspenseMutation>
          {({ loading, error, action }) => {
            return (
              <Form
                formData={formData}
                schema={getSchemas(op, dashboards).schema}
                uiSchema={getSchemas(op, dashboards).uiSchema}
                disabled={loading}
                onSubmit={(
                  form: { formData: any },
                  e: React.FormEvent<HTMLInputElement>
                ) => action(() => submit(e, form.formData, getSchemas(op, dashboards).type))}
                onChange={(form: any) => () => setFormData({ formData: form.formData })}
              >
                <CancelButton
                  onClick={() => history.push("/dashboard")}
                  mr={2}
                  mt={4}
                >
                  Cancel
                </CancelButton>

                <SaveButton ml={2} mt={4} type="submit" disabled={loading}>
                  {(op as any)?.type}
                </SaveButton>

                {error ? (
                  <Box my={2}>
                    <Text color="text.danger">Error...{console.log(error)}</Text>
                  </Box>
                ) : null}
              </Form>
            );
          }}
        </SuspenseMutation>
      </Flex>
    </Box>
  );
}
