import React, { FC } from "react";
import { CancelButton, InputField, DeleteButton, CheckBox } from "components";
import { Box } from "primitives";
import { ResourceBase } from "../models";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "primitives";

interface ResourcePropertiesModalProps {
  selectedResource: ResourceBase | null;
  modalOpen: boolean;
  closeModal: () => void;
  onResourceDelete: (
    selectedResource: ResourceBase,
    isLastResource: boolean,
    isPermanentDelete: boolean,
    reload: any
  ) => void;
  isLastResource: boolean;
  isPermanentDelete: boolean;
  onPermanentDelete: () => void;
  reload: any;
}

const DeleteModal: FC<ResourcePropertiesModalProps> = (props) => {
  // eslint-disable-next-line no-unused-vars
  const {
    modalOpen,
    closeModal,
    selectedResource,
    onResourceDelete,
    isLastResource,
    reload,
    onPermanentDelete,
    isPermanentDelete
  } = props;

  if (!selectedResource) return null;

  return (
    <Dialog open={modalOpen} maxWidth="md" overflow="hidden">
      <DialogTitle>Delete Resource</DialogTitle>
      <DialogContent>
        <form>
          <Box mb={2}>
            <InputField
              id="id"
              value={selectedResource.id}
              label="Id"
              disabled={true}
            />
          </Box>
          <Box mb={2}>
            <InputField
              id="name"
              value={`${
                selectedResource.dir === "."
                  ? ""
                  : `${selectedResource.dir.indexOf("/") === 0 ? "" : "/"}${
                      selectedResource.dir
                    }`
              }/${selectedResource.name}`}
              label="Name"
              disabled={true}
            />
          </Box>
          <Box mb={2}>
            <CheckBox
              checked={isPermanentDelete}
              label={"Delete Permanently"}
              onChange={() => onPermanentDelete()}
            />
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <DeleteButton
          onClick={() => {
            onResourceDelete(
              selectedResource,
              isLastResource,
              isPermanentDelete,
              reload
            );
            closeModal();
          }}
          mx={1}
        >
          Delete
        </DeleteButton>
        <CancelButton onClick={() => closeModal()} mx={1}>
          Cancel
        </CancelButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
