import styled from "styled-components";
import Area from "./Area";

const Content = styled(Area)`
  overflow: hidden;
  background-image: radial-gradient(rgb(128, 128, 128, 0.3) 1px, transparent 0);
  background-size: 12px 12px;
  background-position: -40px -40px;
  &:hover {
    ${(props) => `overflow: ${props.overflow || "auto"};`}
  }
`;

Content.defaultProps = {
  gridArea: "content"
};

Content.defaultProps = {
  bg: "fill.2",
  color: "text.white"
};

export default Content;
