import { connect } from "react-redux";
import { Dashboard } from "../components/Dashboard";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state) => ({
  selectedSatellite: state.constellations.dashboard.find(
    (satellite) => satellite.visible
  )
});

const mapDispatchToProps = () => {
  return {};
};

export const DashboardContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Dashboard)
);
