import { TimeControllerActionTypes, ITimeController } from "../models/index";
import {
  subtractTime,
  getDateRangeDiff,
  decrementDate,
  incrementDate
} from "../utils";

export const {
  HIDE_TIME_CONTROLLER,
  UNHIDE_TIME_CONTROLLER,
  SET_REFRESH_INTERVAL,
  SET_TIME_REFERENCE,
  SET_TIME_RANGE,
  SET_FROM_TO,
  RESET_TIME_CONTROLLER,
  SET_WINDOW_SIZE,
  ZOOM_OUT,
  ZOOM_IN,
  PREV_RANGE,
  NEXT_RANGE
} = TimeControllerActionTypes;

interface ITimeRange {
  from: number;
  to: number;
}

export const initialState = {
  refreshInterval: 5000,
  quickRange: "now-1m",
  from: subtractTime(new Date(Date.now()), 5, "m").toISOString(),
  to: new Date(Date.now()).toISOString(),
  timeReference: "satelliteUtc",
  timeUnit: "Seconds",
  windowSize: 1,
  showTimeController: true
};

export const timeControllerReducer = (
  state = initialState,
  action: { type?: string; payload?: string | number | ITimeRange }
): ITimeController => {
  switch (action.type) {
    case HIDE_TIME_CONTROLLER:
      return { ...initialState, showTimeController: false };
    case UNHIDE_TIME_CONTROLLER:
      return initialState;
    case SET_REFRESH_INTERVAL:
      return { ...state, refreshInterval: action.payload as number };
    case SET_TIME_REFERENCE:
      return { ...state, timeReference: action.payload as string };
    case SET_TIME_RANGE: {
      const { payload } = action;
      const fromValue = (payload as string).slice(
        (payload as string).indexOf("-") + 1,
        (payload as string).length - 1
      );
      const unit = (payload as string).slice(-1);

      const quickRange = payload as string;
      const from = subtractTime(
        new Date(Date.now()),
        Number(fromValue),
        unit
      ).toISOString();
      const to = new Date(Date.now()).toISOString();
      if (
        from !== state.from ||
        to !== state.to ||
        quickRange !== state.quickRange
      ) {
        return {
          ...state,
          quickRange,
          from,
          to,
          // NOTE: if date range is longer than 24hrs, increase the refresh interval to 1 min to prevent blocking ui
          refreshInterval: (payload as string).includes("d")
            ? 60000
            : state.refreshInterval
        };
      } else {
        return state;
      }
    }
    case SET_FROM_TO:
      return {
        ...state,
        from: new Date((action.payload as ITimeRange).from).toISOString(),
        to: new Date((action.payload as ITimeRange).to).toISOString(),
        quickRange: ""
      };
    case RESET_TIME_CONTROLLER:
      return {
        ...state,
        ...initialState,
        showTimeController: state.showTimeController
      };
    case SET_WINDOW_SIZE:
      return {
        ...state,
        windowSize: action.payload as number
      };
    case ZOOM_OUT:
      const dateDiff = getDateRangeDiff(state);
      return {
        ...state,
        from: decrementDate(state.from, dateDiff),
        to: incrementDate(state.to, dateDiff),
        quickRange: ""
      };

    case ZOOM_IN:
      const fromDate = new Date(state.from);
      const toDate = new Date(state.to);
      const midDate = new Date((fromDate.getTime() + toDate.getTime()) / 2);

      return {
        ...state,
        from: new Date(
          fromDate.getTime() + (midDate.getTime() - fromDate.getTime()) / 2
        ).toISOString(),
        to: new Date(
          toDate.getTime() - (toDate.getTime() - midDate.getTime()) / 2
        ).toISOString(),
        quickRange: ""
      };
    case NEXT_RANGE:
      return {
        ...state,
        from: state.to,
        to: incrementDate(state.to, getDateRangeDiff(state)),
        quickRange: ""
      };
    case PREV_RANGE:
      return {
        ...state,
        from: decrementDate(state.from, getDateRangeDiff(state)),
        to: state.from,
        quickRange: ""
      };
    default:
      return state;
  }
};
