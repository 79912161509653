import { connect } from "react-redux";
import { SystemDefinitionEdit } from "../components/SystemDefinitionEdit";
import { setFeedback } from "app/feedback/actions";

const mapStateToProps = (state) => ({
  unitsList: state.dataSources.units
});

const mapDispatchToProps = (dispatch) => {
  return {
    setFeedback: (title, status, details = "") =>
      dispatch(setFeedback(title, status, details))
  };
};

export const SystemDefinitionEditContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemDefinitionEdit);
