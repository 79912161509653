import { DashboardComponents } from "app/dashboard/models";

/**
 * Checks if component types matches with datasources data type.
 * @param {*} formData
 * @param {*} errors
 * @param {*} dataSources
 */
export const validateDataSources = (formData, errors, dataSources) => {
  if (formData && formData.components) {
    formData.components.forEach((element, i) => {
      const component = DashboardComponents[element.type];
      if (component && element.dataSources) {
        element.dataSources.forEach((dataSourceIdLabel, j) => {
          if (dataSourceIdLabel) {
            const errorMessages = [];
            const dataSource = dataSources.find(
              (it) => it.id === dataSourceIdLabel.datasourceId
            );

            if (dataSource) {
              if (
                Array.isArray(component.allowedDataTypes) &&
                !component.allowedDataTypes.includes(dataSource.tmDataType.type)
              ) {
                errorMessages.push(
                  `Type ${dataSource.tmDataType.type} not allowed in ${element.type}`
                );
              }
            } else {
              errorMessages.push(
                `Datasource ${dataSourceIdLabel.datasourceId} not found in state`
              );
            }

            errorMessages.forEach((error) =>
              errors.components[i].dataSources[j].addError(error)
            );
          }
        });
      }
    });
  }
};

/**
 * Validates if components position/size are correctly inside dashboard size,
 * and do not collide with each other.
 * @param {*} formData
 * @param {*} errors
 */
export const validateGridSize = (formData, errors) => {
  if (formData && formData.components && formData.size) {
    const parentSizeRow = formData.size.row;
    const parentSizeCol = formData.size.col;
    const grid = Array(parentSizeRow)
      .fill(null)
      .map(() => Array(parentSizeCol).fill(0));

    formData.components.forEach((element, index) => {
      if (element.position.row <= 0 || element.position.col <= 0) {
        errors.components[index].position.addError(
          `Position row/col must be bigger than 0`
        );
      }

      if (element.size.row <= 0 || element.size.col <= 0) {
        errors.components[index].size.addError(
          `Size row/col must be bigger than 0`
        );
      }

      // Check if they do not collide
      const positionBeginRow = element.position.row - 1; // - 1 because first position begins in 0
      const positionBeginCol = element.position.col - 1; // - 1 because first position begins in 0
      const positionEndRow = positionBeginRow + element.size.row;
      const positionEndCol = positionBeginCol + element.size.col;
      let i = 0,
        j = 0;

      try {
        for (i = positionBeginRow; i < positionEndRow; i++) {
          for (j = positionBeginCol; j < positionEndCol; j++) {
            if (grid[i][j] === 0) {
              grid[i][j] = 1;
            } else {
              throw new Error();
            }
          }
        }
      } catch (e) {
        errors.components[index].size.addError(
          `Problem with component in position [${i + 1}][${j + 1}]`
        );
      }
    });
  }
};

/**
 * This method will do custom validations in formData.
 * 1) If a dataSource is not compatible with the component type
 * an error will be presented.
 * @param {*} formData
 * @param {*} errors
 * @param {*} dataSources
 */
export const validateFormData = (formData, errors, dataSources) => {
  validateDataSources(formData, errors, dataSources);
  validateGridSize(formData, errors);

  return errors;
};

// use mock worker during testing
export const isBrowser = typeof window !== "undefined" && window.Worker;
