import React from "react";
import { deleteSatelliteDefinition } from "../services";
import { SuspenseMutation } from "app/network";
import { Toggler } from "components";
import { DeleteButton, CancelButton } from "components";
import { Flex, Dialog, DialogTitle, DialogActions } from "primitives";

export const SatelliteDefinitionDelete = ({ record, onChange }) => {
  const submit = (onToggle) => {
    return deleteSatelliteDefinition(record).then(() => {
      if (onChange) {
        onChange();
      }
      onToggle();
    });
  };

  return (
    <Toggler>
      {([toggled, onToggle]) => (
        <>
          <DeleteButton onClick={onToggle}>Delete</DeleteButton>

          <Dialog
            open={toggled}
            maxWidth="sm"
            data-testid="SatelliteDefinitionDelete"
          >
            <SuspenseMutation>
              {({ loading, action }) => {
                return (
                  <>
                    <DialogTitle>Delete {record.name}?</DialogTitle>

                    <DialogActions>
                      <Flex
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                      >
                        <DeleteButton
                          onClick={() => action(() => submit(onToggle))}
                          mr={1}
                          disabled={loading}
                        >
                          Delete
                        </DeleteButton>
                        <CancelButton onClick={onToggle} mr={1}>
                          Cancel
                        </CancelButton>
                      </Flex>
                    </DialogActions>
                  </>
                );
              }}
            </SuspenseMutation>
          </Dialog>
        </>
      )}
    </Toggler>
  );
};
