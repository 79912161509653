import { connect } from "react-redux";
import { DashboardCreate } from "../components/DashboardCreate";
import { createDashboard } from "../services";
import { getSatelliteDefinition } from "app/satelliteDefinition/services";
import { withRouter } from "react-router-dom";
import { getSatelliteDefinitions } from "app/satellite/services";
import { setFeedback } from "app/feedback/actions";

const mapStateToProps = (state) => ({
  satellite: state.satellite.dashboard.satelliteDefinition,
  selectedDataSources: state.satellite.dashboard.selectedDataSources,
  constellations: state.constellations.list
});

const mapDispatchToProps = (dispatch) => {
  return {
    createDashboard: (dashboardDefinition) =>
      createDashboard(dashboardDefinition),
    getSatelliteDefinition: (id) => getSatelliteDefinition(id),
    getSatelliteDefinitions: () => getSatelliteDefinitions(null),
    setFeedback: (title, status, details = "") =>
      dispatch(setFeedback(title, status, details))
  };
};

export const DashboardCreateContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DashboardCreate)
);
