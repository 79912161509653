import React from "react";
import {
  SessionKey,
  KeyStatus,
  MasterKey,
  ChangeStateDialog,
  IContextActions,
  ContextActionsNames,
  KeyTypes
} from "../models";

export const handleInjectSessionKey = (
  e: React.MouseEvent,
  key: SessionKey,
  contextDispatcher: React.Dispatch<IContextActions>
) => {
  e.stopPropagation();
  const payload: Omit<ChangeStateDialog, "state"> = {
    key,
    isOpen: true,
    setOpen: contextDispatcher
  };
  contextDispatcher({
    type: ContextActionsNames.injectSessionKeyDialog,
    payload
  });
};

export const masterKeyStateManager = (
  masterKey: string,
  masterKeys: MasterKey[],
  status: KeyStatus
): MasterKey[] => {
  switch (status) {
    case KeyStatus.ACTIVE:
      return masterKeys.map((mk) => {
        return (
          (mk.key_id === masterKey && {
            ...mk,
            status
          }) ||
          mk
        );
      });

    case KeyStatus.DESTROYED:
      return masterKeys.filter((mk) => mk.key_id !== masterKey);
    default:
      return masterKeys;
  }
};

export const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

export const handleKeyStateDialog = (
  e: React.MouseEvent,
  key: MasterKey | SessionKey,
  state: KeyStatus,
  contextDispatcher: React.Dispatch<IContextActions>
) => {
  e.stopPropagation();
  const payload: ChangeStateDialog = {
    key,
    isOpen: true,
    setOpen: contextDispatcher,
    state
  };
  contextDispatcher({ type: ContextActionsNames.changeStateDialog, payload });
};

/**
 * Type guards
 */

export const isSessionKey = (obj: any): obj is SessionKey =>
  obj.key_type === KeyTypes.session;

export const isMasterKey = (obj: any): obj is MasterKey =>
  obj.key_type === KeyTypes.master;
