import { FETCH_ALERT_COUNT_SUCCESS, FETCH_ALERT_COUNT_ERROR } from './../models/constants';
import { FETCH_ALERT_COUNT_START } from "../models/constants";

const initialState = {};

/**
{
    numCritical: 0,
    numWarnings: 0,
    numOthers: 0,
},

*/

export const alertCounterReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_ALERT_COUNT_START:
            return state;
        case FETCH_ALERT_COUNT_ERROR:
            return initialState;
        case FETCH_ALERT_COUNT_SUCCESS:
            return action.payload

        default:
        return state;
    }
};