import { InputBase } from "app/shared";

export const schemaCreate = {
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Name"
    },
    classes: {
      type: "array",
      hasRemove: true,
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
            title: "Name"
          }
        }
      }
    }
  }
};

export const uiSchemaCreate = {
  name: { "ui:widget": InputBase },
  classes: {
    items: {
      name: { "ui:widget": InputBase }
    }
  }
};

export const schemaEdit = {
  type: "object",
  properties: {
    id: {
      type: "number",
      title: "Id",
      kind: "constant"
    },
    name: {
      type: "string",
      title: "Name"
    },
    classes: {
      type: "array",
      hasRemove: true,
      items: {
        type: "object",
        properties: {
          id: {
            type: "number"
          },
          name: {
            type: "string",
            title: "Name"
          }
        }
      }
    }
  }
};

export const uiSchemaEdit = {
  id: { "ui:widget": InputBase },
  name: { "ui:widget": InputBase },
  classes: {
    items: {
      id: { "ui:widget": "hidden" },
      name: { "ui:widget": InputBase }
    }
  }
};
