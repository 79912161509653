import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgMinus = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    width={size || width}
    height={size || height}
    viewBox="0 0 42 10"
    color={color}
  >
    <path
      d="M37.059 0H4.941C2.224 0 0 2.282 0 5s2.224 5 4.941 5H37.059C39.776 10 42 7.718 42 5s-2.224-5-4.941-5z"
      fillRule="evenodd"
    />
  </Svg>
);

SvgMinus.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgMinus;
