import { telemetryDataProvider } from "app/network/dataProvider";
import { GET_ONE } from "app/network/models/constants";

export const fetchFileDownloadLink = (satelliteId, fileId, params = {}) => {
  return telemetryDataProvider(
    GET_ONE,
    `satellite/${satelliteId}/download/${fileId}`
  )
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};
