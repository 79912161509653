import React, { useContext } from "react";
import { Flex, Button, Box, Icon, Text } from "primitives";
import { DragHandle } from "components";
import { VisibiltyWindowContext } from "app/visibilityWindow/context";
import { IWidgetHeader } from "app/visibilityWindow/models";
import { RefreshButton } from "app/shared/timeController/components/styledComponents/index";
import {
  ALL_GROUND_STATIONS,
  InitialValue
} from "app/visibilityWindow/helpers";

const WidgetHeader = ({ options, showModal, type, id }: IWidgetHeader) => {
  const { state, dispatch } = useContext(VisibiltyWindowContext);
  const { showCalendar, loading, resetting } = state;
  const viewButtonLabel = showCalendar ? "Table View" : "Calendar View";

  const handleResetTimeRange = () => {
    // Unmounting the timeline
    dispatch({
      type: "resetting",
      payload: true
    });
    // Remount the timeline and resetting the time range to redraw also the table
    setTimeout(() => {
      dispatch({
        type: "default",
        payload: {
          resetting: false,
          selectedGroundStation: ALL_GROUND_STATIONS,
          begin: new Date(InitialValue.begin),
          end: new Date(InitialValue.end)
        }
      });
    });
  };

  return (
    <Flex mb={2} overflow="visible">
      <Flex alignItems="center" flex={1} mt={1} overflow="visible">
        {options.label && (
          <Text fontSize={18} bold>
            {options.label}
          </Text>
        )}
        {!showCalendar && <DragHandle topLabel={43} left={12} absolute label />}
      </Flex>
      <RefreshButton
        id="refreshTimeline"
        data-testid="refreshTimeline"
        mr={2}
        title="Refresh timeline"
        onClick={handleResetTimeRange}
        overflow="visible"
      >
        <Icon
          size={16}
          name="Refresh"
          animate={loading || resetting ? "spin" : "static"}
          dataTestId="timeline-spinner"
        />
      </RefreshButton>
      <Button
        onClick={() =>
          dispatch({ type: "showCalendar", payload: !showCalendar })
        }
        mr={5}
        size="small"
      >
        {viewButtonLabel}
      </Button>
      <Box cursor="pointer" pr={3} onClick={() => showModal(type, id)}>
        <Icon name={"SettingsMenu"} size={25} id="settings-gear" />
      </Box>
    </Flex>
  );
};

export { WidgetHeader };
