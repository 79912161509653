import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Flex, Icon, Text, Absolute, Box, Button } from "primitives";
import { Telemetry } from "app/dataSource";
import config from "config/constants";
import { DragHandle } from "components";
import { isEqual } from "lodash";
import { LineChartData } from "./LineChartData";
import { LineChartDataViewProps } from "./models";
import {
  setQuickRange,
  setWindowSize
} from "app/shared/timeController/actions";

const DEFAULT_WINDOW_SIZE = 500;

const LineChartDataViewBase = ({
  satellite,
  ids,
  options,
  type,
  refreshData,
  showModal,
  id,
  timeController,
  // eslint-disable-next-line no-shadow
  setQuickRange,
  // eslint-disable-next-line no-shadow
  setWindowSize
}: LineChartDataViewProps) => {
  const { timeReference, dataSources, liveStream, updatePeriod } = options;
  const DEFAULT_FROM =
    (liveStream && liveStream.from ? liveStream.from : 15) * 60;
  const [advancedMode, setAdvancedMode] = useState(false);
  const [telemetryPaused, setTelemetryPaused] = useState(false);
  const [firstFetch, setFirstFetch] = useState(true);
  const [defaultFrom, setDefaultFrom] = useState(DEFAULT_FROM);

  const interval = updatePeriod ? updatePeriod : config.timer.graph;
  const [refresh, setRefresh] = useState(false);
  const optionsDSRef = useRef(dataSources);

  const quickRange = timeController && timeController.quickRange;
  const windowSize = timeController && timeController.windowSize;

  useEffect(() => {
    if (!isEqual(optionsDSRef.current, dataSources)) {
      setRefresh(true);
      optionsDSRef.current = dataSources;
      setTelemetryPaused(false);
      setAdvancedMode(false);
    }
  }, [dataSources]);

  useEffect(() => {
    if (refresh) setRefresh(false);
  }, [refresh]);

  useEffect(() => {
    const isLiveStream = options.isGloballyControlled
      ? Boolean(quickRange)
      : !timeReference.to;
    if (!firstFetch && isLiveStream) {
      const timeout = setTimeout(
        () => setDefaultFrom(interval / 1000 + 5),
        interval
      );
      return clearTimeout(timeout);
    }
  }, [firstFetch, interval, timeReference.to, quickRange]);

  useEffect(() => {
    if (refreshData && !telemetryPaused) {
      setDefaultFrom(DEFAULT_FROM);
      if (!firstFetch) {
        setFirstFetch(true);
        if (quickRange) setQuickRange(quickRange);
        setWindowSize(5000);
      }
    }
  }, [telemetryPaused, refreshData, DEFAULT_FROM]);

  useEffect(() => {
    if (windowSize === 5000) {
      setWindowSize(1);
    }
  }, [windowSize]);

  return refresh ? null : (
    <>
      <Flex
        justifyContent="space-between"
        overflow="visible"
        position="relative"
      >
        <DragHandle absolute label topLabel={7.5} />
        <Absolute width={"100%"} zIndex={1} display="flex" px={2}>
          {options.label && (
            <Text ml={4} fontSize={18} my={1}>
              {options.label}
            </Text>
          )}
          <Button
            id="Chart"
            data-testid="Chart"
            size="small"
            ml="auto"
            onClick={() => setAdvancedMode(!advancedMode)}
            color={!advancedMode ? "" : "#fff"}
            maxHeight={30}
          >
            <Icon name={"Chart"} size={20} />
          </Button>
          <Box
            cursor="pointer"
            ml={2}
            mt={1}
            onClick={() => showModal(type, id)}
            overflow="visible"
          >
            <Icon name={"SettingsMenu"} size={20} />
          </Box>
        </Absolute>
      </Flex>
      <Telemetry
        satellite={satellite}
        ids={ids}
        interval={interval}
        params={{
          windowSize: DEFAULT_WINDOW_SIZE,
          from: `${defaultFrom}`,
          to: `0`,
          timeUnit: "Seconds"
        }}
        options={options}
        autoUpdates={!telemetryPaused}
      >
        {(data: any) => {
          return (
            <>
              <LineChartData
                {...data}
                firstFetch={firstFetch}
                setFirstFetch={setFirstFetch}
                options={options}
                advancedMode={advancedMode}
                setPaused={(paused: boolean) => setTelemetryPaused(paused)}
                timeController={timeController}
                id={id}
              />
            </>
          );
        }}
      </Telemetry>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  refreshData: state.app.refreshData,
  timeController: state.datastore.timeController
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    setQuickRange: (payload: string | Date) => dispatch(setQuickRange(payload)),
    setWindowSize: (payload: number) => dispatch(setWindowSize(payload))
  };
};

export const LineChart = connect(
  mapStateToProps,
  mapDispatchToProps
)(LineChartDataViewBase);
