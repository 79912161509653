import { useState, useCallback } from "react";

interface UseMutation {
  loading: boolean;
  response: any;
  error: any;
  action: (mutation: () => Promise<any>) => void;
}

export const useMutation = (): UseMutation => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  const action = useCallback((mutation: () => Promise<any>) => {
    setLoading(true);
    setError(null);

    mutation()
      .then((res) => {
        setResponse(res);
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => setLoading(false));
  }, []);

  return { loading, response, error, action };
};
