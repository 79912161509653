import {
  SET_REFRESH_DATA_TRUE,
  SET_REFRESH_DATA_FALSE
} from "../models/constants";

const initialState = true;

const RefreshDataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_REFRESH_DATA_TRUE:
      return true;
    case SET_REFRESH_DATA_FALSE:
      return false;
    default:
      return state;
  }
};

export default RefreshDataReducer;
