import React, { Component, ChangeEvent } from "react";
import {
  SaveButton,
  CancelButton,
  InputField,
  EditButton,
  CommentButton
} from "components";
import {
  Flex,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from "primitives";
import { tcStack } from "app/telecommandStack/actions";

interface AddCommentToStackProps {
  comment?: string;
  type?: string;
  id?: string;
  disabled: boolean;
}

interface AddCommentToStackState {
  modalOpen: boolean;
  comment?: string;
}

export class AddCommentToStack extends Component<
  AddCommentToStackProps,
  AddCommentToStackState
> {
  state = {
    modalOpen: false,
    comment: this.props.comment || ""
  };

  hideDialog = () => {
    this.setState({ modalOpen: false });
  };

  openDialog = () => {
    this.setState({ modalOpen: true });
  };

  onChangeLabel(
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) {
    const { value } = event.currentTarget;
    this.setState({ comment: value });
  }

  handleSubmit = (e: any) => {
    e.preventDefault();
    tcStack.comment(this.state.comment, this.props.type, this.props.id);
    this.hideDialog();
  };

  render() {
    const { modalOpen } = this.state;

    return (
      <>
        {this.props.type === "edit" ? (
          <EditButton
            size="small"
            disabled={this.props.disabled}
            ml={2}
            onClick={() => this.openDialog()}
          />
        ) : (
          <CommentButton
            size="medium"
            disabled={this.props.disabled}
            ml={2}
            onClick={() => this.openDialog()}
          ></CommentButton>
        )}

        <Dialog
          open={modalOpen}
          maxWidth="sm"
          data-testid="add-comment-to-stack"
        >
          <DialogTitle>Add Comment to Stack </DialogTitle>
          <DialogContent>
            <form
              onSubmit={(e) => this.handleSubmit(e)}
              id="add-comment-to-stack"
            >
              <Box mb={2}>
                <InputField
                  id="label"
                  required={true}
                  defaultValue={
                    (this.props.comment && this.props.comment) || ""
                  }
                  label="Text"
                  onChange={(e) => this.onChangeLabel(e)}
                />
              </Box>
            </form>
          </DialogContent>
          <DialogActions>
            <Flex
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <SaveButton type="submit" form="add-comment-to-stack" mr={1}>
                Save
              </SaveButton>
              <CancelButton onClick={() => this.hideDialog()}>
                Cancel
              </CancelButton>
            </Flex>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
