import React, { useState } from "react";
import { Form } from "app/shared";
import { Box } from "primitives";
import { SuspenseMutation } from "app/network";
import { schemaDeleteAll, uiSchemaDeleteAll } from "../models/schemas";
import { Heading, Flex, Text } from "primitives";
import { CancelButton, DeleteButton } from "components";
import { useSelector, useDispatch } from "react-redux";
import { Options } from "../models";
import { SET_ALERT_DEFINITIONS } from "../models/constants";
import { deleteAllAlertsDefinitions } from "../services";
import { HTTPResponse } from "app/network/dataProvider/model";
import { setFeedback } from "app/feedback/actions";
import { FeedbackStatus } from "app/feedback/models";

export const AlertDefinitionDeleteAll = (props: any) => {
  const [formData, setFormData] = useState<any>({});
  const dispatch = useDispatch();
  const satDefId = useSelector(
    (s: any) => s.satellite.dashboard.satelliteDefinition.id
  );

  const submit = async (
    e: React.FormEvent<HTMLInputElement>,
    formData: any
  ) => {
    e.preventDefault();
    dispatch(deleteAllAlertDefinitions(satDefId));
    dispatch(setFeedback("Deletion requested sent", FeedbackStatus.SUCCESS));
    return props.history.push("/alert-definitions");
  };

  return (
    <Box color="text.default" data-testid="alert-definition-export" mx={3}>
      <Flex mb={2}>
        <Heading display={1}>Alert Definition Delete All</Heading>
      </Flex>
      <Flex flexDirection="column" bg="fill.0" p={3}>
        <SuspenseMutation>
          {({ loading, error, action }) => {
            return (
              <Form
                formData={formData}
                schema={schemaDeleteAll}
                uiSchema={uiSchemaDeleteAll}
                disabled={loading}
                onSubmit={(
                  form: { formData: any },
                  e: React.FormEvent<HTMLInputElement>
                ) => action(() => submit(e, form.formData))}
                onChange={({ formData }: any) => {
                  return setFormData(formData);
                }}
              >
                <CancelButton
                  onClick={() => props.history.push("/alert-definitions")}
                  mr={2}
                  mt={4}
                >
                  Cancel
                </CancelButton>

                <DeleteButton
                  ml={2}
                  mt={4}
                  type="submit"
                  disabled={loading || !formData?.confirmDeleteAll}
                >
                  Delete All
                </DeleteButton>

                {error ? (
                  <Box my={2}>
                    <Text color="text.danger">
                      Error...{console.log(error)}
                    </Text>
                  </Box>
                ) : null}
              </Form>
            );
          }}
        </SuspenseMutation>
      </Flex>
    </Box>
  );
};

export const deleteAllAlertDefinitions = (satDefId: number): any => {
  return async (dispatch: any) => {
    try {
      const { data } = (await deleteAllAlertsDefinitions(
        satDefId
      )) as HTTPResponse;
      dispatch({
        type: SET_ALERT_DEFINITIONS,
        payload: data
      });
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  };
};
