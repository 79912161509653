import React, { Component } from "react";
import { SatelliteTMDashboard } from "app/satellite";

export class Dashboard extends Component {
  render() {
    const { selectedSatellite } = this.props;
    if (selectedSatellite) {
      return <SatelliteTMDashboard satellite={selectedSatellite} />;
    } else {
      return null;
    }
  }
}
