import React, { useCallback, useState } from "react";
import { Box } from "primitives";
import { Table, TableHead, TableBody, TableRow, TableCell } from "components";
import { Link } from "react-router-dom";
import { PaginationSimple } from "app/shared";
import { DownloadButton, EditButton } from "components";
import { SuspenseQuery, Suspense, LoadingError } from "app/network";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { TelecommandStackDeleteContainer } from "./TelecommandStackDelete";
import { getTelecommandStacks } from "app/telecommandStack/actions";
import { TelecommandStack } from "app/telecommandStack/models";
import orderBy from "lodash/orderBy";
import { TelecommandStackListHeader } from "./TelecommandStackListHeader";
import { exportToJson } from "utils/exportAsJson";

interface TelecommandStackListProps {
  fetchTelecommandStacks: () => Promise<TelecommandStack[]>;
}

export const TelecommandStackList = (props: TelecommandStackListProps) => {
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [selectedSatelliteId, setSelectedSatelliteId] = useState<number | null>(
    null
  );
  const [total, setTotal] = useState(0);

  const filterTelecommandStacks = (telecommandStacks: TelecommandStack[]) => {
    if (!Array.isArray(telecommandStacks)) return [];
    const minIndex = page * pageSize;
    const maxIndex = minIndex + pageSize;
    const result = telecommandStacks
      .sort()
      .filter((tcStack: any) => tcStack.satelliteId === selectedSatelliteId);

    if (result.length !== total) {
      setTotal(result.length);
    }
    return result.slice(minIndex, maxIndex);
  };

  const selectSatellite = (value: number) => {
    setSelectedSatelliteId(value);
    setTotal(0);
    setPage(0);
  };

  const selectFieldsForExport = (tcStack: TelecommandStack) => {
    return {
      name: tcStack.name,
      satelliteId: tcStack.satelliteId,
      description: tcStack.description,
      telecommandList: [...tcStack.telecommandList.map(({ id, ...tc }) => tc)]
    };
  };

  const query = useCallback(() => props.fetchTelecommandStacks(), []);

  interface ErrorProps {
    reload: () => void;
    error: { status: number };
  }
  const Error = ({ reload, error }: ErrorProps) => {
    reload();
    return <LoadingError httpErrorStatus={error.status} />;
  };

  return (
    <>
      <TelecommandStackListHeader
        selectSatellite={selectSatellite}
        selectedSatelliteId={selectedSatelliteId}
      />
      <Suspense>
        <SuspenseQuery query={query} errorFallback={true}>
          {({ response, reload, error }) => {
            const telecommandStacks = filterTelecommandStacks(
              response ? response : []
            );

            return error ? (
              <Error reload={reload} error={error} />
            ) : (
              <Box data-testid="TelecommandStackList" mx={3}>
                <Table>
                  <TableHead>
                    <TableRow bg="fill.0">
                      <TableCell width="200">Name</TableCell>
                      <TableCell width="10">Satellite ID</TableCell>
                      <TableCell width="20">Telecommands</TableCell>
                      <TableCell width="auto">Description</TableCell>
                      <TableCell width="20">Created at</TableCell>
                      <TableCell width="20">Updated at</TableCell>
                      <TableCell width="50">Last Status</TableCell>
                      <TableCell width="50" />
                      <TableCell width="50" />
                      <TableCell width="50" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderBy(
                      telecommandStacks,
                      ["createdAt", "updatedAt"],
                      ["desc", "desc"]
                    ).map((telecommandStack: any) => (
                      <TableRow key={telecommandStack.id}>
                        <TableCell>{telecommandStack.name}</TableCell>
                        <TableCell>
                          {telecommandStack.satelliteId &&
                            telecommandStack.satelliteId}
                        </TableCell>
                        <TableCell>
                          {telecommandStack.telecommandList &&
                            telecommandStack.telecommandList.length}
                        </TableCell>
                        <TableCell>{telecommandStack.description}</TableCell>

                        <TableCell>
                          {telecommandStack.createdAt &&
                            new Date(
                              telecommandStack.createdAt
                            ).toLocaleString()}
                        </TableCell>
                        <TableCell>
                          {telecommandStack.updatedAt &&
                            new Date(
                              telecommandStack.updatedAt
                            ).toLocaleString()}
                        </TableCell>
                        <TableCell>
                          {telecommandStack.status !== "null" &&
                            telecommandStack.status}
                        </TableCell>
                        <TableCell>
                          <DownloadButton
                            onClick={() =>
                              exportToJson(
                                selectFieldsForExport(telecommandStack)
                              )
                            }
                          >
                            Export
                          </DownloadButton>
                        </TableCell>
                        <TableCell>
                          <Link
                            to={`/telecommandstacks/${telecommandStack.id}`}
                          >
                            <EditButton>Edit</EditButton>
                          </Link>
                        </TableCell>
                        <TableCell>
                          <TelecommandStackDeleteContainer
                            telecommandStack={telecommandStack}
                            onChange={() => reload()}
                            deleteTelecommandStack={function (
                              id: any
                            ): Promise<any> {
                              console.error("Function not implemented.");
                              return id;
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <PaginationSimple
                  total={total}
                  page={page + 1}
                  pageSize={pageSize}
                  nextPageUrl={(page + 1) * pageSize < total ? page + 1 : 0}
                  previousPageUrl={page - 1}
                  onChange={(newPage: string | number) => {
                    setPage(Number(newPage));
                    setPageSize(pageSize);
                  }}
                  onPageSizeChange={(newPageSize: number) => {
                    setPage(0);
                    setPageSize(Number(newPageSize));
                  }}
                />
              </Box>
            );
          }}
        </SuspenseQuery>
      </Suspense>
    </>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  fetchTelecommandStacks: () => dispatch(getTelecommandStacks())
});

export const TelecommandStackListContainer = connect(
  null,
  mapDispatchToProps
)(TelecommandStackList);
