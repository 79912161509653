import {
  FETCH_READING_END,
  FETCH_READING_START
} from "app/dataSource/models/constants";
import { FetchingStatus } from "app/shared/timeController/models";

export const initialState = "idle";

export const fetchStatusReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_READING_START:
      return FetchingStatus.fetching;
    case FETCH_READING_END:
      return FetchingStatus.idle;
    default:
      return state;
  }
};
