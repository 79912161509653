import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgSave = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    height={size || height}
    viewBox="0 0 512 512"
    width={size || width}
    color={color}
  >
    <path d="M486.996 64.383l-39.379-39.38A85.226 85.226 0 0 0 387.285 0H58.668C26.262 0 0 26.262 0 58.668v394.664C0 485.738 26.262 512 58.668 512h394.664C485.738 512 512 485.738 512 453.332V124.715a85.226 85.226 0 0 0-25.004-60.332zM85.332 133.332V80c0-8.832 7.168-16 16-16h224c8.832 0 16 7.168 16 16v53.332c0 8.832-7.168 16-16 16h-224c-8.832 0-16-7.168-16-16zM256 426.668c-53.012 0-96-42.988-96-96 0-53.016 42.988-96 96-96s96 42.984 96 96c0 53.012-42.988 96-96 96zm0 0" />
  </Svg>
);

SvgSave.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgSave;
