import { Resource } from "../models";
import { Dispatch } from "react";
import { ThunkAction } from "redux-thunk";
import { fetchResources } from "../services";

export enum ResourceActionType {
  FetchResources = "FETCH_RESOURCES"
}

export interface FetchResourcesAction {
  type: ResourceActionType.FetchResources;
  payload: Resource[];
}

export type ResourceActions = FetchResourcesAction;

export type FetchResourcesActionResult = ThunkAction<
  Promise<Resource[]>,
  {},
  {},
  FetchResourcesAction
>;

export const fetchResourcesAction = (): FetchResourcesActionResult => {
  return async (dispatch: Dispatch<FetchResourcesAction>) => {
    try {
      const payload = await fetchResources();
      dispatch({
        type: ResourceActionType.FetchResources,
        payload
      });
      return Promise.resolve(payload);
    } catch (e) {
      return Promise.resolve([]);
    }
  };
};
