import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Flex } from "primitives";
import { Form } from "app/shared";
import { createBookerConfig } from "app/passageManager/actions";
import {
  calculateToDate,
  QuickRange,
  transformFormData,
  validator
} from "app/passageManager/utils";
import { bookerConfigSchemaCreate, bookerConfigUISchema } from "./configSchema";
import { defaultStartTime } from "app/passageManager/services/candidates";

export const ConfigForm = (props: any) => {
  const type = props.type;
  const dispatch = useDispatch();
  const satellites = useSelector((s: any) => s.constellations.dashboard);

  const groundStations = useSelector(
    (s: any) => s.groundStations?.groundStations
  );

  const { data: passageManagerConfigs } = useSelector(
    (s: any) => s.passageManager.bookerConfigs
  );

  const submit = async (
    e: React.FormEvent<HTMLInputElement>,
    formData: any
  ) => {
    e.preventDefault();
    const formattedData = transformFormData(
      formData,
      satellites,
      groundStations
    );
    const notCreated = await dispatch(createBookerConfig(formattedData));
    !notCreated && props.closeModal();
  };

  const transformData = (formData: any): any => {
    if (
      formData.quickrange &&
      Object.values(QuickRange).includes(formData.quickrange as QuickRange)
    ) {
      formData.daterange = {
        from: defaultStartTime,
        to: calculateToDate(formData.quickrange as QuickRange).toISOString()
      };
    } else if (formData.daterange?.from && formData.daterange?.to) {
      formData.daterange = {
        from: new Date(formData.daterange.from).toISOString(),
        to: new Date(formData.daterange.to).toISOString()
      };
    }
    return formData;
  };

  const onChange = useCallback((form) => {
    transformData(form.formData);
  }, []);

  const formData = {};

  return (
    <Flex flexDirection="column" bg="fill.0" p={5}>
      <Form
        ref={props.formRef}
        id="passage-manager"
        validate={(formData: any, errors: any) => validator(formData, errors)}
        formData={formData}
        schema={bookerConfigSchemaCreate(
          satellites,
          groundStations,
          type,
          passageManagerConfigs
        )}
        uiSchema={bookerConfigUISchema}
        disabled={false}
        onSubmit={(
          form: { formData: any },
          e: React.FormEvent<HTMLInputElement>
        ) => {
          return submit(e, form.formData);
        }}
        onChange={(form: any) => onChange(form)}
      >
        <></>
      </Form>
    </Flex>
  );
};
