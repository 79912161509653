import React from "react";
import styled from "styled-components";

export const AlertStatus = ({ status }: any) => {
  return <StyledSpan active={status}></StyledSpan>;
};

type StatusProps = {
  active: boolean;
};

const StyledSpan = styled.span<StatusProps>`
  height: 15px;
  width: 15px;
  ${(props) =>
    props.active ? `background-color: #88BD40;` : `background-color: grey;`}
  border-radius: 50%;
  display: inline-block;
  margin-left: 30%;
`;
