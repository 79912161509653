import { InputBase, SelectBox } from "app/shared";
import { EmbeddedEditorEditor } from "components";
import { Resource } from "app/resources/models";

export const schemaCreate = (resources: Resource[]) => ({
  definitions: {
    Resources: {
      type: "object",
      properties: {
        id: {
          type: "number",
          title: "Resource",
          enum:
            resources.length > 0
              ? resources.map((resource: Resource) => resource.id)
              : [[]],
          enumNames:
            resources.length > 0
              ? resources.map((resource: Resource) => resource.name)
              : [[]]
        },
        targetLocation: {
          type: "string",
          title: "Target Location"
        }
      }
    }
  },
  type: "object",
  required: ["name", "script"],
  properties: {
    name: {
      type: "string",
      title: "Name"
    },
    script: {
      type: "string",
      title: "Script"
    },
    resources: {
      type: "array",
      title: "Resources",
      hasRemove: true,
      default: [],
      items: {
        $ref: "#/definitions/Resources"
      }
    }
  }
});

export const uiSchemaCreate = {
  name: { "ui:widget": InputBase },
  script: { "ui:widget": EmbeddedEditorEditor },
  resources: {
    items: {
      id: { "ui:widget": SelectBox },
      targetLocation: { "ui:widget": InputBase }
    }
  }
};

export const schemaEdit = (resources: Resource[]) => ({
  definitions: {
    Resources: {
      type: "object",
      properties: {
        id: {
          type: "number",
          title: "Resource",
          enum:
            resources.length > 0
              ? resources.map((resource: Resource) => resource.id)
              : [[]],
          enumNames:
            resources.length > 0
              ? resources.map((resource: Resource) => resource.name)
              : [[]]
        },
        targetLocation: {
          type: "string",
          title: "Target Location"
        }
      }
    }
  },
  type: "object",
  properties: {
    id: {
      type: "number",
      title: "Id",
      kind: "constant"
    },
    ...schemaCreate(resources).properties
  }
});

export const uiSchemaEdit = {
  id: { "ui:widget": InputBase },
  ...uiSchemaCreate
};
