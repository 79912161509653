import { Dashboard } from "app/dashboard/models";
import { store } from "app/store";
import { updateDashboard } from "app/dashboard/actions";
import { clone } from "utils";

const sizeColRow = 1;

export function generateLayout(
  dashboard: Dashboard,
  width: number,
  height: number,
  showSideBar: boolean,
  offsetTop: number
) {
  const items = dashboard.components;
  if (!items) {
    return [];
  }

  const { rowNumber, colNumber } = getRowColNumber(
    dashboard,
    width,
    height,
    showSideBar,
    offsetTop
  );

  const result = items.map((item, index) => {
    const h = Math.floor((item.size.row * rowNumber) / dashboard.size.row);
    const w = Math.floor((item.size.col * colNumber) / dashboard.size.col);
    const key = composeComponentKey(dashboard, index);

    return {
      i: key,
      x: Math.floor(((item.position.col - 1) * colNumber) / dashboard.size.col),
      y: Math.floor(((item.position.row - 1) * rowNumber) / dashboard.size.row),
      w: w < 20 ? 20 : w,
      h: h < 20 ? 20 : h
    };
  });
  return result;
}

export function getRowColNumber(
  dashboard: Dashboard,
  width: number,
  height: number,
  showSideBar: boolean,
  offsetTop: number
) {
  const yMargins = offsetTop ? 89 + offsetTop : 178;

  const rowNumber = dashboard.dimensions.height
    ? (dashboard.dimensions.height - yMargins) / sizeColRow
    : Math.floor((height - yMargins) / sizeColRow);

  const colNumber = dashboard.dimensions.width
    ? Math.floor(dashboard.dimensions.width - 30 / sizeColRow)
    : Math.floor((width - (showSideBar ? 230 : 30)) / sizeColRow);

  return {
    rowNumber,
    colNumber
  };
}

export function updatePositionsAndSizes(
  newLayout: any,
  dashboard: Dashboard,
  width: number,
  height: number,
  showSideBar: boolean,
  offsetTop: number
) {
  const { rowNumber, colNumber } = getRowColNumber(
    dashboard,
    width,
    height,
    showSideBar,
    offsetTop
  );
  const newDashboard: Dashboard = clone(dashboard);
  const items = newDashboard.components;
  items.forEach((item, index) => {
    item.position.row = newLayout[index].y + 1;
    item.position.col = newLayout[index].x + 1;
    item.size.row = newLayout[index].h;
    item.size.col = newLayout[index].w;
  });
  newDashboard.size.row = rowNumber;
  newDashboard.size.col = colNumber;
  store.dispatch(updateDashboard(newDashboard));
}

/**
 * @param {Dashboard} dashboard the current dashboard
 * @param {number} index the index of the component in the dashboard.components array
 * @description It returns a string rappresenting a static UUID composed by dashboard id,
 * component type and a number rappresenting the position of the component in the dashboard.components list.
 */
export const composeComponentKey = (
  dashboard: Dashboard,
  index: number
): string => {
  return `${dashboard.id}@${dashboard.components[index].type}@${index}`;
};
