import React from "react";
import { Route } from "react-router-dom";
import { ConstellationListContainer } from "./containers/ConstellationListContainer";
import { ConstellationCreateContainer } from "./containers/ConstellationCreateContainer";
import { ConstellationEditContainer } from "./containers/ConstellationEditContainer";

export const ConstellationRouters = () => (
  <>
    <Route path="/constellation" exact component={ConstellationListContainer} />
    <Route
      path="/constellation/create"
      exact
      component={ConstellationCreateContainer}
    />
    <Route
      path="/constellation/:id"
      exact
      component={ConstellationEditContainer}
    />
  </>
);
