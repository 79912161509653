import React, { useContext, useEffect } from "react";
import { SessionKey, ContextActionsNames } from "../models";
import { KeyManagerContext } from "../context";
import { SessionKeyContextMenu } from "../containers";
import { KeyStateIndicator } from "./KeyStateIndicator";
import styled from "styled-components";
import { useContextMenu } from "hooks";
import { Tag } from "components";
import { channelMaps, functionalCategoriesMaps } from "../utils/constants";

interface SessionKeyItemProps {
  data: SessionKey;
}
const Container = styled("div")`
  display: flex;
  align-items: center;
  padding: 5px;
`;

export const SessionKeyItem = ({ data: sessionKey }: SessionKeyItemProps) => {
  const { dispatch } = useContext(KeyManagerContext);
  const { contextMenu, handleContextMenu } = useContextMenu({
    data: sessionKey,
    menuTemplate: SessionKeyContextMenu
  });

  useEffect(() => {
    dispatch({ type: ContextActionsNames.contextMenu, payload: contextMenu });
  }, [contextMenu]);

  return (
    <Container
      onContextMenu={handleContextMenu}
      data-testid={"session-key-item"}
    >
      <KeyStateIndicator
        status={sessionKey.status}
        expires_at={sessionKey.expires_at || undefined}
      />
      {sessionKey.channel && (
        <Tag
          icon={"Antenna"}
          title={channelMaps[sessionKey.channel].label}
          bg={channelMaps[sessionKey.channel].bg}
          label={sessionKey.channel}
          mr={2}
        />
      )}
      {sessionKey.category && (
        <Tag
          icon={"Key"}
          title={functionalCategoriesMaps[sessionKey.category].label}
          label={sessionKey.category}
          mr={2}
        />
      )}
      <div style={{ fontFamily: "monospace" }}>{sessionKey.key_id}</div>
    </Container>
  );
};
