import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgStop = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    viewBox="0 0 420 420"
    width={size || width}
    height={size || height}
    color={color}
  >
    <path d="M210 21c104.216 0 189 84.784 189 189s-84.784 189-189 189S21 314.216 21 210 105.784 21 210 21m0-21C94.031 0 0 94.024 0 210s94.031 210 210 210 210-94.024 210-210S325.969 0 210 0z" />
    <path d="M276.066 108.941H143.941c-19.25 0-35 15.75-35 35v132.125c0 19.25 15.75 35 35 35h132.125c19.25 0 35-15.75 35-35V143.941c0-19.25-15.75-35-35-35zm14 160.125c0 11.55-9.45 21-21 21H150.941c-11.55 0-21-9.45-21-21V150.941c0-11.55 9.45-21 21-21h118.125c11.55 0 21 9.45 21 21v118.125z" />
  </Svg>
);

SvgStop.defaultProps = {
  width: "24",
  height: "24"
};
export default SvgStop;
