import React, { Component, ChangeEvent, FormEvent } from "react";
import {
  Box,
  Flex,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Label
} from "primitives";
import { EditButton, SaveButton, CancelButton, InputField } from "components";
import { editSatelliteInstance } from "app/satellite/services";
import { SatelliteInstance } from "app/satellite/models";
import { SatelliteDefinition } from "app/satelliteDefinition/models";
import { FeedbackStatus } from "app/feedback/models";
import { setFeedback } from "app/feedback/actions";
import { store } from "app/store";
import { SelectSimple as Select } from "components";

interface SatelliteInstanceEditProps {
  record: SatelliteInstance;
  onChange: () => void;
  satelliteDefinitions: SatelliteDefinition[];
}

interface SatelliteInstanceEditState {
  modalOpen: boolean;
  label: string;
  satelliteDefinitionId: number | string;
}

export class SatelliteInstanceEdit extends Component<
  SatelliteInstanceEditProps,
  SatelliteInstanceEditState
> {
  state = {
    modalOpen: false,
    label: this.props.record.label,
    satelliteDefinitionId: this.props.record.satelliteDefinitionSummary
      .satelliteDefinitionId
  };

  onChangeLabel(
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) {
    const { value } = event.currentTarget;
    this.setState({ label: value });
  }

  onChangeSatelliteDefinitionID(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.currentTarget;
    this.setState({ satelliteDefinitionId: value });
  }

  hideDialog = () => {
    this.setState({ modalOpen: false });
  };

  openDialog = () => {
    this.setState({ modalOpen: true });
  };

  handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const { label, satelliteDefinitionId } = this.state;
    const { record } = this.props;
    const satelliteInstance = {
      id: record.id,
      label,
      satelliteDefinitionId
    };
    editSatelliteInstance(record, satelliteInstance)
      .then(() => {
        this.props.onChange();
        this.hideDialog();
      })
      .catch(() => {
        store.dispatch(setFeedback("Unauthorized", FeedbackStatus.ERROR));
        this.hideDialog();
      });
  };

  render() {
    const { record, satelliteDefinitions } = this.props;
    const { modalOpen, satelliteDefinitionId } = this.state;

    return (
      <>
        <EditButton onClick={() => this.openDialog()} mr={1}>
          Edit
        </EditButton>
        <Dialog
          open={modalOpen}
          maxWidth="md"
          data-testid="SatelliteInstanceEdit"
        >
          <DialogTitle>Edit</DialogTitle>
          <DialogContent>
            <form
              onSubmit={(e) => this.handleSubmit(e)}
              id="edit-satellite-instance"
            >
              <Box mb={2}>
                <InputField
                  id="id"
                  required={true}
                  defaultValue={(record && record.id) || ""}
                  label="ID"
                  disabled
                />
              </Box>
              <Box mb={2}>
                <InputField
                  id="label"
                  required={true}
                  defaultValue={(record && record.label) || ""}
                  label="Label"
                  onChange={(e) => this.onChangeLabel(e)}
                />
              </Box>
              <Box my={2} width="100%">
                <Label>Satellite Definition</Label>
                <Select
                  width="100%"
                  required={true}
                  name="satelliteDefinition"
                  value={satelliteDefinitionId}
                  onChange={(e) => this.onChangeSatelliteDefinitionID(e)}
                >
                  {satelliteDefinitions &&
                    satelliteDefinitions.map((satelliteDefinition) => (
                      <option
                        key={satelliteDefinition.id}
                        value={satelliteDefinition.id}
                      >
                        {satelliteDefinition.name}
                      </option>
                    ))}
                </Select>
              </Box>
            </form>
          </DialogContent>
          <DialogActions>
            <Flex
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <SaveButton type="submit" form="edit-satellite-instance" mr={1}>
                Save
              </SaveButton>
              <CancelButton onClick={() => this.hideDialog()} mx={1}>
                Cancel
              </CancelButton>
            </Flex>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
