import React from "react";
import { Svg } from "primitives";
import { SvgProps } from "primitives/Svg";

const SvgUnlock = ({
  width,
  height,
  size,
  color
}: React.SVGProps<SVGSVGElement> & SvgProps) => (
  <Svg
    viewBox="0 0 491.52 491.52"
    width={size || width}
    height={size || height}
    color={color}
  >
    <path d="M399.398,203.578h-19.802v-69.744C379.596,60.039,319.558,0,245.763,0h-0.004c-73.792,0.002-133.83,60.04-133.83,133.835    v1.435h47.753v-1.435c0-23.734,9.653-45.254,25.24-60.841c3.896-3.896,8.165-7.423,12.744-10.519    c9.162-6.193,19.572-10.671,30.77-12.968c5.596-1.149,11.392-1.753,17.323-1.753h0.004c47.467,0,86.08,38.613,86.08,86.081v69.744    h-86.084h-86.077h-47.753H92.122c-15.658,0-28.35,12.692-28.35,28.35v17.246v196.749v17.246c0,15.658,12.692,28.35,28.35,28.35    H245.76h153.639c15.656,0,28.35-12.692,28.35-28.35v-17.246V249.175v-17.246C427.747,216.27,415.055,203.578,399.398,203.578z     M274.161,403.177h-56.804l10.168-48.445c-9.507-6.05-15.858-16.614-15.858-28.719c0-18.828,15.263-34.092,34.092-34.092    c18.83,0,34.092,15.263,34.092,34.092c0,12.104-6.35,22.669-15.856,28.718L274.161,403.177z"></path>
  </Svg>
);

SvgUnlock.defaultProps = {
  width: "491.52",
  height: "491.52"
};
export default SvgUnlock;
