import { CheckBox, ColorPicker } from "components";
import { SelectBox, InputBase } from "app/shared";
import { scalingConfig } from "app/telemetry/visualizations/shared/configs";

const gaugeConfigOptionsSchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "gauge.schema.json",
  title: "Aurora Gauge",
  description: "The configuration options for the Aurora's Gauge component",
  type: "object",
  definitions: {
    dataSource: {
      $id: "#/definitions/dataSource",
      type: "object",
      title: "DataSource",
      description: "The data-source item to be added to the component",
      properties: {
        name: {
          $id: "#/definitions/dataSource/properties/name",
          title: "Name",
          description: "The original data-source name",
          type: "string"
        },
        label: {
          $id: "#/definitions/dataSource/properties/label",
          title: "Label",
          description:
            "The label to be given to the data-source. When used, it overrides the original data-source name",
          type: "string"
        },
        id: {
          $id: "#/definitions/dataSource/properties/id",
          title: "Id",
          description: "The id of data-source",
          type: "integer",
          minimum: 1
        },
        indexes: {
          $id: "#/definitions/dataSource/properties/indexes",
          title: "Indexes",
          description:
            "In case of a vector data-source, represents the indexes to be presented. When is a scalar data-source, this field is not used. If it is an array data-source, and this field is not present or is empty, all the indexes should be considered",
          oneOf: [
            {
              type: "array",
              items: {
                title: "Items",
                description:
                  "An explanation about the purpose of this instance.",
                type: "object",
                properties: {
                  index: {
                    $id: "#/definitions/dataSource/properties/indexes/properties/index",
                    title: "Index",
                    description:
                      "The index where this properties will be reflected",
                    type: "integer",
                    minimum: 0
                  }
                },
                required: ["index"]
              }
            },
            {
              type: "null"
            }
          ]
        }
      },
      required: ["name", "id"]
    },
    thresholds: {
      collapsible: "enabled",
      type: "array",
      hasRemove: true,
      items: {
        type: "object",
        properties: {
          color: {
            title: "Range color",
            type: "string"
          },
          min: {
            title: "From",
            type: "number"
          },
          max: {
            title: "To",
            type: "number"
          }
        }
      },
      required: ["min", "max", "color"]
    }
  },
  properties: {
    dataSources: {
      $id: "#/properties/data-sources",
      title: "DataSources",
      description: "List of data-sources to be added to the component",
      type: "array",
      minItems: 1,
      maxItems: 1,
      items: {
        $ref: "#/definitions/dataSource"
      }
    },
    widgetName: {
      type: "string",
      title: "Gauge",
      default: "Gauge"
    },
    displayName: {
      title: "Display name",
      type: "string"
    },
    floatDigits: {
      $id: "#/definitions/floatDigits",
      type: "number",
      title: "Float Digits",
      description: "Number of digits to display for floating point values",
      minimum: 0
    },
    scale: scalingConfig("Gauge").configSchema,
    thresholds: {
      title: "Thresholds",
      $ref: "#/definitions/thresholds"
    },
    minAngle: {
      title: "Min Angle",
      type: "number"
    },
    maxAngle: {
      title: "Max Angle",
      type: "number"
    },
    styles: {
      collapsible: "enabled",
      $id: "#/definitions/styles",
      title: "Gauge font size & colors",
      type: "object",
      properties: {
        labelColor: {
          title: "Label color",
          description: "Color of the gauge label",
          type: "string",
          default: "#7ab9db"
        },
        labelFontSize: {
          title: "Label Font size",
          description: "Font size of the gauge label",
          type: "number",
          default: 10
        },
        labelTopMargin: {
          title: "Label margin",
          description: "Margin between gauge and label",
          type: "number",
          default: 0
        },
        valueFontSize: {
          title: "Value font size",
          description: "Font size of the value",
          type: "number",
          default: 12
        },
        valueColor: {
          title: "Value color",
          description: "Color of the value",
          type: "string",
          default: "#7ab9db"
        },
        valuePathColor: {
          title: "Value path color",
          description: "Color of the value guide",
          type: "string",
          default: "#384c65"
        }
      }
    }
  },
  required: ["dataSources"]
};

const gaugeConfigOptionsDefault = {
  dataSources: [],
  isGloballyControlled: true,
  scale: {
    default: true,
    enableScaling: false,
    scaledUnit: "",
    showUnit: true,
    offset: 100,
    invalidValues: "4294967295",
    invalidValueOpacity: 20
  },
  thresholds: [
    { min: -95, max: 0, color: "#009bef" },
    { min: 0, max: 25, color: "#00ef14" },
    { min: 25, max: 40, color: "#ff8400" },
    { min: 40, max: 130, color: "#ff3c3c" }
  ],
  minAngle: -135, // Defines the start position of the gauge arc
  maxAngle: 135, // Defines the end position of the gauge arc
  floatDigits: 2,
  styles: {
    labelColor: "#7ab9db",
    labelFontSize: 10,
    labelTopMargin: 0,
    valueFontSize: 12,
    valueColor: "#7ab9db",
    valuePathColor: "#384c65"
  },
  displayName: ""
};

const gaugeConfigOptionsUISchema = {
  dataSources: { "ui:widget": "hidden" },
  widgetName: { "ui:widget": "hidden" },
  displayName: { "ui:widget": InputBase },
  scale: scalingConfig("Gauge").uiSchema,
  thresholds: {
    items: {
      min: { "ui:widget": InputBase },
      max: { "ui:widget": InputBase },
      color: { "ui:widget": "color" }
    }
  },
  floatDigits: { "ui:widget": InputBase },
  minAngle: { "ui:widget": "hidden" },
  maxAngle: { "ui:widget": "hidden" },
  // isGloballyControlled: { "ui:widget": CheckBox },
  styles: {
    labelColor: { "ui:widget": "color" },
    labelFontSize: { "ui:widget": InputBase },
    labelTopMargin: { "ui:widget": InputBase },
    valueFontSize: { "ui:widget": InputBase },
    valueColor: { "ui:widget": "color" },
    valuePathColor: { "ui:widget": "color" }
  }
};

export {
  gaugeConfigOptionsSchema,
  gaugeConfigOptionsDefault,
  gaugeConfigOptionsUISchema
};
