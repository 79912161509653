export const exportToJson = (data: any, name = "data") => {
  const timestamp = new Date().toISOString();
  const stringifiedData = `data:text/json;charset=utf-8,${encodeURIComponent(
    JSON.stringify(data, null, 2)
  )}`;
  const fileName = data.name ? data.name : name;
  const satelliteId = data.satelliteId ? data.satelliteId : "";
  const link = document.createElement("a");
  link.href = stringifiedData;
  link.download = `${fileName}-${satelliteId}-${timestamp}.json`;
  link.click();
};
