import styled from "styled-components";
import Area from "./Area";

const Footer = styled(Area)``;

Footer.defaultProps = {
  gridArea: "footer"
};

export default Footer;
