import { cloneDeep, map, filter } from "lodash";
// import { isEqual } from "lodash";
import objectPath from "object-path";
import {
  FormInputArg,
  BuilInputArgs,
  TelecommandFormValidationArgs,
  TcFormDataCheckerPayload,
  Commands,
  TelecommandSpec,
  FormData,
  ParsedSSE,
  MessageType,
  TelecommandFormData
} from "../models";

/**
 * @description Check if armsPath exists, if not create a default value
 * @return {void}
 */
export const addDefaultArmsPath = (
  data: FormData,
  organization: string,
  satelliteLabel: string,
  telecommandSpec: { label: string }
) => {
  // check if selected tc is a DTP DOWNLOAD and if user is not a superuser
  if (
    Object.values(Commands).includes(telecommandSpec.label as Commands) &&
    organization !== "__su__"
  ) {
    const path = data.csp[0].armsPath;
    if (!path) {
      data.csp[0] = {
        ...data.csp[0],
        armsPath: `${organization}/ION/${satelliteLabel}/`
      };
    }
  }
};

/**
 * @description Prepare form data to be saved in the FormExecution state
 * @returns {object}
 */
export const flattenFormData = (
  telecommandSpec: any,
  initialFormData: any
): any => {
  if (!telecommandSpec || !initialFormData) {
    return;
  }
  const newFormData = Object.assign({}, initialFormData);
  const args = telecommandSpec.args;
  Object.keys(initialFormData).forEach((argKey: string) => {
    const arg = args.find((argAux: any) => argAux.id === argKey);
    if (
      arg &&
      arg.size &&
      typeof arg.size === "string" &&
      arg.size.startsWith("@")
    ) {
      const values = initialFormData[argKey];
      const newValues: any = {};
      values.forEach((value: any, index: number) => {
        newValues[`${argKey}[${index}]`] = [{ ...value }];
      });
      newFormData[argKey] = [newValues];
    }
    //Convert arrays to strings
    if (
      arg &&
      arg.argType === "Array" &&
      Array.isArray(initialFormData[argKey])
    ) {
      newFormData[argKey] = initialFormData[argKey].join(",");
    }
  });
  return newFormData;
};

/**
 * @description remove default values if automaticFill
 * @return {void}
 */
export const removeDefaultValues = (arg: any) => {
  for (const objKey in arg) {
    if (objKey === "default") {
      delete arg[objKey];
    } else if (typeof arg[objKey] === "object") {
      removeDefaultValues(arg[objKey]);
    }
  }
};

/**
 * @description Returns object value for a given key
 * @returns {object}
 */
// note: findByKey ccnflicts with testing-library findByKey func
export const _findByKey = (obj: any, key: string): any => {
  let result;
  for (const property in obj) {
    /* eslint-disable no-prototype-builtins */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    if (obj.hasOwnProperty(property)) {
      /* eslint-enable no-prototype-builtins */
      // in case it is an object
      if (typeof obj[property] === "object") {
        result = _findByKey(obj[property], key);

        if (typeof result !== "undefined") {
          return result;
        }
      } else if (property === key) {
        return obj[key]; // returns the value
      }
    }
  }
};

/**
 * @description Process groupSpecs for args containing an argType equal to "group"
 * @returns {FormInputArg}
 */
const processTcArgTypeAsGroup = (
  size: number,
  arg: FormInputArg
): FormInputArg => {
  const _arg = cloneDeep(arg);
  //Flatten array
  const newGroupSpec: any = [];
  Array.from(Array(size)).forEach((_x, i) => {
    const newId = `${_arg.id}[${i}]`;
    const aux = {
      argType: cloneDeep(_arg.argType as object),
      id: newId,
      name: "",
      size: 1,
      groupSpec: cloneDeep(_arg.groupSpec as object),
      addable: false
    };
    newGroupSpec.push(aux);
  });
  _arg.size = 1;
  _arg.groupSpec = cloneDeep(newGroupSpec);
  return _arg;
};

/**
 * @description Normalizes the size value if is type string
 * @returns {FormInputArg}
 */
const processTcSpecSizeAsString = (
  arg: FormInputArg,
  formData: object
): FormInputArg => {
  let _arg = cloneDeep(arg);
  if (_arg.size && typeof _arg.size === "string" && _arg.size.startsWith("@")) {
    const sizeArg = _arg.size.substr(1);
    const size = parseInt(_findByKey(formData, sizeArg));
    if (!size || isNaN(size)) {
      return _arg;
    }
    if (_arg.argType === "Group") {
      _arg = processTcArgTypeAsGroup(size, _arg);
    } else if (_arg.argType === "Array") {
      _arg.size = size;
    }
  }
  return _arg;
};

/**
 * @description process argtype "group" and calls recursively the buildInputArgs function to build nested inputs
 * @returns {void}
 */
const handleGroupArgTypeTcSpec = ({
  arg,
  telecommandSpec,
  path = []
}: BuilInputArgs) => {
  path.push(arg.id);
  arg.groupSpec &&
    arg.groupSpec.forEach((groupArg: any, index: number) => {
      const buildArgRes = buildInputArgs({
        arg: groupArg,
        telecommandSpec,
        path
      });

      arg.groupSpec = arg.groupSpec || [];
      if (buildArgRes) {
        arg.groupSpec[index] = cloneDeep(buildArgRes);
      }
    });
  path.pop();
};

/**
 * @description use the given parameters to build the object to pass to the Form component lib to build the inputs
 * @returns {FormInputArg}
 */
export const buildInputArgs = ({
  arg,
  telecommandSpec,
  path = []
}: BuilInputArgs) => {
  if (!arg) {
    return null;
  }
  const formData = Object.assign({}, {});

  // Internal instance
  let _arg: FormInputArg = cloneDeep(arg);

  _arg = processTcSpecSizeAsString(_arg, formData);

  if (_arg.argType === "Group") {
    handleGroupArgTypeTcSpec({
      arg,
      telecommandSpec,
      path
    });
  }
  return _arg;
};

/**
 *
 * @description remove the null values from the given object
 * @returns {object}
 */
export const removeNullValues = (obj: any) => {
  Object.keys(obj).forEach(
    (key) =>
      (obj[key] &&
        typeof obj[key] === "object" &&
        removeNullValues(obj[key])) ||
      ((obj[key] === undefined || obj[key] === null) && delete obj[key])
  );
  return obj;
};

/**
 *
 * @description returns a new telecomand spec instance
 * @returns {TelecommandSpec}
 */
const processNewTelecommandSpecs = (
  newFormData: any,
  telecommandSpec: TelecommandSpec,
  automaticFill: any
) => {
  const newTelecommandSpec = cloneDeep(telecommandSpec);
  newTelecommandSpec.args = [];
  const args = cloneDeep(telecommandSpec.args);
  if (args && newFormData) {
    args.forEach((arg: any, index: number) => {
      if (!automaticFill) {
        removeDefaultValues(arg);
      }
      let builtArg = buildInputArgs({
        arg,
        telecommandSpec
      });
      if (builtArg) {
        builtArg = removeNullValues(builtArg);
        newTelecommandSpec.args.push(builtArg as FormInputArg);
      }
    });
  }
  return newTelecommandSpec;
};

/**
 * @description create a lcal telecommand spec instance
 * @returns {TelecommandSpec}
 */
export const buildLocalTelecommandSpec = (
  telecommandSpec: TelecommandSpec | null,
  formData: any,
  automaticFill: any
) => {
  if (!telecommandSpec) {
    return null;
  }
  const newFormData = formData ? cloneDeep(formData) : {};
  if (telecommandSpec) {
    return processNewTelecommandSpecs(
      newFormData,
      telecommandSpec,
      automaticFill
    );
  }
};

/**
 *
 * @description on form submit it should parse and check data with argSpec type "group"
 * @returns {number | string | any[]}
 */
const parseGroupArg = (arg: any, data: any, telecommandSpec: any) => {
  if (arg.size && typeof arg.size === "string" && arg.size.startsWith("@")) {
    const values: any = [];
    data.formData[arg.id].forEach((element: any, index: number) => {
      const value: any = checkData({
        item: { id: arg.id, value: element },
        telecommandSpec,
        groupSpec: arg.groupSpec
      });
      if (Object.keys(value).length > 1) {
        Object.keys(value).forEach((key: any, otherIndex: number) => {
          values.push(value[key][0]);
        });
      } else {
        values.push((value as any)[`${arg.id}[${index}]`][0]);
      }
    });
    return values;
  } else {
    const value = checkData({
      item: { id: arg.id, value: data.formData[arg.id] },
      telecommandSpec,
      groupSpec: arg.groupSpec
    });
    return value;
  }
};

/**
 *
 * @description invoked on form submit it checks and parse the form data
 * @returns {void}
 */
export const toCheckOrParseData = (
  modifiedData: any,
  data: any,
  telecommandSpec: any
) => {
  telecommandSpec.args.forEach((spec: any) => {
    modifiedData.formData = {
      ...modifiedData.formData,
      [spec.id]:
        spec.argType === "Array"
          ? map(data.formData[spec.id].split(","), (item: string) =>
              checkData({
                item: { id: spec.id, value: item.trim() },
                telecommandSpec,
                groupSpec: objectPath
              })
            )
          : spec.argType === "Group"
          ? parseGroupArg(spec, data, telecommandSpec)
          : checkData({
              item: { id: spec.id, value: data.formData[spec.id] },
              telecommandSpec,
              groupSpec: spec.groupSpec
            })
    };
  });
};

/**
 * @description Check only for int and double types, at 'Literal' fields
 * @returns {void}
 */
export const validateLiteral = ({
  spec,
  formData,
  errors,
  key,
  errorsKey
}: TelecommandFormValidationArgs) => {
  const itemType = spec.itemSpec ? spec.itemSpec.dataType : spec.dataType;
  if (spec.argType === "Literal" && (formData as any)[key]) {
    switch (itemType) {
      case "int": {
        if (!isInt((formData as any)[key])) {
          (errors as any)[errorsKey].addError(`Wrong type inserted.`);
        }
        break;
      }
      case "double": {
        if (!isDouble((formData as any)[key])) {
          (errors as any)[errorsKey].addError(`Wrong type inserted.`);
        }
        break;
      }
    }
  }
};

/**
 * @description Check the size and empty values at arrays fields
 * @returns {void}
 */
export const validateArrayFields = ({
  spec,
  formData,
  errors,
  key,
  errorsKey
}: TelecommandFormValidationArgs) => {
  const sizeNumber =
    typeof spec.size == "string"
      ? (formData as any)[spec.size.slice(1)] // To remove the "@"
      : spec.size;

  const itemType = spec.itemSpec ? spec.itemSpec.dataType : spec.dataType;
  const values =
    (formData as any)[key].split(",").length > 0
      ? (formData as any)[key].split(",")
      : [];
  // Check if there's any empty values, if not, check if each of them is the right type
  if (parseInt(values.length, 10) !== parseInt(sizeNumber, 10)) {
    (errors as any)[errorsKey].addError(
      `${spec.name} size doesn't match size number`
    );
  } else {
    for (let i = 0; i < values.length; i++) {
      if (typeof values[i] !== "undefined") {
        switch (itemType) {
          case "int": {
            if (!isInt(values[i])) {
              (errors as any)[errorsKey].addError(
                `Wrong type inserted, at position ${i}: ${values[i]}`
              );
            }
            break;
          }
          case "double": {
            if (!isDouble(values[i])) {
              (errors as any).addError(
                `Wrong type inserted, at position ${i}: ${values[i]}`
              );
            }
            break;
          }
        }
      } else {
        (errors as any).addError(
          `Can't insert empty values. Please, check the inserted bytes, and try again.`
        );
        break;
      }
    }
  }
};

/**
 * @description passed down to the FormJsonSchema component to be executed on its 'validate' prop
 * @returns {errors}
 */
// eslint-disable-next-line no-shadow
export const formValidator = (
  formData: any,
  errors: any,
  args: FormInputArg[],
  errorsKey: string | null = null
) => {
  Object.keys(formData).forEach((key: any) => {
    const spec: any = filter(args, (arg: any) => {
      return arg.id === key;
    })[0];
    const newErrorsKey: string = errorsKey || key;

    validateLiteral({ spec, formData, errors, key, errorsKey: newErrorsKey });
    if (
      spec.argType === "Array" &&
      (typeof spec.size == "string" || typeof spec.size == "number") &&
      formData[key]
    ) {
      validateArrayFields({
        spec,
        formData,
        errors,
        key,
        errorsKey: newErrorsKey
      });
    }
    if (spec.argType === "Group") {
      formValidator(formData[key][0], errors, spec.groupSpec, newErrorsKey);
    }
  });

  return errors;
};

/**
 * @description check if he passed value is an int or not
 * @returns {boolean}
 */
const isInt = (data: any): boolean => {
  return isFinite(data) && !(data % 1);
};

/**
 * @description check if he passed value is floated or not
 * @returns {boolean}
 */
const isDouble = (data: any): boolean => {
  return !isNaN(parseFloat(data));
};

/**
 * @description check the inserted data of type Array
 * @returns {Array}
 */
export const handleArrayValues = ({
  item,
  telecommandSpec,
  groupSpec = null
}: TcFormDataCheckerPayload) => {
  //Order object parameters
  const resultObj: any = {};
  groupSpec.forEach((spec: any) => {
    let value: any = item.value[0][spec.id];
    if (spec.argType === "Array" && spec.itemSpec.dataType === "string")
      if (value.indexOf(",") !== -1) value = value.split(",");
      else value = [value];
    else if (spec.argType === "Group")
      value = checkData({
        item: { id: spec.id, value },
        telecommandSpec,
        groupSpec: spec.groupSpec
      });
    else if (
      spec.argType === "Array" &&
      (spec.itemSpec.dataType === "int" || spec.itemSpec.dataType === "double")
    )
      value = map(value.split(","), (v: string) => Number(v));

    resultObj[spec.id] = value;
  });
  return [resultObj];
};

/**
 * @description check the inserted data of type Object
 * @returns {void}
 */
const checkGroup = (
  item: { id: string; value: string | Array<any> },
  dataPath: any[],
  groupSpec: any
) => {
  const [key] = dataPath;
  const group = item.value[key as any];

  if (Array.isArray(group) && group.length === 1) {
    dataPath.push(0);
    checkGroup(item, [key], groupSpec);
  } else if (group && typeof group === "object") {
    Object.keys(group).forEach((auxKey: string) => {
      dataPath.push(auxKey);
      checkGroup(group[auxKey], dataPath, groupSpec);
      dataPath.pop();
    });
  } else if (typeof group === "string") {
    const isArray = checkIfValueIsArray(groupSpec, dataPath);
    //split strings array in to array of strings
    if (isArray) {
      const splitedString = group.split(",");
      const finalValue: any[] = [];
      splitedString.forEach((string: any) => {
        if (!isNaN(string)) {
          finalValue.push(Number(string));
        } else {
          finalValue.push(string);
        }
      });
      objectPath.set(item.value as any, dataPath, finalValue);
    }
  }
};

/**
 * @description check the inserted data in the telecommand form
 * @returns {number | string | any[]}
 */
export const checkData = (
  p: TcFormDataCheckerPayload
): number | string | any[] => {
  const { item, telecommandSpec, groupSpec = null } = p;
  if (typeof item.value === "string") {
    const { args } = telecommandSpec;

    const itemArgSpec: any = args.filter((arg: any) => {
      return arg.id === item.id;
    })[0];

    const type = itemArgSpec
      ? itemArgSpec.itemSpec
        ? itemArgSpec.itemSpec.dataType
        : itemArgSpec.dataType
      : null;

    if (type === "int") {
      const radix = item.value.startsWith("0x") ? 16 : 10;

      return parseInt(item.value, radix);
    }

    if (type === "double") {
      return item.value.startsWith("0x") ? item.value : parseFloat(item.value);
    }
  } else if (Array.isArray(item.value) && groupSpec) {
    return handleArrayValues({ item, telecommandSpec, groupSpec });
  } else if (item.value && typeof item.value === "object") {
    Object.keys(item.value).forEach((key: string) => {
      checkGroup(item, [key], groupSpec);
    });
  }
  return item.value;
};

/**
 * @description WORKAROUND: Groups are being rendered as array fields of one element. React-jsonschema-form package required user to click "Add" button to show the fields. This method automatically clicks the "Add" buttons so the user doesnt have to.
 * @returns {void}
 */
export const automaticallyClickAddButtons = () => {
  const formGroups = Array.from(document.getElementsByClassName("form-group"));
  formGroups.forEach((formGroup) => {
    const button = formGroup.getElementsByTagName("button")[0];
    if (button) {
      button.click();
    }
  });
};

/**
 * @description Check if a value is an array by data path. Looks for value definition in the group spec
 * @returns {boolean}
 */
const checkIfValueIsArray = (groupSpec: any, dataPath: any[]) => {
  let result = false;
  let currentGroupSpec = groupSpec;
  dataPath.forEach((key: string, index: number) => {
    const group = currentGroupSpec.find((gS: any) => gS.id === key);
    if (group && group.groupSpec) {
      currentGroupSpec = group.groupSpec;
    } else if (group && index === dataPath.length - 1) {
      if (group.argType === "Array") {
        result = true;
      }
    }
  });
  return result;
};

export const parseSSE = (event: MessageEvent<any>): ParsedSSE | undefined => {
  if (!event) return;
  try {
    const payload = JSON.parse(event.data);
    const msgType = JSON.parse(payload).message.type;
    const satId = JSON.parse(payload).satId;
    const timeStamp = JSON.parse(payload).timestamp;
    return { payload, msgType, satId, timeStamp };
  } catch (error) {
    console.log("Error parsing server sent event");
  }
};

export const shouldRefetchTCList = (sse: ParsedSSE, satId: Number) => {
  const isValidEventType = Object.values(MessageType).includes(sse.msgType);
  const hasCurrentSatId = sse.satId && sse.satId === satId;
  const msgIsOlderThanOneHour = isTimeStampOlderThanMinutes(sse.timeStamp, 59);
  // only refetch TC list for current satellite when user is on Mission Control dashboard while ignoring messages older than 1 hour
  return isValidEventType && hasCurrentSatId && !msgIsOlderThanOneHour;
};

export const isTCResponseEvent = (sse: ParsedSSE) => {
  return sse.msgType === MessageType.TELECOMMAND_RESPONSE;
};

export const isTimeStampOlderThanMinutes = (
  timeStamp: string,
  minutes: number
) => {
  const timeDiff = new Date().getTime() - new Date(timeStamp).getTime();
  return timeDiff / (1000 * minutes) > minutes;
};

const transformArrayValuesToString = (payload: any[]) => {
  const newPayload = payload.map((p: any) => {
    return Object.keys(p).reduce((acc: any, curr: any) => {
      return {
        ...acc,
        [curr]: (Array.isArray(p[curr]) && p[curr].join()) || p[curr]
      };
    }, {});
  });

  return { payload: newPayload };
};

const remapPayloads = (specs: any[]) => {
  // Skip for satSim - only for SatSim this is not an array
  if (Array.isArray(specs)) {
    const newSpecs = specs.map((spec: any) => {
      if (spec.payload && spec.payload.length > 0) {
        const transformedPayloads = transformArrayValuesToString(spec.payload);
        return { ...spec, ...transformedPayloads };
      }

      return spec;
    });

    return newSpecs;
  }
  return specs;
};

/**
 * This function scan the formData `payload` property to find field of type array and transorm the content into a string.
 * This is necessary because at moment to show an arrays can be shown only in a text field of type string.
 *
 * NOTE: This is a temporary solution.
 * Once AURC-1455 will remap the payload from backend this will be removed.
 */
export const getConsumedFormData = (
  formData: TelecommandFormData
): TelecommandFormData => {
  if (formData && Object.keys(formData).length > 0) {
    const consumedFormData = Object.keys(formData).reduce(
      (acc: any, curr: any) => {
        const dataNode = remapPayloads(formData[curr]);
        return { ...acc, [curr]: dataNode };
      },
      {}
    );

    return consumedFormData;
  }

  return formData;
};
