import { InputBase, SelectBox } from "app/shared";
import { CheckBox } from "components";

/* NOTES:
// TODO:
JSON schemas need to be broken down into composable chunks.Small widget - agnostic schemas like "Margins", "Fonts", "Overrides" etc should be created once and reused for all widgets. 
  This will allow us to do two things:

- Streamline styling in one place for all widgets so we can improve UI in one place and it automatically applies to all Widgets.
  
- More importantly, current UISchema is not flexible enough to wrap common configuration into an accordion.For instance, user should be able to open / close sub settings instead of displaying all possible settings.
Grafana configuration has config options categorized into accordion which is searchable. 

Another issue in the widget configuration is Modal view blocks the dashboard view, any changes to configuration is not live until user saves the config.

*/

export const scalingConfig = (widget: string) => {
  const baseUISchema = {
    enableScaling: {
      enableScaling: { "ui:widget": CheckBox },
      scaledUnit: { "ui:widget": "hidden" }
      // scaledUnit: { "ui:widget": SelectBox }
    },
    showUnit: { "ui:widget": CheckBox },
    scaledUnit: { "ui:widget": SelectBox },
    customUnit: { "ui:widget": InputBase },
    transform: { "ui:widget": InputBase },
    collapsible: "enabled"
  };

  const baseConfigSchema = {
    $id: "#/definitions/scale",
    title: "Scale",
    description:
      "Scale to be used. If it is not set, the autoscale is used. If only one of the options is set (min or max), the other will still automatically autoscale",
    type: "object",
    collapsible: "enabled",
    properties: {
      enableScaling: {
        title: "Enable scaling of datasource units",
        description:
          "If selected, default datasource units are converted into scaled unit",
        type: "object",
        enum: [true, false],
        default: false,
        properties: {
          enableScaling: { type: "boolean" }
        },
        dependencies: {
          enableScaling: {
            oneOf: [
              {
                properties: {
                  enableScaling: { const: false }
                }
              },
              {
                properties: {
                  enableScaling: { const: true },
                  scaledUnit: {
                    title: "Scaled Unit",
                    description:
                      "Convert default unit to scaled unit (e.g, seconds to days)",
                    minItems: 0,
                    enum: [""]
                  }
                }
              }
            ]
          }
        }
      },
      showUnit: {
        title: "Show unit",
        description: "",
        type: "boolean",
        default: true
      },
      customUnit: {
        title: "Custom Unit",
        description: "Override default unit with a custom unit",
        type: "string"
      },
      transform: {
        title: "Transform datasource value",
        description:
          "Apply a single transformation to value (e.g value / 10, value * 10)",
        type: "string",
        default: "value * 1"
      }
    }
  };

  const minMaxSchema = {
    min: {
      title: "Minimum",
      description: "The value to be used as fixed minimum",
      type: "string",
      default: 0
    },
    max: {
      title: "Maximum",
      description: "The value to be used as fixed maximum",
      type: "string",
      default: "auto"
    }
  };

  const minMaxUISchema = {
    min: { "ui:widget": InputBase },
    max: { "ui:widget": InputBase }
  };

  const histogramUISchema = {
    enableMaxValid: { "ui:widget": CheckBox },
    offset: { "ui:widget": InputBase },
    invalidValues: { "ui:widget": InputBase },
    invalidValueOpacity: { "ui:widget": InputBase }
  };

  const histogramSchema = {
    enableMaxValid: {
      title: "Max valid value",
      description:
        "Make highest valid value as max after removing invalid values from the dataset (e.g if dataset contains 4294967295(invalid value) use second highest value to create max)",
      type: "boolean",
      default: true
    },
    offset: {
      title: "Offset value between invalid & max valid value (1-100)",
      type: "number",
      default: 100
    },
    invalidValues: {
      title: "Invalid Values",
      description: "List of commas separated values (122,323)",
      type: "string",
      default: "4294967295,"
    },
    invalidValueOpacity: {
      title: "opacity value in int",
      type: "number",
      default: 20
    }
  };

  if (widget === "Histogram") {
    return {
      uiSchema: {
        ...baseUISchema,
        ...histogramUISchema,
        ...minMaxUISchema
      },
      configSchema: {
        ...baseConfigSchema,
        properties: {
          ...baseConfigSchema.properties,
          ...histogramSchema,
          ...minMaxSchema
        }
      }
    };
  }
  if (widget === "LineChart") {
    return {
      uiSchema: {
        ...baseUISchema,
        ...minMaxUISchema
      },
      configSchema: {
        ...baseConfigSchema,
        properties: {
          ...baseConfigSchema.properties,
          ...minMaxSchema
        }
      }
    };
  }
  return {
    uiSchema: baseUISchema,
    configSchema: baseConfigSchema
  };
};
