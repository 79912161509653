import React, { Component } from "react";
import SortableTree, { changeNodeAtPath, map } from "react-sortable-tree";
import NodeConstellationRenderer from "app/shared/tree/nodeConstellationRenderer";
import { connect } from "react-redux";
import styled from "styled-components";
import { Box, Flex, Text, Icon } from "primitives";
import { getConstellations } from "app/constellation";
import { getSatelliteDefinition } from "app/satelliteDefinition/services";
import { getAlerts as getAlertsService } from "app/alert/services";
import { Telemetry } from "app/dataSource";
import { DataTableComponent } from "app/telemetry/visualizations";
import { SatelliteDefinitionSystem } from "app/satelliteDefinition/models";
import { SatelliteInstance } from "app/satellite/models";
import { Constellation } from "app/constellation/models";
import { AlertDTO, AlertValueStatus, Severity, Colors } from "app/alert/models";
import { DataSource } from "app/dataSource/models";
import config from "config/constants";
import { DragHandle } from "components";

const Tree = styled(SortableTree)`
  .rst__lineHalfHorizontalRight::before {
    top: 13px;
  }

  .rst__lineFullVertical::after,
  .rst__lineHalfVerticalTop::after,
  .rst__lineHalfVerticalBottom::after {
    width: 1px;
    left: 50%;
  }

  .rst__node .rst__lineBlock:last-child {
    display: none;
  }
`;

const AlertIndicatorLight = styled(Box)``;
AlertIndicatorLight.defaultProps = {
  size: 8,
  borderRadius: "50%",
  ml: 1
};

const NodeHeaderAction = styled(Flex)``;
NodeHeaderAction.defaultProps = {
  flex: "1 0 auto",
  borderRight: 2,
  alignItems: "center",
  justifyContent: "center",
  px: 2
};

const NodeHeader = styled(Flex)``;
NodeHeader.defaultProps = {
  position: "sticky",
  top: 0,
  alignItems: "stretch",
  justifyContent: "space-between",
  flex: "1 0 100%",
  width: "500px",
  ml: "44px",
  p: 2,
  bg: "palette.brand.0",
  bgOpacity: 3,
  border: 1,
  borderRadius: "2px",
  color: "text.default",
  fontSize: 2
};

interface ConstellationTreeProps {
  getConstellations: () => Promise<any>;
  getSatelliteDefinition: (
    satelliteDefinitionId: number
  ) => Promise<SatelliteDefinitionSystem>;
  options?: any;
}

interface TreeData {
  id: number;
  title: string;
  expanded: boolean;
  contentExpanded: boolean;
  record?: any;
  children: TreeData[];
  alerts: AlertDTO[] | null;
  header?: boolean;
}

interface Satellites {
  satellite: SatelliteInstance;
  satelliteDefinitionId: number;
}

interface AlertList {
  [key: number]: AlertDTO[] | null;
}

interface ConstellationTreeState {
  treeData: TreeData[];
  satellites: Satellites[];
  satelliteDefinitions: SatelliteDefinitionSystem[];
  alertList: AlertList;
  contentExpandedList: number[];
}

class ConstellationTreeBase extends Component<
  ConstellationTreeProps,
  ConstellationTreeState
> {
  state = {
    treeData: [],
    satellites: [],
    satelliteDefinitions: [],
    alertList: {},
    contentExpandedList: []
  };
  private listRef: any = null;
  private interval: number | null | ReturnType<typeof setTimeout> = null;

  /**
   * 1 - Retrieve all constellations to get all satelliteInstance's/satelliteDefinitionId's
   * 2 - Retrieve all satelliteDefinitions from satelliteDefinitionId's
   * 3 - For each satelliteInstance get all alerts
   * 4 - Build tree based on satelliteDefinitions and alerts
   */
  async componentDidMount() {
    const satellites: Satellites[] = [];
    const constellations = await this.props.getConstellations();

    if (constellations) {
      constellations.forEach((constellation: Constellation) => {
        constellation.satelliteInstances.forEach(
          (satelliteInstance: SatelliteInstance) => {
            if (
              satelliteInstance &&
              satelliteInstance.satelliteDefinitionSummary
            ) {
              satellites.push({
                satellite: satelliteInstance,
                satelliteDefinitionId:
                  satelliteInstance.satelliteDefinitionSummary
                    .satelliteDefinitionId
              });
            }
          }
        );
      });
      this.setState({ satellites }, () => this.getAlerts());

      const satelliteDefinitions: SatelliteDefinitionSystem[] =
        await Promise.all(
          satellites.map((item) =>
            this.props.getSatelliteDefinition(item.satelliteDefinitionId)
          )
        );
      this.setState({ satelliteDefinitions }, () => this.buildTree());
      this.loadDataInterval(0);
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearTimeout(this.interval as ReturnType<typeof setTimeout>);
    }
  }

  private loadDataInterval(timer = config.timer.alerts) {
    this.interval = setTimeout(() => {
      this.getAlerts();
      this.loadDataInterval();
    }, timer);
  }

  async getAlerts() {
    const satellites: Satellites[] = this.state.satellites;
    const alertList: AlertList = {};

    await Promise.all(
      satellites.map((item) =>
        getAlertsService(item.satellite.id)
          .then((alerts: any | null) => {
            if (alerts) {
              alertList[item.satellite.id] = alerts.data;
            } else {
              alertList[item.satellite.id] = null;
            }
          })
          .catch(() => (alertList[item.satellite.id] = null))
      )
    );

    this.setState({ alertList }, () => this.refreshTree());
  }

  getTree(
    systems: SatelliteDefinitionSystem[],
    satellite: Satellites,
    parentDefinitionId?: number
  ): TreeData[] {
    if (!systems) return [];
    const { contentExpandedList }: { contentExpandedList: number[] } =
      this.state;

    return systems.map((system: SatelliteDefinitionSystem) => {
      return {
        id: system.id,
        title: system.name,
        expanded: false,
        contentExpanded: contentExpandedList.includes(system.id),
        record: { parentDefinitionId, ...system },
        children: this.getTree(system.systems, satellite, system.id),
        alerts: this.getAlertsFromSystem(system, satellite.satellite.id),
        sat: satellite
      };
    });
  }

  buildTree() {
    const { satelliteDefinitions, satellites } = this.state;
    const treeData: any = [];
    satelliteDefinitions.forEach(
      (satelliteDefinition: SatelliteDefinitionSystem, index: number) => {
        const tree = this.getTree(
          satelliteDefinition.systems,
          satellites[index],
          index
        );
        treeData.push({
          id: satelliteDefinition.id,
          title: satellites[index] && satellites[index]["satellite"]["label"],
          expanded: true,
          children: tree,
          contentExpanded: false,
          header: true
        });
      }
    );

    this.setState({ treeData });
  }

  /**
   * Method to refresh alert data in the tree
   */
  refreshTree() {
    const { treeData } = this.state;
    const updatedTreeData = map({
      treeData,
      callback: ({ node }: any) => {
        const system = node.record;
        return {
          ...node,
          alerts: this.getAlertsFromSystem(
            system,
            node.sat && node.sat.satellite.id
          )
        };
      },
      getNodeKey: ({ treeIndex }) => treeIndex,
      ignoreCollapsed: true
    });
    this.onChange(updatedTreeData);
  }

  toggleExpandAll(expanded: boolean) {
    const { treeData }: { treeData: any } = this.state;

    if (!expanded) {
      this.setState({ contentExpandedList: [] });
    }

    const updatedTreeData = map({
      treeData,
      callback: ({ node }: any) => {
        return {
          ...node,
          expanded: expanded,
          contentExpanded: expanded
        };
      },
      getNodeKey: ({ treeIndex }) => treeIndex,
      ignoreCollapsed: false
    });

    this.onChange(updatedTreeData);

    if (this.listRef) {
      this.recomputeRowHeights();
    }
  }

  toggleExpandAlerts() {
    const { treeData } = this.state;

    const updatedTreeData = map({
      treeData,
      callback: ({ node }: any) => {
        const showAlerts = node.alerts && node.alerts.length > 0;
        return {
          ...node,
          expanded: node.header ? true : showAlerts,
          contentExpanded: node.header ? false : showAlerts
        };
      },
      getNodeKey: ({ treeIndex }) => treeIndex,
      ignoreCollapsed: false
    });

    this.onChange(updatedTreeData);

    if (this.listRef) {
      this.recomputeRowHeights();
    }
  }

  expandNodeContent(
    node: any,
    path: number[],
    getNodeKey: ({ treeIndex }: { treeIndex: number }) => number
  ) {
    const { treeData } = this.state;
    const updatedNode = {
      ...node,
      contentExpanded: !node.contentExpanded
    };
    const updatedTreeData = changeNodeAtPath({
      treeData,
      path,
      getNodeKey,
      newNode: updatedNode
    });

    const { contentExpandedList }: { contentExpandedList: any } = this.state;

    if (updatedNode.contentExpanded) {
      contentExpandedList.push(updatedNode.id);
    } else {
      const index = contentExpandedList.indexOf(updatedNode.id);
      if (index > -1) {
        contentExpandedList.splice(index, 1);
      }
    }

    this.setState({ contentExpandedList });

    this.onChange(updatedTreeData);

    if (this.listRef) {
      this.recomputeRowHeights();
    }
  }

  getDataSourcesFromSystemAndChild(system: SatelliteDefinitionSystem) {
    const systems = (system && system.systems) || [];
    const initial = system ? [...system.dataSources] : [];

    return systems.reduce((acc: any, cur: any) => {
      acc.push(...this.getDataSourcesFromSystemAndChild(cur));
      return acc;
    }, initial);
  }

  getAlertsFromSystem(system: SatelliteDefinitionSystem, satelliteId: number) {
    if (!satelliteId) return [];
    const { alertList }: { alertList: AlertList } = this.state;
    const alerts = alertList[satelliteId];
    const dataSourceIds = this.getDataSourcesFromSystemAndChild(system).map(
      (dataSource: DataSource) => dataSource.id
    );

    return (
      alerts &&
      alerts.filter((alert: AlertDTO) =>
        dataSourceIds.includes(alert.dataSourceId)
      )
    );
  }

  onChange(newData: any) {
    this.setState({ treeData: newData });
  }

  recomputeRowHeights() {
    if (this.listRef) {
      this.listRef.wrappedInstance.current.recomputeRowHeights();
    }
  }

  renderRow({ node }: any) {
    // Method needed to update the rowHeight props from SortableTree
    const defaultRowHeight = node.header ? 32 : 24;

    if (node.record && node.record.dataSources.length > 0) {
      return defaultRowHeight + (node.contentExpanded ? 300 : 0);
    }
    return defaultRowHeight;
  }

  /** Method required by react-sortable-tree */
  getNodeKey = ({ treeIndex }: { treeIndex: number }) => treeIndex;

  getAlertSeverities(alerts: AlertDTO[]) {
    const alertSeverities: string[] = [];

    alerts.forEach((alert: AlertDTO) => {
      if (alert.value && alert.value.length > 0) {
        alert.value.forEach((value) => {
          const valueAlert = value.alert;
          if (
            valueAlert.type === AlertValueStatus.AlertValue &&
            valueAlert.severity
          ) {
            alertSeverities.push(valueAlert.severity);
          } else if (valueAlert.type === AlertValueStatus.OutOfBoundsValue) {
            alertSeverities.push(AlertValueStatus.OutOfBoundsValue);
          }
        });
      }
    });

    return alertSeverities;
  }

  renderAlerts(node: TreeData) {
    const { alerts } = node;
    if (!alerts) return null;

    const alertSeverities: string[] = this.getAlertSeverities(alerts);

    return (
      <Flex>
        {alertSeverities.includes(Severity.Normal) ? (
          <AlertIndicatorLight bg={Colors[Severity.Normal]} />
        ) : null}
        {alertSeverities.includes(Severity.Warning) ? (
          <AlertIndicatorLight bg={Colors[Severity.Warning]} />
        ) : null}
        {alertSeverities.includes(Severity.Critical) ? (
          <AlertIndicatorLight bg={Colors[Severity.Critical]} />
        ) : null}
        {alertSeverities.includes(AlertValueStatus.OutOfBoundsValue) ? (
          <AlertIndicatorLight bg={Colors.OutOfBounds} />
        ) : null}
      </Flex>
    );
  }

  render() {
    const { treeData } = this.state;

    return (
      <Box data-testid="constellation-tree" height="100%">
        <DragHandle relative top={22} left={15} />
        <NodeHeader>
          <NodeHeaderAction onClick={() => this.toggleExpandAll(true)}>
            <Icon name="ArrowDown" size={10} />
            <Text ml={1}>expand all</Text>
          </NodeHeaderAction>
          <NodeHeaderAction onClick={() => this.toggleExpandAll(false)}>
            <Icon name="ArrowUp" size={10} />
            <Text ml={1}>collapse all</Text>
          </NodeHeaderAction>
          <NodeHeaderAction
            borderRight={0}
            onClick={() => this.toggleExpandAlerts()}
          >
            <Box mr={1}>
              <Icon name="ArrowDown" size={10} />
            </Box>
            <Icon name="Alert" color="status.danger" size={10} />
            <Text ml={1}>expand alerts</Text>
          </NodeHeaderAction>
        </NodeHeader>
        <Tree
          reactVirtualizedListProps={{
            ref: (ref: any) => (this.listRef = ref)
          }}
          treeData={treeData}
          nodeContentRenderer={NodeConstellationRenderer as any}
          isVirtualized={true}
          rowHeight={this.renderRow}
          generateNodeProps={({ node, path, ...rest }: any) => {
            const alertList: AlertList = this.state.alertList;
            const dataSourcesIds = node.record
              ? node.record.dataSources.map(
                (dataSource: DataSource) => dataSource.id
              )
              : null;

            return {
              body: (
                <Flex
                  alignItems="stretch"
                  justifyContent="space-between"
                  flex="1 0 100%"
                  height="100%"
                >
                  <Flex alignItems="center">
                    <Box px={1}>{node.title}</Box>
                    <Box>{this.renderAlerts(node)}</Box>
                  </Flex>
                </Flex>
              ),
              dataSources: node.contentExpanded && dataSourcesIds && (
                <Telemetry
                  satellite={node.sat.satellite}
                  ids={dataSourcesIds}
                  interval={config.timer.table}
                >
                  {(props) => (
                    <DataTableComponent
                      {...props}
                      alerts={alertList[node.sat.satellite.id]}
                    />
                  )}
                </Telemetry>
              ),
              expandNodeContent: () =>
                this.expandNodeContent(node, path, this.getNodeKey)
            };
          }}
          onChange={(newData: TreeData[]) => this.onChange(newData)}
        />
      </Box>
    );
  }
}

const mapDispatchToProps = () => {
  return {
    getConstellations: () => getConstellations(),
    getSatelliteDefinition: (id: number) => getSatelliteDefinition(id)
  };
};

export const ConstellationTree = connect(
  null,
  mapDispatchToProps
)(ConstellationTreeBase);
